import liveChatRepository from "../repository/LiveChatRepository";
import {useQuery} from "@tanstack/react-query";
import {LiveChatHistory} from "../model/livechat";
import {LiveChatError, toLiveChatError} from "../error";


const getData = async (channelId: string, liveChatId: string) => {
    try {
        const {data} = await liveChatRepository.findHistories(channelId, liveChatId);
        return data.result;
    } catch (e) {
        // const error = toLiveChatError(e)
        // if (error.code === '502') {
        //     return [];
        // }
        // throw e;
        throw toLiveChatError(e);
    }

}

const useLiveChatHistories = (channelId: string, liveChatId: string) => {
    return useQuery<LiveChatHistory[], LiveChatError>(
        ['liveChatHistories', channelId, liveChatId],
        async () => getData(channelId, liveChatId),
        {suspense: false});
};


export default useLiveChatHistories;