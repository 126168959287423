import React, {useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Card, Col, Dropdown, Row} from "react-bootstrap";
import {useIntl} from "react-intl";
import "../assets/css/main.scss";
import {Channel} from "../model/channel";
import {APP_TITLE, CHATBOT_WEB_HOST, LIVE_CHAT_API_HOST} from "../core/variables";
import Avatar from "../component/Avatar";
import electronRuntime from "../core/electronRuntime";
import logoImage from "../assets/images/swingchat_logo.png";
import useChannels from "../query/channel/useChannels";
import useProfile from "../query/useProfile";
import {clearToken} from "../core/token";
import SimpleBar from "simplebar-react";
import FullAreaSpinner from "../component/FullAreaSpinner";
import toast from "react-hot-toast";

const MainView: React.FC = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const {data: channels, isSuccess: isSuccessChannels, status: channelsStatus, refetch: refetchChannels} = useChannels();
    const {data: profile, remove: removeProfile} = useProfile();

    const referenceElement = useRef<HTMLDivElement>(null);

    useEffect(() => {
        localStorage.removeItem('default_channel');
    }, []);

    const onClickLogout = () => {
        localStorage.removeItem('redirect');
        localStorage.removeItem('default_channel');
        localStorage.removeItem('LCM');
        localStorage.removeItem('LLC');

        if (electronRuntime) {
            electronRuntime.invoke('logout').then(async () => {
                removeProfile();
                await clearToken();

                navigate('/', {replace: true});
            });
        }
        else {
            window.location.href = `${LIVE_CHAT_API_HOST}/v1/auth/logout`;
        }
    };

    const onClickChannelCreateBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        if (electronRuntime) {
            electronRuntime.send('open-browser', CHATBOT_WEB_HOST);
        }
        else {
            window.open(CHATBOT_WEB_HOST, '_blank');
        }
    };

    const onClickReloadBtn = async (e: React.MouseEvent) => {
        e.preventDefault();
        try {
            await refetchChannels({
                throwOnError: true
            });
        } catch (e) {
            toast.error(intl.formatMessage({id: 'i000107'}));
        }
    };


    return (
        <div className="app-content-wrapper flex-column">
            <div className="main-view-header">
                <div className="d-flex align-items-center">
                    <img src={logoImage} alt="title logo" className="mr-1" height={30} />
                    <h4 className="m-0">
                        {APP_TITLE}
                    </h4>
                </div>

                <div className="main-view-header-right-items">
                    <Dropdown>
                        <Dropdown.Toggle id="menu-profile-drop" as="div" className="cursor-pointer" ref={referenceElement}>
                            <Avatar size={30} name={profile?.nickname || ''} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item as="button" type="button" onClick={onClickLogout} className="notify-item">
                                <i className="mdi mdi-logout mr-1" />
                                <span>Logout</span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className="main-view-content">
                {(isSuccessChannels && channels && channels.length > 0) && (
                    <>
                        <div className="page-title-box">
                            <h4 className="page-title">{intl.formatMessage({id: 'i000001'})}</h4>
                        </div>
                        <Row className="mt-1" style={{minHeight: '100px'}}>
                            <SimpleBar className="h-100">
                                <Col>
                                    {channels?.map(channel => (
                                        <ChannelFragment key={channel._id} channel={channel} />
                                    ))}
                                </Col>
                            </SimpleBar>
                        </Row>
                        <Button variant="primary" className="btn-rounded mt-3" onClick={onClickChannelCreateBtn}>새 채널 만들기</Button>
                    </>
                )}
                {(isSuccessChannels && (!channels || channels.length < 1)) && (
                    <>
                        <div className="page-title-box">
                            <h4 className="page-title">{intl.formatMessage({id: 'i000110'})}</h4>
                            <p style={{whiteSpace: 'pre'}}>{intl.formatMessage({id: 'i000111'})}</p>
                        </div>
                        <div className="d-flex mt-4">
                            <Button variant="outline-info" onClick={onClickChannelCreateBtn} className="flex-grow-1 btn-rounded">
                                {intl.formatMessage({id: 'i000225'})}
                            </Button>
                            <Button variant="outline-secondary" onClick={onClickReloadBtn} className="ml-2 btn-rounded">
                                <i className="mdi mdi-reload" />
                            </Button>
                        </div>
                    </>
                )}
                {channelsStatus === 'loading' && (
                    <FullAreaSpinner />
                )}
            </div>
        </div>
    );
};

const ChannelFragment: React.FC<ChannelFragmentProps> = ({channel}) => {
    const navigate = useNavigate();
    const onClickChannel = (e: React.MouseEvent) => {
        e.preventDefault();

        window.localStorage.setItem('default_channel', channel._id);
        navigate(`/channels/${channel._id}`);
    };

    return (
        <Card border="secondary" className="shadow-none bg-transparent border mt-0 mb-2 user-select-none">
            <Card.Body className="d-flex justify-content-between align-items-center cursor-pointer p-2" onClick={onClickChannel}>
                <Card.Title>{channel.name}</Card.Title>
                <Button variant="link" className="p-0"><i className="uil uil-angle-right-b font-24" /></Button>
            </Card.Body>
        </Card>
    );
};

type ChannelFragmentProps = {
    channel: Channel;
}

export default MainView;
