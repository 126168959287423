import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {useIntl} from "react-intl";
import {Dropdown, Form, Spinner} from "react-bootstrap";
import classNames from "classnames";
import ChatList from "../../view/chat/ChatList";
import {useRecoilValue} from "recoil";
import {LiveChat, LiveChatContext, LiveChatStatus} from "../../model/livechat";
import liveChatContext from "../../recoil/liveChatContext";
import SimpleBar from "simplebar-react";
import styled from "styled-components";
import chatMenuSegmentState from "../../recoil/chatMenuSegment";
import {Page} from "../../query";
import useUnassignedLiveChats from "../../query/liveChat/useUnassignedLiveChats";
import useLiveChatContext from "../../hook/useLiveChatContext";
import useAllLiveChats from "../../query/liveChat/useAllLiveChats";
import useManagerLiveChats from "../../query/liveChat/useManagerLiveChats";
import useUnreadLiveChats from "../../query/liveChat/useUnreadLiveChats";
import emptyBoxImage from "../../assets/images/empty-box.png";
import MoreTrigger from "../MoreTrigger";
import useFavoriteLiveChats from "../../query/liveChat/useFavoriteLiveChats";
import {UnStyledButton} from "../UnstyledBootstrap";
import useChannel from "../../query/channel/useChannel";
import {useLocalStorageValue} from "@react-hookz/web";
import {LATEST_ALL_PRIORITIES_KEY, LATEST_MANAGER_PRIORITIES_KEY} from "../../core/variables";
import useSocketIOSubscribe from "../../socket/useSocketIOSubscribe";
import {SocketEvent} from "../../socket";
import ChatBulkActionDropdown from "../chat/ChatBulkActionDropdown";
import useLiveChatBulkActionHandler from "../../hook/useLiveChatBulkActionHandler";
import toast from "react-hot-toast";
import useRequestedOutsideWorkingTimesLiveChats from "../../query/liveChat/useRequestedOutsideWorkingTimesLiveChats";

const ChatListMenu: React.FC<{channelId: string, managerUserId: string}> = ({channelId, managerUserId}) => {
    const intl = useIntl();

    const context = useRecoilValue(liveChatContext);
    const chatMenuSegment = useRecoilValue(chatMenuSegmentState);

    const {
        isBulkMode,
        isBulkActionLoading,
        enableBulkMode,
        showBulkCheckBox,
        bulkLiveChatIds,
        disableBulkMode
    } = useLiveChatBulkActionHandler(channelId);

    const title = useMemo(() => {
        switch (context.type) {
            case LiveChatContext.SEARCH:
                return intl.formatMessage({id: 'm0201'});
            case LiveChatContext.INBOX:
                return intl.formatMessage({id: 'm0204'});
            case LiveChatContext.ALL_INBOX:
                return intl.formatMessage({id: 'm0206'});
            case LiveChatContext.UNREAD:
                return intl.formatMessage({id: 'm0205'});
            case LiveChatContext.UNASSIGNED:
                return intl.formatMessage({id: 'm0203'});
            case LiveChatContext.FAVORITE:
                return intl.formatMessage({id: 'm0207'});
            case LiveChatContext.REQUESTED_OUTSIDE_WORKING_TIMES:
                return intl.formatMessage({id: 'm0208'});
        }
    }, [context, intl]);

    const segmentKey = useMemo(() => {
        return chatMenuSegment[context.type ?? LiveChatContext.INBOX] ?? LiveChatStatus.IN_PROGRESS;
    }, [context, chatMenuSegment]);

    const onClickBulkCheckbox = async () => {
        if (isBulkActionLoading) {
            toast.error(intl.formatMessage({id: "i210071"}));
            return;
        }

        if (isBulkMode) {
            disableBulkMode();
        } else {
            await enableBulkMode();
        }
    };

    useEffect(() => {
        disableBulkMode();
    }, [context, disableBulkMode]);

    return (
        <ChatListMenuStyle>
            <div className="chat-list-menu-container">
                <div className="px-2 pt-3 pb-2 d-flex justify-content-between align-items-center" style={{minHeight: "70px"}}>
                    <div className="d-flex align-items-center" style={{minHeight: "22px"}}>
                        {showBulkCheckBox ?
                            <>
                                {!isBulkMode && <h4 className="my-0 user-select-none">{title}</h4> }
                                {isBulkMode && <div className="my-0 text-primary">{`${bulkLiveChatIds.length}개 선택됨`}</div> }
                                <BulkActionCheckBox isBulkMode={isBulkMode} onClick={onClickBulkCheckbox} disabled={isBulkActionLoading}/>
                            </>
                            :
                            <h4 className="my-0 user-select-none mr-1">{title}</h4>
                        }
                    </div>

                    {showBulkCheckBox && isBulkMode && <ChatBulkActionDropdown channelId={channelId} />}
                    {context.type === LiveChatContext.INBOX && !isBulkMode && <PriorityFilterDropdown channelId={channelId} storageKey={LATEST_MANAGER_PRIORITIES_KEY}/>}
                    {context.type === LiveChatContext.ALL_INBOX && <PriorityFilterDropdown channelId={channelId} storageKey={LATEST_ALL_PRIORITIES_KEY}/>}
                </div>
                {(context.type === LiveChatContext.INBOX || context.type === LiveChatContext.ALL_INBOX) && (
                    <>
                        <ChatStatusSegment channelId={channelId} disabled={isBulkMode}/>
                    </>
                )}
                <div className="scroll-wrapper">
                    <SimpleBar className="flex-grow-1 h-100">
                        {context.type === LiveChatContext.UNASSIGNED && (
                            <UnassignedChatList channelId={channelId} />
                        )}
                        {(context.type === LiveChatContext.INBOX) && (
                            <ManagerLiveChatList channelId={channelId} status={segmentKey} managerUserId={managerUserId}/>
                        )}
                        {context.type === LiveChatContext.UNREAD && (
                            <UnreadLiveChatList channelId={channelId} />
                        )}
                        {(context.type === LiveChatContext.ALL_INBOX) && (
                            <AllLiveChatList channelId={channelId} status={segmentKey} />
                        )}
                        {(context.type === LiveChatContext.REQUESTED_OUTSIDE_WORKING_TIMES) && (
                            <RequestedOutsideWorkingTimesChatList channelId={channelId} />
                        )}
                        {(context.type === LiveChatContext.FAVORITE) && (
                            <FavoriteChatList channelId={channelId} managerUserId={managerUserId}/>
                        )}

                    </SimpleBar>
                </div>
            </div>
        </ChatListMenuStyle>
    );
}

const ChatStatusSegment: React.FC<{channelId: string, className?: string, disabled?: boolean}> = ({channelId, className, disabled = false}) => {
    const intl = useIntl();

    return (
        <ChatStatusSegmentStyle className={className} disabled={disabled}>
            <ChatStatusSegmentItem channelId={channelId} name={intl.formatMessage({id: 'i000002'})} eventKey={LiveChatStatus.IN_PROGRESS} disabled={disabled}/>
            <ChatStatusSegmentItem channelId={channelId} name={intl.formatMessage({id: 'i000004'})} eventKey={LiveChatStatus.HOLDING} disabled={disabled}/>
            <ChatStatusSegmentItem channelId={channelId} name={intl.formatMessage({id: 'i000383'})} eventKey={LiveChatStatus.COMPLETED} disabled={disabled}/>
        </ChatStatusSegmentStyle>
    );
};

const ChatStatusSegmentItem: React.FC<{channelId: string, name: string, eventKey: LiveChatStatus, disabled?: boolean}> = ({channelId, name, eventKey, disabled = false}) => {
    // const liveChatContext = useRecoilValue(liveChatContextState);
    // const [segmentState, setSegmentState] = useRecoilState(chatMenuSegmentState);

    const {liveChatContext, chatMenuSegment, update: updateLiveChatContext} = useLiveChatContext(channelId);

    const active = useMemo(() => {
        const currentSegment = chatMenuSegment ?? 'in_progress';
        return currentSegment === eventKey;
    }, [eventKey, chatMenuSegment]);

    const onClick = (e: React.MouseEvent) => {
        e.preventDefault();

        if (disabled) {
            return;
        }

        if (liveChatContext) {
            updateLiveChatContext(liveChatContext, eventKey);
        }
    };

    return (
        <div className={classNames('segment-item', {active})} role="button" onClick={onClick}>
            {name}
        </div>
    );
};


const UnassignedChatList: React.FC<{channelId: string}> = ({channelId}) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const {data: liveChatPage, fetchNextPage, hasNextPage, isFetchingNextPage, isSuccess, isLoading, isStale} = useUnassignedLiveChats(channelId, '-since');

    const liveChats = useMemo(() => {
        return liveChatPage?.pages?.map((page: Page<LiveChat>) => page.result).flat() ?? []
    }, [liveChatPage]);

    const onEnterMoreTrigger = async () => {
        await fetchNextPage();
    }

    return (
        <ChatListWrapper ref={containerRef}>
            <ChatList key="unassigned" groupKey="unassigned" liveChats={liveChats} className={classNames({'opacity-0': !isSuccess})} />
            {(isSuccess && liveChats.length < 1 && isStale) && <EmptyChatList />}
            {isLoading && <ChatListSpinner />}
            {(hasNextPage && !isFetchingNextPage) && (
                <MoreTrigger position="bottom" onEnter={onEnterMoreTrigger} scrollHeight={containerRef.current?.scrollHeight} />
            )}
        </ChatListWrapper>
    );
};


const ManagerLiveChatList: React.FC<{channelId: string, status: LiveChatStatus, managerUserId: string}> = ({channelId, status, managerUserId}) => {
    const [priorities] = useLocalStorageValue<{[key: string]: number[]}>(LATEST_MANAGER_PRIORITIES_KEY, {[channelId]: []}, {storeDefaultValue: true});

    const {
        data: liveChatPage,
        fetchNextPage,
        isSuccess,
        isLoading,
        hasNextPage,
        isStale,
        isFetchingNextPage
    } = useManagerLiveChats(channelId, status, managerUserId, priorities[channelId] ?? [], '-since');

    const containerRef = useRef<HTMLDivElement>(null);

    const liveChats = useMemo(() => {
        return liveChatPage?.pages?.map((page: Page<LiveChat>) => page.result).flat() ?? []
    }, [liveChatPage]);

    const onEnterMoreTrigger = async () => {
        await fetchNextPage();
    };

    return (
        <ChatListWrapper ref={containerRef}>
            <ChatList key={`manager-${status}`} groupKey={status.toString()} liveChats={liveChats} className={classNames({'opacity-0': !isSuccess})} />
            {(isSuccess && liveChats.length < 1 && isStale) && <EmptyChatList />}
            {isLoading && <ChatListSpinner />}
            {(hasNextPage && !isFetchingNextPage) && (
                <MoreTrigger position="bottom" onEnter={onEnterMoreTrigger} scrollHeight={containerRef.current?.scrollHeight} />
            )}
        </ChatListWrapper>
    );
};


const AllLiveChatList: React.FC<{channelId: string, status: LiveChatStatus}> = ({channelId, status}) => {
    const [priorities] = useLocalStorageValue<{[key: string]: number[]}>(LATEST_ALL_PRIORITIES_KEY, {[channelId]: []}, {storeDefaultValue: true});

    const {
        data: liveChatPage,
        fetchNextPage,
        isFetchingNextPage,
        hasNextPage,
        isSuccess,
        isLoading,
        isStale
    } = useAllLiveChats(channelId, status, priorities[channelId] ?? [], '-since');

    const containerRef = useRef<HTMLDivElement>(null);

    const liveChats = useMemo(() => {
        return liveChatPage?.pages?.map((page: Page<LiveChat>) => page.result).flat() ?? []
    }, [liveChatPage]);

    const onEnterMoreTrigger = async () => {
        await fetchNextPage();
    }

    return (
        <ChatListWrapper ref={containerRef}>
            <ChatList key={`all-${status}`} groupKey={`all-${status}`} liveChats={liveChats} className={classNames({'opacity-0': !isSuccess})} />
            {(isSuccess && liveChats.length < 1 && isStale) && <EmptyChatList />}
            {isLoading && <ChatListSpinner />}
            {(hasNextPage && !isFetchingNextPage) && (
                <MoreTrigger position="bottom" onEnter={onEnterMoreTrigger} scrollHeight={containerRef.current?.scrollHeight} />
            )}
        </ChatListWrapper>
    );
};

const UnreadLiveChatList: React.FC<{channelId: string}> = ({channelId}) => {
    const {data: liveChatPage, fetchNextPage, isFetchingNextPage, hasNextPage, isSuccess, isLoading, isStale, refetch} = useUnreadLiveChats(channelId, '-since');

    const containerRef = useRef<HTMLDivElement>(null);

    const liveChats = useMemo(() => {
        return liveChatPage?.pages?.map((page: Page<LiveChat>) => page.result).flat() ?? []
    }, [liveChatPage]);

    const onEnterMoreTrigger = async () => {
        await fetchNextPage();
    }

    useSocketIOSubscribe(SocketEvent.RECONNECT, async () => refetch().finally());

    return (
        <ChatListWrapper ref={containerRef}>
            <ChatList key="unread" groupKey="unread" liveChats={liveChats} className={classNames({'opacity-0': !isSuccess})} />
            {(isSuccess && liveChats.length < 1 && isStale) && <EmptyChatList />}
            {isLoading && <ChatListSpinner />}
            {(hasNextPage && !isFetchingNextPage) && (
                <MoreTrigger position="bottom" onEnter={onEnterMoreTrigger} scrollHeight={containerRef.current?.scrollHeight} />
            )}
        </ChatListWrapper>
    );
};

const FavoriteChatList: React.FC<{channelId: string, managerUserId: string}> = ({channelId, managerUserId}) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const {data: liveChatPage, fetchNextPage, hasNextPage, isFetchingNextPage, isSuccess, isLoading, isStale} = useFavoriteLiveChats(channelId, managerUserId, '-since');

    const liveChats = useMemo(() => {
        return liveChatPage?.pages?.map((page: Page<LiveChat>) => page.result).flat() ?? []
    }, [liveChatPage]);

    const onEnterMoreTrigger = async () => {
        await fetchNextPage();
    }

    return (
        <ChatListWrapper ref={containerRef}>
            <ChatList key="favorite" groupKey="favorite" liveChats={liveChats} className={classNames({'opacity-0': !isSuccess})} />
            {(isSuccess && liveChats.length < 1 && isStale) && <EmptyChatList />}
            {isLoading && <ChatListSpinner />}
            {(hasNextPage && !isFetchingNextPage) && (
                <MoreTrigger position="bottom" onEnter={onEnterMoreTrigger} scrollHeight={containerRef.current?.scrollHeight} />
            )}
        </ChatListWrapper>
    );
};

const RequestedOutsideWorkingTimesChatList: React.FC<{channelId: string}> = ({channelId}) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const {
        data: liveChatPage,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isSuccess,
        isLoading,
        isStale
    } = useRequestedOutsideWorkingTimesLiveChats(channelId, '-since');

    const liveChats = useMemo(() => {
        return liveChatPage?.pages?.map((page: Page<LiveChat>) => page.result).flat() ?? []
    }, [liveChatPage]);

    const onEnterMoreTrigger = async () => {
        await fetchNextPage();
    }

    return (
        <ChatListWrapper ref={containerRef}>
            <ChatList groupKey="requestedOutsideWorkingTimes" liveChats={liveChats} className={classNames({'opacity-0': !isSuccess})} />
            {(isSuccess && liveChats.length < 1 && isStale) && <EmptyChatList />}
            {isLoading && <ChatListSpinner />}
            {(hasNextPage && !isFetchingNextPage) && (
                <MoreTrigger position="bottom" onEnter={onEnterMoreTrigger} scrollHeight={containerRef.current?.scrollHeight} />
            )}
        </ChatListWrapper>
    );
};

const ChatListSpinner = React.memo(() => {
    return (
        <ChatListSpinnerStyle>
            <Spinner animation="grow" variant="info" />
        </ChatListSpinnerStyle>
    );
});

const EmptyChatList = React.memo(() => {
    const intl = useIntl();
    // const [opacity, setOpacity] = useState(0);
    //
    // useMountEffect(() => {
    //     setOpacity(100);
    // });

    return (
        <EmptyChatListStyle>
            <img src={emptyBoxImage} alt="empty live chats" width="74px" />
            <span className="text-muted">
                {intl.formatMessage({id: 'i000018'})}
            </span>
        </EmptyChatListStyle>
    );
});

const PriorityFilterDropdown: React.FC<{channelId: string, storageKey: string}> = ({channelId, storageKey}) => {
    const intl = useIntl();

    const [_priorityFilters, setPriorityFilters] = useLocalStorageValue<{[key: string]: number[]}>(
        storageKey, {}, {storeDefaultValue: true}
    );

    const priorityFilters = useMemo(() => {
        return _priorityFilters[channelId] ?? [];
    }, [channelId, _priorityFilters])

    const { data: channel } = useChannel(channelId);

    const priorities = useMemo(() => {
        return channel?.config.priorities.sort((a, b) => a.priority > b.priority ? -1 : 1) ?? [];
    }, [channel])

    const updatePriorityFilters = useCallback((priority: number) => {
        setPriorityFilters(prev => {
            const _prev = prev[channelId] ?? [];
            return {
                ...prev,
                [channelId]: _prev.includes(priority)
                    ? [..._prev.filter(item => item !== priority)].sort()
                    : [..._prev, priority].sort()
            }
        });
    }, [setPriorityFilters, channelId]);

    const onClickPriorityCheck = (e: React.MouseEvent, priority: number) => {
        e.stopPropagation()
        updatePriorityFilters(priority)
    };

    return (
        <div className="priority-filter">
            <Dropdown autoClose="outside">
                <Dropdown.Toggle id="chat-list-priority-filter-toggle"
                                 as={FilterToggleButton}
                                 bsPrefix="unused">
                    <div className="inner-dropdown-toggle">
                        <span className="font-12" style={{marginRight: "0.25rem"}}>
                            {intl.formatMessage({id: "i210036"})}
                        </span>
                        {priorityFilters.length > 0 &&
                            <div className="priority-filter-count">{priorityFilters.length}</div>
                        }
                        <i className="mdi mdi-chevron-down font-12"/>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {priorities.map(priority => (
                        <Dropdown.Item key={priority.priority}
                                       className="px-2 py-1" id={`priority-${priority.priority}`}
                                       onClick={() => updatePriorityFilters(priority.priority)}>
                            <PriorityFilterItemStyle>
                                <Form.Check checked={priorityFilters.includes(priority.priority)}
                                            readOnly={true}
                                            onClick={(e) => onClickPriorityCheck(e, priority.priority)}/>
                                <div className="priority-color" style={{backgroundColor: priority.color}}/>
                                <span className="priority-name">{priority.alias ?? priority.name}</span>
                            </PriorityFilterItemStyle>
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

const FilterToggleButton = styled(UnStyledButton)<{enabled: boolean}>`
  &:hover {
    background-color: unset;
  }
`

const PriorityFilterItemStyle = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  .priority-color {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-left: 0.5rem;
  }

  .priority-name {
    margin-left: 0.5rem;
  }
`;

const ChatListMenuStyle = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #ffffff;
  width: 260px;
  //height: 100%;
  flex-shrink: 0;
  //overflow: hidden;
  border-right: 1px solid rgba(212,213,215,0.8);
  
  .chat-list-menu-container {
    position: relative;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
    //overflow: hidden;

    .priority-filter {
      display: flex;
      align-items: center;
      justify-content: end;

      .dropdown {
        margin: 0 6px 2px 6px;
      }

      .unused {
        padding: 0 !important;
      }

      .inner-dropdown-toggle {
        width: 80px;
        display: flex;
        justify-content: end;
        align-items: center;
        color: #616263;
      }

      .priority-filter-count {
        width: 14px;
        height: 14px;
        font-size: 11px;
        border-radius: 7px;
        margin-right: 2px;

        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: #DCDBDC;
      }

      .unused {
        padding: 0.25rem;
      }
    }
    
    .scroll-wrapper {
      //height: 100%;
      min-height: 1px;
      flex-grow: 1;
    }
  }
`;


// const ChatMenuBottomStyle = styled.div`
//   display: flex;
//   justify-content: end;
//   align-items: center;
//   border-top: 1px solid var(--ct-border-color);
//   //border-right: 1px solid var(--ct-border-color);
//   padding: 0.75rem 0.75rem 0.75rem 1.075rem;
//   background-color: #ffffff;
//   //margin-right: 1px;
// `;

const ChatStatusSegmentStyle = styled.div<{disabled: boolean}>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 4px;
  margin: 0 6px 10px;
  background-color: #F0F1F4;
  border-radius: 4px;
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border-radius: 4px;

    content: "";
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 10;
    visibility: ${props => props.disabled ? "visible" : "hidden"};
    opacity: ${props => props.disabled ? "1" : "0"};
    transition: opacity 0.2s ease-in-out;

    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  }
  
  .segment-item {
    border-radius: 4px;
    padding: 4px;
    //margin: 0 0.2rem;
    flex-grow: 1;
    flex-shrink: 0;
    text-align: center;
    color: #000000;
    user-select: none;

    &.active {
      background-color: #ffffff;
    }
    
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

const ChatListWrapper = styled.div`
  position: relative;

  .chat-list-enter {
    opacity: 0;
    //max-height: 0;
  }
  .chat-list-enter-active {
    opacity: 1;
    //max-height: 100px;
    transition: opacity 300ms ease-in;
  }
  .chat-list-exit {
    opacity: 1;
  }
  .chat-list-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-out;
  }
`;

const EmptyChatListStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  user-select: none;
  //transition: opacity ease-in-out 0.2s;
  //padding: 120px 0;
  height: calc(100vh - 340px);
  min-height: 340px;
  //position: absolute;
  //top: 0;
  //width: 100%;
`;

const ChatListSpinnerStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  user-select: none;
  //transition: opacity ease-in-out 0.2s;
  //padding: 120px 0;
  height: calc(100vh - 340px);
  min-height: 340px;
`;

type BulkActionCheckBoxProps = {
    isBulkMode: boolean;
    onClick: () => void;
    disabled: boolean;
};

const BulkActionCheckBox: React.FC<BulkActionCheckBoxProps> = (props) => {
    const { isBulkMode, onClick, disabled } = props;
    return (
        <BulkActionCheckBoxStyle isBulkMode={isBulkMode} onClick={onClick} disabled={disabled}>
            <i className="mdi mdi-check"/>
        </BulkActionCheckBoxStyle>
    )
}

const BulkActionCheckBoxStyle = styled.div<{isBulkMode: boolean, disabled: boolean}>`
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  margin-left: 0.5rem;
  border: 1px solid ${props => props.isBulkMode ? "#0F62FE" : "#5e5e5e"};
  background-color: ${props => props.isBulkMode ? "#0F62FE" : "#FFFFFF"};
  transition: border 0.1s linear, background-color 0.1s linear;
  cursor: ${props => props.disabled ? "default" : "pointer"};

  .mdi-check {
    position: absolute;
    font-size: 16px;
    left: 0;
    bottom: -4px;
    color: ${props => props.isBulkMode ? "#FFFFFF" : "#5e5e5e"};
  }

  &:hover {
    border: 1px solid ${props => props.isBulkMode ? "#206fff" : "#5e5e5e"};
    background-color: ${props => props.isBulkMode ? "#206fff" : "#e8e8e8"};
    transition: border 0.1s linear, background-color 0.1s linear;
  }
`;

export default ChatListMenu;