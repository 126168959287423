import React from "react";
import {useIntl} from "react-intl";


const LastConfirmedHelpMessage = React.memo(() => {
    const intl = useIntl();

    return (
        <p className="hr-sect">{intl.formatMessage({id: 'i000178'})}</p>
    );
});

export default LastConfirmedHelpMessage;