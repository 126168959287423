import React, {useCallback} from "react";
import {
    Cafe24PaymentMethod,
    ClipboardData,
    CodeSnippetObject,
    DividerItemData,
    IconItemData,
    ImageItemData,
    ItemDataType,
    JSONTemplateItem,
    LinkItemData,
    PaymentMethodTextItemData,
    SmallTextItemData,
    TextItemData
} from "../model/codeSnippet";
import electronRuntime from "../core/electronRuntime";
import moment from "moment";
import {useIntl} from "react-intl";
import toast from "react-hot-toast";


interface CodeSnippetRendererProps {
    codeSnippetObject: CodeSnippetObject
}

const NO_IMAGE_URL = "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg";

const CodeSnippetRenderer : React.FC<CodeSnippetRendererProps> = (props) => {
    const { codeSnippetObject } = props;

    const intl = useIntl();

    const onClickLink = useCallback((url?: string) => {
        if (!url) {
            return
        }

        if (electronRuntime) {
            electronRuntime.send('open-default-browser', url);
        } else {
            window.open(url, '_blank')
        }
    }, [])

    const onClickClipboard = useCallback((text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            toast.success(intl.formatMessage({id: 'i100058'}));
        });
    }, [intl])

    const dataComponent = (data: JSONTemplateItem[]) => {
        return data.map((item: JSONTemplateItem, index) => {

            switch (item.type) {
                case ItemDataType.TEXT:
                    const textItemData = item.data as TextItemData;
                    return (
                        <div key={index}
                             className={textItemData.className}
                             style={textItemData.style}>
                            {textItemData.date ? moment.utc(textItemData.text).local().format('LLL') : textItemData.text}
                        </div>
                    )
                case ItemDataType.SMALL_TEXT:
                    const smallTextItemData = item.data as SmallTextItemData;
                    return (
                        <small key={index}
                               className={smallTextItemData.className}
                               style={smallTextItemData.style}>
                            {smallTextItemData.date ? moment.utc(smallTextItemData.text).local().format('LLL') : smallTextItemData.text}
                        </small>
                    )
                case ItemDataType.PAYMENT_METHOD_TEXT:
                    const paymentMethodTextItemData = item.data as PaymentMethodTextItemData;
                    return (
                        <small key={index}
                               className={paymentMethodTextItemData.className}
                               style={paymentMethodTextItemData.style}>
                            {Cafe24PaymentMethod.get(paymentMethodTextItemData.text)}
                        </small>
                    )
                case ItemDataType.ICON:
                    const iconItemData = item.data as IconItemData;
                    return (
                        <i key={index}
                           className={iconItemData.className}
                           style={iconItemData.style}
                           onClick={() => onClickLink(iconItemData.clickUrl)}/>
                    )
                case ItemDataType.LINK:
                    const linkItemData = item.data as LinkItemData;
                    return (
                        <div key={index}
                             className={linkItemData.className}
                             style={linkItemData.style}
                             onClick={() => onClickLink(linkItemData.url)}>
                            {linkItemData.text}
                        </div>
                    )
                case ItemDataType.DIVIDER:
                    const dividerItemData = item.data as DividerItemData;
                    return (
                        <hr key={index}
                            className={dividerItemData.className}
                            style={dividerItemData.style}/>
                    )
                case ItemDataType.IMAGE:
                    const imageItemData = item.data as ImageItemData;
                    const url = imageItemData.url === "None" ? NO_IMAGE_URL : imageItemData.url
                    return (
                        <img key={index}
                             src={url} alt=""
                             className={imageItemData.className}
                             style={imageItemData.style}
                             onClick={() => onClickLink(imageItemData.clickUrl)}/>
                    )
                case ItemDataType.LIST:
                    const listItemData = item.data as JSONTemplateItem[];
                    return (
                        <div key={index}
                             className="d-flex flex-column"
                             style={item.style}>
                            {dataComponent(listItemData)}
                        </div>
                    )
                case ItemDataType.LIST_ROW:
                    const listRowItemData = item.data as JSONTemplateItem[];
                    return (
                        <div key={index}
                             className="d-flex"
                             style={item.style}>
                            {dataComponent(listRowItemData)}
                        </div>
                    )
                case ItemDataType.CLIPBOARD:
                    const clipboardData = item.data as ClipboardData;
                    return (
                        <div key={index}
                             className={clipboardData.className}
                             style={clipboardData.style}
                             onClick={() => onClickClipboard(clipboardData.text)}>
                            {clipboardData.text}
                        </div>
                    )
                default:
                    return <></>
            }
        })
    }

    return (
        <div className={`d-flex flex-column m-0 ${codeSnippetObject.className}`}
             style={codeSnippetObject.style}>
            {dataComponent(codeSnippetObject.data)}
        </div>
    )
}

export default CodeSnippetRenderer;
