import React, {useEffect, useState} from "react";

import minimizeIcon from "../assets/images/windows/minimize.svg";
import maximizeIcon from "../assets/images/windows/maximize.svg";
import restoreIcon from "../assets/images/windows/restore.svg";
import closeIcon from "../assets/images/windows/close.svg";

import styled from "styled-components";
import classNames from "classnames";
import electronRuntime from "../core/electronRuntime";

const WindowsControls: React.FC = () => {

    const [isMaximized, setMaximized] = useState(false);

    useEffect(() => {
        if (!electronRuntime) {
            return;
        }

        electronRuntime.invoke('is-maximized').then(isMaximized => {
            setMaximized(isMaximized);
        });

        const unsubscribe = electronRuntime.subscribe('window-updated', (event: any, args: any) => {
            if (args.event === 'maximized') {
                setMaximized(true);
            }
            else if (args.event === 'unmaximized') {
                setMaximized(false);
            }
        });

        return () => {
            unsubscribe();
        };

    }, []);

    const onClickMinimize = async (e: React.MouseEvent) => {
        e.preventDefault();

        if (electronRuntime) {
            await electronRuntime.invoke('window-minimize');
        }
    };

    const onClickMaximize = async (e: React.MouseEvent) => {
        e.preventDefault();

        if (electronRuntime) {
            await electronRuntime.invoke('window-maximize');
            setMaximized(true);
        }
    };

    const onClickRestore = async (e: React.MouseEvent) => {
        e.preventDefault();

        if (electronRuntime) {
            await electronRuntime.invoke('window-unmaximize');
            setMaximized(false);
        }
    };

    const onClickClose = async (e: React.MouseEvent) => {
        e.preventDefault();

        if (electronRuntime) {
            await electronRuntime.invoke('window-close');
        }
    };

    return (
        <WindowControlsStyle>
            <div className="windows-controls">
                <div className="button min-button" onClick={onClickMinimize}>
                    <img className="icon"
                         src={minimizeIcon}
                         // srcSet={`${minK10Img} 1x, ${minK12Img} 1.25x, ${minK15Img} 1.5x, ${minK15Img} 1.75x, ${minK20Img} 2x, ${minK20Img} 2.25x, ${minK24Img} 2.5x, ${minK30Img} 3x, ${minK30Img} 3.5x`}
                         width="24"
                         height="24"
                         alt="최소화"
                         draggable="false"/>
                </div>
                <div className={classNames('button max-button', {'d-none': isMaximized})} onClick={onClickMaximize}>
                    <img className="icon"
                         src={maximizeIcon}
                         width="24"
                         height="24"
                         // srcSet={`${maxK10Img} 1x, ${maxK12Img} 1.25x, ${maxK15Img} 1.5x, ${maxK15Img} 1.75x, ${maxK20Img} 2x, ${maxK20Img} 2.25x, ${maxK24Img} 2.5x, ${maxK30Img} 3x, ${maxK30Img} 3.5x`}
                         alt="최대화"
                         draggable="false"/>
                </div>
                <div className={classNames('button restore-button', {'d-none': !isMaximized})} onClick={onClickRestore}>
                    <img className="icon"
                         src={restoreIcon}
                         width="24"
                         height="24"
                         // srcSet={`${restoreK10Img} 1x, ${restoreK12Img} 1.25x, ${restoreK15Img} 1.5x, ${restoreK15Img} 1.75x, ${restoreK20Img} 2x, ${restoreK20Img} 2.25x, ${restoreK24Img} 2.5x, ${restoreK30Img} 3x, ${restoreK30Img} 3.5x`}
                         alt="이전 크기로 복원"
                         draggable="false"/>
                </div>
                <div className="button close-button" onClick={onClickClose}>
                    <img className="icon"
                         src={closeIcon}
                         width="24"
                         height="24"
                         // srcSet={`${closeK10Img} 1x, ${closeK12Img} 1.25x, ${closeK15Img} 1.5x, ${closeK15Img} 1.75x, ${closeK20Img} 2x, ${closeK20Img} 2.25x, ${closeK24Img} 2.5x, ${closeK30Img} 3x, ${closeK30Img} 3.5x`}
                         alt="닫기"
                         draggable="false"/>
                </div>
            </div>

        </WindowControlsStyle>
    )
};

const WindowControlsStyle = styled.div`
  //display: flex;
  //grid-template-columns: repeat(3, 46px);
  position: fixed;
  top: 0;
  right: 0;
  -webkit-app-region: no-drag;
  
  .windows-controls {
    display: flex;
    height: 32px;
    justify-self: end;
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 47px;
      height: 100%;
      transition: background-color 200ms;
      user-select: none;
      flex-grow: 0;
      flex-shrink: 0;

      &.close-button {
        &:hover {
          background-color: #e81123 !important;
          .icon {
            filter: invert(1);
          }
        }

        &:active {
          background-color: #f90d3c !important;
          
          .icon {
            filter: invert(1);
          }
        }
      }

      &:hover {
        background-color: #e4e4e5;
      }

      &:active {
        background-color: #d4d4d5;
      }
    }
  }

  &.maximized {
    width: 100%;
    padding: 0;

    .window-title {
      margin-left: 12px;
    }

    .restore-button {
      display: flex !important;
    }

    .max-button {
      display: none !important;
    }
  }
`;

export default WindowsControls;