import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError, AxiosResponse} from "axios";
import oauthAxios from "../../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../../core/variables";
import useLiveChatsHandler from "../../hook/useLiveChatsHandler";


const useUpdateLiveChat = (channelId: string, liveChatId: string) => {
    const queryClient = useQueryClient();

    const liveChatsHandler = useLiveChatsHandler(channelId);

    return useMutation<AxiosResponse, AxiosError, {categoryId?: string, priority?: number}>(['liveChat', 'update', channelId, liveChatId], async (body) => {
        try {
            return await oauthAxios.put(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/livechats/${liveChatId}`, body);
        } catch (e) {
            throw e;
        }
    }, {
        onSuccess: (response) => {
            return Promise.all([
                queryClient.invalidateQueries(['liveChat', channelId, liveChatId], {exact: true}),
                liveChatsHandler.updateLiveChat(response.data.result)
                // queryClient.invalidateQueries(['liveChats', 'unassigned', channelId], {exact: true}),
                // queryClient.invalidateQueries(['liveChats', 'inProgress', channelId], {exact: true}),
                // queryClient.invalidateQueries(['liveChats', 'holding', channelId], {exact: true}),
                // queryClient.invalidateQueries(['liveChats', 'completed', channelId], {exact: true}),
            ]);
        }
    });
}

export default useUpdateLiveChat;
