import {action, makeObservable, observable} from "mobx";
import React from "react";
import {Button, Modal} from 'react-bootstrap';
import {observer} from "mobx-react-lite";
import parse from "html-react-parser";
import {useIntl} from "react-intl";

class DialogStore {

    @observable
    isOpen: boolean = false;

    @observable
    title?: string | null = null;

    @observable
    content: string | null = null;

    @observable
    contentAlign: 'center'|'left'|'right' = 'center';

    @observable
    variant: DialogVariant = 'primary';

    @observable
    confirmText?: string;

    @observable
    cancelText?: string;

    @observable
    onConfirm: VoidFunction | null = null;

    @observable
    onCancel: VoidFunction | null = null;

    constructor() {
        makeObservable(this);
    }

    @action
    open(params: OpenParams) {
        this.isOpen = true;
        this.content = params.content;
        this.contentAlign = params.contentAlign || 'center';
        this.onConfirm = params.onConfirm;
        this.title = params.title;
        this.variant = params.variant || 'primary';
        this.confirmText = params.confirmText;
        this.cancelText = params.cancelText;
        this.onCancel = params.onCancel || null;
    }

    @action
    close() {
        this.isOpen = false;

        setTimeout(() => {
            this.content = null;
            this.contentAlign = 'center';
            this.onConfirm = null;
            this.title = null;
            this.variant = 'primary';
            this.confirmText = undefined;
            this.cancelText = undefined;
            this.onCancel = null;
        }, 300);
    }
}

type DialogVariant = 'primary' | 'success' | 'warning' | 'danger';

type OpenParams = {
    content: string,
    contentAlign?: 'center'|'left'|'right',
    onConfirm: VoidFunction,
    title?: string,
    variant?: DialogVariant,
    confirmText?: string,
    cancelText?: string,
    onCancel?: VoidFunction
};

const context = React.createContext(new DialogStore());
export const useDialog = () => React.useContext(context);

const Dialog: React.FC = observer(() => {

    const dialog = useDialog();
    const intl = useIntl();

    const handleClose = () => {
        if (dialog.onCancel) {
            dialog.onCancel();
        }

        dialog.close();
    };

    const onClickConfirmBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        if (dialog.onConfirm) {
            dialog.onConfirm();
        }

        dialog.close();
    };

    return (
        <Modal show={dialog.isOpen} onHide={handleClose} animation={true} role="dialog" centered style={{zIndex: 2055}}>
            <Modal.Body>
                {dialog.title && (
                    <h4 className={`text-${dialog.variant} text-center mt-2`}>{dialog.title}</h4>
                )}
                <div className="my-3">
                    <p style={{ whiteSpace: "pre-wrap", textAlign: dialog.contentAlign}}>{dialog.content && parse(dialog.content)}</p>
                </div>

                <div className="d-flex justify-content-center mt-2 mb-2">
                    <Button variant="outline-secondary" className="btn-rounded mr-2" onClick={handleClose} style={{minWidth: '72px'}}>
                        {dialog.cancelText || intl.formatMessage({id: 'i000302'})}
                    </Button>
                    <Button variant={dialog.variant} onClick={onClickConfirmBtn} className="btn-rounded" style={{minWidth: '72px'}}>
                        {dialog.confirmText || intl.formatMessage({id: 'i000303'})}
                    </Button>
                </div>
            </Modal.Body>
            {/*<Modal.Footer>*/}
            {/*    <Button variant={'outline-secondary'} onClick={handleClose}>{dialog.cancelText}</Button>*/}
            {/*    <Button variant={`outline-${dialog.variant}`} onClick={onClickConfirmBtn}>{dialog.confirmText}</Button>*/}
            {/*</Modal.Footer>*/}
        </Modal>
    );
});

export default Dialog;
