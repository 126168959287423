import {atom} from "recoil";


const qnaChatbotState = atom({
    key: 'qnaChatbot',
    default: {
        show: false
    }
});

export default qnaChatbotState;
