import {useMutation} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import {LiveChatError, toLiveChatError} from "../../error";
import {AnnouncementCreate} from "../../model/announcement";
import announcementRepository from "../../repository/AnnouncementRepository";

const useCreateAnnouncement = (channelId: string) => {
    return useMutation<AxiosResponse, LiveChatError, AnnouncementCreate>({
        mutationKey: ["announcement", "create", channelId],
        mutationFn: async (body: AnnouncementCreate) => {
            try {
                const { data } = await announcementRepository.create(channelId, body)
                return data.result;
            } catch (e) {
                throw toLiveChatError(e);
            }
        }
    });
};

export default useCreateAnnouncement;
