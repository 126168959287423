import React, {useRef} from "react";
import useModal, {MaruModal} from "../../hook/useModal";
import {Button, Form, Modal} from "react-bootstrap";
import {Editor, EditorRef} from "@42maru/content-editor";
import {useIntl} from "react-intl";
import useCreateAnnouncement from "../../query/announcement/useCreateAnnouncement";
import toast from "react-hot-toast";
import {AnnouncementCreate} from "../../model/announcement";

export const ANNOUNCEMENT_PUB_MODAL_ID = "announcement-pub-modal-id";

type AnnouncementPublishModalProps = {
    channelId: string;
    managerUserId: string;
}

const AnnouncementPublishModal: React.FC<AnnouncementPublishModalProps> = (props) => {
    const { channelId, managerUserId } = props;

    const intl = useIntl();
    const modal = useModal();

    const { mutateAsync: createAnnouncement } = useCreateAnnouncement(channelId);

    const editorRef = useRef<EditorRef>(null);

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!editorRef.current) {
            return;
        }

        const contents = editorRef.current.getContents();
        const blocks = [];

        const text = contents.map(content => content.value.text).join('\n');
        if (!text) {
            return;
        }

        const html = contents.map(content => content.value.html).join('\n');
        if (text) {
            blocks.push({
                type: "text",
                value: {
                    text: text.trim(),
                    html: html.trim()
                },
                meta: contents.map(content => content.meta)
            });
        }

        const body: AnnouncementCreate = {
            authorId: managerUserId,
            blocks: blocks
        };

        await toast.promise(
            createAnnouncement(body),
            {
                loading: intl.formatMessage({id: "i210048"}),
                success: () => {
                    modal.close(ANNOUNCEMENT_PUB_MODAL_ID);
                    return intl.formatMessage({id: "i210049"});
                },
                error: () => {
                    return intl.formatMessage({id: "i210050"});
                }
            }
        ).catch(() => {});
    }

    const onClickCancelBtn = (e: React.MouseEvent) => {
        e.preventDefault();
        modal.close(ANNOUNCEMENT_PUB_MODAL_ID);
    }

    return (
        <MaruModal id={ANNOUNCEMENT_PUB_MODAL_ID}
                   title={intl.formatMessage({id: "i210042"})}
                   centered size="lg">
            <Modal.Body>
                <div className="d-flex justify-content-center">
                    <Form id='announcement-pub-form' onSubmit={onSubmit}>
                        <Form.Group className="d-flex align-items-center mb-2">
                            <Form.Label className="mr-2 mb-0">
                                {intl.formatMessage({id: "i210051"})}
                            </Form.Label>
                            <Form.Select defaultValue={'all'} disabled className="w-50" size="sm">
                                <option key="all" value="all">
                                    {intl.formatMessage({id: "i210052"})}
                                </option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                {intl.formatMessage({id: "i210055"})}
                            </Form.Label>
                            <Editor id={'announcement-pub-editor'}
                                    placeholder={intl.formatMessage({id: "i210053"})}
                                    height="250px"
                                    width="600px"
                                    ref={editorRef}
                                    enterFunctionKey={"shift"}
                            />
                        </Form.Group>
                    </Form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" className="btn-rounded" variant="outline-secondary" onClick={onClickCancelBtn}>
                    {intl.formatMessage({id: 'i000022'})}
                </Button>
                <Button type="submit" className="btn-rounded" form={'announcement-pub-form'}>
                    {intl.formatMessage({id: "i210054"})}
                </Button>
            </Modal.Footer>
        </MaruModal>
    )
};

export default AnnouncementPublishModal;
