import {useContext, useEffect, useRef} from "react";
import {SocketContext} from "./SocketProvider";
import {useThrottledCallback} from "@react-hookz/web";

const useThrottledSocketSubscribe = <T>(event: string, handler: (data: T[]) => void, delay: number) => {
    const { socket } = useContext(SocketContext);
    const eventQueueRef = useRef<T[]>([]);

    const throttledHandler = useThrottledCallback(() => {
        handler(eventQueueRef.current);
        eventQueueRef.current = [];
    }, [handler], delay)

    useEffect(() => {
        const handlerWrapper = (response: any) => {
            eventQueueRef.current.push(response.data);
            throttledHandler();
        };

        socket?.on(event, handlerWrapper);

        return () => {
            socket?.off(event, handlerWrapper);
        }
    }, [event, handler, throttledHandler, socket])
}

export default useThrottledSocketSubscribe;
