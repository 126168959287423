import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError, AxiosResponse} from "axios";
import notificationSettingRepository from "../../repository/NotificationSettingRepository";
import {NotificationSetting} from "../../model/NotificationSetting";


const useUpdateNotificationSetting = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, NotificationSetting>(['notificationSetting', 'update', channelId], async (notificationSetting: NotificationSetting) => {
        try {
            return await notificationSettingRepository.update(channelId, notificationSetting)
        } catch (e) {
            throw e;
        }
    }, {
        onSuccess: () => {
            return queryClient.invalidateQueries(['notificationSetting', channelId], {exact: true});
        }
    });
}

export default useUpdateNotificationSetting;
