import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError, AxiosResponse} from "axios";
import managerRepository from "../../repository/ManagerRepository";


const useUpdateManagerAway = (channelId: string, managerId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, {awayReason?: string, awayUntil?: string}>(
        ['manager', 'away', 'update', channelId],
        async ({awayUntil, awayReason}) => {
            try {
                return await managerRepository.updateAway(channelId, managerId, {awayReason: awayReason, awayUntil: awayUntil});
            } catch (e) {
                throw e;
            }
        }, {
            onSuccess: () => {
                return Promise.all([
                    queryClient.invalidateQueries(['manager', 'away', 'update', channelId], {exact: true}),
                    queryClient.invalidateQueries(['manager', channelId], {exact: true})
                ]);
            }
        });
}

export default useUpdateManagerAway;
