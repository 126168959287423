import React from "react";
import errorBgUrl from "../../assets/images/error-bg.svg";
import styled from "styled-components";
import ReactDOM from "react-dom";
import starImage from "../../assets/images/startman.svg";
import logo from "../../assets/images/swing_chat_logo.png";
import {Button} from "react-bootstrap";
import {useIntl} from "react-intl";
import electronRuntime from "../../core/electronRuntime";

const View500: React.FC<{reset: () => void}> = (props) => {
    const intl = useIntl();

    const onClickReturnHome = () => {
        localStorage.removeItem('default_channel');
        localStorage.removeItem('LCM');
        localStorage.removeItem('LLC');
        localStorage.removeItem('redirect');

        props.reset();

        if (electronRuntime) {
            electronRuntime.send('loadMain', 'ping');
        }
        else {
            window.location.href = '/';
        }
    };

    return ReactDOM.createPortal((
        <ErrorViewWrapper>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xxl-4 col-lg-5">
                        <div className="card">
                            <div className="card-header py-4 d-flex align-items-center justify-content-center bg-primary">
                                <img src={logo} alt="logo" height="30" className="mr-1"/>
                                <h3 className="m-0 text-white mt-1">
                                    스윙챗
                                </h3>
                            </div>

                            <div className="card-body p-4">
                                <div className="text-center">
                                    <img src={starImage} height="120" alt="logo"/>

                                    {/*<h1 className="text-error mt-4">5<i className="mdi mdi-emoticon-sad"/>0</h1>*/}
                                    <p className="text-start text-muted mt-3 text-pre font-13">{intl.formatMessage({id: 'e000010'})}</p>

                                    <Button variant="info" onClick={onClickReturnHome}>
                                        <i className="mdi mdi-reply me-1"/>
                                        홈으로 돌아가기
                                    </Button>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </ErrorViewWrapper>
    ), document.getElementById('error-portal')!);
};


const ErrorViewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  height: 100%;
  
  background-image: url(${errorBgUrl});
  background-size: cover;
  background-position: center;
`;

export default View500;