import React from "react";
import styled from "styled-components";


const FloatingToolbar = React.memo<{children: React.ReactNode, placement?: 'top' | 'center' }>(({children, placement}) => {
    if (placement === 'center') {
        return (
            <FloatingToolbarCenterStyle className="floating-toolbar">
                {children}
            </FloatingToolbarCenterStyle>
        );
    } else {
        return (
            <FloatingToolbarTopStyle className="floating-toolbar">
                {children}
            </FloatingToolbarTopStyle>
        );
    }
});

const FloatingToolbarTopStyle = styled.div`
  display: none;
  position: absolute;
  right: 20px;
  top: -10px;
  z-index: 1000;
  background-color: #ffffff;
  align-self: center;
  box-shadow: 0 4px 12px 0 rgba(0,0,0,0.2), 0 0 0 1px rgba(0,0,0,0.03);
  border-radius: 5px;
`;

const FloatingToolbarCenterStyle = styled.div`
  display: none;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1000;
  background-color: #ffffff;
  align-self: center;
  box-shadow: 0 4px 12px 0 rgba(0,0,0,0.2), 0 0 0 1px rgba(0,0,0,0.03);
  border-radius: 5px;
`;

export default FloatingToolbar;