import React, {useMemo, useRef} from "react";
import styled from "styled-components";

import {LiveChat} from "../../model/livechat";
import {useIntl} from "react-intl";
import {useDialog} from "../../hook/useDialog";
import liveChatRepository from "../../repository/LiveChatRepository";
import useManager from "../../query/manager/useManager";
import Avatar from "../Avatar";
import kakaotalkIcon from "../../assets/images/kakaotalk_logo.png";
import instagramIcon from "../../assets/images/instagram_logo.png";
import facebookMessengerIcon from "../../assets/images/facebook_messenger_logo.png";
import navertalkIcon from "../../assets/images/navertalk_logo.png";
import lineIcon from "../../assets/images/line_logo.png";
import useModal from "../../hook/useModal";
import LiveChatSettingModal, {LIVECHAT_SETTING_MODAL_KEY} from "./LiveChatSettingModal";
import classNames from "classnames";
import {CenteredIcon} from "../UnstyledBootstrap";
import useTooltip from "../../hook/useTooltip";
import {Dropdown} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import {WEB_HOST} from "../../core/variables";
import toast from "react-hot-toast";
import useUpdateLiveChatState from "../../query/liveChat/useUpdateLiveChatState";
import useUpdateLiveChatFollowers from "../../query/liveChat/useUpdateLiveChatFollowers";

const EXPOSED_MANAGER_AVATAR_CNT = 3

const LiveChatTop: React.FC<{liveChat: LiveChat, fullSize?: boolean}> = ({liveChat}) => {
    const intl = useIntl();
    const dialog = useDialog();
    const modal = useModal();
    const location = useLocation();

    const onlineTooltip = useTooltip(liveChat.user.online ? intl.formatMessage({id: 'i000333'}) : intl.formatMessage({id: 'i000334'}));

    const thisRef = useRef<HTMLDivElement>(null);

    const {mutate: updateLiveChatState, isLoading: updatingState} = useUpdateLiveChatState(liveChat.channelId, liveChat._id);
    const {mutate: updateLiveChatFollowers, isLoading: updatingFavorite} = useUpdateLiveChatFollowers(liveChat.channelId, liveChat._id);

    const {data: manager} = useManager(liveChat.channelId);

    const isFavorite = useMemo(() => {
        return manager ? liveChat.followers.includes(manager!.userId) : false
    }, [liveChat, manager])

    const joined = useMemo(() => {
        return liveChat.managers.some(m => m.userId === manager?.userId);
    }, [liveChat, manager]);

    const showActions = useMemo(() => {
        if (liveChat.canceled) return false;
        if (!joined && liveChat.state !== "expired") return false;

        switch (liveChat.state) {
            case "unassigned":
                return true;
            case "assigned":
                return true;
            case "responding":
                return true;
            case "holding":
                return true;
            case "expired":
                return true;
            case "solved":
                return true;
            case "closed":
                return true;
        }
    }, [liveChat, joined]);

    const showCloseButton = useMemo(() => {
        // 취소된 경우에는 종료 버튼 노출 안함
        if (liveChat.canceled) return false;
        if (!joined && liveChat.state !== "expired") return false;

        switch (liveChat.state) {
            case "unassigned":
                return true;
            case "assigned":
                return true;
            case "responding":
                return true;
            case "holding":
                return true;
            case "expired":
                return true;
            case "solved":
                return false;
            case "closed":
                return false;
        }
    }, [liveChat, joined]);

    const showHoldButton = useMemo(() => {
        // 취소된 경우에는 보류 버튼 노출 안함
        if (liveChat.canceled) return false;
        if (!joined) return false;

        switch (liveChat.state) {
            case "unassigned":
                return false;
            case "assigned":
                return false;
            case "responding":
                return true;
            case "holding":
                return true;
            case "expired":
                return false;
            case "solved":
                return false;
            case "closed":
                return false;
        }
    }, [liveChat, joined]);

    const showCloseButtonForUnassigned = useMemo(() => {
        return liveChat.state === 'unassigned' && liveChat.managers.length < 1;
    }, [liveChat]);

    const isHolding = useMemo(() => {
        return liveChat.state === 'holding';
    }, [liveChat]);

    const managerChatLink = useMemo(() => {
        return `${WEB_HOST}${location.pathname}`;
    }, [location.pathname]);

    const onClickSolvedBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        if (updatingState) {
            return;
        }

        dialog.open({
            content: intl.formatMessage({id: 'i000011'}),
            variant: 'primary',
            confirmText: intl.formatMessage({id: 'i100029'}),
            cancelText: intl.formatMessage({id: 'i100030'}),
            onConfirm: async () => {
                const state = (liveChat.state === 'expired' || liveChat.state === 'unassigned') ? 'closed' : 'solved';
                const sendMessageTrigger = (liveChat.state === 'responding' || liveChat.state === 'holding') && !!manager;

                updateLiveChatState({state, sendMessageTrigger}, {
                    onSuccess: async () => {
                        try {
                            if (sendMessageTrigger) {
                                await liveChatRepository.requestSatisfactionInfo(liveChat.channelId, liveChat._id);
                            }
                        } catch (e) {

                        }
                    },
                    onError: () => {
                        toast.error(intl.formatMessage({id: 'i000217'}));
                    }
                });
            }
        });
    };

    const onClickHoldBtn = async (e: React.MouseEvent) => {
        e.preventDefault();

        if (updatingState) {
            return;
        }

        const state = liveChat.state !== 'holding' ? 'holding' : 'responding';
        updateLiveChatState({state}, {
            // onSuccess: (response) => {
            //     liveChatsHandler.updateLiveChat(response.data.result);
            // },
            onError: () => {
                toast.error(intl.formatMessage({id: state === 'holding' ? 'i000228' : 'i000229'}));
            }
        });
    };

    // const onClickFullSize = (e: React.MouseEvent) => {
    //     e.preventDefault();
    //
    //     ReactTooltip.hide();
    //
    //     if (fullSize) {
    //         history.replace(`/channels/${liveChat.channelId}/livechats/${liveChat._id}`);
    //     }
    //     else {
    //         history.replace(`/channels/${liveChat.channelId}/livechats/${liveChat._id}/full`);
    //     }
    // }

    const chatType = useMemo(() => {
        return liveChat.type || 'web'
    }, [liveChat])

    const openSettingModal = (content: string) => {
        modal.open(LIVECHAT_SETTING_MODAL_KEY, {tapContent: content})
    }

    const toggleFavorite = async (e: React.MouseEvent) => {
        e.preventDefault();

        if (updatingFavorite) return;

        updateLiveChatFollowers(!isFavorite, {
            onError: () => {
                toast.error(intl.formatMessage({id: isFavorite ? 'i100090' : 'i100089'}));
            }
        });
    };

    const onClickUserName = (e: React.MouseEvent) => {
        e.preventDefault();

        openSettingModal('user');
    };

    const onClickManagerChatLink = (e: React.MouseEvent) => {
        e.preventDefault();
        navigator.clipboard.writeText(managerChatLink).then(() => {
            toast.success(intl.formatMessage({id: 'i000352'}));
        });
    }

    const onClickChatUserChatLink = async (e: React.MouseEvent) => {
        e.preventDefault();

        try {
            const { data } = await liveChatRepository.getUserChatLink(liveChat.channelId, liveChat._id);

            if (data.result === null) {
                toast.error(intl.formatMessage({id: "i200079"}));
                return;
            }

            navigator.clipboard.writeText(data.result).then(() => {
                toast.success(intl.formatMessage({id: 'i000353'}));
            });
        } catch (e) {
            toast.error(intl.formatMessage({id: "i200080"}));
        }
    };

    return (
        <>
            <LiveChatTopStyle ref={thisRef}>
                <CenteredIcon size={18}
                              className={classNames('mdi mdi-circle-medium font-18 ml-1', {'text-success': liveChat.user.online, 'text-muted': !liveChat.user.online})}
                              ref={onlineTooltip.setTriggerRef}
                />
                {onlineTooltip.element}
                <section role="button" className="customer-info" onClick={onClickUserName}>
                    <Avatar size={24} name={liveChat.user.name || liveChat.user.alias} className="chat-avatar mr-1" />
                    <span>
                        {liveChat.user.name || liveChat.user.alias}
                    </span>
                    {(chatType === 'kakaotalk' || chatType === 'sangdamtalk') && <img className="chat-type" src={kakaotalkIcon} alt="kakaotalk" />}
                    {chatType === 'instagram' && <img className="chat-type" src={instagramIcon}  alt="instagrem"/>}
                    {chatType === 'facebook' && <img className="chat-type" src={facebookMessengerIcon} alt="facebook" />}
                    {chatType === 'navertalk' && <img className="chat-type" src={navertalkIcon} alt="navertalk" />}
                    {chatType === 'line' && <img className="chat-type" src={lineIcon} alt="line" />}
                </section>


                <section className="action-buttons d-flex justify-content-end align-items-center flex-grow-1 space">
                    <Dropdown>
                        <Dropdown.Toggle as="div" bsPrefix="unused" className="mr-1" style={{width: '24px', height: '24px'}}>
                            <CenteredIcon size={24} className="mdi mdi-link-variant" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={onClickManagerChatLink}>
                                {intl.formatMessage({id: 'i000350'})}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={onClickChatUserChatLink}>
                                {intl.formatMessage({id: 'i000351'})}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <CenteredIcon role="button"
                                  size={24}
                                  className={classNames('mdi mr-1', {'mdi-star': isFavorite, 'mdi-star-outline': !isFavorite})}
                                  onClick={toggleFavorite}
                    />
                    {showCloseButtonForUnassigned && <CenteredIcon size={24} role="button" className="mdi mdi-exit-to-app mr-1" onClick={onClickSolvedBtn}/>}

                    {showActions && (
                        <>
                            {showHoldButton && <CenteredIcon size={24}
                                                             role="button"
                                                             className={classNames('mdi mr-1', {'mdi-lock': isHolding, 'mdi-lock-outline': !isHolding})}
                                                             onClick={onClickHoldBtn} />}
                            {showCloseButton && <CenteredIcon size={24} role="button" className="mdi mdi-exit-to-app mr-1" onClick={onClickSolvedBtn}/>}

                            {joined &&
                                <div role="button" className="manager-status" onClick={() => openSettingModal('member')}>
                                    <StackedAvatarWrapper avatarSize={liveChat.managers.length > EXPOSED_MANAGER_AVATAR_CNT ?  EXPOSED_MANAGER_AVATAR_CNT : liveChat.managers.length}>
                                        {liveChat.managers.slice(0, EXPOSED_MANAGER_AVATAR_CNT).map((m, i) => (
                                            <StackedAvatar key={m._id} size={22} name={m.name} className="chat-avatar" index={i}/>
                                        ))}
                                    </StackedAvatarWrapper>
                                    {liveChat.managers.length > 0 && <span className="mr-1">{liveChat.managers.length}</span>}
                                </div>
                            }
                        </>
                    )}
                </section>
            </LiveChatTopStyle>
            <LiveChatSettingModal liveChat={liveChat}
                                  isFavorite={isFavorite}
                                  onClickHoldBtn={onClickHoldBtn}
                                  toggleFavorite={toggleFavorite}
                                  activeHoldBtn={updatingState}
                                  showHold={showHoldButton}
                                  showClose={showCloseButton}
            />
        </>
    );
};

const StackedAvatarWrapper = styled.div<{avatarSize: number}> `
  height: 24px;
  width: ${p => 20 + p.avatarSize * 14}px;
  position: relative;
`
const StackedAvatar = styled(Avatar)<{index: number}> `
  z-index: ${p => 10 - p.index};
  position: absolute;
  left: ${p => 15 * p.index}px;
  top: 0;
`

const LiveChatTopStyle = styled.div `
  margin-bottom: 2px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0.375rem;
  flex: 0 0 50px;
  //box-shadow: var(--panel-box-shadow);
  border-bottom: 1px solid rgba(212, 213, 215, 0.8);
  border-right: 1px solid rgba(212, 213, 215, 0.8);
  
  img.chat-type {
    width: 15px;
    height: 15px;
    margin-left: 0.375rem;
  }
  
  section.customer-info {
    padding: 0.375rem;
    border-radius: 0.25rem;
    flex-grow: 0;
    display: flex;
    align-items: center;
  }

  section.customer-info:hover {
    background-color: #F4F5F7;
  }
  
  .action-buttons {
    .mdi {
      font-size: 24px;
    }

    .mdi:hover {
      color: var(--ct-info);
    }
    
    .mdi-star {
      color: var(--ct-yellow);
    }
    
    .mdi-lock {
      color: var(--ct-red);
    }
    
  }
  .manager-status {
    //padding: 0.375rem;
    padding: 2px;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    border: 1px #a7b1b6 solid;
    //height: 28px;
    
    &:hover {
      background-color: #f4f5f7;
    }
  }
`


export default LiveChatTop;
