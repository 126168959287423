import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
    BrowserRouter,
    createRoutesFromChildren,
    HashRouter as Router,
    matchRoutes,
    useLocation,
    useNavigationType
} from "react-router-dom";
import axios from "axios";
import "./assets/css/bs-custom.scss";
import "./assets/css/style.scss";
import "./assets/css/tooltip.css";
import {APP_TITLE, APP_VERSION, SENTRY_DNS, WEB_VERSION} from "./core/variables";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {configure} from "mobx";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {RecoilRoot} from "recoil";
import reportWebVitals from "./reportWebVitals";

import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {isAxiosNetworkError, toLiveChatError} from "./error";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";

configure({enforceActions: 'never'});

axios.defaults.withCredentials = true;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            useErrorBoundary: true,
            // networkMode: 'always',
            // refetchOnReconnect: true,
            retry: (failureCount, error) => {
                if (isAxiosNetworkError(error)) {
                    return true
                }
                const liveChatError = toLiveChatError(error);
                return liveChatError.code !== '401' && liveChatError.code !== '404' && failureCount < 2;
            },
        }
    }
});

Sentry.init({
    dsn: SENTRY_DNS,
    release: `${APP_VERSION}(${WEB_VERSION})`,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    environment: process.env.NODE_ENV,
    tracesSampleRate: 0.3,
    normalizeDepth: 6,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(React.useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes)
        }),
        new Sentry.Integrations.Breadcrumbs({ console: true })
    ]
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

if (process.env.REACT_APP_ROUTER === 'hash') {
    root.render(
        <React.StrictMode>
            <HelmetProvider>
                <Helmet>
                    <meta name="description" content={APP_TITLE} />
                    <title>{APP_TITLE}</title>
                </Helmet>
                <QueryClientProvider client={queryClient}>
                    <RecoilRoot>
                        <Router>
                            <App/>
                        </Router>
                    </RecoilRoot>
                    <ReactQueryDevtools initialIsOpen={false} position="bottom-right"/>
                </QueryClientProvider>
            </HelmetProvider>
        </React.StrictMode>
    );
    // root.render(
    //     <HelmetProvider>
    //         <Helmet>
    //             <meta name="description" content={APP_TITLE} />
    //             <title>{APP_TITLE}</title>
    //         </Helmet>
    //         <QueryClientProvider client={queryClient}>
    //             <RecoilRoot>
    //                 <Router>
    //                     <App/>
    //                 </Router>
    //             </RecoilRoot>
    //         </QueryClientProvider>
    //     </HelmetProvider>
    // );
}
else {
    // root.render(
    //     <React.StrictMode>
    //         <HelmetProvider>
    //             <Helmet>
    //                 <meta name="description" content={APP_TITLE} />
    //                 <title>{APP_TITLE}</title>
    //             </Helmet>
    //             <QueryClientProvider client={queryClient}>
    //                 <RecoilRoot>
    //                     <BrowserRouter>
    //                         <App/>
    //                     </BrowserRouter>
    //                 </RecoilRoot>
    //             </QueryClientProvider>
    //         </HelmetProvider>
    //     </React.StrictMode>
    // );
    root.render(
        <HelmetProvider>
            <Helmet>
                <meta name="description" content={APP_TITLE} />
                <title>{APP_TITLE}</title>
            </Helmet>
            <QueryClientProvider client={queryClient}>
                <RecoilRoot>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </RecoilRoot>
                <ReactQueryDevtools initialIsOpen={false} position="bottom-right"/>
            </QueryClientProvider>
        </HelmetProvider>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
