import {LIVE_CHAT_API_HOST} from "../core/variables";
import oauthAxios from "../core/oauthAxios";


class CategoryRepository {
    async find(channelId: string, name?: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/categories`, {
            params: {name}
        });
    }

    async create(channelId: string, name: string) {
        return oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/categories`, {
            name
        });
    }

    async update(channelId: string, categoryId: string, name: string) {
        return oauthAxios.put(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/categories/${categoryId}`, {
            name
        });
    }

    async delete(channelId: string, categoryId: string) {
        return oauthAxios.delete(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/categories/${categoryId}`);
    }

    async summary(channelId: string, name?: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/summary/categories`, {
            params: {name}
        });
    }

}

export default new CategoryRepository();