import oauthAxios from "../core/oauthAxios";
import {AnnouncementCreate} from "../model/announcement";
import {LIVE_CHAT_API_HOST} from "../core/variables";

class AnnouncementRepository {
    async create(channelId: string, body: AnnouncementCreate) {
        return oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/announcements`, body)
    }

    async find(channelId: string, search?: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/announcements`, {
            params: { search }
        })
    }
}

export default new AnnouncementRepository();
