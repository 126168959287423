
type Unsubscribe = () => void;
type Listener = (event: any, ...args: any[]) => void;

interface ElectronRuntime {
    send: (channel: string, data: any) => void
    subscribe: (channel: string, listener: Listener) => Unsubscribe
    invoke: (channel: string, args?: any) => Promise<any>
}

const electronRuntime = (window as any).electronRuntime as ElectronRuntime;

export default electronRuntime;
