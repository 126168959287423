import React, {useCallback, useMemo} from "react";
import styled from "styled-components";
import {useIntl} from "react-intl";
import LeftContainer from "../LeftContainer";
import SimpleBar from "simplebar-react";
import {LiveChatContext} from "../../model/livechat";
import classNames from "classnames";
import useLiveChatContext from "../../hook/useLiveChatContext";
import useLiveChatTotal from "../../query/liveChat/useLiveChatTotal";
import useUnreadMessages from "../../query/message/useUnreadMessages";
import {useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import chatSearchTrackingState from "../../recoil/chatSearchTracking";
import Hotkeys from "../Hotkeys";
import platformState from "../../recoil/platform";
import useSocketIOSubscribe from "../../socket/useSocketIOSubscribe";
import {SocketEvent} from "../../socket";
import useUserPermissions from "../../query/useUserPermissions";


const ChatMenu: React.FC<{channelId: string, managerUserId: string}> = ({channelId, managerUserId}) => {
    const intl = useIntl();
    const navigate = useNavigate();

    const {data: permissions} = useUserPermissions(channelId);
    const {update: updateLiveChatContext} = useLiveChatContext(channelId);

    const {data: total, refetch: refetchLiveChatTotal} = useLiveChatTotal(channelId, managerUserId);
    const {data: unreadMessages} = useUnreadMessages(channelId);

    const chatSearchTracking = useRecoilValue(chatSearchTrackingState);

    const moveToSearchView = useCallback(() => {
        navigate(`/channels/${channelId}/livechats/search`);
        updateLiveChatContext(LiveChatContext.SEARCH);
    }, [navigate, updateLiveChatContext, channelId]);

    const inboxCount = useMemo(() => {
        if (!total) {
            return 0;
        }
        return total.managerAssignedCount + total.managerRespondingCount + total.managerExpiredCount + total.managerHoldingCount;
    }, [total]);

    const allInboxCount = useMemo(() => {
        if (!total) {
            return 0;
        }
        return total.assignedCount + total.respondingCount + total.expiredCount + total.holdingCount;
    }, [total]);

    const unreadCount = useMemo(() => {
        return Object.values(unreadMessages ?? {}).filter(messages => messages.length > 0).length;
    }, [unreadMessages]);

    const unassignedCount = useMemo(() => {
        return (total?.unassignedCount ?? 0) - (total?.requestedOutsideWorkingTimesCount ?? 0);
    }, [total]);

    useSocketIOSubscribe(SocketEvent.RECONNECT, async () => refetchLiveChatTotal().finally());

    return (
        <LeftContainer title={intl.formatMessage({id: 'm02'})}>
            <div className="scroll-wrapper mt-2">
                <SimpleBar className="h-100">
                    <ChatMenuItem channelId={channelId} context={LiveChatContext.SEARCH} name={intl.formatMessage({id: 'm0201'})} />
                    {chatSearchTracking.isTracking &&
                        <BackToSearchMenuItemStyle onClick={() => moveToSearchView()}>
                            <div className='chat-menu-item' role="button">
                                <span>{intl.formatMessage({id: "m0202"})}</span>
                            </div>
                        </BackToSearchMenuItemStyle>
                    }
                    <hr className="my-2 me-2 ms-1" />
                    <ChatMenuItem channelId={channelId} context={LiveChatContext.UNASSIGNED} name={intl.formatMessage({id: 'm0203'})} badge={unassignedCount} />
                    <ChatMenuItem channelId={channelId} context={LiveChatContext.INBOX} name={intl.formatMessage({id: 'm0204'})} badge={inboxCount} />
                    <ChatMenuItem channelId={channelId} context={LiveChatContext.UNREAD} name={intl.formatMessage({id: 'm0205'})} badge={unreadCount} />
                    <ChatMenuItem channelId={channelId} context={LiveChatContext.FAVORITE} name={intl.formatMessage({id: 'm0207'})} badge={total?.favoriteCount} />
                    <hr className="my-2 me-2 ms-1" />
                    <ChatMenuItem channelId={channelId} context={LiveChatContext.ALL_INBOX} name={intl.formatMessage({id: 'm0206'})} badge={allInboxCount} />
                    {!!total?.requestedOutsideWorkingTimesCount && (
                        <ChatMenuItem channelId={channelId} context={LiveChatContext.REQUESTED_OUTSIDE_WORKING_TIMES} name={intl.formatMessage({id: 'm0208'})} badge={total?.requestedOutsideWorkingTimesCount} />
                    )}
                    {permissions?.includes('setting:livechat') &&
                        <ChatMenuItem channelId={channelId} context={LiveChatContext.DASHBOARD} name={intl.formatMessage({id: 'i100119'})}/>
                    }
                </SimpleBar>
            </div>
        </LeftContainer>
    );
};

const ChatMenuItem: React.FC<{channelId: string, context: LiveChatContext, name: string, badge?: number}> = ({channelId, context, name, badge}) => {
    // const [liveChatContext, setLiveChatContext] = useRecoilState(liveChatContextState);
    // const [, setChatMenu] = useLocalStorageValue('LCM', {[channelId]: LiveChatContext.INBOX}, {storeDefaultValue: true});
    const navigate = useNavigate()

    const {liveChatContext, chatMenuSegment, update: updateLiveChatContext} = useLiveChatContext(channelId);
    const platform = useRecoilValue(platformState);

    const specialHotKey = useMemo(() => {
        return platform === 'darwin' ? 'Meta' : 'Ctrl';
    }, [platform]);

    const active = useMemo(() => {
        return liveChatContext === context;
    }, [context, liveChatContext]);

    const onClick = useCallback(async (e: React.MouseEvent) => {
        e.preventDefault();

        if (liveChatContext === context) return

        if (context === LiveChatContext.SEARCH) {
            navigate(`/channels/${channelId}/livechats/search`)
        } else if (context === LiveChatContext.DASHBOARD) {
            navigate(`/channels/${channelId}/livechats/dashboard`)
        } else {
            navigate(`/channels/${channelId}/livechats`);
        }

        updateLiveChatContext(context, chatMenuSegment);
    }, [channelId, navigate, chatMenuSegment, context, liveChatContext, updateLiveChatContext]);

    const moveToSearchView = useCallback(() => {
        if (liveChatContext !== LiveChatContext.SEARCH) {
            navigate(`/channels/${channelId}/livechats/search`);
            updateLiveChatContext(LiveChatContext.SEARCH);
        }
    }, [liveChatContext, navigate, updateLiveChatContext, channelId]);

    return (
        <ChatMenuItemStyle>
            <div className={classNames('chat-menu-item', {'active': active})} role="button" onClick={onClick}>
                <span>{name}</span>
                {(!!badge && badge > 0) && <span className="chat-count">{badge}</span>}
                {context === LiveChatContext.SEARCH &&
                    <Hotkeys keys={`${specialHotKey}+Shift+F`} callbackFn={moveToSearchView}/>
                }
            </div>
        </ChatMenuItemStyle>
    );
};


const ChatMenuItemStyle = styled.div`
  //color: var(--left-container-text-color);
  background-color: transparent;
  //position: relative;
  //margin-bottom: 4px;
  //margin: 0 0.4rem 2px 0.4rem;
  
  .chat-menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0.5rem 0.5rem;
    //padding: 0 0.375rem;
    //color: var(--left-container-text-color);
    width: 100%;
    line-height: 1.1rem;
    border: 1px solid transparent;

    span {
      display: block;
      font-size: 15px;
      font-weight: 600;
      color: var(--left-container-text-color);
    }

    &.active, &:hover {
      border-radius: 8px;
      background-color: var(--menu-active-background-color);
      border: 1px solid rgba(212, 213, 215, 0.6);
      //border-bottom: 1px solid rgba(212, 213, 215, 0.8);
      //border-left: 1px solid rgba(212, 213, 215, 0.8);
      //box-shadow: inset 1px 0 2px 0 rgba(212, 213, 215, 0.4);
    }

    //&.active {
    //  border-radius: 0.25rem 0 0 0.25rem;
    //  background-color: var(--menu-active-background-color);
    //  border-top: 1px solid rgba(212, 213, 215, 0.8);
    //  border-bottom: 1px solid rgba(212, 213, 215, 0.8);
    //  border-left: 1px solid rgba(212, 213, 215, 0.8);
    //  box-shadow: inset 1px 0 2px 0 rgba(212, 213, 215, 0.4);
    //}
    
    //&:not(.active) {
    //    &:hover {
    //      border-radius: 0.25rem 0 0 0.25rem;
    //      background-color: var(--menu-active-background-color);
    //    }
    //}
  }
`

const BackToSearchMenuItemStyle = styled(ChatMenuItemStyle)`
  margin-top: 0.25rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
`

export default ChatMenu;