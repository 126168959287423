import runCodeSnippetRepository from "../../repository/RunCodeSnippetRepository";
import {CodeSnippetObject} from "../../model/codeSnippet";
import {useQuery} from "@tanstack/react-query";
import { LiveChatError } from "../../error";

const getData = async (swingChatChannelId: string, memberId: string) => {
    try {
        const { data } = await runCodeSnippetRepository.runCommerceOrder(swingChatChannelId, memberId)
        return data.result;
    } catch (e) {
        return undefined
    }
}

const useCommerceOrderCodeSnippet = (swingChatChannelId: string, memberId: string) => {
    return useQuery<CodeSnippetObject|CodeSnippetObject[], LiveChatError>(
        ['code-snippet', 'commerce-orders', swingChatChannelId, memberId],
        async () => getData(swingChatChannelId, memberId),
        {suspense: false, enabled: !!memberId}
    )
}

export default useCommerceOrderCodeSnippet;