import React, {useEffect, useMemo} from "react";
import {useIntl} from "react-intl";
import {useInView} from "react-intersection-observer";
import {ListGroup, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import moment from "moment";
import styled from "styled-components";
import {LiveChat} from "../../model/livechat";
import useUserLiveChats from "../../query/liveChat/useUserLiveChats";
import SimpleBar from "simplebar-react";
import FullAreaSpinner from "../FullAreaSpinner";
import useSocketIOSubscribe from "../../socket/useSocketIOSubscribe";
import {SocketEvent} from "../../socket";


const UserLiveChatListCard: React.FC<UserLiveChatListCardProps> = ({channelId, chatUserId, currentLiveChatId}) => {
    const [bottomRef, bottomInView] = useInView({threshold: 0});

    const userLiveChatsQuery = useUserLiveChats(channelId, chatUserId);

    const liveChats = useMemo(() => {
        return userLiveChatsQuery.data?.pages?.reduce((acc: LiveChat[], cur) => {
            return [...acc, ...cur.result];
        }, []) ?? [];
    }, [userLiveChatsQuery.data]);

    const height = useMemo(() => {
        const length = liveChats.length;
        const itemHeight = 34;
        if (length === 0) {
            return null;
        } else if (length < 5) {
            return itemHeight * length + 40;
        } else {
            return itemHeight * 5 + 40;
        }
    }, [liveChats]);

    useEffect(() => {
        (async () => {
            if (bottomInView && userLiveChatsQuery.hasNextPage) {
                await userLiveChatsQuery.fetchNextPage();
            }
        })();
    }, [bottomInView, userLiveChatsQuery]);

    useSocketIOSubscribe(SocketEvent.RECONNECT, async () => userLiveChatsQuery.refetch().finally());

    return (
        <ListGroup variant="flush"
                   style={{height: height ? `${height}px` : undefined}}
                   className="overflow-hidden height-transition"
        >
            {userLiveChatsQuery.status === 'loading' && (
                <FullAreaSpinner size="sm" />
            )}
            {userLiveChatsQuery.isSuccess && (
                <SimpleBar style={{minHeight: '100%'}}>
                    {liveChats.map(liveChat => (
                        <UserLiveChatItemFragment key={liveChat._id} liveChat={liveChat} currentLiveChatId={currentLiveChatId} />
                    ))}
                    {userLiveChatsQuery.hasNextPage && (
                        <ListGroup.Item className="px-0" ref={bottomRef}>
                            <div style={{height: '40px'}}>
                                {userLiveChatsQuery.isFetchingNextPage && (
                                    <Spinner size="sm" animation="border" />
                                )}
                            </div>
                        </ListGroup.Item>
                    )}
                </SimpleBar>
            )}
        </ListGroup>
    );
};


const UserLiveChatItemFragment: React.FC<UserLiveChatItemProps> = ({liveChat}) => {

    const intl = useIntl();

    const manager = useMemo(() => {
        const assignedManager = liveChat.managers.filter(manager => manager.userId === liveChat.assigneeId);
        if (assignedManager.length > 0) {
            return assignedManager[0];
        }

        return null;
    }, [liveChat]);

    const description = useMemo(() => {
        let result = ''
        if (manager) {
            result += manager.name
        }

        let liveChatStatus;
        switch (liveChat.state) {
            case 'unassigned':
                liveChatStatus = intl.formatMessage({id: 'i000061'})
                break;
            case 'holding':
                liveChatStatus = intl.formatMessage({id: 'i000062'})
                break;
            case 'assigned':
                liveChatStatus = intl.formatMessage({id: 'i000085'})
                break;
            case 'responding':
                liveChatStatus = intl.formatMessage({id: 'i000063'})
                break;
        }
        if (liveChatStatus) {
            result += `[${liveChatStatus}]`
        }
        return result
    }, [intl, manager, liveChat.state])

    return (
        <UserLiveChatItemFragmentStyle key={liveChat._id} className="px-0 border-0">
            <Link to={`/channels/${liveChat.channelId}/livechats/${liveChat._id}`}
                  className='d-flex align-items-center text-primary'
            >
                {/*<small>{moment.utc(liveChat.createdAt).local().format('lll')} {description}</small>*/}
                <small>{moment.utc(liveChat.createdAt).local().format('lll')}&nbsp;&nbsp;&nbsp;{description}</small>

            </Link>
        </UserLiveChatItemFragmentStyle>
    );
};


const UserLiveChatItemFragmentStyle = styled(ListGroup.Item) `
  padding: 0.5rem;

  &:hover {
    text-decoration: underline;
  }
`

type UserLiveChatListCardProps = {
    channelId: string;
    chatUserId: string;
    currentLiveChatId: string;
};

type UserLiveChatItemProps = {
    liveChat: LiveChat;
    currentLiveChatId: string;
};

export default UserLiveChatListCard;