import React, {useCallback} from "react";
import {Dropdown} from "react-bootstrap";
import {SortType} from "../../model/chatSearch";
import {ChatSearchSortingDropdownToggleStyle} from "./ChatSearchFilterStyle";
import {useIntl} from "react-intl";

type ChatSearchResultSortingDropdownProps = {
    sortType: SortType
    setSortType: (manner: SortType) => void
}

const ChatSearchResultSortingDropdown: React.FC<ChatSearchResultSortingDropdownProps> = (props) => {
    const { sortType, setSortType } = props;
    const intl = useIntl();

    const sortTypeText = useCallback((type: SortType) => {
        switch (type) {
            case SortType.MOST_RELEVANT:
                return intl.formatMessage({id: "i210030"})
            case SortType.LATEST:
                return intl.formatMessage({id: "i210024"});
            case SortType.EARLIEST:
                return intl.formatMessage({id: "i210025"});
            case SortType.PRIORITY_DESC:
                return intl.formatMessage({id: "i210026"});
            case SortType.PRIORITY_ASC:
                return intl.formatMessage({id: "i210031"})
        }
    }, [intl])

    return (
        <Dropdown style={{marginLeft: "1rem", alignSelf: "end"}}>
            <Dropdown.Toggle as={SortingDropdownWrapper}>
                <ChatSearchSortingDropdownToggleStyle>
                    <span className="mr-1">
                        <strong>{intl.formatMessage({id: "i210027"})}</strong>
                        {sortTypeText(sortType)}
                    </span>
                    <i className="mdi mdi-chevron-down"/>
                </ChatSearchSortingDropdownToggleStyle>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {Object.values(SortType).map(type => (
                    <Dropdown.Item key={type}
                                   onClick={() => setSortType(type)}>
                        {sortTypeText(type)}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
};

const SortingDropdownWrapper = React.forwardRef<any, any>(({children, onClick}, ref) => {
    return (
        <div ref={ref} role="button" onClick={(e) => {e.preventDefault(); onClick(e)}}>
            {children}
        </div>
    )
});

export default ChatSearchResultSortingDropdown;
