import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {Button, Table} from "react-bootstrap";
import {CategorySummary} from "../../model/category";
import IconButton from "../../component/StyledButton";
import {useParams} from "react-router-dom";
import {utcToLocal} from "../../util/datetime";
import CategoryRegistrationModal from "../../component/setting/CategoryRegistraionModal";
import useModal from "../../hook/useModal";
import {useDialog} from "../../hook/useDialog";
import CategoryUpdateModal from "../../component/setting/CategoryUpdateModal";
import SettingViewWrapper from "../../component/setting/SettingViewWrapper";
import useCategorySummaries from "../../query/category/useCategorySummaries";
import useDeleteCategory from "../../query/category/useDeleteCategory";
import toast from "react-hot-toast";

const CategoryManagementView: React.FC = () => {
    const intl = useIntl();
    const modal = useModal();

    const {channelId} = useParams<{channelId: string}>();

    if (!channelId) {
        throw new Error('Required channelId path params')
    }

    const {data: categorySummaries, status: categorySummariesStatus} = useCategorySummaries(channelId);

    const isEmptyCategories = useMemo(() => {
        return categorySummariesStatus === 'success' && (!categorySummaries || categorySummaries.length < 1);
    }, [categorySummaries, categorySummariesStatus]);

    const onClickAddCategoryBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        modal.open('category-registration-modal');
    };

    return (
        <>
            <SettingViewWrapper>
                <div className="setting-title">
                    <h3>{intl.formatMessage({id: 'i000095'})}</h3>
                    <p>
                        {intl.formatMessage({id: 'i000101'})}
                    </p>
                    <Button variant="info" type="button" className="btn-rounded" onClick={onClickAddCategoryBtn}>
                        {intl.formatMessage({id: 'i000118'})}
                    </Button>
                </div>

                <Table size="sm" className="table-centered">
                    <colgroup>
                        <col width={'*'} />
                        <col width={'160px'} />
                        <col width={'160px'} />
                        <col width={'140px'} />
                        <col width={'50px'} />
                        <col width={'50px'} />
                    </colgroup>
                    <thead>
                    <tr>
                        <th>{intl.formatMessage({id: 'i000126'})}</th>
                        <th>{intl.formatMessage({id: 'i000131'})}</th>
                        <th>{intl.formatMessage({id: 'i000132'})}</th>
                        <th>{intl.formatMessage({id: 'i000133'})}</th>
                        <th>{intl.formatMessage({id: 'i000029'})}</th>
                        <th>{intl.formatMessage({id: 'i000034'})}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {categorySummariesStatus === 'success' && categorySummaries?.map(summary => (
                        <CategoryRowFragment key={summary._id} categorySummary={summary} />
                    ))}
                    {isEmptyCategories && (
                        <tr>
                            <td colSpan={6} className="text-muted">{intl.formatMessage({id: 'i000130'})}</td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </SettingViewWrapper>
            <CategoryRegistrationModal channelId={channelId} />
            <CategoryUpdateModal channelId={channelId} />
        </>
    );
};


const CategoryRowFragment: React.FC<CategoryRowFragmentProps> = ({categorySummary}) => {
    const dialog = useDialog();
    const intl = useIntl();
    const modal = useModal();

    const {mutate: deleteCategory} = useDeleteCategory(categorySummary.channelId);

    const onClickEditBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        modal.open('category-update-modal', {categoryId: categorySummary._id, name: categorySummary.name});
    };

    const onClickDeleteBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        dialog.open({
            title: intl.formatMessage({id: 'i000121'}),
            content: intl.formatMessage({id: 'i000122'}),
            variant: 'danger',
            onConfirm: async () => {
                deleteCategory(categorySummary._id, {
                    onError: (_) => {
                        toast.error(intl.formatMessage({id: 'e000001'}));
                    }
                })
                // try {
                //     await categoryRepository.delete(categorySummary.channelId, categorySummary._id);
                //     await Promise.all([
                //         categorySummaryStore.load(categorySummary.channelId),
                //         // categoryListStore.load(categorySummary.channelId)
                //     ]);
                // } catch (e) {
                //     alert.error(intl.formatMessage({id: 'e000001'}));
                // }
            }
        });
    }
    return (
        <tr>
            <td>{categorySummary.name}</td>
            <td>{categorySummary.liveChatCount}</td>
            <td>{categorySummary.managerCount}</td>
            <td>{utcToLocal(categorySummary.createdAt)}</td>
            <td><IconButton onClick={onClickEditBtn}><i className="mdi mdi-square-edit-outline" /></IconButton></td>
            <td><IconButton onClick={onClickDeleteBtn}><i className="mdi mdi-trash-can-outline" /></IconButton></td>
        </tr>
    );
};

type CategoryRowFragmentProps = {
    categorySummary: CategorySummary;
};


export default CategoryManagementView;
