import styled from "styled-components";

export const DashboardHelpCardStyle = styled.div`
  width: 50%;
  margin: 1.25rem;
  padding: 1.25rem;
  border: 2px solid #e7e7e7;
  border-radius: 15px;
  box-shadow: #f5f5f5 0 0 15px 5px;

  h5 {
    margin: 0;
  }

  .help-paragraph {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 1.5rem;

    i {
      font-size: 30px;
    }

    p {
      margin: 0;
      text-align: justify;
    }
  }
`;

export const TotalStatisticSummaryCardStyle = styled.div`
  width: 50%;
  height: 300px;
  margin: 1.25rem;
  display: grid;
  grid-gap: 1.25rem;
  grid-template-columns: repeat(3, 3fr);
  grid-template-rows: repeat(2, 2fr);

  .each-statistic-summary-card {
    padding: 1.25rem;
    border: 2px solid #e7e7e7;
    border-radius: 15px;
    box-shadow: #f5f5f5 0 0 15px 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h5 {
      margin: 0;
    }

    p {
      width: 130px;
      margin: 0;
      align-self: end;
      font-size: 16px;
      font-weight: bold;
      text-align: end;
    }

    div {
      color: gray !important;
      align-self: end;
    }

    &:hover {
      background-color: #f5f5f5;
      transition: background-color 0.2s linear;
    }
  }
`;

export const DiffStatisticSummaryCardStyle = styled.div`
  width: 50%;
  height: 470px;
  margin: 1.25rem;
  display: grid;
  grid-gap: 1.25rem;
  grid-template-columns: repeat(2, 2fr);
  grid-template-rows: repeat(3, 3fr);
`;

export const ChartWrapperStyle = styled.div`
  //width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;

  .chart-card {
    width: 50%;
    //max-width: 50%;
    height: 360px;
    margin: 1.25rem;
    padding: 1.25rem;
    border: 2px solid #e7e7e7;
    border-radius: 15px;
    box-shadow: #f5f5f5 0 0 20px 10px;
    display: flex;
    flex-direction: column;

    .chart-title {
      display: flex;
      justify-content: start;
      align-items: center;
    }

    h5 {
      margin: 0;
    }

    & > div:nth-child(2) {
      align-self: center;
    }
  }
`;

export const QualityStatusChartStyle = styled(ChartWrapperStyle)`
  min-width: 50%;
  
  .chart-card {
    width: 100%;
    margin: unset;
  }
`;
