import {LiveChat} from "../../model/livechat";
import {useInfiniteQuery} from "@tanstack/react-query";
import liveChatRepository from "../../repository/LiveChatRepository";
import {LiveChatError, toLiveChatError} from "../../error";
import {Page} from "../index";

export const getData = async (channelId: string, managerId: string, pageable: {since?: string, limit: number}, sortBy?: string) => {
    const _params = {
        followerId: managerId,
        sortBy: sortBy,
        since: pageable.since,
        limit: pageable.limit
    };

    try {
        const {data} = await liveChatRepository.find(channelId, _params);
        const liveChats: LiveChat[] = data.result;

        return {
            result: liveChats,
            pageable: {
                ...pageable,
                isLast: liveChats.length < pageable.limit
            }
        };
    } catch (e) {
        throw toLiveChatError(e);
    }
}


const useFavoriteLiveChats = (channelId: string, managerId: string, sortBy?: string) => {
    return useInfiniteQuery<Page<LiveChat, {since?: string}>, LiveChatError>({
        queryKey: ['liveChats', 'favorite', channelId],
        queryFn: ({pageParam = {offset: 0, limit: 50, isLast: false}}) => getData(channelId, managerId, pageParam, sortBy),
        refetchOnMount: true,
        getNextPageParam: ({pageable}, pages) => {
            if (pageable.isLast) {
                return undefined;
            }

            const lastPageResult = pages.length > 0 ? (pages[pages.length - 1].result ?? []) : [];

            return {
                since: lastPageResult.length > 0 ? lastPageResult[lastPageResult.length - 1].since : undefined,
                limit: pageable.limit,
                isLast: false
            };
        }
    });
};


export default useFavoriteLiveChats;
