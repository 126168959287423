import {AxiosError} from "axios";


export class LiveChatError extends Error {
    constructor(public code: string, message?: string, noErrorPage?: boolean) {
        super(message);
        Object.setPrototypeOf(this, LiveChatError.prototype);
        this.noErrorPage = noErrorPage;
    }

    public readonly isLiveChatError = true;
    public noErrorPage?: boolean;
}

type ErrorWithMessage = {
    message: string;
};

export const isErrorWithMessage = (error: unknown): error is ErrorWithMessage => {
    try {
        return (
            typeof error === "object" && error !== null && "message" in error && typeof (error as Record<string, unknown>).message === 'string'
        );
    } catch (e) {
        return false
    }
}

export const isAxiosError = (error: unknown): error is AxiosError => {
    try {
        return (
            typeof error === "object" && error !== null && "isAxiosError" in error && typeof (error as Record<string, unknown>).isAxiosError === 'boolean'
        );
    } catch (e) {
        return false
    }
}

export const isLiveChatError = (error: unknown): error is LiveChatError => {
    try {
        return (
            typeof error === "object" && error !== null && "isLiveChatError" in error && typeof (error as Record<string, unknown>).isLiveChatError === 'boolean'
        );
    } catch (e) {
        return false
    }
}

export const isAxiosNetworkError = (error: unknown): error is AxiosError => {
    return (
        typeof error === "object"
        && error !== null
        && error.hasOwnProperty("code")
        && (error as {code?: string})?.code === "ERR_NETWORK"
    )
}

// export const toLiveChatError = (code: string, unknownError: unknown): LiveChatError => {
//     if (isErrorWithMessage(unknownError)) {
//         return new LiveChatError(code, unknownError.message);
//     }
//
//     try {
//         return new LiveChatError(code, JSON.stringify(unknownError));
//     } catch {
//         // 순환 참조와 같이 maybeError를 stringify하는 과정에서 발생하는
//         // 에러에 대해 fallback을 제공한다
//         return new LiveChatError(code, String(unknownError));
//     }
// }


export const toLiveChatError = (unknownError: unknown, options?: {code?: string, noErrorPage?: boolean}): LiveChatError => {
    if (isAxiosError(unknownError)) {
        return new LiveChatError(String(unknownError.response?.status) ?? '500', unknownError.message, options?.noErrorPage);
    }

    if (isLiveChatError(unknownError)) {
        return unknownError;
    }

    if (isErrorWithMessage(unknownError)) {
        return new LiveChatError(options?.code ?? '500', unknownError.message, options?.noErrorPage);
    }

    try {
        return new LiveChatError(options?.code ?? '500', JSON.stringify(unknownError));
    } catch {
        // 순환 참조와 같이 maybeError를 stringify하는 과정에서 발생하는 에러에 대해 fallback을 제공한다
        return new LiveChatError(options?.code ?? '500', String(unknownError), options?.noErrorPage);
    }
}


// interface LiveChatErrorConstructor {
//     new (code: string, message?: string): LiveChatError;
//     (code: string, message?: string): LiveChatError;
//     readonly prototype: LiveChatError;
// }
// export declare var LiveChatError: LiveChatErrorConstructor;
