import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import channelRepository from "../../repository/ChannelRepository";
import {Channel} from "../../model/channel";
import {LiveChatError, toLiveChatError} from "../../error";


const useUpdateChannel = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, LiveChatError, Channel>(['channels', 'update', channelId], async (channel) => {
        try {
            return await channelRepository.update(channelId, channel)
        } catch (e) {
            throw toLiveChatError(e);
        }
    }, {
        onSuccess: () => {
            return queryClient.invalidateQueries(['channels', channelId], {exact: true})
        }
    });
}

export default useUpdateChannel;
