import jwtDecode, {JwtPayload} from "jwt-decode";
import electronRuntime from "./electronRuntime";


interface KeeperAccessToken extends JwtPayload {
    // units: string[];
    // permissions: string[];
}

export type AuthToken = {
    refreshToken?: string;
    accessToken?: string;
    idToken?: string;
    exp: number;
}

const AUTH_TOKEN: AuthToken = {exp: 0};


export const syncAuthTokenFromElectron = async () => {
    const electronAuthToken = await electronRuntime.invoke('get-auth-token');
    setAuthToken(electronAuthToken, false);
}


export const getAuthToken = () => {
    return AUTH_TOKEN;
}


export const setAuthToken = (authToken: {refreshToken?: string, accessToken?: string, idToken?: string}, toElectron: boolean) => {
    if (authToken) {
        AUTH_TOKEN.refreshToken = authToken.refreshToken;
        AUTH_TOKEN.accessToken = authToken.accessToken;
        AUTH_TOKEN.idToken = authToken.idToken;

        if (authToken.accessToken) {
            const accessTokenInfo = jwtDecode<KeeperAccessToken>(authToken.accessToken);
            AUTH_TOKEN.exp = accessTokenInfo.exp || 0;
        }
        else {
            AUTH_TOKEN.exp = 0;
        }
    }

    if (electronRuntime && toElectron) {
        electronRuntime.invoke('set-auth-token', AUTH_TOKEN).then(() => {
            console.debug('invoke set-auth-token')
        });
    }
}

export const getAccessToken = () => {
    return AUTH_TOKEN.accessToken || '';
};

export const getRefreshToken = () => {
    return AUTH_TOKEN.refreshToken;
};

export const getIdToken = () => {
    return AUTH_TOKEN.idToken;
}


// export const validExp = () => {
//     const now = new Date().getTime() / 1000;
//     return TOKEN_INFO.exp && TOKEN_INFO.exp > now;
// };
//
// export const estimateValidExp = (estimate: number) => {
//     const now = new Date().getTime() / 1000;
//     return TOKEN_INFO.exp && TOKEN_INFO.exp > (now + estimate);
// };

// export const estimateValidExp = (accessToken: string, estimate: number) => {
//     const {exp} = jwtDecode<KeeperAccessToken>(accessToken);
//     const now = new Date().getTime() / 1000;
//     return exp && exp > (now + estimate);
// };

export const clearToken = async () => {
    AUTH_TOKEN.refreshToken = undefined;
    AUTH_TOKEN.accessToken = undefined;
    AUTH_TOKEN.idToken = undefined;
    AUTH_TOKEN.exp = 0;

    if (electronRuntime) {
        await electronRuntime.invoke('set-auth-token', AUTH_TOKEN);
    }
};
