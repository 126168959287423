import React, {useCallback, useMemo, useRef, useState} from "react"
import styled from "styled-components";
import {Button, Dropdown, Form} from "react-bootstrap";
import {useIntl} from "react-intl";
import useChannel from "../../query/channel/useChannel";
import useUpdateChannel from "../../query/channel/useUpdateChannel";
import toast from "react-hot-toast";
import useConditionalAnnouncements from "../../query/conditionalAnnouncement/useConditionalAnnouncements";
import useCreateConditionalAnnouncement from "../../query/conditionalAnnouncement/useCreateConditionalAnnouncement";
import classNames from "classnames";
import {
    ConditionalAnnouncement,
    ConditionalAnnouncementCreate,
    instanceOfManagerStateTimeLimit,
    ManagerStateTimeLimit
} from "../../model/conditionalAnnouncement";
import {useNavigate} from "react-router-dom";
import useDeleteConditionalAnnouncement from "../../query/conditionalAnnouncement/useDeleteConditionalAnnouncement";
import FullAreaSpinner from "../../component/FullAreaSpinner";

const ManagerNotificationView: React.FC<{channelId: string}> = ({channelId}) => {
    const intl = useIntl()
    const navigate = useNavigate();
    const {data: channel} = useChannel(channelId);
    const {mutateAsync: updateChannel, isLoading, } = useUpdateChannel(channelId!);

    const onChangeAllowsNotification = async () => {
        if (!channel || isLoading) return

        const body = {
            ...channel,
            config: {
                ...channel.config, useTimeElapsedAnnouncement: !channel.config.useTimeElapsedAnnouncement

            }
        };

        await toast.promise(
            updateChannel(body),
            {
                loading: intl.formatMessage(({id: 'i200003'})),
                success: () => {
                    return intl.formatMessage({id: 'i000377'});
                },
                error: () => {
                    return intl.formatMessage({id: 'e000001'});
                }
            }
        )
    };

    const navigateBack = () => {
        navigate(-1)
    }
    return (
        <ManagerNotificationViewStyle>
            <div className='m-2 h-100 d-flex flex-column'>
                <div className="p-2 d-flex align-items-center">
                    <div className='d-flex align-items-center flex-grow-1'>
                        <h3 className="mr-2">
                            <i className="mdi mdi-arrow-left mr-2" role={'button'} onClick={navigateBack}/>
                            {intl.formatMessage({id: 'i100121'})}
                        </h3>
                        <Form.Check type="switch"
                                    id="allow-manager-time-elapsed-notification"
                                    disabled={isLoading}
                                    onChange={onChangeAllowsNotification}
                                    checked={channel?.config.useTimeElapsedAnnouncement}/>
                    </div>
                </div>

                <div className='p-2'>
                    <ConditionalAnnouncementList channelId={channelId}/>

                    <div className='mb-4'/>
                    <ConditionalAnnouncementForm channelId={channelId}/>

                </div>

            </div>
        </ManagerNotificationViewStyle>
    )
}

const ManagerNotificationViewStyle = styled.div `
  flex: 1 0 0;
  background-color: white;
  
  & > div {
    max-width: 1000px;
  }
`

const ConditionalAnnouncementList: React.FC<{channelId: string}> = ({channelId}) => {
    const intl = useIntl()
    const {data, isSuccess, isFetching} = useConditionalAnnouncements(channelId, {refetchOnMount: true})

    return (
        <ConditionalAnnouncementListStyle>
            <h4>
                {intl.formatMessage({id: 'i100122'})}
            </h4>

            <ul className="border rounded mb-0 p-2">
                {isSuccess && data?.length === 0 &&
                    <div className='h-100 d-flex align-items-center justify-content-center'>
                        <span className='text-muted'>{intl.formatMessage({id: 'i100133'})}</span>
                    </div>
                }
                {isFetching && data === undefined &&
                    <FullAreaSpinner animation="border" variant={'light'}/>
                }
                {data?.map((conditionalAnnouncement, index) => (
                    <ConditionalAnnouncementItem key={`conditional-announcement-item-${index}`}
                                               channelId={channelId}
                                               announcement={conditionalAnnouncement}
                    />
                ))}
            </ul>
        </ConditionalAnnouncementListStyle>
    )
}

const ConditionalAnnouncementListStyle = styled.div `
    & > ul {
      height: 250px;
      overflow-y: auto;
    }
`

const ConditionalAnnouncementItem: React.FC<{channelId: string, announcement: ConditionalAnnouncement}> = ({channelId, announcement}) => {
    const intl = useIntl()

    const managerState = useMemo(() => {
        if (!instanceOfManagerStateTimeLimit(announcement.condition)) return undefined

        return announcement.condition.state

    }, [announcement.condition])

    const {mutateAsync: deleteThis} = useDeleteConditionalAnnouncement(channelId, announcement._id)

    const reason = useMemo(() => {
        if (instanceOfManagerStateTimeLimit(announcement.condition) &&
            announcement.condition.awayReason &&
            announcement.condition.awayReason in REASON_ITEMS) {
            return intl.formatMessage({id: REASON_ITEMS[announcement.condition.awayReason]})
        }
    }, [intl, announcement])

    const description = useMemo(() => {
        if (!('minute' in announcement.condition)) return ''

        let result = intl.formatMessage({id: "i100130"}, {minute: announcement.condition.minute})
        result += ' '

        if (announcement.actions.includes('notify') && announcement.actions.includes('highlight')) {
            result += intl.formatMessage({id: "i100127"})
        } else if (announcement.actions.includes('notify')) {
            result += intl.formatMessage({id: "i100135"})
        } else {
            result += intl.formatMessage({id: "i100126"})
        }
        return result
    }, [intl, announcement])

    const _deleteThis = async () => {
        await toast.promise(
            deleteThis(),
            {
                loading: intl.formatMessage({id: "i100131"}),
                success: () => intl.formatMessage({id: "i100132"}),
                error: () => {
                    return intl.formatMessage({id: "e000001"})
                }
            }
        );
        deleteThis(undefined, {onError: () => toast.error(intl.formatMessage({id: 'e000002'}))})
    }

    const dotClassName = useMemo(() => {
        let result = 'rounded-circle mr-1 '
        if (managerState === 'online') {
            result += 'bg-success'
        } else if (managerState === 'offline') {
            result += 'bg-success'
        } else if (managerState === 'away') {
            result += 'bg-warning'
        }
        return result
    }, [managerState])
    return (
        <ConditionalAnnouncementItemStyle className="d-flex align-items-center">
            <div className='d-flex align-items-center'>
                <div className={dotClassName}
                     style={{width: "10px", height: "10px"}}
                />
                <span>
                    {intl.formatMessage({id: managerState === 'online' ? 'i000333' : 'i100099'})}
                </span>
            </div>

            <div>
                {reason}
            </div>

            <div>
                {description}
            </div>

            <div>
                <i className="mdi mdi-trash-can-outline delete"
                   role='button'
                   onClick={_deleteThis}/>
            </div>
        </ConditionalAnnouncementItemStyle>
    )
}

const REASON_ITEMS: {[key: string] :string} = {
    'meeting': 'i100101',
    'moving': 'i100102',
    'meal': 'i100103',
    'busy': 'i100104',
    'vacation': 'i100105'
}

const ConditionalAnnouncementItemStyle = styled.li `
  & > div:nth-child(1) {
    flex: 0 0 20%
  }

  & > div:nth-child(2) {
    flex: 0 0 30%
  }

  & > div:nth-child(3) {
    flex: 1 0 0
  }

  & > div:nth-child(4) {
    flex: 0 0 5%
  }
`


const ConditionalAnnouncementForm: React.FC<{channelId: string}> = ({channelId}) => {
    const intl = useIntl()

    const toggleRef = useRef<HTMLDivElement>(null);
    const minuteRef = useRef<HTMLInputElement>(null);
    const {mutate: create, isLoading} = useCreateConditionalAnnouncement(channelId)

    const [conditionState, setConditionState] = useState<'online' | 'away'>('away')
    const [awayReason, setAwayReason] = useState<string | undefined>(undefined)
    const [actions, setActions] = useState<('highlight'|'notify')[]>(['notify', 'highlight'])

    const onChangeState = useCallback((state: 'online' | 'away') => {
        setConditionState(state)
        if (state === 'online') {
            setAwayReason(undefined)
        }
    }, [setAwayReason, setConditionState])

    const reasonToggleText = useMemo(() => {
        return awayReason && awayReason in REASON_ITEMS ? intl.formatMessage({id: REASON_ITEMS[awayReason]}) : ''
    }, [intl, awayReason])


    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        if (!minuteRef.current) return;

        if (minuteRef.current.value.length < 1) {
            toast.error(intl.formatMessage({id: 'i100128'}))
            return;
        }

        let condition: ManagerStateTimeLimit
        condition = {type: "state_time_limit", state: conditionState, awayReason: awayReason, minute: parseInt(minuteRef.current.value)}

        const body: ConditionalAnnouncementCreate = {
            channelId,
            condition,
            actions
        }

        try {
            await create(body)
            toast.success(intl.formatMessage({id: 'i100129'}))

            minuteRef.current.value = ''
            setConditionState('away')
            setAwayReason(undefined)
            setActions(['notify', 'highlight'])
        } catch (e) {
            console.log(e)
            toast.error(intl.formatMessage({id: 'e000001'}))
        }
    }
    return (
        <ConditionalAnnouncementFormStyle>
            <h4>
                {intl.formatMessage({id: 'i200127'})}
            </h4>

            <Form className='form-wrapper pt-3 pb-3 pr-2 pl-2 rounded' onSubmit={onSubmit}>

                <div className='form-row mb-2'>
                    <p className='row-head'>{intl.formatMessage({id: 'i100123'})}</p>
                    <Dropdown placement="top-start">
                        <Dropdown.Toggle id="menu-profile-drop"
                                         as="div"
                                         className="state-toggle bg-white d-flex align-items-center"
                                         ref={toggleRef}
                        >
                            <div className={classNames(
                                conditionState === 'away' ? 'bg-warning' : 'bg-success',
                                'rounded-circle mr-1'
                            )}
                                 style={{width: "10px", height: "10px"}}
                            />
                            <span>
                                {intl.formatMessage({id: conditionState === 'online' ? 'i000333' : 'i100099'})}
                            </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>

                            <Dropdown.Item as="button"
                                           className='d-flex align-items-center'
                                           type="button"
                                           onClick={() => {onChangeState('away')}}
                            >
                                <div className='bg-warning rounded-circle mr-1'
                                     style={{width: "10px", height: "10px"}}
                                />
                                <span>{intl.formatMessage({id: 'i100099'})}</span>
                            </Dropdown.Item>

                            {/*<Dropdown.Item as="button"
                                           className='d-flex align-items-center'
                                           type="button"
                                           onClick={() => {onChangeState('online')}}
                            >
                                <div className='bg-success rounded-circle mr-1'
                                     style={{width: "10px", height: "10px"}}
                                />
                                <span>{intl.formatMessage({id: 'i000333'})}</span>
                            </Dropdown.Item>*/}

                        </Dropdown.Menu>
                    </Dropdown>

                    {conditionState === 'away' &&
                        <Dropdown >
                            <Dropdown.Toggle id='notification-action-dropdown'
                                             as="div"
                                             className='ml-1 reason-toggle bg-white d-flex align-items-center'
                                             ref={toggleRef}
                            >
                                <span>{reasonToggleText}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>

                                {Object.entries(REASON_ITEMS).map(([reasonKey, i18n]) => (
                                    <Dropdown.Item key={`announcement-reason-${reasonKey}`}
                                                   as="button"
                                                   className='d-flex align-items-center'
                                                   type="button"
                                                   onClick={() => {setAwayReason(reasonKey)}}
                                    >
                                        <span>{intl.formatMessage({id: i18n})}</span>
                                    </Dropdown.Item>
                                ))}

                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </div>

                <div className='form-row'>
                    <p className='row-head'>{intl.formatMessage({id: 'i100124'})}</p>

                    <div className='d-flex align-items-center position-relative'>
                        <Form.Control type='number'  ref={minuteRef} className='minute-input'/>
                        <span className='position-absolute text-muted' style={{right: "1rem"}}>
                            {intl.formatMessage({id: 'i000367'})}
                        </span>
                    </div>

                    <p className='row-head'>{intl.formatMessage({id: 'i100125'})}</p>

                    <Dropdown >
                        <Dropdown.Toggle id="menu-profile-drop"
                                         as="div"
                                         className='ml-1 action-toggle bg-white d-flex align-items-center'
                                         ref={toggleRef}
                        >
                            <span>{intl.formatMessage({id: actions.includes('notify') ? 'i100127' : "i100126"})}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>

                            <Dropdown.Item as="button"
                                           className='d-flex align-items-center'
                                           type="button"
                                           onClick={() => {setActions(['highlight'])}}
                            >
                                <span>{intl.formatMessage({id: 'i100126'})}</span>
                            </Dropdown.Item>

                            <Dropdown.Item as="button"
                                           className='d-flex align-items-center'
                                           type="button"
                                           onClick={() => {setActions(['highlight', 'notify'])}}
                            >
                                <span>{intl.formatMessage({id: 'i100127'})}</span>
                            </Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <div className="d-flex justify-content-end">
                    <Button type='submit' className="btn-rounded" disabled={isLoading}>
                        {intl.formatMessage({id: 'i000023'})}
                    </Button>

                </div>
            </Form>

        </ConditionalAnnouncementFormStyle>
    )
}

const ConditionalAnnouncementFormStyle = styled.div `
  
  .form-wrapper {
    background-color: #F4F5F7;
  }

  .form-row {
    display: flex;
    align-items: center;

    .row-head {
      margin-right: 1rem;
      margin-bottom: 0;
      text-align: right;
      flex: 0 0 70px;
      color: black;
    }

    .state-toggle, .reason-toggle ,.action-toggle {
      cursor: pointer;
      display: flex;
      padding-right: 1rem;
      padding-left: 1rem;
      
      & > span {
        flex-grow: 1;
      }
    }
    
    .minute-input {
      text-align: right;
      padding-right: 2rem;
      margin: 0;
    }

    .minute-input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      -moz-appearance: textfield;
    }
    
    .state-toggle, .reason-toggle, .minute-input {
      width: 150px;
      height: 30px;
      border-radius: .3rem;
    }

    .action-toggle {
      width: 250px;
      height: 30px;
    }
    
  }
`


export default ManagerNotificationView