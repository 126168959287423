import {useContext, useEffect} from "react";
import {SocketContext} from "./SocketProvider";


const useSocketIOSubscribe = <T>(event: any, handler: (data: T) => void) => {
    const { socket } = useContext(SocketContext);

    useEffect(() => {

        const handlerWrapper = (response: any) => {
            handler(response.data);
        };

        socket?.io.on(event, handlerWrapper);

        return () => {socket?.io.off(event, handlerWrapper)};

    }, [event, handler, socket?.io]);
}

export default useSocketIOSubscribe;