import {useContext, useEffect} from "react";
import {SocketContext} from "./SocketProvider";


const useSocketSubscribe = <T>(event: string, handler: (data: T) => void) => {
    const { socket } = useContext(SocketContext);

    useEffect(() => {
        // console.debug('SocketIO: Adding listener', event);

        const handlerWrapper = (response: any) => {
            handler(response.data);
        };

        socket?.on(event, handlerWrapper);

        return () => {
            // console.debug('SocketIO: Removing listener', event);
            socket?.off(event, handlerWrapper);
        }
    }, [event, handler, socket]);
}

export default useSocketSubscribe;