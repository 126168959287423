import React, {useMemo, useState} from "react";
import AceEditor from "react-ace";
import {ComponentType, CustomSnippetObject} from "../../model/customSnippet";
import CustomSnippetRenderer from "./CustomSnippetRenderer";
import {Card} from "react-bootstrap";
import {CenteredIcon} from "../UnstyledBootstrap";
import ace from "ace-builds";
import "ace-builds/src-noconflict/mode-json"
import "ace-builds/src-noconflict/theme-monokai";
import {useIntl} from "react-intl";

ace.config.set("useWorker", false);

const CustomPanelSnippetDemo: React.FC = () => {
    const intl = useIntl();

    const defaultObject = useMemo<CustomSnippetObject>(() => {
        return {
            snippet: intl.formatMessage({id: "i200129"}),
            data: [
                {
                    type: ComponentType.TEXT,
                    data: { text: intl.formatMessage({id: "i200130"}) }
                },
                { type: ComponentType.DIVIDER },
                {
                    type: ComponentType.TEXT,
                    data: { text: intl.formatMessage({id: "i200131"}) }
                },
                {
                    type: ComponentType.LIST,
                    data: [
                        {
                            type: ComponentType.TEXT,
                            data: { text: intl.formatMessage({id: "i200132"}) }
                        },
                        {
                            type: ComponentType.TEXT,
                            data: { text: intl.formatMessage({id: "i200133"}) }
                        }
                    ]
                },
                { type: ComponentType.DIVIDER },
                {
                    type: ComponentType.TEXT,
                    data: { text: intl.formatMessage({id: "i200134"}) }
                },
                {
                    type: ComponentType.LIST,
                    data: [
                        {
                            type: ComponentType.IMAGE,
                            data: {
                                url: "https://picsum.photos/100/100",
                                clickUrl: "https://picsum.photos/100/100"
                            }
                        },
                        {
                            type: ComponentType.IMAGE,
                            data: {
                                url: "https://picsum.photos/100/100",
                                clickUrl: "https://picsum.photos/100/100"
                            }
                        },
                        {
                            type: ComponentType.IMAGE,
                            data: {
                                url: "https://picsum.photos/100/100",
                                clickUrl: "https://picsum.photos/100/100"
                            }
                        },
                    ],
                    style: {
                        display: "flex",
                        justifyContent: "space-between"
                    }
                },
                { type: ComponentType.DIVIDER },
                {
                    type: ComponentType.TEXT,
                    data: { text: intl.formatMessage({id: "i200135"}) }
                },
                {
                    type: ComponentType.KEY_VALUE,
                    data: {
                        items: [
                            { key: "name", value: "Swing Chat" },
                            { key: "age", value: "20" },
                            { key: "City", value: "Seoul" },
                        ]
                    }
                },
                { type: ComponentType.DIVIDER },
                {
                    type: ComponentType.LINK,
                    data: {
                        text: intl.formatMessage({id: "i200136"}),
                        url: "https://www.swing.chat"
                    }
                }
            ],
        };
    }, [intl]);

    const [snippetJson, setSnippetJson] = useState(JSON.stringify(defaultObject, null, 2));

    const snippetJsonObject = useMemo(() => {
        try {
            return JSON.parse(snippetJson);
        } catch (e) {
            return {snippet: "empty", data: []}
        }
    }, [snippetJson]);

    const onChangeSnippetJson = (content: string) => {
        setSnippetJson(prev => {
            try {
                JSON.parse(content);
                return content;
            } catch (e) {
                return prev;
            }
        });
    }

    return (
        <div className="content">
            <div className="mb-2">
                {intl.formatMessage({id: "i200137"})}
            </div>

            <div className="d-flex justify-content-between">
                <AceEditor name="snippet-editor"
                           className="rounded"
                           mode="json"
                           theme="monokai"
                           width="60%"
                           height="600px"
                           defaultValue={JSON.stringify(defaultObject, null, 2)}
                           onChange={onChangeSnippetJson}
                           setOptions={{
                               showPrintMargin: false,
                               showLineNumbers: true,
                               tabSize: 2,
                               useWorker: false,
                           }}/>

                <ChatPanelWithSnippet snippetObject={snippetJsonObject} />
            </div>
        </div>
    )
};

type ChatPanelWithSnippetProps = {
    snippetObject: CustomSnippetObject
}

const ChatPanelWithSnippet: React.FC<ChatPanelWithSnippetProps> = (props) => {
    const { snippetObject } = props;

    return (
        <div className="d-flex align-items-center justify-content-center"
             style={{width: "40%"}}>
            <ChatPanel title={snippetObject.snippet}>
                <CustomSnippetRenderer snippetObject={snippetObject}/>
            </ChatPanel>
        </div>
    )
};

type ChatPanelProps = {
    title: string;
    children?: React.ReactNode
}

const ChatPanel: React.FC<ChatPanelProps> = (props) => {
    const { title, children } = props;
    return (
        <Card className="mb-0" style={{maxHeight: "600px"}}>
            <Card.Header>
                <div className="d-flex align-items-center">
                    <p className="m-0 flex-grow-1 fw-bold">{title}</p>
                    <CenteredIcon size={24} className="font-24 uil-angle-up" role="button"/>
                </div>
            </Card.Header>
            <Card.Body className="py-2" style={{overflowY: "auto"}}>
                {children}
            </Card.Body>
        </Card>
    )
};

export default CustomPanelSnippetDemo;
