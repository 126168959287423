import packageJson from "../../package.json";

export const APP_TITLE = process.env.REACT_APP_TITLE;
export const APP_VERSION = packageJson.version;
export const WEB_VERSION = packageJson.webVersion;
export const APP_NAME = process.env.REACT_APP_NAME;
export const APP_LOGIN_URI = process.env.REACT_APP_LOGIN_URI;
export const ELECTRON_AUTHORIZATION_REDIRECT_URL = process.env.REACT_APP_ELECTRON_AUTHORIZATION_REDIRECT_URL;

export const LIVE_CHAT_API_HOST = process.env.REACT_APP_LIVECHAT_API_HOST;
export const WEB_HOST = process.env.REACT_APP_WEB_HOST;
export const WEBSOCKET_HOST = process.env.REACT_APP_WEBSOCKET_HOST as string;
export const WEBSOCKET_PATH = process.env.REACT_APP_WEBSOCKET_PATH || '/socket.io';
export const CM_API_HOST = process.env.REACT_APP_CM_API_HOST;
export const KEEPER_API_HOST = process.env.REACT_APP_KEEPER_API_HOST;
export const KEEPER_CLIENT_ID = process.env.REACT_APP_KEEPER_CLIENT_ID as string;
export const WEBHOOK_URL_CHECK = process.env.REACT_APP_WEBHOOK_URL_CHECK === 'true';
export const CHATBOT_WEB_HOST = process.env.REACT_APP_CHATBOT_WEB_HOST;

export const LIVE_CHAT_DASHBOARD_CHARTS_STORAGE_KEY = 'dashboard-charts';
export const LIVE_CHAT_SEARCH_HISTORIES_STORAGE_KEY = 'search-histories';

export const LATEST_MANAGER_PRIORITIES_KEY = 'LMP';
export const LATEST_ALL_PRIORITIES_KEY = 'LAP';

export const PANEL_SETTINGS_STORAGE_KEY_LEGACY = "panel-settings"
export const PANEL_SETTINGS_STORAGE_KEY = "panel-settings-key"
export const READ_TOASTS_STORAGE_KEY = "read-toasts";

export const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS
export const QNA_CHATBOT_URL = process.env.REACT_APP_QNA_CHATBOT_URL as string | null;

export const DISABLE_CHAT_WITH_ENTER_KEY = 'disable-chat-with-enter';
