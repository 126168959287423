import React, {useCallback, useMemo, useState} from "react";
import {Moment} from "moment/moment";
import {
    LiveChatStatisticBase,
    LiveChatStatisticSummaryResponse,
    LiveChatTimeHistory,
    ResponseChartType,
    TotalStatisticSummary
} from "../../model/statistic";
import {ApexOptions} from "apexcharts";
import {useIntl} from "react-intl";
import {Dropdown, DropdownButton, Spinner} from "react-bootstrap";
import Chart from "react-apexcharts";
import {useRecoilValue} from "recoil";
import liveChatDashboardCharts from "../../recoil/liveChatDashboardCharts";
import TooltipIcon from "../../component/TooltipIcon";
import {getIntervalMaximum} from "../../util/statisticUtil";
import ResponseStatusDashboardController from "../../component/dashboard/ResponseStatusDashboardController";
import {ChartWrapperStyle, DashboardHelpCardStyle, TotalStatisticSummaryCardStyle} from "./DashboardStyles";

const DISTRIBUTION_RANGE = [180, 600, 1800, 3600];
const DEFAULT_TICK_AMOUNT = 5;

type ResponseStatusDashboardViewProps = {
    liveChatStatisticSummary?: LiveChatStatisticSummaryResponse
    isLoading: boolean
    period: {
        startDate: Moment
        endDate: Moment
    },
}

const ResponseStatusDashboardView: React.FC<ResponseStatusDashboardViewProps> = (props) => {
    const {
        liveChatStatisticSummary,
        isLoading,
        period,
    } = props;

    const intl = useIntl()
    const dashboardCharts = useRecoilValue(liveChatDashboardCharts);

    const showChart = useMemo(() => {
        return {
            accept: dashboardCharts.find(chart => chart.id === "accept-chart"),
            response: dashboardCharts.find(chart => chart.id === "response-chart"),
            duration: dashboardCharts.find(chart => chart.id === "duration-chart"),
        }
    }, [dashboardCharts])

    const emptyChart = useMemo(() => {
        return !dashboardCharts.some(chart => chart.show);
    }, [dashboardCharts])

    const distributionChartCategory = useMemo(() => {
        return [
            intl.formatMessage({id: "i200040"}),
            intl.formatMessage({id: "i200041"}),
            intl.formatMessage({id: "i200042"}),
            intl.formatMessage({id: "i200043"}),
            intl.formatMessage({id: "i200044"})
        ]
    }, [intl])
    const statisticCategoryList = useMemo(() => {
        return [
            { key: "mean", name: intl.formatMessage({id: "i200026"}) },
            { key: "median", name: intl.formatMessage({id: "i200027"}) },
        ]
    }, [intl])

    const searchCoverageList = useMemo(() => {
        return [
            { key: "entire", name: intl.formatMessage({id: "i200028"}) },
            { key: "working", name: intl.formatMessage({id: "i200029"}) },
        ]
    }, [intl])

    const [statisticCategory, setStatisticCategory] = useState(statisticCategoryList[0]);
    const [searchCoverage, setSearchCoverage] = useState(searchCoverageList[0])

    const xAxisCategories = useMemo(() => {
        const startDate = period.startDate.clone()
        const endDate = period.endDate.clone()
        const daysLength = Number(endDate.diff(startDate, 'days', true).toFixed(0))

        return Array.from({length: daysLength}).map((_, dayIndex) => {
            const date = startDate.clone().add(dayIndex, 'days')
            return date.local().format("MM-DD")
        })
    }, [period]);

    const getStatistic = useCallback((statistic: LiveChatStatisticBase, category: string, coverage: string) => {
        if (coverage === 'entire') {
            return {
                value: category === 'mean' ? statistic.entireStatistic.average : statistic.entireStatistic.median,
                count: statistic.entireStatistic.count
            }
        } else {
            return {
                value: category === 'mean' ? statistic.workingStatistic.average : statistic.workingStatistic.median,
                count: statistic.workingStatistic.count
            }
        }
    }, [])

    const secondsToDHMS = useCallback((seconds: number) => {
        const day = Math.floor(seconds / (3600 * 24));
        const hour = Math.floor(seconds % (3600 * 24) / 3600);
        const minute = Math.floor(seconds % 3600 / 60);
        const second = Math.floor(seconds % 60);

        const dayFormat = day > 0 ? intl.formatMessage({id: "i200045"}, {day: day}) : "";
        const hourFormat = (day <= 10 && hour > 0) ? intl.formatMessage({id: "i200046"}, {hour: hour}) : "";
        const minuteFormat = (day === 0 && minute > 0)? intl.formatMessage({id: "i200047"}, {minute: minute}) : "";
        const secondFormat = (day === 0 && hour === 0 && second > 0) ? intl.formatMessage({id: "i200048"}, {second: second}) : "";
        const result = dayFormat + hourFormat + minuteFormat + secondFormat;

        return result ? result : intl.formatMessage({id: "i200048"}, {second: 0});
    }, [intl]);

    const totalStatistics = useMemo(() => {
        const parser = (total: TotalStatisticSummary|undefined) => {
            if (!total) {
                return { value: 0, count: 0 }
            }

            if (searchCoverage.key === 'entire') {
                return {
                    value: statisticCategory.key === 'mean' ? total.entire.average : total.entire.median,
                    count: total.entire.count
                }
            } else {
                return {
                    value: statisticCategory.key === 'mean' ? total.working.average : total.working.median,
                    count: total.working.count
                }
            }
        }

        const acceptStatistic = parser(liveChatStatisticSummary?.accept.totalStatistics);
        const responseStatistic = parser(liveChatStatisticSummary?.response.totalStatistics);
        const durationStatistic = parser(liveChatStatisticSummary?.duration.totalStatistics);

        return {
            accept: {
                value: showChart.accept?.show ? secondsToDHMS(Number(acceptStatistic.value.toFixed(1))) : intl.formatMessage({id: "i200060"}),
                count: acceptStatistic.count
            },
            response: {
                value: showChart.response?.show ? secondsToDHMS(Number(responseStatistic.value.toFixed(1))) : intl.formatMessage({id: "i200060"}),
                count: responseStatistic.count
            },
            duration: {
                value: showChart.duration?.show ? secondsToDHMS(Number(durationStatistic.value.toFixed(1))) : intl.formatMessage({id: "i200060"}),
                count: durationStatistic.count
            },
        }
    }, [liveChatStatisticSummary, searchCoverage, statisticCategory, secondsToDHMS, showChart, intl])

    const generateLineChartSeries = useCallback((chartType: ResponseChartType) => {
        if (liveChatStatisticSummary) {
            switch (chartType) {
                case "accept-chart":
                    return [{
                        name: intl.formatMessage({id: "i200018"}),
                        data: liveChatStatisticSummary.accept.statistics.map(statistic => {
                            const { value: statisticValue } = getStatistic(statistic, statisticCategory.key, searchCoverage.key);
                            return Number(statisticValue.toFixed(1));
                        })
                    }] as ApexAxisChartSeries
                case "response-chart":
                    return [{
                        name: intl.formatMessage({id: "i200007"}),
                        data: liveChatStatisticSummary.response.statistics.map(statistic => {
                            const { value: statisticValue } = getStatistic(statistic, statisticCategory.key, searchCoverage.key);
                            return Number(statisticValue.toFixed(1));
                        })
                    }] as ApexAxisChartSeries
                case "duration-chart":
                    return [{
                        name: intl.formatMessage({id: "i200020"}),
                        data: liveChatStatisticSummary.duration.statistics.map(statistic => {
                            const { value: statisticValue } = getStatistic(statistic, statisticCategory.key, searchCoverage.key);
                            return Number(statisticValue.toFixed(1));
                        })
                    }] as ApexAxisChartSeries
            }
        } else {
            return [{
                name: intl.formatMessage({id: "i200008"}),
                data: []
            }] as ApexAxisChartSeries
        }
    }, [liveChatStatisticSummary, getStatistic, intl, searchCoverage.key, statisticCategory.key])

    const generateLineChartOption = useCallback((chartType: ResponseChartType) => {
        let maxValue = 10
        if (liveChatStatisticSummary) {
            switch (chartType) {
                case "accept-chart":
                    maxValue = getIntervalMaximum(liveChatStatisticSummary.accept.statistics.map(statistic => {
                        return statistic.entireStatistic.average
                    }))
                    break
                case "response-chart":
                    maxValue = getIntervalMaximum(liveChatStatisticSummary.response.statistics.map(statistic => {
                        return statistic.entireStatistic.average
                    }))
                    break
                case "duration-chart":
                    maxValue = getIntervalMaximum(liveChatStatisticSummary.duration.statistics.map(statistic => {
                        return statistic.entireStatistic.average
                    }))
                    break
            }
        }

        const commonOptions: ApexOptions = {
            chart: {
                id: 'default',
                type: 'line',
                height: 350,
                width: 600,
                zoom: { enabled: false },
                toolbar: { show: false }
            },
            markers: { size: 5 },
            dataLabels: { enabled: false },
            colors: ["#FFBC00D9"],
            grid: {
                show: true,
                position: "back",
            },
            xaxis: {
                categories: xAxisCategories,
                tickAmount: 5,
                labels: {
                    rotate: 0,
                    hideOverlappingLabels: true
                },
                axisBorder: {
                    show: true,
                    color: "#868686"
                },
            },
            yaxis: {
                // title: {
                //     text: intl.formatMessage({id: "i200030"})
                // },
                min: 0,
                max: maxValue,
                tickAmount: DEFAULT_TICK_AMOUNT,
                labels: {
                    formatter: val => secondsToDHMS(val)
                }
            },
            tooltip: {
                shared: true,
                followCursor: true,
                y: {
                    formatter: (val) => {
                        if (val == null) {
                            return intl.formatMessage({id: "i200008"});
                        }
                        return secondsToDHMS(val)
                    }
                }
            },
            stroke: {
                curve: 'straight',
                width: 3
            },
        }

        if (commonOptions.chart?.id) {
            commonOptions.chart.id = `${chartType}-line`
        }

        switch (chartType) {
            case "accept-chart":
                commonOptions.colors = ["#FF9696D9"]
                break
            case "response-chart":
                commonOptions.colors = ["#FFBC00D9"]
                break
            case "duration-chart":
                commonOptions.colors = ["#818DF9D9"]
                break
        }

        return commonOptions
    }, [xAxisCategories, intl, liveChatStatisticSummary, secondsToDHMS])

    const generateBarChartSeries = useCallback((chartType: ResponseChartType) => {
        if (liveChatStatisticSummary) {
            let histories: LiveChatTimeHistory[]

            switch (chartType) {
                case "accept-chart":
                    histories = liveChatStatisticSummary.accept.histories;
                    break
                case "response-chart":
                    histories = liveChatStatisticSummary.response.histories;
                    break
                case "duration-chart":
                    histories = liveChatStatisticSummary.duration.histories;
                    break
            }

            const timesDistribution = [0, 0, 0, 0, 0];
            let totalResponses = 0;

            const times = histories.map(history => {
                return searchCoverage.key === 'entire' ? history.time : history.workingTime;
            });

            times.forEach(time => {
                if (time) {
                    totalResponses += 1;
                    if (time < DISTRIBUTION_RANGE[0]) {
                        timesDistribution[0] += 1
                    } else if (time < DISTRIBUTION_RANGE[1]) {
                        timesDistribution[1] += 1
                    } else if (time < DISTRIBUTION_RANGE[2]) {
                        timesDistribution[2] += 1
                    } else if (time < DISTRIBUTION_RANGE[3]) {
                        timesDistribution[3] += 1
                    } else {
                        timesDistribution[4] += 1
                    }
                }
            })
            return [{
                name: intl.formatMessage({id: "i200037"}),
                data: timesDistribution.map(value => totalResponses ? value / totalResponses * 100 : 0)
            }]
        } else {
            return [{
                name: intl.formatMessage({id: "i200008"}),
                data: []
            }]
        }
    }, [liveChatStatisticSummary, intl, searchCoverage.key])

    const generateBarChartOption = useCallback((chartType: ResponseChartType) => {
        const commonOptions: ApexOptions = {
            chart: {
                id: 'default-distribution',
                type: 'bar',
                height: 350,
                width: 600,
                toolbar: { show: false },
            },
            colors: ["#FFBC00D9"],
            plotOptions: {
                bar: {
                    borderRadius: 2,
                    horizontal: true,
                    barHeight: '40%',
                    dataLabels: { total: { enabled: true } }
                }
            },
            dataLabels: { enabled: true, formatter: (val) => `${Number(val).toFixed(1)}%`},
            xaxis: {
                min: 0,
                max: 100,
                labels: {
                    show: true,
                    formatter: (val) => `${val}%`
                },
                categories: distributionChartCategory
            },
            tooltip: {
                y: {
                    formatter: (val) => `${val.toFixed(1)}%`
                }
            },
        }

        if (commonOptions.chart?.id) {
            commonOptions.chart.id = `${chartType}-bar`
        }

        switch (chartType) {
            case "accept-chart":
                commonOptions.colors = ["#FF9696D9"]
                break
            case "response-chart":
                commonOptions.colors = ["#FFBC00D9"]
                break
            case "duration-chart":
                commonOptions.colors = ["#818DF9D9"]
                break
        }

        return commonOptions
    }, [distributionChartCategory])

    return (
        <div className="w-100">
            <div className="d-flex mx-3 mt-3 justify-content-between">
                <div className="d-flex justify-content-start align-items-center">
                    <DropdownButton title={statisticCategory.name}
                                    variant="light">
                        {statisticCategoryList.map(category => (
                            <Dropdown.Item key={category.key}
                                           onClick={() => setStatisticCategory(category)}>
                                {category.name}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                    <DropdownButton title={searchCoverage.name}
                                    className="ms-2"
                                    variant="light">
                        {searchCoverageList.map(coverage => (
                            <Dropdown.Item key={coverage.key}
                                           onClick={() => setSearchCoverage(coverage)}>
                                {coverage.name}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </div>

                <ResponseStatusDashboardController/>
            </div>

            <div className="d-flex justify-content-start">
                <TotalStatisticSummaryCardStyle>
                    <div className="each-statistic-summary-card" style={{color: "#FF9696D9"}}>
                        <h5>{intl.formatMessage({id: "i200018"})}</h5>
                        {isLoading ?
                            <Spinner animation="border" size="sm" />
                            :
                            <p style={{color: "#FF9696D9"}}>{totalStatistics.accept.value}</p>
                        }
                    </div>

                    <div className="each-statistic-summary-card" style={{color: "#FFBC00D9"}}>
                        <h5>{intl.formatMessage({id: "i200019"})}</h5>
                        {isLoading ?
                            <Spinner animation="border" size="sm"/>
                            :
                            <p>{totalStatistics.response.value}</p>
                        }
                    </div>

                    <div className="each-statistic-summary-card" style={{color: "#818DF9D9"}}>
                        <h5>{intl.formatMessage({id: "i200033"})}</h5>
                        {isLoading ?
                            <Spinner animation="border" size="sm" />
                            :
                            <p style={{color: "#818DF9D9"}}>{totalStatistics.duration.value}</p>
                        }
                    </div>
                </TotalStatisticSummaryCardStyle>

                <DashboardHelpCardStyle>
                    <h5 className="mb-2 fw-bold">
                        {intl.formatMessage({id: "i200055"})}
                    </h5>
                    <div className="help-paragraph">
                        <i className="mdi mdi-calendar mr-2"/>
                        <p className="text-dark">{intl.formatMessage({id: "i200056"})}</p>
                    </div>
                    <div className="help-paragraph">
                        <i className="mdi mdi-calculator mr-2"/>
                        <p className="text-dark">{intl.formatMessage({id: "i200057"})}</p>
                    </div>
                    <div className="help-paragraph">
                        <i className="mdi mdi-exit-run mr-2"/>
                        <p className="text-dark">{intl.formatMessage({id: "i200058"})}</p>
                    </div>
                </DashboardHelpCardStyle>
            </div>

            {emptyChart &&
                <div className="w-100 d-flex justify-content-center align-items-center">
                    <p className="text-muted">{intl.formatMessage({id: "i200059"})}</p>
                </div>
            }

            {showChart.accept?.show &&
                <ChartWrapperStyle>
                    <div className="chart-card">
                        <div className="chart-title">
                            <h5>{intl.formatMessage({id: "i200018"})}</h5>
                            <TooltipIcon className="ml-1"
                                         tooltip={intl.formatMessage({id: "i200049"})}
                                         options={{placement: "right"}}/>
                        </div>
                        <Chart type='line' height={280} width={700}
                               series={generateLineChartSeries("accept-chart")}
                               options={generateLineChartOption("accept-chart")}/>
                    </div>
                    <div className="chart-card">
                        <h5>{intl.formatMessage({id: "i200021"}, {count: totalStatistics.accept.count})}</h5>
                        <Chart type='bar' height={280} width={700}
                               series={generateBarChartSeries("accept-chart")}
                               options={generateBarChartOption("accept-chart")}/>
                    </div>
                </ChartWrapperStyle>
            }

            {showChart.response?.show &&
                <ChartWrapperStyle>
                    <div className="chart-card">
                        <div className="chart-title">
                            <h5>{intl.formatMessage({id: "i200019"})}</h5>
                            <TooltipIcon className="ml-1"
                                         tooltip={intl.formatMessage({id: "i200050"})}
                                         options={{placement: "right"}}/>
                        </div>
                        <Chart type='line' height={280} width={700}
                               series={generateLineChartSeries("response-chart")}
                               options={generateLineChartOption("response-chart")}/>
                    </div>
                    <div className="chart-card">
                        <h5>{intl.formatMessage({id: "i200022"}, {count: totalStatistics.response.count})}</h5>
                        <Chart type='bar' height={280} width={700}
                               series={generateBarChartSeries("response-chart")}
                               options={generateBarChartOption("response-chart")}/>
                    </div>
                </ChartWrapperStyle>
            }

            {showChart.duration?.show &&
                <ChartWrapperStyle>
                    <div className="chart-card">
                        <div className="chart-title">
                            <h5>{intl.formatMessage({id: "i200033"})}</h5>
                            <TooltipIcon className="ml-1"
                                         tooltip={intl.formatMessage({id: "i200053"})}
                                         options={{placement: "right"}}/>
                        </div>
                        <Chart type='line' height={280} width={700}
                               series={generateLineChartSeries("duration-chart")}
                               options={generateLineChartOption("duration-chart")}/>
                    </div>
                    <div className="chart-card">
                        <h5>{intl.formatMessage({id: "i200034"}, {count: totalStatistics.duration.count})}</h5>
                        <Chart type='bar' height={280} width={700}
                               series={generateBarChartSeries("duration-chart")}
                               options={generateBarChartOption("duration-chart")}/>
                    </div>
                </ChartWrapperStyle>
            }
        </div>
    )
}

export default ResponseStatusDashboardView;
