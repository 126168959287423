
export interface ManagerStateTimeLimit {
    type: 'state_time_limit',
    awayReason?: string
    state: 'online' | 'offline' | 'away'
    minute: number
}

interface ConditionalAnnouncementBase {
    channelId: string;
    condition: ManagerStateTimeLimit | {type: string}
    actions: ('highlight'|'notify')[]
}

export interface ConditionalAnnouncement extends ConditionalAnnouncementBase {
    _id: string;
    createdAt: string;
}

export const instanceOfManagerStateTimeLimit = (object: any): object is ManagerStateTimeLimit => {
    return object.type === 'state_time_limit'
}

export interface ConditionalAnnouncementCreate extends ConditionalAnnouncementBase {}
