import React from "react";
import styled from "styled-components";
import {Color} from "../../assets/variables";
import SimpleBar from "simplebar-react";


const SettingViewWrapper: React.FC<{children?: React.ReactNode, minWidth?: string}> = ({children, minWidth}) => {

    return (
        <SettingViewWrapperStyle minWidth={minWidth}>
            <SimpleBar className="setting-content">
                {children}
                {/*<div className="setting-content">*/}
                {/*</div>*/}
            </SimpleBar>
        </SettingViewWrapperStyle>
    );
}


export const SettingViewWrapperStyle = styled.div<{minWidth?: string}>`
  //flex-grow: 1;
  //flex-shrink: 0;
  background-color: white;
  flex: 1;
  //overflow: auto;
  //padding-right: 2.225rem;

  .setting-content {
    min-width: ${props => props.minWidth || '600px'};
    height: 100%;
    padding: 4.5rem 2.225rem 4.5rem 2.225rem;
    .setting-title {
      margin-bottom: 3rem;
      p {
        margin: 1rem 0;
        white-space: pre-wrap;
        font-size: 0.9rem;
        color: ${Color.muted}
      }
    }
  }
`;


export default SettingViewWrapper;
