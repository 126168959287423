import {DefaultRepository} from "./index";
import {LIVE_CHAT_API_HOST} from "../core/variables";
import oauthAxios from "../core/oauthAxios";

class AuthenticationRepository extends DefaultRepository {

    getKeeperProfile() {
        return oauthAxios.get(`${this.keeperHost}/profile`);
    }

    async getMe() {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/auth/me`);
    }
}

export default new AuthenticationRepository();
