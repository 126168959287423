import managerFeedRepository from "../../repository/ManagerFeedRepository";
import {LiveChatError, toLiveChatError} from "../../error";
import {useQuery} from "@tanstack/react-query";
import {ManagerFeed} from "../../model/managerFeeds";
import {ReactQueryOptions} from "../index";

const DEFAULT_ARGS = {retry: false, suspense: false, refetchOnMount: false}

const useFeeds = (channelId: string, args?: ReactQueryOptions) => {
    let _args = {...DEFAULT_ARGS}
    if (args) {
        _args = Object.assign(_args, args)
    }
    return useQuery<ManagerFeed[], LiveChatError>({
        queryKey: ['feeds', channelId],
        queryFn: async () => {
            try {
                const { data } = await managerFeedRepository.find(channelId);
                return data.result;
            } catch (e) {
                throw toLiveChatError(e);
            }
        },
        ..._args
    });
};

export default useFeeds;
