import useChatbotMessages from "../query/message/useChatbotMessages";
import useLiveChatHistories from "../query/useLiveChatHistories";
import {useCallback, useMemo, useState} from "react";
import moment from "moment/moment";
import {Message, MessageUI} from "../model/message";
import {useQueryClient} from "@tanstack/react-query";
import {LiveChat} from "../model/livechat";
import useMessages from "../query/message/useMessages";
import {Page} from "../query";


const useMessageHandler = (liveChat: LiveChat, baseDate?: string) => {

    const queryClient = useQueryClient();

    const endDate = moment.utc(liveChat.createdAt).add(10, 'second').toISOString();

    const messagesQuery = useMessages(liveChat.channelId, liveChat._id, baseDate)
    const chatbotMessagesQuery = useChatbotMessages(liveChat.channelId, liveChat._id, endDate);
    const liveChatHistoriesQuery = useLiveChatHistories(liveChat.channelId, liveChat._id);

    const [lastConfirmedMessageId, setLastConfirmedMessageId] = useState<string>();

    const initialized = useMemo(() => {
        return messagesQuery.isSuccess && chatbotMessagesQuery.isSuccess && liveChatHistoriesQuery.isSuccess;
    }, [messagesQuery.isSuccess, chatbotMessagesQuery.isSuccess, liveChatHistoriesQuery.isSuccess]);

    const messages: MessageUI[] = useMemo(() => {
        const all_messages: MessageUI[] = [];

        if (!messagesQuery.isSuccess || !chatbotMessagesQuery.isSuccess) {
            return [];
        }

        if (messagesQuery.isSuccess && !messagesQuery.hasPreviousPage) {
            let firstMessage: Message|undefined;
            if (messagesQuery.data?.pages && messagesQuery.data.pages.length > 0 && messagesQuery.data.pages[0].result.length > 0) {
                firstMessage = messagesQuery.data.pages[0].result[0];
            }
            const chatbotMessageTimeline = firstMessage ? moment(firstMessage.createdAt).subtract(10, 'millisecond') : undefined;
            chatbotMessagesQuery.data?.pages?.map(page => page.result).flat().forEach(chatbotMessage => {
                if (chatbotMessage.message_type === "live_chat") return;

                if (!chatbotMessageTimeline || moment(chatbotMessage.created_at).isBefore(chatbotMessageTimeline)) {
                    all_messages.push({
                        type: 'chatbotMessage', data: chatbotMessage, date: chatbotMessage.created_at
                    });
                }
            });
        }

        messagesQuery.data?.pages?.map(page => page.result).flat().forEach(message => {
            all_messages.push({type: 'message', data: message, date: message.createdAt});
            if (message._id === lastConfirmedMessageId) {
                all_messages.push({type: 'lastConfirmedMessage', data: message, date: message.createdAt});
            }
        });

        liveChatHistoriesQuery.data?.forEach(history => {
            if (MANAGER_HISTORY_TYPE.some(filter => filter === history.action)) {
                all_messages.push({type: 'managerHistory', data: history, date: history.createdAt})
            }
        });

        const sorted_messages = all_messages.sort((a, b) => {
            const dateA = moment(a.date);
            const dateB = moment(b.date);
            return dateA.isAfter(dateB) ? 1 : -1;
        });

        const initialData: MessageUI[] = [];
        return sorted_messages.reduce((acc, cur) => {
            const previousMessage = acc.length > 0 ? acc[acc.length - 1] : undefined;
            const previousDate = previousMessage ? moment.utc(previousMessage?.date).local().format('YYYY-MM-DD') : undefined;
            const thisDate = moment.utc(cur.date).local().format('YYYY-MM-DD');

            if (previousDate && previousDate !== thisDate) {
                acc.push({type: 'dateline', data: cur.date, date: cur.date})
            }

            if (previousMessage?.type === 'chatbotMessage' && cur.type === 'message') {
                acc.push({type: 'endChatbotMessage', data: previousMessage.date, date: previousMessage.date})
            }

            acc.push(cur);
            return acc
        }, initialData);
    }, [
        // liveChat,
        messagesQuery,
        chatbotMessagesQuery,
        liveChatHistoriesQuery,
        lastConfirmedMessageId
    ]);

    const isFetching = useMemo(() => {
        return messagesQuery.isFetching || chatbotMessagesQuery.isFetching ||liveChatHistoriesQuery.isFetching;
    }, [messagesQuery.isFetching, chatbotMessagesQuery.isFetching, liveChatHistoriesQuery.isFetching])

    const isFetchingPreviousPage = useMemo(() => {
        return messagesQuery.isFetchingPreviousPage || chatbotMessagesQuery.isFetchingPreviousPage;
    }, [messagesQuery.isFetchingPreviousPage, chatbotMessagesQuery.isFetchingPreviousPage]);

    const isFetchingNextPage = useMemo(() => {
        return messagesQuery.isFetchingNextPage || chatbotMessagesQuery.isFetchingNextPage;
    }, [messagesQuery.isFetchingNextPage, chatbotMessagesQuery.isFetchingNextPage]);

    const hasPreviousPage = useMemo(() => {
        return messagesQuery.hasPreviousPage || chatbotMessagesQuery.hasPreviousPage;
    }, [messagesQuery, chatbotMessagesQuery]);

    const hasNextPage = useMemo(() => {
        return messagesQuery.hasNextPage;
    }, [messagesQuery])

    const fetchPreviousPage = useCallback(async () => {
        if (isFetching) {
            return;
        }

        if (messagesQuery.hasPreviousPage) {
            await messagesQuery.fetchPreviousPage();
        }
        else if (chatbotMessagesQuery.hasPreviousPage) {
            await chatbotMessagesQuery.fetchPreviousPage();
        }
    }, [messagesQuery, chatbotMessagesQuery, isFetching]);

    const fetchNextPage = useCallback(async () => {
        if (isFetching) {
            return;
        }

        if (messagesQuery.hasNextPage) {
            await messagesQuery.fetchNextPage();
        }
    }, [messagesQuery, isFetching])

    const addMessage = useCallback((message: Message) => {
        queryClient.setQueryData(['messages', message.channelId, message.liveChatId, baseDate], ((oldData: any) => {
            if (oldData && oldData.pages && oldData.pages.length > 0) {
                const aheadPages = oldData.pages.slice(0, oldData.pages.length - 1);
                const lastPage = oldData.pages.at(-1);
                const hasSendMessage = message.sendId && lastPage.result.some((msg: Message) => msg.sendId === message.sendId);
                if (hasSendMessage) {
                    const pageMessages = lastPage.result.map((msg: Message) => msg.sendId === message.sendId ? message : msg);
                    return {
                        pages: [...aheadPages, {...lastPage, result: pageMessages}],
                        pageParams: oldData.pageParams
                    }
                }
                else {
                    return {
                        pages: [...aheadPages, {...lastPage, result: [...lastPage.result, message]}],
                        pageParams: oldData.pageParams
                    }
                }
            }
            else {
                return {
                    pages: [{result: [message], pageable: {isFirst: false, isLast: false, limit: 50}}],
                    // pageParams: oldData.pageParams
                }
            }
        }));
    }, [queryClient, baseDate]);

    const deleteMessage = useCallback((message: Message) => {
        queryClient.setQueryData(['messages', message.channelId, message.liveChatId, baseDate], ((oldData: any) => {
            if (oldData && oldData.pages && oldData.pages.length > 0) {

                const pages = oldData.pages.map((page: Page<Message, {baseDate?: string}>) => {
                    return {
                        ...page,
                        result: page.result.filter((_message) => _message._id !== message._id)
                    }
                })

                return {
                    pages: pages,
                    pageParams: oldData.pageParams
                }

            } else {
                return oldData
            }
        }));
    }, [queryClient, baseDate]);

    const updateMessage = useCallback((message: Message) => {
        queryClient.setQueryData(['messages', message.channelId, message.liveChatId, baseDate], ((oldData: any) => {
            if (oldData && oldData.pages && oldData.pages.length > 0) {

                const pages = oldData.pages.map((page: Page<Message, {baseDate?: string}>) => {
                    return {
                        ...page,
                        result: page.result.map((_message) => {
                            if (_message._id === message._id) {
                                return message
                            } else {
                                return _message
                            }
                        })
                    }
                })

                return {
                    pages: pages,
                    pageParams: oldData.pageParams
                }

            } else {
                return oldData
            }
        }));
    }, [queryClient, baseDate]);

    const addMessages = useCallback((messages: Message[]) => {
        const messagesByLiveChat: {[key: string]: Message[]} = {};
        messages.forEach(message => {
            const key = `${message.channelId}_${message.liveChatId}`;
            const _messages = messagesByLiveChat[key] || [];
            _messages.push(message);
            messagesByLiveChat[key] = _messages;
        });

        Object.keys(messagesByLiveChat).forEach(key => {
            const [channelId, liveChatId] = key.split('_');
            const messages = messagesByLiveChat[key];

            queryClient.setQueryData(['messages', channelId, liveChatId, baseDate], ((oldData: any) => {
                if (oldData && oldData.pages && oldData.pages.length > 0) {
                    const aheadPages = oldData.pages.slice(0, oldData.pages.length - 1);
                    const lastPage = oldData.pages.at(-1);
                    let lastPageMessages = [...lastPage.result];
                    // let lastPageMessages = lastPage.result;

                    messages.forEach(message => {
                        const hasSendMessage = message.sendId && lastPageMessages.some((msg: Message) => msg.sendId === message.sendId);
                        if (hasSendMessage) {
                            lastPageMessages = lastPageMessages.map((msg: Message) => msg.sendId === message.sendId ? message : msg);
                        }
                        else {
                            lastPageMessages.push(message);
                        }
                    });

                    return {
                        pages: [...aheadPages, {...lastPage, result: lastPageMessages}],
                        pageParams: oldData.pageParams
                    }
                }
                else {
                    return {
                        pages: [{result: messages, pageable: {isFirst: false, isLast: false, limit: 50}}],
                        // pageParams: oldData.pageParams
                    }
                }
            }));
        });
    }, [queryClient, baseDate]);


    return useMemo(() => {
        return {
            messages,
            initialized,
            lastConfirmedMessageId,
            setLastConfirmedMessageId,
            isFetching,
            isFetchingPreviousPage,
            isFetchingNextPage,
            hasPreviousPage,
            hasNextPage,
            fetchPreviousPage,
            fetchNextPage,
            addMessage,
            addMessages,
            deleteMessage,
            updateMessage
        };
    }, [
        messages,
        initialized,
        lastConfirmedMessageId,
        setLastConfirmedMessageId,
        isFetching,
        isFetchingPreviousPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
        hasNextPage,
        fetchNextPage,
        addMessage,
        addMessages,
        deleteMessage,
        updateMessage
    ]);

};

const MANAGER_HISTORY_TYPE = ['join', 'leave', 'change_assignee', 'holding', 'resume'];

export default useMessageHandler;