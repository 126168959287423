import React, {useRef, useState} from "react";
import useModal, {MaruModal} from "../../hook/useModal";
import {useIntl} from "react-intl";
import {Button, Form, Modal} from "react-bootstrap";
import categoryRepository from "../../repository/CategoryRepository";
import _ from "lodash";
import {Category} from "../../model/category";
import useCreateCategory from "../../query/category/useCreateCategory";
import toast from "react-hot-toast";

const INITIAL_INVALID = {name: false};

const CategoryRegistrationModal: React.FC<CategoryRegistrationModalProps> = ({channelId, onCreated}) => {
    const intl = useIntl();
    const modal = useModal();

    const nameRef = useRef<HTMLInputElement>(null);

    const [invalid, setInvalid] = useState(INITIAL_INVALID);
    const [duplicatedName, setDuplicatedName] = useState(false);

    const {mutate: createCategory} = useCreateCategory(channelId);

    const checkName = _.debounce(() => {
        const name = nameRef.current?.value.trim();
        if (!name) {
            setDuplicatedName(false);
            return;
        }

        if (!modal.status['category-registration-modal']) {
            return;
        }

        categoryRepository.find(channelId, name).then(({data}) => {
            if (data.result.some((category: Category) => category.name === name)) {
                setDuplicatedName(true);
            }
            else {
                setDuplicatedName(false);
            }
        });
    }, 300);

    const onChangeName = (e: React.ChangeEvent) => {
        e.stopPropagation();
        checkName();
    };

    const onClickCancelBtn = (e: React.MouseEvent) => {
        e.preventDefault();
        onClosed();

        modal.close('category-registration-modal');
    };

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const name = nameRef.current?.value;

        const invalid = {name: !name};
        if (Object.values(invalid).some(value => value) || duplicatedName) {
            setInvalid(invalid);
            return;
        }
        setInvalid(INITIAL_INVALID);

        createCategory(name!, {
            onSuccess: (_) => {
                onCreated && onCreated();
                onClosed();
                modal.close('category-registration-modal');
            },
            onError: (error) => {
                if (error.response?.status !== 400) {
                    toast.error(intl.formatMessage({id: 'i000120'}));
                }
            }
        });
    };

    const onClosed = () => {
        setInvalid(INITIAL_INVALID);
        setDuplicatedName(false);

    }

    return (
        <MaruModal id={'category-registration-modal'} title={intl.formatMessage({id: 'i000118'})} onClose={onClosed}>
            <Modal.Body>
                <Form id={'category-registration-form'} onSubmit={onSubmit}>
                    <Form.Group className="mb-1">
                        <Form.Label>{intl.formatMessage({id: 'i000119'})}</Form.Label>
                        <Form.Control onChange={onChangeName} ref={nameRef} isInvalid={invalid.name || duplicatedName} />
                        <Form.Control.Feedback type={'invalid'}>
                            {duplicatedName ? intl.formatMessage({id: 'i000125'}) : intl.formatMessage({id: 'i000024'})}
                        </Form.Control.Feedback>
                        <Form.Text>{intl.formatMessage({id: 'i000287'})}</Form.Text>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" className="btn-rounded" onClick={onClickCancelBtn}>{intl.formatMessage({id: 'i000012'})}</Button>
                <Button type="submit" className="btn-rounded" form={'category-registration-form'}>{intl.formatMessage({id: 'i000040'})}</Button>
            </Modal.Footer>
        </MaruModal>
    )
};

type CategoryRegistrationModalProps = {
    channelId: string;
    onCreated?: () => void;
}

export default CategoryRegistrationModal;