import React, {useCallback, useState} from "react";
import SettingViewWrapper from "../../component/setting/SettingViewWrapper";
import {Button, Tab, Table, Tabs} from "react-bootstrap";
import styled from "styled-components";
import {useIntl} from "react-intl";
import {useParams} from "react-router-dom";
import {CustomSnippet} from "../../model/customSnippet";
import moment from "moment/moment";
import useCustomSnippets from "../../query/customSnippet/useCustomSnippets";
import useModal from "../../hook/useModal";
import CustomPanelSnippetRegistrationModal, {
    REGISTRATION_MODAL_ID
} from "../../component/setting/CustomPanelSnippetRegistrationModal";
import CustomPanelSnippetUpdateModal, {UPDATE_MODAL_ID} from "../../component/setting/CustomPanelSnippetUpdateModal";
import {useDialog} from "../../hook/useDialog";
import useDeleteCustomSnippet from "../../query/customSnippet/useDeleteCustomSnippet";
import toast from "react-hot-toast";
import FullAreaSpinner from "../../component/FullAreaSpinner";
import CustomPanelSnippetDemo from "../../component/setting/CustomPanelSnippetDemo";
import electronRuntime from "../../core/electronRuntime";

const DOCUMENT_URL = "https://swingchat.notion.site/9756880e04014d6dbe928a90e33f0f25";

const CustomPanelSettingView: React.FC = () => {
    const intl = useIntl();
    const modal = useModal();

    const { channelId } = useParams<{channelId: string}>();

    const { data: snippets, isLoading: isSnippetsLoading } = useCustomSnippets(channelId!);

    const [activeKey, setActiveKey] = useState("snippets");

    const onClickDocument = () => {
        if (electronRuntime) {
            electronRuntime.send('open-default-browser', DOCUMENT_URL);
        } else {
            window.open(DOCUMENT_URL, "_blank");
        }
    }

    const onClickAddSnippet = (e: React.MouseEvent) => {
        e.preventDefault();
        modal.open(REGISTRATION_MODAL_ID);
    };

    return (
        <SettingViewWrapper>
            <div className="setting-title">
                <h3>{intl.formatMessage({id: "i200081"})}</h3>
                <p>{intl.formatMessage({id: "i200082"})}</p>
            </div>

            <CustomPanelSettingTabsStyle>
                <Tabs id="custom-panel-setting-tabs"
                      className="nav-bordered mx-0"
                      activeKey={activeKey}
                      onSelect={(key) => setActiveKey(key ?? "snippets")}>
                    <Tab title={intl.formatMessage({id: "i200108"})} eventKey="snippets">
                        {isSnippetsLoading ?
                            <FullAreaSpinner />
                            :
                            <div className="content">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <div>{intl.formatMessage({id: "i200083"})}</div>
                                    <Button variant="info" className="btn-rounded" onClick={onClickAddSnippet}>
                                        <i className="mdi mdi-plus me-1"/>
                                        {intl.formatMessage({id: "i200084"})}
                                    </Button>
                                </div>
                                <CustomPanelSnippets channelId={channelId!} snippets={snippets ?? []}/>

                                <CustomPanelSnippetRegistrationModal channelId={channelId!}/>
                                <CustomPanelSnippetUpdateModal channelId={channelId!}/>
                            </div>
                        }
                    </Tab>

                    <Tab title={intl.formatMessage({id: "i200096"})} eventKey="demo">
                        <CustomPanelSnippetDemo />
                    </Tab>
                    
                    <Tab title={
                        <div onClick={onClickDocument}>
                            {intl.formatMessage({id: "i200085"})}
                            <i className="mdi mdi-open-in-new ms-1"/>
                        </div>
                    }
                    />
                </Tabs>
            </CustomPanelSettingTabsStyle>
        </SettingViewWrapper>
    )
};

const CustomPanelSnippets: React.FC<{channelId: string, snippets: CustomSnippet[]}> = ({channelId, snippets}) => {
    const { mutateAsync: _deleteSnippet } = useDeleteCustomSnippet(channelId);
    const intl = useIntl();

    const deleteSnippet = useCallback(async (snippetId: string) => {
        await _deleteSnippet(snippetId, {
            onSuccess: () => {
                toast.success(intl.formatMessage({id: "i200086"}));
            },
            onError: () => {
                toast.error(intl.formatMessage({id: "i200087"}));
            }
        })
    }, [_deleteSnippet, intl]);

    return (
        <SnippetsTableStyle>
            <Table className="table-centered mb-0">
                <colgroup>
                    <col width="200px"/>
                    <col width="*"/>
                    <col width="300px"/>
                    <col width="200px"/>
                    <col width="150px"/>
                </colgroup>
                <thead>
                <tr>
                    <th>{intl.formatMessage({id: "i200088"})}</th>
                    <th>{intl.formatMessage({id: "i200089"})}</th>
                    <th className="text-center">{intl.formatMessage({id: "i200090"})}</th>
                    <th className="text-center">{intl.formatMessage({id: "i200091"})}</th>
                    <th className="text-center">{intl.formatMessage({id: "i200092"})}</th>
                </tr>
                </thead>
            </Table>
            <div style={{maxHeight: "400px", overflowY: "auto"}}>
                <Table className="table-centered mb-0" hover>
                    <colgroup>
                        <col width="200px"/>
                        <col width="*"/>
                        <col width="300px"/>
                        <col width="200px"/>
                        <col width="150px"/>
                    </colgroup>
                    <tbody>
                    {snippets.map(snippet => (
                        <SnippetFragment key={snippet._id} snippet={snippet} deleteSnippet={deleteSnippet}/>
                    ))}
                    </tbody>
                </Table>
            </div>
        </SnippetsTableStyle>
    )
};

type SnippetFragmentProps = {
    snippet: CustomSnippet,
    deleteSnippet: (snippetId: string) => Promise<void>
}

const SnippetFragment: React.FC<SnippetFragmentProps> = (props) => {
    const { snippet, deleteSnippet } = props;

    const intl = useIntl();
    const modal = useModal();
    const dialog = useDialog();

    const onClickCopy = useCallback(() => {
        navigator.clipboard.writeText(snippet.token).then(() => {
            toast.success(intl.formatMessage({id: 'i100058'}));
        });
    }, [intl, snippet.token])

    const onClickUpdateSnippet = () => {
        modal.open(UPDATE_MODAL_ID, snippet)
    };

    const onClickDeleteSnippet = () => {
        dialog.open({
            variant: "danger",
            title: intl.formatMessage({id: "i200093"}),
            content: intl.formatMessage({id: "i200094"}),
            confirmText: intl.formatMessage({id: "i200095"}),
            onConfirm: async () => {
                await deleteSnippet(snippet._id);
            }
        })
    };

    return (
        <SnippetRowStyle>
            <td className="text-name text-truncate">{snippet.name}</td>
            <td className="text-url text-truncate font-monospace">{snippet.url}</td>
            <td className="text-center">
                <div className="d-flex align-items-center font-monospace">
                    {snippet.token}
                    <i className="mdi mdi-content-copy cursor-pointer ms-1" onClick={onClickCopy}/>
                </div>
            </td>
            <td className="text-center">{moment.utc(snippet.updatedAt).local().format('YYYY.MM.DD HH:mm')}</td>
            <td>
                <div className="d-flex justify-content-center align-items-center">
                    <i className="mdi mdi-square-edit-outline me-2 font-18 cursor-pointer"
                       onClick={onClickUpdateSnippet}/>
                    <i className="mdi mdi-trash-can-outline font-18 cursor-pointer text-danger"
                       onClick={onClickDeleteSnippet}/>
                </div>
            </td>
        </SnippetRowStyle>
    )
};

const SnippetRowStyle = styled.tr`
  td.text-name {
    max-width: 200px;
  }

  td.text-url {
    max-width: 600px;
  }
`;

const CustomPanelSettingTabsStyle = styled.div`
  .nav-tabs > li > .nav-link {
    color: #2c343f;
    border-top-color: #ffffff;
    border-right-color: #ffffff;
    border-left-color: #ffffff;

  }

  .nav-tabs > li > .nav-link.active {
    border-top-color: #ffffff;
    border-right-color: #ffffff;
    border-left-color: #ffffff;
    border-bottom: 2px solid #727CF5;
  }

  .tab-content > div > .content {
    margin-top: 1rem;

    .ace_editor {
      box-shadow: 0 0 10px #7e7e7e;
    }
  }
`;

const SnippetsTableStyle = styled.div`
  border: 1px solid #c5c5c5;

  table > thead {
    border-top: none !important;
    background-color: #f1f1f1;
  }
`

export default CustomPanelSettingView;
