
export declare type Page<T, P = unknown> = {result: T[], pageable: {limit: number, isLast?: boolean, isFirst?: boolean} & P};

export declare type ReactQueryOptions = {
    retry?: boolean
    suspense?: boolean
    useErrorBoundary?: boolean
    refetchOnMount?: boolean
    staleTime?: number
    enabled?: boolean
    refetchInterval?: number
}
export const DEFAULT_ARGS = {retry: false, suspense: false, refetchOnMount: false, useErrorBoundary: false}