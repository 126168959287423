import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError, AxiosResponse} from "axios";
import oauthAxios from "../../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../../core/variables";

const useUpdateLiveChatFollowers = (channelId: string, liveChatId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, boolean>({
        mutationKey: ['liveChat', 'updateFollowers', liveChatId],
        mutationFn: async (isFavorite: boolean) => {
            try {
                if (isFavorite) {
                    return await oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/livechats/${liveChatId}/followers`);
                } else {}
                return await oauthAxios.delete(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/livechats/${liveChatId}/followers`);

            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => queryClient.invalidateQueries(['liveChat', channelId, liveChatId], {exact: true})
    });
}

export default useUpdateLiveChatFollowers;
