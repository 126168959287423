import {useQuery} from "@tanstack/react-query";
import chatUserRepository from "../repository/ChatUserRepository";
import {ChatUserAttribute} from "../model/chatUser";
import {LiveChatError, toLiveChatError} from "../error";


export const getData = async (channelId: string) => {
    try {
        const {data} = await chatUserRepository.readAttributes(channelId);
        return data.result.filter((attribute: ChatUserAttribute) => attribute.expose);
    } catch (e) {
        throw toLiveChatError(e);
    }

}

const useUserAttributes = (channelId: string) => {
    return useQuery<ChatUserAttribute[], LiveChatError>(['useAttributes', channelId], async () => getData(channelId), {suspense: false});
};


export default useUserAttributes;