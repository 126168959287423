import React, {useRef} from "react";
import {Dropdown, Form} from "react-bootstrap";
import {useIntl} from "react-intl";
import {useRecoilValue, useSetRecoilState} from "recoil";
import liveChatDashboardCharts from "../../recoil/liveChatDashboardCharts";
import {LIVE_CHAT_DASHBOARD_CHARTS_STORAGE_KEY} from "../../core/variables";

const ResponseStatusDashboardController: React.FC = () => {
    const intl = useIntl()

    return (
        <div className="d-flex justify-content-start align-items-center">
            <p className="m-0 font-16">{intl.formatMessage({id: "i200066"})}</p>
            <Dropdown>
                <Dropdown.Toggle id="dashboard-charts-controller-toggle"
                                 as="i" bsPrefix="unused"
                                 className="mdi mdi-tune font-24 ml-1"
                                 role="button" />
                <Dropdown.Menu>
                    <DashboardControllerList />
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
};

const DashboardControllerList: React.FC = () => {
    const intl = useIntl();
    const dashboardChats = useRecoilValue(liveChatDashboardCharts);

    return (
        <div className="p-1 mb-0">
            {dashboardChats.map(chart => (
                <DashboardControllerItem id={chart.id}
                                         key={chart.id}
                                         value={intl.formatMessage({id: chart.i18n})}
                                         show={chart.show} />
            ))}
        </div>
    )
}

type DashboardControllerItemProps = {
    id: string
    value: string
    show: boolean
};

const DashboardControllerItem: React.FC<DashboardControllerItemProps> = (props) => {
    const { id, value, show } = props;
    const ref = useRef<HTMLInputElement>(null)
    const setDashboardCharts = useSetRecoilState(liveChatDashboardCharts);

    const onClick = () => {
        setDashboardCharts(prev => {
            const charts = prev.map(chart => {
                if (chart.id === id) {
                    return {
                        ...chart,
                        show: ref.current?.checked ?? false
                    }
                } else {
                    return chart
                }
            });

            window.localStorage.setItem(LIVE_CHAT_DASHBOARD_CHARTS_STORAGE_KEY, JSON.stringify(charts));

            return charts;
        })
    };

    return (
        <li className="d-flex align-items-center rounded p-1">
            <p className="mb-0 flex-grow-1 mr-2">{value}</p>
            <Form.Check id={id} defaultChecked={show} onClick={onClick} ref={ref}/>
        </li>
    )
};

export default ResponseStatusDashboardController;
