import React, {useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {useParams} from "react-router-dom";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Channel} from "../../model/channel";
import classNames from "classnames";
import TooltipIcon from "../../component/TooltipIcon";
import SettingViewWrapper from "../../component/setting/SettingViewWrapper";
import useChannel from "../../query/channel/useChannel";
import useUpdateChannel from "../../query/channel/useUpdateChannel";
import toast from "react-hot-toast";


const AssignRuleConfigView: React.FC = () => {
    const intl = useIntl();

    const [saving, setSaving] = useState(false);

    const assignRuleRef = useRef<HTMLSelectElement>(null);
    const assignCategoryRuleLimitRef = useRef<HTMLInputElement>(null);

    const {channelId} = useParams<{channelId: string}>();

    if (!channelId) {
        throw new Error('Required channelId path params')
    }

    const {data: channel} = useChannel(channelId);
    const {mutateAsync: updateChannel} = useUpdateChannel(channelId);

    const [showAssignCategoryRuleLimit, setShowAssignCategoryRuleLimit] = useState(channel?.config.assignRule === 'category');

    const onChangeAssignRule = (e: React.ChangeEvent) => {
        e.preventDefault();

        setShowAssignCategoryRuleLimit(assignRuleRef.current?.value === 'category');
    }

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!channel) {
            return;
        }

        setSaving(true);

        const form: Channel = {
            ...channel,
            config: {
                ...channel.config,
                assignRule: assignRuleRef.current?.value || 'default',
                assignCategoryRuleLimit: assignCategoryRuleLimitRef.current?.value ? parseInt(assignCategoryRuleLimitRef.current.value) : 5
            }
        };

        await toast.promise(
            updateChannel(form), {
                loading: intl.formatMessage(({id: 'i200003'})),
                success: () => {
                    setSaving(false);
                    return intl.formatMessage(({id: 'i000140'}));
                },
                error: () => {
                    setSaving(false);
                    return intl.formatMessage(({id: 'i000141'}));
                }
            }
        )
    };

    // for automatic update
    useEffect(() => {
        if (channel?.config) {
            if (assignRuleRef.current) {
                assignRuleRef.current.value = channel.config.assignRule;
            }

            if (assignCategoryRuleLimitRef.current) {
                assignCategoryRuleLimitRef.current.value = String(channel.config.assignCategoryRuleLimit);
            }

            setShowAssignCategoryRuleLimit(channel.config.assignRule === 'category')
        }
    }, [channel?.config])

    return (
        <SettingViewWrapper>
            <div className="setting-title">
                <h3>{intl.formatMessage({id: 'i000134'})}</h3>
                <p>{intl.formatMessage({id: 'i000135'})}</p>
            </div>

            <Form style={{maxWidth: 650}} onSubmit={onSubmit}>
                <Form.Group as={Row} className="mb-1">
                    <Form.Label column xs={4}>{intl.formatMessage({id: 'i000136'})}</Form.Label>
                    <Col className="d-flex align-items-center">
                        <Form.Control as={'select'} defaultValue={channel?.config.assignRule || 'default'} onChange={onChangeAssignRule} ref={assignRuleRef}>
                            <option value={'default'}>{intl.formatMessage({id: 'i000137'})}</option>
                            <option value={'category'}>{intl.formatMessage({id: 'i000138'})}</option>
                            <option value={'manual'}>{intl.formatMessage({id: 'i000180'})}</option>
                        </Form.Control>
                        <TooltipIcon className="ml-1" tooltip={intl.formatMessage({id: 'i000288'})} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className={classNames('mb-1', {'d-none': !showAssignCategoryRuleLimit})}>
                    <Form.Label column xs={4}>{intl.formatMessage({id: 'i000139'})}</Form.Label>
                    <Col className="d-flex align-items-center">
                        <Form.Control type={'number'} min={1} defaultValue={channel?.config.assignCategoryRuleLimit || 5} ref={assignCategoryRuleLimitRef} />
                        <TooltipIcon className="ml-1" tooltip={intl.formatMessage({id: 'i000289'})} />
                    </Col>
                </Form.Group>

                <Button type="submit" className="btn-rounded" disabled={saving}>
                    {intl.formatMessage({id: 'i000040'})}
                </Button>
            </Form>
        </SettingViewWrapper>
    );
};

export default AssignRuleConfigView;
