import {useRecoilState} from "recoil";
import players from "../recoil/players";
import {useCallback, useEffect, useMemo} from "react";


const usePlayer = (id: string) => {
    const [_players, setPlayers] = useRecoilState(players);

    useEffect(() => {
        setPlayers(prev => {
            return {...prev, [id]: {id: id, playing: false}};
        });
    }, [setPlayers, id]);

    const playing = useMemo(() => {
        return _players[id]?.playing ?? false;
    }, [_players, id]);

    const play = useCallback(() => {
        setPlayers(prev => {
            return Object.values(prev).reduce((acc, curr) => {
                return {
                    ...acc,
                    [curr.id]: {...curr, playing: curr.id === id}
                }
            }, {});
        });
    }, [id, setPlayers]);

    const stop = useCallback(() => {
        setPlayers(prev => {
            return Object.values(prev).reduce((acc, curr) => {
                return {
                    ...acc,
                    [curr.id]: {...curr, playing: curr.id === id ? false: curr.playing}
                }
            }, {});
        });
    }, [id, setPlayers]);

    return {playing: playing, play, stop};
}

export default usePlayer;