import {useMutation} from "@tanstack/react-query";
import {AxiosError, AxiosResponse} from "axios";
import oauthAxios from "../../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../../core/variables";


const useUploadFiles = () => {
    return useMutation<AxiosResponse, AxiosError, File>(['fileGroups', 'upload'], async (file: File) => {
        try {
            let form = new FormData();
            form.append('files', file);
            return oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/file-groups`, form, {
                headers: {
                    "Content-Type" : "multipart/form-data"
                }
            });
        } catch (e) {
            throw e;
        }
    });
}

export default useUploadFiles;
