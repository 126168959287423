import React from "react";
import {FloatingLabel} from "react-bootstrap";
import classNames from "classnames";
import "../assets/css/floating-label.scss";


const SCFloatingLabel: React.FC<SCFloatingLabelProps> = ({controlId, label, floatingLabel, placeholder, className, children}) => {
    return (
        <FloatingLabel controlId={controlId}
                       label={<CustomLabel label={label} floatingLabel={floatingLabel} placeholder={placeholder} />}
                       className={classNames({[className ?? '']: className})} >
            {children}
        </FloatingLabel>
    );
};

const CustomLabel: React.FC<{label: string, floatingLabel?: string, placeholder?: string}> = ({label, floatingLabel, placeholder}) => {
    if (floatingLabel) {
        return (
            <>
                <span className="floating-label">
                    {floatingLabel}
                </span>
                <span className="floating-placeholder">
                    {label}
                    {placeholder && <small className="ms-1 text-muted floating-placeholder">{placeholder}</small>}
                </span>
            </>
        );
    }
    else {
        return (
            <span>
                {label}
                {placeholder && <small className="ms-1 text-muted floating-placeholder">{placeholder}</small>}
            </span>
        );
    }
}

type SCFloatingLabelProps = {
    controlId?: string,
    label: string,
    floatingLabel?: string,
    placeholder?: string,
    className?: string,
    children: React.ReactNode
}

export default SCFloatingLabel;