import React, {useMemo, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";
import {Badge, Button, Col, Form, ListGroup, Modal, Row, Table} from "react-bootstrap";
import useModal, {MaruModal} from "../../hook/useModal";
import useCreateManagementUnitRole from "../../query/managementUnitRole/useCreateManagementUnitRole";
import {Role} from "../../model/role";
import useUpdateManagementUnitRole from "../../query/managementUnitRole/useUpdateManagementUnitRole";
import styled from "styled-components";
import useRoles from "../../query/managementUnitRole/useRoles";
import usePermissions from "../../query/permission/usePermissions";
import { CenteredIcon } from "../../component/UnstyledBootstrap";
import SettingViewWrapper from "../../component/setting/SettingViewWrapper";
import SCFloatingLabel from "../../component/SCFloatingLabel";
import toast from "react-hot-toast";


const ROLE_MODAL_ID = 'role-modal';

const RoleView: React.FC = () => {
    const intl = useIntl();
    const modal = useModal();

    const {channelId} = useParams<{channelId: string}>();

    const {data: roles} = useRoles(channelId!);

    const onClickAddRoleBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        modal.open(ROLE_MODAL_ID);
    };

    return (
        <SettingViewWrapper>
            <div className="setting-title">
                <h3>{intl.formatMessage({id: 'i000335'})}</h3>
                <p>
                    {intl.formatMessage({id: 'i000336'})}
                </p>
            </div>

            <Button type="button" variant="info" onClick={onClickAddRoleBtn} className="mt-1 mb-2 btn-rounded">
                {intl.formatMessage({id: 'i000337'})}
            </Button>

            <RoleTableStyle className="table-centered" size="sm">
                <colgroup>
                    <col width="240px" />
                    <col width="*" />
                    <col width="80px" />
                </colgroup>
                <thead>
                <tr>
                    <th>{intl.formatMessage({id: 'i000338'})}</th>
                    {/*<th>{intl.formatMessage({id: 'i000339'})}</th>*/}
                    <th>{intl.formatMessage({id: 'i000340'})}</th>
                    <th />
                </tr>
                </thead>
                <tbody>
                {roles?.map(role => (
                    <RoleRow key={role._id} role={role} />
                ))}
                </tbody>
            </RoleTableStyle>
            <RoleModal channelId={channelId!} />
        </SettingViewWrapper>
    );
};


const RoleRow = React.memo<{role: Role}>(({role}) => {
    const intl = useIntl();
    const modal = useModal();

    const {data: permissions} = usePermissions();

    const permissionDescriptions = useMemo(() => {
        return permissions?.filter(permission => role.permissions?.some(p => p === permission.name)).map(permission => permission.description).join(', ');
    }, [permissions, role.permissions]);
    
    const onClickEditBtn = (e: React.MouseEvent) => {
        e.preventDefault();
        modal.open(ROLE_MODAL_ID, role);
    }

    return (
        <tr>
            {/*<td>{role.name}</td>*/}
            <td>{role.description}</td>
            <td className="text-truncate">{permissionDescriptions}</td>
            <td className="text-end">
                {role.default ? (
                    <Badge>{intl.formatMessage({id: 'i000347'})}</Badge>
                ) : (
                    <CenteredIcon size={18} className="mdi mdi-square-edit-outline font-18" role="button" onClick={onClickEditBtn} />
                )}
            </td>
        </tr>
    )
});


const RoleModal: React.FC<{channelId: string}> = ({channelId}) => {
    const intl = useIntl();
    const modal = useModal();

    const {data: permissions} = usePermissions();
    const {mutateAsync: createManagementUnitRole, isLoading: submitting} = useCreateManagementUnitRole(channelId);
    const {mutateAsync: updateManagementUnitRole, isLoading: updating} = useUpdateManagementUnitRole(channelId);

    const [invalidation, setInvalidation] = useState({name: false, description: false});

    const nameRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLInputElement>(null);
    const permissionRefs = useRef<{[key: string]: HTMLInputElement}>({});

    const defaultRole: Role = useMemo(() => {
        return modal.data[ROLE_MODAL_ID];
    }, [modal.data]);

    const onChangeName = (e: any) => {
        if (nameRef.current) {
            nameRef.current.value = e.target.value.replace(/[^A-Za-z\d-_]/ig, '')
        }
    }

    const onClickSaveBtn = async (e: React.MouseEvent) => {
        e.preventDefault();

        const name = nameRef.current?.value;
        const description = descriptionRef.current?.value;
        const permissionNames = Object.entries(permissionRefs.current).filter(([_, checkbox]) => checkbox.checked).map(([permission, _]) => permission);

        const invalidation = {name: !name, description: !description};
        if (Object.values(invalidation).some(value => value)) {
            setInvalidation(invalidation);
            return;
        }

        try {
            if (defaultRole) {
                await updateManagementUnitRole({
                    id: defaultRole._id,
                    data: {
                        name: name!,
                        description: description,
                        permissions: permissionNames
                    }
                });
            }
            else {
                await createManagementUnitRole({
                    name: name!,
                    description: description,
                    permissions: permissionNames
                });
            }
            modal.close(ROLE_MODAL_ID);
        } catch (e) {
            toast.error(intl.formatMessage({id: 'e000001'}));
        } finally {
            setInvalidation({name: false, description: false});
        }
    }

    const onClickCloseBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        setInvalidation({name: false, description: false});
        modal.close(ROLE_MODAL_ID);
    };

    return (
        <MaruModal id="role-modal" size="lg" title={intl.formatMessage({id: 'i000345'})} backdrop={submitting ? 'static' : undefined}>
            <Modal.Body>
                <h5>{intl.formatMessage({id: 'i000341'})}</h5>
                <Row>
                    <Col xs={12} lg={6}>
                        <SCFloatingLabel controlId="form-control-role-name"
                                         floatingLabel={intl.formatMessage({id: 'i000348'})}
                                         label={intl.formatMessage({id: 'i000342'})}>
                            <Form.Control type="text"
                                          ref={nameRef}
                                          placeholder={intl.formatMessage({id: 'i000342'})}
                                          defaultValue={defaultRole?.name ?? ''}
                                          onChange={onChangeName}
                                          isInvalid={invalidation.name} />
                            <Form.Text>{intl.formatMessage({id: 'i000349'})}</Form.Text>
                            <Form.Control.Feedback className="pl-1" type="invalid">
                                {intl.formatMessage({id: 'i000024'})}
                            </Form.Control.Feedback>
                        </SCFloatingLabel>
                    </Col>
                    <Col xs={12} lg={6}>
                        <SCFloatingLabel controlId="form-control-role-description"
                                         floatingLabel={intl.formatMessage({id: 'i000339'})}
                                         label={intl.formatMessage({id: 'i000343'})}>
                            <Form.Control type="text"
                                          ref={descriptionRef}
                                          placeholder={intl.formatMessage({id: 'i000343'})}
                                          defaultValue={defaultRole?.description ?? ''}
                                          isInvalid={invalidation.description} />
                            <Form.Control.Feedback className="pl-1" type="invalid">
                                {intl.formatMessage({id: 'i000024'})}
                            </Form.Control.Feedback>
                        </SCFloatingLabel>
                    </Col>
                </Row>
                <hr className="my-3" />
                <h5>{intl.formatMessage({id: 'i000344'})}</h5>
                <div className="overflow-auto" style={{maxHeight: 'calc(100vh - 450px)'}}>
                    <ListGroup>
                        {permissions?.map(permission => (
                            <ListGroup.Item key={permission._id} className="d-flex align-items-center">
                                <div style={{width: '200px'}}>
                                    <Form.Check id={`permission-chk-${permission._id}`}
                                                label={permission.name}
                                                defaultChecked={defaultRole?.permissions?.some(permissionName => permissionName === permission.name)}
                                                ref={((ref: HTMLInputElement) => permissionRefs.current[permission.name] = ref)} />
                                </div>
                                <span>{permission.description}</span>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary"
                        className="btn-rounded"
                        disabled={submitting || updating}
                        onClick={onClickCloseBtn}>
                    {intl.formatMessage({id: 'i000302'})}
                </Button>
                <Button variant="primary"
                        className="btn-rounded"
                        disabled={submitting || updating}
                        onClick={onClickSaveBtn}>
                    {intl.formatMessage({id: 'i000040'})}
                </Button>
            </Modal.Footer>
        </MaruModal>
    );
}

const RoleTableStyle = styled(Table)`
  table-layout: fixed;
  min-width: 800px;
`


export default RoleView;