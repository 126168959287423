import React, {useRef} from "react";
import styled from "styled-components";
import {useRecoilState} from "recoil";
import qnaChatbotState from "../recoil/qnaChatbot";
import {QNA_CHATBOT_URL} from "../core/variables";
import classNames from "classnames";


const QnaChatbot: React.FC = () => {

    const [qnaChatbot, setQnaChatbot] = useRecoilState(qnaChatbotState);

    const iframeRef = useRef<HTMLIFrameElement>(null);

    const onClickCloseBtn = (e: React.MouseEvent) => {
        e.preventDefault();
        setQnaChatbot({show: false});
    }

    return (
        <QnaChatbotStyle className={classNames({'d-none': !qnaChatbot.show})}>
            <iframe id="qna-chatbot-iframe"
                    title="QnA Chatbot"
                    src={`${QNA_CHATBOT_URL}`}
                    width="100%"
                    height="100%"
                    style={{border: 'none', borderRadius: '8px'}}
                    loading="lazy"
                    ref={iframeRef} />
            <div className="close-icon-wrapper">
                <i className="mdi mdi-close text-white" role="button" onClick={onClickCloseBtn} />
            </div>
        </QnaChatbotStyle>
    );

}



const QnaChatbotStyle = styled.div`
  position: fixed;
  bottom: 30px;
  right: 25px;
  width: 370px;
  height: 710px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 12px 60px 5px !important;
  border-radius: 8px;
  background-color: #ffffff;
  //z-index: 99999;
  
  .close-icon-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 10px;
    top: 10px;
    width: 34px;
    height: 34px;
    //background-color: var(--ct-primary);
    border-radius: 50%;
    i {
      font-size: 30px;
    }
  }
`;

export default QnaChatbot;