import React from "react";
import LiveChatUI from "../../component/chat/LiveChatUI";
import {useParams} from "react-router-dom";
import useLiveChat from "../../query/liveChat/useLiveChat";


const ChatFullSizeView: React.FC = () => {
    const {channelId, liveChatId} = useParams<{channelId: string, liveChatId: string}>();

    if (!channelId || !liveChatId) {
        throw new Error('Required channelId path params')
    }

    const {data: liveChat, isSuccess} = useLiveChat(channelId, liveChatId);

    if (isSuccess) {
        return (
            <LiveChatUI channelId={channelId} liveChat={liveChat} fullSize />
        );
    }
    else {
        return null;
    }
};

export default ChatFullSizeView;
