import React from "react";
import styled from "styled-components";


const ChatPanelLabel: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({children, ...props}) => {

    return (
        <ChatPanelLabelStyle {...props}>
            <label>{children}</label>
        </ChatPanelLabelStyle>
    )
}

const ChatPanelLabelStyle = styled.div`
  width: 95px;
  margin-right: 0.4rem;
  flex-shrink: 0;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  
  label {
    //color: #98a6ad;
    font-size: 13px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 28px;
  }
`;

export default ChatPanelLabel;