import React, {useMemo, useState} from "react";
import styled from "styled-components";
import {Manager} from "../../model/manager";
import {isWithinDaysFromNow, utcToLocal} from "../../util/datetime";
import {Announcement} from "../../model/announcement";
import {TextMessage} from "../../model/message";
import parse from "html-react-parser";
import useAnnouncements from "../../query/announcement/useAnnouncements";
import {useIntl} from "react-intl";
import {Tab, Tabs} from "react-bootstrap";
import useFeeds from "../../query/feed/useFeeds";
import {ManagerFeed} from "../../model/managerFeeds";
import {useLocalStorageValue} from "@react-hookz/web";
import {UnreadToast} from "../../model/unreadToast";
import {READ_TOASTS_STORAGE_KEY} from "../../core/variables";
import classNames from "classnames";

type ChannelNotificationViewProps = {
    channelId: string;
    managerUserId: string;
    managers: Manager[];
}
const ChannelNotificationView: React.FC<ChannelNotificationViewProps> = (props) => {
    const { channelId, managerUserId, managers } = props;
    const intl = useIntl();

    const [readToasts] = useLocalStorageValue<UnreadToast[]>(
        READ_TOASTS_STORAGE_KEY, [], {storeDefaultValue: true}
    );

    const readToastIds = useMemo(() => {
        return readToasts.map((toast) => toast._id) ?? []
    }, [readToasts])

    const [activeKey, setActiveKey] = useState("announcement");

    const { data: announcements, isLoading: isAnnouncementsLoading } = useAnnouncements(channelId);
    const { data: feeds, isLoading: isFeedsLoading } = useFeeds(channelId, {refetchOnMount: true})

    return (
        <NotificationViewStyle>
            <h2>{intl.formatMessage({id: "i210056"})}</h2>


            <Tabs id="channel-notification-tabs"
                  className="nav-bordered mx-0 mt-3"
                  activeKey={activeKey}
                  onSelect={(key) => setActiveKey(key ?? "announcement")}>

                <Tab title={intl.formatMessage({id: "i210057"})} eventKey="announcement">
                    <div className="announcements-container">
                        {announcements && announcements.map((announcement, index) => (
                            <AnnouncementRow key={`announcement-row-${index}`}
                                             announcement={announcement}
                                             managerUserId={managerUserId}
                                             managers={managers}/>
                        ))}

                        {!isAnnouncementsLoading && !announcements?.length &&
                            <div className="text-center mt-2">
                                채널에 전송된 공지사항이 없습니다.
                            </div>
                        }
                    </div>
                </Tab>

                <Tab title={intl.formatMessage({id: "i210058"})} eventKey="personal" className='feeds-tab'>
                    {feeds && feeds.map((feed, index) => (
                        <FeedRow key={`feed-row-${index}`}
                                 feed={feed}
                                 managerUserId={managerUserId}
                                 isRead={readToastIds.includes(feed._id)}
                                 managers={managers}
                        />
                    ))}
                    {!isFeedsLoading && !feeds?.length &&
                        <div className="text-center mt-2">
                            나에게 전송된 개인 알림이 없습니다.
                        </div>
                    }
                </Tab>

            </Tabs>
        </NotificationViewStyle>
    )
}

type AnnouncementRowProps = {
    announcement: Announcement;
    managerUserId: string;
    managers: Manager[];
}

const AnnouncementRow: React.FC<AnnouncementRowProps> = (props) => {
    const { announcement, managers } = props;
    const intl = useIntl();
    const author = useMemo(() => {
        return managers.find(manager => manager.userId === announcement.authorId);
    }, [announcement, managers]);

    const announcementContent = useMemo(() => {
        const value = announcement.blocks[0].value as TextMessage;
        return value.html;
    }, [announcement])

    const isNew = useMemo(() => {
        return isWithinDaysFromNow(announcement.createdAt, 7);
    }, [announcement]);

    return (
        <div className="announcement-item">
            <div className="d-flex flex-column align-items-center">
                <i className="mdi mdi-bullhorn-variant-outline font-20 mb-1"/>
                {isNew && <div className="new-badge">{intl.formatMessage({id: "i210059"})}</div>}
            </div>
            <div className="mx-2 flex-grow-1">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <div className="font-15">{author?.name ?? intl.formatMessage({id: "i210040"})}</div>
                    <div>{utcToLocal(announcement.createdAt, "LLL")}</div>
                </div>
                <div style={{whiteSpace: "pre-wrap"}}>
                    {parse(announcementContent)}
                </div>
            </div>
        </div>
    )
}

type FeedRowProps = {
    feed: ManagerFeed;
    managerUserId: string;
    managers: Manager[];
    isRead: boolean
}

const FeedRow: React.FC<FeedRowProps> = (props) => {
    const { feed, managers } = props;
    const intl = useIntl();
    const author = useMemo(() => {
        if (feed.authorId) {
            return managers.find(manager => manager.userId === feed.authorId)?.name;
        }

        if (feed.senderIsSystem) {
            return intl.formatMessage({id: "i000301"})
        }
    }, [feed, managers, intl]);

    const announcementContent = useMemo(() => {
        const value = feed.blocks[0].value as TextMessage;
        return value.html;
    }, [feed])

    const isNew = useMemo(() => {
        return isWithinDaysFromNow(feed.createdAt, 7) && !props.isRead;
    }, [feed, props.isRead]);

    return (
        <div className="announcement-item">
            <div className="d-flex flex-column align-items-center">
                <i className={classNames("mdi font-20 mb-1", isNew ? "mdi-email-outline" : "mdi-email-open-outline")}/>
                {isNew && <div className="new-badge">{intl.formatMessage({id: "i210059"})}</div>}
            </div>
            <div className="mx-2 flex-grow-1">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <div className="font-15">{author ?? intl.formatMessage({id: "i210040"})}</div>
                    <div>{utcToLocal(feed.createdAt, "LLL")}</div>
                </div>
                <div style={{whiteSpace: "pre-wrap"}}>
                    {parse(announcementContent)}
                </div>
            </div>
        </div>
    )
}

const NotificationViewStyle = styled.div`
  padding: 3rem;
  width: 100%;
  min-width: 700px;
  height: 100%;

  h2 {
    font-weight: bold;
  }

  .nav-tabs > li > .nav-link {
    color: #2c343f;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    background-color: transparent;
  }

  .nav-tabs > li > .nav-link.active {
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom: 2px solid #000000;
    background-color: transparent;
  }

  .tab-content {
    height: 90%;
  }

  .tab-pane {
    height: 100%;
  }

  .announcements-container, .feeds-tab {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .announcement-item {
      width: 100%;
      height: fit-content;
      background-color: white;
      border: 1px solid #8c8c8c;
      border-radius: 5px;

      display: flex;
      padding: 0.75rem 0.5rem;
      margin-bottom: 0.5rem;

      
      & > div:nth-child(1) {
        flex: 0 0 50px;
        
        & > i {
          position: relative;
          bottom: 3px;
        }
      }

      & > div:nth-child(2) {
        flex: 1 0 0;
      }
      
      .new-badge {
        border: 1px solid #FF5B5B;
        border-radius: 10px;
        color: #FF5B5B;
        font-size: 9px;
        padding: 1px 5px;
      }
    }
  }
`;

export default ChannelNotificationView;
