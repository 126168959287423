import React from "react";
import WebhookListStore from "./WebhookListStore";
import WebhookStore from "./WebhookStore";
import BoardListStore from "./BoardListStore";
import BoardArticleListStore from "./BoardArticleListStore";
import BoardCommentListStore from "./BoardCommentListStore";
import BoardUnreadArticleIdListStore from "./BoardUnreadArticleIdListStore";


export const storeContext = React.createContext({
    webhookListStore: new WebhookListStore(),
    webhookStore: new WebhookStore(),
    boardListStore: new BoardListStore(),
    boardArticleListStore: new BoardArticleListStore(),
    boardCommentListStore: new BoardCommentListStore(),
    boardUnreadArticleIdListStore: new BoardUnreadArticleIdListStore(),
});
