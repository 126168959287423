import oauthAxios from "../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../core/variables";


class WebhookRepository {
    async create(channelId: string, name: string, url: string) {
        return oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/webhooks`, {name, url})
    }

    async find(channelId: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/webhooks`)
    }

    async read(channelId: string, webhookId: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/webhooks/${webhookId}`)
    }

    async update(channelId: string, webhookId: string, name: string, url: string, token: string, events: string[]) {
        return oauthAxios.put(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/webhooks/${webhookId}`, {name, url, token, events})
    }

    async delete(channelId: string, webhookId: string) {
        return oauthAxios.delete(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/webhooks/${webhookId}`)
    }
}

export default new WebhookRepository();