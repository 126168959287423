import React from "react";
import parse from "html-react-parser";
const DELIMITER = '\u0001';
// eslint-disable-next-line no-useless-escape
const REGEX_URL = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

const splitByURL = (text: string) => text.replace(REGEX_URL, (text) => DELIMITER + text + DELIMITER).split(DELIMITER);


type TDefaultUrl = {
    url: string;
    [key: string]: any;
};

const DefaultUrl: React.FC<TDefaultUrl> = ({ url, ...props }) => (
    <a href={url} {...props} rel="noopener noreferrer" target="_blank">
        {url}
    </a>
);

const hasUrl = (text: string) => {
    return REGEX_URL.test(text);
};

const linkify = (text: string, Component = DefaultUrl) => {
    const dataArray = splitByURL(text);
    return dataArray.map((el, index) => {
        const isUrl = REGEX_URL.test(el);
        // if (!isUrl) {
        //     const textArray = splitByDoubleQuotes(el);
        //     return textArray.map((el, index) => {
        //         const hasDoubleQuotes = REGEX_BOLD.test(el);
        //         if (hasDoubleQuotes) {
        //             return <b key={`bold_text${index}`}>{el}</b>;
        //         } else {
        //             return <Fragment key={`text${index}`}>{el}</Fragment>;
        //         }
        //     });
        // }
        if (isUrl) {
            return <Component key={`url${index}`} url={el}/>;
        } else {
            return <React.Fragment key={`text${index}`}>{el}</React.Fragment>;
        }
    });
};

interface LinkifyProps {
    children?: string;
}

const Linkify: React.FC<LinkifyProps> = ({ children }) => {
    const text = `${children}` || "";
    const hasHtml = /<\/?[a-z][\s\S]*>/i.test(text);
    if (hasHtml) {
        return <div className="pb-1">{parse(text)}</div>;
    }

    return <p>{linkify(text)}</p>;
};

export { Linkify, linkify, hasUrl };