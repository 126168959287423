import {action, makeObservable, observable} from "mobx";
import {StoreState} from "../model";
import boardRepository from "../repository/BoardRepository";

class BoardUnreadArticleIdListStore {
    @observable
    data: {[key: string]: string[]} = {};

    @observable
    state: StoreState = 'none';

    constructor() {
        makeObservable(this);
    }

    @action
    async load(channelId: string, boardIds: string[]) {
        this.state = 'pending';
        try {
            const {data} = await boardRepository.findUnreadArticleIds(channelId, boardIds);
            this.data = data.result;
            this.state = 'done'
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    async read(boardId: string, articleId: string) {
        const _data = {...this.data}
        if (boardId in _data) {
            _data[boardId] = _data[boardId].filter(v => v !== articleId)
        }

        this.data = _data
    }

    @action
    addArticleId(boardId: string, articleId: string) {

        if (!(boardId in this.data)) {
            return
        }

        const storedArticleIds = this.data[boardId];
        if (!storedArticleIds.includes(articleId)) {
            storedArticleIds.push(articleId);

            this.data = {
                ...this.data,
                [boardId]: storedArticleIds
            };
        }
    }

    @action
    clear() {
        this.data = {};
        this.state = 'none';
    }
}

export default BoardUnreadArticleIdListStore;
