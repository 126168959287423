import {DefaultRepository} from "./index";
import {LIVE_CHAT_API_HOST} from "../core/variables";
import qs from "qs";
import oauthAxios from "../core/oauthAxios";
import {MessageQueryDirection} from "../model/message";
import {ChatSearchRequestParams} from "../model/chatSearch";

class MessageRepository extends DefaultRepository {

    async findUnreadMessages(channelId: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/messages/unread`);
    }

    async findLatestMessages(channelId: string, liveChatIds: string[]) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/messages/latest`, {
            params: {
                liveChatId: liveChatIds
            },
            paramsSerializer: {
                serialize: (params) => qs.stringify(params, {arrayFormat: "repeat"})
            }
        });
    }

    async find(channelId: string, liveChatId: string, direction?: MessageQueryDirection, baseDate?: string, limit?: number) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/livechats/${liveChatId}/messages`, {
            params: {
                direction, baseDate, limit
            }
        });
    }

    async predict(channelId: string, liveChatId: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/livechats/${liveChatId}/messages/predict`);
    }

    async uploadFile(channelId: string, liveChatId: string, file: File) {
        let form = new FormData();
        form.append('files', file);
        return oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/livechats/${liveChatId}/messages/files`, form, {
            headers: {
                "Content-Type" : "multipart/form-data"
            }
        });
    }

    async search(channelId: string, params: ChatSearchRequestParams) {
        return oauthAxios.post(
            `${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/messages/search`,
            {
                keyword: params.searchText,
                page: params.page,
                size: params.limit,
                managerIds: params.managerIds,
                chatUserIds: params.chatUserIds,
                startDate: params.startDate,
                endDate: params.endDate,
                sortBy: params.sortType
            }
        )
    }
}

export default new MessageRepository();
