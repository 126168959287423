import React, {useMemo} from "react";
import ReactPlayer from "react-player";
import * as uuid from "uuid";
import usePlayer from "../../hook/usePlayer";

const VideoMessage: React.FC<{url: string}> = ({url}) => {
    const playerId = useMemo(() => {
        return uuid.v4()
    }, []);

    const {playing, play, stop} = usePlayer(playerId);

    const onPlay = () => {
        play();
    }

    const onPause = () => {
        stop();
    }

    return (
        <div className="message-content my-1">
            <ReactPlayer url={url}
                         width={480}
                         height={270}
                         controls={true}
                         playing={playing}
                         onPlay={onPlay}
                         onPause={onPause}
                         config={{
                             file: {
                                 attributes: {
                                     controlsList: "nodownload",
                                     disablePictureInPicture: true
                                 }
                             }
                         }}/>
        </div>
    );
};

export default VideoMessage;