import React, {useEffect, useMemo, useState} from "react";
import WindowsControls from "./WindowsControls";
import styled from "styled-components";
import electronRuntime from "../core/electronRuntime";
import logoImg from "../assets/images/swingchat_logo.png";
import {useLocation} from "react-router-dom";
import {useIntl} from "react-intl";
import classNames from "classnames";
import {useRecoilValue} from "recoil";
import platformState from "../recoil/platform";
import useTooltip from "../hook/useTooltip";
import appUpdateEventState from "../recoil/appUpdateEvent";
import {useHotkeys} from "react-hotkeys-hook";

const TitleBar: React.FC = () => {
    const intl = useIntl();
    const location = useLocation();

    const [navigationInfo, setNavigationInfo] = useState({canGoBack: false, canGoForward: false});
    // const [histories, setHistories] = useState<string[]>([]);

    const platform = useRecoilValue(platformState);
    const appUpdateEvent = useRecoilValue(appUpdateEventState);

    const specialHotKey = useMemo(() => {
        return platform === 'darwin' ? 'meta' : 'ctrl';
    }, [platform]);

    const specialHotImoKey = useMemo(() => {
        return platform === 'darwin' ? '⌘' : 'Ctrl';
    }, [platform]);

    const updateTooltipText = useMemo(() => {
        switch (appUpdateEvent) {
            case 'update-available-manual':
                return intl.formatMessage({id: 'i000355'});
            case 'download-progress':
                return intl.formatMessage({id: 'i000355'});
            case 'update-downloaded':
                return intl.formatMessage({id: 'i000379'});
            default:
                return '';
        }
    }, [appUpdateEvent, intl]);

    const refreshTooltip = useTooltip(`${intl.formatMessage({id: 'i000265'})} ${specialHotImoKey}+R`);
    const goBackTooltip = useTooltip(`${intl.formatMessage({id: 'i000263'})} ${specialHotImoKey}+[`);
    const goForwardTooltip = useTooltip(`${intl.formatMessage({id: 'i000264'})} ${specialHotImoKey}+]`);
    const updateTooltip = useTooltip(updateTooltipText);

    const styles = useMemo(() => {
        if (platform === 'darwin') {
            return {
                height: '40px',
                padding: '0 20px 0 80px'
            };
        }
        else {
            return {
                height: '32px',
                padding: '0 150px 0 10px'
            };
        }
    }, [platform]);

    useHotkeys(`${specialHotKey}+[`, (event) => {
        event.preventDefault();
        if (electronRuntime) {
            electronRuntime.invoke('go-back').then();
        }
    }, [specialHotKey])

    useHotkeys(`${specialHotKey}+]`, (event) => {
        event.preventDefault();
        if (electronRuntime) {
            electronRuntime.invoke('go-forward').then();
        }
    }, [specialHotKey])

    useHotkeys(`${specialHotKey}+R`, (event) => {
        event.preventDefault();
        if (electronRuntime) {
            electronRuntime.invoke('reload').then();
        }
    }, [specialHotKey]);

    useEffect(() => {
        if (electronRuntime) {
            electronRuntime.invoke('navigation-info').then(info => {
                setNavigationInfo(info);
            });
        }
    }, [location.pathname]);

    const onClickReloadBtn = async (e: React.MouseEvent) => {
        e.preventDefault();
        if (electronRuntime) {
            await electronRuntime.invoke('reload');
        }
        else {
            window.location.reload();
        }
    }

    const onClickGoBackBtn = async (e: React.MouseEvent) => {
        e.preventDefault();
        // history.goBack();

        if (electronRuntime) {
            await electronRuntime.invoke('go-back');
        }
    };

    const onClickGoForwardBtn = async (e: React.MouseEvent) => {
        e.preventDefault();

        // history.goForward();
        if (electronRuntime) {
            await electronRuntime.invoke('go-forward');
        }
    };

    const onClickUpdateBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        if (electronRuntime) {
            if (appUpdateEvent === 'update-available-manual') {
                return;
            }
            electronRuntime.send('update', 'ping');
        }
    }

    return (
        <>
            <TitleBarStyle platform={platform} style={styles}>
                <div className="title-items-wrapper">
                    <div className="d-flex align-items-center text-light" style={{width: '100px'}}>
                        {platform !== 'darwin' && (
                            <div className="mr-4">
                                <img src={logoImg} alt="logo" height="18px" />
                            </div>
                        )}
                        <div className={classNames('tool-icon-btn', {'disabled': !navigationInfo.canGoBack})} onClick={onClickGoBackBtn}>
                            <>
                                <i className="mdi mdi-arrow-left" ref={goBackTooltip.setTriggerRef}/>
                                {goBackTooltip.visible && goBackTooltip.element}
                            </>
                        </div>
                        <div className={classNames('tool-icon-btn', {'disabled': !navigationInfo.canGoForward})} onClick={onClickGoForwardBtn}>
                            <>
                                <i className="mdi mdi-arrow-right" ref={goForwardTooltip.setTriggerRef} />
                                {goForwardTooltip.visible && goForwardTooltip.element}
                            </>
                        </div>
                        <div className="tool-icon-btn" onClick={onClickReloadBtn}>
                            <>
                                <i className="mdi mdi-reload" ref={refreshTooltip.setTriggerRef}/>
                                {refreshTooltip.visible && refreshTooltip.element}
                            </>
                        </div>
                        {appUpdateEvent === 'update-downloaded' && (
                            <div className="tool-btn ms-2" onClick={onClickUpdateBtn} ref={updateTooltip.setTriggerRef}>
                                <i className="mdi mdi-update me-1" />
                                <span className="font-12">업데이트</span>
                                {updateTooltip.visible && updateTooltip.element}
                            </div>
                        )}
                        {(appUpdateEvent === 'update-available-manual' || appUpdateEvent === 'download-progress') && (
                            <div className="tool-btn ms-2" ref={updateTooltip.setTriggerRef}>
                                <i className="mdi mdi-update me-1" />
                                <span className="font-12">다운로드 중..</span>
                                {updateTooltip.visible && updateTooltip.element}
                            </div>
                        )}
                    </div>
                    <div className="app-title-wrapper">
                        <span id="app-title" className="app-title" />
                    </div>
                    <div className="right-toolbar">

                    </div>
                </div>

                {/*<div className="d-flex ml-auto">*/}
                {/*    <Dropdown className="mr-2">*/}
                {/*        <Dropdown.Toggle id="topbar-userdrop" as={'button'} className="btn-link btn text-white" bsPrefix={'unused'}>*/}
                {/*            <span className="account-user-name">{profileStore.profile?.nickname}</span>*/}
                {/*            /!*<span className="account-user-avatar">*!/*/}
                {/*            /!*    <Avatar size={32} name={'Ethan' || 'User'} />*!/*/}
                {/*            /!*</span>*!/*/}
                {/*            /!*<span className="text-white">*!/*/}
                {/*            /!*    <span className="account-user-name">{'Ethan'}</span>*!/*/}
                {/*            /!*    <span className="account-position">{'ethan@42maru.com'}</span>*!/*/}
                {/*            /!*</span>*!/*/}
                {/*        </Dropdown.Toggle>*/}
                {/*        <Dropdown.Menu alignRight={true}>*/}
                {/*            <Dropdown.Item as={'button'} type={'button'} onClick={onClickLogout} className="notify-item">*/}
                {/*                <i className="mdi mdi-logout mr-1" />*/}
                {/*                <span>Logout</span>*/}
                {/*            </Dropdown.Item>*/}
                {/*        </Dropdown.Menu>*/}
                {/*    </Dropdown>*/}
                {/*</div>*/}
                {platform !== 'darwin' && (
                    <WindowsControls />
                )}
            </TitleBarStyle>
        </>
    );
};

const TitleBarStyle = styled.div<{platform?: string}>`
  //-webkit-user-select: none;
  -webkit-app-region: drag;
  //background-color: rgba(0, 0, 0, 0.8); // var(--menu-background-color); // #030678;
  background-color: var(--menu-background-color);
  border-bottom: 1px solid var(--menu-border-color);
  display: block;
  //flex-shrink: 0;
  //flex-grow: 0;
  //align-items: center;
  //justify-content: space-between;
  //padding: 0 10px;
  position: relative;

  .title-items-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .app-title-wrapper {
      .app-title{
        user-select: none;
      }
    }

    .tool-icon-btn {
      -webkit-app-region: no-drag;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      flex-grow: 0;
      border-radius: 4px;
      color: #98a6ad;
      font-size: 18px;
      width: 24px;
      height: 24px;
      transition: background 20ms ease-in 0s;

      &:hover {
        background-color: #e4e4e5;
      }

      &.disabled {
        color: #dfdfdf;

        &:hover {
          background-color: unset;
        }
      }
    }

    .tool-btn {
      -webkit-app-region: no-drag;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      flex-grow: 0;
      border-radius: 4px;
      transition: background 20ms ease-in 0s;
      border: 1px solid #727cf5;
      background-color: #727cf5;
      padding: 2px 6px;

      &:hover {
        background-color: #6169d0;
      }

      &.disabled {
        color: #dfdfdf;

        &:hover {
          background-color: unset;
        }
      }
    }

    .right-toolbar {
      width: 100px;
      display: flex;
      align-items: center;
      -webkit-app-region: no-drag;
    }
  }

`;


export default TitleBar;
