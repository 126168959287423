import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import {LIVE_CHAT_API_HOST} from "../../core/variables";
import oauthAxios from "../../core/oauthAxios";
import {LiveChatError, toLiveChatError} from "../../error";


const useUpdateMember = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, LiveChatError, {memberId: string, roleId: string, defaultRole: boolean}>(['members', 'update', channelId], async ({memberId, roleId, defaultRole}) => {
        try {
            return await oauthAxios.put(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/members/${memberId}`, {roleId, default: defaultRole});
        } catch (e) {
            throw toLiveChatError(e);
        }
    }, {
        onSuccess: () => {
            return queryClient.invalidateQueries(['members', channelId], {exact: true})
        }
    });
}

export default useUpdateMember;
