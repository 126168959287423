import React from "react";
import {ChatSearchFilterToggleStyle} from "./ChatSearchFilterStyle";
import {Dropdown} from "react-bootstrap";

type ChatSearchFilterToggleProps = {
    showPopup: boolean
    updateShowPopup: () => void
    label: string
    toggleRef: React.RefObject<HTMLDivElement>
    selectedLength: number
}

const ChatSearchFilterToggle: React.FC<ChatSearchFilterToggleProps> = (props) => {
    const { showPopup, updateShowPopup, label, selectedLength, toggleRef } = props;
    
    return (
        <Dropdown.Toggle onClick={updateShowPopup}
                         as={ChatSearchFilterToggleWrapper}
                         ref={toggleRef}>
            <ChatSearchFilterToggleStyle active={!!selectedLength}>
                <span className="mx-1">{label}</span>
                {selectedLength > 0 && <span className="mr-1">{selectedLength}</span>}
                <i className={showPopup ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"}/>
            </ChatSearchFilterToggleStyle>
        </Dropdown.Toggle>
    )
};

const ChatSearchFilterToggleWrapper = React.forwardRef<any, any>(({children, onClick}, ref) => {
    return (
        <div ref={ref} role="button"
             onClick={(e) => {
                 e.preventDefault();
                 onClick(e);
             }}>
            {children}
        </div>
    )
});

export default ChatSearchFilterToggle;
