import {Page} from "../index";
import {useInfiniteQuery} from "@tanstack/react-query";
import liveChatRepository from "../../repository/LiveChatRepository";
import {LiveChat} from "../../model/livechat";
import {LiveChatError, toLiveChatError} from "../../error";


const getData = async (channelId: string, chatUserId: string, pageable: {offset: number, limit: number}) => {
    try {

        const {data} = await liveChatRepository.find(channelId, {
            chatUserId,
            offset: pageable.offset,
            limit: pageable.limit
            // baseDate: baseDate.format()
        });

        return {
            result: data.result,
            pageable: {
                ...pageable,
                isLast: data.result.length < pageable.limit
            }
        };

    } catch (e) {
        throw toLiveChatError(e);
    }
}

const useUserLiveChats = (channelId: string, userId: string) => {
    return useInfiniteQuery<Page<LiveChat, {offset: number}>, LiveChatError>(['userLiveChats', channelId, userId],async ({pageParam = {offset: 0, limit: 30, isLast: false}}) => getData(channelId, userId, pageParam), {
        suspense: false,
        useErrorBoundary: false,
        getNextPageParam: ({pageable}) => {
            if (pageable.isLast) {
                return undefined;
            }

            return {
                offset: pageable.offset + pageable.limit,
                limit: pageable.limit,
                isLast: false
            };
        }
    });
};


export default useUserLiveChats;