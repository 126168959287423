import React, {useEffect, useRef} from "react"
import LiveChatInfoCard from "../../component/chat/LiveChatInfoCard";
import ChatUserInfoCard from "../../component/chat/ChatUserInfoCard";
import styled from "styled-components";
import {useIntl} from "react-intl";
import {Accordion, Card, Dropdown, Form, useAccordionButton} from "react-bootstrap";
import LiveChatManagerInfoCard from "../../component/chat/LiveChatManagerInfoCard";
import LiveChatFileArchiveCard from "../../component/chat/LiveChatFileArchiveCard";
import UserLiveChatListCard from "../../component/chat/UserLiveChatListCard";
import UserAccessLogsCard from "../../component/chat/UserChatAccessEventsCard";
import {CenteredIcon} from "../../component/UnstyledBootstrap";
import {closestCenter, DndContext, DragEndEvent, MouseSensor, TouchSensor, useSensor, useSensors} from "@dnd-kit/core";
import {arrayMove, rectSortingStrategy, SortableContext, useSortable} from "@dnd-kit/sortable";
import {CSS} from '@dnd-kit/utilities';
import {useRecoilState, useSetRecoilState} from "recoil";
import {LiveChat, PanelSetting} from "../../model/livechat";
import DownloadStatusBar from "../../component/DownloadStatusBar";
import SimpleBar from "simplebar-react";
import UserOrderListCard from "../../component/chat/UserOrderListCard";
import CustomChatPanelContent from "../../component/chat/CustomChatPanelContent";
import channelPanelSettingsState from "../../recoil/panelSettings";
import useCustomSnippets from "../../query/customSnippet/useCustomSnippets";


const ChatPanels: React.FC<{liveChat: LiveChat}> = ({liveChat}) => {
    const intl = useIntl();
    const [panelSettings, setPanelSettings] = useRecoilState(channelPanelSettingsState(liveChat.channelId));
    const { data: customSnippets } = useCustomSnippets(liveChat.channelId);

    useEffect(() => {
        if (customSnippets) {
            setPanelSettings(prev => {
                const snippets: PanelSetting[] = customSnippets.map(snippet => ({
                    id: snippet._id,
                    i18n: snippet.name,
                    show: false,
                    collapse: false,
                    isCustom: true
                }));
                const snippetsKeys = snippets.map(v => v.id);

                // update settings with new snippets
                const settings = prev
                    .filter(v => !v.isCustom || snippetsKeys.includes(v.id))
                    .map(v => (
                        !v.isCustom
                            ? v
                            : { ...v, i18n: snippets.find(s => s.id === v.id)?.i18n ?? v.i18n }
                    ));
                const settingsSnippetKeys = settings.filter(v => v.isCustom).map(v => v.id);
                const mergingSnippetKeys = snippetsKeys.filter(key => !settingsSnippetKeys.includes(key));

                return settings.concat(snippets.filter(v => mergingSnippetKeys.includes(v.id)));
            })
        }
    }, [customSnippets, setPanelSettings]);

    return (
        <ChatPanelsStyle className='chat-panels'>
            <div className="chat-panel-header">
                <p className="mb-0 flex-grow-1 fw-bold">{intl.formatMessage({id: 'i100018'})}</p>
                <PanelController channelId={liveChat.channelId}/>
            </div>
            <div className="chat-panels">
                <SimpleBar className="h-100 simplebar">
                    {panelSettings.map((setting) => {
                        let child = null;
                        if (!setting.show) {
                            return null
                        } else if (setting.id === 'livechat') {
                            child = <LiveChatInfoCard liveChat={liveChat} />
                        } else if (setting.id === 'customer') {
                            child = <ChatUserInfoCard channelId={liveChat.channelId}
                                                      liveChatId={liveChat._id!}
                                                      user={liveChat.user}
                                                      showUserInfoBtn={liveChat.state === 'responding' || liveChat.state === 'holding'} />

                        } else if (setting.id === 'archive') {
                            child = <UserLiveChatListCard channelId={liveChat.channelId}
                                                          chatUserId={liveChat.user._id}
                                                          currentLiveChatId={liveChat._id}
                            />
                        } else if (setting.id === 'accessLog') {
                            child = <UserAccessLogsCard channelId={liveChat.channelId}
                                                        chatUserId={liveChat.user._id}
                                                        currentLiveChatId={liveChat._id}
                            />
                        } else if (setting.id === 'members') {
                            child = <LiveChatManagerInfoCard liveChat={liveChat}/>
                        } else if (setting.id === 'fileArchive') {
                            child = <LiveChatFileArchiveCard liveChat={liveChat}/>
                        } else if (setting.id === 'orders') {
                            child = <UserOrderListCard channelId={liveChat.channelId} chatUser={liveChat.user}/>
                        } else if (setting.isCustom) {
                            child = <CustomChatPanelContent channelId={liveChat.channelId}
                                                            chatUser={liveChat.user}
                                                            snippetId={setting.id}/>
                        } else {
                            return null;
                        }

                        return (
                            <PanelWrapper key={setting.id}
                                          title={setting.isCustom ? setting.i18n : intl.formatMessage({id: setting.i18n})}
                                          panelSetting={setting}
                                          channelId={liveChat.channelId}>
                                {child}
                            </PanelWrapper>
                        )
                    })}
                </SimpleBar>
            </div>
            <DownloadStatusBar />
        </ChatPanelsStyle>
    )
}

type PanelControllerItemProps = {
    id: string;
    value: string;
    show: boolean;
    channelId: string;
}

const PanelControllerItem: React.FC<PanelControllerItemProps> = (props) => {
    const { id, value, show, channelId } = props;

    const ref = useRef<HTMLInputElement>(null);
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: id});

    const setPanelSettings = useSetRecoilState(channelPanelSettingsState(channelId));

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const onClick = (_: React.MouseEvent) => {
        setPanelSettings(prev => prev.map(setting => {
            if (setting.id === id) {
                return {
                    ...setting,
                    show: ref.current?.checked ?? false
                }
            }
            else {
                return setting
            }
        }))
    }

    return (
        <PanelControllerItemStyle className="d-flex align-items-center rounded p-1" ref={setNodeRef} style={style} {...attributes}>
            <i role="button" className="mdi mdi-menu position-relative" {...listeners} />
            <p className="mb-0 ml-1 flex-grow-1 mr-2 text-truncate" title={value} style={{maxWidth: "100px"}}>{value}</p>
            <Form.Check id={id} defaultChecked={show} onClick={onClick} ref={ref} />
        </PanelControllerItemStyle>
    );
};

const PanelController: React.FC<{channelId: string}> = ({channelId}) => {
    return (
        <Dropdown>
            <Dropdown.Toggle id="chat-menu-dropdown-toggle" as="i" bsPrefix="unused" className="mdi mdi-tune font-22" role="button" />
            <Dropdown.Menu>
                <PanelsControllerList channelId={channelId}/>
            </Dropdown.Menu>
        </Dropdown>
    );
};

const PanelsControllerList: React.FC<{channelId: string}> = ({channelId}) => {
    const intl = useIntl();
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

    const [panelSettings, setPanelSettings] = useRecoilState(channelPanelSettingsState(channelId));

    const handleDragEnd = (event: DragEndEvent) => {
        const {active, over} = event;

        if (active.id !== over?.id) {
            setPanelSettings(prev => {
                const oldIndex = prev.findIndex(s => s.id === active.id);
                const newIndex = prev.findIndex(s => s.id === over?.id);

                return arrayMove(prev, oldIndex, newIndex);
            })
        }
    }

    return (
        <div className="p-1 mb-0">
            <DndContext sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
            >
                <SortableContext items={panelSettings} strategy={rectSortingStrategy}>
                    {panelSettings.map(item => {
                        const isCustom = !!item.isCustom;

                        return (
                            <PanelControllerItem key={item.id}
                                                 id={item.id}
                                                 value={isCustom ? item.i18n : intl.formatMessage({id: item.i18n})}
                                                 show={item.show}
                                                 channelId={channelId}
                            />
                        )
                    })}
                </SortableContext>
            </DndContext>
        </div>
    );
};

const CustomToggle:React.FC<{eventKey: string, collapse: boolean, children?: React.ReactNode}> = ({ children, collapse, eventKey })  => {
    const decoratedOnClick = useAccordionButton(eventKey, (_) => {});

    return (
        <div className="d-flex align-items-center">
            <div className="m-0 flex-grow-1 fw-bold">
                {children}
            </div>
            <CenteredIcon size={24} className={`font-24 uil-angle-${collapse ? 'down' : 'up'}`} onClick={decoratedOnClick} role="button" />
            {/*<i role='button' className={`font-22 uil-angle-${collapse ? 'up' : 'down'}`} onClick={decoratedOnClick}/>*/}
        </div>
    );
}

type PanelWrapperProps = {
    channelId: string,
    title: string,
    panelSetting: PanelSetting,
    children?: React.ReactNode
}

const PanelWrapper: React.FC<PanelWrapperProps> = (props) => {
    const { channelId, title, panelSetting, children } = props;

    const setPanelSettings = useSetRecoilState(channelPanelSettingsState(channelId));

    const onSelect =(_: any) => {
        setPanelSettings(prev => prev.map(setting => {
            if (setting.id === panelSetting.id) {
                return {
                    ...setting,
                    collapse: !setting.collapse
                }
            }
            else {
                return setting
            }
        }))
    };

    return (
        <PanelWrapperStyle defaultActiveKey={panelSetting.collapse ? '' : panelSetting.id} onSelect={onSelect}>
            <Card className="position-static">
                <Card.Header>
                    <CustomToggle eventKey={panelSetting.id} collapse={panelSetting.collapse}>
                        {title}
                    </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={panelSetting.id}>
                    <Card.Body className="py-2 chat-panel-content">
                        {children}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </PanelWrapperStyle>
    )
}

const PanelWrapperStyle = styled(Accordion) `
  
  .card-header {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0 !important;
  }
  
  //.card > .card-header {
  //  background-color: transparent;
  //}
  
  .chat-panel-content {
    //height: 300px;
    
    .variant-message {
      //padding: 0.5rem;
    }
  }
`

const ChatPanelsStyle = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  width: var(--live-chat-info-width);;
  height: 100%;
  display: flex;
  flex-direction: column;
  
  .chat-panel-header {
    display: flex;
    align-items: center;
    background-color: #fff;
    //margin: 0.1rem 0;
    padding: 0.75rem 1.5rem;
    height: 50px;
    flex-shrink: 0;

    //box-shadow: var(--panel-box-shadow);
    border-bottom: 1px solid rgba(212, 213, 215, 0.8);
    border-left: 1px solid rgba(212, 213, 215, 0.8);
  }
  
  .chat-panels {
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    //height: calc(100% - 50px);
    
    & > {
      .simplebar {
        padding: 1.5rem 5%;
      }
    }
  }
`

const PanelControllerItemStyle = styled.li `
  //z-index: 1001;
  &:hover {
    background: #f4F4F7;
  }
    
`

export default ChatPanels