import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import classNames from "classnames";
import Avatar from "../Avatar";
import moment from "moment";
import {useIntl} from "react-intl";
import {useInView} from "react-intersection-observer";
import {
    FileMessage,
    Message,
    MessageBlock, MessageForm,
    MessageUI,
    TextMessage as MessageBlockTextMessage
} from "../../model/message";
import TextMessage from "./TextMessage";
import {LiveChat} from "../../model/livechat";
import FileMessageBlock from "./FileMessageBlock";
import useModal from "../../hook/useModal";
import {messageTemplateFormModalId} from "../setting/MessageTemplateFormModal";
import useManagers from "../../query/manager/useManagers";
import FloatingToolbar from "../FloatingToolbar";
import MessageBlockStyle from "./MessageBlockStyle";
import {CenteredIcon} from "../UnstyledBootstrap";
import useTooltip from "../../hook/useTooltip";
import useUnreadMessages from "../../query/message/useUnreadMessages";
import useWindowFocus from "../../hook/useWindowFocus";
import {useMountEffect} from "@react-hookz/web";
import {genMessageBlockGroupId} from "../../util/idGenUtil";
import styled from "styled-components";
import {SocketEvent, useSocket} from "../../socket";
import MessageSentAt from "./MessageSentAt";


const MessageBlockGroup = React.memo<MessageBlockGroupProps>((props) => {
    const { message, liveChat, previousMessage, confirmMessage, trackedText, updateMessage, deleteMessage} = props

    const intl = useIntl();
    const socket = useSocket();

    const {data: managers} = useManagers(liveChat.channelId);
    const {data: unreadMessages} = useUnreadMessages(liveChat.channelId);

    const ackTimerIdRef = useRef<any>(null);

    const [senderName, setSenderName] = useState<string>();
    const [senderAvatar, setSenderAvatar] = useState<string|undefined>(undefined)
    const [state, setState] = useState<'fail' | 'bannedWords' | undefined>(undefined)
    const [isFocused, setIsFocused] = useState(false)

    // Update message state
    useEffect(() => {
        if (message.shouldAck) {
            ackTimerIdRef.current = setTimeout(() => {
                if (message.shouldAck) {
                    setState("fail")
                }
            }, 2000);
        } else {
            if (message.rejectedBannedWords) {
                setState('bannedWords')
            } else {
                setState(undefined)
            }
        }

        return () => {
            if (ackTimerIdRef.current) {
                window.clearTimeout(ackTimerIdRef.current);
                ackTimerIdRef.current = null;
            }
        }
    }, [message])


    const [messageRef, messageInView] = useInView({threshold: 0, trackVisibility: true, delay: 1000});
    const focusWindow = useWindowFocus();

    const sentMessageConfirmRef = useRef<boolean>(false);

    useMountEffect(() => {
        const previousDate = previousMessage?.date;
        const thisDate = moment.utc(message.createdAt);
        const diff = previousDate ? Math.abs(moment.utc(previousDate).diff(thisDate, 'minutes')) : 1;
        const beforeSenderUserId = previousMessage && previousMessage.type === 'message' ? (previousMessage.data as Message).sender.userId : undefined;

        const showSender = previousDate ? diff > 0 || beforeSenderUserId !== message.sender.userId : true;
        if (showSender) {
            if (message.sender.type === 'bot') {
                setSenderName('Bot');
                setSenderAvatar(undefined)
            }
            else if (message.sender.type === 'user') {
                setSenderName(liveChat.user.name ?? liveChat.user.alias);
                setSenderAvatar(undefined)
            }
            else if (message.sender.type === 'manager') {
                const matchedManagers = managers?.filter(manager => manager.userId === message.sender.userId) ?? [];
                if (matchedManagers.length > 0) {
                    setSenderName(matchedManagers[0].name);
                    setSenderAvatar(matchedManagers[0].avatar);
                } else {
                    setSenderName(intl.formatMessage({id: "i200078"}));
                    setSenderAvatar(undefined);
                }
            }
            else {
                setSenderName(intl.formatMessage({id: "i200078"}));
                setSenderAvatar(undefined);
            }
        }
    });

    const _deleteMessage = useCallback(() => {
        deleteMessage(message)
    }, [message, deleteMessage])

    const _reSend = useCallback(() => {

        const messageForm: MessageForm = {
            senderType: 'manager',
            senderUserId: message.sender.userId,
            blocks: message.blocks,
            createdAt: moment.utc().format("YYYY-MM-DD HH:mm:ss.SSSSS"),
            sendId: message.sendId,
            ignoreBannedWords: message.rejectedBannedWords && message.rejectedBannedWords.length > 0
        };

        socket.send(SocketEvent.MESSAGE, {liveChatId: liveChat._id, message: messageForm});

        // Wait 2 second for message ack
        updateMessage({...message, shouldAck: true})

    }, [socket, message, liveChat._id, updateMessage])

    const isUnread = useMemo(() => {
        return unreadMessages?.[liveChat._id]?.some(unreadMessage => unreadMessage._id === message._id) ?? false;
    }, [unreadMessages, liveChat, message]);

    useEffect(() => {
        if (!messageInView || sentMessageConfirmRef.current || !focusWindow) return;

        if (isUnread) {
            sentMessageConfirmRef.current = true;
            confirmMessage(message._id);
        }
    }, [messageInView, message, confirmMessage, isUnread, focusWindow]);

    return (
        <MessageBlockStyle id={genMessageBlockGroupId(message)}
                           className={classNames({'display-avatar': senderName, 'user-message': message.sender.type === 'user'})}
                           data-floating-toolbar="true"
                           ref={messageRef}
                           onMouseEnter={() => setIsFocused(true)}
                           onMouseLeave={() => setIsFocused(false)}
                           tracked={!!trackedText}>
            {(senderName) && (
                <div className="message-avatar">
                    <Avatar size={35} name={senderName} avatarUrl={senderAvatar}/>
                </div>
            )}

            <div className="message-wrapper">
                {senderName && (
                    <p className="message-header">
                        {senderName}
                        <MessageSentAt at={message.createdAt} expanded={isFocused}/>
                    </p>
                )}

                <div className="message-container">
                    <div className="d-flex flex-column flex-grow-1">
                        {message.blocks.map((messageBlock, index) => (
                            <MessageBlockFragment key={`${genMessageBlockGroupId(message)}_${index}`}
                                                  messageBlock={messageBlock}
                                                  trackedText={trackedText}
                                                  createdAt={message.createdAt}/>
                        ))}
                    </div>

                    {state && <MessageFailover state={state} reSend={_reSend} deleteMessage={_deleteMessage}/>}

                </div>
            </div>

            {messageInView && !state && <MessageToolbar message={message} />}
        </MessageBlockStyle>
    );
});

type MessageFailoverProps = {
    state: 'fail' | 'bannedWords',
    reSend: () => void,
    deleteMessage: () => void
}

const MessageFailover: React.FC<MessageFailoverProps> = ({state, reSend, deleteMessage}) => {
    const intl = useIntl();

    return (
        <MessageControllerStyle className='message-controller ml-1'>
            <div className='mr-2 flex-grow-1 d-flex align-items-center'>
                <i className="mdi mdi-information-outline flex-shrink-0 text-danger"/>
                <small className='text-danger ml-1'>{intl.formatMessage({id: state === 'fail' ? 'i000328' : 'i100097'})}</small>
            </div>
            <small role={'button'} className='text-primary text-decoration-underline mr-1' onClick={reSend}>
                {intl.formatMessage({id: state === 'fail' ? 'i100095' : 'i100115'})}
            </small>
            <small role={'button'}
                   className='text-primary text-decoration-underline'
                   onClick={deleteMessage}
            >
                {intl.formatMessage({id: 'i000034'})}
            </small>
        </MessageControllerStyle>
    )
}

const MessageControllerStyle = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`

const MessageBlockFragment = React.memo<{messageBlock: MessageBlock, trackedText?: string, createdAt?: string}>(({messageBlock, trackedText, createdAt}) => {
    const component = useMemo(() => {
        if (messageBlock.type === 'text') {
            const htmlValue = (messageBlock.value as MessageBlockTextMessage).html;
            return <TextMessage text={htmlValue} trackedText={trackedText} preview />;
        }
        else if (messageBlock.type === 'file') {
            return <FileMessageBlock fileMessage={messageBlock.value as FileMessage} createdAt={createdAt}/>
        }
        else {
            // return (
            //     <div className="message-content">
            //         <p className="text-message bg-light">아직 지원하지 않는 형식 {messageBlock.type}</p>
            //     </div>
            // );
            return null;
        }
    }, [messageBlock, trackedText, createdAt]);

    return (
        <>
            {component}
        </>
    );
});

const MessageToolbar: React.FC<{message: Message}> = ({message}) => {
    const modal = useModal();
    const intl = useIntl();

    const messageTemplateTooltip = useTooltip(intl.formatMessage({id: 'i000224'}));

    const onClickMessageTemplateBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        modal.open(messageTemplateFormModalId, {blocks: message.blocks})
    };

    return (
        <>
            <FloatingToolbar>
                <CenteredIcon size={28}
                              className="mdi mdi-clipboard-edit-outline font-20"
                              role="button"
                              ref={messageTemplateTooltip.setTriggerRef}
                              onClick={onClickMessageTemplateBtn} />
                {messageTemplateTooltip.element}

            </FloatingToolbar>

        </>
    );
};


interface MessageBlockGroupProps {
    message: Message
    liveChat: LiveChat
    previousMessage?: MessageUI
    confirmMessage: (messageId: string) => void
    // to track search text, if it is undefined, this is not a search context.
    trackedText?: string
    deleteMessage: (message: Message) => void
    updateMessage: (message: Message) => void
}

export default MessageBlockGroup;
