import {useQuery} from "@tanstack/react-query";
import {MessageTemplate} from "../../model/message";
import messageTemplateRepository from "../../repository/MessageTemplateRepository";
import {LiveChatError, toLiveChatError} from "../../error";


const getData = async (channelId: string) => {
    try {
        const {data} = await messageTemplateRepository.find(channelId, true);
        return data.result;
    } catch (e) {
        throw toLiveChatError(e);
    }

}


const useMessageTemplates = (channelId: string) => {
    return useQuery<MessageTemplate[], LiveChatError>(['messageTemplates', channelId], async () => getData(channelId), {suspense: false});
};


export default useMessageTemplates;