import announcementRepository from "../../repository/AnnouncementRepository";
import {LiveChatError, toLiveChatError} from "../../error";
import {useQuery} from "@tanstack/react-query";
import {Announcement} from "../../model/announcement";

const getData = async (channelId: string, search?: string) => {
    try {
        const { data } = await announcementRepository.find(channelId, search);
        return data.result;
    } catch (e) {
        throw toLiveChatError(e);
    }
};

const useAnnouncements = (channelId: string, search?: string) => {
    return useQuery<Announcement[], LiveChatError>({
        queryKey: ['announcements', channelId, search],
        queryFn: async () => getData(channelId, search),
        retry: false,
        suspense: false,
    });
};

export default useAnnouncements;
