import React from "react";
import {SentryRoutes} from "../../core/sentry";
import {Navigate, Route} from "react-router-dom";
import PrivateRoute from "../../component/PrivateRoute";
import MainDashboardView from "./MainDashboardView";

const DashboardView : React.FC = () => {
    return (
        <SentryRoutes>
            <Route index element={<Navigate to={"main-dashboard"} />} />
            <Route path="main-dashboard" element={<PrivateRoute><MainDashboardView/></PrivateRoute>}/>
        </SentryRoutes>
    )
}

export default DashboardView;
