const language: Record<string, string> = {
    m01: '홈',
    m02: '상담',
    m03: '설정',
    i000001: '내 상담 채널',
    i000002: '응대중인 상담',
    i000003: '배정된 상담',
    i000004: '보류된 상담',
    i000005: '종료된 상담',
    i000006: '메시지를 입력하세요.',
    i000007: '고객',
    i000008: '상담 시작',
    i000009: '종료 요청',
    i000010: '상담 종료',
    i000011: '상담종료를 요청 합니다.',
    i000012: '취소',
    i000013: '종료된 상담입니다. 고객이 아직 확인은 안했습니다.',
    i000014: '종료된 상담입니다.',
    i000015: '고객의 요청으로 취소된 상담입니다.',
    i000016: '확인',
    i000017: '새로운 메시지가 도착했습니다.',
    i000018: '상담할 내용이 없습니다.',
    i000019: '상담 목록이 없습니다.',
    i000020: '이름',
    i000021: '내용',
    i000022: '취소',
    i000023: '등록',
    i000024: '필수 입력 항목입니다.',
    i000025: '등록된 템플릿이 없습니다.',
    i000026: '자주 쓰는 메시지를 템플릿으로 만들어서 사용하세요.\n미리 등록된 템플릿으로 고객들과 효율적으로 대화할 수 있습니다.',
    i000027: '템플릿 메시지 만들기',
    i000028: '템플릿 만들기',
    i000029: '수정',
    i000030: '전송',
    i000031: '상담내용을 입력하세요.',
    i000032: '줄바꿈은 Shift + Enter 키를 누르세요.',
    i000033: '템플릿을 삭제 하시겠습니까?',
    i000034: '삭제',
    i000035: '챗봇 메시지 설정',
    i000036: '고객이 상담사 연결과 관련해 챗봇이 응답할 메시지를 설정하세요.',
    i000037: '첫 응대 메시지',
    i000038: '연결 취소 메시지',
    i000039: '부재중 메시지',
    i000040: '저장',
    i000041: '상담 운영시간 설정',
    i000042: '요일별로 상담 운영시간을 설정 하세요.\n수동으로도 상담 가능 여부를 설정할 수 있습니다.',
    i000043: '일',
    i000044: '월',
    i000045: '화',
    i000046: '수',
    i000047: '목',
    i000048: '금',
    i000049: '토',
    i000050: '상담 운영시간 설정 방법',
    i000051: '수동으로 설정',
    i000052: '요일별 시간 설정',
    i000053: '운영 상태',
    i000054: '운영중',
    i000055: '부재중',
    i000056: '요일',
    i000057: '시간',
    i000058: '추가',
    i000059: '시간대',
    i000060: '상담이력',
    i000061: '대기중',
    i000062: '보류',
    i000063: '상담중',
    i000064: '고객이 상담을 요청했습니다.',
    i000065: '새로운 메시지가 도착했습니다.',
    i000066: '메모',
    i000067: '상담에 대한 설명이나 필요한 정보를 저장하세요.',
    i000068: '고객이 접속을 종료 했습니다. 상담을 종료할 수 있습니다.',
    i000069: '파일 첨부',
    i000070: '신규 상담 요청이 도착 했습니다.',
    i000071: '로그아웃',
    i000072: '새로운 업데이트를 사용할 수 있습니다. 업데이트 하시겠습니까?',
    i000073: '업데이트',
    i000074: '알림 설정',
    i000075: '새로운 상담, 새 메시지에 대한 알림을 설정 할 수 있습니다.',
    i000076: '알림 설정을 적용 중 에러가 발생했습니다.',
    i000077: '모든 알림 받기',
    i000078: '참여 중인 대화방의 모든 메시지에 대한 알림과 새로운 상담에 대한 알림을 모두 받습니다.',
    i000079: '모든 알림 받기를 끄면 다른 알림 설정에 상관없이 모든 알림을 받지 않습니다.',
    i000080: '새 상담 알림 받기',
    i000081: '새로운 상담 요청이 도착하면 알림을 받습니다.',
    i000082: '메시지 알림 받기',
    i000083: '참여 중인 대화방의 모든 메시지에 대한 알림을 받습니다.',
    i000084: '상담 목록을 불러오지 못했습니다.',
    i000085: '배정됨',
    i000086: '담당자 없음',
    i000087: '이름',
    i000088: '채널 프로필 설정',
    i000089: '채널에서 사용할 이름을 입력하세요.',
    i000090: '채널 프로필 정보 저장 중 에러가 발생했습니다.',
    i000091: '채널 정보 조회중 오류가 발생했습니다.',
    i000092: '채널 프로필 정보 조회 중 에러가 발생했습니다.',
    i000093: '상담 카테고리 설정',
    i000094: '주로 상담하는 카테고리를 설정하세요.\n상담 분배 설정이 카테고리별로 되어 있으면 설정한 카테고리를 우선적으로 분배 받을 수 있습니다.',
    i000095: '카테고리 관리',
    i000096: '상담사 카테고리 목록 조회 중 오류가 발생했습니다.',
    i000097: '카테고리 목록 조회 중 오류가 발생했습니다.',
    i000098: '등록된 카테고리가 없습니다.',
    i000099: '카테고리 삭제 중 에러가 발생했습니다.',
    i000100: '카테고리 추가 중 에러가 발생했습니다.',
    i000101: '상담 카테고리를 설정해서 카테고리 별로 상담을 분류하고 어떤 종류의 상담이 많은지 확인할 수 있습니다.\n상담 분배 설정에서 카테고리 별로 상담사를 배치해 상담을 분배 할 수 있습니다.',
    i000102: '상담 운영시간 수정 중 에러가 발생했습니다.',
    i000103: '상담 운영시간을 수정했습니다.',
    i000104: '채널 정보 조회 중 에러가 발생했습니다.',
    i000105: '상담 메모를 저장 했습니다.',
    i000106: '챗봇 메시지를 저장 했습니다.',
    i000107: '채널 목록 조회 중 에러가 발생했습니다.',
    i000108: '메시지 템플릿 삭제 중 에러가 발생했습니다.',
    i000109: '챗봇 시나리오 목록을 불러오지 못했습니다.',
    i000110: '새 채널 만들기',
    i000111: '미리 등록된 챗봇 시나리오로 상담 채널을 등록 하세요.',
    i000112: '채널명',
    i000113: '시나리오 선택',
    i000114: '선택 할 시나리오가 없으면 Conversation Maker에서 시나리오를 만들어 주세요.',
    i000115: '만들기',
    i000116: '대화 정보를 불러 오지 못했습니다.',
    i000117: '카테고리 정보를 불러 오지 못했습니다.',
    i000118: '카테고리 만들기',
    i000119: '카테고리명',
    i000120: '카테고리 저장 중 에러가 발생했습니다.',
    i000121: '카테고리를 삭제 하시겠습니까?',
    i000122: '해당 카테고리가 적용된 모든 상담챗에 변경사항이 적용됩니다.',
    i000123: '카테고리 삭제 중 에러가 발생했습니다.',
    i000124: '카테고리 수정하기',
    i000125: '해당 카테고리가 이미 존재합니다.',
    i000126: '카테고리',
    i000127: '상담 카테고리를 선택해주세요.',
    i000128: '사용자의 챗봇 시나리오 정보가 없습니다. 시나리오를 먼저 등록해 주세요.',
    i000129: '채널정보 다시 불러오기',
    i000130: '등록된 카테고리가 없습니다.',
    i000131: '상담수',
    i000132: '상담사 수',
    i000133: '생성일',
    i000134: '상담 분배 방식 설정',
    i000135: '순차 및 카테고리 우선 분배 방식으로 상담을 분배 할 수 있습니다.' +
        '\n카테고리 우선 분배는 고객이 상담 요청시 설정한 카테고리를 담당하고 있는 상담사에게 우선 배정됩니다.' +
        '\n\n해당 카테고리를 담당하고 있는 상담사의 진행, 대기중인 상담이 카테고리 우선 분배시 상담 제한 수 보다 많을 경우에는' +
        '\n원할한 상담을 위해 순차 분배 방식으로 다른 상담사에게 배정됩니다.',
    i000136: '분배 방식',
    i000137: '순차 분배',
    i000138: '카테고리 우선 분배',
    i000139: '카테고리 우선 분배시 상담 제한 수',
    i000140: '상담 분배 방식을 수정했습니다.',
    i000141: '상담 분배 방식 수정 중 에러가 발생했습니다.',
    i000142: '금칙어 관리',
    i000143: '금칙어를 등록해서 욕설, 선정적인 발언, 비하 발언 등을 마스킹 처리할 수 있습니다.',
};

export default language;