import React, {useState} from "react";
import {useIntl} from "react-intl";
import {Form} from "react-bootstrap";
import {NotificationSetting} from "../../model/NotificationSetting";
import {useParams} from "react-router-dom";
import SettingViewWrapper from "../../component/setting/SettingViewWrapper";
import useNotificationSetting from "../../query/notification/useNotificationSetting";
import useUpdateNotificationSetting from "../../query/notification/useUpdateNotificationSetting";
import toast from "react-hot-toast";

const NotificationSettingView: React.FC = () => {
    const intl = useIntl();
    const {channelId} = useParams<{channelId: string}>();

    if (!channelId) {
        throw new Error('Required channelId path params')
    }

    const {data: notificationSetting, status: notificationSettingStatus} = useNotificationSetting(channelId);
    const {mutate: updateNotificationSetting} = useUpdateNotificationSetting(channelId);

    const [notification, setNotification] = useState<NotificationSetting>(
        notificationSetting || {allowsNotification: true, allowsLiveChat: true, allowsMessage: true, allowsOfflineEmail: true});
    //
    // useEffect(() => {
    //     notificationSettingStore.load(channelId).then(() => {
    //         if (notificationSettingStore.data) {
    //             setNotificationSetting(notificationSettingStore.data);
    //         }
    //     });
    // }, [channelId, notificationSettingStore]);

    const onChangeAllowsNotification = () => {
        updateNotification({
            ...notification,
            allowsNotification: !notification.allowsNotification
        });

        // updateNotificationSetting({
        //     ...notificationSetting,
        //     allowsNotification: !notificationSetting.allowsNotification
        // });
    };

    const onChangeAllowsLiveChat = () => {
        updateNotification({
            ...notification,
            allowsLiveChat: !notification.allowsLiveChat
        });
    };

    const onChangeAllowsMessage = () => {
        updateNotification({
            ...notification,
            allowsMessage: !notification.allowsMessage
        });
    };

    const onChangeAllowsOfflineEmail = () => {
        updateNotification({
            ...notification,
            allowsOfflineEmail: !notification.allowsOfflineEmail
        });
    };

    const updateNotification = (notificationSetting: NotificationSetting) => {
        setNotification(notificationSetting);

        updateNotificationSetting(notificationSetting, {
            onError: (_) => {
                toast.error(intl.formatMessage({id: 'i000076'}));
            }
        });
    }

    if (notificationSettingStatus === 'success') {
        return (
            <SettingViewWrapper>
                <div className="setting-title">
                    <h3>{intl.formatMessage({id: 'i000074'})}</h3>
                    <p>
                        {intl.formatMessage({id: 'i000075'})}
                    </p>
                </div>
                <Form>
                    <Form.Group className="mb-1">
                        <Form.Check type="switch"
                                    id="allows-notifications-switch"
                                    label={(
                                        <div>
                                            <span>{intl.formatMessage({id: 'i000077'})}</span>
                                            {/*<p className="text-muted small mb-0">{intl.formatMessage({id: 'i000078'})}</p>*/}
                                            <p className="text-muted small">{intl.formatMessage({id: 'i000079'})}</p>
                                        </div>
                                    )}
                                    onChange={onChangeAllowsNotification}
                                    checked={notificationSetting?.allowsNotification}/>
                    </Form.Group>
                    <Form.Group className="mb-1">
                        <Form.Check type="switch"
                                    id="allows-live-chat-switch"
                                    label={(
                                        <div>
                                            <span>{intl.formatMessage({id: 'i000080'})}</span>
                                            <p className="text-muted small">{intl.formatMessage({id: 'i000081'})}</p>
                                        </div>
                                    )}
                                    onChange={onChangeAllowsLiveChat}
                                    disabled={!notificationSetting?.allowsNotification}
                                    checked={notificationSetting?.allowsLiveChat}/>
                    </Form.Group>
                    <Form.Group className="mb-1">
                        <Form.Check type="switch"
                                    id="allows-message-switch"
                                    label={(
                                        <div>
                                            <span>{intl.formatMessage({id: 'i000082'})}</span>
                                            <p className="text-muted small">{intl.formatMessage({id: 'i000083'})}</p>
                                        </div>
                                    )}
                                    onChange={onChangeAllowsMessage}
                                    disabled={!notificationSetting?.allowsNotification}
                                    checked={notificationSetting?.allowsMessage}/>
                    </Form.Group>
                    <Form.Group className="mb-1">
                        <Form.Check type="switch"
                                    id="allows-offline-email-switch"
                                    label={(
                                        <div>
                                            <span>{intl.formatMessage({id: 'i000360'})}</span>
                                            <p className="text-muted small">{intl.formatMessage({id: 'i000361'})}</p>
                                        </div>
                                    )}
                                    onChange={onChangeAllowsOfflineEmail}
                                    disabled={!notificationSetting?.allowsNotification}
                                    checked={notificationSetting?.allowsOfflineEmail}/>
                    </Form.Group>
                </Form>
            </SettingViewWrapper>
        );
    }
    else {
        return null;
    }

};


export default NotificationSettingView;
