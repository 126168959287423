import React, {useMemo} from "react";
import {ChatbotMessage, MessageData} from "../../model/chatbot";
import ImageMessage from "./ImageMessage";
import VideoMessage from "./VideoMessage";
import AudioMessage from "./AudioMessage";

const MediaMessage: React.FC<MediaMessageProps> = ({message}) => {

    const component = useMemo(() => {
        if (message.data?.media_type === 'image' && message.data.media_url) {
            return <ImageMessage src={message.data.media_url} />
        }
        else if (message.data?.media_type === 'video' && message.data.media_url) {
            return <VideoMessage url={message.data.media_url} />
        }
        else if (message.data?.media_type === 'audio' && message.data.media_url) {
            return <AudioMessage url={message.data.media_url} />
        }
    }, [message]);

    return (
        <>
            {component}
        </>
    );
};

type MediaMessageProps = {
    message: ChatbotMessage<MessageData>;
}


export default MediaMessage;
