import {useRecoilState, useRecoilValue} from "recoil";
import liveChatContext from "../recoil/liveChatContext";
import chatMenuSegmentState from "../recoil/chatMenuSegment";
import {useCallback, useMemo} from "react";
import {LiveChat, LiveChatContext, LiveChatState, LiveChatStatus} from "../model/livechat";
import useManager from "../query/manager/useManager";
import {useLocalStorageValue} from "@react-hookz/web";
import {LATEST_MANAGER_PRIORITIES_KEY} from "../core/variables";
import {InfiniteData, useQueryClient} from "@tanstack/react-query";
import {Page} from "../query";
import useModal from "./useModal";
import toast from "react-hot-toast";
import {BULK_LIVECHAT_MESSAGE_MODAL_ID} from "../component/chat/BulkLiveChatMessageModal";
import isBulkModeState from "../recoil/liveChatBulkAction/isBulkMode";
import bulkLiveChatIdsState from "../recoil/liveChatBulkAction/bulkLiveChatIds";
import isBulkActionLoadingState from "../recoil/liveChatBulkAction/isBulkActionLoading";
import liveChatBulkRepository from "../repository/LiveChatBulkRepository";
import {useIntl} from "react-intl";

export type BulkActionItem = {
    label: string;
    handler: () => void;
    supportedContexts: { context?: LiveChatContext, segment?: LiveChatStatus }[]
}

const useLiveChatBulkActionHandler = (channelId: string) => {
    const intl = useIntl();
    const modal = useModal();

    const queryClient = useQueryClient();

    const context = useRecoilValue(liveChatContext);
    const chatMenuSegment = useRecoilValue(chatMenuSegmentState);
    const [bulkLiveChatIds, setBulkLiveChatIds] = useRecoilState(bulkLiveChatIdsState);
    const [isBulkMode, setIsBulkMode] = useRecoilState(isBulkModeState);
    const [isBulkActionLoading, setIsBulkActionLoading] = useRecoilState(isBulkActionLoadingState);

    const { data: manager } = useManager(channelId);

    const [priorities] = useLocalStorageValue<{[key: string]: number[]}>(LATEST_MANAGER_PRIORITIES_KEY, {[channelId]: []}, {storeDefaultValue: true});

    const _segmentKey = useMemo(() => {
        return chatMenuSegment[context.type ?? LiveChatContext.INBOX] ?? LiveChatStatus.IN_PROGRESS;
    }, [context, chatMenuSegment]);

    // enable bulk action mode & select live chats in current context & chat menu segment
    const enableBulkMode = useCallback(async () => {
        if (manager && _segmentKey) {
            let queryKey = [];

            if (context.type === LiveChatContext.INBOX) {
                queryKey.push('liveChats', _segmentKey, channelId, manager.userId, priorities[channelId] ?? []);
            } else if ( context.type === LiveChatContext.FAVORITE) {
                queryKey.push('liveChats', 'favorite', channelId);
            }

            const data = queryClient.getQueryData<InfiniteData<Page<LiveChat>>>(queryKey, {exact: true});
            if (data) {
                const liveChatIds = data.pages.map(page => page.result).flat().map(liveChat => liveChat._id);
                if (liveChatIds.length > 0) {
                    setIsBulkMode(true);
                }
                setBulkLiveChatIds(liveChatIds);
            }
        }
    }, [manager, context, _segmentKey, channelId, priorities, queryClient, setBulkLiveChatIds, setIsBulkMode])

    const updateBulkLiveChatIds = useCallback((liveChatId: string) => {
        setBulkLiveChatIds(prev => {
            if (prev.includes(liveChatId)) {
                return prev.filter(id => id !== liveChatId);
            } else {
                return [...prev, liveChatId];
            }
        });
    }, [setBulkLiveChatIds])

    const disableBulkMode = useCallback(() => {
        setIsBulkMode(false);
        setBulkLiveChatIds([]);
    }, [setIsBulkMode, setBulkLiveChatIds]);

    const sendBulkMessages = useCallback(() => {
        modal.open(BULK_LIVECHAT_MESSAGE_MODAL_ID);
    }, [modal]);

    const changeFavoriteLiveChat = useCallback(async (isFavorite: boolean) => {
        setIsBulkActionLoading(true);
        try {
            await liveChatBulkRepository.changeFavorite(channelId, bulkLiveChatIds, isFavorite)
            disableBulkMode();
            toast.success(intl.formatMessage({id: "i210071"}));
        } catch (e) {
            toast.error(intl.formatMessage({id: "i210072"}));
        } finally {
            setIsBulkActionLoading(false);
        }
    }, [intl, setIsBulkActionLoading, bulkLiveChatIds, disableBulkMode, channelId]);

    const changeLiveChatStatus = useCallback(async (state: LiveChatState) => {
        setIsBulkActionLoading(true);
        try {
            await liveChatBulkRepository.changeState(channelId, bulkLiveChatIds, state, state === "solved")
            setIsBulkActionLoading(false);
            disableBulkMode();
            toast.success(intl.formatMessage({id: "i210071"}));
        } catch (e) {
            toast.error(intl.formatMessage({id: "i210072"}));
        } finally {
            setIsBulkActionLoading(false);
        }
    }, [intl, setIsBulkActionLoading, bulkLiveChatIds, disableBulkMode, channelId]);

    // All bulk actions currently implemented.
    const _bulkActions = useMemo<BulkActionItem[]>(() => {
        return [
            {
                label: intl.formatMessage({id: "i210073"}),
                handler: () => sendBulkMessages(),
                supportedContexts: [
                    { context: LiveChatContext.INBOX, segment: LiveChatStatus.IN_PROGRESS },
                    { context: LiveChatContext.INBOX, segment: LiveChatStatus.HOLDING },
                    { context: LiveChatContext.FAVORITE },
                ]
            },
            {
                label: intl.formatMessage({id: "i210074"}),
                handler: () => changeFavoriteLiveChat(true),
                supportedContexts: [
                    { context: LiveChatContext.INBOX, segment: LiveChatStatus.IN_PROGRESS },
                    { context: LiveChatContext.INBOX, segment: LiveChatStatus.HOLDING },
                ]
            },
            {
                label: intl.formatMessage({id: "i210075"}),
                handler: () => changeFavoriteLiveChat(false),
                supportedContexts: [
                    { context: LiveChatContext.FAVORITE },
                ]
            },
            {
                label: intl.formatMessage({id: "i210076"}),
                handler: () => changeLiveChatStatus("holding"),
                supportedContexts: [
                    { context: LiveChatContext.INBOX, segment: LiveChatStatus.IN_PROGRESS },
                ]
            },
            {
                label: intl.formatMessage({id: "i210077"}),
                handler: () => changeLiveChatStatus("responding"),
                supportedContexts: [
                    { context: LiveChatContext.INBOX, segment: LiveChatStatus.HOLDING },
                ]
            },
            {
                label: intl.formatMessage({id: "i210078"}),
                handler: () => changeLiveChatStatus("solved"),
                supportedContexts: [
                    { context: LiveChatContext.INBOX, segment: LiveChatStatus.IN_PROGRESS },
                    { context: LiveChatContext.INBOX, segment: LiveChatStatus.HOLDING },
                ]
            }
        ]
    }, [intl, sendBulkMessages, changeLiveChatStatus, changeFavoriteLiveChat]);

    // accessible bulk actions with current livechat context & chat list segment
    const bulkActions = useMemo(() => {
        return _bulkActions.filter(action =>
            action.supportedContexts.length === 0 ||
            action.supportedContexts.some(level =>
                level.context === context.type &&
                (level.segment === _segmentKey || level.segment === undefined)
            )
        );
    }, [context, _segmentKey, _bulkActions]);

    return useMemo(() => {
        return {
            isBulkMode,
            isBulkActionLoading,
            bulkActions,
            enableBulkMode,
            showBulkCheckBox: bulkActions.length > 0,
            bulkLiveChatIds,
            updateBulkLiveChatIds,
            disableBulkMode,
        }
    }, [
        isBulkMode,
        isBulkActionLoading,
        bulkActions,
        enableBulkMode,
        bulkLiveChatIds,
        updateBulkLiveChatIds,
        disableBulkMode
    ]);
}

export default useLiveChatBulkActionHandler;
