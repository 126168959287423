import {Comment} from "../model/board";
import {action, makeObservable, observable} from "mobx";
import {StoreState} from "../model";
import boardRepository from "../repository/BoardRepository";

class BoardCommentListStore {

    @observable
    offset = 0;

    @observable
    hasPrev: boolean = false;

    @observable
    data: Comment[] = [];

    @observable
    state: StoreState = 'none';

    constructor() {
        makeObservable(this);
    }

    @action
    async load(props: {channelId: string, boardId: string, articleId: string, limit: number}) {
        this.state = 'pending';

        try {
            const {data} = await boardRepository.paginateComment({
                offset: 0,
                channelId: props.channelId,
                boardId: props.boardId,
                articleId: props.articleId,
                limit: props.limit + 1
            });

            this.data = data.result.slice(0, props.limit);
            this.hasPrev = data.result.length > props.limit
            this.offset = data.result.length > 0 ? data.result.length - 1 : 0

            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    async loadPrev(props: {channelId: string, boardId: string, articleId: string, limit: number}) {
        this.state = 'pending';
        try {
            const {data} = await boardRepository.paginateComment({
                offset: this.offset,
                channelId: props.channelId,
                boardId: props.boardId,
                articleId: props.articleId,
                limit: props.limit + 1
            });

            this.data = this.data.concat(data.result.slice(0, props.limit));
            this.hasPrev = data.result.length > props.limit
            this.offset += data.result.length - 1

            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    clear() {
        this.data = [];
        this.state = 'none';
        this.offset = 0
        this.hasPrev = false
    }
}


export default BoardCommentListStore;