import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError, AxiosResponse} from "axios";
import liveChatMemoRepository from "../../repository/LiveChatMemoRepository";


const useUpdateMemo = (channelId: string, liveChatId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, {memoId: string, contents: string}>(['memos', 'update', channelId, liveChatId], async (params) => {
        try {
            return await liveChatMemoRepository.update(params.memoId, channelId, liveChatId, params.contents);
        } catch (e) {
            throw e;
        }
    }, {
        onSuccess: () => {
            return queryClient.invalidateQueries(['memos', channelId, liveChatId], {exact: true});
        }
    });
}

export default useUpdateMemo;
