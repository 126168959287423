import {useCallback, useMemo} from "react";
import {LiveChat} from "../model/livechat";
import {useQueryClient} from "@tanstack/react-query";
import {ChatUser} from "../model/chatUser";
import {atom, useRecoilState} from "recoil";

const liveChatState = atom<string|undefined>({
    key: 'currentLiveChatId',
    default: undefined
})


const useLiveChatHandler = (channelId: string) => {
    const queryClient = useQueryClient();

    const [currentLiveChatId, setCurrentLiveChatId] = useRecoilState(liveChatState);

    // const {data: liveChat} = useLiveChat(channelId, liveChatId);

    const setLiveChatId = useCallback((liveChatId?: string) => {
        setCurrentLiveChatId(liveChatId);
    }, [setCurrentLiveChatId]);

    const updateLiveChat = useCallback((liveChat: LiveChat) => {
        queryClient.setQueryData<LiveChat|undefined>(['liveChat', channelId, liveChat._id], (oldData => {
            const latestMessage = liveChat.latestMessage || oldData?.latestMessage;
            return {
                ...liveChat,
                latestMessage
            }
        }));
    }, [queryClient, channelId]);

    const updateUser = useCallback((liveChatId: string, chatUser: ChatUser) => {
        queryClient.setQueryData<LiveChat | undefined>(['liveChat', channelId, liveChatId], (oldData => {
            if (oldData?.user._id === chatUser._id) {
                return {
                    ...oldData,
                    user: {
                        ...oldData.user,
                        ...chatUser
                    }
                }
            }
            else {
                return oldData;
            }
        }));
    }, [queryClient, channelId]);

    const onlineUser = useCallback((liveChatId: string) => {
        queryClient.setQueryData<LiveChat | undefined>(['liveChat', channelId, liveChatId ?? 'unknown'], (oldData => {
            if (oldData) {
                return {
                    ...oldData,
                    user: {
                        ...oldData.user,
                        online: true
                    }
                }
            }
            return undefined;
        }));
    }, [queryClient, channelId]);

    const offlineUser = useCallback((liveChatId: string) => {
        queryClient.setQueryData<LiveChat | undefined>(['liveChat', channelId, liveChatId ?? 'unknown'], (oldData => {
            if (oldData) {
                return {
                    ...oldData,
                    user: {
                        ...oldData.user,
                        online: false
                    }
                }
            }
            return undefined;
        }));
    }, [queryClient, channelId]);

    const invalidate = useCallback(async (liveChatId: string) => {
        await queryClient.invalidateQueries(['liveChat', channelId, liveChatId], {exact: true});
    }, [queryClient, channelId]);

    const invalidateHistories = useCallback(async (liveChatId: string) => {
        await queryClient.invalidateQueries(['liveChatHistories', channelId, liveChatId], {exact: true});
    }, [queryClient, channelId]);

    // const updateUnreadMessage = useCallback((message: Message) => {
    //     queryClient.setQueryData<LiveChat|undefined>(['liveChat', channelId, message.liveChatId], (oldData => {
    //         if (oldData) {
    //             return {
    //                 ...oldData,
    //                 unreadMessages: [...(oldData.unreadMessages ?? []), message]
    //             }
    //         }
    //     }));
    // }, [queryClient, channelId]);

    const refetchLiveChat = useCallback(async (liveChatId: string) => {
        await queryClient.refetchQueries({queryKey: ['liveChat', channelId, liveChatId], exact: true})
    }, [channelId, queryClient])

    return useMemo(() => {
        return {
            liveChatId: currentLiveChatId,
            setLiveChatId,
            updateLiveChat,
            updateUser,
            onlineUser,
            offlineUser,
            // updateUnreadMessage,
            invalidate,
            invalidateHistories,
            refetchLiveChat
        };
    }, [currentLiveChatId, setLiveChatId, updateLiveChat, updateUser, onlineUser, offlineUser, invalidate, invalidateHistories, refetchLiveChat])
};


export default useLiveChatHandler;