import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError, AxiosResponse} from "axios";
import userCategoryRepository from "../../repository/UserCategoryRepository";


const useCreateUserCategory = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, string>(['userCategories', 'create', channelId], async (categoryId: string) => {
        try {
            return await userCategoryRepository.add(channelId, categoryId)
        } catch (e) {
            throw e;
        }
    }, {
        onSuccess: () => {
            return queryClient.invalidateQueries(['userCategories', channelId], {exact: true});
        }
    });
}

export default useCreateUserCategory;
