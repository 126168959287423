import React, {useEffect, useMemo, useState} from "react";
import {ComponentType, CustomSnippetObject, isCustomSnippetObject} from "../../model/customSnippet";
import CustomSnippetRenderer from "../setting/CustomSnippetRenderer";
import {useIntl} from "react-intl";
import {ChatUser} from "../../model/chatUser";
import useCustomSnippet from "../../query/customSnippet/useCustomSnippet";
import {Spinner} from "react-bootstrap";
import _ from "lodash";
import customSnippetRepository from "../../repository/CustomSnippetRepository";

type CustomChatPanelContentProps = {
    channelId: string;
    snippetId: string;
    chatUser: ChatUser;
}

const CustomChatPanelContent: React.FC<CustomChatPanelContentProps> = (props) => {
    const { channelId, snippetId, chatUser } = props;
    const intl = useIntl();

    const { data: customSnippet, isLoading } = useCustomSnippet(channelId, snippetId);

    const [snippetObject, setSnippetObject] = useState<CustomSnippetObject>();

    const generateErrorObject = (text: string) => {
        return {
            snippet: "error",
            data: [{ type: ComponentType.TEXT, data: { text } }]
        }
    };

    const errorObject = useMemo(() => {
        return generateErrorObject(intl.formatMessage({id: "i200110"}))
    }, [intl]);

    const notFoundErrorObject = useMemo(() => {
        return generateErrorObject(intl.formatMessage({id: "i200122"}))
    }, [intl]);

    const queryParams = useMemo(() => {
        if (customSnippet) {
            return JSON.stringify(customSnippet.queryParams.reduce((prev, cur) => {
                prev[cur.name] = _.get(chatUser, cur.attribute);
                return prev;
            }, {} as { [key: string]: any }))
        }

        return "{}"
    }, [customSnippet, chatUser]);

    useEffect(() => {
        (async () => {
            if (customSnippet) {
                try {
                    const { data } = await customSnippetRepository.bypass(
                        channelId,
                        customSnippet._id,
                        queryParams
                    );
                    setSnippetObject(
                        isCustomSnippetObject(data.result)
                            ? data.result
                            : errorObject
                    );
                } catch (e) {
                    setSnippetObject(errorObject);
                }
            } else {
                setSnippetObject(notFoundErrorObject);
            }
        })();
    }, [channelId, customSnippet, errorObject, notFoundErrorObject, queryParams]);

    if (isLoading) {
        return <Spinner animation="grow" size="sm" />
    } else {
        return (
            snippetObject ? <CustomSnippetRenderer snippetObject={snippetObject} /> : <></>
        )
    }
};

export default CustomChatPanelContent;
