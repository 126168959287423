import React, {CSSProperties, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {IntlProvider} from "react-intl";
import ko from "./i18n/ko";
import en from "./i18n/en";
import {DictType} from "./model";
import {Navigate, Route} from "react-router-dom";
import Dialog from "./hook/useDialog";
import moment from "moment";
import "moment/locale/ko";
import AutoUpdateCheckView from "./view/AutoUpdateCheckView";
import AppNotification from "./hook/useNotification";
import "react-bootstrap-typeahead/css/Typeahead.css";
import 'simplebar-react/dist/simplebar.min.css';
import electronRuntime from "./core/electronRuntime";
import TitleBar from "./component/TitleBar";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import AuthProvider from "./component/AuthProvider";
import platformState from "./recoil/platform";
import {NotificationContainer} from "./hook/useToast";
import * as Sentry from '@sentry/react';
import SentryErrorFallback from "./component/error/SentryErrorFallback";
import {SentryRoutes} from "./core/sentry";
import QnaChatbot from "./component/QnaChatbot";
import MainView from "./view/MainView";
import ChannelRegistrationView from "./view/channel/ChannelRegistrationView";
import ChannelContextView from "./view/ChannelContextView";
import LoginView from "./view/authentication/LoginView";
import {useNetworkState} from "@react-hookz/web";
import toast, {Toaster} from "react-hot-toast";
import {UnStyledButton} from "./component/UnstyledBootstrap";
import deepLinkQueriesState from "./recoil/deepLinkQueries";
import {OAuthAxiosProvider} from "./core/oauthAxios";
import axiosNetworkOnlineState from "./recoil/axiosResult";

function App() {
    const setDeepLinkQueries = useSetRecoilState(deepLinkQueriesState);
    const [platform, setPlatform] = useRecoilState(platformState);
    const [initialized, setInitialized] = useState(!electronRuntime)
    const axiosNetworkOnline = useRecoilValue(axiosNetworkOnlineState);

    const offlineToastIdRef = useRef<string>();

    const onlineState = useNetworkState();

    const onClickReload = useCallback((e: React.MouseEvent) => {
        e.preventDefault();

        if (electronRuntime) {
            electronRuntime.send('loadMain', 'ping');
        }
        else {
            window.location.reload();
        }
    }, []);

    useEffect(() => {
        const resetOfflineToast = () => {
            toast.dismiss(offlineToastIdRef.current);
            offlineToastIdRef.current = undefined;
        }

        if (!onlineState.online || !axiosNetworkOnline) {
            if (offlineToastIdRef.current !== undefined) {
                resetOfflineToast();
            }

            offlineToastIdRef.current = toast.loading(
                <div>
                    <span className="me-1">네트워크 연결이 끊겼습니다.</span>
                    <UnStyledButton className="text-primary" style={{padding: '4px', lineHeight: 1}} onClick={onClickReload}>새로고침</UnStyledButton>
                </div>, {icon: <i className="mdi mdi-wifi-off text-danger font-18" />}
            );
        } else {
            resetOfflineToast();
        }

        if (electronRuntime) {
            electronRuntime.send('online-status-changed', onlineState.online);
        }
    }, [onlineState.online, onClickReload, axiosNetworkOnline]);

    // 앱 실행중, 딥링크의 쿼리 파람을 수신
    useMemo(() => {
        if (!electronRuntime) {
            return;
        }

        const unsubscribe = electronRuntime.subscribe('pass-deep-link-queries', (_, queries: null | {[key: string]: string}) => {
            setDeepLinkQueries(queries)
            setInitialized(true)
        });

        return () => {
            unsubscribe();
        };
    }, [setDeepLinkQueries]);


    // 앱 최초 실행시, 딥링크로 전달된 쿼리파람이 있는지 확인
    useMemo(() => {
        if (electronRuntime) {
            electronRuntime.send('checking-deep-link-queries', 'ping');
        }
    }, [])

    const wrapperStyles: CSSProperties|undefined = useMemo(() => {
        if (electronRuntime) {
            if (platform === 'darwin') {
                return {
                    position: 'fixed',
                    top: '40px',
                    height: 'calc(100% - 40px)'
                }
            }
            else {
                return {
                    top: '32px',
                    position: 'fixed',
                    height: 'calc(100% - 32px)'
                }
            }
        }
        else {
            return undefined;
        }
    }, [platform]);

    const messages: DictType<Record<string, string>> = useMemo(() => {
        return {"ko": ko, "en": en};
    }, []);

    const titleBarHeight = useMemo(() => {
        if (electronRuntime) {
            if (platform === 'darwin') {
                return 40;
            }
            else {
                return 32;
            }
        }
        else {
            return 0;
        }
    }, [platform]);

    const portalStyle = useMemo(() => {
        if (electronRuntime) {
            return {
                height: `calc(100% - ${titleBarHeight}px)`
            }
        }
        else {
            return {height: '100%'};
        }
    }, [titleBarHeight]);

    useEffect(() => {
        if (electronRuntime) {
            electronRuntime.invoke('get-platform').then((result: any) => {
                setPlatform(result);
            });
        }
    }, [setPlatform]);

    useEffect(() => {
        if (!('Notification' in window)) {
            return;
        }

        Notification.requestPermission().then(result => {
            console.debug(`Notification permission: ${result}`);
        }).catch(reason => {
            console.error(`Notification request permission error: ${reason}`);
        });
    }, []);

    useEffect(() => {
        moment.locale('ko');
    }, []);

    if (!initialized) return null

    return (
        <IntlProvider locale={'ko'} messages={messages['ko']}>
            <Sentry.ErrorBoundary fallback={errorData => <SentryErrorFallback {...errorData} />}>
                <OAuthAxiosProvider>
                    {/*<ErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>*/}
                    {electronRuntime && <TitleBar />}
                    <div className="app-wrapper" style={wrapperStyles}>
                        <AppNotification />
                        <SentryRoutes>
                            <Route path="/" element={<RootView />} />
                            <Route path="/login" element={<LoginView />} />
                            <Route path="/channels" element={<AuthProvider />}>
                                <Route index element={<MainView />} />
                                <Route path="registration" element={<ChannelRegistrationView />} />
                                <Route path=":channelId/*" element={<ChannelContextView />} />
                            </Route>

                            {/*<Route path="/channels/*" element={(*/}
                            {/*    <AuthProvider>*/}
                            {/*        <SentryRoutes>*/}
                            {/*            <Route index element={<MainView />} />*/}
                            {/*            <Route path="registration" element={<ChannelRegistrationView />} />*/}
                            {/*            <Route path=":channelId/*" element={<ChannelContextView />} />*/}
                            {/*        </SentryRoutes>*/}
                            {/*    </AuthProvider>*/}
                            {/*)} />*/}
                        </SentryRoutes>
                    </div>
                    <Dialog />
                    <div id="tooltip-portal" />
                    {/*</ErrorBoundary>*/}
                </OAuthAxiosProvider>
            </Sentry.ErrorBoundary>
            <AutoUpdateCheckView />
            <NotificationContainer />
            <QnaChatbot />
            <Toaster position="top-right" containerStyle={{top: `${titleBarHeight + 14}px`, zIndex: 50}} />
            <div id="error-portal" style={portalStyle} />
        </IntlProvider>
    );
}

const RootView: React.FC = () => {
    const defaultChannelId = window.localStorage.getItem('default_channel');
    if (defaultChannelId) {
        return <Navigate to={`/channels/${defaultChannelId}`} replace />
    }
    else {
        return <Navigate to="/channels" replace />
    }
};



// const AppWrapperStyle = styled.div`
//   display: flex;
//   flex-direction: column;
//   height: 100vh;
//   height: -webkit-fill-available;
//   width: 100vw;
//   overflow: hidden;
//
//   .app-content-wrapper {
//     //background-color: #f4f5f7;
//     //background-color: #ffffff;
//     display: flex;
//     flex-shrink: 1;
//     flex-grow: 0;
//     overflow: hidden;
//     height: 100%;
//     //width: 100%;
//
//   }
// `

export default Sentry.withProfiler(App);
