import React, {useCallback} from "react";
import {action, makeObservable, observable} from "mobx";
import {observer} from 'mobx-react-lite';
import {Alert, Button} from "react-bootstrap";
import styled from "styled-components";

class NotificationStore {

    @observable
    isOn: boolean = false;

    @observable
    text: string = '';

    @observable
    buttonText?: string;

    @observable
    onClick?: () => void;

    variant: 'primary'|'info'|'success'|'warning'|'danger' = 'success';

    @observable
    hiddenCloseButton?: boolean;

    constructor() {
        makeObservable(this);
    }

    @action
    onSuccess(text: string) {
        this.isOn = true;
        this.text = text;
        this.variant = 'success';

        this.autoClose();
    }

    @action
    onInfo(text: string, buttonText?: string, onClick?: () => void) {
        this.isOn = true;
        this.text = text;
        this.variant = 'info';

        this.buttonText = buttonText;
        this.onClick = onClick;

        this.autoClose();
    }

    @action
    onWarning(text: string) {
        this.isOn = true;
        this.text = text;
        this.variant = 'warning';

        this.autoClose();
    }

    @action
    onError(text: string) {
        this.isOn = true;
        this.text = text;
        this.variant = 'danger';

        this.autoClose();
    }

    @action
    onResponseError(error: any) {
        if (error.response && error.response.data && error.response.data.hasOwnProperty('error_message') && error.response.data.error_message) {
            this.text = error.response.data.error_message;
        }
        else {
            this.text = 'Error!';
        }
        this.isOn = true;
        this.variant = 'danger';

        this.autoClose();
    }

    @action
    on(params: {text: string, variant?: 'primary'|'info'|'success'|'warning'|'danger', buttonText?: string, onClick?: () => void, autoClose?: boolean, hiddenCloseButton?: boolean}) {
        const {text, variant, buttonText, onClick, autoClose, hiddenCloseButton} = params;
        this.isOn = true;
        this.text = text;
        this.variant = variant || 'info';

        this.buttonText = buttonText;
        this.onClick = onClick;
        this.hiddenCloseButton = hiddenCloseButton;

        if (autoClose) {
            this.autoClose();
        }
    }

    @action
    off() {
        this.isOn = false;
        this.text = '';
    }

    @action
    clear() {
        this.isOn = false;
        this.text = '';
        this.variant = 'success';
    }

    autoClose() {
        setTimeout(() => {
            this.off();
        }, 3000);
    }
}

const context = React.createContext(new NotificationStore());
export const useNotification = () => React.useContext(context);


const AppNotification: React.FC = observer(() => {

    const notification = useNotification();

    const onClose = useCallback(() => {
        notification.off();
    }, [notification]);

    if (notification.isOn) {
        return (
            <Alert variant={notification.variant} onClose={onClose} dismissible={!notification.hiddenCloseButton} className="m-0">
                {notification.text}
                {notification.buttonText && <AlertButton size="sm" onClick={notification.onClick}>{notification.buttonText}</AlertButton>}
            </Alert>
        );
    }
    else {
        return null;
    }
});


const AlertButton = styled(Button)`
    font-size: 12px;
    padding: 0.05rem 0.375rem;
    line-height: 15px;
    margin-left: 0.375rem;
`;

export default AppNotification;
