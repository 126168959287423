import React, {useMemo, useState} from "react";
import moment, {Moment} from "moment/moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {Button, Dropdown, DropdownButton, Tab, Tabs} from "react-bootstrap";
import DashboardViewWrapper from "../../component/dashboard/DashboardViewWrapper";
import useManagers from "../../query/manager/useManagers";
import {useParams} from "react-router-dom";
import {Manager} from "../../model/manager";
import {useIntl} from "react-intl";
import ResponseStatusDashboardView from "./ResponseStatusDashboardView";
import useLiveChatStatisticSummary from "../../query/statistic/useLiveChatStatisticSummary";
import QualityStatusDashboardView from "./QualityStatusDashboardView";
import {koLocaleForDateRangePicker} from "../../util/datetime";


const MainDashboardView: React.FC = () => {
    const intl = useIntl();
    const {channelId} = useParams<{channelId: string}>();
    const { data: managers } = useManagers(channelId!);

    const [period, setPeriod] = useState({
        startDate: moment().subtract(6, 'days').startOf('day'),
        endDate: moment().endOf('day')
    })
    const [manager, setManager] = useState<Manager|undefined>(undefined);
    const [tabKey, setTabKey] = useState("response");

    const {
        data: liveChatStatisticSummary,
        isLoading: isLiveChatStatisticSummaryLoading,
        refetch: refetchLiveChatStatisticSummary
    } = useLiveChatStatisticSummary(
        channelId!,
        {
            startDate: period.startDate.utc().toISOString(),
            endDate: period.endDate.utc().toISOString(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            managerUserId: manager?.userId
        }
    )

    const previousPeriod = useMemo(() => {
        const diff = period.endDate.clone().diff(period.startDate.clone(), 'days');
        return {
            startDate: period.endDate.clone().local().subtract(2 * diff + 1, 'days').startOf('day'),
            endDate: period.endDate.clone().local().subtract(diff + 1, 'days').endOf('day')
        }
    }, [period])

    const onDatePickerCallBack = (start?: Moment, end?: Moment) => {
        if (start && end) {
            setPeriod({startDate: start.startOf('day'), endDate: end.endOf('day')});
        }
    }

    const onClickRefresh = async () => {
        await refetchLiveChatStatisticSummary();
    }

    return (
        <DashboardViewWrapper>
            <div className="dashboard-title">
                <h3>{intl.formatMessage({id: "i200009"})}</h3>
            </div>

            <div className="d-flex justify-content-between mb-2">
                <div className="d-flex">
                    <DateRangePicker onCallback={onDatePickerCallBack}
                                     initialSettings={{
                                         startDate: period.startDate,
                                         endDate: period.endDate,
                                         minDate: moment().subtract(2, 'years'),
                                         maxDate: moment(),
                                         ranges: {
                                             '오늘': [moment(), moment()],
                                             '어제': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                             '최근 7일': [moment().subtract(6, 'days'), moment()],
                                             '최근 30일': [moment().subtract(29, 'days'), moment()],
                                             '이번 달': [moment().startOf('month'), moment().endOf('month')],
                                             '저번 달': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')]
                                         },
                                         locale: koLocaleForDateRangePicker()
                                     }}>
                        <Button variant="light">
                            {period.startDate.local().format('YYYY.MM.DD')} - {period.endDate.local().format('YYYY.MM.DD')}
                        </Button>
                    </DateRangePicker>
                    <DropdownButton title={<><i className="mdi mdi-account me-1"/>{manager?.name ?? intl.formatMessage({id: "i200015"})}</>}
                                    variant="light"
                                    className="ms-2">
                        <Dropdown.Item key="whole-managers" onClick={() => setManager(undefined)}>
                            {intl.formatMessage({id: "i200015"})}
                        </Dropdown.Item>
                        {managers && managers.map(manager => {
                            return (
                                <Dropdown.Item key={manager.userId} onClick={() => setManager(manager)}>
                                    {manager.name}
                                </Dropdown.Item>
                            )
                        })}
                    </DropdownButton>
                </div>
                <Button variant="outline-secondary" className="ms-2 rounded" onClick={onClickRefresh} disabled={isLiveChatStatisticSummaryLoading}>
                    <i className="mdi mdi-refresh me-1"/>
                    {intl.formatMessage({id: "i200143"})}
                </Button>
            </div>

            <Tabs id="dashboard-tabs"
                  className="nav-bordered mx-0"
                  activeKey={tabKey}
                  onSelect={(key) => setTabKey(key ? key : "response")}>
                <Tab title={intl.formatMessage({id: "i200016"})} eventKey="response">
                    <ResponseStatusDashboardView liveChatStatisticSummary={liveChatStatisticSummary}
                                                 isLoading={isLiveChatStatisticSummaryLoading}
                                                 period={period} />
                </Tab>
                <Tab title={intl.formatMessage({id: "i200065"})} eventKey="quality">
                    <QualityStatusDashboardView liveChatStatisticSummary={liveChatStatisticSummary}
                                                isLoading={isLiveChatStatisticSummaryLoading}
                                                period={period}
                                                previousPeriod={previousPeriod}
                                                manager={manager} />
                </Tab>
            </Tabs>
        </DashboardViewWrapper>
    )
};



export default MainDashboardView;
