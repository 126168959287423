import {ChatSearchRequestParams, ChatSearchResultResponse} from "../../model/chatSearch";
import {useQuery} from "@tanstack/react-query";
import {LiveChatError, toLiveChatError} from "../../error";
import messageRepository from "../../repository/MessageRepository";
import toast from "react-hot-toast";
import {useIntl} from "react-intl";

const getData = async (channelId: string, params: ChatSearchRequestParams) => {
    try {
        const { data } = await messageRepository.search(channelId, params);
        return data.result
    } catch (e) {
        throw toLiveChatError(e, {noErrorPage: true});
    }
};

const useSearchMessages = (channelId: string, searchParams: ChatSearchRequestParams) => {
    const intl = useIntl();
    return useQuery<ChatSearchResultResponse, LiveChatError>({
        queryKey: ['chatSearch', channelId, ...Object.values(searchParams)],
        queryFn: () => getData(channelId, searchParams),
        enabled: searchParams.searchText.length > 0,
        onError: (err) => {
            toast.error(intl.formatMessage({id: err.code === "404" ? "i210028": "e000001"}))
        },
        keepPreviousData: true,
        useErrorBoundary: false,
    })
};

export default useSearchMessages;
