import {useCallback, useMemo} from "react";
import useAnnouncements from "../query/announcement/useAnnouncements";
import {useQueryClient} from "@tanstack/react-query";
import {Announcement} from "../model/announcement";
import {UnreadToast} from "../model/unreadToast";
import {useLocalStorageValue} from "@react-hookz/web";
import {READ_TOASTS_STORAGE_KEY} from "../core/variables";
import {isWithinDaysFromNow} from "../util/datetime";
import {useIntl} from "react-intl";
import useFeeds from "../query/feed/useFeeds";
import {ManagerFeed} from "../model/managerFeeds";
import moment from "moment/moment";

const useUnreadToastHandlers = (channelId: string) => {
    const intl = useIntl();
    const queryClient = useQueryClient();

    const announcementsQuery = useAnnouncements(channelId);
    const managerFeedsQuery = useFeeds(channelId);

    const [readToasts, setReadToasts] = useLocalStorageValue<UnreadToast[]>(
        READ_TOASTS_STORAGE_KEY, [], {storeDefaultValue: true}
    );

    const initialized = useMemo(() => {
        return announcementsQuery.isSuccess
    }, [announcementsQuery.isSuccess]);

    const unreadToasts: UnreadToast[] = useMemo(() => {
        const allUnreadToasts: UnreadToast[] = [];

        announcementsQuery.data?.filter(announcement => {
            const stale = !isWithinDaysFromNow(announcement.createdAt, 7);
            const unread = !readToasts?.find(item => item.channelId === channelId && item._id === announcement._id);
            return !stale && unread;
        }).forEach(announcement => {
            allUnreadToasts.push({
                _id: announcement._id,
                channelId: channelId,
                type: "announcement",
                authorId: announcement.authorId,
                senderIsSystem: false,
                title: intl.formatMessage({id: "i210057"}),
                blocks: announcement.blocks,
                createdAt: announcement.createdAt,
            });
        });

        managerFeedsQuery.data?.filter(feed => {
            const stale = !isWithinDaysFromNow(feed.createdAt, 7);
            const unread = !readToasts?.find(item => item.channelId === channelId && item._id === feed._id);
            return !stale && unread;
        }).forEach(feed => {
            allUnreadToasts.push({
                _id: feed._id,
                channelId: channelId,
                type: "personal",
                authorId: feed.authorId,
                senderIsSystem: feed.senderIsSystem,
                title: intl.formatMessage({id: "i000301"}),
                blocks: feed.blocks,
                createdAt: feed.createdAt,
            });
        });

        return allUnreadToasts.sort((a, b) => {
            const dateA = moment(a.createdAt);
            const dateB = moment(b.createdAt);
            return dateA.isAfter(dateB) ? 1 : -1;
        });
    }, [intl, channelId, announcementsQuery.data, readToasts, managerFeedsQuery.data]);

    const addNewAnnouncement = useCallback((announcement: Announcement) => {
        queryClient.setQueryData<Announcement[]>(
            ['announcements', channelId, undefined],
            (oldData) => {
                if (oldData && oldData.length > 0 ) {
                    if (oldData.find(item => item.channelId === channelId && item._id === announcement._id)) {
                        // TODO: update existing announcements, but now just return oldData to prevent duplication
                        return [...oldData];
                    } else {
                        return [announcement, ...oldData];
                    }
                } else {
                    return [announcement];
                }
            }
        );
    }, [queryClient, channelId]);

    const removeUnreadToast = useCallback((unreadToast: UnreadToast) => {
        setReadToasts(prev => {
            const refreshReadToasts = prev?.filter(item => {
                return isWithinDaysFromNow(item.createdAt, 7);
            }) ?? [];

            return [...refreshReadToasts, unreadToast];
        });
    }, [setReadToasts]);

    const addNewFeed = useCallback((feed: ManagerFeed) => {
        queryClient.setQueryData<ManagerFeed[]>(
            ['feeds', channelId],
            (oldData) => {
                if (oldData && oldData.length > 0 ) {
                    if (oldData.find(item => item.channelId === channelId && item._id === feed._id)) {
                        return [...oldData];
                    } else {
                        return [feed, ...oldData];
                    }
                } else {
                    return [feed];
                }
            }
        );
    }, [queryClient, channelId]);

    return useMemo(() => {
        return {
            unreadToasts,
            initialized,
            addNewAnnouncement,
            addNewFeed,
            removeUnreadToast,
        }
    }, [
        unreadToasts,
        initialized,
        addNewAnnouncement,
        addNewFeed,
        removeUnreadToast
    ]);
}

export default useUnreadToastHandlers;
