import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import {LiveChatError, toLiveChatError} from "../../error";
import oauthAxios from "../../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../../core/variables";
import {LiveChatWorkingHolidayConfig, LiveChatWorkingTime} from "../../model/channel";


export interface updateWorkingTimesProps {
    workingTimeMode?: string,
    working?: boolean,
    workingTimes?: LiveChatWorkingTime[],
    workingHolidayConfig?: LiveChatWorkingHolidayConfig,
    timezone?: string
}

const useUpdateWorkingTimes = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, LiveChatError, updateWorkingTimesProps>({
        mutationKey: ['channels', 'workingTimes', 'update', channelId],
        mutationFn: async (params) => {
            try {
                return await oauthAxios.put(`${LIVE_CHAT_API_HOST}/v2/channels/${channelId}/config/working`, params);
            } catch (e) {
                throw toLiveChatError(e);
            }
        },
        onSuccess: () => {
            return queryClient.invalidateQueries(['channels', channelId], {exact: true})
        }
    });
}

export default useUpdateWorkingTimes;
