import {LiveChatStatisticSummaryRequestParams, LiveChatStatisticSummaryResponse} from "../../model/statistic";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {toLiveChatError} from "../../error";
import oauthAxios from "../../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../../core/variables";

const getData = async (channelId: string, requestParam: LiveChatStatisticSummaryRequestParams) => {
    try {
        const { data } = await oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/statistic/response-status`, {
            params: {
                startDate: requestParam.startDate,
                endDate: requestParam.endDate,
                timezone: requestParam.timezone,
                managerUserId: requestParam.managerUserId
            }
        })
        return data.result;
    } catch (e) {
        throw toLiveChatError(e);
    }
}

const useLiveChatStatisticSummary = (channelId: string, requestParam: LiveChatStatisticSummaryRequestParams) => {
    return useQuery<LiveChatStatisticSummaryResponse, AxiosError>(
        ['response-status', channelId, requestParam.startDate, requestParam.endDate, requestParam.managerUserId],
        async () => getData(channelId, requestParam),
        {suspense: false, refetchOnMount: true}
    )
};

export default useLiveChatStatisticSummary;
