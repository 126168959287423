import React, {useEffect, useMemo, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {useIntl} from "react-intl";
import {Button} from "react-bootstrap";
import styled from "styled-components";
import chatUserRepository from "../../repository/ChatUserRepository";
import {ChatUser, ChatUserAttribute} from "../../model/chatUser";
import moment from "moment";
import useUserAttributes from "../../query/useUserAttributes";
import ChatPanelLabel from "./ChatPanelLabel";
import classNames from "classnames";
import {CenteredIcon} from "../UnstyledBootstrap";
import useTooltip from "../../hook/useTooltip";
import liveChatRepository from "../../repository/LiveChatRepository";
import useToast from "../../hook/useToast";
import toast from "react-hot-toast";

type ChatUserInfoCardProps = {
    channelId: string,
    liveChatId: string,
    user: ChatUser,
    showUserInfoBtn: boolean
}

const NUM_REGEX = /^[0-9]*$/;

const ChatUserInfoCard: React.FC<ChatUserInfoCardProps & React.HTMLProps<HTMLDivElement>> = observer(({channelId, liveChatId, user, showUserInfoBtn, className, style}) => {
    const intl = useIntl();
    const {data: userAttributes} = useUserAttributes(channelId);

    const onClickSendUserInfoForm = async (e: React.MouseEvent) => {
        e.preventDefault();

        try {
            await liveChatRepository.requestUserInfo(channelId, liveChatId);
        } catch (_) {
            toast.error(intl.formatMessage({id: 'i000216'}));
        }
    };

    return (
        <div className={className} style={style}>
            {userAttributes && (
                <>
                    {userAttributes.map((chatUserAttribute, index) => (
                        <UserAttributeInfo key={index}
                                           chatUser={user}
                                           chatUserAttribute={chatUserAttribute}
                                           channelId={channelId} />
                    ))}
                    <TagsFragment channelId={channelId} chatUser={user} />

                    {showUserInfoBtn && (
                        <div className="d-flex justify-content-center">
                            <Button size="sm" variant="link" className="p-0 my-1" onClick={onClickSendUserInfoForm}>
                                {intl.formatMessage({id: 'i000168'})}
                            </Button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
});

const UserAttributeInfo: React.FC<UserAttributeInfoProps> = (props) => {
    const {chatUserAttribute, chatUser, channelId} = props;
    const intl = useIntl();
    const toast = useToast();

    const [editing, setEditing] = useState(false);

    const valueRef = useRef<HTMLInputElement>(null);

    const value = useMemo(() => {
        if (chatUserAttribute.name.startsWith('profile.')) {
            if (chatUser.profile) {
                const value = chatUser.profile[chatUserAttribute.name.replace('profile.', '')];

                if (chatUserAttribute.type === 'date' && value) {
                    return moment.utc(value).local().format('YYYY-MM-DD[T]HH:mm:ss');
                }
                else {
                    return value
                }
            }
            else {
                return '';
            }
        }
        else if (chatUserAttribute.name.startsWith('custom.')) {
            if (chatUser.custom) {
                const value = chatUser.custom[chatUserAttribute.name.replace('custom.', '')];

                if (chatUserAttribute.type === 'date' && value) {
                    return moment.utc(value).local().format('YYYY-MM-DD[T]HH:mm:ss');
                }
                else {
                    return value
                }
            }
            else {
                return '';
            }
        }
        else {
            let value = chatUser[chatUserAttribute.name];
            if (chatUserAttribute.name === 'name' && !value) {
                value = chatUser.alias;
            }

            if (chatUserAttribute.type === 'date' && value) {
                return moment.utc(value).local().format('YYYY-MM-DD[T]HH:mm:ss');
            }
            else {
                return value
            }
        }
    }, [chatUserAttribute, chatUser]);

    const displayValue = useMemo(() => {
        if (chatUserAttribute.type === 'date' && value) {
            return moment(value).format('LLL');
        }
        else {
            return value;
        }
    }, [value, chatUserAttribute]);

    const valueTooltip = useTooltip(displayValue);

    const inputType = useMemo(() => {
        switch (chatUserAttribute.type) {
            case 'text':
                return 'text';
            case 'number':
                return 'number';
            case 'date':
                return 'datetime-local';
            default:
                return 'text';
        }
    }, [chatUserAttribute.type]);

    useEffect(() => {
        if (editing) {
            valueRef.current?.focus();
        }
    }, [editing]);

    const onClickEditBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        if (!chatUserAttribute.editable) {
            return;
        }

        setEditing(true);
    };

    const onBlurValueInput = async (e: React.FocusEvent) => {
        e.preventDefault();
        if (chatUserAttribute.type === 'date') {
            await patchAttribute();
        }

        setEditing(false);
    };

    const onKeyDownValueInput = async (e: React.KeyboardEvent) => {
        if (e.key.toLowerCase() === 'enter') {
            e.preventDefault();

            await patchAttribute();
            setEditing(false);
        }
    };

    const patchAttribute = async () => {
        let value;
        if (chatUserAttribute.type === 'date' && valueRef.current?.value) {
            value = moment(valueRef.current?.value).utc().toISOString();
        }
        else {
            value = valueRef.current?.value;
        }
        try {
            const params: {[key: string]: any} = {};
            if (chatUserAttribute.default) {
                if (chatUserAttribute.name === 'phone' && value && !NUM_REGEX.test(value)) {
                    toast.warning(intl.formatMessage({id: 'i000330'}))
                    return;
                }

                params[chatUserAttribute.name] = value;
            }
            else {
                params['custom'] = {[chatUserAttribute.name.replace('custom.', '')]: value}
            }

            await chatUserRepository.patch(channelId, chatUser._id, params);
        } catch (e) {

        }
    };

    return (
        <UserAttributeInfoStyle className={classNames({editing: editing, editable: chatUserAttribute.editable})} onClick={onClickEditBtn}>
            <div className="info-wrapper">
                <ChatPanelLabel title={chatUserAttribute.displayName ?? chatUserAttribute.name}>{chatUserAttribute.displayName ?? chatUserAttribute.name}</ChatPanelLabel>
                {editing && (
                    <InputStyle type={inputType}
                                ref={valueRef}
                                defaultValue={value}
                                onBlur={onBlurValueInput}
                                onKeyDown={onKeyDownValueInput}
                                placeholder={intl.formatMessage({id: 'i000278'})} step={60} />
                )}
                {!editing && (
                    <AttributeValueStyle ref={valueTooltip.setTriggerRef}>
                        {displayValue || intl.formatMessage({id: 'i000277'})}
                        {valueTooltip.visible && valueTooltip.element}
                    </AttributeValueStyle>
                )}
            </div>
            {chatUserAttribute.editable && <CenteredIcon size={18} className="mdi mdi-square-edit-outline edit-icon font-18" />}
        </UserAttributeInfoStyle>
    );
};

const TagsFragment: React.FC<{channelId: string, chatUser: any}> = ({channelId, chatUser}) => {
    const intl = useIntl();

    const [editing, setEditing] = useState(false);

    const valueRef = useRef<HTMLInputElement>(null);

    const value = useMemo(() => {
        return chatUser.tags.join(', ');
    }, [chatUser]);

    const tooltip = useTooltip(value);

    useEffect(() => {
        if (editing) {
            valueRef.current?.focus();
        }
    }, [editing]);

    const onClickEditBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        setEditing(true);
    };

    const onBlurValueInput = (e: React.FocusEvent) => {
        e.preventDefault();
        setEditing(false);
    };

    const onKeyDownValueInput = async (e: React.KeyboardEvent) => {
        if (e.key.toLowerCase() === 'enter') {
            e.preventDefault();
            const tags = valueRef.current?.value.split(',').map(tag => tag.trim());
            try {
                await chatUserRepository.patch(channelId, chatUser._id, {'tags': tags});
            } catch (e) {

            }
            setEditing(false);
        }
    };

    return (
        <UserAttributeInfoStyle className={classNames('editable', {editing: editing})} onClick={onClickEditBtn}>
            <div className="info-wrapper">
                <ChatPanelLabel>{intl.formatMessage({id: 'i000279'})}</ChatPanelLabel>
                {editing && (
                    <InputStyle ref={valueRef}
                                defaultValue={value}
                                onBlur={onBlurValueInput}
                                onKeyDown={onKeyDownValueInput}
                                placeholder={intl.formatMessage({id: 'i000280'})} />
                )}
                {!editing && (
                    <AttributeValueStyle ref={tooltip.setTriggerRef}>
                        {value}
                        {tooltip.visible && tooltip.element}
                    </AttributeValueStyle>
                )}
            </div>
            <CenteredIcon size={18} className="mdi mdi-square-edit-outline edit-icon font-18" />
        </UserAttributeInfoStyle>
    );
};

interface UserAttributeInfoProps {
    chatUserAttribute: ChatUserAttribute;
    channelId: string;
    chatUser: any;
}


const UserAttributeInfoStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.1rem 0.375rem;
  margin: 0 -0.375rem;
  
  .info-wrapper {
    display: flex;
    align-items: center;
    width: calc(100% - 20px);
    height: 28px;
    //line-height: 28px;
  }
  
  &.editable {
    cursor: pointer;
    &:hover {
      background-color: var(--ct-light);
    }
  }

  &.editing {
    .info-wrapper {
      width: calc(100%);
    }
    .edit-icon {
      display: none;
    }
  }
`;


const AttributeValueStyle = styled.span`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const InputStyle = styled.input`
  border: 1px solid #39afd1;
  border-radius: 4px;
  width: 100%;
  height: auto;
  outline: none;
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 0.8rem;
  padding: 5px 8px;
`;

export default ChatUserInfoCard;
