import moment, {Moment} from "moment";


export const utcToLocal = (datetime: string, format?: string) => {
    return moment.utc(datetime).local().format(format || 'YYYY-MM-DD');
};

export const toUTC = (datetime: string) => {
    return moment(datetime).utc().toISOString();
};

export const koLocaleForDateRangePicker = () => {
    return {
        format: "YYYY년 MM월 DD일",
        applyLabel: "적용",
        cancelLabel: "취소",
        customRangeLabel: "사용자 설정 기한",
        daysOfWeek: ["일", "월", "화", "수", "목", "금", "토"],
    }
};

export const genDateRangeLabel = (start: Moment|undefined, end: Moment|undefined, format: string) => {
    return `${moment(start).local().format(format)} ~ ${moment(end)?.local().format(format)}`
};

export const isWithinDaysFromNow = (datetime: string, days: number) => {
    return moment.utc().diff(moment.utc(datetime), 'days') < days;
};
