import {useQuery} from "@tanstack/react-query";
import oauthAxios from "../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../core/variables";
import {LiveChatError, toLiveChatError} from "../error";
import {ChannelIntegration} from "../model/channel";


const getData = async (channelId: string) => {
    try {
        const {data} = await oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/integration`);
        return data.result;
    } catch (e: any) {
        if (e.response.status === 404) {
            return null
        } else {
            throw toLiveChatError(e);
        }
    }
}


const useChannelIntegration = (channelId: string) => {
    return useQuery<ChannelIntegration | null, LiveChatError>({
        queryKey: ['channels', channelId, 'integration'],
        queryFn: () => getData(channelId)
    });
};


export default useChannelIntegration;