import {useQuery} from "@tanstack/react-query";
import managerRepository from "../../repository/ManagerRepository";
import {Manager} from "../../model/manager";
import {LiveChatError, toLiveChatError} from "../../error";


export const getData = async (channelId: string, name?: string) => {
    try {
        const {data} = await managerRepository.find(channelId, name);
        return data.result;
    } catch (e) {
        throw toLiveChatError(e);
    }

}

const useManagers = (channelId: string, name?: string) => {
    return useQuery<Manager[], LiveChatError>({
        queryKey: ['managers', channelId, name],
        queryFn: async () => getData(channelId, name),
        suspense: false
    });
};


export default useManagers;