import React, {useCallback, useRef, useState} from "react";
import {ChatbotMessage, MessageData} from "../../model/chatbot";
import {Linkify} from "../Linkify";
import ButtonMessage from "./ButtonMessage";
import {Image} from "react-bootstrap";
import ReactPlayer from "react-player";
import {useInView} from "react-intersection-observer";
import Viewer from "react-viewer";

const CarouselMessage: React.FC<CarouselMessageProps> = ({message}) => {

    const carouselRef = useRef<any>();

    const [firstItemRef, firstItemInView] = useInView({threshold: 0.999});
    const [lastItemRef, lastItemInView] = useInView({threshold: 0.999});

    const renderMediaFragment = useCallback((data: MessageData) => {
        if (data.media_type === 'image') {
            return (
                <ImageFragment src={data.media_url} />
            );
        }
        else if (data.media_type === 'video') {
            return (
                <VideoFragment url={data.media_url} />
            );
        }
        else if (data.media_type === 'audio') {
            return (
                <AudioFragment url={data.media_url} />
            );
        }
        else {
            return (
                <p>Unsupported media type</p>
            );
        }
    }, []);

    const onClickLeftArrow = (e: React.MouseEvent) => {
        e.preventDefault();
        let offset = carouselRef.current.scrollLeft - 265;
        if (offset < 0) {
            offset = 0;
        }
        carouselRef.current.scrollLeft = offset;
    };

    const onClickRightArrow = (e: React.MouseEvent) => {
        e.preventDefault();
        let offset = carouselRef.current.scrollLeft + 265;
        if (carouselRef.current.scrollWidth < offset) {
            offset = carouselRef.current.scrollWidth - 260;
        }
        carouselRef.current.scrollLeft = offset;
    };

    return (
        <div className="message-content align-items-center">
            {(message.data?.length || 0) > 2 && !firstItemInView && (
                <div className="carousel-arrow-left">
                    <button onClick={onClickLeftArrow}><i className="mdi mdi-chevron-left" /></button>
                </div>
            )}

            <div className="carousel-message" ref={carouselRef}>
                {message.data?.map((data, index) => {
                    let ref = undefined;
                    const length = message.data?.length || 0;
                    if (length > 2) {
                        if (index === 0) {
                            ref = firstItemRef;
                        }
                        else if (index !== 0 && index + 1 === length) {
                            ref = lastItemRef;
                        }
                    }
                    return (
                        <div key={index} className="carousel-message-item" ref={ref}>
                            {data.media_type && renderMediaFragment(data)}
                            {data.title && <h5>{data.title}</h5>}
                            {data.text && <div className="carousel-message-item-text"><Linkify>{data.text}</Linkify></div>}
                            {data.buttons && data.buttons.filter(button => button.label).map((button, btnIndex) => (
                                <ButtonMessage key={btnIndex} data={button} disabled={true} />
                            ))}
                        </div>
                    );
                })}
            </div>
            {((message.data?.length || 0) > 2 && !lastItemInView) && (
                <div className="carousel-arrow-right">
                    <button onClick={onClickRightArrow}><i className="mdi mdi-chevron-right" /></button>
                </div>
            )}
        </div>
    );
};

const ImageFragment: React.FC<ImageFragmentProps> = ({src}) => {

    const [zoom, setZoom] = useState(false);

    const toggleImage = () => {
        setZoom(!zoom);
    };

    return (
        <>
            <div className="media" onClick={toggleImage}>
                <Image src={src} />
            </div>
            {(zoom && src) && <Viewer visible={zoom}
                                      onClose={toggleImage}
                                      onMaskClick={toggleImage}
                                      images={[{src: src, downloadUrl: src}]}
                                      rotatable={false}
                                      scalable={false}
                                      changeable={false}
                                      downloadable={true}
                                      noNavbar={true} />
            }
        </>
    );
};

const VideoFragment: React.FC<VideoFragmentProps> = ({url}) => {

    return (
        <div className="media">
            <ReactPlayer url={url} width={'100%'} height={'100%'} controls={true} config={{
                file: {
                    attributes: {
                        controlsList: "nodownload",
                        disablePictureInPicture: true
                    }
                },
                youtube: {

                }
            }} />
        </div>
    );
};

const AudioFragment: React.FC<AudioFragmentProps> = ({url}) => {

    return (
        <div className="media">
            <ReactPlayer url={url} width={'100%'} height={40} controls={true} config={{
                file: {
                    attributes: {
                        controlsList: "nodownload",
                        disablePictureInPicture: true
                    }
                }
            }} />
        </div>
    );
};

type CarouselMessageProps = {
    message: ChatbotMessage<MessageData[]>;
};

type ImageFragmentProps = {
    src?: string;
}

type VideoFragmentProps= {
    url?: string;
};

type AudioFragmentProps= {
    url?: string;
};


export default CarouselMessage;
