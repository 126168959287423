import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useRecoilState, useSetRecoilState} from "recoil";
import downloadFilesState from "../recoil/downloadFiles";
import {DefaultExtensionType, defaultStyles, FileIcon} from "react-file-icon";
import electronRuntime from "../core/electronRuntime";
import styled from "styled-components";
import {Card, ProgressBar} from "react-bootstrap";
import {CenteredIcon} from "./UnstyledBootstrap";
import SimpleBar from "simplebar-react";
import {useIntl} from "react-intl";
import useToast from "../hook/useToast";

const DownloadStatusBar: React.FC = () => {
    const intl = useIntl();
    const toast = useToast();
    const [downloadFiles, setDownloadFiles] = useRecoilState(downloadFilesState);

    useEffect(() => {
        if (!electronRuntime) {
            return;
        }

        const unsubscribe = electronRuntime.subscribe('download-started', (event, {id, filename}) => {
            setDownloadFiles(prev => prev.map(file => {
                if (file.id === id) {
                    return {
                        ...file,
                        filename: filename
                    }
                }
                else {
                    return file
                }
            }));
        });

        return () => {
            unsubscribe();
        };
    }, [setDownloadFiles]);

    useEffect(() => {
        if (!electronRuntime) {
            return;
        }

        const unsubscribe = electronRuntime.subscribe('download-cancel', (event, {id}) => {
            setDownloadFiles(prev => prev.filter(file => file.id !== id));
        });

        return () => {
            unsubscribe();
        };
    }, [setDownloadFiles]);

    useEffect(() => {
        if (!electronRuntime) {
            return;
        }

        const unsubscribe = electronRuntime.subscribe('download-completed', (event, {id, filename}) => {
            if (!downloadFiles.some(file => file.id === id)) {
                toast.success(intl.formatMessage({id: 'i000331'}), filename);
            }
        });

        return () => {
            unsubscribe();
        };
    }, [downloadFiles, toast, intl]);

    const onClickCloseBtn = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        setDownloadFiles([]);
    }, [setDownloadFiles]);

    if (downloadFiles.length > 0) {
        return (
            <div style={{backgroundColor: '#f4f5f7'}} className="px-2 shadow flex-grow-1">
                <Card className="mt-2 mb-3 mx-2">
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <span>{intl.formatMessage({id: 'i000332'})}</span>
                        <CenteredIcon size={20} className="mdi mdi-close float-end font-20" role="button" onClick={onClickCloseBtn} />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <SimpleBar style={{maxHeight: '320px'}} className="py-2 px-3">
                            <DownloadFilesStyle>
                                {downloadFiles.map(downloadFile => (
                                    <DownloadFile key={downloadFile.id} file={downloadFile} />
                                ))}
                            </DownloadFilesStyle>
                        </SimpleBar>
                    </Card.Body>
                </Card>
            </div>
        );
    }
    else {
        return null;
    }

};

const DownloadFile: React.FC<{file: {id: string, filename: string, url: string}}> = ({file}) => {
    const [progress, setProgress] = useState(0);
    const [completed, setCompleted] = useState(false);
    const [path, setPath] = useState('');

    const setDownloadFiles = useSetRecoilState(downloadFilesState);

    const progressRef = useRef<HTMLSpanElement>(null);

    const extension: DefaultExtensionType = useMemo(() => {
        const split = file.filename.split('.') ?? [];
        if (split.length > 1) {
            return (split.pop() || 'txt') as DefaultExtensionType;
        }

        return 'txt';
    }, [file]);

    useEffect(() => {
        if (!electronRuntime) {
            return;
        }

        const unsubscribe = electronRuntime.subscribe('download-progress', (event, args) => {
            if (file.id === args.id && progressRef.current) {
                const percent = Math.floor(args.progress.percent * 100);
                progressRef.current.innerHTML = `${percent}%`;
                setProgress(prev => prev < percent ? percent : prev);
            }
        });

        return () => {
            unsubscribe();
        };
    }, [setProgress, file]);

    useEffect(() => {
        if (!electronRuntime) {
            return;
        }

        const unsubscribe = electronRuntime.subscribe('download-completed', (event, {id, item}) => {
            if (id === file.id) {
                setCompleted(true);
                setPath(item.path)
            }
        });

        return () => {
            unsubscribe();
        };
    }, [setDownloadFiles, file]);

    const onClickOpenFile = (e: React.MouseEvent) => {
        e.preventDefault();
        setDownloadFiles(prev => prev.filter(f => f.id !== file.id));
        electronRuntime.send('open-path', {path: path});
    };

    const onClickClose = (e: React.MouseEvent) => {
        e.preventDefault();

        setDownloadFiles(prev => prev.filter(f => f.id !== file.id));
    }

    return (
        <DownloadFileStyle>
            <div className="download-file-thumbnail">
                <FileIcon extension={extension} {...defaultStyles[extension]} fold={false} />
            </div>
            <div className="ml-1 flex-grow-1">
                <div className="d-flex justify-content-between">
                    <p className="mb-1 text-truncate flex-grow-1" style={{maxWidth: '260px'}}>{file.filename}</p>
                    {completed && <CenteredIcon role="button" size={24} className="mdi mdi-close flex-shrink-0" onClick={onClickClose} />}
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <ProgressBar min={0} max={100} now={progress} striped={false} variant="primary" className="flex-grow-1" style={{height: '8px'}} />
                    {completed ? (
                        <span className="text-info font-12 ml-1 text-end" role="button" onClick={onClickOpenFile}>열기</span>
                    ) : (
                        <span className="text-muted font-12 ml-1 text-end" ref={progressRef} style={{width: '40px'}}>0%</span>
                    )}
                </div>
            </div>
        </DownloadFileStyle>
    );
};

const DownloadFilesStyle = styled.div`
  background-color: #ffffff;
`

const DownloadFileStyle = styled.div`
  display: flex;
  align-items: center;
  //padding: 0.375rem;
  
  .download-file-thumbnail {
    width: 36px;
  }
`

export default DownloadStatusBar;