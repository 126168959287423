import {LIVE_CHAT_API_HOST} from "../core/variables";
import oauthAxios from "../core/oauthAxios";

export type PaginateArticleProps = {
    channelId: string
    boardId: string
    startDate?: string
    endDate?: string
    offset: number
    limit: number
}

export type PaginateCommentProps = {
    channelId: string,
    boardId: string,
    articleId: string,
    offset: number,
    limit: number
}

export type WriteArticleData = {
    channelId: string,
    boardId: string,
    writer: string,
    title: string,
    html?: string,
    content?: string,
    replyArticleId?: string
}

class BoardRepository {

    async find(channelId: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/boards`);
    }

    async writeArticle(props: WriteArticleData) {
        const {channelId, boardId, writer, title, html, content, replyArticleId} = props
        return oauthAxios.post(
            `${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/boards/${boardId}/articles`,
            {writer, title, html, content, replyArticleId: replyArticleId},
        );
    }

    async updateArticle(props: {channelId: string, boardId: string, articleId: string, title: string, content?: string, html?: string}) {
        const {channelId, boardId, articleId, title, html, content} = props
        return oauthAxios.put(
            `${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/boards/${boardId}/articles/${articleId}`,
            {title, html, content}
        );
    }

    async deleteArticle(channelId: string, boardId: string, articleId: string) {
        return oauthAxios.delete(
            `${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/boards/${boardId}/articles/${articleId}`,
        );
    }

    async findArticles(props: PaginateArticleProps) {
        const {channelId, boardId, startDate, endDate, offset, limit} = props
        const params: any = {offset, limit}
        if (startDate) {
            params.startDate = startDate;
        }
        if (endDate) {
            params.endDate = endDate;
        }
        return oauthAxios.get(
            `${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/boards/${boardId}/articles`,
            {params: params}
        );
    }

    async paginateComment(props: PaginateCommentProps) {
        const {channelId, boardId, articleId, offset, limit} = props
        return oauthAxios.get(
            `${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/boards/${boardId}/articles/${articleId}/comments`,
            {params: {offset, limit}}
        );
    }

    async comment(channelId: string, boardId: string, articleId: string, writer: string, password: string, content: string) {
        return oauthAxios.post(
            `${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/boards/${boardId}/articles/${articleId}/comments`,
            {writer, password, text: content}
        );
    }

    async deleteComment(channelId: string, boardId: string, articleId: string, commentId: string) {
        return oauthAxios.delete(
            `${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/boards/${boardId}/articles/${articleId}/comments/${commentId}`
        );
    }

    async findUnreadArticleIds(channelId: string, boardIds: string[]) {
        return oauthAxios.get(
            `${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/boards/unread-articles`,
            {params: {boardIds: boardIds.join(',')}}
        );
    }

    async readArticle(channelId: string, boardId: string, articleId: string) {
        return oauthAxios.post(
            `${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/boards/${boardId}/articles/${articleId}/read`,
        );
    }

}

export default new BoardRepository();
