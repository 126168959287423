import React, {PropsWithChildren, useRef} from "react";
import {ChatSearchFilterInputGroupStyle, ChatSearchFilterListStyle} from "./ChatSearchFilterStyle";
import {Dropdown, Form, InputGroup} from "react-bootstrap";
import _ from "lodash";
import {useIntl} from "react-intl";

type ChatSearchFilterDropdownMenuProps = PropsWithChildren<{
    showPopup: boolean
    setFilterSearch: (value: string) => void
    dropDownMenuRef: React.RefObject<HTMLDivElement>
}>

const ChatSearchFilterDropdownMenu: React.FC<ChatSearchFilterDropdownMenuProps> = (props) => {
    const { children, showPopup, setFilterSearch, dropDownMenuRef } = props;
    const intl = useIntl();

    const filterSearchRef = useRef<HTMLInputElement>(null);

    const onChangeFilterSearch = _.debounce(() => {
        if (filterSearchRef.current) {
            setFilterSearch(filterSearchRef.current.value)
        }
    }, 500);

    return (
        <Dropdown.Menu style={{boxShadow: "0 4px 4px #8C8C8C3F", borderRadius: "10px"}}>
            <div ref={dropDownMenuRef}>
                <ChatSearchFilterInputGroupStyle show={showPopup}>
                    <InputGroup className="filter-search-bar-input-group" size="sm">
                        <InputGroup.Text>
                            <i className="mdi mdi-magnify"/>
                        </InputGroup.Text>
                        <Form.Control ref={filterSearchRef}
                                      placeholder={intl.formatMessage({id: "i210015"})}
                                      onChange={onChangeFilterSearch}/>
                    </InputGroup>
                </ChatSearchFilterInputGroupStyle>
                <ChatSearchFilterListStyle>
                    {children}
                </ChatSearchFilterListStyle>
            </div>
        </Dropdown.Menu>
    )
};

export default ChatSearchFilterDropdownMenu;
