import React, {useMemo, useState} from "react";
import classNames from "classnames";
import Avatar from "../Avatar";
import moment from "moment";
import {useIntl} from "react-intl";
import {ChatbotMessage, ChatbotMessageInfo, MessageData} from "../../model/chatbot";
import TextMessage from "./TextMessage";
import {LiveChat} from "../../model/livechat";
import useModal from "../../hook/useModal";
import ListMessage from "./ListMessage";
import MediaMessage from "./MediaMessage";
import CarouselMessage from "./CarouselMessage";
import QuickReplyMessage from "./QuickReplyMessage";
import FileMessageBlock from "./FileMessageBlock";
import mime from "mime";
import {FileMessage, MessageUI} from "../../model/message";
import {messageTemplateFormModalId} from "../setting/MessageTemplateFormModal";
import MessageBlockStyle from "./MessageBlockStyle";
import {useInView} from "react-intersection-observer";
import FloatingToolbar from "../FloatingToolbar";
import {CenteredIcon} from "../UnstyledBootstrap";
import useTooltip from "../../hook/useTooltip";
import toast from "react-hot-toast";
import MessageSentAt from "./MessageSentAt";


const ChatbotMessageBlockGroup = React.memo<ChatbotMessageBlockGroupProps>(({messageInfo, liveChat, previousMessage}) => {
    const intl = useIntl();
    const modal = useModal();

    const [messageRef, messageInView] = useInView({threshold: 0});

    const messageTemplateTooltip = useTooltip(intl.formatMessage({id: 'i000224'}), {delayShow: 1000});

    const [isFocused, setIsFocused] = useState(false)

    const senderName = useMemo(() => {
        if (messageInfo.sender?.type === 'bot') {
            return 'Bot';
        }
        else if (messageInfo.sender?.type === 'user') {
            return liveChat.user.name || liveChat.user.alias;
        }
        else if (messageInfo.sender?.type === 'consultant') {
            return messageInfo.sender?.name || intl.formatMessage({id: 'i000247'});
        }

        return intl.formatMessage({id: "i200078"})
    }, [messageInfo, liveChat, intl]);

    const showSender = useMemo(() => {
        const previousDate = previousMessage?.date;
        const thisDate = moment.utc(messageInfo.created_at);
        const diff = previousDate ? Math.abs(moment.utc(previousDate).diff(moment.utc(thisDate), 'minutes')) : 1;
        const beforeSenderType = (previousMessage && previousMessage.type === 'chatbotMessage') && (previousMessage.data as ChatbotMessageInfo).sender?.type;

        return previousDate ? diff > 0 || beforeSenderType !== messageInfo.sender?.type : true;
    }, [messageInfo, previousMessage]);

    const onClickMessageTemplateBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        const messages = messageInfo.messages?.filter(message => (message.data_type === 'text' && message.data) || message.data_type === 'file');
        if (messages && messages.length > 0) {
            const messageBlocks = messages.map(message => {
                if (message.data_type === 'text') {
                    return {
                        type: 'text',
                        value: {
                            text: (message.data as MessageData[])[0].text,
                            html: (message.data as MessageData[])[0].text
                        }
                    }
                }
                // file message
                else {
                    const messageData = message.data as MessageData;
                    const fileMessage: FileMessage = {
                        filename: messageData.text || '',
                        url: messageData.file_url || '',
                        size: messageData.file_size
                    }
                    const contentType = messageData.text && mime.getType(messageData.text);
                    if (contentType) {
                        fileMessage.contentType = contentType
                    }

                    return {
                        type: 'file',
                        value: fileMessage
                    }
                }
            });

            modal.open(messageTemplateFormModalId, {blocks: messageBlocks})
        }
        else {
            toast(intl.formatMessage({id: 'i000249'}), {icon: <i className="mdi mdi-alert-outline text-warning font-18" />})
        }
    };

    return (
        <>
            <MessageBlockStyle id={`chatbot-mbg-${messageInfo.id}`}
                               className={classNames('chatbot', {
                                   'display-avatar': showSender,
                                   'user-message': messageInfo.sender?.type === 'user'
                               })}
                               data-floating-toolbar="true"
                               ref={messageRef}
                               onMouseEnter={() => setIsFocused(true)}
                               onMouseLeave={() => setIsFocused(false)}
            >

                {showSender && (
                    <div className="message-avatar">
                        <Avatar size={35} name={senderName} />
                    </div>
                )}
                <div className="message-wrapper">
                    {showSender && (
                        <p className="message-header">
                            {senderName}
                            {/*<small className="text-muted ml-1" data-for="global-tooltip" data-tip={moment.utc(messageInfo.created_at).local().format('LLL')}>*/}
                            <MessageSentAt at={messageInfo.created_at} expanded={isFocused}/>
                        </p>
                    )}

                    <div className="message-container">
                        <div className="d-flex flex-column overflow-hidden w-100">
                            {messageInfo.messages?.map((message, index) => (
                                <ChatbotMessageBlockFragment key={`chatbot_mb_${messageInfo.id}_${index}`} message={message} />
                            ))}
                        </div>
                    </div>
                </div>

                {messageInView && (
                    <FloatingToolbar>
                        <>
                            <CenteredIcon size={28} className="mdi mdi-clipboard-edit-outline font-20" role="button" onClick={onClickMessageTemplateBtn} ref={messageTemplateTooltip.setTriggerRef} />
                            {messageTemplateTooltip.visible && messageTemplateTooltip.element}
                        </>
                    </FloatingToolbar>
                )}

            </MessageBlockStyle>
        </>
    );
});


const ChatbotMessageBlockFragment: React.FC<{message: ChatbotMessage}> = ({message}) => {
    const component = useMemo(() => {
        if (message.data_type === 'text') {
            if (message.data) {
                if (Array.isArray(message.data)) {
                    return (message.data as MessageData[]).map((messageData, index) => (
                        <TextMessage key={`chatbot_mgb_data_${index}`} text={messageData.text} preview />
                    ));
                }
                else {
                    return <TextMessage text={(message.data as MessageData).text} preview />
                }
            }
            else {
                return null;
            }
        }
        else if (message.data_type === 'file') {
            const messageData = message.data as MessageData;
            const fileMessage: FileMessage = {
                filename: messageData.text || '',
                url: messageData.file_url || '',
                size: messageData.file_size
            }
            const contentType = messageData.text && mime.getType(messageData.text);
            if (contentType) {
                fileMessage.contentType = contentType
            }
            return <FileMessageBlock fileMessage={fileMessage}/>
        }
        else if (message.data_type === 'list') {
            return <ListMessage message={message} />
        }
        else if (message.data_type === 'media') {
            return <MediaMessage message={message as ChatbotMessage<MessageData>} />
        }
        else if (message.data_type === 'carousel') {
            return <CarouselMessage message={message as ChatbotMessage<MessageData[]>} />
        }
        else if (message.data_type === 'quick_reply') {
            return <QuickReplyMessage message={message} />
        }
        else if (message.data_type === 'custom') {
            // return (
            //     <div className="message-content">
            //         <p className="text-message">Custom UI</p>
            //     </div>
            // );

            return null;
        }
        else {
            return (
                <div className="message-content">
                    <p className="text-message bg-light">지원하지 않는 형식 {message.data_type}</p>
                </div>
            );
        }
    }, [message]);

    return (
        <>
            {component}
        </>
    );
};

type ChatbotMessageBlockGroupProps = {
    messageInfo: ChatbotMessageInfo;
    liveChat: LiveChat;
    previousMessage?: MessageUI
};


export default ChatbotMessageBlockGroup;