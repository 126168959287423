import React from "react";
import {Navigate, Route} from "react-router-dom";
import PrivateRoute from "../../component/PrivateRoute";
import {SentryRoutes} from "../../core/sentry";
import ManagerSettingView from "./ManagerSettingView";
import MessageTemplateManagementView from "./MessageTemplateManagementView";
import NotificationSettingView from "./NotificationSettingView";
import UserCategorySettingView from "./UserCategorySettingView";
import ChatbotMessageConfigView from "./ChatbotMessageConfigView";
import WorkingTimeConfigView from "./WorkingTimeConfigView";
import WorkingHolidayConfigView from "./WorkingHolidayConfigView";
import CategoryManagementView from "./CategoryManagementView";
import AssignRuleConfigView from "./AssignRuleConfigView";
import BannedWordConfigView from "./BannedWordConfigView";
import LiveChatAutoCloseConfigView from "./LiveChatAutoCloseConfigView";
import RoleView from "./RoleView";
import APIKeyManagementView from "./APIKeyManagementView";
import WebhookManagementView from "./WebhookManagementView";
import LiveChatPrioritySettingView from "./LiveChatPrioritySettingView";
import CustomPanelSettingView from "./CustomPanelSettingView";
import AnnouncementSettingView from "./AnnouncementSettingView";

const SettingView: React.FC = () => {

    // return (
    //     <Suspense>
    //         <SentryRoutes>
    //             <Route index element={<Navigate replace to="manager" />} />
    //             <Route path="manager" element={<PrivateRoute><views.managerSettingView /></PrivateRoute>} />
    //             <Route path="templates" element={<PrivateRoute permissions={['default:livechat']}><views.messageTemplateManagementView /></PrivateRoute>} />
    //             <Route path="notification" element={<PrivateRoute permissions={['default:livechat']}><views.notificationSettingView /></PrivateRoute>} />
    //             <Route path="user-categories" element={<PrivateRoute permissions={['default:livechat']}><views.userCategorySettingView /></PrivateRoute>} />
    //             <Route path="messages" element={<PrivateRoute permissions={['setting:livechat']}><views.chatbotMessageConfigView /></PrivateRoute>} />
    //             <Route path="working-times" element={<PrivateRoute permissions={['setting:livechat']}><views.workingTimeConfigView /></PrivateRoute>} />
    //             <Route path="categories" element={<PrivateRoute permissions={['setting:livechat']}><views.categoryManagementView /></PrivateRoute>} />
    //             <Route path="assign-rule" element={<PrivateRoute permissions={['setting:livechat']}><views.assignRuleConfigView /></PrivateRoute>} />
    //             <Route path="banned-words" element={<PrivateRoute permissions={['setting:livechat']}><views.bannedWordConfigView /></PrivateRoute>} />
    //             <Route path="auto-close" element={<PrivateRoute permissions={['setting:livechat']}><views.liveChatAutoCloseConfigView /></PrivateRoute>} />
    //             <Route path="roles" element={<PrivateRoute permissions={['setting:livechat']}><views.roleView /></PrivateRoute>} />
    //             <Route path="api-keys" element={<PrivateRoute permissions={['setting:livechat']}><views.apiKeyManagementView /></PrivateRoute>} />
    //             <Route path="webhooks/*" element={<PrivateRoute permissions={['setting:livechat']}><views.webhookManagementView /></PrivateRoute>} />
    //         </SentryRoutes>
    //     </Suspense>
    // );

    return (
        <SentryRoutes>
            <Route index element={<Navigate replace to="manager" />} />
            <Route path="manager" element={<PrivateRoute><ManagerSettingView /></PrivateRoute>} />
            <Route path="templates" element={<PrivateRoute permissions={['default:livechat']}><MessageTemplateManagementView /></PrivateRoute>} />
            <Route path="notification" element={<PrivateRoute permissions={['default:livechat']}><NotificationSettingView /></PrivateRoute>} />
            <Route path="user-categories" element={<PrivateRoute permissions={['default:livechat']}><UserCategorySettingView /></PrivateRoute>} />
            <Route path="announcements" element={<PrivateRoute permissions={['setting:livechat']}><AnnouncementSettingView/></PrivateRoute> }/>
            <Route path="messages" element={<PrivateRoute permissions={['setting:livechat']}><ChatbotMessageConfigView /></PrivateRoute>} />
            <Route path="working-times" element={<PrivateRoute permissions={['setting:livechat']}><WorkingTimeConfigView /></PrivateRoute>} />
            <Route path="categories" element={<PrivateRoute permissions={['setting:livechat']}><CategoryManagementView /></PrivateRoute>} />
            <Route path="assign-rule" element={<PrivateRoute permissions={['setting:livechat']}><AssignRuleConfigView /></PrivateRoute>} />
            <Route path="banned-words" element={<PrivateRoute permissions={['setting:livechat']}><BannedWordConfigView /></PrivateRoute>} />
            <Route path="auto-close" element={<PrivateRoute permissions={['setting:livechat']}><LiveChatAutoCloseConfigView /></PrivateRoute>} />
            <Route path="roles" element={<PrivateRoute permissions={['setting:livechat']}><RoleView /></PrivateRoute>} />
            <Route path="working-holidays" element={<PrivateRoute permissions={['setting:livechat']}><WorkingHolidayConfigView /></PrivateRoute>} />
            <Route path="api-keys" element={<PrivateRoute permissions={['setting:livechat']}><APIKeyManagementView /></PrivateRoute>} />
            <Route path="webhooks/*" element={<PrivateRoute permissions={['setting:livechat']}><WebhookManagementView /></PrivateRoute>} />
            <Route path="priorities" element={<PrivateRoute permissions={['setting:livechat']}><LiveChatPrioritySettingView /></PrivateRoute>} />
            <Route path="custom-panels" element={<PrivateRoute permissions={['setting:livechat']}><CustomPanelSettingView /></PrivateRoute>} />
        </SentryRoutes>
    );
};


export default SettingView;