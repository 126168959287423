import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import {LIVE_CHAT_API_HOST} from "../../core/variables";
import {LiveChatError, toLiveChatError} from "../../error";
import oauthAxios from "../../core/oauthAxios";


const useDeleteInvitation = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, LiveChatError, string>(['invitations', 'delete', channelId], async (invitationId) => {
        try {
            return await oauthAxios.delete(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/invitations/${invitationId}`);
        } catch (e) {
            throw toLiveChatError(e);
        }
    }, {
        onSuccess: () => {
            return queryClient.invalidateQueries(['invitations', channelId], {exact: true})
        }
    });
}

export default useDeleteInvitation;
