import oauthAxios from "../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../core/variables";
import {ChatUserForm} from "../model/chatUser";


class ChatUserRepository {

    async find(channelId: string, name?: string, offset?: number, limit?: number) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/chat-users`, {
            params: { name, offset, limit }
        });
    }

    async read(channelId: string, chatUserId: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/chat-users/${chatUserId}`);
    }

    async patch(channelId: string, chatUserId: string, chatUser: ChatUserForm) {
        return oauthAxios.patch(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/chat-users/${chatUserId}`, chatUser);
    }

    async readAttributes(channelId: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/chat-user-attributes`);
    }
}

export default new ChatUserRepository();