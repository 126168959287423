import {Config, PopperOptions, usePopperTooltip} from "react-popper-tooltip";
import React, {useMemo} from "react";
import Tooltip from "../component/Tooltip";


const useTooltip = (message: string, config?: Config, options?: PopperOptions) => {

    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible
    } = usePopperTooltip({...config}, {...options, strategy: 'fixed'});

    const element = useMemo(() => {
        return (
            <Tooltip message={message} visible={visible} setTooltipRef={setTooltipRef} getTooltipProps={getTooltipProps} getArrowProps={getArrowProps} />
        );
    }, [getTooltipProps, getArrowProps, visible, message, setTooltipRef]);

    return useMemo(() => ({
        visible,
        setTriggerRef,
        element,
    }), [visible, setTriggerRef, element]);
};


export default useTooltip;