import React, {useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {Table} from "react-bootstrap";
import styled from "styled-components";
import IconButton from "../../component/StyledButton";
import {useParams} from "react-router-dom";
import {Typeahead} from "react-bootstrap-typeahead";
import {Category} from "../../model/category";
import SettingViewWrapper from "../../component/setting/SettingViewWrapper";
import useCategories from "../../query/category/useCategories";
import useUserCategories from "../../query/category/useUserCategories";
import useCreateUserCategory from "../../query/category/useCreateUserCategory";
import useDeleteUserCategory from "../../query/category/useDeleteUserCategory";
import "react-bootstrap-typeahead/css/Typeahead.bs5.min.css";
import toast from "react-hot-toast";

const UserCategorySettingView: React.FC = () => {
    const intl = useIntl();

    const {channelId} = useParams<{channelId: string}>();

    if (!channelId) {
        throw new Error('Required channelId path params')
    }

    const [openCreateRow, setOpenCreateRow] = useState(false);

    const {data: categories} = useCategories(channelId, {suspense: false, retry: true});
    const {data: userCategories, status: userCategoriesStatus} = useUserCategories(channelId);

    const isEmptyCategories = useMemo(() => {
        return userCategoriesStatus === 'success' && (!userCategories || userCategories.length < 1);
    }, [userCategories, userCategoriesStatus]);

    const onClickOpenCreateRowBtn = (e: React.MouseEvent) => {
        e.preventDefault();
        setOpenCreateRow(true);
    }

    const onCreated = () => {
        setOpenCreateRow(false);
    };

    const onCanceled = () => {
        setOpenCreateRow(false);
    };

    return (
        <SettingViewWrapper>
            <div className="setting-title">
                <h3>{intl.formatMessage({id: 'i000093'})}</h3>
                <p>
                    {intl.formatMessage({id: 'i000094'})}
                </p>
            </div>
            <MyCategoriesStyle size="sm" className="table-centered">
                <colgroup>
                    <col width="*" />
                    <col width="80px" />
                </colgroup>
                <thead>
                <tr>
                    <th>내 카테고리</th>
                    <th className="text-end">
                        <IconButton onClick={onClickOpenCreateRowBtn}><i className="mdi mdi-plus-box-outline" /></IconButton>
                    </th>
                </tr>
                </thead>
                <tbody>
                {/*{isLoading && <tr><td colSpan={2}><Spinner size="sm" animation="border" /></td></tr>}*/}
                {openCreateRow && <CreateCategoryRow channelId={channelId}
                                                     categories={categories ?? []}
                                                     userCategories={userCategories ?? []}
                                                     onCreated={onCreated}
                                                     onCanceled={onCanceled} />}
                {userCategoriesStatus === 'success' && userCategories?.map(category => (
                    <CategoryRowFragment key={category._id} channelId={channelId} category={category} />
                ))}
                {isEmptyCategories && <tr><td colSpan={2} className="text-muted">{intl.formatMessage({id: 'i000098'})}</td></tr>}
                </tbody>
            </MyCategoriesStyle>
        </SettingViewWrapper>
    );
};

const CategoryRowFragment: React.FC<CategoryRowFragmentProps> = ({channelId, category}) => {
    const intl = useIntl();

    const {mutate: deleteUserCategory} = useDeleteUserCategory(channelId);

    const onClickDeleteBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        deleteUserCategory(category._id, {
            onError: (_) => {
                toast.error(intl.formatMessage({id: 'i000099'}));
            }
        })
    };

    return (
        <tr>
            <td>{category.name}</td>
            <td className="text-end">
                <IconButton onClick={onClickDeleteBtn}><i className="mdi mdi-trash-can-outline" /></IconButton>
            </td>
        </tr>
    );
};

const CreateCategoryRow: React.FC<CategoryCreateRowProps> = ({channelId, categories, userCategories, onCreated, onCanceled}) => {
    const intl = useIntl();

    const [categoryId, setCategoryId] = useState<string|undefined>(undefined);
    const [invalid, setInvalid] = useState({category: false});

    const {mutate: createCategory} = useCreateUserCategory(channelId);

    const options = useMemo(() => {
        return categories.filter(category => !userCategories.some(userCategory => userCategory._id === category._id))
            .map(category => ({id: category._id, label: category.name}));
    }, [categories, userCategories]);

    const onChangeCategory = (selected: any[]) => {
        if (selected.length > 0) {
            setCategoryId(selected[0].id);
        }
        else {
            setCategoryId(undefined);
        }
    }

    const onKeyDownCategory = (e: any) => {
        if (e.key.toLowerCase() === 'enter') {
            if (!categoryId) {
                setInvalid({category: true});
                return;
            }
            else {
                setInvalid({category: false});
            }

            createCategory(categoryId, {
                onSuccess: (_) => {
                    onCreated();
                },
                onError: (_) => {
                    toast.error(intl.formatMessage({id: 'i000100'}));
                }
            })
        }
    };

    const onClickCancelBtn = (e: React.MouseEvent) => {
        e.preventDefault();
        onCanceled();
    }

    return (
        <tr>
            <td>
                <Typeahead id="category-typeahead"
                           options={options}
                           multiple={false}
                           size="sm"
                           onChange={onChangeCategory}
                           onKeyDown={onKeyDownCategory}
                           clearButton={true}
                           isInvalid={invalid.category} />
            </td>
            <td className="text-end">
                {/*<IconButton onClick={onClickAddBtn}><i className="mdi mdi-content-save" /></IconButton>*/}
                <IconButton onClick={onClickCancelBtn}><i className="mdi mdi-close" /></IconButton>
            </td>
        </tr>
    )
};

type CategoryRowFragmentProps = {
    channelId: string;
    category: Category;
};

type CategoryCreateRowProps = {
    channelId: string;
    categories: Category[];
    userCategories: Category[];
    onCreated: () => void;
    onCanceled: () => void;
};

const MyCategoriesStyle = styled(Table)`
    min-width: 450px;
    max-width: 100%;
    width: unset;
`;


export default UserCategorySettingView;
