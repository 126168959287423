import React, {useRef, useState} from "react";
import {useIntl} from "react-intl";
import {Button, Form, Modal, Table} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {ChannelApiKey} from "../../model/channel";
import styled from "styled-components";
import {utcToLocal} from "../../util/datetime";
import useModal, {MaruModal} from "../../hook/useModal";
import {useDialog} from "../../hook/useDialog";
import SettingViewWrapper from "../../component/setting/SettingViewWrapper";
import {CenteredIcon} from "../../component/UnstyledBootstrap";
import useApiKeys from "../../query/apiKey/useApiKeys";
import useDeleteApiKey from "../../query/apiKey/useDeleteApiKey";
import useCreateApiKey from "../../query/apiKey/useCreateApiKey";
import toast from "react-hot-toast";

const APIKeyManagementView: React.FC = () => {
    const intl = useIntl();
    const modal = useModal();

    const {channelId} = useParams<{channelId: string}>();

    if (!channelId) {
        throw new Error('Required channelId path params')
    }

    const {data: apiKeys} = useApiKeys(channelId, {suspense: false});

    const onClickAddApiKey = (e: React.MouseEvent) => {
        e.preventDefault();
        modal.open('api-key-registration-modal');
    };

    return (
        <SettingViewWrapper>
            <div className="setting-title">
                <h3>{intl.formatMessage({id: 'i000191'})}</h3>
                <p>
                    {intl.formatMessage({id: 'i000193'})}
                </p>
                <Button variant="info" type="button" className="btn-rounded" onClick={onClickAddApiKey}>
                    {intl.formatMessage({id: 'i000196'})}
                </Button>
            </div>

            <Table size="sm" className="table-centered" responsive={false}>
                <colgroup>
                    <col width="*" />
                    <col width="250px" />
                    <col width="300px" />
                    <col width="100px" />
                    <col width="50px" />
                </colgroup>
                <thead>
                <tr>
                    <th>{intl.formatMessage({id: 'i000087'})}</th>
                    <th>Access Key</th>
                    <th>Access Secret</th>
                    <th>{intl.formatMessage({id: 'i000133'})}</th>
                    <th>{''}</th>
                </tr>
                </thead>
                <tbody>
                {apiKeys?.map(apiKey => (
                    <ApiKeyRowFragment key={apiKey._id} apiKey={apiKey} />
                ))}
                {(apiKeys?.length ?? 0) < 1 && (
                    <tr><th colSpan={5} className="text-muted">{intl.formatMessage({id: 'i000195'})}</th></tr>
                )}
                </tbody>
            </Table>

            <ApiKeyRegistrationModal channelId={channelId} />
        </SettingViewWrapper>
    )
};

const ApiKeyRowFragment: React.FC<{apiKey: ChannelApiKey}> = ({apiKey}) => {
    const intl = useIntl();
    const dialog = useDialog();

    const {mutate: deleteApiKey} = useDeleteApiKey(apiKey.channelId);

    const onClickDeleteBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        dialog.open({
            title: intl.formatMessage({id: 'i000034'}),
            content: intl.formatMessage({id: 'i000198'}),
            variant: 'danger',
            onConfirm: async () => {
                deleteApiKey(apiKey._id, {
                    onError: (_) => {
                        toast.error(intl.formatMessage({id: 'i000199'}));
                    }
                });
            }
        });
    };

    return (
        <ApiKeyRowStyle>
            <td>
                <span className="name text-truncate" title={apiKey.name}>{apiKey.name}</span>
            </td>
            <td className="text-key">{apiKey.accessKey}</td>
            <td className="text-key">{apiKey.accessSecret}</td>
            <td>{utcToLocal(apiKey.createdAt)}</td>
            <td>
                <CenteredIcon size={28} className="mdi mdi-trash-can-outline font-20 me-1" onClick={onClickDeleteBtn} role="button" />
            </td>
        </ApiKeyRowStyle>
    );
};

const ApiKeyRegistrationModal: React.FC<{channelId: string}> = ({channelId}) => {
    const intl = useIntl();
    const modal = useModal();

    const {mutate: createApiKey} = useCreateApiKey(channelId);

    const [invalid, setInvalid] = useState({name: false});

    const nameRef = useRef<HTMLInputElement>(null);

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const name = nameRef.current?.value;
        const invalid = {name: !name};
        if (Object.values(invalid).some(value => value)) {
            setInvalid(invalid);
            return;
        }
        setInvalid({name: false});

        createApiKey(name!, {
            onSuccess: () => {
                onClose();
                modal.close('api-key-registration-modal');
            },
            onError: (_) => {
                toast.error(intl.formatMessage({id: 'i000197'}));
            }
        })
    }

    const onClose = () => {
        setInvalid({name: false});
    };

    const onClickCancelBtn = (e: React.MouseEvent) => {
        e.preventDefault();
        onClose();
        modal.close('api-key-registration-modal');
    }

    return (
        <MaruModal id={'api-key-registration-modal'} title={intl.formatMessage({id: 'i000196'})} onClose={onClose}>
            <Modal.Body>
                <Form id={'api-key-registration-form'} onSubmit={onSubmit}>
                    <Form.Group className="mb-1">
                        <Form.Label>{intl.formatMessage({id: 'i000087'})}</Form.Label>
                        <Form.Control type={'text'} placeholder={intl.formatMessage({id: 'i000200'})} isInvalid={invalid.name} ref={nameRef} />
                        <Form.Control.Feedback type={'invalid'}>{intl.formatMessage({id: 'i000024'})}</Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" className="btn-rounded" variant="outline-secondary" onClick={onClickCancelBtn}>{intl.formatMessage({id: 'i000022'})}</Button>
                <Button type="submit" className="btn-rounded" form={'api-key-registration-form'}>{intl.formatMessage({id: 'i000023'})}</Button>
            </Modal.Footer>
        </MaruModal>
    )
};


const ApiKeyRowStyle = styled.tr`
  td {
    span.name {
      width: 150px !important;
      display: block;
    }
  }
  td.text-key {
    font-family: "Roboto Mono", monospace !important;
  }
`

export default APIKeyManagementView;
