import React, {useCallback} from "react";
import electronRuntime from "../../core/electronRuntime";
import {
    ComponentItem,
    ComponentType,
    CustomSnippetObject,
    DefaultDividerStyle,
    DefaultImageStyle,
    DefaultKeyValueStyle,
    DefaultLinkStyle,
    DefaultTextStyle,
    DividerComponentData,
    ImageComponentData,
    KeyValueComponentData,
    LinkComponentData,
    TextComponentData
} from "../../model/customSnippet";

const NO_IMAGE_URL = "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg";

interface CustomSnippetRendererProps {
    snippetObject: CustomSnippetObject;
    postUrl?: string;
}

const CustomSnippetRenderer: React.FC<CustomSnippetRendererProps> = (props) => {
    const { snippetObject } = props;

    const onClickLink = useCallback((url?: string) => {
        if (!url) {
            return
        }

        if (electronRuntime) {
            electronRuntime.send('open-default-browser', url);
        } else {
            window.open(url, '_blank')
        }
    }, [])

    const parseSnippetObject = (data?: ComponentItem[]) => {
        try {
            return data?.map((item: ComponentItem, index) => {
                switch (item.type) {
                    case ComponentType.TEXT:
                        const textData = item.data as TextComponentData;
                        return (
                            <DefaultTextStyle key={index}
                                 style={textData?.style}>
                                {textData?.text}
                            </DefaultTextStyle>
                        )
                    case ComponentType.LINK:
                        const linkData = item.data as LinkComponentData;
                        return (
                            <DefaultLinkStyle key={index}
                                              style={linkData?.style}
                                              onClick={() => onClickLink(linkData?.url)}>
                                {linkData?.text}
                            </DefaultLinkStyle>
                        )
                    case ComponentType.DIVIDER:
                        const dividerData = item.data as DividerComponentData;
                        return (
                            <DefaultDividerStyle key={index} style={dividerData?.style}/>
                        )
                    case ComponentType.IMAGE:
                        const imageData = item.data as ImageComponentData;
                        const url = imageData?.url ?? NO_IMAGE_URL;
                        return (
                            <DefaultImageStyle key={index}
                                               src={url} alt=""
                                               style={imageData?.style}
                                               onClick={() => onClickLink(imageData?.clickUrl)}/>
                        )
                    case ComponentType.LIST:
                        const listData = item.data as ComponentItem[];
                        return (
                            <div key={index}
                                 style={item?.style}>
                                {parseSnippetObject(listData)}
                            </div>
                        )
                    case ComponentType.KEY_VALUE:
                        const keyValueData = item.data as KeyValueComponentData;
                        return (
                            <div key={index}>
                                {keyValueData?.items && keyValueData.items.map((item, index) => (
                                    <DefaultKeyValueStyle key={index}>
                                        <div className="key" style={keyValueData.itemStyle?.key}>{item.key}</div>
                                        <div className="value" style={keyValueData.itemStyle?.value}>{item.value}</div>
                                    </DefaultKeyValueStyle>
                                ))}
                            </div>
                        )
                    // case ComponentType.INPUT:
                    //     const InputData = item.data as InputComponentData;
                    //
                    //     // create event handler per input component
                    //
                    //     const onClick = (e: React.MouseEvent) => {
                    //         // create an object from input values wrt keys
                    //         // include object in req body and req POST method
                    //     }
                    //
                    //     // construct input form & btn to submit data
                    //
                    //     return undefined;
                    default:
                        return undefined;
                }
            })
        } catch (e) {
            return undefined;
        }
    }

    return (
        <div style={{maxWidth: "100%", ...(snippetObject?.style ?? {})}}>
            {parseSnippetObject(snippetObject.data)}
        </div>
    )
};

export default CustomSnippetRenderer;
