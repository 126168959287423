import chatUserRepository from "../../repository/ChatUserRepository";
import {LiveChatError, toLiveChatError} from "../../error";
import {useInfiniteQuery} from "@tanstack/react-query";
import {ChatUser} from "../../model/chatUser";
import {Page} from "../index";

const getData = async (channelId: string, pageable: {offset: number, limit: number, name?: string}) => {
    try {
        const { data } = await chatUserRepository.find(channelId, pageable.name, pageable.offset, pageable.limit);
        return {
            result: data.result,
            pageable: {
                ...pageable,
                isLast: data.result.length < pageable.limit
            }
        }
    } catch (e) {
        throw toLiveChatError(e, {noErrorPage: true})
    }
};

const useChatUsers = (channelId: string, name?: string) => {
    return useInfiniteQuery<Page<ChatUser, {name?: string, offset: number}>, LiveChatError>({
        queryKey: ['chatUsers', channelId, name],
        queryFn: ({
            pageParam = {
                name: name?.length ? name : undefined,
                offset: 0,
                limit: 5,
                isLast: false
            }
        }) => getData(channelId, pageParam),
        refetchOnMount: true,
        getNextPageParam: ({ pageable, result }) => (
            pageable.isLast ?
                undefined :
                {
                    ...pageable,
                    offset: pageable.offset + result.length,
                    limit: pageable.limit,
                    isLast: false
                }
        )
    })
}

export default useChatUsers;
