import {useQuery} from "@tanstack/react-query";
import {Manager} from "../../model/manager";
import {LiveChatError, toLiveChatError} from "../../error";
import oauthAxios from "../../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../../core/variables";


const getData = async (channelId: string) => {
    try {
        const {data} = await oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/managers/me`)
        return data.result;
    } catch (e) {
        throw toLiveChatError(e);
    }
}

const useManager = (channelId: string) => {
    return useQuery<Manager, LiveChatError>({
        queryKey: ['manager', channelId],
        queryFn: () => getData(channelId),
        useErrorBoundary: false
    });
};


export default useManager;