import {atom} from "recoil";
import {DashboardChart} from "../../model/statistic";
import {LIVE_CHAT_DASHBOARD_CHARTS_STORAGE_KEY} from "../../core/variables";

const DASHBOARD_DEFAULT_CHARTS: DashboardChart[] = [
    { id: "accept-chart", i18n: "i200018", show: true},
    { id: "response-chart", i18n: "i200019", show: true},
    { id: "duration-chart", i18n: "i200033", show: true},
    // { id: "user-talk-chart", i18n: "i200061", show: true},
    // { id: "first-respond-chart", i18n: "i200031", show: true},
    // { id: "duration-chart", i18n: "i200033", show: true},
    // { id: "bounce-rate-chart", i18n: "i200035", show: true},
]

const dashboardChartsState = atom({
    key: 'liveChatDashboardChartsState',
    default: (() => {
       const dashboardChartRaw = window.localStorage.getItem(LIVE_CHAT_DASHBOARD_CHARTS_STORAGE_KEY);
       return dashboardChartRaw ? JSON.parse(dashboardChartRaw) as DashboardChart[] : DASHBOARD_DEFAULT_CHARTS;
    })()
});

export default dashboardChartsState;
