import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {LiveChat, LiveChatHistory} from "../../model/livechat";
import {useIntl} from "react-intl";
import {useQueryClient} from "@tanstack/react-query";
import useManager from "../../query/manager/useManager";
import {Message} from "../../model/message";
import {scroller} from "react-scroll";
import MessageBlockGroup from "../message/MessageBlockGroup";
import {Button} from "react-bootstrap";
import MessageTemplateFormModal from "../setting/MessageTemplateFormModal";
import styled from "styled-components";
import ChatbotMessageBlockGroup from "../message/ChatbotMessageBlockGroup";
import {SocketEvent, useSocket, useSocketSubscribe, useThrottledSocketSubscribe} from "../../socket";
import {useRecoilState, useRecoilValue} from "recoil";
import outgoingMessagesState from "../../recoil/outgoingMessages";
import SimpleBar from "simplebar-react";
import useMessageHandler from "../../hook/useMessageHandler";
import {ChatbotMessageInfo} from "../../model/chatbot";
import ManagerHistoryMessage from "../message/ManagerHistoryMessage";
import DatelineMessage from "../message/DatelineMessage";
import LastConfirmedHelpMessage from "../message/LastConfirmedHelpMessage";
import useUnreadMessages from "../../query/message/useUnreadMessages";
import {useDebouncedCallback, useDebouncedEffect, useRafCallback, useRafEffect} from "@react-hookz/web";
import FullAreaSpinner from "../FullAreaSpinner";
import moment from "moment";
import chatSearchTrackingState from "../../recoil/chatSearchTracking";
import {genMessageBlockGroupId} from "../../util/idGenUtil";
import MoreTrigger from "../MoreTrigger";
import _ from "lodash";
import useSocketIOSubscribe from "../../socket/useSocketIOSubscribe";
import useMessages from "../../query/message/useMessages";

const SCROLL_OFFSETS: {[key: string]: number|undefined} = {};


const MessageList: React.FC<{liveChat: LiveChat}> = ({liveChat}) => {
    const intl = useIntl();
    const queryClient = useQueryClient();

    const chatSearchTracking = useRecoilValue(chatSearchTrackingState);

    const socket = useSocket();

    // queries
    const {data: manager} = useManager(liveChat.channelId);
    const {data: unreadMessages} = useUnreadMessages(liveChat.channelId);

    // local state
    const [invisibleNewMessage, setInvisibleNewMessage] = useState(false);
    // const [newMessage, setNewMessage] = useState(false);
    const [checkedNewMessage, setCheckedNewMessage] = useState(false);
    const [opacity, setOpacity] = useState(0);
    const [avoidInitialTrigger, setAvoidInitialTrigger] = useState(true);

    const [outgoingMessages, setOutgoingMessages] = useRecoilState(outgoingMessagesState);

    /* In chat search context, base date for search must be tracking message creation date,
     * except that a new message is sent by manager or chat user.
     *
     * Otherwise, including the case of new message checked in search context,
     * base date will be the date of current moment, which loads the latest messages.
     * */
    const baseDate = useMemo(() => {
        if (chatSearchTracking.isTracking && !checkedNewMessage) {
            return chatSearchTracking.trackingMessage.createdAt
        } else {
            return moment.utc().toISOString()
        }
    }, [chatSearchTracking, checkedNewMessage])

    const messagesQuery = useMessages(liveChat.channelId, liveChat._id, baseDate)
    const messageHandler = useMessageHandler(liveChat, baseDate);
    // refs
    const messageListRef = useRef<HTMLDivElement>(null);

    const visiblePreviousTrigger = useMemo(() => {
        return !avoidInitialTrigger && messageHandler.initialized && messageHandler.hasPreviousPage && !messageHandler.isFetchingPreviousPage;
    }, [avoidInitialTrigger, messageHandler.initialized, messageHandler.hasPreviousPage, messageHandler.isFetchingPreviousPage]);

    const visibleNextTrigger = useMemo(() => {
        return !avoidInitialTrigger && messageHandler.initialized && messageHandler.hasNextPage && !messageHandler.isFetchingNextPage;
    }, [avoidInitialTrigger, messageHandler.initialized, messageHandler.hasNextPage, messageHandler.isFetchingNextPage]);

    // Handle rejected message
    useSocketSubscribe<{liveChatId: string, messageId: string, bannedWords: string[]}>(SocketEvent.REJECTED_LIVECHAT_MESSAGE, (data) => {
        if (liveChat._id !== data.liveChatId) return

        const filteredMessageUi = messageHandler.messages.filter((messageUi) => {
            if (messageUi.type === 'message') {
                const message = messageUi.data as Message
                return message._id === data.messageId
            }
            return false
        })

        if (filteredMessageUi.length < 1) return

        const applicableMessage = {...filteredMessageUi[0].data as Message}
        applicableMessage.rejectedBannedWords = data.bannedWords
        applicableMessage.shouldAck = undefined
        messageHandler.addMessage(applicableMessage)
    });

    useThrottledSocketSubscribe(SocketEvent.MESSAGE, async (messages: Message[]) => {
        const currentLiveChatMessages = messages.filter(message => message.liveChatId === liveChat._id);
        if (!currentLiveChatMessages.length) {
            return;
        }

        // Add new message to current query data
        // only if it can be contained in current page of query data.
        // For example, if there is no more next page of messages,
        // the new message can be added to current page since it is the latest one.
        // Otherwise, there exist some messages not loaded yet between current page
        // and new message, thus it must be avoided to add a new message to current page.
        if (!messageHandler.hasNextPage && !messageHandler.isFetching) {
            messageHandler.addMessages(currentLiveChatMessages);
        }

        // if (currentLiveChatMessages.at(-1)?.sender.userId !== manager?.userId && messageListRef.current) {
        if (messageListRef.current) {
            const currentScrollHeight = messageListRef.current.scrollHeight;
            const currentScrollTop = messageListRef.current.scrollTop;
            const offsetHeight = messageListRef.current.offsetHeight;

            const isOtherSender = currentLiveChatMessages.at(-1)?.sender.userId !== manager?.userId;

            if (isOtherSender && currentScrollHeight - offsetHeight - 100 > currentScrollTop) {
                setInvisibleNewMessage(true);
            }
            else {
                moveBottom();
                setInvisibleNewMessage(false);
            }
        } else {
            messageHandler.setLastConfirmedMessageId(undefined);
        }
    }, 1000);

    const [moveBottom] = useRafCallback(() => {
        if (messageHandler.initialized) {
            scroller.scrollTo('message-bottom', {
                duration: 0,
                delay: 0,
                containerId: 'message-list',
                smooth: 'easeInOutQuint'
            });
        }
    });

    const [moveScroll] = useRafCallback((offset: number) => {
        if (messageListRef.current) {
            messageListRef.current.scrollTop = offset;
        }

        // avoid adjusting scroll after checking new message
        if (checkedNewMessage) {
            moveBottom();
        }
    });

    const liveChatUnreadMessages = useMemo(() => {
        return unreadMessages ? unreadMessages[liveChat._id] ?? [] : []
    }, [unreadMessages, liveChat]);

    const saveScrollOffset = useDebouncedCallback(() => {
        if (messageListRef.current) {
            if (messageListRef.current.scrollHeight < (messageListRef.current.scrollTop + messageListRef.current.offsetHeight + 40)) {
                delete SCROLL_OFFSETS[liveChat._id]
            }
            else {
                SCROLL_OFFSETS[liveChat._id] = messageListRef.current.scrollTop;
            }
        }
    }, [liveChat], 100);

    const removeInvisibleNewMessage = useDebouncedCallback(() => {
        setInvisibleNewMessage(false);
    }, [], 200);

    const onClickNewMessageAlert = async (e: React.MouseEvent) => {
        e.preventDefault();

        // if current query page data is not latest, trigger base date change
        // by modifying checkNewMessage state, which loads the latest messages
        // including new message.
        if (messageHandler.hasNextPage) {
            setCheckedNewMessage(true)
        } else {
            scroller.scrollTo('message-bottom', {
                duration: 10,
                delay: 0,
                containerId: 'message-list',
                smooth: 'easeInOutQuart',
            });
        }
    };

    const handleMessageListScroll = useCallback((_: React.UIEvent<HTMLDivElement>) => {
        if (!messageListRef.current) {
            return;
        }

        if (messageListRef.current.scrollTop === 0 && messageHandler.hasPreviousPage) {
            // Scroll infinite scroll trick when to up
            // messageListRef.current.scrollTop = messageListRef.current.scrollHeight * 0.41;
            messageListRef.current.scrollTop = 1;
        }

        const currentScrollHeight = messageListRef.current.scrollHeight;
        const currentScrollTop = messageListRef.current.scrollTop;
        const offsetHeight = messageListRef.current.offsetHeight;

        if (currentScrollHeight - offsetHeight - 40 < currentScrollTop) {
            removeInvisibleNewMessage();
        }

        saveScrollOffset();
    }, [saveScrollOffset, messageHandler.hasPreviousPage, removeInvisibleNewMessage]);

    const onEnterMorePreviousTrigger = useCallback(async () => {
        if (messageHandler.hasPreviousPage) {
            await messageHandler.fetchPreviousPage();
        }
    }, [messageHandler])

    const onEnterMoreNextTrigger = useCallback(async () => {
        if (messageHandler.hasNextPage) {
            await messageHandler.fetchNextPage();
        }
    }, [messageHandler])

    // move to scroll when changed live chat
    useLayoutEffect(() => {
        if (messageHandler.initialized) {
            const offset = SCROLL_OFFSETS[liveChat._id];

            if (offset !== undefined) {
                moveScroll(offset);
            } else {
                moveBottom();
                // setTimeout(() => {
                // }, 500);
            }
        }
    }, [liveChat._id, moveBottom, moveScroll, messageHandler.initialized]);

    useEffect(() => {
        const displayUnreadMessages = liveChatUnreadMessages.filter(unreadMessage => !unreadMessage.noBadge);

        if (displayUnreadMessages.length > 5) {
            // 5개 이상 메시지를 안 읽었을때 안 읽은 메시지 정보 표시
            messageHandler.setLastConfirmedMessageId(liveChatUnreadMessages[0]._id);
        }
        else {
            messageHandler.setLastConfirmedMessageId(undefined);
        }

    }, [messageHandler, liveChatUnreadMessages]);

    // useEffect(() => {
    //     if (newMessage) {
    //         setNewMessage(false);
    //         moveBottom();
    //     }
    // }, [newMessage, moveBottom]);

    // handle outgoing messages
    useEffect(() => {
        if (outgoingMessages.length < 1) {
            return;
        }
        outgoingMessages.forEach(message => {
            if (liveChat._id !== message.liveChatId) {
                return;
            }

            // setNewMessage(true);
            moveBottom();
            messageHandler.setLastConfirmedMessageId(undefined);

            // consider outgoing message creation as same as new message checking
            // because it also triggers scroll to be bottom.
            // If the current page is latest, manually modify query data, not refetching.
            if (messageHandler.hasNextPage) {
                setCheckedNewMessage(true);
            } else {
                queryClient.setQueryData(['messages', message.channelId, message.liveChatId, baseDate], ((oldData: any) => {
                    if (oldData?.pages && oldData.pages.length > 0) {
                        const aheadPages = oldData.pages.slice(0, oldData.pages.length - 1);
                        const lastPage = oldData.pages.at(-1);
                        return {
                            pages: [...aheadPages, {...lastPage, result: [...lastPage.result, message]}],
                            pageParams: oldData.pageParams
                        }
                    }
                    else {
                        return {
                            pages: [{result: [message], pageable: {isFirst: false, limit: 50}}],
                            pageParams: oldData.pageParams
                        }
                    }
                }));
            }
        })
        setOutgoingMessages([]);
    }, [outgoingMessages, liveChat, queryClient, setOutgoingMessages, messageHandler, baseDate, moveBottom]);

    useRafEffect(() => {
        if (!messageHandler.initialized) {
            setOpacity(0);
        }
        else {
            setOpacity(100);
        }
    }, [messageHandler.initialized, setOpacity]);

    // Scroll to tracked message when its live chat is opened
    useDebouncedEffect(() => {
        if (messageHandler.initialized && avoidInitialTrigger) {
            if (chatSearchTracking.isTracking && chatSearchTracking.trackingMessage.liveChatId === liveChat._id) {
                const trackedMessage = document.getElementById(genMessageBlockGroupId(chatSearchTracking.trackingMessage));
                if (messageListRef.current && trackedMessage) {
                    messageListRef.current.scrollTo({
                        behavior: "smooth",
                        top: trackedMessage.offsetTop - (messageListRef.current.offsetHeight / 2)
                    })
                }
            }

            _.debounce(() => {
                setAvoidInitialTrigger(false)
            }, 500)();
        }
    }, [liveChat._id, avoidInitialTrigger, messageHandler.initialized, chatSearchTracking], 200);

    const confirmMessage = useCallback((messageId: string) => {
        socket.sendDebounce(SocketEvent.CONFIRM_MESSAGES, (oldData) => {
            if (oldData) {
                return {
                    liveChatId: liveChat._id,
                    messageIds: [...oldData.messageIds, messageId],
                    managerUserId: manager?.userId
                }
            }
            else {
                return {
                    liveChatId: liveChat._id,
                    messageIds: [messageId],
                    managerUserId: manager?.userId
                }
            }
        });
    }, [socket, liveChat, manager]);

    useSocketIOSubscribe(SocketEvent.RECONNECT, async () => messagesQuery.refetch().finally());

    if (messageHandler.initialized) {
        return (
            <MessageListStyle id="message-list-div" style={opacity === 0 ? {opacity: 0} : undefined}>
                <SimpleBar className="h-100 message-list-wrapper position-relative"
                           scrollableNodeProps={{ id: 'message-list', ref: messageListRef, onScroll: handleMessageListScroll }}>
                    <div className="inner position-relative h-auto">
                        {visiblePreviousTrigger &&
                            <MoreTrigger position="top"
                                         onEnter={onEnterMorePreviousTrigger}
                                         scrollHeight={messageListRef.current?.scrollHeight}/>
                        }
                        {messageHandler.messages.map((messageUI, index) => {
                            switch (messageUI.type) {
                                case "message":
                                    const message = messageUI.data as Message;
                                    const tracked = chatSearchTracking.isTracking && chatSearchTracking.trackingMessage._id === message._id;
                                    return (
                                        <MessageBlockGroup key={genMessageBlockGroupId(message)}
                                                           message={message as Message}
                                                           liveChat={liveChat}
                                                           previousMessage={index > 0 ? messageHandler.messages[index - 1] : undefined}
                                                           confirmMessage={confirmMessage}
                                                           trackedText={tracked ? chatSearchTracking.searchText : undefined}
                                                           updateMessage={messageHandler.updateMessage}
                                                           deleteMessage={messageHandler.deleteMessage}
                                        />
                                    );
                                case "chatbotMessage":
                                    const chatbotMessage = messageUI.data as ChatbotMessageInfo;
                                    return (
                                        <ChatbotMessageBlockGroup key={`chatbot-mbg-${chatbotMessage.id}`} messageInfo={chatbotMessage} liveChat={liveChat} previousMessage={index > 0 ? messageHandler.messages[index - 1] : undefined} />
                                    );
                                case "managerHistory":
                                    const history = messageUI.data as LiveChatHistory;
                                    return (
                                        <ManagerHistoryMessage key={`his-${history._id}`} history={history} />
                                    );
                                case "dateline":
                                    const date = messageUI.data as string;
                                    return (
                                        <DatelineMessage key={`dateline-${date}`} date={date} />
                                    );
                                case "lastConfirmedMessage":
                                    return (
                                        <LastConfirmedHelpMessage key="lastConfirmedMessage" />
                                    );
                                case "endChatbotMessage":
                                    return (
                                        <p key="endChatbotMessage" className="hr-sect">
                                            <i className="mdi mdi-arrow-up-thick"/>
                                            <i className="mdi mdi-arrow-up-thick"/>
                                            {intl.formatMessage({id: 'i000248'})}
                                            <i className="mdi mdi-arrow-up-thick"/>
                                            <i className="mdi mdi-arrow-up-thick"/>
                                        </p>
                                    );
                                default:
                                    return null;
                            }
                        })}
                        {visibleNextTrigger &&
                            <MoreTrigger position="bottom"
                                         onEnter={onEnterMoreNextTrigger}
                                         scrollHeight={messageListRef.current?.scrollHeight}/>
                        }
                    </div>
                    <div id="message-bottom" />
                </SimpleBar>
                {invisibleNewMessage && (
                    <NewMessageAlertStyle>
                        <Button variant="info" className="btn-rounded" onClick={onClickNewMessageAlert}>
                            {intl.formatMessage({id: 'i000017'})}
                        </Button>
                    </NewMessageAlertStyle>
                )}
                <MessageTemplateFormModal channelId={liveChat.channelId}  />
            </MessageListStyle>
        );
    }
    else {
        return (
            <FullAreaSpinner />
        );
    }
};

const MessageListStyle = styled.div`
  z-index: 10;
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  transition: opacity 0.15s ease-in-out;
  
  .message-list-wrapper {
      padding: 10px 10px 10px 18px;
      background-color: #fff;
      position: relative;
    
      .hr-sect {
        display: flex;
        flex: 0;
        align-items: center;
        color: rgba(0, 0, 0, 0.4);
        font-size: 12px;
        margin: 8px 0;
      }
      .hr-sect::before,
      .hr-sect::after {
        content: "";
        flex-grow: 1;
        background: rgba(0, 0, 0, 0.4);
        height: 1px;
        font-size: 0;
        line-height: 0;
        margin: 0 16px;
      }
  }
  
  .simplebar-placeholder {
    width: auto !important;
  }
`

const NewMessageAlertStyle = styled.div`
  position: absolute;
  bottom: 12px;
  z-index: 100;
  background-color: transparent;
  padding: 2px;
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export default MessageList;
