import {LiveChatState, LiveChatStatus} from "../model/livechat";
import {LIVE_CHAT_API_HOST} from "../core/variables";
import oauthAxios from "../core/oauthAxios";
import qs from "qs";

class LiveChatRepository {

    async find(channelId: string, params?: LiveChatListParams) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v2/channels/${channelId}/livechats`, {
            params: params,
            paramsSerializer: {
                serialize: (params) => qs.stringify(params, {arrayFormat: "repeat"})
            }
        });
    }

    async read(channelId: string, liveChatId: string, state?: LiveChatState) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v2/channels/${channelId}/livechats/${liveChatId}`, {
            params: {state}
        });
    }

    async updateAssignee(channelId: string, liveChatId: string, userId: string) {
        return oauthAxios.put(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/livechats/${liveChatId}/assignee`, {
            userId
        });
    }

    async updateState(channelId: string, liveChatId: string, state: LiveChatState, sendMessage?: boolean) {
        return oauthAxios.put(
            `${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/livechats/${liveChatId}/state/${state}`,
            undefined,
            { params: { sendMessage }}
        );
    }

    async requestUserInfo(channelId: string, liveChatId: string) {
        return oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/livechats/${liveChatId}/user/request-info`);
    }

    async requestSatisfactionInfo(channelId: string, liveChatId: string) {
        return oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/livechats/${liveChatId}/satisfaction/request-info`);
    }

    async addManager(channelId: string, liveChatId: string, userId: string) {
        return oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/livechats/${liveChatId}/managers`, {
            userId: userId
        });
    }

    async removeManager(channelId: string, liveChatId: string, userId: string) {
        return oauthAxios.delete(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/livechats/${liveChatId}/managers/${userId}`);
    }

    async findHistories(channelId: string, liveChatId: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/livechats/${liveChatId}/histories`);
    }
    async getUserChatLink(channelId: string, liveChatId: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v2/channels/${channelId}/livechats/${liveChatId}/user-chat-link`);
    }

}

interface LiveChatListParams {
    status?: LiveChatStatus;
    state?: LiveChatState;
    assigneeId?: string;
    managerUserId?: string;
    chatUserId?: string;
    baseDate?: string;
    priorities?: number[];
    sortBy?: string;
    since?: string;
    offset?: number;
    limit?: number;
}

export default new LiveChatRepository();
