import {Spinner} from "react-bootstrap";
import React from "react";
import {Variant} from "react-bootstrap/types";


const FullAreaSpinner = React.memo<{size?: 'sm', animation?: 'border' | 'grow', variant?: Variant}>(({size, animation, variant}) => (
    <div className="d-flex align-items-center justify-content-center h-100 w-100">
        <Spinner variant={variant ?? "info"}
                 size={size}
                 animation={animation ?? "grow"}
                 className="m-2" />
    </div>
));

export default FullAreaSpinner