import React, {useEffect, useMemo, useRef, useState} from "react";
import SettingViewWrapper from "../../component/setting/SettingViewWrapper";
import {useIntl} from "react-intl";
import {Button, Form, InputGroup, Table} from "react-bootstrap";
import styled from "styled-components";
import AnnouncementPublishModal, {
    ANNOUNCEMENT_PUB_MODAL_ID
} from "../../component/announcement/AnnouncementPublishModal";
import useModal from "../../hook/useModal";
import {useParams} from "react-router-dom";
import useManager from "../../query/manager/useManager";
import useAnnouncements from "../../query/announcement/useAnnouncements";
import {Announcement} from "../../model/announcement";
import useManagers from "../../query/manager/useManagers";
import {Manager} from "../../model/manager";
import {utcToLocal} from "../../util/datetime";
import {TextMessage} from "../../model/message";
import parse from "html-react-parser";
import classNames from "classnames";

const AnnouncementSettingView: React.FC = () => {
    const { channelId } = useParams<{channelId: string}>();

    const [search, setSearch] = useState<string>();

    const { data: manager } = useManager(channelId!);
    const { data: managers } = useManagers(channelId!);

    const { data: announcements, isLoading } = useAnnouncements(channelId!, search);

    const intl = useIntl();
    const modal = useModal();

    const searchBarRef = useRef<HTMLInputElement>(null);

    const updateSearch = () => {
        const searchText = searchBarRef.current?.value ?? "";
        if (searchText) {
            setSearch(searchText);
        }
    };

    const resetSearch = () => {
        setSearch(undefined);
        if (searchBarRef.current) {
            searchBarRef.current.value = "";
        }
    };

    const onKeyDownSearchBar = (e: React.KeyboardEvent) => {
        if (e.code === "Enter") {
            updateSearch();
        } else if (e.code === "Escape") {
            resetSearch();
        }
    };

    const onClickSearch = (e: React.MouseEvent) => {
        e.preventDefault();
        updateSearch();
    };

    // const onClickReset = (e: React.MouseEvent) => {
    //     e.preventDefault();
    //     resetSearch();
    // };

    const onChangeSearchBar = () => {
        if (searchBarRef.current?.value === "") {
            resetSearch();
        }
    };

    const onClickNewAnnouncement = () => {
        modal.open(ANNOUNCEMENT_PUB_MODAL_ID);
    };

    return (
        <SettingViewWrapper>
            <div className="setting-title">
                <div className="d-flex justify-content-between align-items-center">
                    <h3>{intl.formatMessage({id: "i210038"})}</h3>
                    <Button className="btn-rounded" onClick={onClickNewAnnouncement}>
                        {intl.formatMessage({id: "i210042"})}
                    </Button>
                </div>
                <p>{intl.formatMessage({id: "i210039"})}</p>
            </div>

            <AnnouncementSearchBarStyle>
                <InputGroup className="announcement-search-bar-input-group">
                    <InputGroup.Text><i className="mdi mdi-magnify"/></InputGroup.Text>
                    <Form.Control ref={searchBarRef}
                                  placeholder={intl.formatMessage({id: "i210043"})}
                                  onKeyDown={onKeyDownSearchBar}
                                  onChange={onChangeSearchBar} />
                    <div className="reset-search-btn" onClick={() => resetSearch()}>
                        <i className="mdi mdi-close"/>
                    </div>
                </InputGroup>
                <Button className="mr-1 rounded-3"
                        variant="info"
                        onClick={onClickSearch}>
                    {intl.formatMessage({id: "i210044"})}
                </Button>
                {/*{searchDisabled &&*/}
                {/*    <Button className="rounded-3" variant="info" onClick={onClickReset}>초기화</Button>*/}
                {/*}*/}
            </AnnouncementSearchBarStyle>

            <Table borderless className="mb-0">
                <colgroup>
                    <col width="*"/>
                    <col width="150px"/>
                    <col width="250px"/>
                    <col width="50px"/>
                </colgroup>
                <thead>
                <tr>
                    <th className="px-2 py-1">{intl.formatMessage({id: "i210045"})}</th>
                    <th className="px-2 py-1 text-center">{intl.formatMessage({id: "i210046"})}</th>
                    <th className="px-2 py-1 text-center">{intl.formatMessage({id: "i210047"})}</th>
                    <th className="px-2 py-1 text-center"></th>
                </tr>
                </thead>
            </Table>
            <div style={{maxHeight: "600px", overflowY: "auto"}}>
                <Table borderless style={{borderCollapse: "separate", borderSpacing: "0 5px"}}>
                    <colgroup>
                        <col width="*"/>
                        <col width="150px"/>
                        <col width="250px"/>
                        <col width="50px"/>
                    </colgroup>
                    <tbody>
                        {!isLoading && announcements && managers &&
                            (announcements.map((announcement) => (
                                <AnnouncementRow key={announcement._id}
                                                 announcement={announcement}
                                                 managers={managers}
                                                 search={search}
                                />
                            )))
                        }
                    </tbody>
                </Table>
            </div>

            {channelId && manager &&
                <AnnouncementPublishModal channelId={channelId} managerUserId={manager.userId}/>
            }
        </SettingViewWrapper>
    )
};

type AnnouncementRowProps = {
    announcement: Announcement;
    managers: Manager[];
    search?: string;
}

const AnnouncementRow: React.FC<AnnouncementRowProps> = (props) => {
    const { announcement, managers, search } = props;
    const intl = useIntl();
    const [showContent, setShowContent] = useState(false);

    const author = useMemo(() => {
        return managers.find(manager => manager.userId === announcement.authorId);
    }, [announcement, managers]);

    const content = useMemo(() => {
        const message = announcement.blocks[0].value as TextMessage;

        return search
            ? message.html.replaceAll(search, `<span class="search-keyword">${search}</span>`)
            : message.html;
    }, [announcement, search]);

    useEffect(() => {
        setShowContent(!!search);
    }, [search])

    return (
        <>
            <AnnouncementTableRowStyle onClick={() => setShowContent(prev => !prev)}>
                <td className="px-3 py-2 text-truncate" style={{maxWidth: "400px", borderRadius: "5px"}}>
                    {(announcement.blocks[0].value as TextMessage).text}
                </td>
                <td className="px-3 py-2 text-center">{author ? author.name : intl.formatMessage({id: "i210040"})}</td>
                <td className="px-3 py-2 text-center">{utcToLocal(announcement.createdAt, "LLL")}</td>
                <td className="pl-0 pr-3 py-2 text-center" style={{borderRadius: "0 5px 5px 0"}}>
                    <i className={classNames("font-16 mdi", showContent ? "mdi-chevron-up" : "mdi-chevron-down")}/>
                </td>
            </AnnouncementTableRowStyle>
            <AnnouncementTableCollapseRowStyle className={classNames("collapse", showContent && "show")}>
                <td colSpan={4} style={{whiteSpace: "pre-wrap", border: "1px solid #9DA3AD4D", borderRadius: "5px"}}>
                    {parse(content)}
                </td>
            </AnnouncementTableCollapseRowStyle>
        </>
    )
}

const AnnouncementSearchBarStyle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  
  .announcement-search-bar-input-group {
    max-width: 590px;
    position: relative;
    margin-right: 0.5rem;

    background-color: white;
    border: 1px solid #9DA3AD;
    border-radius: 4px;
    
    & > .input-group-text {
      border: unset;
      background-color: white;
      border-radius: 4px;
      font-size: 20px;
      padding: 0 0.5rem;
    }
    
    & > .form-control {
      border: unset;
      padding: 0.5rem 0;
    }
    
    & > .reset-search-btn {
      align-self: center;
      margin: 0 0.75rem;
      cursor: pointer;
    }
  }
`;

const AnnouncementTableRowStyle = styled.tr`
  background-color: #F4F5F7;
  transition: background-color 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: #edeef1;
    transition: background-color 0.2s ease;
  }
`;

const AnnouncementTableCollapseRowStyle = styled.tr`
  span.search-keyword {
    background-color: #F7E9BA;
  }
`;

export default AnnouncementSettingView;
