import {ChatUser} from "../model/chatUser";
import {Content} from "@42maru/content-editor";
import _ from "lodash";

export const renderContentWithChatUser = (contents: Content[], chatUser: ChatUser) => {
    const result = contents.map(content => {
        let {text, html} = content.value;
        content.meta.children.forEach(child => {
            if ("type" in child && child.type === "variable") {
                console.log(child)
                const renderedExpression = _.get(chatUser, child.key, child.defaultValue);
                text = text.replace(child.expression, renderedExpression);
                html = html.replace(child.expression, renderedExpression);
            }
        });
        return {text, html};
    });

    return {
        text: result.map(item => item.text).join("\n"),
        html: result.map(item => item.html).join("\n")
    };
};
