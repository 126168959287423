import React, {useEffect, useMemo} from "react";
import {useIntl} from "react-intl";
import {Badge, ListGroup, Spinner} from "react-bootstrap";
import moment from "moment";
import styled from "styled-components";
import SimpleBar from "simplebar-react";
import useChatUserAccessEvents from "../../query/liveChat/useChatUserAccessEvents";
import {ChatUserAccessEvent} from "../../model/chatUserEvent";
import electronRuntime from "../../core/electronRuntime";
import useTooltip from "../../hook/useTooltip";
import FullAreaSpinner from "../FullAreaSpinner";
import {useInView} from "react-intersection-observer";
import useSocketIOSubscribe from "../../socket/useSocketIOSubscribe";
import {SocketEvent} from "../../socket";
import ChatPanelEmptyMessage from "./ChatPanelEmptyMessage";


const UserChatAccessEventsCard: React.FC<UserChatAccessEventsCardProps> = ({channelId, chatUserId, currentLiveChatId}) => {
    const intl = useIntl()
    const [bottomRef, bottomInView] = useInView({threshold: 0});

    const userChatAccessEventsQuery = useChatUserAccessEvents(channelId, chatUserId);

    const userChatAccessEvents = useMemo(() => {
        return userChatAccessEventsQuery.data?.pages?.reduce((acc: ChatUserAccessEvent[], cur) => {
            const validEvents = cur.result.filter((event) => (event.page.title && event.page.url))
            return [...acc, ...validEvents];
        }, []) ?? [];
    }, [userChatAccessEventsQuery.data]);

    const height = useMemo(() => {
        const length = userChatAccessEvents.length;
        const itemHeight = 52;
        if (length === 0) {
            return null;
        } else if (length < 5) {
            return itemHeight * length + 40;
        }
        else {
            return itemHeight * 5 + 40;
        }
    }, [userChatAccessEvents]);

    useEffect(() => {
        (async () => {
            if (bottomInView && userChatAccessEventsQuery.hasNextPage) {
                await userChatAccessEventsQuery.fetchNextPage();
            }
        })();
    }, [bottomInView, userChatAccessEventsQuery]);

    useSocketIOSubscribe(SocketEvent.RECONNECT, async () => userChatAccessEventsQuery.refetch().finally());

    return (
        <ListGroup variant="flush"
                   style={{height: height ? `${height}px` : undefined}}
                   className="overflow-hidden height-transition">
            {userChatAccessEventsQuery.status === 'loading' && (
                <FullAreaSpinner size="sm" />
            )}
            {userChatAccessEventsQuery.isSuccess && (
                <SimpleBar style={{minHeight: '100%', paddingRight: '11px'}}>
                    {userChatAccessEventsQuery.isSuccess && userChatAccessEvents.length > 0 && (
                        <div className='pb-2'>
                            {userChatAccessEvents.map(liveChat => (
                                <UserChatAccessItem key={liveChat._id} chatUserAccess={liveChat} currentLiveChatId={currentLiveChatId} />
                            ))}
                        </div>
                    )}
                    {(userChatAccessEventsQuery.isSuccess && !userChatAccessEventsQuery.hasNextPage) && (
                        <ChatPanelEmptyMessage message={intl.formatMessage({id: 'i100050'})} />
                    )}
                    {userChatAccessEventsQuery.hasNextPage && (
                        <div style={{height: '40px', width: '100%'}} ref={bottomRef}>
                            {userChatAccessEventsQuery.isFetchingNextPage && (
                                <Spinner size="sm" animation="border" />
                            )}
                        </div>
                    )}
                </SimpleBar>
            )}
        </ListGroup>
    );
};


const UserChatAccessItem: React.FC<UserChatAccessItemProps> = ({chatUserAccess}) => {
    const intl = useIntl()

    const onClick = () => {
        if (!chatUserAccess.page.url ){
            return
        }

        if (electronRuntime) {
            electronRuntime.send('open-default-browser', `${chatUserAccess.page.url}`);
        } else {
            window.open(chatUserAccess.page.url, '_blank');
        }
    }

    const description = useMemo(() => {
        switch (chatUserAccess.page.pageType) {
            case "cart":
                return intl.formatMessage({id: 'i100051'});
            case "product-detail":
                return intl.formatMessage({id: 'i100052'});
            case "order":
                return intl.formatMessage({id: 'i100053'});
            case "main":
                return intl.formatMessage({id: 'i100054'});
            case "join":
                return intl.formatMessage({id: 'i100055'});
            case "login":
                return intl.formatMessage({id: 'i100056'});
            default:
                return '';
        }
    }, [chatUserAccess.page.pageType, intl])

    const url = useMemo(() => {
        if (!chatUserAccess.page.url) {
            return ''
        }

        return chatUserAccess.page.url.length > 50 ? chatUserAccess.page.url.slice(0,50)+'...' : chatUserAccess.page.url
    }, [chatUserAccess.page.url])
    const valueTooltip = useTooltip(url, {delayShow: 1000});

    return (
        <UserChatAccessItemStyle key={chatUserAccess._id}
                                role={'button'}
                                 className="p-0 border-0 rounded"
                                 onClick={onClick}>
            <div className="p-1" ref={valueTooltip.setTriggerRef}>
                {valueTooltip.visible && valueTooltip.element}
                <div className="d-flex align-items-center">
                    <span className="chat-access-page-title">
                        {chatUserAccess.page.title}
                    </span>
                    {description && <Badge bg='info' className='ml-1'>{description}</Badge>}
                </div>
                <div className="d-flex justify-content-end">
                    <small>{moment.utc(chatUserAccess.connectionTime).local().format('lll')}</small>
                </div>
            </div>
        </UserChatAccessItemStyle>
    );
};


const UserChatAccessItemStyle = styled(ListGroup.Item) `
  &:hover {
    background-color: #f4f5f7;
  }
  
  .chat-access-page-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

type UserChatAccessEventsCardProps = {
    channelId: string;
    chatUserId: string;
    currentLiveChatId: string;
};

type UserChatAccessItemProps = {
    chatUserAccess: ChatUserAccessEvent;
    currentLiveChatId: string;
};

export default UserChatAccessEventsCard;