import {useInfiniteQuery} from "@tanstack/react-query";
import {Page} from "../index";
import {Message, MessageQueryDirection as Direction} from "../../model/message";
import {LiveChatError, toLiveChatError} from "../../error";
import messageRepository from "../../repository/MessageRepository";


const getData = async (channelId: string, liveChatId: string, pageable: {baseDate?: string, limit: number, direction: Direction}) => {
    try {
        if (pageable.direction === Direction.EXACT) {
            const { data: previousData } = await messageRepository.find(channelId, liveChatId, Direction.PREV, pageable.baseDate, pageable.limit);
            const { data: middleData } = await messageRepository.find(channelId, liveChatId, Direction.EXACT, pageable.baseDate);
            const { data: nextData } = await messageRepository.find(channelId, liveChatId, Direction.NEXT, pageable.baseDate, pageable.limit);

            const previousMessages = previousData.result.reverse();
            const middleMessages = middleData.result;
            const nextMessages = nextData.result;

            return {
                result: [...previousMessages, ...middleMessages, ...nextMessages],
                pageable: {
                    ...pageable,
                    direction: Direction.PREV,
                    isFirst: previousMessages.length < pageable.limit,
                    isLast: nextMessages.length < pageable.limit
                }
            }
        } else {
            const { data } = await messageRepository.find(channelId, liveChatId, pageable.direction, pageable.baseDate, pageable.limit);
            const messages = pageable.direction === Direction.PREV ? data.result.reverse() : data.result;

            return {
                result: messages,
                pageable: {
                    ...pageable,
                    isFirst: pageable.direction === Direction.PREV && messages.length < pageable.limit,
                    isLast: pageable.direction === Direction.NEXT && messages.length < pageable.limit
                }
            }
        }

    } catch (e) {
        throw toLiveChatError(e, {noErrorPage: true})
    }
}

const useMessages = (channelId: string, liveChatId: string, baseDate?: string) => {
    return useInfiniteQuery<Page<Message, {baseDate?: string}>, LiveChatError>({
        queryKey: ['messages', channelId, liveChatId, baseDate],
        queryFn: ({
            pageParam = {
                baseDate: baseDate,
                limit: 50,
                direction: Direction.EXACT,
                isFirst: false,
                isLast: false
            }
        }) => getData(channelId, liveChatId, pageParam),
        refetchOnMount: true,
        getPreviousPageParam: ({pageable, result}) => (
            pageable.isFirst ?
                undefined :
                {
                    ...pageable,
                    baseDate: result.length > 0 ?
                        result[0].createdAt :
                        undefined,
                    direction: Direction.PREV,
                    isFirst: false,
                }
        ),
        getNextPageParam: ({pageable, result}) => (
            pageable.isLast ?
                undefined :
                {
                    ...pageable,
                    baseDate: result.length > 0 ?
                        result[result.length - 1].createdAt :
                        undefined,
                    direction: Direction.NEXT,
                    isLast: false
                }
        )
    });
};

export default useMessages;
