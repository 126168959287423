import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import useUserPermissions from "../query/useUserPermissions";
import {LiveChatError} from "../error";
import {authorizePermissions} from "../util/permissionUtil";

const PrivateRoute: React.FC<PrivateRouteProps> = ({children, permissions}) => {
    // const location = useLocation();
    const {channelId} = useParams<{channelId?: string}>();
    const {data: userPermissions} = useUserPermissions(channelId);

    // if (!channelId) {
    //     throw new Error('Required channelId path params')
    // }

    // const {data: profile} = useProfile();

    // const ignoreRedirectPaths = useMemo(() => {
    //     return ['/login', '/authorize'];
    // }, []);

    useEffect(() => {
        if (!authorizePermissions(permissions, userPermissions)) {
            throw new LiveChatError('403', 'Not permitted')
        }
    }, [userPermissions, permissions]);

    return (
        <>
            {children}
        </>
    );

    // if (profile) {
    //     return (
    //         <>
    //             {channelId && (
    //                 <Suspense fallback={<></>}>
    //                     <PermissionCheck channelId={channelId} permission={permission} />
    //                 </Suspense>
    //             )}
    //             {children}
    //         </>
    //     );
    // }
    // else {
    //     if (!ignoreRedirectPaths.includes(location.pathname)) {
    //         return <Navigate replace to="/login" />
    //     }
    //     else {
    //         // 로딩페이지 리턴하면 되나?
    //         return null;
    //     }
    // }
};

// const PermissionCheck: React.FC<{ channelId?: string, permission?: string }> = ({channelId, permission}) => {
//     const {data: permissions} = usePermissions(channelId);
//     const errorBoundary = useErrorBoundary();
//
//     useEffect(() => {
//         if (permission) {
//             if (!permissions?.includes(permission)) {
//                 errorBoundary.setStatus(403);
//             }
//             // if (channelId) {
//             //     if (!permissions?.includes(permission)) {
//             //         errorBoundary.setStatus(403);
//             //     }
//             // }
//             // else {
//             //     if (!permissions?.includes(permission)) {
//             //         errorBoundary.setStatus(403);
//             //     }
//             // }
//         }
//         // }, [permission, errorBoundary, permissionListStore]);
//     }, [permission, errorBoundary, permissions]);
//
//     return null;
// }

interface PrivateRouteProps {
    permissions?: string[];
    children?: React.ReactNode
}

export default PrivateRoute;
