import {LIVE_CHAT_API_HOST} from "../core/variables";
import oauthAxios from "../core/oauthAxios";


class LiveChatMemoRepository {

    async find(channelId: string, liveChatId: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/livechats/${liveChatId}/memos`);
    }

    async create(channelId: string, liveChatId: string, contents: string) {
        return oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/livechats/${liveChatId}/memos`, {
            contents
        })
    }

    async update(memoId: string, channelId: string, liveChatId: string, contents: string) {
        return oauthAxios.put(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/livechats/${liveChatId}/memos/${memoId}`, {
            contents
        });
    }
}

export default new LiveChatMemoRepository();