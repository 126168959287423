import React, {useCallback, useEffect, useRef} from "react";
import io, {Socket} from "socket.io-client";
import {WEBSOCKET_HOST, WEBSOCKET_PATH} from "../core/variables";
import electronRuntime from "../core/electronRuntime";
import {getAccessToken} from "../core/token";
import {useNetworkState, useRerender} from "@react-hookz/web";
import moment from "moment";
import {renewAuthToken} from "../core/oauthAxios";
import "../assets/css/main.scss";
import toast from "react-hot-toast";
import {UnStyledButton} from "../component/UnstyledBootstrap";

export const SocketContext = React.createContext<{socket: Socket|null}>({socket: null});

const SocketProvider = React.memo<{channelId: string, children: React.ReactNode}>(({channelId, children}) => {
    const socketRef = useRef<Socket|null>(null);
    const errorCountRef = useRef(0);
    const connectionErrorToastIdRef = useRef<string>();
    const offlineRef = useRef(false);
    const renderer = useRerender();
    const onlineState = useNetworkState();

    // Clear last connected server info
    const clearConnectedServerInfo = () => {
        if (socketRef.current?.io.opts.query) {
            if ('appVersion' in socketRef.current.io.opts.query) {
                delete socketRef.current.io.opts.query['appVersion']
            }

            if ('appVersion' in socketRef.current.io.opts.query) {
                delete socketRef.current.io.opts.query['appVersion']
            }
        }
    }

    useEffect(() => {
        offlineRef.current = !onlineState.online;
        if (!onlineState.online) {
            clearConnectedServerInfo()
        }
    }, [onlineState]);

    const onClickReload = useCallback((e: React.MouseEvent) => {
        e.preventDefault();

        if (electronRuntime) {
            electronRuntime.send('loadMain', 'ping');
        }
        else {
            window.location.reload();
        }
    }, []);

    useEffect(() => {
        const handleConnected = (response: {data: {appVersion: string, serverId: string}}) => {
            console.info(`SocketIO: Connected ${moment.utc().format()}`);
            if (socketRef.current!.io.opts.query) {
                socketRef.current!.io.opts.query['appVersion'] = response.data.appVersion
                socketRef.current!.io.opts.query['serverId'] = response.data.serverId
            }

            errorCountRef.current = 0;

            if (connectionErrorToastIdRef.current) {
                toast.dismiss(connectionErrorToastIdRef.current)
                connectionErrorToastIdRef.current = undefined;
            }

            renderer();
        };

        const handleDisconnected = (reason: string) => {
            console.info(`SocketIO: Disconnect (${reason}) ${moment.utc().format()}`);
            if (reason === 'io server disconnect' && socketRef.current) {
                socketRef.current.connect();
            }
        };

        const handleConnectError = async (response: {message: string, data?: {status_code: number}}) => {
            console.error(`SocketIO: Connection error ${moment.utc().format()}`);
            if (response.data) {
                if (response.data.status_code === 401) {
                    if (electronRuntime) {
                        try {
                            await renewAuthToken();
                            if (socketRef.current) {
                                socketRef.current.disconnect();
                                socketRef.current.connect();
                            }
                        } catch (e) {
                            window.location.reload();
                        }
                    }
                    else {
                        window.location.reload();
                    }
                }
                else if (response.data.status_code === 403) {

                }
                else {

                }
            }
            else {
                // if (errorCountRef.current > 5) {
                //     setOffline(true);
                // }
            }
        };

        const handleHealth = (sid: string) => {
            if (socketRef.current?.id !== sid) {
                socketRef.current?.emit('health_ack', {})
            }

        };

        const handleSocketError = () => {
            errorCountRef.current = errorCountRef.current + 1;
            if (errorCountRef.current > 3 && !connectionErrorToastIdRef.current && !offlineRef.current) {
                connectionErrorToastIdRef.current = toast.loading(
                    <div>
                        <span className="me-1">연결이 끊겼습니다.</span>
                        <UnStyledButton className="text-primary" style={{padding: '4px', lineHeight: 1}} onClick={onClickReload}>새로고침</UnStyledButton>
                    </div>, {icon: <i className="mdi mdi-wifi-off text-danger font-18" />}
                );
            }
        };

        if (!socketRef.current) {
            socketRef.current = io(WEBSOCKET_HOST, {
                path: WEBSOCKET_PATH,
                query: {
                    channelId,
                    userType: 'manager',
                    authType: electronRuntime ? 'accessToken': 'session'
                },
                auth: (callback) => callback({accessToken: getAccessToken()}),
                reconnection: true,
                reconnectionDelay: 500,
                reconnectionAttempts: Infinity,
                transports: ['websocket']
            });

            socketRef.current.on('connected', handleConnected);
            socketRef.current.on('health', handleHealth);
            socketRef.current.on('connect_error', handleConnectError);
            socketRef.current.on('disconnect', handleDisconnected);
            // socketRef.current.io.on('reconnect', () => console.log('reconnect'))
            // socketRef.current.io.on('reconnect_attempt', () => console.log('reconnect_attempt'))
            // socketRef.current.io.on('reconnect_failed', () => console.log('reconnect_failed'))
            // socketRef.current.io.on('reconnect_error', () => console.log('reconnect_error'))
            socketRef.current.io.on('error', handleSocketError);

            // throw new LiveChatError('401', 'socket connection failure');
        }

        return () => {
            if (socketRef.current?.connected) {
                socketRef.current?.off('connected', handleConnected);
                socketRef.current?.off('connect_error', handleConnectError);
                socketRef.current?.off('disconnect', handleDisconnected);
                socketRef.current.io.off('error', handleSocketError);

                socketRef.current.removeAllListeners();
                socketRef.current.disconnect();
                // socketRef.current = null;
            }
        }
    }, [channelId, renderer, onClickReload]);

    // if (socketRef.current?.connected) {
    //     return (
    //         <SocketContext.Provider value={{socket: socketRef.current}}>{children}</SocketContext.Provider>
    //     );
    // }
    // else {
    //     return (
    //         <AppLoading />
    //     );
    // }

    useEffect(() => {
        return () => clearConnectedServerInfo()
    }, [])

    return (
        <SocketContext.Provider value={{socket: socketRef.current}}>
            {children}
        </SocketContext.Provider>
    );

});

export default SocketProvider;