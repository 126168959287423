import {Page} from "../index";
import {useInfiniteQuery} from "@tanstack/react-query";
import userEventsRepository from "../../repository/UserEventsRepository";
import {ChatUserAccessEvent} from "../../model/chatUserEvent";
import {LiveChatError, toLiveChatError} from "../../error";

export const QUERY_KEY_Chat_USER_ACCESS = 'useChatUserAccess'

const getData = async (channelId: string, chatUserId: string, pageable: {offset: number, limit: number}) => {
    try {

        const {data} = await userEventsRepository.findChatAccessEvents(channelId,
            chatUserId,
            pageable.offset,
            pageable.limit
        );

        return {
            result: data.result,
            pageable: {
                ...pageable,
                isLast: data.result.length < pageable.limit
            }
        };

    } catch (e) {
        throw toLiveChatError(e);
    }
}

const useChatUserAccessEvents = (channelId: string, userId: string) => {
    return useInfiniteQuery<Page<ChatUserAccessEvent, {offset: number}>, LiveChatError>([QUERY_KEY_Chat_USER_ACCESS, channelId, userId], async ({pageParam = {offset: 0, limit: 20, isLast: false}}) => getData(channelId, userId, pageParam), {
        suspense: false,
        useErrorBoundary: false,
        getNextPageParam: ({pageable}) => {
            if (pageable.isLast) {
                return undefined;
            }

            return {
                offset: pageable.offset + pageable.limit,
                limit: pageable.limit,
                isLast: false
            };
        }
    });
};


export default useChatUserAccessEvents;