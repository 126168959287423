import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError, AxiosResponse} from "axios";
import liveChatMemoRepository from "../../repository/LiveChatMemoRepository";


const useCreateMemo = (channelId: string, liveChatId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, string>(['memos', 'create', channelId, liveChatId], async (contents: string) => {
        try {
            return await liveChatMemoRepository.create(channelId, liveChatId, contents);
        } catch (e) {
            throw e;
        }
    }, {
        onSuccess: () => {
            return queryClient.invalidateQueries(['memos', channelId, liveChatId], {exact: true});
        }
    });
}

export default useCreateMemo;
