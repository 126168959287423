import React, {useEffect, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {Button, Form} from "react-bootstrap";
import {useParams} from "react-router-dom";
import * as uuid from "uuid";
import classNames from "classnames";
import SettingViewWrapper from "../../component/setting/SettingViewWrapper";
import useChannel from "../../query/channel/useChannel";
import useUpdateChannel from "../../query/channel/useUpdateChannel";
import {Editor, EditorElement, EditorRef, trimContents} from "@42maru/content-editor";
import {MessageBlock} from "../../model/message";
import useUserAttributes from "../../query/useUserAttributes";
import {Channel, ChannelMessage} from "../../model/channel";
import toast from "react-hot-toast";

const ChatbotMessageConfigView: React.FC = () => {
    const intl = useIntl();

    const {channelId} = useParams<{channelId: string}>();

    if (!channelId) {
        throw new Error('Required channelId path params')
    }

    const {data: userAttributes} = useUserAttributes(channelId)

    const userEditorVariables = useMemo(() => {
        return userAttributes?.map(attribute => ({
            key: attribute.name,
            label: attribute.description
        })) ?? [];
    }, [userAttributes])

    const {data: channel} = useChannel(channelId);
    const {mutateAsync: updateChannel} = useUpdateChannel(channelId);

    const [respondMessages, setRespondMessages] = useState<{id: string, elements: EditorElement[]}[]>([])

    const welcomeMessageEditorRef = useRef<EditorRef>(null);
    // const cancelMessageEditorRef = useRef<EditorRef>(null);
    const closedMessageEditorRef = useRef<EditorRef>(null);
    const respondMessageEditorRefs = useRef<EditorRef[]>([]);
    const completedMessageEditorRef = useRef<EditorRef>(null);

    const [invalidForm, setInvalidForm] = useState({welcome: false, closed: false});
    const [saving, setSaving] = useState(false);

    const config = useMemo(() => {
        return channel?.config;
    }, [channel]);

    const defaultMessages = useMemo(() => {
        if (!config) {
            return undefined;
        }

        return {
            welcome: (config.message.welcome[0].meta ?? []) as EditorElement[],
            closed: (config.message.closed[0].meta ?? []) as EditorElement[],
            respond: config.message.respond?.map(blocks => {
                return blocks.reduce((prev, cur) => {
                    return prev.concat(cur.meta as EditorElement[])
                }, [] as EditorElement[])
            }) ?? [] as EditorElement[][],
            completed: (config.message.completed?.[0]?.meta ?? []) as EditorElement[]
        }
    }, [config])

    useEffect(() => {
        if (defaultMessages) {
            const elements = defaultMessages.respond;
            setRespondMessages(elements.map(elements => ({
                id: uuid.v4(),
                elements: elements
            })))

            // for automatic update
            welcomeMessageEditorRef.current?.setValues(defaultMessages.welcome);
            closedMessageEditorRef.current?.setValues(defaultMessages.closed);
            completedMessageEditorRef.current?.setValues(defaultMessages.completed);
        }
    }, [defaultMessages])

    useEffect(() => {
        if (respondMessages.length === respondMessageEditorRefs.current.length) {
            respondMessageEditorRefs.current?.forEach((ref, index) => {
                ref.setValues(respondMessages[index].elements);
            })
        }
    }, [respondMessages])

    const onClickAddRespondMessage = (e: React.MouseEvent) => {
        e.preventDefault();
        setRespondMessages([...respondMessages, {id: uuid.v4(), elements: [] as EditorElement[]}])
    };

    const onDeleteRespondMessage = (id: string) => {
        respondMessageEditorRefs.current = []
        setRespondMessages(respondMessages.filter(message => message.id !== id));
    };

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        if (!channel) {
            return;
        }

        const welcomeContents = welcomeMessageEditorRef.current?.getContents() || [];
        const closedContents = closedMessageEditorRef.current?.getContents() || [];
        const completedContents = completedMessageEditorRef.current?.getContents() || [];

        const respondContents = respondMessageEditorRefs.current.filter(el => {
            const contents = el.getContents();
            return trimContents(contents).length >= 1;
        }).map(el => el.getContents())

        const invalid = {
            welcome: trimContents(welcomeContents).length < 1,
            closed: trimContents(closedContents).length < 1
        }

        if (Object.values(invalid).some(v => v)) {
            toast.error(intl.formatMessage({id: 'i200005'}));
            setInvalidForm(invalid);
            return;
        }

        setSaving(true);

        const text = {
            welcome: welcomeContents.map(content => content.value.text).join('\n'),
            closed: closedContents.map(content => content.value.text).join('\n'),
            completed: completedContents.map(content => content.value.text).join('\n'),
            respond: respondContents.map(contents => contents.map(content => content.value.text).join('\n'))
        }
        const html = {
            welcome: welcomeContents.map(content => content.value.html).join('\n'),
            closed: closedContents.map(content => content.value.html).join('\n'),
            completed: completedContents.map(content => content.value.html).join('\n'),
            respond: respondContents.map(contents => contents.map(content => content.value.html).join('\n'))
        }
        const meta = {
            welcome: welcomeContents.map(content => content.meta),
            closed: closedContents.map(content => content.meta),
            completed: completedContents.map(content => content.meta),
            respond: respondContents.map(contents => contents.map(content => content.meta))
        }

        const blocks = {
            welcome: [] as MessageBlock[],
            closed: [] as MessageBlock[],
            completed: [] as MessageBlock[],
            respond: [] as MessageBlock[][]
        }

        if (text.welcome) {
            blocks.welcome.push({
                type: 'text',
                value: {
                    text: text.welcome,
                    html: html.welcome
                },
                meta: meta.welcome
            })
        }

        if (text.closed) {
            blocks.closed.push({
                type: 'text',
                value: {
                    text: text.closed,
                    html: html.closed
                },
                meta: meta.closed
            })
        }

        if (text.completed) {
            blocks.completed.push({
                type: 'text',
                value: {
                    text: text.completed,
                    html: html.completed
                },
                meta: meta.completed
            })
        }

        if (text.respond.length >= 1) {
            text.respond.forEach((item, index) => {
                blocks.respond.push([{
                    type: 'text',
                    value: {
                        text: item,
                        html: item,
                    },
                    meta: meta.respond[index]
                }])
            })
        }

        const newChannelMessage: ChannelMessage = {
            welcome: blocks.welcome,
            closed: blocks.closed,
            respond: blocks.respond,
            completed: blocks.completed
        }

        const form = {
            ...channel,
            config: {
                ...config,
                message: newChannelMessage
            }
        }

        await toast.promise(
            updateChannel(form as Channel),
            {
                loading: intl.formatMessage(({id: 'i200003'})),
                success: () => {
                    setSaving(false);
                    return intl.formatMessage(({id: 'i000106'}))
                },
                error: () => {
                    setSaving(false);
                    return intl.formatMessage(({id: 'e000001'}));
                }
            }
        )
    }

    return (
        <SettingViewWrapper>
            <div className="setting-title">
                <h3>{intl.formatMessage({id: 'i000035'})}</h3>
                <p>
                    {intl.formatMessage({id: 'i000036'})}
                </p>
            </div>

            <Form onSubmit={onSubmit}>
                <Form.Group className="mb-2">
                    <Form.Label>
                        {intl.formatMessage({id: 'i000037'})}
                        <small className="text-muted ml-1">{intl.formatMessage({id: 'i000282'})}</small>
                    </Form.Label>
                    <Editor id='welcome-message-editor'
                            defaultValue={defaultMessages?.welcome}
                            ref={welcomeMessageEditorRef}
                            height="80px"
                            width="600px"
                            enterFunctionKey={"shift"}
                            variables={userEditorVariables}/>
                    {invalidForm.welcome && <small className="text-danger">{intl.formatMessage({id: "i000024"})}</small>}
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>
                        {intl.formatMessage({id: 'i000039'})}
                        <small className="text-muted ml-1">{intl.formatMessage({id: 'i000284'})}</small>
                    </Form.Label>
                    <Editor id='closed-message-editor'
                            defaultValue={defaultMessages?.closed}
                            ref={closedMessageEditorRef}
                            height="80px"
                            width="600px"
                            enterFunctionKey={"shift"}
                            variables={userEditorVariables}/>
                    {invalidForm.closed && <small className="text-danger">{intl.formatMessage({id: "i000024"})}</small>}
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>
                        {intl.formatMessage({id: 'i000179'})}
                        <i className="mdi mdi-plus-box-outline text-primary font-15" role="button" onClick={onClickAddRespondMessage} />
                        <small className="text-muted ml-1">{intl.formatMessage({id: 'i000285'})}</small>
                    </Form.Label>

                    {respondMessages.map((respondMessage, index) => {
                        return (
                            <div key={respondMessage.id}
                                 className="position-relative mb-1"
                                 style={{width: "600px"}}>
                                <Editor id={`respond-message-editor-${index}`}
                                        key={`${JSON.stringify(respondMessage.elements)}-respond-${index}`}
                                        defaultValue={respondMessage.elements}
                                        ref={(el) => {
                                            if (el) respondMessageEditorRefs.current[index] = el;
                                        }}
                                        height="80px"
                                        width="600px"
                                        enterFunctionKey={"shift"}
                                        variables={userEditorVariables}/>
                                <i className={classNames('mdi mdi-trash-can-outline text-muted font-16')}
                                   style={{position: 'absolute', top: '4px', right: '5px'}}
                                   role="button"
                                   onClick={() => onDeleteRespondMessage(respondMessage.id)} />
                            </div>
                        )
                    })}
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>
                        {intl.formatMessage({id: 'i000186'})}
                        <small className="text-muted ml-1">{intl.formatMessage({id: 'i000286'})}</small>
                    </Form.Label>
                    <Editor id='complete-message-editor'
                            key={`${JSON.stringify(defaultMessages?.completed)}-completed`}
                            defaultValue={defaultMessages?.completed}
                            ref={completedMessageEditorRef}
                            height="80px"
                            width="600px"
                            enterFunctionKey={"shift"}
                            variables={userEditorVariables}/>
                </Form.Group>
                <Button type={'submit'} className="btn-rounded" disabled={saving}>
                    {intl.formatMessage({id: 'i000040'})}
                </Button>
            </Form>
        </SettingViewWrapper>
    );
};

export default ChatbotMessageConfigView;
