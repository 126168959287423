import {useCallback, useContext, useMemo, useRef} from "react";
import {SocketContext} from "./SocketProvider";
import {useDebouncedCallback} from "@react-hookz/web";


const useSocket = () => {
    const { socket } = useContext(SocketContext);

    const eventDataRef = useRef<{[key: string]: any}>({});

    const send = useCallback((event: string, data: any) => {
        if (socket) {
            console.debug('socket is connected', socket.connected);
            if (socket.disconnected) {
                // socket.auth = {'accessToken': getAccessToken()};
                socket.connect();
            }

            // if (!socket.connected) {
            //     connect?.();
            //     socket.emit(event, data);
            // }
            // else {
                socket.emit(event, data);
            // }
        }
    }, [socket]);

    const _sendDebounce = useDebouncedCallback((event: string) => {
        send(event, eventDataRef.current[event]);
        delete eventDataRef.current[event];
    }, [send], 300, 1000);

    const sendDebounce = useCallback((event: string, callable: (oldData?: any) => any) => {
        eventDataRef.current[event] = callable(eventDataRef.current[event]);
        _sendDebounce(event);
    }, [_sendDebounce]);

    const join = useCallback((channel: string) => {
        if (socket) {
            // if (!socket.connected) {
            //     connect?.();
            //     socket.emit('join', channel);
            // }
            // else {
                socket.emit('join', channel);
            // }
        }
    }, [socket]);

    const leave = useCallback((channel: string) => {
        if (socket) {
            // if (!socket.connected) {
            //     connect?.();
            //     socket.emit('leave', channel);
            // }
            // else {
                socket.emit('leave', channel);
            // }
        }
    }, [socket]);

    return useMemo(() => {
        return {
            connected: socket?.connected,
            send,
            sendDebounce,
            join,
            leave
        }
    }, [send, sendDebounce, join, leave, socket?.connected]);
};

export default useSocket;