import React, {useEffect, useState} from "react";
import useProfile from "../query/useProfile";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import * as Sentry from '@sentry/react';
import {LiveChatError, toLiveChatError} from "../error";
import "../assets/css/main.scss";
import AppLoading from "./AppLoading";
import {useRecoilState} from "recoil";
import deepLinkQueriesState from "../recoil/deepLinkQueries";
import electronRuntime from "../core/electronRuntime";


const AuthProvider: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const {data: profile, isSuccess, error, status} = useProfile();

    useEffect(() => {
        if (error) {
            if (error.code === '401') {
                localStorage.setItem('redirect', location.pathname);
                navigate('/login');
            } else {
                throw toLiveChatError(error);
            }
        }
    }, [error, navigate, location]);

    useEffect(() => {
        if (isSuccess) {
            Sentry.configureScope((scope) => {
                if (profile) {
                    scope.setUser({
                        id: profile.user_id,
                        email: profile.email,
                        username: profile.username
                    });
                }
                else {
                    scope.setUser({
                        id: undefined,
                        email: undefined,
                        username: undefined
                    });
                }
            });
        }
    }, [profile, isSuccess]);

    if (status === 'loading') {
        return (
            <AppLoading />
        );
    }
    else {
        if (isSuccess) {
            return (
                <DeepLinkNavigator>
                    <Outlet />
                </DeepLinkNavigator>

            );
        }
        else {
            if (error?.code !== '401') {
                throw new LiveChatError(error?.code, 'An error occurred during authentication');
            }
            else {
                return null;
            }
        }
    }
}

const DeepLinkNavigator: React.FC<{children: React.ReactNode}> = ({children}) => {

    const navigate = useNavigate();

    const [initialized, setInitialized] = useState(!electronRuntime)
    const [deepLinkQueries, setDeepLinkQueries] = useRecoilState(deepLinkQueriesState);


    useEffect(() => {
        if (!electronRuntime) return

        if (deepLinkQueries?.channelId) {
            let pathname = `/channels/${deepLinkQueries.channelId}`
            if (deepLinkQueries.liveChatId) {
                pathname += `/livechats/${deepLinkQueries.liveChatId}`
            }

            setDeepLinkQueries(null)
            navigate(pathname)
        }

        setInitialized(true)

    }, [navigate, setDeepLinkQueries, deepLinkQueries])

    if (!initialized) return null

    return <>{children}</>
}

export default AuthProvider;