import oauthAxios from "../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../core/variables";


class ChatbotMessageRepository {
    async find(channelId: string, liveChatId: string, endDate?: string, page?: number, size?: number) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/livechats/${liveChatId}/chatbot-messages`, {
            params: {
                endDate, page, size
            }
        });
    }
}

export default new ChatbotMessageRepository();