import React, {useMemo, useRef} from "react";
import {Card} from "react-bootstrap";
import {DefaultExtensionType, defaultStyles, FileIcon} from "react-file-icon";
import numeral from "numeral";
import styled from "styled-components";
import classNames from "classnames";
import IconButton from "./StyledButton";
import electronRuntime from "../core/electronRuntime";
import {v4} from "uuid";
import {useSetRecoilState} from "recoil";
import downloadFilesState from "../recoil/downloadFiles";
import {useIntl} from "react-intl";

const FileInfoCard: React.FC<FileInfoCardProps> = (props) => {
    const {id, variant, size, filename, fileSize, className, downloadable, downloadSrc, onRemove, disabled} = props;
    const intl = useIntl();
    const setDownloadFiles = useSetRecoilState(downloadFilesState);

    const downloadLinkRef = useRef<HTMLAnchorElement>(null);

    const extension: DefaultExtensionType = useMemo(() => {
        const split = filename.split('.');
        if (split.length > 1) {
            return (split.pop() || 'txt') as DefaultExtensionType;
        }

        return 'txt';
    }, [filename]);

    const onClickRemoveBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        onRemove && onRemove(id);
    };

    const onClickDownloadBtn = async (e: React.MouseEvent) => {
        e.preventDefault();

        if (downloadSrc) {
            if (electronRuntime) {
                const id = v4();
                electronRuntime.send('download', {id, url: downloadSrc, filename});
                setDownloadFiles(prev => {
                    return [{id, url: downloadSrc, filename: filename}, ...prev];
                });
            }
            else {
                if (downloadLinkRef.current) {
                    downloadLinkRef.current.click();
                }
            }
        }
    };

    return (
        <Card border={disabled ? 'light' : (variant || 'light')} className={classNames('shadow-none border mb-0', {[className || '']: className})}>
            <Card.Body className="px-2 py-1">
                <div className="d-flex align-items-center justify-content-between">
                    <FileInfoStyle>
                        <FileIconStyle size={size}>
                            <FileIcon extension={extension} {...defaultStyles[extension]} />
                        </FileIconStyle>
                        {size === 'sm' && (
                            <>
                                <FilenameSmallStyle>{filename}</FilenameSmallStyle>
                                <small className="text-muted">
                                    {fileSize && numeral(fileSize).format('0b')}
                                    {!fileSize && '-'}
                                </small>
                            </>
                        )}
                        {!size && (
                            <div className="d-flex flex-column justify-content-center overflow-hidden">
                                <FilenameStyle>{filename}</FilenameStyle>
                                <small className="text-muted">
                                    {fileSize && numeral(fileSize).format('0b')}
                                    {!fileSize && '-'}
                                </small>
                            </div>
                        )}
                    </FileInfoStyle>
                    <div style={{width: '70px'}} className="text-end flex-shrink-0">
                        {onRemove && <IconButton onClick={onClickRemoveBtn} disabled={disabled}><i className="mdi mdi-close" /></IconButton>}
                        {downloadable && <IconButton onClick={onClickDownloadBtn} disabled={disabled}><i className="mdi mdi-download" /></IconButton>}
                        {downloadable && <a href={downloadSrc} ref={downloadLinkRef} target="_blank" rel="noreferrer" download={filename} className="d-none">download</a>}
                        {!downloadable && <span className="font-12 text-muted">{intl.formatMessage({id: "i210037"})}</span>}
                    </div>
                </div>
                {/*{downloadProgress > 0 && <ProgressBar variant={'success'} style={{height: '5px'}} min={0} max={100} now={downloadProgress} animated={true} className="mt-1" />}*/}
            </Card.Body>
        </Card>
    );
};

const FileInfoStyle = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`

const FileIconStyle = styled.span<{size?: 'sm'}>`
  margin-right: 0.375rem;
  flex-shrink: 0;
  
  ${props => props.size === 'sm' && 'width: 20px;'}
  ${props => !props.size && 'width: 30px;'}
`;

const FilenameStyle = styled.span`
  color: #474d56;
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 240px;
`;

const FilenameSmallStyle = styled.span`
  margin-right: 0.375rem;
  color: #474d56;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

type FileInfoCardProps = {
    id: string,
    size?: 'sm',
    variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | string;
    filename: string,
    fileSize?: number,
    className?: string,
    downloadable?: boolean,
    downloadSrc?: string,
    onRemove?: (id: string) => void,
    disabled?: boolean
};

export default FileInfoCard;
