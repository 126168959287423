import React, {useMemo, useRef} from "react";
import {Button, FormCheck, Table} from "react-bootstrap";
import {useIntl} from "react-intl";
import MessageTemplateFormModal, {messageTemplateFormModalId} from "../../component/setting/MessageTemplateFormModal";
import {useParams} from "react-router-dom";
import IconButton from "../../component/StyledButton";
import {useDialog} from "../../hook/useDialog";
import messageTemplateRepository from "../../repository/MessageTemplateRepository";
import useModal from "../../hook/useModal";
import {FileMessage, MessageTemplate, TextMessage} from "../../model/message";
import SettingViewWrapper from "../../component/setting/SettingViewWrapper";
import useManager from "../../query/manager/useManager";
import useMessageTemplates from "../../query/message/useMessageTemplates";
import {useQueryClient} from "@tanstack/react-query";
import toast from "react-hot-toast";

const MessageTemplateManagementView: React.FC = () => {
    const modal = useModal();
    const intl = useIntl();

    const {channelId} = useParams<{channelId: string}>();

    if (!channelId) {
        throw new Error('Required channelId path params');
    }

    const {data: manager} = useManager(channelId);
    const {data: messageTemplates, status: messageTemplatesStatus} = useMessageTemplates(channelId);

    // const [showTemplateForm, setShowTemplateForm] = useState<{show: boolean, data?: MessageTemplate}>({show: false, data: undefined});

    const onClickTemplateAddBtn = (e: React.MouseEvent) => {
        e.preventDefault();
        modal.open('message-template-form-modal');
        // setShowTemplateForm({show: true, data: undefined});
    };

    return (
        <SettingViewWrapper>
            <div className="setting-title">
                <h3>{intl.formatMessage({id: 'i000027'})}</h3>
                <p>
                    {intl.formatMessage({id: 'i000026'})}
                </p>
                <Button variant="info" type="button" className="btn-rounded" onClick={onClickTemplateAddBtn}>
                    {intl.formatMessage({id: 'i000028'})}
                </Button>
            </div>

            <div className="w-100">
                <Table size="sm" className="table-centered">
                    <colgroup>
                        <col width={'250px'}/>
                        <col width={'*'}/>
                        <col width={'50px'}/>
                        <col width={'50px'}/>
                        <col width={'50px'}/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th>{intl.formatMessage({id: 'i000020'})}</th>
                        <th>{intl.formatMessage({id: 'i000021'})}</th>
                        <th>{intl.formatMessage({id: 'i000181'})}</th>
                        <th className="text-center">{intl.formatMessage({id: 'i000029'})}</th>
                        <th className="text-center">{intl.formatMessage({id: 'i000034'})}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {messageTemplates?.filter(template => template.userId === manager?.userId)
                        .map(template => (
                            <TemplateRow key={template._id} channelId={channelId} template={template} />
                        ))
                    }
                    {(messageTemplatesStatus === 'success' && (!messageTemplates || messageTemplates.length < 1)) && (
                        <tr>
                            <td colSpan={5} className="text-muted">
                                {intl.formatMessage({id: 'i000025'})}
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>

            <MessageTemplateFormModal channelId={channelId}  />
        </SettingViewWrapper>
    );
};

const TemplateRow: React.FC<TemplateRowProps> = ({channelId, template}) => {
    const modal = useModal();
    const dialog = useDialog();
    const intl = useIntl();

    const publicCheckboxRef = useRef<HTMLInputElement>(null);

    const queryClient = useQueryClient();

    const blockPreview = useMemo(() => {
        if (template.blocks.length > 0) {
            const block = template.blocks[0];
            if (block.type === 'text') {
                return (block.value as TextMessage).text;
            }
            else if (block.type === 'file') {
                return (block.value as FileMessage).filename;
            }
        }

        return intl.formatMessage({id: 'i000326'});
    }, [template, intl]);

    const onClickToPublic = async (e: React.MouseEvent) => {
        e.stopPropagation();

        if (!publicCheckboxRef.current) {
            return;
        }

        const checkedPublic = publicCheckboxRef.current.checked;
        try {
            await messageTemplateRepository.update(channelId, template._id, template.name, template.blocks, checkedPublic);
            await queryClient.invalidateQueries(['messageTemplates', channelId], {exact: true});

            toast.success(intl.formatMessage({id: 'i000183'}));
        } catch (_) {
            toast.error(intl.formatMessage({id: 'i000184'}));
        }
    };

    const onClickEditBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        modal.open(messageTemplateFormModalId, template);
    };

    const onClickDeleteBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        dialog.open({
            content: intl.formatMessage({id: 'i000033'}),
            variant: 'danger',
            confirmText: intl.formatMessage({id: 'i000034'}),
            cancelText: intl.formatMessage({id: 'i000012'}),
            onConfirm: async () => {
                try {
                    await messageTemplateRepository.delete(channelId, template._id);
                } catch (e) {
                    toast.error(intl.formatMessage({id: 'i000108'}))
                }

                await queryClient.invalidateQueries(['messageTemplates', channelId], {exact: true});
            }
        })
    };

    return (
        <tr>
            <td><strong className="text-muted">{'/'}</strong>{template.name}</td>
            <td style={{maxWidth: 'calc(100vw - 700px)'}}>
                <p className="text-truncate mb-0">{blockPreview}</p>
            </td>
            <td className="text-center">
                <FormCheck id={`template-public-check-${template._id}`}
                           defaultChecked={template.public}
                           label={''}
                           onClick={onClickToPublic}
                           ref={publicCheckboxRef} />
            </td>
            <td className="text-center">
                <IconButton onClick={onClickEditBtn}><i className="mdi mdi-square-edit-outline" /></IconButton>
            </td>
            <td className="text-center">
                <IconButton onClick={onClickDeleteBtn}><i className="mdi mdi-trash-can-outline" /></IconButton>
            </td>
        </tr>
    );
};


type TemplateRowProps = {
    channelId: string;
    template: MessageTemplate;
};

export default MessageTemplateManagementView;
