import React, {Dispatch, SetStateAction, useCallback, useMemo, useRef, useState} from "react";
import {Dropdown, Form} from "react-bootstrap";
import {FilterSelectionItemStyle} from "./ChatSearchFilterStyle";
import {useClickOutside} from "@react-hookz/web";
import useChatUsers from "../../query/chatUser/useChatUsers";
import {ChatUser} from "../../model/chatUser";
import Avatar from "../Avatar";
import FullAreaSpinner from "../FullAreaSpinner";
import {UnStyledButton} from "../UnstyledBootstrap";
import ChatSearchFilterToggle from "./ChatSearchFilterToggle";
import ChatSearchFilterDropdownMenu from "./ChatSearchFilterDropdownMenu";
import {FilterResultOperation} from "../../model/chatSearch";
import {useIntl} from "react-intl";

type ChatSearchUserFilterProps = {
    channelId: string
    selectedChatUsers: ChatUser[]
    setSelectedChatUsers: Dispatch<SetStateAction<ChatUser[]>>
}

const ChatSearchUserFilter : React.FC<ChatSearchUserFilterProps> = (props) => {
    const { channelId, selectedChatUsers, setSelectedChatUsers } = props;
    const intl = useIntl();

    const [showPopup, setShowPopup] = useState(false);
    const [filterSearch, setFilterSearch] = useState("");

    const filterToggleRef = useRef<HTMLDivElement>(null);
    const dropDownMenuRef = useRef<HTMLDivElement>(null);

    const {
        data: chatUsers,
        hasNextPage: hasNextChatUsers,
        fetchNextPage: fetchNextChatUsers,
        isLoading: isLoadingChatUsers
    } = useChatUsers(channelId, filterSearch);


    const deselectedChatUsers = useMemo<ChatUser[]>(() => {
        return chatUsers?.pages.map(page => (
            page.result.filter(chatUser => selectedChatUsers.every(user => user._id !== chatUser._id))
        )).flat() ?? []
    }, [chatUsers?.pages, selectedChatUsers])

    const wrapperSetFilterSearch = useCallback((value: string) => {
        setFilterSearch(value)
    }, [setFilterSearch])

    const onClickOutside = useCallback((e: Event) => {
        if (filterToggleRef.current && !e.composedPath().includes(filterToggleRef.current)) {
            setShowPopup(false);
        }
    }, [])

    useClickOutside(dropDownMenuRef, (e) => {
        onClickOutside(e);
    })

    const onClickMoreChatUsers = useCallback(async () => {
        if (hasNextChatUsers) {
            await fetchNextChatUsers();
        }
    }, [hasNextChatUsers, fetchNextChatUsers])

    const onClickFilterSelect = (chatUser: ChatUser, operation: FilterResultOperation) => {
        if (operation === FilterResultOperation.ADD) {
            setSelectedChatUsers(prev => [...prev, chatUser]);
        } else if (operation === FilterResultOperation.REMOVE) {
            setSelectedChatUsers(prev => prev.filter(user => user._id !== chatUser._id));
        }
    }

    return (
        <Dropdown show={showPopup} style={{marginRight: "1rem"}}>
            <ChatSearchFilterToggle showPopup={showPopup}
                                    updateShowPopup={() => setShowPopup(prev => !prev)}
                                    label={intl.formatMessage({id: "i210018"})}
                                    toggleRef={filterToggleRef}
                                    selectedLength={selectedChatUsers.length}/>

            <ChatSearchFilterDropdownMenu showPopup={showPopup}
                                          setFilterSearch={wrapperSetFilterSearch}
                                          dropDownMenuRef={dropDownMenuRef}>
                {selectedChatUsers.map((chatUser, index) => (
                    <FilterSelectionItemStyle key={`added-${index}`}
                                              onClick={() => onClickFilterSelect(chatUser, FilterResultOperation.REMOVE)}>
                        <Form.Check id={`filter-select-added-${index}`}
                                    checked={true}
                                    readOnly={true}/>
                        <div className="item-info">
                            <Avatar size={20} name={chatUser.name ?? chatUser.alias}/>
                            <div className="name">
                                {chatUser.name ?? chatUser.alias}
                            </div>
                        </div>
                    </FilterSelectionItemStyle>
                ))}

                {selectedChatUsers.length > 1 &&
                    <div className="cursor-pointer text-primary font-12"
                         style={{padding: "0.25rem 1.5rem 0 1.5rem", width: "fit-content"}}
                         onClick={() => setSelectedChatUsers([])}>
                        {intl.formatMessage({id: "i210021"})}
                    </div>
                }

                {selectedChatUsers.length > 0 && <hr className="my-2"/>}

                {isLoadingChatUsers ?
                    <FullAreaSpinner/> :
                    <>
                        {deselectedChatUsers.map((chatUser, index) => (
                            <FilterSelectionItemStyle key={index}
                                                      onClick={() => onClickFilterSelect(chatUser, FilterResultOperation.ADD)}>
                                <Form.Check id={`filter-select-${index}`}
                                            checked={false}
                                            readOnly={true}/>
                                <div className="item-info">
                                    <Avatar size={20} name={chatUser.name ?? chatUser.alias}/>
                                    <div className="name">
                                        {chatUser.name ?? chatUser.alias}
                                    </div>
                                </div>
                            </FilterSelectionItemStyle>
                        ))}
                        {hasNextChatUsers &&
                            <div className="d-grid mt-1">
                                <UnStyledButton className="text-center py-0" onClick={onClickMoreChatUsers}>
                                    <i className="font-18 mdi mdi-chevron-down"/>
                                </UnStyledButton>
                            </div>
                        }
                    </>
                }

                {chatUsers && !chatUsers.pages?.[0]?.result.length &&
                    <div className="text-muted mx-3 font-14">{intl.formatMessage({id: "i210022"})}</div>
                }
            </ChatSearchFilterDropdownMenu>
        </Dropdown>
    )
};

export default ChatSearchUserFilter;
