import React, {useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {Badge, Button, Card, Col, Form, InputGroup, Row} from "react-bootstrap";
import {useDialog} from "../../hook/useDialog";
import channelRepository from "../../repository/ChannelRepository";
import {useParams} from "react-router-dom";
import SettingViewWrapper from "../../component/setting/SettingViewWrapper";
import useChannel from "../../query/channel/useChannel";
import useUpdateChannel from "../../query/channel/useUpdateChannel";
import toast from "react-hot-toast";

const BannedWordConfigView: React.FC = () => {
    const dialog = useDialog();
    const intl = useIntl();

    const {channelId} = useParams<{channelId: string}>();

    if (!channelId) {
        throw new Error('Required channelId path params')
    }

    const [searchText, setSearchText] = useState('');

    const {data: channel, refetch: refetchChannel} = useChannel(channelId);
    const {mutate: updateChannel} = useUpdateChannel(channelId);
    const [disableButtons, setDisableButtons] = useState(false)

    const bannedWordRef = useRef<HTMLInputElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const validateManagerBannedWordsRef = useRef<HTMLInputElement>(null)

    const bannedWords = useMemo(() => {
        if (searchText) {
            return channel?.config.bannedWords.filter(bannedWord => bannedWord.includes(searchText)) || [];
        }
        else {
            return channel?.config.bannedWords || [];
        }
    }, [channel, searchText]);

    const onSearch = (e: React.FormEvent) => {
        e.preventDefault();

        setSearchText(searchInputRef.current?.value || '');
    };

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!channel) return;
        if (!bannedWordRef.current?.value) return;

        const form = {
            ...channel,
            config: {
                ...channel?.config,
                bannedWords: [...channel.config.bannedWords, bannedWordRef.current.value]
            }
        };

        setDisableButtons(true)
        updateChannel(form, {
            onSuccess: (_) => {
                if (bannedWordRef.current) {
                    bannedWordRef.current.value = '';
                }
                setDisableButtons(false)
            },
            onError: (_) => {
                toast.error(intl.formatMessage({id: 'i000219'}));
                setDisableButtons(false)
            },
        });
    }

    const onDeleteBannedWord = (bannedWord: string) => {
        if (!channel) return;

        dialog.open({
            title: intl.formatMessage({id: 'i000145'}),
            content: intl.formatMessage({id: 'i000218'}),
            variant: 'danger',
            onConfirm: () => {
                const form = {
                    ...channel,
                    config: {
                        ...channel.config,
                        bannedWords: channel?.config.bannedWords.filter(word => bannedWord !== word) || []
                    }
                };

                updateChannel(form);
            }
        });
    };

    const onClickApplyTemplate = (e: React.MouseEvent) => {
        e.preventDefault();

        dialog.open({
            title: intl.formatMessage({id: 'i000147'}),
            content: intl.formatMessage({id: 'i100094'}),
            variant: 'primary',
            onConfirm: async () => {
                try {
                    await channelRepository.applyBannedWordsTemplate(channelId);
                    await refetchChannel();
                } catch (e) {
                    toast.error(intl.formatMessage({id: 'e000001'}));
                }
            }
        });
    }

    const onChangeValidateManagerBannedWords = async (_: React.ChangeEvent) => {

        if (!validateManagerBannedWordsRef.current || !channel) return;

        const form = {
            ...channel,
            config: {
                ...channel.config,
                validateManagerBannedWords: validateManagerBannedWordsRef.current.checked
            }
        };

        updateChannel(form);
    };

    const onClickReset = () => {
        if (!channel) return;

        dialog.open({
            title: intl.formatMessage({id: 'i100091'}),
            content: intl.formatMessage({id: 'i100092'}),
            variant: 'danger',
            onConfirm: () => {
                const form = {
                    ...channel,
                    config: {
                        ...channel.config,
                        bannedWords: []
                    }
                };

                updateChannel(form);
            }
        });
    };

    return (
        <SettingViewWrapper>
            <div className="setting-title">
                <h3>{intl.formatMessage({id: 'i000142'})}</h3>
                <p>
                    {intl.formatMessage({id: 'i000143'})}
                </p>
            </div>

            <div className='d-flex align-items-center mb-2'>
                <Form onSubmit={onSubmit} className="flex-grow-1 mr-1">
                    <Form.Group as={Row}>
                        <Form.Label column xs={'auto'}>{intl.formatMessage({id: 'i000144'})}</Form.Label>
                        <Col>
                            <InputGroup>
                                <Form.Control type={'text'} ref={bannedWordRef} />
                                <Button type="submit">{intl.formatMessage({id: 'i000023'})}</Button>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                </Form>

                <Button variant="primary" type="button" className='mr-1' onClick={onClickApplyTemplate}>
                    {intl.formatMessage({id: 'i000147'})}
                </Button>

                <Button variant="outline-danger" type="button" onClick={onClickReset}>
                    {intl.formatMessage({id: 'i000233'})}
                </Button>

            </div>

            <Card border={'info'} className="shadow-none border" >
                <Card.Body className="p-2">
                    <Form onSubmit={onSearch} className="float-right">
                        <InputGroup size="sm" className="float-right mb-1" style={{width: 240}}>
                            <Form.Control type="text" placeholder="Search..." ref={searchInputRef} />
                            <Button type="submit"><i className="mdi mdi-magnify" /></Button>
                        </InputGroup>
                    </Form>

                    {bannedWords.map(bannedWord => (
                        <BannedWordFragment key={bannedWord} bannedWord={bannedWord} onDelete={onDeleteBannedWord} />
                    ))}
                    {bannedWords.length < 1 && (
                        <Card.Text className="mt-1">{intl.formatMessage({id: 'i000304'})}</Card.Text>
                    )}
                </Card.Body>
            </Card>

            <Form.Check type="checkbox"
                        className='text-secondary'
                        id="validate-manager-banned-words"
                        disabled={disableButtons}
                        ref={validateManagerBannedWordsRef}
                        label={intl.formatMessage({id: 'i100093'})}
                        onChange={onChangeValidateManagerBannedWords}
                        defaultChecked={channel?.config.validateManagerBannedWords}
            />

        </SettingViewWrapper>
    );
};

const BannedWordFragment: React.FC<BannedWordFragmentProps> = ({bannedWord, onDelete}) => {
    const onClickBannedWord = (e: React.MouseEvent) => {
        e.preventDefault();

        onDelete(bannedWord);
    }
    return (
        <Badge bg="secondary" className="mr-1 cursor-pointer p-1 mb-1" onClick={onClickBannedWord}>{bannedWord} x</Badge>
    );
};

type BannedWordFragmentProps = {
    bannedWord: string;
    onDelete: (bannedWord: string) => void;
}

export default BannedWordConfigView;
