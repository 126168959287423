import React, {useMemo, useState} from 'react'
import FullCalendar from '@fullcalendar/react'
import {useIntl} from "react-intl";
import {calendarSchedulerModalId} from "./WorkingTimeCalendarModal";
import useModal from "../../hook/useModal";
import {Table} from "react-bootstrap";
import styled from "styled-components";
import useChannel from "../../query/channel/useChannel";
import toast from "react-hot-toast";
import useUpdateWorkingTimes from "../../query/channel/useUpdateWorkingTimes";
import IconButton from "../StyledButton";
import {useDialog} from "../../hook/useDialog";
import moment from "moment-timezone";

export interface CalendarSchedulerProps {
    channelId: string
    calendarRef: FullCalendar
    displayYear: number
}

interface HolidayItem {
    holidayId: string;
    title: string;
    recurringAnnually: boolean;
    clientInfoMessage?: string;
    start: Date
    end: Date
}

export const WorkingTimeHolidayList: React.FC<CalendarSchedulerProps> = (props) => {
    const {channelId, calendarRef, displayYear} = props
    const intl = useIntl();
    const modal = useModal();
    const dialog = useDialog();

    const {data: channel} = useChannel(channelId);
    const {mutateAsync: updateWorkingTimes, isLoading} = useUpdateWorkingTimes(channelId);

    const [sortingOrder, setSortingOrder] = useState<{date: boolean, recurring?: boolean}>({date: true})

    const displayHolidays: HolidayItem[] = useMemo(() => {

        if (!channel) return []

        const holidayItems: HolidayItem[] = channel.config.workingHoliday.holidays.map((holiday) => {
            return {
                ...holiday,
                start: moment.utc(`${holiday.start}Z`).tz(channel.config.timezone).toDate(),
                end: moment.utc(`${holiday.end}Z`).tz(channel.config.timezone).toDate()}
        })

        const startOfYear = new Date(displayYear, 0, 1)
        const endOfYear = new Date(displayYear + 1, 0, 1)

        return holidayItems
            .filter((holiday) =>
                (startOfYear <= holiday.start && holiday.start < endOfYear) ||
                (startOfYear <= holiday.end && holiday.end < endOfYear))
            .sort((a,b) => {
                const timeDifference = (a.start.getTime() - b.start.getTime()) * (sortingOrder.date ? 1 : -1)

                if (sortingOrder.recurring == null) {
                    return timeDifference
                }

                const recurringDifference = ((b.recurringAnnually ? 1 : 0) - (a.recurringAnnually ? 1 : 0)) *  (sortingOrder.recurring ? 1 : -1)
                return recurringDifference || timeDifference
        })

    }, [sortingOrder, displayYear, channel])

    const editHoliday = (holidayItem: HolidayItem) => {
        if (!channel) return

        const holiday = channel.config.workingHoliday.holidays.find((holiday) => holiday.holidayId === holidayItem.holidayId)
        if (holiday) {
            modal.open(
                calendarSchedulerModalId,
                {
                    start: holidayItem.start,
                    end: holidayItem.end,
                    holiday: holiday,
                    eventId: holidayItem.holidayId
                }
            )
        }

    };

    const close = () => modal.close(calendarSchedulerModalId);

    const deleteEvent = async (eventId: string) => {
        if (!channel) return;

        const event = calendarRef.getApi().getEventById(eventId)
        if (!event) return

        const holidays = channel.config.workingHoliday.holidays
            .filter((holiday) => holiday.holidayId !== event.id)

        await toast.promise(
            updateWorkingTimes({workingHolidayConfig: {active: channel.config.workingHoliday.active, holidays}}),
            {
                loading: intl.formatMessage(({id: 'i200003'})),
                success: () => {
                    event.remove()
                    close()
                    return intl.formatMessage(({id: 'i100083'}));
                },
                error: () => {
                    return intl.formatMessage(({id: 'e000001'}))
                }
            }
        )
    }

    const onClickTrash = (eventId: string) => {
        dialog.open({
            title: intl.formatMessage({id: 'i000034'}),
            content: intl.formatMessage({id: 'i100086'}),
            variant: 'danger',
            onConfirm: async () => await deleteEvent(eventId)
        });
    }

    const displayRange = (holiday: HolidayItem) => {
        if (!channel) return ''

        const startText = moment(holiday.start).tz(channel.config.timezone).format("YYYY-MM-DD")
        const endText = moment(holiday.end).tz(channel.config.timezone).subtract(1, 'days').local().format("YYYY-MM-DD")

        return `${startText} ~ ${endText}`
    }

    const toggleDateSortingOrder = () => setSortingOrder({date: !sortingOrder.date})

    const toggleRecurringSortingOrder = () => setSortingOrder({
            date: sortingOrder.date,
            recurring: sortingOrder.recurring != null ? !sortingOrder.recurring : true
    })


    return (
        <WorkingTimeHolidayListWrapper className='pt-2'>
            <h5 className='mb-2'>{intl.formatMessage({id: 'i100085'}, {year: displayYear})}</h5>

            {displayHolidays.length === 0 &&  <p className='pl-1'>{intl.formatMessage({id: 'i100088'})}</p>}

            {displayHolidays.length > 0 &&
                <Table size="sm" className="table-centered">
                    <colgroup>
                        <col width={'20%'}/>
                        <col width={'60%'}/>
                        <col width={'10%'}/>
                        <col width={'10%'}/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th className="text-center">
                            <div className='d-flex justify-content-center align-items-center'>
                                <p className='mb-0 mr-1'>{intl.formatMessage({id: 'i100065'})}</p>
                                <IconButton onClick={toggleDateSortingOrder}>
                                    <i className={`uil uil-angle-${sortingOrder.date ? 'down' : 'up'}`} />
                                </IconButton>
                            </div>
                        </th>
                        <th>{intl.formatMessage({id: 'i100071'})}</th>
                        <th className="text-center">
                            <div className='d-flex justify-content-center align-items-center'>
                                <p className='mb-0 mr-1'>{intl.formatMessage({id: 'i100084'})}</p>
                                <IconButton onClick={toggleRecurringSortingOrder}>
                                    <i className={`uil uil-angle-${sortingOrder.recurring ? 'up' : 'down'}`} />
                                </IconButton>
                            </div>
                        </th>
                        <th className="text-center"/>
                    </tr>
                    </thead>
                    <tbody>
                    {displayHolidays.map((holiday, index) => (
                        <tr key={`wk-holiday-list-item-${index}`} className='wk-holiday-list-item'>
                            <td className="text-center">
                                {displayRange(holiday)}
                            </td>
                            <td>
                                <p className='mb-0'>{holiday.title}</p>
                            </td>
                            <td className="text-center">
                                {holiday.recurringAnnually ? 'O' : '-'}
                            </td>
                            <td className="text-center">
                                <IconButton disabled={isLoading} onClick={() => editHoliday(holiday)}>
                                    <i className="mdi mdi-square-edit-outline" />
                                </IconButton>
                                <IconButton disabled={isLoading} onClick={() => onClickTrash(holiday.holidayId)}>
                                    <i className="mdi mdi-trash-can-outline" />
                                </IconButton>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            }

        </WorkingTimeHolidayListWrapper>
    );
};

const WorkingTimeHolidayListWrapper = styled.div `
  
  .table {
    display: block;
    table-layout: fixed;
    word-break: break-word;
  }

  tbody {
    display: block;
    max-height: 500px;
    overflow: auto;
  }

  thead {
    border-top: unset !important;
    border-top: unset !important;
  }
  
  td {
    border-top: unset;
  }
  
  thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;/* even columns width , fix width of table too*/
  }

  th:nth-child(1),td:nth-child(1) {
    width: 20%;
  }
  
  th:nth-child(3),th:nth-child(4),td:nth-child(3),td:nth-child(4) {
    width: 10%;
  }

  th:nth-child(2),td:nth-child(2) {
    width: 60%;
  }
  
`