import React from "react";
import styled from "styled-components";
import classNames from "classnames";

const IconButton: React.FC<IconButtonProps> = (props) => {
    const {children, type, onClick, className, disabled} = props;

    return (
        <IconButtonStyle type={type || 'button'}
                         className={classNames('btn shadow-none', {[className || '']: className})}
                         onClick={onClick}
                         disabled={disabled}>
            {children}
        </IconButtonStyle>
    );

};


const IconButtonStyle = styled.button<{disabled?: boolean}>`
    
  font-size: 1.2rem;
  display: inline-block;
  padding: 0 3px;
  ${props => props.disabled === true ? 'color: #bacadf;' : 'color: #98a6ad;'}
`;

type IconButtonProps = {
    type?: 'button'|'submit';
    onClick?: (e: React.MouseEvent) => void;
    className?: string;
    disabled?: boolean;
    children?: React.ReactNode
};

export default IconButton;


