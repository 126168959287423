import React, {useCallback, useDeferredValue, useEffect, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {FileMessage, Message, MessageBlock, MessageForm} from "../model/message";
import messageRepository from "../repository/MessageRepository";
import {FileGroup} from "../model/fileGroup";
import moment from "moment";
import {Badge, Button, Form, Spinner} from "react-bootstrap";
import {LiveChat} from "../model/livechat";
import _ from "lodash";
import {
    Autocomplete,
    AutocompleteItem,
    Content,
    Editor,
    EditorElement,
    EditorRef,
    FileInfo
} from "@42maru/content-editor";
import {ReactComponent as MessageTemplateIcon} from "../assets/images/message_template_icon.svg";
import {toJS} from "mobx";
import checkAxiosError from "../util/checkAxiosError";
import {isMobile} from "react-device-detect";
import useManager from "../query/manager/useManager";
import useChannel from "../query/channel/useChannel";
import useMessageTemplates from "../query/message/useMessageTemplates";
import {SocketEvent, useSocket} from "../socket";
import {useSetRecoilState} from "recoil";
import outgoingMessagesState from "../recoil/outgoingMessages";
import toast from "react-hot-toast";
import {isSupportedFileExtension, isSupportedFileSize} from "../util/fileUtil";
import {useLocalStorageValue} from "@react-hookz/web";
import {DISABLE_CHAT_WITH_ENTER_KEY} from "../core/variables";
import useUserAttributes from "../query/useUserAttributes";
import {renderContentWithChatUser} from "../util/messageUtil";

const MessageTextarea: React.FC<{liveChat: LiveChat}> = ({liveChat}) => {
    // const {recommendedAnswerStore} = useStores();
    const intl = useIntl();

    const socket = useSocket();
    // const queryClient = useQueryClient();

    const [uploading, setUploading] = useState(false);
    const [hasValue, setHasValue] = useState(false);

    const setOutgoingMessages = useSetRecoilState(outgoingMessagesState);

    const [disableEnterKey, setDisableEnterKey] = useLocalStorageValue<boolean>(DISABLE_CHAT_WITH_ENTER_KEY, false, {storeDefaultValue: true});

    const deferredHasValue = useDeferredValue(hasValue);

    const {data: channel} = useChannel(liveChat.channelId);
    const {data: manager} = useManager(liveChat.channelId);
    const {data: messageTemplates} = useMessageTemplates(liveChat.channelId);

    const {data: userAttributes} = useUserAttributes(liveChat.channelId)

    const userEditorVariables = useMemo(() => {
        return userAttributes?.map(attribute => ({
            key: attribute.name,
            label: attribute.description
        })) ?? [];
    }, [userAttributes]);

    // const reloadRecommendedAnswersRef = useRef<boolean>(true);
    const editorRef = useRef<EditorRef>(null);
    const typingRef = useRef<{value?: string}>({value: undefined});

    // const emojiI18n = useMemo(() => {
    //     return {
    //         search: intl.formatMessage({id: 'i000146'}),
    //         clear: intl.formatMessage({id: 'i000233'}),
    //         notfound: intl.formatMessage({id: 'i000234'}),
    //         categories: {
    //             search: intl.formatMessage({id: 'i000235'}),
    //             recent: intl.formatMessage({id: 'i000236'}),
    //             smileys: intl.formatMessage({id: 'i000237'}),
    //             people: intl.formatMessage({id: 'i000238'}),
    //             nature: intl.formatMessage({id: 'i000239'}),
    //             foods: intl.formatMessage({id: 'i000240'}),
    //             activity: intl.formatMessage({id: 'i000241'}),
    //             places: intl.formatMessage({id: 'i000242'}),
    //             objects: intl.formatMessage({id: 'i000243'}),
    //             symbols: intl.formatMessage({id: 'i000244'}),
    //             flags: intl.formatMessage({id: 'i000245'}),
    //             custom: 'Custom',
    //         },
    //         categorieslabel: intl.formatMessage({id: 'i000246'})
    //     }
    // }, [intl]);

    const typing = useCallback((liveChatId: string, action: 'on'|'off') => {
        socket.send(SocketEvent.TYPING, {
            action: action,
            liveChatId: liveChatId,
            senderType: 'manager',
            userId: manager?.userId
        });
    }, [manager, socket]);

    const clearTyping = useCallback((liveChatId: string) => {
        if (typingRef.current.value) {
            typing(liveChatId, 'off');
        }
    }, [typing]);

    const checkTyping = useMemo(() => _.throttle((liveChatId: string, checkValue?: string) => {
        if (checkValue === undefined) {
            return;
        }

        if (checkValue) {
            typing(liveChatId, 'on');
        }
        else {
            typing(liveChatId, 'off');
        }

    }, 2000), [typing]);

    useEffect(() => {
        editorRef.current?.clear();
        typingRef.current.value = undefined;

        return () => {
            checkTyping.cancel();
            clearTyping(liveChat._id);
        }
    }, [liveChat._id, clearTyping, checkTyping]);

    // useEffect(() => {
    //     const observer = new QueryObserver(queryClient, {queryKey: ['messages', liveChat.channelId, liveChat._id]});
    //     const unsubscribe = observer.subscribe(_ => {
    //         reloadRecommendedAnswersRef.current = true;
    //     });
    //
    //     return () => {
    //         reloadRecommendedAnswersRef.current = true;
    //         unsubscribe();
    //     }
    // }, [queryClient, liveChat]);


    const sendMessage = async (contents: Content[], files: FileInfo[]) => {
        if (!liveChat) return;
        if (!manager) return;
        if (!channel) return;
        if (!deferredHasValue) return;

        const blocks = [];

        const {text, html} = renderContentWithChatUser(contents, liveChat.user);
        if (!text && files.length < 1) {
            return;
        }

        if (text) {
            blocks.push({
                type: 'text',
                value: {
                    text: text.trim(),
                    html: html.trim()
                },
                meta: contents.map(content => content.meta)
            });
        }

        files.forEach(file => {
            blocks.push({
                type: 'file',
                value: {
                    filename: file.filename,
                    url: file.url,
                    size: file.size,
                    contentType: file.contentType
                }
            });
        });

        const currentUtcTime = moment.utc().format("YYYY-MM-DD HH:mm:ss.SSSSS");
        const sendId = `${Date.now()}`;
        const messageForm: MessageForm = {
            senderType: 'manager',
            senderUserId: manager.userId,
            blocks: blocks,
            createdAt: currentUtcTime,
            sendId: sendId
        };

        socket.send(SocketEvent.MESSAGE, {liveChatId: liveChat._id, message: messageForm});

        editorRef.current?.clear();
        editorRef.current?.focus();

        const outgoingMessage: Message = {
            _id: sendId,
            channelId: channel._id,
            liveChatId: liveChat._id,
            blocks: blocks,
            sender: {
                userId: manager.userId,
                name: manager.name,
                type: 'manager',
                alias: manager.name
            },
            createdAt: currentUtcTime,
            noBadge: true,
            sendId: sendId,
            shouldAck: true
        };

        setOutgoingMessages(prev => [...prev, outgoingMessage]);
    };

    const onClickSendBtn = async (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const contents = editorRef.current?.getContents() || [];
        const files = editorRef.current?.files || [];
        await sendMessage(contents, files);
    };

    const messageTemplateProvider = useCallback(async (token: string) => {
        // TODO: refresh message templates
        // const currentTime = new Date().getTime();
        // if (messageTemplateRefreshTimeRef.current && currentTime - messageTemplateRefreshTimeRef.current >= 30000) {
        //     await messageTemplateListStore.load(channel!._id, true);
        //     messageTemplateRefreshTimeRef.current = new Date().getTime();
        // }

        return messageTemplates?.filter(template => template.name.includes(token)).map(template => {
            let elements: EditorElement[] = [];
            template.blocks.forEach(block => {
                if (block.type === 'text') {
                    elements = elements.concat(toJS(block.meta) as EditorElement[]);
                }
            });
            return {
                ...template,
                key: template._id,
                elements: elements,
                // text: template.blocks.filter((block: MessageBlock) => block.type === 'text').map(block => (block.value as TextMessage).html).join(' '),
            }
        }) ?? [];
    }, [messageTemplates]);

    // const recommendedAnswerProvider = useCallback(async (token: string) => {
    //     if (reloadRecommendedAnswersRef.current) {
    //         reloadRecommendedAnswersRef.current = false;
    //         await recommendedAnswerStore.load(channel!._id, liveChat._id);
    //     }
    //     return recommendedAnswerStore.data.filter(answer => answer.includes(token)).map(answer => ({
    //         key: v4(),
    //         text: answer
    //     }));
    // }, [channel, liveChat, recommendedAnswerStore]);

    // const autocompletes = useMemo(() => {
    //     return [{
    //         trigger: '/',
    //         dataProvider: messageTemplateProvider,
    //         component: MessageTemplateItem,
    //         toolbarButton: MessageTemplateIcon,
    //         tooltip: intl.formatMessage({id: 'i000230'})
    //     }, {
    //         trigger: '#',
    //         dataProvider: recommendedAnswerProvider,
    //         component: RecommendedAnswerItem,
    //         toolbarButton: ConversationSuggestIcon,
    //         tooltip: intl.formatMessage({id: 'i000231'})
    //     }]
    // }, [messageTemplateProvider, recommendedAnswerProvider, intl]);

    const autocompletes = useMemo(() => {
        return [{
            trigger: '/',
            dataProvider: messageTemplateProvider,
            component: MessageTemplateItem,
            toolbarButton: MessageTemplateIcon,
            tooltip: intl.formatMessage({id: 'i000230'})
        }]
    }, [messageTemplateProvider, intl]);

    const onClickFile = async (file: File) => {
        if (!isSupportedFileSize(file)) {
            toast.error(intl.formatMessage({id: "i200076"}));
            return;
        }

        if (!isSupportedFileExtension(file)) {
            toast.error(intl.formatMessage({id: "i200077"}));
            return;
        }

        try {
            setUploading(true);
            const {data} = await messageRepository.uploadFile(liveChat.channelId, liveChat._id, file);
            setUploading(false);
            const fileGroup: FileGroup = data.result;
            const fileInfo = fileGroup.files[0];
            return {
                filename: fileInfo.originalFilename,
                url: fileInfo.url,
                size: fileInfo.size,
                contentType: fileInfo.contentType
            }
        } catch (e){
            setUploading(false);
            if (checkAxiosError(e, 'FIL_001')) {
                toast.error(intl.formatMessage({id: 'e000002'}));
            } else if (checkAxiosError(e, 'FIL_003')) {
                toast.error(intl.formatMessage({id: 'i200077'}))
            } else {
                toast.error(intl.formatMessage({id: 'e000003'}));
            }
        }
    };

    const onEnterEditor = async (contents: Content[], files: FileInfo[]) => {
        await sendMessage(contents, files);
    };

    const onChangeEditor = (contents: Content[], files?: FileInfo[]) => {
        const value = contents.map(content => content.value.text.trim()).join('');
        const prevValue = typingRef.current.value;

        setHasValue(!!value || !!files?.length);

        if (prevValue !== undefined || value) {
            typingRef.current.value = value;
        }

        if (typingRef.current.value !== undefined && prevValue !== value) {
            checkTyping(liveChat._id, value);
        }
    };

    const onSelectAutocomplete = (autocomplete: Autocomplete, item: AutocompleteItem) => {
        if (autocomplete.trigger === '/') {
            const files = item.blocks.filter((block: MessageBlock) => block.type === 'file').map((block: MessageBlock) => {
                const fileMessage = block.value as FileMessage;
                return {
                    filename: fileMessage.filename,
                    size: fileMessage.size,
                    url: fileMessage.url,
                    contentType: fileMessage.contentType
                }
            });

            editorRef.current?.setFiles([...editorRef.current?.files, ...files]);
        }
    }

    const onChangeDisableEnterKey = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDisableEnterKey(e.target.checked)
    }

    const newLineFunctionKey = useMemo(() => {
        if (isMobile) return undefined

        return disableEnterKey ? undefined : 'shift'
    }, [disableEnterKey])

    const enterFunctionKey = useMemo(() => {
        if (isMobile) return undefined

        return disableEnterKey ? 'shift' : undefined
    }, [disableEnterKey])

    const placeholder = useMemo(() => {

        let result = intl.formatMessage({id: "i000031"})

        if (!isMobile) {
            result += intl.formatMessage({id: "i000032"}, {key: newLineFunctionKey === 'shift' ? "(Shift + Enter)" : 'Enter'})
        }
        return result

    }, [intl, newLineFunctionKey])

    return (
        <Editor id="message-editor"
                placeholder={placeholder}
                maxHeight={150}
                extraToolbar={(
                    <div className="d-flex justify-content-end align-items-center">
                        {/*{!isMobile && <small className="text-muted mr-2 align-self-end">{intl.formatMessage({id: 'i000032'})}</small>}*/}

                        {!isMobile &&
                            <Form.Check type="checkbox"
                                        className='mr-2 d-flex align-items-center'
                                        label={
                                            <span className='ml-1 text-secondary text-muted small'>
                                                    {intl.formatMessage({id: 'i100098'})}
                                                </span>
                                        }
                                        id="validate-manager-banned-words"
                                        onChange={onChangeDisableEnterKey}
                                        defaultChecked={disableEnterKey}
                            />
                        }

                        <Button variant={deferredHasValue ? 'primary' : 'secondary'} size="sm" className="btn-rounded" onClick={onClickSendBtn} disabled={uploading || !deferredHasValue}>
                            {uploading && (
                                <Spinner size="sm" animation="border" className="mr-1" />
                            )}
                            {intl.formatMessage({id: 'i000030'})}
                        </Button>

                    </div>
                )}
                onClickFile={onClickFile}
                newLineFunctionKey={newLineFunctionKey}
                enterFunctionKey={enterFunctionKey}
                autocompletes={autocompletes}
                onEnter={onEnterEditor}
                onChange={onChangeEditor}
                onSelectAutocomplete={onSelectAutocomplete}
                ref={editorRef}
                variables={userEditorVariables}
        />
    );
};

const MessageTemplateItem: React.FC<{entity: any}> = ({entity}) => {
    const intl = useIntl();

    const {data: manager} = useManager(entity.channelId);

    const blockPreview = useMemo(() => {
        if (entity.blocks && entity.blocks.length > 0) {
            const block = entity.blocks[0];
            if (block.type === 'text') {
                return block.value.text;
            }
            else if (block.type === 'file') {
                return (block.value as FileMessage).filename;
            }
        }

        return 'Unknown';
    }, [entity]);

    return (
        <div className="p-1 d-flex align-items-center" style={{maxWidth: '600px'}}>
            <p className="mb-0 flex-shrink-0">{`/${entity.name}`}</p>
            <small className="mb-0 ml-1 text-muted text-truncate">{blockPreview}</small>
            {(entity.public && entity.userId !== manager?.userId) && (
                <Badge bg="outline-secondary" className="ml-1 font-10">
                    {intl.formatMessage({id: 'i000187'})}
                </Badge>
            )}
        </div>
    );
};

// const RecommendedAnswerItem: React.FC<{entity: any}> = ({entity}) => {
//     return (
//         <div className="p-1 d-flex align-items-center" style={{maxWidth: '600px'}}>
//             {/*<p className="mb-0 flex-shrink-0">{`/${entity.name}`}</p>*/}
//             <small className="mb-0 ml-1 text-muted text-truncate">{entity.text}</small>
//         </div>
//     );
// };


export default MessageTextarea;
