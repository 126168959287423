import styled from "styled-components";

const MessageBlockStyle = styled.div<{tracked?: boolean}>`
  display: flex;
  padding: 1px 3px;
  width: 100%;
  position: relative;
  //border-radius: 0 0.25rem 0.25rem 0;
  //transition: all 0.5s ease-in-out;

  &:hover {
    background-color: rgba(var(--ct-primary-rgb), 0.15) !important;
  }

  &.display-avatar {
    margin-top: 0.25rem;
    padding-top: 0.4rem;
    //padding-top: 0.7rem;

    .message-avatar {
      height: 35px !important;
    }

    .message-container {
      padding-left: 0 !important;
    }
  }

  &.user-message {
    box-shadow: -3px 0 0 0 #699cff;
    background-color: rgba(228, 237, 255, 0.7);
  }

  .message-avatar {
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    width: 35px !important;
  }

  .message-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    overflow: hidden;

    .message-header {
      margin: 0 0 0 5px;
      font-size: 0.9rem;
      line-height: 1.1rem;
      font-weight: bold;

      small {
        color: var(--color-muted);
      }
    }

    .message-container {
      width: 100%;
      display: flex;
      margin-left: 5px;
      //overflow: hidden;
      padding-left: 35px;
      padding-right: 5px;
      align-items: center;
      //position: relative;

      .message-content {
        display: inherit;
        //width: var(--message-content-width);
        //min-width: 500px;
        border-radius: 2px;
        flex-grow: 1;
        //overflow: hidden;
        //width: 100%;
        //position: relative;

        &:hover {
          .carousel-arrow-left, .carousel-arrow-right {
            display: block;
          }
        }

        button {
          &:disabled {
            cursor: not-allowed;
          }
        }

        .text-message {
          margin: 0;
          font-size: 0.9rem;
          line-height: 1.3rem;
          word-break: break-all;
          word-wrap: break-word;
          white-space: pre-wrap;
        }

        .list-message {
          border: 1px var(--message-border-color) solid;
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          background-color: #eeeeee;
          width: 260px;
          margin: 5px 0;

          button {
            &:not(:first-child) {
              border-top: 1px solid #c4c4c4;
            }

            font-size: 14px;
            color: #000975;
            border: none;
            padding: 10px 14px;
            text-align: left;
            border-radius: 0;
            margin: 0;
            outline: none !important;
          }
        }

        .image-message {
          border: 1px var(--media-message-border-color) solid;
          border-radius: 5px;
          width: 148px;
          height: 90px;
          overflow: hidden;

          //max-width: 300px;
          //min-width: 60px;
          //max-height: 300px;
          //min-height: 60px;
          
          cursor: zoom-in;

          img {
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            //max-width: 298px;
            //max-height: 298px;
            //min-width: 58px;
            //min-height: 58px;
            object-fit: cover;
            background-position: center;
            background-size: cover;
            border-radius: 5px;
          }
        }

        .carousel-arrow-left {
          display: none;
          position: relative;
          left: 15px;
          top: calc(50% - 15px);
          width: 0;
          height: 0;
          z-index: 1001;

          button {
            width: 30px;
            height: 30px;
            background-color: #ffffff;
            outline: none;
            //border: none;
            border: 1px solid #c4c4c4;
            border-radius: 50%;
            box-shadow: 0 0 35px 0 rgba(154, 161, 171, .15);
          }
        }

        .carousel-arrow-right {
          display: none;
          position: relative;
          right: 35px;
          top: calc(50% - 15px);
          width: 0;
          height: 0;
          z-index: 1001;

          button {
            width: 30px;
            height: 30px;
            background-color: #ffffff;
            outline: none;
            //border: none;
            border: 1px solid #c4c4c4;
            border-radius: 50%;
            box-shadow: 0 0 35px 0 rgba(154, 161, 171, .15);
          }
        }

        .carousel-message {
          margin: 5px 0;
          //padding: 1px;

          overflow-x: auto;
          display: flex;
          box-sizing: border-box;
          touch-action: pan-y;
          -webkit-tap-highlight-color: transparent;
          -ms-overflow-style: none;

          .carousel-message-item {
            border: 1px var(--message-border-color) solid;
            border-radius: 15px;
            outline: none;
            padding: 16px;
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
            flex-grow: 0;
            width: 260px;
            height: 100%;

            &:not(:last-child) {
              margin-right: 5px;
            }

            h5 {
              margin-top: 0;
            }

            .media {
              width: 258px;
              height: 145px;
              margin: -17px -16px 10px -16px;
              display: flex;
              -webkit-box-align: center !important;
              -ms-flex-align: center !important;
              align-items: center !important;
              overflow: hidden;
              outline: none;
              border-radius: 15px 15px 0 0;

              img {
                cursor: zoom-in;
                width: 100%;
                height: 145px;
                object-fit: cover;
                background-position: center;
                background-size: cover;
              }
            }

            .carousel-message-item-text {
              white-space: pre-wrap;
              word-break: break-word;

              p {
                margin: 0;
              }

              :not(:last-child) {
                margin-bottom: 6px;
              }
            }

            button {
              &:not(:last-child) {
                margin-bottom: 0.3rem;
              }

              text-align: left;
            }

          }

          .carousel-quick-reply {
            &:not(:last-child) {
              margin-right: 5px;
            }

            //border: 1px var(--message-border-color) solid;
            //border-radius: 15px;
            border: none;
            outline: none;
            padding: 0;
            display: flex;
            flex-shrink: 0;
            flex-grow: 0;
            //width: 260px;
            height: 100%;
          }
        }

        .carousel-message::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  .message-toolbar {
    display: none;
    position: absolute;
    right: 20px;
    top: -10px;
    z-index: 1000;
    background-color: #ffffff;
    align-self: center;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }

  &:hover {
    .message-toolbar {
      display: inline;
    }
  }

  &::-webkit-scrollbar {
    &:window-inactive {
      display: none;
    }
  }

  //&::-webkit-scrollbar-thumb {
  //  //border-radius: 42px;
  //  //background-color: red;
  //}

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  // highlight all tracked text
  span.tracked-substring {
    background-color: #F7E9BA;
  }
  
  @keyframes highlight {
    0% {background-color: #FAF5E3}
    75% {background-color: #FAF5E3}
    100% {background-color: white}
  }
  
  ${props => props.tracked && "animation: highlight 4s 1;"}

`;

export default MessageBlockStyle;