import React from "react";
import {Dropdown} from "react-bootstrap";
import styled from "styled-components";
import useLiveChatBulkActionHandler, {BulkActionItem} from "../../hook/useLiveChatBulkActionHandler";
import BulkLiveChatMessageModal from "./BulkLiveChatMessageModal";
import toast from "react-hot-toast";
import {useIntl} from "react-intl";

type ChatBulkActionDropdownProps = {
    channelId: string;
};

const ChatBulkActionDropdown: React.FC<ChatBulkActionDropdownProps> = (props) => {
    const { channelId } = props;

    const intl = useIntl();

    const { bulkActions, bulkLiveChatIds } = useLiveChatBulkActionHandler(channelId);

    const onClick = (e: React.MouseEvent, action: BulkActionItem) => {
        e.preventDefault();
        if (!bulkLiveChatIds.length) {
            toast.error(intl.formatMessage({id: "i210062"}));
            return;
        } else {
            action.handler();
        }
    };

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle id="chat-bulk-action-toggle" variant="outline-primary" as={ActionButton} bsPrefix="unused">
                    {intl.formatMessage({id: "i210070"})}
                    <i className=" mdi mdi-menu-down"/>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {bulkActions.map((action, index) => (
                        <Dropdown.Item key={index} onClick={(e) => onClick(e, action)}>
                            {action.label}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>

            <BulkLiveChatMessageModal channelId={channelId} />
        </>
    );
};

const ActionButton = styled.button`
  background-color: white;
  transition: background-color 0.1s linear;

  border: 1px solid #0D6EFD;
  border-radius: 5px;

  font-size: 14px;
  color: #0D6EFD;

  padding: 0.25rem 0.75rem;

  &:hover {
    background-color: #e7ecff;
    transition: background-color 0.1s linear;
  }
`;

export default ChatBulkActionDropdown;
