import React, {useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {Button, Form} from "react-bootstrap";
import managerRepository from "../../repository/ManagerRepository";
import {useParams} from "react-router-dom";
import SettingViewWrapper from "../../component/setting/SettingViewWrapper";
import useManager from "../../query/manager/useManager";
import {useQueryClient} from "@tanstack/react-query";
import toast from "react-hot-toast";
import styled from "styled-components";
import defaultAvatar from "../../assets/images/profile_placeholder_image.png"
import useUploadFiles from "../../query/fileGroup/useUploadFiles";
import {FileGroup} from "../../model/fileGroup";
import {isSupportedFileExtension, isSupportedFileSize} from "../../util/fileUtil";

const ManagerSettingView: React.FC = () => {
    const intl = useIntl();

    const {channelId} = useParams<{channelId: string}>();

    if (!channelId) {
        throw new Error('Required channelId path params')
    }

    const {data: manager} = useManager(channelId);
    const queryClient = useQueryClient();
    const {mutateAsync: uploadFile} = useUploadFiles()

    const [saving, setSaving] = useState(false);
    const [invalid, setInvalid] = useState({name: false});
    const [currentAvatar, setCurrentAvatar] = useState<string|undefined>('')
    const nameRef = useRef<HTMLInputElement>(null);

    const onClickDeleteAvatar = (e: React.MouseEvent) => {
        e.preventDefault()

        setCurrentAvatar(undefined)
    }

    const onClickUploadAvatar = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.currentTarget.files || e.currentTarget.files?.length === 0) return;

        const file = e.currentTarget.files[0];

        if (!isSupportedFileSize(file)) {
            toast.error(intl.formatMessage({id: "i200076"}));
            return;
        }

        if (!isSupportedFileExtension(file)) {
            toast.error(intl.formatMessage({id: "i200077"}));
            return;
        }

        try {
            const { data } = await uploadFile(file)
            const fileGroup: FileGroup = data.result
            setCurrentAvatar(fileGroup.files[0].url)
        } catch (e) {
            toast.error(intl.formatMessage({id: "i200001"}))
        } finally {
            e.target.files = null
        }
    }

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!manager) {
            return;
        }

        const name = nameRef.current?.value ?? '';
        setInvalid({name: !name});

        if (Object.values(invalid).some(value => value)) {
            return;
        }

        setSaving(true);

        await toast.promise(
            managerRepository.update(channelId, manager._id, {name, avatar: currentAvatar}),
            {
                loading: intl.formatMessage(({id: 'i200003'})),
                success: () => {
                    setSaving(false);
                    return intl.formatMessage({id: 'i200002'});
                },
                error: () => {
                    setSaving(false);
                    return intl.formatMessage({id: 'i000176'});
                }
            }
        )

        await queryClient.invalidateQueries(['manager', channelId], {exact: true});
        await queryClient.refetchQueries(['managers', channelId], {exact: true});
    };

    useEffect(() => {
        setCurrentAvatar(manager?.avatar)
    }, [manager])

    return (
        <SettingViewWrapper>
            <div className="setting-title">
                <h3>{intl.formatMessage({id: 'i000173'})}</h3>
                <p>
                    {intl.formatMessage({id: 'i000174'})}
                </p>
            </div>

            <Form onSubmit={onSubmit}>
                <div className="d-flex justify-content-start align-items-center mb-4">
                    <Form.Group>
                        <ProfileAvatarStyle isDefault={!currentAvatar}>
                            <div className="profile-avatar">
                                <img width={80} height={80}
                                     src={currentAvatar ?? defaultAvatar}
                                     alt="profile"/>
                            </div>
                            <div className="edit-profile-avatar">
                                <Form.Label htmlFor="profile-avatar-upload">
                                    <i className="mdi mdi-image-edit-outline edit"/>
                                </Form.Label>
                                <Form.Control id="profile-avatar-upload"
                                              type="file"
                                              accept=".png,.jpg,.jpeg"
                                              onChange={onClickUploadAvatar}
                                              className="d-none"/>
                                {currentAvatar &&
                                    <i className="mdi mdi-trash-can-outline delete ms-1"
                                       onClick={onClickDeleteAvatar}/>
                                }
                            </div>
                        </ProfileAvatarStyle>
                    </Form.Group>
                    <Form.Group className="ms-3">
                        <Form.Label>{intl.formatMessage({id: 'i000175'})}</Form.Label>
                        <Form.Control defaultValue={manager?.name || ''} ref={nameRef} isInvalid={invalid.name} />
                        <Form.Control.Feedback type={'invalid'}>{intl.formatMessage({id: 'i000024'})}</Form.Control.Feedback>
                    </Form.Group>
                </div>
                <Button type={'submit'} style={{borderRadius: "10px"}} disabled={saving}>
                    {intl.formatMessage({id: 'i000040'})}
                </Button>
            </Form>
        </SettingViewWrapper>
    );
};

const ProfileAvatarStyle = styled.div<{isDefault: boolean}>`
  position: relative;

  .profile-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0 0 6px 2px;

    img {
      object-fit: cover;
      border-radius: 20px;
    }
  }

  .profile-avatar:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.2s;
    border-radius: 20px;
  }

  .edit-profile-avatar {
    display: none;
  }

  &:hover {
    .profile-avatar:after {
      background-color: rgba(0, 0, 0, 0.5);
      transition: background-color 0.2s;
    }

    .edit-profile-avatar {
      display: flex;
      position: absolute;
      top: 22px;
      left: ${props => props.isDefault ? "28px" : "15px"};

      .edit, .delete {
        font-size: 24px;
        color: white;
        transition: color 0.2s;

        &:hover {
          cursor: pointer;
          color: #cecece;
          transition: color 0.2s;
        }
      }
    }
  }
`

export default ManagerSettingView;
