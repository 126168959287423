import React, {useCallback, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {Badge, Button, Form, Modal, Table} from "react-bootstrap";
import useModal, {MaruModal} from "../../hook/useModal";
import {useParams} from "react-router-dom";
import {Invitation, Member} from "../../model/member";
import {useDialog} from "../../hook/useDialog";
import {utcToLocal} from "../../util/datetime";
import moment from "moment";
import classNames from "classnames";
import SettingViewWrapper from "../../component/setting/SettingViewWrapper";
import useManager from "../../query/manager/useManager";
import useMembers from "../../query/member/useMembers";
import useDeleteMember from "../../query/member/useDeleteMember";
import useTooltip from "../../hook/useTooltip";
import useDeleteInvitation from "../../query/invitations/useDeleteInvitation";
import useInvitations from "../../query/invitations/useInvitations";
import useCreateInvitation from "../../query/invitations/useCreateInvitation";
import useRoles from "../../query/managementUnitRole/useRoles";
import SCFloatingLabel from "../../component/SCFloatingLabel";
import useUpdateMember from "../../query/member/useUpdateMember";
import FullAreaSpinner from "../../component/FullAreaSpinner";
import toast from "react-hot-toast";


const INVITATION_MODAL_ID = 'invitation-modal';


const MemberView: React.FC = () => {
    const intl = useIntl();
    const modal = useModal();

    const {channelId} = useParams<{channelId: string}>();

    if (!channelId) {
        throw new Error('Required channelId path params')
    }

    const {data: members, isSuccess: isSuccessMembers, status: membersStatus} = useMembers(channelId);
    const {data: invitations, status: invitationsStatus} = useInvitations(channelId);

    const onClickInviteBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        modal.open(INVITATION_MODAL_ID);
    };

    if (membersStatus === 'loading' || invitationsStatus === 'loading') {
        return (
            <FullAreaSpinner />
        );
    }
    else {
        return (
            <>
                <SettingViewWrapper>
                    <div className="setting-title">
                        <h3>{intl.formatMessage({id: 'i000148'})}</h3>
                        <p>
                            {intl.formatMessage({id: 'i000149'})}
                        </p>
                        <Button variant="info" type="button" className="btn-rounded" onClick={onClickInviteBtn}>
                            {intl.formatMessage({id: 'i000150'})}
                        </Button>
                    </div>

                    {(invitations && invitations.length > 0) && (
                        <>
                            <h5>{intl.formatMessage({id: 'i000257'})}</h5>
                            <Table style={{minWidth: '800px'}} className="table-centered">
                                <colgroup>
                                    <col width="250px" />
                                    <col width="*" />
                                    <col width="250px" />
                                    <col width="100px" />
                                    <col width="80px" />
                                </colgroup>
                                <thead>
                                <tr>
                                    <th>{intl.formatMessage({id: 'i000151'})}</th>
                                    <th>{intl.formatMessage({id: 'i000338'})}</th>
                                    <th>{intl.formatMessage({id: 'i000258'})}</th>
                                    <th>{intl.formatMessage({id: 'i000292'})}</th>
                                    <th/>
                                </tr>
                                </thead>
                                <tbody>
                                {invitations?.map(invitation => (
                                    <InvitationRow key={invitation._id} channelId={channelId} invitation={invitation} />
                                ))}
                                </tbody>
                            </Table>
                        </>
                    )}

                    <Table style={{minWidth: '800px'}} className="table-centered">
                        <colgroup>
                            <col width="150px" />
                            <col width="250px" />
                            <col width="*" />
                            <col width="80px" />
                        </colgroup>
                        <thead>
                        <tr>
                            <th>{intl.formatMessage({id: 'i000153'})}</th>
                            <th>{intl.formatMessage({id: 'i000151'})}</th>
                            {/*<th>{intl.formatMessage({id: 'i000154'})}</th>*/}
                            <th>{intl.formatMessage({id: 'i000338'})}</th>
                            <th className="text-center">{intl.formatMessage({id: 'i000356'})}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {members?.map(member => (
                            <MemberRow key={member.userId} channelId={channelId} member={member} />
                        ))}
                        {(isSuccessMembers && (!members || members.length < 1)) && (
                            <tr><td colSpan={5}>{intl.formatMessage({id: 'i000160'})}</td></tr>
                        )}
                        </tbody>
                    </Table>
                </SettingViewWrapper>
                <InvitationModal channelId={channelId} />
                <MemberEditModal channelId={channelId} />
            </>
        );
    }
};


const MemberRow: React.FC<MemberRowProps> = ({channelId, member}) => {
    const dialog = useDialog();
    const intl = useIntl();
    const modal = useModal();

    const {data: manager} = useManager(channelId);
    const {mutate: deleteMember} = useDeleteMember(channelId);

    const isMe = useMemo(() => {
        return member.userId === manager?.userId;
    }, [manager, member]);

    const roles = useMemo(() => {
        const _roles = member.roles.map(role => `${role.description} (${intl.formatMessage({id: 'i000357'})})`).join(',');
        const _muRoles = member.managementUnitRoles.map(role => role.description).join(', ');

        if (_roles && _muRoles) {
            return `${_roles}, ${_muRoles}`
        }
        else {
            return _roles || _muRoles;
        }
    }, [member, intl]);

    const onClickDeleteBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        if (isMe) {
            return;
        }

        dialog.open({
            title: intl.formatMessage({id: 'i000162'}),
            content: intl.formatMessage({id: 'i000163'}),
            variant: 'danger',
            onConfirm: () => {
                deleteMember(member._id, {
                    onError: () => {
                        toast.error(intl.formatMessage({id: 'i000164'}));
                    }
                });
            },
            confirmText: intl.formatMessage({id: 'i000034'}),
            cancelText: intl.formatMessage({id: 'i000012'}),
        });
    }

    const onClickEditBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        modal.open(MEMBER_EDIT_MODAL_ID, member);
    };

    return (
        <tr key={member.userId}>
            <td>{member.name}</td>
            <td>{member.user.username}</td>
            <td>{member.isAdmin ? `Admin (${intl.formatMessage({id: 'i000357'})})` : roles}</td>
            <td className="text-center">
                {isMe && (<Badge bg="info">You</Badge>)}
                {(!isMe && !member.isAdmin ) && (
                    <>
                        <i className="mdi mdi-square-edit-outline text-muted font-18" role="button" onClick={onClickEditBtn} />
                        <i className="mdi mdi-trash-can-outline text-muted font-18" role="button" onClick={onClickDeleteBtn} />
                    </>
                )}
            </td>
        </tr>
    )
};

const InvitationRow: React.FC<{channelId: string, invitation: Invitation}> = ({channelId, invitation}) => {
    const intl = useIntl();
    const dialog = useDialog();

    const [copied, setCopied] = useState(false);

    const {mutate: deleteInvitation} = useDeleteInvitation(channelId);

    const clipboardTooltip = useTooltip(intl.formatMessage({id: 'i000299'}));
    const cancelInviteTooltip = useTooltip(intl.formatMessage({id: 'i000300'}));

    const expired = useMemo(() => {
        return moment.utc(invitation.expiredAt).diff(moment.utc(), 'seconds') <= 0;
    }, [invitation]);

    const status = useMemo(() => {
        if (moment.utc(invitation.expiredAt).diff(moment.utc(), 'seconds') > 0) {
            return intl.formatMessage({id: 'i000293'});
        }
        else {
            return intl.formatMessage({id: 'i000294'});
        }
    }, [invitation, intl]);

    const roles = useMemo(() => {
        if (invitation.roleDescription && invitation.managementUnitRoleDescription) {
            return `${invitation.roleDescription}, ${invitation.managementUnitRoleDescription}`
        }
        else {
            return invitation.roleDescription || invitation.managementUnitRoleDescription;
        }
    }, [invitation]);

    const onClickClipboardInviteLink = (e: React.MouseEvent) => {
        e.preventDefault();
        navigator.clipboard.writeText(invitation.link);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    }

    const onClickRemoveBtn = async (e: React.MouseEvent) => {
        e.preventDefault();

        dialog.open({
            // title: '',
            content: intl.formatMessage({id: 'i000296'}, {username: `<strong>${invitation.username}</strong>`}),
            variant: 'danger',
            onConfirm: () => {
                deleteInvitation(invitation._id, {
                    onSuccess: () => toast.success(intl.formatMessage({id: 'i000297'})),
                    onError: () => toast.error(intl.formatMessage({id: 'e000001'}))
                })
            },
            confirmText: intl.formatMessage({id: 'i000295'})
        })
    };

    return (
        <tr key={invitation._id}>
            <td>{invitation.username}</td>
            <td>{roles}</td>
            <td>{utcToLocal(invitation.createdAt, 'LLL')}</td>
            <td>{status}</td>
            <td className="text-end">
                {!expired && (
                    <>
                        <i className={classNames('mdi text-muted font-18', {'mdi-clipboard-outline': !copied, 'mdi-clipboard-check-outline': copied})}
                           onClick={onClickClipboardInviteLink}
                           ref={clipboardTooltip.setTriggerRef}
                           role="button" />
                        {clipboardTooltip.visible && clipboardTooltip.element}
                    </>
                )}
                <i className="mdi mdi-close text-muted font-18 ml-1"
                   onClick={onClickRemoveBtn}
                   ref={cancelInviteTooltip.setTriggerRef}
                   role="button" />
                {cancelInviteTooltip.visible && cancelInviteTooltip.element}
            </td>
        </tr>
    )
};


const InvitationModal: React.FC<{channelId: string}> = ({channelId}) => {
    const intl = useIntl();
    const modal = useModal();

    const {data: members} = useMembers(channelId);
    const {data: invitations} = useInvitations(channelId);
    const {data: roles} = useRoles(channelId);

    const {mutate: createInvitation} = useCreateInvitation(channelId);

    const emailRef = useRef<HTMLInputElement>(null);
    const roleRef = useRef<HTMLSelectElement>(null);

    const emailIsValid = useCallback((email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }, []);

    const onClickCancelBtn = (e: React.MouseEvent) => {
        e.preventDefault();
        onClose();

        modal.close(INVITATION_MODAL_ID);
    };

    const onClose = () => {
        if (emailRef.current) {
            emailRef.current.value = '';
        }

        if (roleRef.current) {
            roleRef.current.value = 'ADMIN';
        }
    };

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const username = emailRef.current?.value;
        const selectedRole = roleRef.current?.value;

        if (!selectedRole) {
            return;
        }

        const [type, _roleId] = selectedRole.split(':')
        const roleId = type === 'default' ? _roleId : undefined;
        const managementUnitRoleId = type === 'custom' ? _roleId : undefined;

        if (username) {
            if (!emailIsValid(username)) {
                toast.error(intl.formatMessage({id: 'i000157'}));
                return;
            }

            const exists = members?.some(member => member.user.username === username) || invitations?.some(invitation => invitation.username === username);
            if (exists) {
                toast.error(intl.formatMessage({id: 'i000261'}));
                return;
            }

            createInvitation({username, roleId, managementUnitRoleId}, {
                onError: () => toast.error(intl.formatMessage({id: 'i000158'}))
            });
        }

        modal.close(INVITATION_MODAL_ID);
    };

    return (
        <MaruModal id={INVITATION_MODAL_ID} title={intl.formatMessage({id: 'i000150'})} onClose={onClose} centered={true}>
            <Modal.Body>
                <Form id="member-create-form" onSubmit={onSubmit}>
                    <Form.Group className="mb-1">
                        <Form.Label>{intl.formatMessage({id: 'i000151'})}</Form.Label>
                        <Form.Control type="text" placeholder={intl.formatMessage({id: 'i000298'})} ref={emailRef} />
                    </Form.Group>

                    <Form.Group className="mb-1">
                        <Form.Label>{intl.formatMessage({id: 'i000154'})}</Form.Label>
                        <Form.Control as="select" ref={roleRef}>
                            {roles?.map(role => (
                                <option key={role._id} value={`${role.default ? 'default' : 'custom'}:${role._id}`}>{role.description}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" variant="outline-secondary" onClick={onClickCancelBtn} className="btn-rounded">{intl.formatMessage({id: 'i000012'})}</Button>
                <Button type="submit" form="member-create-form" className="btn-rounded">
                    {intl.formatMessage({id: 'i000250'})}
                </Button>
            </Modal.Footer>
        </MaruModal>
    )
};


const MEMBER_EDIT_MODAL_ID = 'member-edit-modal';
const MemberEditModal: React.FC<{channelId: string}> = ({channelId}) => {
    const intl = useIntl();
    const modal = useModal();

    const roleRef = useRef<HTMLSelectElement>(null);

    const {data: roles} = useRoles(channelId);

    const {mutate: update, isLoading: submitting} = useUpdateMember(channelId);

    const member: Member = useMemo(() => {
        return modal.data[MEMBER_EDIT_MODAL_ID];
    }, [modal.data]);

    const currentRole = useMemo(() => {
        const roleLength = member?.roles.length ?? 0;
        const muRoleLength = member?.managementUnitRoles.length ?? 0;

        if (roleLength > 0) {
            return member.roles[0];
        }
        if (muRoleLength > 0) {
            return member.managementUnitRoles[0];
        }

        return null;
    }, [member]);

    const onClickSaveBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        const filtered = roles?.filter(role => role._id === roleRef.current?.value) ?? [];
        if (filtered.length > 0) {
            const selectedRole = filtered[0];
            update({memberId: member._id, roleId: selectedRole._id, defaultRole: selectedRole.default}, {
                onSuccess: () => {
                    toast.success(intl.formatMessage({id: 'i000358'}));
                    modal.close(MEMBER_EDIT_MODAL_ID);
                },
                onError: () => toast.error(intl.formatMessage({id: 'e000001'}))
            });
        }
    }

    const onClickCloseBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        modal.close(MEMBER_EDIT_MODAL_ID);
    };

    return (
        <MaruModal id={MEMBER_EDIT_MODAL_ID} title={intl.formatMessage({id: 'i000359'})} backdrop={submitting ? 'static' : undefined}>
            <Modal.Body>
                <SCFloatingLabel controlId="form-control-member-name"
                                 className="mb-2"
                                 label={intl.formatMessage({id: 'i000339'})}>
                    <Form.Control type="text"
                                  defaultValue={member?.name}
                                  disabled />
                </SCFloatingLabel>
                <SCFloatingLabel controlId="form-control-member-email"
                                 className="mb-2"
                                 label={intl.formatMessage({id: 'i000151'})}>
                    <Form.Control type="text"
                                  defaultValue={member?.user.username}
                                  disabled />
                </SCFloatingLabel>
                <hr />
                {currentRole && (
                    <SCFloatingLabel controlId="form-control-member-roles"
                                     label={intl.formatMessage({id: 'i000338'})}>
                        <Form.Select defaultValue={currentRole?._id} ref={roleRef}>
                            {roles?.map(role => (
                                <option key={role._id} value={role._id}>{role.description}</option>
                            ))}
                        </Form.Select>
                    </SCFloatingLabel>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary"
                        className="btn-rounded"
                        disabled={submitting}
                        onClick={onClickCloseBtn}>
                    {intl.formatMessage({id: 'i000012'})}
                </Button>
                <Button variant="primary"
                        className="btn-rounded"
                        disabled={submitting}
                        onClick={onClickSaveBtn}>
                    {intl.formatMessage({id: 'i000040'})}
                </Button>
            </Modal.Footer>
        </MaruModal>
    )
}


type MemberRowProps = {
    channelId: string;
    member: Member;
};


export default MemberView;
