import React, {useCallback, useMemo, useRef, useState} from "react";
import {LiveChat} from "../../model/livechat";
import {useIntl} from "react-intl";
import liveChatRepository from "../../repository/LiveChatRepository";
import {Badge, Dropdown, Form} from "react-bootstrap";
import {Manager} from "../../model/manager";
import {useDialog} from "../../hook/useDialog";
import Avatar from "../Avatar";
import styled from "styled-components";
import useManagers from "../../query/manager/useManagers";
import useManager from "../../query/manager/useManager";
import useLiveChatsHandler from "../../hook/useLiveChatsHandler";
import _ from "lodash";
import {CenteredIcon, UnStyledButton} from "../UnstyledBootstrap";
import ChatPanelLabel from "./ChatPanelLabel";
import useLiveChatHandler from "../../hook/useLiveChatHandler";
import useTooltip from "../../hook/useTooltip";
import toast from "react-hot-toast";

const LiveChatManagerInfoCard: React.FC<{liveChat: LiveChat, className?: string}> = ({liveChat, className}) => {
    const intl = useIntl();

    // const queryClient = useQueryClient();
    const liveChatHandler = useLiveChatHandler(liveChat.channelId);

    const {data: managers} = useManagers(liveChat.channelId);

    const updateAssignee = useCallback(async (manager: Manager) => {
        if (liveChat.assigneeId === manager.userId) {
            return
        }

        try {
            // liveChatStore.data = {...liveChatStore.data!, assigneeId: manager.userId}
            await liveChatRepository.updateAssignee(liveChat.channelId, liveChat._id, manager.userId);
            await liveChatHandler.invalidate(liveChat._id);
        } catch (e) {
            toast.error(intl.formatMessage({id: 'i100035'}));
        }

    }, [intl, liveChat._id, liveChat.channelId, liveChat.assigneeId, liveChatHandler])

    const owner = useMemo(() => {
        return liveChat.managers.find((v) => v.userId === liveChat.assigneeId)
    }, [liveChat])

    return (
        <LiveChatManagerInfoCardStyle className={className}>
            <div className="d-flex align-items-center mb-1" style={{height: '34px'}}>
                <ChatPanelLabel>{intl.formatMessage({id: 'i000315'})}</ChatPanelLabel>
                <Dropdown>
                    <Dropdown.Toggle as={UnStyledButton} className="w-100 text-start px-1" bsPrefix="unused">
                        {owner && <Avatar name={owner.name} size={24} className="mr-1" />}
                        <span className="manger-name">{owner ? owner.name: intl.formatMessage({id: 'i000086'})}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="px-2">
                        {owner &&
                            <>
                                <Dropdown.Header className="pb-0 px-1">{intl.formatMessage({id: 'i000315'})}</Dropdown.Header>
                                <ManagerRow liveChat={liveChat} manager={owner} className="px-1 pb-0"/>
                                <Dropdown.Divider />
                            </>
                        }

                        <div className="manager-container">
                            {managers?.map((manager) => (
                                <Dropdown.Item key={manager.userId} onClick={() => updateAssignee(manager)} className="px-1">
                                    <ManagerRow liveChat={liveChat}
                                                manager={manager}
                                                showSwap={manager.userId !== liveChat.assigneeId}
                                                className="p-0"
                                                tooltip={manager.userId !== liveChat.assigneeId ? intl.formatMessage({id: 'i100039'}) : undefined}
                                    />
                                </Dropdown.Item>
                            ))}
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            {liveChat.assigneeId &&
                <div className="d-flex align-items-center" style={{height: '34px'}}>
                    <ChatPanelLabel>{intl.formatMessage({id: 'i100034'})}</ChatPanelLabel>
                    <ManagerInviter liveChat={liveChat}/>
                </div>
            }

        </LiveChatManagerInfoCardStyle>
    )
};

type ManagerInviterProps = {
    liveChat: LiveChat
}

const ManagerInviter:React.FC<ManagerInviterProps> = ({liveChat}) => {
    const intl = useIntl();
    const dialog = useDialog()
    const liveChatsHandler = useLiveChatsHandler(liveChat.channelId);
    const liveChatHandler = useLiveChatHandler(liveChat.channelId);
    const {data: me} = useManager(liveChat.channelId);

    const {data: managers} = useManagers(liveChat.channelId);

    const managerNameRef = useRef<HTMLInputElement>(null)
    const [managerItems, setManagerItems] = useState<Manager[]>([])
    const showRef = useRef(false)

    const checkName = _.debounce(() => {
        const name = managerNameRef.current?.value.trim();

        let _managerItems = name && managers ? managers.filter((v) => liveChat.assigneeId !== v.userId && v.name.includes(name)) : managers!
        setManagerItems(sortMembers(_managerItems))
    }, 300);


    const memberIds = useMemo(() => liveChat.managers.map((v) => v.userId), [liveChat.managers])

    const sortMembers = useCallback((managers: Manager[]) => {
        function sortValue(userId: string) {
            if (userId === liveChat.assigneeId) {
                return 0;
            } else if (memberIds.includes(userId)) {
                return 1
            } else {
                return 2
            }
        }

        return managers.sort((a,b) => {
            return sortValue(a.userId) - sortValue(b.userId)
        })

    }, [liveChat.assigneeId, memberIds])

    const onToggle = (show: boolean) => {
        if (!show) {
            setManagerItems([])
        } else if (!showRef.current && managers) {
            setManagerItems(sortMembers(managers))
        }

        showRef.current = show
    }

    // const sendMessage = useCallback((manager: Manager, message: string) => {
    //     const currentUtcTime = moment.utc().format("YYYY-MM-DD HH:mm:ss.SSSSS");
    //     const messageData: MessageForm = {
    //         senderType: 'manager',
    //         senderUserId: manager.userId,
    //         blocks: [{
    //             type: 'text',
    //             value: {
    //                 text: message,
    //                 html: message
    //             }
    //         }],
    //         createdAt: currentUtcTime
    //     };
    //     webSocketStore.sendMessage(liveChat._id, messageData);
    // }, [webSocketStore, liveChat._id])

    const kickManager = useCallback(async (manager: Manager) => {

        const isMe = manager.userId === me?.userId

        dialog.open({
            content: isMe ? intl.formatMessage({id: 'i000322'}) : intl.formatMessage({id: 'i000314'}, {name: manager.name}),
            variant: 'danger',
            onConfirm: async () => {
                try {
                    // liveChat.managers = liveChat.managers.filter((v) => v.userId !== manager.userId)
                    await liveChatRepository.removeManager(liveChat.channelId, liveChat._id, manager.userId);
                    await liveChatHandler.invalidate(liveChat._id);
                    liveChatsHandler.removeManager(liveChat._id, manager.userId);
                    // sendMessage(manager, intl.formatMessage({id: 'i100042'}, {name: manager.name}) )
                } catch (e) {
                    toast.error(intl.formatMessage({id: 'e000001'}));
                }
            },
            confirmText: isMe ? intl.formatMessage({id: 'i000323'}) : intl.formatMessage({id: 'i000318'}),
            cancelText: intl.formatMessage({id: 'i000012'}),
        });
    }, [dialog, intl, liveChat._id, liveChat.channelId, liveChatsHandler, liveChatHandler, me])

    const inviteManager = useCallback(async (manager: Manager)  => {
        try {
            // liveChat.managers = [...liveChat.managers, manager]
            await liveChatRepository.addManager(liveChat.channelId, liveChat._id, manager.userId);
            await liveChatHandler.invalidate(liveChat._id);
            // sendMessage(manager, intl.formatMessage({id: 'i100043'}, {name: manager.name}) )
        } catch (e) {
            toast.error(intl.formatMessage({id: 'i100038'}));
        }
    }, [intl, liveChat._id, liveChat.channelId, liveChatHandler])

    const genItemProps = useCallback((manager: Manager) => {
        const isInvolved = memberIds.includes(manager.userId)
        const isOwner = liveChat.assigneeId === manager.userId
        let onClick
        let tooltip

        if (isOwner) {
            onClick = undefined
            tooltip = undefined
        } else if (isInvolved) {
            onClick = kickManager
            tooltip = intl.formatMessage({id: 'i100040'})
        } else {
            onClick = inviteManager
            tooltip = intl.formatMessage({id: 'i100041'})
        }
        return {isInvolved, isOwner, onClick, tooltip}
    }, [liveChat.assigneeId, memberIds, intl, inviteManager, kickManager])

    return (
        <Dropdown onToggle={onToggle}>
            <Dropdown.Toggle as={UnStyledButton} className="px-1" bsPrefix="unused">
                <CenteredIcon size={18} className="mdi mdi-plus-circle font-18 mr-1" />
                {liveChat.managers.slice(0, 5).map((manager, index) => (
                    <Avatar key={`manager-item-${index}`} name={manager.name} size={24} style={{marginRight: '0.15rem'}} />
                ))}
                {liveChat.managers.length > 5 && <Badge bg="secondary" className="ml-1" pill>+{liveChat.managers.length - 5}</Badge>}
            </Dropdown.Toggle>
            <Dropdown.Menu className="px-2">
                <Form.Control ref={managerNameRef}
                              className="my-1"
                              size="sm"
                              placeholder={intl.formatMessage({id: 'i100037'})}
                              onChange={checkName}
                />

                <div className="manager-container">
                    {managerItems.map(manager => {
                        const {isInvolved, isOwner, onClick, tooltip} = genItemProps(manager)
                        return (
                            <Dropdown.Item key={manager._id} onClick={() => onClick?.(manager)} className="px-1">
                                <ManagerRow liveChat={liveChat}
                                            manager={manager}
                                            showKick={!isOwner&& isInvolved}
                                            showInvite={!isInvolved}
                                            tooltip={tooltip}
                                            className="py-0"
                                />
                            </Dropdown.Item>
                        )
                    })}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}

type ManagerRowProps = {
    liveChat: LiveChat,
    manager: Manager
    showSwap?: boolean
    showKick?: boolean
    showInvite?: boolean
    tooltip?: string
    onClick?: (manager: Manager) => Promise<void>
};

const ManagerRow: React.FC<ManagerRowProps & React.HTMLProps<HTMLDivElement>> = (props) => {
    const {liveChat, manager, showKick, showSwap, showInvite, onClick, tooltip, className} = props
    const intl = useIntl();

    const actionTooltip = useTooltip(tooltip ?? '');

    const managerType = useMemo(() => {
        if (liveChat.assigneeId === manager.userId) {
            return 'owner'
        } else if (liveChat.managers.map(v => v.userId).includes(manager.userId)) {
            return 'member'
        } else {
            return undefined
        }
    }, [manager, liveChat]);

    const _onClick = useCallback(() => onClick?.(manager), [manager, onClick])

    return (
        <ManagerRowStyle onClick={_onClick} className={className}>
            <div className="d-flex align-items-center">
                <Avatar name={manager.name} size={24} className="mr-1" />
                <span className="mr-1">{manager.name}</span>
                {managerType === 'owner' && (
                    <small className="text-muted">{intl.formatMessage({id: 'i000315'})}</small>
                )}

                {managerType === 'member' && (
                    <small className="text-muted">{intl.formatMessage({id: 'i100034'})}</small>
                )}
            </div>
            {showSwap && <CenteredIcon size={20} className="mdi mdi-swap-horizontal ml-1" ref={actionTooltip.setTriggerRef} />}
            {showKick && <CenteredIcon size={20} className="mdi mdi-minus ml-1" ref={actionTooltip.setTriggerRef} />}
            {showInvite && <CenteredIcon size={20} className="mdi mdi-plus ml-1" ref={actionTooltip.setTriggerRef} />}
            {actionTooltip.visible && actionTooltip.element}
        </ManagerRowStyle>
    );
};

const ManagerRowStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;
  padding: 0.375rem 0;
`

const LiveChatManagerInfoCardStyle = styled.div `
  
  .manger-name {
    white-space: normal;
  }
  
  .manager-container {
    max-height: 300px;
    overflow-y: auto;
  }
  
  //.extra-member-cnt {
  //  width: 24px;
  //  height: 24px;
  //  border: 1px solid black;
  //  border-radius: 12px;
  //}

`;

export default LiveChatManagerInfoCard;
