import React, {useEffect, useMemo, useRef, useState} from "react";
import * as uuid from "uuid";
import {DateRangeOverlayPicker} from "@42maru/react-date-picker"
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {useIntl} from "react-intl";
import moment from "moment-timezone";
import {LiveChatWorkingHoliday} from "../../model/channel";
import toast from "react-hot-toast";
import FullCalendar from "@fullcalendar/react";
import useUpdateWorkingTimes from "../../query/channel/useUpdateWorkingTimes";
import useChannel from "../../query/channel/useChannel";
import useModal, {MaruModal} from "../../hook/useModal";
import styled from "styled-components";
import useChannelIntegration from "../../query/useChannelIntegration";
import {DateRangePickerData} from "@42maru/react-date-picker/dist/DateRangePicker";

interface HolidaySchedulerCalendarModalProps {
    channelId: string
    calendarRef: FullCalendar
}

// const minuteToSelectOption = (minute: number) => {
//     let displayHour = Math.floor(minute/60).toString()
//     if (displayHour.length < 2) {
//         displayHour = '0'+ displayHour
//     }
//
//     let displayMinute = (minute % 60).toString()
//     if (displayMinute.length < 2) {
//         displayMinute = '0'+ displayMinute
//     }
//
//     return `${displayHour}:${displayMinute}`
// }
//
// const getTimePickerItems = (minMinute?: number) => {
//     const result: number[] = []
//
//     for (let minute = minMinute || 0; minute < 1440; minute+=15) {
//         result.push(minute)
//     }
//     return result
// }

interface HolidayRange {
    year: number
    month: number
    day: number
    minute: number
}

const pad = (n: number) => n < 10 ? "0"+n : n

const holidayRangeDisplayDate = (year: number, month: number, date: number) => {
    month += 1;
    const _month = month < 10 ? `0${month}` : month
    const _date = date < 10 ? `0${date}` : date
    return `${year}-${_month}-${_date}`
}

const holidayRangeToMoment = (timezone: string, holidayRange: HolidayRange) => {
    return moment.tz(
        `${holidayRange.year}-${pad(holidayRange.month+1)}-${pad(holidayRange.day)} ${pad(holidayRange.minute/60)}:${pad(holidayRange.minute % 60)}`
        , timezone);
}

const WorkingTimeCalendarModal: React.FC<HolidaySchedulerCalendarModalProps> = (props) => {
    const {channelId, calendarRef} = props
    const modal = useModal();
    const intl = useIntl();

    const titleRef = useRef<HTMLInputElement>(null)
    const clientInfoMessageRef = useRef<HTMLTextAreaElement>(null)
    const startDateRef = useRef<HTMLInputElement>(null)
    const recurringAnnuallyRef = useRef<HTMLInputElement>(null);
    // const endDateRef = useRef<HTMLInputElement>(null)
    // const startTimeRef = useRef<HTMLSelectElement>(null)
    // const endTimeRef = useRef<HTMLSelectElement>(null)

    const data: {
        start?: Date,
        end?: Date,
        holiday?: LiveChatWorkingHoliday,
        eventId?: string
    } = useMemo(() => {
        return modal.data[calendarSchedulerModalId] ? modal.data[calendarSchedulerModalId] : {}
    }, [modal.data])

    const [startFrom, setStartFrom] = useState<HolidayRange | undefined>(undefined)
    const [endFrom, setEndFrom] = useState<HolidayRange | undefined>(undefined)

    const {data: channel} = useChannel(channelId);
    const {isLoading: channelIntegrationLoading } = useChannelIntegration(channelId);
    const {mutateAsync: updateWorkingTimes, isLoading} = useUpdateWorkingTimes(channelId);

    const workingHolidayConfig = useMemo(() => channel?.config.workingHoliday, [channel])

    useEffect(() => {
        if (!channel) return

        const start = moment(data.start?.toISOString()).tz(channel.config.timezone)
        const end = moment(data.end?.toISOString()).tz(channel.config.timezone)

        setStartFrom({
            year: start.year(),
            month: start.month(),
            day: start.date(),
            minute: start.hour() * 60 + start.minute()
        })
        setEndFrom({
            year: end.year(),
            month: end.month(),
            day: end.date(),
            minute: end.hour() * 60 + end.minute()
        })

        if (titleRef.current && data.holiday) {
            titleRef.current.value = data.holiday.title
        }
        if (clientInfoMessageRef.current && data.holiday?.clientInfoMessage) {
            clientInfoMessageRef.current.value = data.holiday?.clientInfoMessage
        }

        if (recurringAnnuallyRef.current && data.holiday) {
            recurringAnnuallyRef.current.checked = data.holiday.recurringAnnually
        }

    }, [data, channel])

    // const onChangeStartDate = (start?: Date) => {
    //     if (!start || !startFrom) {
    //         return
    //     }
    //
    //     setStartFrom({year: start.getFullYear(), month: start.getMonth(), day: start.getDate(), minute: startFrom.minute})
    // };
    //
    // const onChangeEndDate = (end?: Date) => {
    //     if (!end || !endFrom) {
    //         return
    //     }
    //
    //     setEndFrom({year: end.getFullYear(), month: end.getMonth(), day: end.getDate(), minute: endFrom.minute})
    // };

    const onChangeData = ({startDate, endDate}: DateRangePickerData) => {
        if (startDate && startFrom) {
            setStartFrom({year: startDate.getFullYear(), month: startDate.getMonth(), day: startDate.getDate(), minute: startFrom.minute})
        }


        if (endDate && endFrom) {
            setEndFrom({year: endDate.getFullYear(), month: endDate.getMonth(), day: endDate.getDate(), minute: endFrom.minute})
        }
    }

    const close = () => modal.close(calendarSchedulerModalId)

    // 타임 피커
    // const onChangeStartTime = (_: any) => {
    //     if (!startTimeRef.current || !startFrom) return
    //
    //     setStartFrom({...startFrom, minute: parseInt(startTimeRef.current.value)})
    // }
    //
    // const onChangeEndTime = (_: any) => {
    //     if (!endTimeRef.current || !endFrom) return
    //
    //     setEndFrom({...endFrom, minute: parseInt(endTimeRef.current.value)})
    // }

    const _onSubmit = async () => {
        if (!channel ||
            !titleRef.current ||
            !clientInfoMessageRef.current ||
            !recurringAnnuallyRef.current ||
            !workingHolidayConfig ||
            !startFrom ||
            !endFrom) return;

        if (titleRef.current.value.length < 1) {
            toast(intl.formatMessage({id: 'i100078'}), {icon: <i className="mdi mdi-alert-outline text-warning font-18" />})
            return
        }

        const _start = holidayRangeToMoment(channel.config.timezone, startFrom).toDate()
        const _end = holidayRangeToMoment(channel.config.timezone, endFrom).toDate()

        if (_start.getTime() > _end.getTime()) {
            toast(intl.formatMessage({id: 'i100087'}), {icon: <i className="mdi mdi-alert-outline text-warning font-18" />})
            return
        }

        const holidays = [...workingHolidayConfig.holidays]

        const holiday: LiveChatWorkingHoliday = {
            holidayId: data.eventId ?? uuid.v4(),
            title: titleRef.current.value,
            clientInfoMessage: clientInfoMessageRef.current?.value,
            recurringAnnually: recurringAnnuallyRef.current.checked,
            start: _start.toISOString(),
            end: _end.toISOString()
        }

        if (data.eventId) {
            // Update
            const holidayIndex = holidays.findIndex(item => item.holidayId === data.eventId)
            if (holidayIndex >= 0) {
                holidays[holidayIndex] = holiday
            }
        } else {
            // Create
            holidays.push(holiday)
        }

        close()
        await toast.promise(
            updateWorkingTimes({workingHolidayConfig: {active: workingHolidayConfig.active, holidays}}),
            {
                loading: intl.formatMessage(({id: 'i200003'})),
                success: () => {
                    close()
                    return intl.formatMessage(({id: 'i100082'}));
                },
                error: () => {
                    return intl.formatMessage(({id: 'e000001'}))
                }
            }
        )
    }

    const deleteEvent = async () => {
        if (!data.eventId || !workingHolidayConfig) return;

        const event = calendarRef.getApi().getEventById(data.eventId)
        if (!event) return

        const holidays = workingHolidayConfig.holidays
            .filter((holiday) => holiday.holidayId !== event.id)

        await toast.promise(
            updateWorkingTimes({workingHolidayConfig: {active: workingHolidayConfig.active, holidays}}),
            {
                loading: intl.formatMessage(({id: 'i200003'})),
                success: () => {
                    event.remove()
                    close()
                    return intl.formatMessage(({id: 'i100083'}));
                },
                error: () => {
                    return intl.formatMessage(({id: 'e000001'}))
                }
            }
        )
    }

    const displayRange = useMemo(() => {
        if (!channel) return ''

        const fromDateText = startFrom ? holidayRangeDisplayDate(startFrom.year, startFrom.month, startFrom.day) : ''
        let toDateText = ''
        if (endFrom) {
            const toMoment = holidayRangeToMoment(channel.config.timezone, endFrom).subtract(1, 'days')

            toDateText = holidayRangeDisplayDate(toMoment.year(), toMoment.month(), toMoment.date())
        }

        return `${fromDateText} ~ ${toDateText}`
    }, [startFrom, endFrom, channel])

    // const navigateToTimezoneConsole = async () => {
    //     if (!channelIntegration) return;
    //
    //     const consoleUrl = `${CHATBOT_WEB_HOST}/channels/${channelIntegration.swingChatChannelId}/settings/basic-info`
    //     if (electronRuntime) {
    //         electronRuntime.send('open-browser', consoleUrl);
    //     } else {
    //         window.open(consoleUrl, '_blank');
    //     }
    // }

    if (channelIntegrationLoading) return null

    return (
        <WorkingTimeCalendarModalWrapper
            id={calendarSchedulerModalId}
            size='lg'
            title={intl.formatMessage({id: data.eventId ? 'i100070' : 'i100069'})}
            centered
        >
            <Modal.Body>
                <Form id={'holiday-edit-form'}>
                    <Form.Group as={Row} className="mb-3 align-items-center">
                        <Form.Label column sm={2}>
                            {intl.formatMessage({id: 'i100075'})}
                        </Form.Label>

                        <Col sm={6}>
                            <DateRangeOverlayPicker
                                range={{startDate: startFrom ? new Date(startFrom.year, startFrom.month, startFrom.day) : undefined, endDate: endFrom ? new Date(endFrom.year, endFrom.month, endFrom.day) : undefined}}
                                onChange={onChangeData}
                                showTimePicker={true}
                                months={2}
                            >
                                <Form.Control className='wk-calendar-range-input'
                                              ref={startDateRef}
                                              value={displayRange}
                                              // value={`${startFrom ? holidayRangeDisplayDate(startFrom.year, startFrom.month, startFrom.day) : ''} ~  ${endFrom ? holidayRangeDisplayDate(endFrom.year, endFrom.month, endFrom.day) : ''}`}

                                              readOnly={true}
                                />
                            </DateRangeOverlayPicker>
                        </Col>
                        {/*<Col sm={3}>*/}
                        {/*    <DatePickerOverlay date={startFrom ? new Date(startFrom.year, startFrom.month, startFrom.day) : undefined}*/}
                        {/*                       onChange={onChangeStartDate}*/}
                        {/*                       showDateInput={false}>*/}
                        {/*        <Form.Control className='wk-calendar-range-input'*/}
                        {/*                      ref={startDateRef}*/}
                        {/*                      value={startFrom ? holidayRangeDisplayDate(startFrom.year, startFrom.month, startFrom.day) : ''}*/}
                        {/*                      readOnly={true}*/}
                        {/*        />*/}
                        {/*    </DatePickerOverlay>*/}
                        {/*</Col>*/}

                        {/*-*/}

                        {/*<Col sm={3}>*/}
                        {/*    <DatePickerOverlay date={endFrom ? new Date(endFrom.year, endFrom.month, endFrom.day) : undefined}*/}
                        {/*                       onChange={onChangeEndDate}*/}
                        {/*                       showDateInput={false}>*/}
                        {/*        <Form.Control className='wk-calendar-range-input'*/}
                        {/*                      ref={endDateRef}*/}
                        {/*                      value={endFrom ? holidayRangeDisplayDate(endFrom.year, endFrom.month, endFrom.day) : ''}*/}
                        {/*                      readOnly={true}*/}
                        {/*        />*/}
                        {/*    </DatePickerOverlay>*/}
                        {/*</Col>*/}
                    </Form.Group>

                    {/*시간설정*/}
                    {/*<Form.Group as={Row} className="mb-3 align-items-center">*/}
                    {/*    <Form.Label column sm={2}>*/}
                    {/*        {intl.formatMessage({id: 'i000057'})}*/}
                    {/*    </Form.Label>*/}

                    {/*    <Col sm={3}>*/}
                    {/*        <Form.Select value={startFrom ? startFrom.minute : 0}*/}
                    {/*                     ref={startTimeRef}*/}
                    {/*                     onChange={onChangeStartTime}*/}
                    {/*        >*/}
                    {/*            {getTimePickerItems().map((minute, i) =>*/}
                    {/*                <option key={`start-time-option-${i}`} value={minute}>*/}
                    {/*                    {minuteToSelectOption(minute)}*/}
                    {/*                </option>*/}
                    {/*            )}*/}
                    {/*        </Form.Select>*/}
                    {/*    </Col>*/}

                    {/*    -*/}

                    {/*    <Col sm={3}>*/}
                    {/*        <Form.Select value={endFrom ? endFrom.minute : 0}*/}
                    {/*                     ref={endTimeRef}*/}
                    {/*                     onChange={onChangeEndTime}*/}
                    {/*        >*/}
                    {/*            {getTimePickerItems().map((minute, i) =>*/}
                    {/*                <option key={`start-time-option-${i}`} value={minute}>*/}
                    {/*                    {minuteToSelectOption(minute)}*/}
                    {/*                </option>*/}
                    {/*            )}*/}
                    {/*        </Form.Select>*/}
                    {/*    </Col>*/}
                    {/*</Form.Group>*/}

                    <Form.Group as={Row} className='mb-3'>
                        <Form.Label column xs={'2'}>{intl.formatMessage({id: 'i100084'})}</Form.Label>
                        <Col sm={5} className='d-flex align-items-center'>
                            <Form.Switch className="m-0" ref={recurringAnnuallyRef}/>
                        </Col>
                    </Form.Group>

                    {/*<Form.Group as={Row} className='mb-3'>*/}
                    {/*    <Form.Label column xs={'2'}>{intl.formatMessage({id: 'i000059'})}</Form.Label>*/}
                    {/*    <Col sm={3} className='d-flex align-items-center'>*/}
                    {/*        {channelIntegration ?*/}
                    {/*            <Card.Link className='text-decoration-underline'*/}
                    {/*                       href="#"*/}
                    {/*                       onClick={navigateToTimezoneConsole}*/}
                    {/*            >*/}
                    {/*                {channel?.config.timezone}*/}
                    {/*            </Card.Link>*/}
                    {/*            :*/}
                    {/*            <p className='mb-0'>{channel?.config.timezone}</p>*/}
                    {/*        }*/}
                    {/*    </Col>*/}
                    {/*</Form.Group>*/}

                    <Form.Group className="mb-3">
                        <Form.Label column sm={2}>{intl.formatMessage({id: 'i100071'})}</Form.Label>
                        <Form.Control ref={titleRef}/>
                    </Form.Group>

                    <Form.Group className="mb-2 d-none">
                        <Form.Label>{intl.formatMessage({id: 'i100077'})}</Form.Label>
                        <Form.Control as="textarea" rows={3} ref={clientInfoMessageRef}/>
                    </Form.Group>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary"
                        className="btn-rounded"
                        disabled={isLoading}
                        onClick={close}
                    >
                    {intl.formatMessage({id: 'i000302'})}
                </Button>
                {data.eventId &&
                    <Button variant="outline-danger"
                            className="btn-rounded"
                            disabled={isLoading}
                            onClick={deleteEvent}
                    >
                        {intl.formatMessage({id: 'i000034'})}
                    </Button>
                }
                <Button onClick={_onSubmit}
                        variant="primary"
                        className="btn-rounded"
                        disabled={isLoading}
                    >
                    {intl.formatMessage({id: data.eventId ? 'i000029' : 'i000040'})}
                </Button>
            </Modal.Footer>
        </WorkingTimeCalendarModalWrapper>
    );
};

export const calendarSchedulerModalId = 'calendar-scheduler-modal';

const WorkingTimeCalendarModalWrapper = styled(MaruModal) `
    .wk-calendar-range-input[readonly] {
      background-color: white;
    }
`

export default WorkingTimeCalendarModal;
