import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError, AxiosResponse} from "axios";
import liveChatRepository from "../../repository/LiveChatRepository";
import {LiveChatState} from "../../model/livechat";
import {useIntl} from "react-intl";
import checkAxiosError from "../../util/checkAxiosError";
import toast from "react-hot-toast";

type LiveChatStateWithTrigger = {
    state: LiveChatState,
    sendMessageTrigger?: boolean,
}

const useUpdateLiveChatState = (channelId: string, liveChatId: string) => {
    const queryClient = useQueryClient();
    const intl = useIntl();

    return useMutation<AxiosResponse, AxiosError, LiveChatStateWithTrigger>({
        mutationKey: ['liveChat', 'updateState', channelId, liveChatId],
        mutationFn: async ({state, sendMessageTrigger}) => {
            try {
                return await liveChatRepository.updateState(channelId, liveChatId, state, sendMessageTrigger)
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            return queryClient.invalidateQueries(['liveChat', channelId, liveChatId], {exact: true})
        },
        onError: (e) => {
            if (checkAxiosError(e, 'ACC_001')) {
                toast.error(intl.formatMessage({id: 'e000004'}));
            } else if (checkAxiosError(e, 'ACC_003')) {
                toast.error(intl.formatMessage({id: 'e000005'}));
            } else {
                toast.error(intl.formatMessage({id: 'e000001'}));
            }
        }
    });
}

export default useUpdateLiveChatState;
