import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import {LiveChatError, toLiveChatError} from "../../error";
import {CustomSnippetCreate} from "../../model/customSnippet";
import customSnippetRepository from "../../repository/CustomSnippetRepository";

const useCreateCustomSnippet = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, LiveChatError, CustomSnippetCreate>({
        mutationKey: ['snippets', 'create', channelId],
        mutationFn: async (body: CustomSnippetCreate) => {
            try {
                return await customSnippetRepository.create(channelId, body);
            } catch (e) {
                throw toLiveChatError(e);
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(['snippets', channelId], {exact: true});
        }
    })
};

export default useCreateCustomSnippet;
