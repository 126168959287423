import React, {useState} from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import {ChatSearchFilterToggleStyle} from "./ChatSearchFilterStyle";
import {koLocaleForDateRangePicker} from "../../util/datetime";
import styled from "styled-components";
import {useIntl} from "react-intl";

type ChatSearchDateRangeFilterProps = {
    onCallback: () => void
    label?: string
}

const ChatSearchDateRangeFilter: React.FC<ChatSearchDateRangeFilterProps> = (props) => {
    const { onCallback, label } = props;
    const intl = useIntl();

    const [showPopup, setShowPopup] = useState(false)

    return (
        <DateRangePickerStyle>
            <DateRangePicker onCallback={onCallback}
                             onShow={() => setShowPopup(true)}
                             onHide={() => setShowPopup(false)}
                             initialSettings={{
                                 autoUpdateInput: false,
                                 startDate: moment().subtract(2, 'years'),
                                 endDate: moment(),
                                 minDate: moment().subtract(2, 'years'),
                                 maxDate: moment(),
                                 ranges: {
                                     '모든 기간': [moment().subtract(2, 'years'), moment()],
                                     '오늘': [moment(), moment()],
                                     '어제': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                     '지난 7일': [moment().subtract(6, 'days'), moment()],
                                     '지난 1개월': [moment().subtract(1, 'months'), moment()],
                                     '지난 3개월': [moment().subtract(3, 'months'), moment()]
                                 },
                                 locale: koLocaleForDateRangePicker()
                             }}>
                <ChatSearchFilterToggleStyle active={!!label} role="button">
                    <i className="mdi mdi-calendar-month"/>
                    <span className="mx-1">{label ?? intl.formatMessage({id: "i210014"})}</span>
                    <i className={showPopup ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"}/>
                </ChatSearchFilterToggleStyle>
            </DateRangePicker>
        </DateRangePickerStyle>
    )
};

const DateRangePickerStyle = styled.div`
  margin-right: 1rem;
`

export default ChatSearchDateRangeFilter;
