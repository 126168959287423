import React, {useEffect, useState} from "react";
import SimpleBar from "simplebar-react";
import CodeSnippetRenderer from "../CodeSnippetRenderer";
import {CodeSnippetErrorObject, CodeSnippetObject, EmptyObject, NotFoundMemberObject} from "../../model/codeSnippet";
import {ChatUser} from "../../model/chatUser";
import useCommerceOrderCodeSnippet from "../../query/codeSnippet/useCommerceOrderCodeSnippet";
import ChatPanelEmptyMessage from "./ChatPanelEmptyMessage";
import {useIntl} from "react-intl";

const UserOrderListCard : React.FC<UserOrderListCardProps> = (props) => {
    const intl = useIntl();
    const { channelId, chatUser } = props;

    const { data: codeSnippetResult } = useCommerceOrderCodeSnippet(channelId, chatUser.profile?.memberId)
    const [codeSnippetObject, setCodeSnippetObject] = useState<CodeSnippetObject|CodeSnippetObject[]>(EmptyObject)

    useEffect(() => {
        setCodeSnippetObject(_ => {
            if (!chatUser.profile || !codeSnippetResult) {
                return EmptyObject
            }

            if (!chatUser.profile.memberId) {
                return NotFoundMemberObject
            }

            return codeSnippetResult
        })
    }, [chatUser, codeSnippetResult]);

    return (
        <div className="position-relative" style={{maxHeight: '300px'}}>
            {chatUser.profile?.memberId && (
                <SimpleBar style={{height: '100%', paddingRight: '11px'}}>
                    {Array.isArray(codeSnippetObject) ?
                        (codeSnippetObject.length === 0 ?
                                <CodeSnippetRenderer codeSnippetObject={EmptyObject}/>
                                :
                                <>
                                    {codeSnippetObject.map((item, index) => {
                                        const last = index === codeSnippetObject.length - 1;
                                        return (
                                            <div key={index}>
                                                <CodeSnippetRenderer codeSnippetObject={item}/>
                                                {!last && <hr className="my-2"/>}
                                            </div>
                                        )
                                    })}
                                </>
                        )
                        :
                        <CodeSnippetRenderer codeSnippetObject={codeSnippetObject ?? CodeSnippetErrorObject}/>
                    }
                </SimpleBar>
            )}
            {!chatUser.profile?.memberId && (
                <ChatPanelEmptyMessage message={intl.formatMessage({id: 'i000378'})} />
            )}
        </div>
    )
};

type UserOrderListCardProps = {
    channelId: string
    chatUser: ChatUser
}

export default UserOrderListCard;