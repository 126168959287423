import React, {useMemo} from "react";
import classNames from "classnames";
import {createAvatar as createSvg} from "@dicebear/avatars";
import * as avatarStyle from '@dicebear/avatars-initials-sprites';
import {useIntl} from "react-intl";
// import * as style from '@dicebear/avatars-avataaars-sprites';


const Avatar: React.FC<AvatarProps & React.HTMLProps<HTMLDivElement>> = ({name, size, className, avatarUrl, style}) => {
    const intl = useIntl();

    const avatar = useMemo(() => {
        const unknown = name === intl.formatMessage({id: "i200078"});
        return createSvg(avatarStyle, {
            seed: unknown ? undefined : name,
            radius: 20,
            width: size,
            height: size,
            // backgroundColorLevel: 600,
            backgroundColors: unknown ? ["grey"] : undefined,
            backgroundColor: style?.backgroundColor,
            fontSize: 50,
            chars: unknown ? 0 : 1,
        })
    }, [name, size, intl, style?.backgroundColor]);

    if (avatarUrl) {
        return <img src={avatarUrl} width={size} height={size} alt="avatar" style={{borderRadius: "20%"}}/>
    } else {
        return (
            <div className={classNames('d-inline-block', {[className || '']: className})}
                 dangerouslySetInnerHTML={{__html: avatar}}
                 style={style}/>
        )
    }
}

// const Avatar: React.FC<{name: string, size: number, className?: string}> = ({name, size, className}) => {
//
//     const avatar = useMemo(() => {
//         return createSvg(style, {
//             seed: name,
//             style: 'transparent',
//             // style: 'circle',
//             radius: 20,
//             width: size,
//             height: size,
//             margin: 0,
//             backgroundColor: '#C9CBA3',
//             // backgroundColor: '#ffffff',
//             // backgroundColor: '#2980b9',
//             // backgroundColor: '#3498db',
//             eyes: ['default', 'happy', 'hearts', 'wink', 'winkWacky'],
//             mouth: ['concerned', 'default', 'smile', 'twinkle'],
//             topChance: 100,
//             accessoriesChance: 50,
//             facialHairChance: 20,
//             skin: ['tanned', 'pale', 'light', 'brown', 'darkBrown']
//         })
//     }, [name, size]);
//
//     return (
//         <div className={classNames('d-inline-block', {[className || '']: className})} dangerouslySetInnerHTML={{__html: avatar}}>
//
//         </div>
//     );
// }

// const Avatar: React.FC<AvatarProps> = ({size, name, className}) => {
//
//     const canvasRef = useRef<any>();
//
//     useLayoutEffect(() => {
//         if (size && name) {
//             const colours = [
//                 "#1abc9c", "#2ecc71", "#3498db", "#9b59b6",
//                 "#2929a5", "#16a085", "#27ae60", "#2980b9",
//                 "#8e44ad", "#2c3e50", "#f1c40f", "#e67e22",
//                 "#e74c3c", "#15c5a6", "#f39c12", "#d35400",
//                 "#c0392b", "#DFBBB1", "#F56476", "#E43F6F",
//                 "#BE3E82", "#5E4352", "#7798AB", "#312509",
//                 "#C9CBA3", "#FF5E5B", "#00CECB", "#FFED66",
//                 "#0E3B43", "#357266", "#E26D5C", "#65532F",
//             ];
//             const initials = name.substr(0, 1).toUpperCase();
//             const charIndex = initials.charCodeAt(0) - 65;
//             const colourIndex = Math.abs(charIndex % (colours.length - 1));
//             const context = canvasRef.current.getContext("2d");
//
//
//             const dpr = window.devicePixelRatio || 1
//             const bsr = context.webkitBackingStorePixelRatio ||
//                 context.mozBackingStorePixelRatio ||
//                 context.msBackingStorePixelRatio ||
//                 context.oBackingStorePixelRatio ||
//                 context.backingStorePixelRatio || 1;
//             const pixelRatio = dpr / bsr;
//
//             // support high resolution
//             canvasRef.current.width = size * pixelRatio;
//             canvasRef.current.height = size * pixelRatio;
//             canvasRef.current.style.width = `${size}px`;
//             canvasRef.current.style.height = `${size}px`;
//             context.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
//
//             context.fillStyle = colours[colourIndex];
//             context.fillRect (0, 0, size, size);
//             context.font = `${size / 2}px Arial`;
//             context.textAlign = "center";
//             context.fillStyle = "#FFF";
//             context.fillText(initials, size / 2, size / 1.5);
//         }
//     }, [size, name]);
//
//
//     return (
//         <AvatarStyle ref={canvasRef} width={size} height={size} className={classNames('avatar', {[className || '']: className})} />
//     );
// };

type AvatarProps = {
    size: number;
    name: string;
    className?: string;
    avatarUrl?: string;
};

// const AvatarStyle = styled.canvas`
//     border-radius: 4px;
// `

export default Avatar;