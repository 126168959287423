import {useQueryClient} from "@tanstack/react-query";
import {useCallback} from "react";
import {Message} from "../../model/message";


const useUpdateUnreadMessages = () => {
    const queryClient = useQueryClient();

    return useCallback((channelId: string, messages: Message[]) => {
        const messagesByLiveChat: {[key: string]: Message[]} = {};
        messages.forEach(message => {
            const _messages = messagesByLiveChat[message.liveChatId] || [];
            _messages.push(message);
            messagesByLiveChat[message.liveChatId] = _messages;
        });

        queryClient.setQueryData<{[key: string]: Message[]}>(['unreadMessages', channelId], (oldData) => {
            const nonNullOldData = {...oldData ?? {}};
            Object.entries(messagesByLiveChat).forEach(([liveChatId, messages]) => {
                if (liveChatId in nonNullOldData) {
                    nonNullOldData[liveChatId] = [...nonNullOldData[liveChatId], ...messages]
                }
                else {
                    nonNullOldData[liveChatId] = messages;
                }
            });

            return nonNullOldData;
        });
    }, [queryClient]);
};


export default useUpdateUnreadMessages;