import {useQuery} from "@tanstack/react-query";
import {LiveChatError, toLiveChatError} from "../../error";
import {ReactQueryOptions} from "../index";
import {ChannelApiKey} from "../../model/channel";
import channelRepository from "../../repository/ChannelRepository";


const getData = async (channelId: string) => {
    try {
        const {data} = await channelRepository.findApiKeys(channelId);
        return data.result;
    } catch (e) {
        throw toLiveChatError(e);
    }

}


const useApiKeys = (channelId: string, options?: ReactQueryOptions) => {
    return useQuery<ChannelApiKey[], LiveChatError>(['apiKeys', channelId], async () => getData(channelId), {...options});
};


export default useApiKeys;