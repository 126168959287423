import {useQuery} from "@tanstack/react-query";
import channelRepository from "../../repository/ChannelRepository";
import {Channel} from "../../model/channel";
import {LiveChatError, toLiveChatError} from "../../error";


const getData = async (channelId: string) => {
    try {
        const {data} = await channelRepository.read(channelId);
        return data.result;
    } catch (e) {
        throw toLiveChatError(e);
    }

}


const useChannel = <TData=Channel>(channelId: string, select?: (data: Channel) => TData) => {
    return useQuery<Channel, LiveChatError, TData>(['channels', channelId], async () => getData(channelId), {suspense: false, select});
};


export default useChannel;