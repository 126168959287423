
const language: Record<string, string> = {
    e000001: '일시적인 오류로 처리할 수 없습니다.\n잠시후 다시 시도해 주세요.',
    e000002: '카카오 상담톡은 5MB 이하만 가능합니다',
    e000003: '파일전송 중에 오류가 발생하였습니다',
    e000004: '문자 잔액이 충분하지 않습니다',
    e000005: '문자 잔액 자동 결제에 실패하였습니다',
    e000006: "접근 권한이 없습니다.",
    e000007: "방문하시려는 페이지는 권한이 있는 사용자만 사용할 수 있습니다.\n채널 관리자에게 권한을 요청하시거나 권한이 있어도 같은 오류가 발생한다면 고객센터에 문의하여 주시기 바랍니다.",
    e000008: "페이지를 찾을 수 없습니다.",
    e000009: "방문하시려는 페이지의 주소가 잘못 입력되었거나, 페이지의 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.\n입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.",
    e000010: "서비스 이용에 불편을 드려 죄송합니다.\n시스템 에러가 발생하여 페이지를 표시할 수 없습니다. 고객센터에 문의하시거나 Home으로 이동 후 다시 시도해 주세요.",
    m01: '홈',
    m02: '채팅상담',
    m03: '설정',
    m04: '멤버',
    m05: '게시판',
    m06: '채널 알림',
    m0201: '검색',
    m0202: '이전 검색으로 돌아가기',
    m0203: '담당자 없음',
    m0204: '내 수신함',
    m0205: '안 읽은 메시지',
    m0206: '전체 수신함',
    m0207: '즐겨찾기',
    m0208: '시간 외 상담 신청',
    i000001: '내 상담 채널',
    i000002: '응대중',
    i000003: '담당자 없음',
    i000004: '보류중',
    i000005: '전체 수신함 (종료)',
    i000006: '메시지를 입력하세요.',
    i000007: '고객',
    i000008: '상담 시작',
    i000009: '종료',
    i000010: '상담 종료',
    i000011: '상담을 종료하시겠습니까?',
    i000012: '취소',
    i000013: '종료된 상담입니다.',
    i000014: '종료된 상담입니다.',
    i000015: '고객의 요청으로 취소된 상담입니다.',
    i000016: '확인',
    i000017: '새로운 메시지가 도착했습니다.',
    i000018: '상담 목록이 비어 있습니다.',
    i000019: '상담 목록이 없습니다.',
    i000020: '이름',
    i000021: '내용',
    i000022: '취소',
    i000023: '등록',
    i000024: '필수 입력 항목입니다.',
    i000025: '등록된 템플릿이 없습니다.',
    i000026: '자주 쓰는 메시지를 템플릿으로 만들어서 사용하세요.\n미리 등록된 템플릿으로 고객들과 효율적으로 대화할 수 있습니다.',
    i000027: '메시지 템플릿 만들기',
    i000028: '템플릿 만들기',
    i000029: '수정',
    i000030: '전송',
    i000031: '고객에게 보낼 메시지를 입력하세요.',
    i000032: '줄바꿈은 {key} 키를 누르세요.',
    i000033: '템플릿을 삭제 하시겠습니까?',
    i000034: '삭제',
    i000035: '챗봇 메시지 설정',
    i000036: '고객이 상담사 연결과 관련해 챗봇이 응답할 메시지를 설정하세요.',
    i000037: '상담 요청 메시지',
    i000038: '연결 취소 메시지',
    i000039: '부재중 메시지',
    i000040: '저장',
    i000041: '상담 운영시간 설정',
    i000042: '요일별로 상담 운영시간을 설정 하세요.\n수동으로도 상담 가능 여부를 설정할 수 있습니다.',
    i000043: '일',
    i000044: '월',
    i000045: '화',
    i000046: '수',
    i000047: '목',
    i000048: '금',
    i000049: '토',
    i000050: '상담 운영시간 설정 방법',
    i000051: '수동으로 설정',
    i000052: '요일별 시간 설정',
    i000053: '운영 상태',
    i000054: '운영중',
    i000055: '부재중',
    i000056: '요일',
    i000057: '시간',
    i000058: '추가',
    i000059: '시간대',
    i000060: '상담이력',
    i000061: '대기중',
    i000062: '보류중',
    i000063: '상담중',
    i000064: '고객이 상담을 요청했습니다.',
    i000065: '새로운 메시지가 도착했습니다.',
    i000066: '메모',
    i000067: '상담에 대한 설명이나 필요한 정보를 저장하세요.',
    i000068: '고객님이 상담을 종료 했습니다.',
    i000069: '파일 첨부',
    i000070: '새로운 상담을 요청했습니다.',
    i000071: '로그아웃',
    i000072: '새로운 업데이트를 사용할 수 있습니다. 업데이트 하시겠습니까?',
    i000073: '업데이트',
    i000074: '알림 설정',
    i000075: '새로운 상담, 새 메시지에 대한 알림을 설정 할 수 있습니다.',
    i000076: '알림 설정을 적용 중 에러가 발생했습니다.',
    i000077: '알림 허용',
    i000078: '참여 중인 대화방의 모든 메시지에 대한 알림과 새로운 상담에 대한 알림을 모두 받습니다.',
    i000079: '알림 허용을 비활성화 하면 다른 알림 설정에 상관없이 모든 알림을 받지 않습니다.',
    i000080: '새 상담 알림 받기',
    i000081: '새로운 상담 요청이 도착하면 알림을 받습니다.',
    i000082: '메시지 알림 받기',
    i000083: '참여 중인 대화방의 모든 메시지에 대한 알림을 받습니다.',
    i000084: '상담 목록을 불러오지 못했습니다.',
    i000085: '배정됨',
    i000086: '담당자 없음',
    i000087: '이름',
    i000088: '프로필 설정',
    i000089: '채널에서 사용할 이름을 입력하세요.',
    i000090: '채널 프로필 정보 저장 중 에러가 발생했습니다.',
    i000091: '채널 정보 조회중 오류가 발생했습니다.',
    i000092: '채널 프로필 정보 조회 중 에러가 발생했습니다.',
    i000093: '상담 카테고리 설정',
    i000094: '주로 상담하는 카테고리를 설정하세요.\n상담 분배 설정이 카테고리별로 되어 있으면 설정한 카테고리를 우선적으로 분배 받을 수 있습니다.',
    i000095: '카테고리 관리',
    i000096: '상담사 카테고리 목록 조회 중 오류가 발생했습니다.',
    i000097: '카테고리 목록 조회 중 오류가 발생했습니다.',
    i000098: '등록된 카테고리가 없습니다.',
    i000099: '카테고리 삭제 중 에러가 발생했습니다.',
    i000100: '카테고리 추가 중 에러가 발생했습니다.',
    i000101: '상담 카테고리를 설정해서 카테고리 별로 상담을 분류하고 어떤 종류의 상담이 많은지 확인할 수 있습니다.\n상담 분배 설정에서 카테고리 별로 상담사를 배치해 상담을 분배 할 수 있습니다.',
    i000102: '상담 운영시간 수정 중 에러가 발생했습니다.',
    i000103: '상담 운영시간을 수정했습니다.',
    i000104: '채널 정보 조회 중 에러가 발생했습니다.',
    i000105: '상담 메모를 저장했습니다.',
    i000106: '챗봇 메시지를 저장했습니다.',
    i000107: '채널 목록 조회 중 에러가 발생했습니다.',
    i000108: '메시지 템플릿 삭제 중 에러가 발생했습니다.',
    i000109: '채널과 연동할 챗봇 시나리오 목록을 불러오지 못했습니다.',
    i000110: '새 채널 만들기',
    i000111: '아직 생성된 채널이 없네요.\n채널을 만들어서 챗봇과 채팅을 사용해서 효율적인 상담업무를 체험해보세요.',
    i000112: '채널명',
    i000113: '시나리오 선택',
    i000114: '선택 할 시나리오가 없으면 Conversation Maker에서 시나리오를 만들어 주세요.',
    i000115: '만들기',
    i000116: '대화 정보를 불러 오지 못했습니다.',
    i000117: '카테고리 정보를 불러 오지 못했습니다.',
    i000118: '카테고리 만들기',
    i000119: '카테고리명',
    i000120: '카테고리 저장 중 에러가 발생했습니다.',
    i000121: '카테고리를 삭제 하시겠습니까?',
    i000122: '해당 카테고리가 적용된 모든 상담챗에 변경사항이 적용됩니다.',
    i000123: '카테고리 삭제 중 에러가 발생했습니다.',
    i000124: '카테고리 수정하기',
    i000125: '해당 카테고리가 이미 존재합니다.',
    i000126: '카테고리',
    i000127: '상담 카테고리를 선택해주세요.',
    i000128: '사용자의 챗봇 시나리오 정보가 없습니다. 시나리오를 먼저 등록해 주세요.',
    i000129: '채널정보 다시 불러오기',
    i000130: '등록된 카테고리가 없습니다.',
    i000131: '상담수',
    i000132: '상담사 수',
    i000133: '생성일',
    i000134: '상담 분배 방식 설정',
    i000135: '순차 및 카테고리 우선 분배 방식으로 상담을 분배 할 수 있습니다.' +
        '\n카테고리 우선 분배는 고객이 상담 요청시 설정한 카테고리를 담당하고 있는 상담사에게 우선 배정됩니다.' +
        '\n\n고객이 상담을 요청하면 어느 상담사에게 분배할지 결정해보세요.',
    i000136: '분배 방식',
    i000137: '순차 분배',
    i000138: '카테고리 우선 분배',
    i000139: '카테고리 별 상담 제한 수',
    i000140: '상담 분배 방식을 수정했습니다.',
    i000141: '상담 분배 방식 수정 중 에러가 발생했습니다.',
    i000142: '금칙어 관리',
    i000143: '금칙어를 등록해서 욕설, 선정적인 발언, 비하 발언 등을 마스킹 처리할 수 있습니다.',
    i000144: '금칙어',
    i000145: '금칙어를 삭제 하시겠습니까?',
    i000146: '검색',
    i000147: '기본 금칙어 등록',
    i000148: '채널 멤버 관리',
    i000149: '채널 내 멤버를 관리 하세요.',
    i000150: '멤버 추가',
    i000151: '이메일',
    i000152: '비밀번호',
    i000153: '이름',
    i000154: '유형',
    i000155: '멤버',
    i000156: '소유자',
    i000157: '이메일 형식이 올바르지 않습니다.',
    i000158: '채널 멤버 등록 중 에러가 발생했습니다.',
    i000159: '채널 멤버 조회 중 에러가 발생했습니다.',
    i000160: '채널 멤버가 없습니다.',
    i000161: '에러가 발생했습니다. 다시 불러오기',
    i000162: '채널 멤버 삭제',
    i000163: '채널 멤버를 삭제하시겠습니까? 삭제한 멤버는 채널에 접근 할 수 없습니다.',
    i000164: '채널 멤버 삭제 중 에러가 발생했습니다.',
    i000165: '고객 정보',
    i000166: '전화번호',
    i000167: '만족도',
    i000168: '고객 정보 요청하기',
    i000169: '상담 정보',
    i000170: '카테고리',
    i000171: '요청 시간',
    i000172: '시작 시간',
    i000173: '프로필 설정',
    i000174: '상담 프로필 이미지와 이름을 설정할 수 있습니다.',
    i000175: '이름',
    i000176: '프로필 저장 중 에러가 발생했습니다.',
    i000177: '상담',
    i000178: '여기까지 읽었습니다.',
    i000179: '상담 시작 메시지',
    i000180: '수동 분배',
    i000181: '공유',
    i000182: '템플릿 공유',
    i000183: '메시지 템플릿 공유 설정을 변경했습니다.',
    i000184: '메시지 템플릿 공유 설정 중 에러가 발생했습니다.',
    i000185: '메시지 템플릿 목록을 불러오지 못했습니다.',
    i000186: '상담 종료 메시지',
    i000187: '공유 템플릿',
    i000188: '상담사 설정',
    i000189: '채널 설정',
    i000190: '개발',
    i000191: '인증키 관리',
    i000192: 'Webhook 관리',
    i000193: 'API를 사용할 때 필요한 인증키를 관리 합니다.' +
        '\nAPI를 사용하면 채널에서 일어나는 이벤트를 처리 할 수 있습니다.' +
        '\n상담 챗이 열렸을 때, 메시지를 주고 받을 때, 봇을 만들어서 메시지를 보내는 등 다양한 이벤트를 처리 할 수 있습니다.',
    i000194: '인증키 목록을 불러오지 못했습니다.',
    i000195: '등록된 인증키가 없습니다. API를 사용하려면 인증키를 만들어 주세요.',
    i000196: '인증키 등록',
    i000197: '인증키 등록 중 에러가 발생했습니다.',
    i000198: '인증키를 삭제하시겠습니까? 삭제한 인증키로 API 요청을 할 수 없습니다.',
    i000199: '인증키를 삭제 중 에러가 발생했습니다.',
    i000200: '인증키를 식별할 수 있는 이름을 입력하세요.',
    i000201: 'Webhook 목록을 불러오지 못했습니다.',
    i000202: 'Webhook을 설정해서 채널에서 일어나는 이벤트를 외부 도구와 통합합니다.' +
        '\n상담 챗이 열렸을 때, 메시지를 주고 받을 때 등 다양한 이벤트를 처리 할 수 있습니다.',
    i000203: 'Webhook 등록',
    i000204: '등록된 Webhook이 없습니다. 외부 도구와 통합하려면 Webhook을 만들어 주세요.',
    i000205: 'Webhook을 삭제하시겠습니까? 삭제 후에는 되돌릴 수 없습니다.',
    i000206: 'Webhook 삭제 중 에러가 발생했습니다.',
    i000207: 'Webhook 등록 중 에러가 발생했습니다.',
    i000208: 'Webhook은 POST 방식, Content-Type은 application/json 으로 전송됩니다.',
    i000209: 'URL 형식이 아닙니다.',
    i000210: 'Webhook 정보를 불러오지 못했습니다.',
    i000211: '뒤로',
    i000212: 'Webhook을 삭제하면 이벤트에 대한 통합이 중단됩니다.',
    i000213: 'Token 값은 HTTP 헤더에 "Authorization: token" 으로 추가됩니다.',
    i000214: 'Webhook 정보를 수정했습니다.',
    i000215: '채널 생성 중 에러가 발생했습니다.',
    i000216: '고객 정보 요청 중 에러가 발생했습니다.',
    i000217: '상담 종료 중 에러가 발생했습니다.',
    i000218: '금칙어를 삭제 하면 기존 메시지의 마스킹이 모두 제거 됩니다.',
    i000219: '금칙어 등록 중 에러가 발생했습니다.',
    i000220: '파일업로드 중 에러가 발생했습니다.',
    i000221: '다운로드를 실패했습니다.',
    i000222: '파일이 삭제되었거나 존재하지 않는 파일입니다.',
    i000223: '파일',
    i000224: '템플릿으로 만들기',
    i000225: '채널 만들러 가기',
    i000226: '상담 보류',
    i000227: '보류 해제',
    i000228: '상담을 보류 상태로 변경 중 에러가 발생했습니다.',
    i000229: '상담 보류 해제 중 에러가 발생했습니다.',
    i000230: '메시지 템플릿',
    i000231: '추천 답변',
    i000232: '이모티콘',
    i000233: '초기화',
    i000234: '검색결과 없음',
    i000235: '검색 결과',
    i000236: '자주 사용함',
    i000237: '감정',
    i000238: '감정&사람',
    i000239: '동물&자연',
    i000240: '음식&음료',
    i000241: '활동',
    i000242: '여행&장소',
    i000243: '사물',
    i000244: '기호',
    i000245: '국기',
    i000246: '이모티콘 카테고리',
    i000247: '상담사',
    i000248: '고객이 상담 요청전에 나눈 챗봇 대화를 참고하세요.',
    i000249: '템플릿으로 만들수 없는 내용이 포함되었습니다.',
    i000250: '초대 링크 보내기',
    i000251: '이벤트',
    i000252: '채팅',
    i000253: '메시지',
    i000254: '멤버',
    i000255: '수신할 이벤트를 선택하세요.',
    i000256: '채널에 초대한 멤버 정보 조회 중 에러가 발생했습니다.',
    i000257: '초대중인 멤버',
    i000258: '초대일시',
    i000259: '초대 메일 재전송',
    i000260: '초대 메일을 전송했습니다.',
    i000261: '이미 초대한 멤버입니다.',
    i000262: '상담 메모를 저장 중 에러가 발생했습니다.',
    i000263: '뒤로가기',
    i000264: '앞으로가기',
    i000265: '새로고침',
    i000266: '내 수신함',
    i000267: '시스템 아이디',
    i000268: '이름',
    i000269: '전화번호',
    i000270: '이메일',
    i000271: '최초 접속 시간',
    i000272: '최근 접속 시간',
    i000273: '총 접속 횟수',
    i000274: '브라우저',
    i000275: '디바이스',
    i000276: 'OS',
    i000277: '없음',
    i000278: '엔터키를 눌러 저장하세요.',
    i000279: '태그',
    i000280: '쉼표(,)로 구분해서 입력하세요.',
    i000281: '타이핑',
    i000282: '상담 운영시간에 고객이 상담을 요청하면 자동으로 전송되는 메시지입니다.',
    i000283: '고객이 상담 요청 후 취소 버튼에 표시될 문구 입니다.',
    i000284: '상담 운영시간이 아닌 경우 고객이 상담을 요청하면 자동으로 전송되는 메시지입니다.',
    i000285: '상담원이 연결되면 자동으로 전송되는 메시지입니다.',
    i000286: '상담원이 상담 종료 버튼을 누르면 자동으로 전송되는 메시지입니다.',
    i000287: '예) 상품문의, 배송문의, 교환문의, 반품문의, 가격문의 등',
    i000288: '* 순차 분배 : 고객이 상담을 요청하면 상담 수가 적은 상담사에게 자동 분배됩니다.\n' +
        '* 카테고리 우선 분배 : 고객이 상담을 요청하면서 선택한 카테고리를 담당하는 상담사에게 자동 분배됩니다.\n' +
        '* 수동 분배 : 고객이 상담을 요청하면 대기 상태로 들어가고, 상담사가 직접 고객을 선택하여 응대합니다.',
    i000289: '카테고리 담당 상담사가 분배받은 상담이 제한 수를 초과하는 경우에는 원활한 상담을 위해 상담 수가 적은 다른 상담사에게 분배됩니다.',
    i000290: '추가 된 운영시간이 없습니다. 상담 운영시간을 추가하세요.',
    i000291: '내용을 입력하세요.',
    i000292: '상태',
    i000293: "대기중",
    i000294: "만료됨",
    i000295: "초대 취소",
    i000296: "초대 취소를 하시면 {username}은 초대 링크에 접근 할 수 없습니다.",
    i000297: "멤버 초대를 취소 했습니다.",
    i000298: "초대 할 사용자의 이메일을 입력하세요.",
    i000299: "초대 링크 복사",
    i000300: "초대 취소",
    i000301: "알림",
    i000302: "취소",
    i000303: "확인",
    i000304: "등록된 금칙어가 없습니다.",
    i000305: "설정에서 카테고리를 등록하세요.",
    i000306: "익명",
    i000307: "댓글등록",
    i000308: "게시글 삭제",
    i000309: "게시글을 지웁니다.",
    i000310: "글쓰기",
    i000311: "새글 알람을 받고 있습나디.",
    i000312: "새글 알람을 받지 않습니다.",
    i000313: "상담원 정보",
    i000314: "{name}님을 대화방에서 내보내시겠습니까?",
    i000315: "담당자",
    i000316: "추가할 상담원 이름을 입력하세요.",
    i000317: "추가",
    i000318: "내보내기",
    i000319: "전체 수신함",
    i000320: "참여하기",
    i000321: "검색된 상담원이 없습니다.",
    i000322: "대화방에서 나가시겠습니까?",
    i000323: "나가기",
    i000324: "전체보기",
    i000325: "돌아가기",
    i000326: "등록된 내용이 없습니다.",
    i000327: "(상담톡 : 상담 시작시 100원이 과금됩니다)",
    i000328: "전송 실패",
    i000329: "창닫기",
    i000330: "전화번호는 숫자만 입력해주세요.",
    i000331: "다운로드가 완료되었습니다.",
    i000332: "파일 다운로드 목록",
    i000333: "온라인",
    i000334: "오프라인",
    i000335: "역할 관리",
    i000336: "역할별로 멤버의 접근 권한을 관리할 수 있습니다.",
    i000337: "역할 등록",
    i000338: "그룹",
    i000339: "이름",
    i000340: "권한",
    i000341: "역할 기본 정보",
    i000342: "역할 코드를 입력하세요.",
    i000343: "역할 이름을 입력하세요.",
    i000344: "권한 설정",
    i000345: "역할 설정",
    i000346: "전체",
    i000347: "기본",
    i000348: "코드",
    i000349: "코드는 영문, 숫자, '-', '_' 만 입력 가능합니다.",
    i000350: "메니저용 링크 복사",
    i000351: "고객용 링크 복사",
    i000352: "메니저용 링크가 클립보드에 복사되었습니다.",
    i000353: "고객용 링크가 클립보드에 복사되었습니다.",
    i000354: "현재 최신 버전입니다.",
    i000355: "업데이트 항목을 다운로드 중입니다..",
    i000356: "수정",
    i000357: "기본",
    i000358: "멤버 정보를 수정했습니다.",
    i000359: "멤버 정보 수정",
    i000360: "오프라인 시 이메일 알림 받기",
    i000361: "오프라인일 때 참여 중인 대화방의 메시지 알림을 이메일로 받습니다.",
    i000362: "상담 자동 종료 설정",
    i000363: "설정한 자동 종료 시간 초과 시 자동 종료 메시지가 고객에게 발송되면서 상담이 종료됩니다. 상담이 종료되면 챗봇모드로 자동 전환됩니다.",
    i000364: "상담 자동 종료 시간",
    i000365: "동안 고객 추가 문의 없을 때 상담이 자동으로 종료되며 챗봇모드로 전환됩니다.",
    i000366: "고객이 추가 문의 없을 때 상담 자동 종료",
    i000367: "분",
    i000368: "종료 안내 메시지 발송 시간(분)",
    i000369: "동안 추가 문의가 없는 고객에게 안내 메시지가 전송됩니다.",
    i000370: "상담 자동 종료 메시지",
    i000371: "상담 자동 종료 메시지를 입력하세요.",
    i000372: "앞 시간보다 작게 입력하세요.",
    i000373: "동안 회신하지 못할 시 고객에게 안내 메시지가 전송됩니다.",
    i000374: "동안 추가 문의가 없는 고객에게 안내 메시지가 전송됩니다.",
    i000375: "동안 상담원이 회신을 못할 때 상담이 자동으로 종료되며 챗봇모드로 전환됩니다.",
    i000376: "상담원이 회신을 못할 때 상담 자동 종료",
    i000377: "설정이 반영되었습니다.",
    i000378: "고객이 쇼핑몰에서 주문한 내역을 확인할 수 있습니다.",
    i000379: '새로운 업데이트를 사용할 수 있습니다.',
    i000380: '채팅방 우선순위 설정',
    i000381: '채팅방 우선순위를 설정하세요. 색상과 우선순위명을 변경할 수 있습니다.',
    i000382: '우선순위',
    i000383: '종료됨',
    i000384: '이미지 URL을 불러오는데 실패 했습니다.',


    i100001: "게시판",
    i100002: "연동된 게시판이 없습니다.",
    i100003: "제목",
    i100004: "작성자",
    i100005: "작성일",
    i100006: "댓글목록",
    i100007: "조회수",
    i100008: "상담사",
    i100009: "작성자는 필수입니다.",
    i100010: "제목은 필수입니다.",
    i100011: "내용은 필수입니다.",
    i100012: "답변쓰기",
    i100013: "등록된 댓글이 없습니다.",
    i100014: "게시글 수정",
    i100015: "게시글을 수정합니다.",
    i100016: "새글 불러오기",
    i100017: "연동된 쇼핑몰에서 새글을 불러오는데 지연될 수 있습니다. 잠시후 다시 시도해주세요.",
    i100018: "정보패널",
    i100019: "상담정보",
    i100020: "고객정보",
    i100021: "상담이력",
    i100022: "방문 페이지",
    i100023: "메모 추가...",
    i100024: "일반",
    i100025: "카테고리를 수정할 수 없습니다. 다시 시도해 주세요.",
    i100026: "채팅방 설정",
    i100027: "상담이 보류되었습니다.",
    i100028: "상담 보류 해제되었습니다.",
    i100029: "네",
    i100030: "아니오",
    i100031: "생성 일자",
    i100032: "즐겨찾기",
    i100033: "상담원 설정",
    i100034: "상담원",
    i100035: "담당자 변경에 실패했습니다.",
    i100036: "잠시후 다시 시도해주세요.",
    i100037: "이름으로 찾기",
    i100038: "상담원 추가에 실패했습니다.",
    i100039: "담당자 변경하기",
    i100040: "상담원 내보내기",
    i100041: "상담원 추가하기",
    i100042: '"{name}"님이 채팅방에서 나갔습니다.',
    i100043: '"{name}"님이 채팅방에 참여했습니다.',
    i100044: '상담 중 주고받은 파일을 확인할 수 있습니다.',
    i100045: '파일 모아보기',
    i100046: "'파일모아보기'를 사용할 수 없습니다.",
    i100047: "정보를 불러오지 못했습니다.",
    i100048: "더보기",
    i100049: "접기",
    i100050: "고객이 방문한 페이지는 최대 7일까지만 저장됩니다.",
    i100051: "장바구니 화면",
    i100052: "상품정보 화면",
    i100053: "주문 화면",
    i100054: "메인 화면",
    i100055: "회원가입 화면",
    i100056: "로그인 화면",
    i100057: "주문 내역",
    i100058: "클립보드에 복사되었습니다.",
    i100059: "날짜를 선택하시면 새로운 휴무일 선택이 가능합니다.",
    i100060: "드래그 앤 드랍으로 휴무일을 옮길 수 있습니다.",
    i100061: "휴무일을 선택하시면 삭제할 수 있습니다.",
    i100062: "오늘",
    i100063: "주",
    i100064: "상담 운영시간을 설정합니다.",
    i100065: "휴무일",
    i100066: "상담 휴무일 설정",
    i100067: "상담시간",
    i100068: "'휴무일'로 지정된 날짜는 '운영시간' 에도 상담요청을 받지 않으며 상담 요청시 '부재중 안내 메시지'를 고객에게 전달합니다.\n또한 등록된 '휴무일'은 캘린더와 등록 내역에서 확인 및 편집이 가능합니다.",

    i100069: "휴무일 추가",
    i100070: "휴무일 수정",
    i100071: "설명",
    i100072: "휴무일 설정하기",
    i100073: "휴무일 등록하기",
    i100074: "상담운영시간 {start} ~ {end}",
    i100075: "날짜",
    i100076: "시간 설정",
    i100077: "사용자 안내 메시지",
    i100078: "휴무일의 '설명'은 필수입니다.",
    i100079: "휴무일의 '고객 문구'는 필수입니다.",
    i100080: "휴무일 설정이 변경되었습니다.",
    i100081: "휴무일의 '고객 문구'는 필수입니다.",
    i100082: "휴무일이 저장되었습니다.",
    i100083: "휴무일이 삭제되었습니다.",
    i100084: "매 년 반복",
    i100085: "휴무일 등록 내역 ({year})",
    i100086: "휴무일을 삭제하시겠습니까?",
    i100087: "사용할 수 없는 '날짜' 범위입니다.",
    i100088: "등록된 휴무일이 없습니다.",
    i100089: "즐겨찾기 등록 중 에러가 발생했습니다.",
    i100090: "즐겨찾기 해제 중 에러가 발생했습니다.",
    i100091: "금칙어 초기화",
    i100092: "저장된 금칙어를 모두 초기화합니다.",
    i100093: "상담원 입력 메시지 중 금칙어가 포함되어 있을 경우 확인 후 전송(비활성화시 모든 메시지 즉시 전송)",
    i100094: "욕설 및 비속어를 금칙어로 등록합니다.",
    i100095: "재전송",
    i100096: "욕설 및 비속어를 금칙어로 등록합니다.",
    i100097: "금칙어 포함",
    i100098: "(Shift + Enter) 키로 전송",
    i100099: "자리 비움",
    i100100: "사유",
    i100101: "회의 중",
    i100102: "이동 중",
    i100103: "식사 중",
    i100104: "다른 업무 중",
    i100105: "휴가 중",
    i100106: "오늘",
    i100107: "이번 주",
    i100108: "남은 시간",
    i100109: "{minute}분",
    i100110: "{hour}시간 {minute}분",
    i100111: "{hour}시간",
    i100112: "현재 상태",
    i100113: "총 온라인 시간",
    i100114: "대기",
    i100115: "무시하고 전송",
    i100116: "담당자 없음",
    i100117: "상담 대기",
    i100118: "유지시간",
    i100119: "실시간 모니터링",
    i100120: "자동 업데이트 : 1분 간격",
    i100121: "시간 경과 알림",
    i100122: "현재 목록",
    i100123: "현재 상태",
    i100124: "유지 시간",
    i100125: "초과시",
    i100126: "대시보드에 표시",
    i100127: "대시보드에 표시하고 알림 전달",
    i100128: "유지 시간은 필수입니다.",
    i100129: "경과 알림이 추가되었습니다.",
    i100130: "{minute}분 초과 시",
    i100131: "경과 알림 삭제 중",
    i100132: "삭제되었습니다.",
    i100133: "시간 경과 알림을 추가하면 현재 목록에 표시됩니다.",
    i100134: "방금 전",
    i100135: "알림 전달",






    i200001: "사진을 등록하는 도중 에러가 발생했습니다.",
    i200002: "프로필을 저장했습니다.",
    i200003: "설정을 반영 중입니다.",
    i200004: "종료 메시지를 입력해주세요.",
    i200005: "메시지를 입력해주세요.",
    i200006: "상담사 응답 시간 분포",
    i200007: "상담사 응답 시간",
    i200008: "내역 없음",
    i200009: "대시보드",
    i200010: "오늘",
    i200011: "어제",
    i200012: "최근 7일",
    i200013: "이번 달",
    i200014: "저번 달",
    i200015: "전체",
    i200016: "응답 현황",
    i200017: "{coverage}에 대한 통계 요약 ({category})",
    i200018: "상담 수락 시간",
    i200019: "상담사 응답 시간",
    i200020: "상담 처리 시간",
    i200021: "상담 수락 시간 분포 ({count}건 조회됨)",
    i200022: "상담사 응답 시간 분포 ({count}건 조회됨)",
    i200023: "상담 처리 시간 분포 ({count}건 조회됨)",
    i200024: "서비스 현황",
    i200025: "{value}초",
    i200026: "평균값",
    i200027: "중앙값",
    i200028: "전체 시간",
    i200029: "운영 시간",
    i200030: "시간",
    i200031: "최초 응대 시간",
    i200032: "최초 응대 시간 분포 ({count}건 조회됨)",
    i200033: "총 상담 시간",
    i200034: "총 상담 시간 분포 ({count}건 조회됨)",
    i200035: "상담 이탈률",
    i200036: "퍼센트(%)",
    i200037: "비율",
    i200038: "통계",
    i200039: "대시보드",
    i200040: "3분 미만",
    i200041: "3분 ~ 10분",
    i200042: "10분 ~ 30분",
    i200043: "30분 ~ 1시간",
    i200044: "1시간 초과",
    i200045: "{day}일 ",
    i200046: "{hour}시간 ",
    i200047: "{minute}분 ",
    i200048: "{second}초",
    i200049: "고객이 상담을 신청한 시점부터 상담원이 상담을 수락하는 시점까지 소요한 시간입니다.",
    i200050: "고객이 보낸 메시지에 상담원이 응답할 때까지 소요한 시간입니다.\n고객이 보낸 마지막 메시지부터 상담원이 응답하는 첫번째 메시지까지의 시간이 통계에 반영됩니다.",
    i200051: "고객이 처음 메시지를 보낸 순간부터 상담이 종료되기 전 상담 담당자의 마지막 메시지까지 소요된 시간입니다.\n총 상담시간보다 짧을 수 있습니다.",
    i200052: "상담이 시작된 후 상담 담당자가 고객의 메시지에 처음 응대하기까지 소요된 시간입니다.",
    i200053: "상담을 시작한 시점부터 상담을 종료한 시점까지 소요한 시간입니다.\n상담원이 상담을 종료하지 않을 경우 통계값이 매우 커질 수 있습니다.\n설정 메뉴에서 ‘상담 자동 종료’ 기능으로 상담을 자동 종료할 수 있습니다.",
    i200054: "고객이 상담을 신청하였으나 상담원이 상담을 수락하기 전에 채팅방을 종료하면 상담 이탈이 됩니다.\n상담 이탈률은 해당 기간 동안 상담 신청이 이탈된 비율로 계산됩니다.",
    i200055: "응답 현황 통계 도움말",
    i200056: "응답 현황 통계는 빠르게 현재 채팅 상담에 대한 지표를 확인할 수 있는 통계입니다. " +
        "상담 수락 시간, 상담원 응답 시간, 상담 처리 시간에 대한 그래프를 제공합니다. " +
        "평균값/중앙값, 전체시간/운영시간으로 통계의 기준을 변경하여 그래프를 볼 수 있습니다.",
    i200057: "평균값이 이상치로 인해 대표성을 잃고 데이터의 전반적인 양상을 반영하지 못한다면 중앙값으로 기준을 변경할 수 있습니다.",
    i200058: "응답 현황 통계는 정상적으로 종료된 상담에 대해서만 데이터를 산출합니다. 단, 응답 시간은 실시간으로 데이터를 반영합니다.",
    i200059: "응답 현황에 대한 통계 차트가 없습니다. 대시보드 옆 설정 버튼을 통해서 차트를 활성화해보세요.",
    i200060: "차트 숨겨짐",
    i200061: "고객 응답 시간",
    i200062: "고객 응답 시간 분포 ({count}건 조회됨)",
    i200063: "상담원이 보낸 메시지에 고객이 응답할 때까지 소요된 시간입니다.\n상담원이 보낸 마지막 메시지부터 고객이 응답하는 첫번째 메시지까지의 시간이 통계에 반영됩니다.",
    i200064: "({period} 대비)",
    i200065: "상담 품질 지표",
    i200066: "차트 설정",
    i200067: "상담 이탈률",
    i200068: "급증",
    i200069: "{count}건 조회됨",
    i200070: "상담이 마무리된 후 상담이 종료 처리되기까지 소요된 시간입니다. 종료 메시지가 설정된 경우 처리 시간이 짧게 측정될 수 있습니다.",
    i200071: "상담 후 처리 시간 분포",
    i200072: "모두 받기",
    i200073: "파일을 압축하는 과정에서 오류가 발생했습니다.",
    i200074: "파일을 압축하는 중입니다.",
    i200075: "압축된 파일을 다운로드하고 있습니다.",
    i200076: "한 번에 100MB 미만의 파일만 업로드할 수 있습니다.",
    i200077: "지원하지 않는 파일 형식입니다.",
    i200078: "(비활성화된 유저)",
    i200079: "고객용 링크를 생성할 수 없는 상담입니다.",
    i200080: "고객용 링크를 복사하는 도중 에러가 발생했습니다.",
    i200081: "커스텀 패널 관리",
    i200082: "상담 화면 우측에 패널을 직접 추가할 수 있습니다. 커스텀 패널은 규칙에 맞게 작성된 json 값을 토대로 렌더링됩니다.\n" +
        "json 값은 커스텀 패널 스니펫에 작성된 URL로부터 받아옵니다. " +
        "문서와 데모를 참고하여 스니펫을 작성하고 커스텀 패널을 추가해 보세요.",
    i200083: "현재 등록된 커스텀 패널 스니펫 목록입니다.",
    i200084: "스니펫 등록하기",
    i200085: "문서",
    i200086: "스니펫을 삭제했습니다.",
    i200087: "스니펫을 삭제하는 도중 에러가 발생했습니다.",
    i200088: "이름",
    i200089: "URL",
    i200090: "API Token",
    i200091: "수정된 날짜",
    i200092: "수정 & 삭제",
    i200093: "커스텀 패널 스니펫 삭제",
    i200094: "커스텀 패널 스니펫을 삭제하시겠습니까?\n채팅 상담에서도 커스텀 패널을 볼 수 없게 됩니다.",
    i200095: "삭제",
    i200096: "데모",
    i200097: "스니펫을 추가했습니다",
    i200098: "스니펫을 추가하는 도중 에러가 발생했습니다.",
    i200099: "커스텀 패널 스니펫 등록하기",
    i200100: "커스텀 패널 이름",
    i200101: "이름을 입력하세요",
    i200102: "이미 존재하는 이름입니다.",
    i200103: "커스텀 패널 스니펫 요청 URL",
    i200104: "URL을 입력하세요",
    i200105: "커스텀 패널 구성을 위해 위 URL로 GET 요청을 보냅니다.",
    i200106: "스니펫을 수정했습니다.",
    i200107: "스니펫을 수정하는 도중 에러가 발생했습니다.",
    i200108: "커스텀 패널 목록",
    i200109: "커스텀 패널 로딩중...",
    i200110: "패널 로딩에 실패했습니다. URL 요청에 실패했거나 잘못된 스니펫으로 응답했을 수 있습니다. 채널관리자에게 스니펫 작동 여부에 대해 문의하세요.",
    i200111: "등록된 커스텀 패널이 없습니다. 개발 설정에서 추가해보세요.",
    i200112: "이미 추가된 패널입니다. 우측 상단 패널 설정을 확인해보세요.",
    i200113: "잠시 후 다시 시도해주세요.",
    i200114: "커스텀 패널 추가하기",
    i200115: "커스텀 패널 선택",
    i200116: "(이미 추가됨)",
    i200117: "취소",
    i200118: "추가",
    i200119: "커스텀 패널 삭제",
    i200120: "커스텀 패널을 삭제하시겠습니까?\n 다시 패널을 추가하려면 커스텀 패널 추가하기 버튼을 클릭하세요.",
    i200121: "{name} ({type})",
    i200122: "삭제된 커스텀 패널입니다.",
    i200123: "커스텀 패널 스니펫 수정하기",
    i200124: "스니펫을 저장하는 중입니다.",
    i200125: "Query 파라미터 (Optional)",
    i200126: "잘못된 형식의 파라미터입니다.",
    i200127: "추가하기",
    i200128: "상담 중인 고객에 대한 정보를 원하는 이름의 query 파라미터로 사용할 수 있습니다.",
    i200129: "커스텀 패널 예시",
    i200130: "텍스트 컴포넌트 예시",
    i200131: "리스트 컴포넌트 예시",
    i200132: "1. 1번 항목",
    i200133: "2. 2번 항목",
    i200134: "이미지 & 리스트 컴포넌트 조합 예시",
    i200135: "키-값 컴포넌트 예시",
    i200136: "스윙챗 홈페이지로 이동하기",
    i200137: "스니펫을 직접 작성하고 어떻게 커스텀 패널을 구성할지 테스트해보세요.",
    i200138: "수정",
    i200139: "상담 품질 지표 통계 도움말",
    i200140: "상담 품질 지표는 채팅 상담 품질을 확인할 수 있는 통계입니다.\n" +
        "상담 수락 시간, 고객 응답 시간, 상담 이탈률 등 채팅 상담 품질에 대한 지표와 차트를 제공합니다.",
    i200141: "상단 블록에는 지표를 요약하여 표시합니다. 설정한 기간 기준으로 동일한 간격의 이전 조회 기간과 대비하여 변동율을 나타냅니다. 상승 또는 하락한 상태를 한 눈에 확인할 수 있습니다.",
    i200142: "하단 그래프에는 각 지표에 대한 분포를 보여줍니다. 그래프를 통해 어느 시간대에 가장 많이 분포하는지 확인할 수 있습니다. 단, 상담 이탈률의 경우에는 시간 단위가 아닌 날짜 단위로 분포를 보여줍니다.",
    i200143: "새로고침",


    i210001: "후에 상담이 강제로 종료되며 챗봇모드로 전환됩니다.",
    i210002: "상담 배정 후 일정 시간이 지나면 상담 강제 종료",
    i210003: "상담 진행 여부와 관계없이 상담이 강제로 종료됩니다. 상담 제한 시간을 두고 싶은 경우에만 활성화해주세요.\n이 설정을 활성화하면 다른 자동 종료 설정을 이용할 수 없습니다.",
    i210004: "주의 ",
    i210005: "사용자 설정 기한",
    i210006: "YYYY년 MM월 DD일",
    i210007: "채팅 내용에서 검색",
    i210008: "최근 검색",
    i210009: "필터 초기화",
    i210010: "\"{search}\"에 대한 검색 결과",
    i210011: "총 {count}건",
    i210012: "검색 결과가 없습니다.",
    i210013: "새로운 검색어를 입력하거나 필터를 바꿔서 검색해보세요.",
    i210014: "날짜",
    i210015: "이름으로 찾기",
    i210016: "상담사",
    i210017: "외 {count}명",
    i210018: "고객",
    i210019: "기간",
    i210020: "YYYY.MM.DD",
    i210021: "전체 선택 해제",
    i210022: "검색 결과 없음",
    i210023: "YYYY년 MM월 DD일",
    i210024: "최신 메시지 순",
    i210025: "오래된 메시지 순",
    i210026: "우선순위 높은 채팅방 순",
    i210027: "정렬: ",
    i210028: "아직 채널에 전송된 메시지가 없습니다.",
    i210029: "담당자 비활성화됨",
    i210030: "가장 관련있음",
    i210031: "우선순위 낮은 채팅방 순",
    i210032: "보낸 사람",
    i210033: "또는",
    i210034: "오늘",
    i210035: "모든 기간",
    i210036: "우선순위",
    i210037: "기간 만료",
    i210038: "채널 공지 관리",
    i210039: "채널 알림을 내부 직원들에게 보낼 수 있습니다. 전송한 채널 알림을 확인할 수 있습니다.",
    i210040: "비활성화된 유저",
    i210041: "{count}개 읽지 않음",
    i210042: "채널 공지 보내기",
    i210043: "검색어를 입력해주세요",
    i210044: "검색",
    i210045: "내용",
    i210046: "작성자",
    i210047: "전송 시간",
    i210048: "채널 공지를 보내는 중입니다.",
    i210049: "채널 공지를 보냈습니다.",
    i210050: "채널 공지를 보내는 도중 에러가 발생했습니다.",
    i210051: "발송 대상",
    i210052: "전체 상담원",
    i210053: "공지사항 내용을 입력해주세요.",
    i210054: "전송",
    i210055: "내용",
    i210056: "채널 알림",
    i210057: "채널 공지",
    i210058: "개인 알림",
    i210059: "NEW",
    i210060: "메시지를 입력해주세요.",
    i210061: "메시지를 일괄 전송 중입니다.",
    i210062: "선택된 상담이 없습니다.",
    i210063: "이 작업은 선택한 상담에 일괄 적용됩니다. 계속 진행하시겠습니까?",
    i210064: "네",
    i210065: "아니오",
    i210066: "선택한 채팅방에 동일한 내용의 메시지를 일괄적으로 보낼 수 있습니다.",
    i210067: "메시지를 입력하세요",
    i210068: "보내기",
    i210069: "메시지 보내기",
    i210070: "액션",
    i210071: "일괄 작업이 진행 중입니다.",
    i210072: "일괄 적용하는 도중 에러가 발생했습니다.",
    i210073: "메시지 전송",
    i210074: "즐겨찾기 등록",
    i210075: "즐겨찾기 해제",
    i210076: "상담 보류",
    i210077: "상담 재개",
    i210078: "상담 종료",
    i210079: "채널에 전송된 공지사항이 없습니다.",
    i210080: "나에게 전송된 개인 알림이 없습니다.",


};

export default language;
