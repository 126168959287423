import {useQuery} from "@tanstack/react-query";
import categoryRepository from "../../repository/CategoryRepository";
import {CategorySummary} from "../../model/category";
import {LiveChatError, toLiveChatError} from "../../error";


const getData = async (channelId: string) => {
    try {
        const {data} = await categoryRepository.summary(channelId);
        return data.result;
    } catch (e) {
        throw toLiveChatError(e);
    }

}


const useCategorySummaries = (channelId: string) => {
    return useQuery<CategorySummary[], LiveChatError>(['categorySummaries', channelId], async () => getData(channelId), {suspense: false});
};


export default useCategorySummaries;