import React, {useCallback, useMemo} from "react";
import {atom, useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {Modal, ModalProps} from "react-bootstrap";

export const modalStatusState = atom<{[id: string]: boolean}>({
    key: 'modalStatus',
    default: {}
})

const modalDataState = atom<{[id: string]: any}>({
    key: 'modalData',
    default: {}
})

const useModal = () => {
    const [modalStatus, setModalStatus] = useRecoilState(modalStatusState)
    const [modalData, setModalData] = useRecoilState(modalDataState)

    const resetModalStatus = useResetRecoilState(modalStatusState)
    const resetModalData = useResetRecoilState(modalDataState)

    const register = useCallback((id: string, status?: boolean) => {
        setModalStatus({...modalStatus, [id]: !!status})
    }, [modalStatus, setModalStatus])

    const open = useCallback((id: string, data?: any) => {
        if (modalStatus[id] === undefined) {
            register(id)
        }

        setModalStatus({
            ...modalStatus,
            [id]: true
        })

        setModalData({
            ...modalData,
            [id]: data
        })
    }, [modalStatus, setModalStatus, modalData, setModalData, register])

    const close = useCallback((id: string) => {
        setModalStatus({
            ...modalStatus,
            [id]: false
        })

        setModalData({
            ...modalData,
            [id]: null
        })
    }, [modalStatus, setModalStatus, modalData, setModalData])

    const clear = useCallback(() => {
        resetModalStatus()
        resetModalData()
    }, [resetModalStatus, resetModalData])

    return useMemo(() => {
        return {
            register,
            open,
            close,
            clear,
            data: modalData,
            status: modalStatus
        }
    }, [register, open, close, clear, modalData, modalStatus])
}

export const MaruModal: React.FC<MaruModalProps> = ((props) => {
    const {children, id, modalClassName, title, size, headerVariant, onClose, activeClose = true, showHeader = true, ...modalProps} = props

    const modal = useModal();
    const modalStatus = useRecoilValue(modalStatusState)

    const handleClose = () => {
        if (activeClose) {
            onClose && onClose();
            modal.close(id);
        }
    };

    const className = !!headerVariant ? `modal-colored-header bg-${headerVariant}` : '';

    return (
        <Modal dialogClassName={modalClassName}
               show={modalStatus[id] ?? false}
               onHide={handleClose}
               size={size}
               {...modalProps}
        >
            {showHeader && (
                <Modal.Header closeButton className={className}>
                    <Modal.Title as={'h4'}>{title}</Modal.Title>
                </Modal.Header>
            )}
            {children}
        </Modal>
    );
});

interface MaruModalProps extends ModalProps {
    id: string;
    modalClassName?: string;
    title?: string;
    size?: 'sm'|'lg'|'xl';
    headerVariant?: 'primary'|'info'|'success'|'warning'|'danger';
    onExit?: ()=>void;
    onClose?: () => void;
    activeClose?: boolean
    showHeader?: boolean
}

export default useModal