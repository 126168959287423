import oauthAxios from "../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../core/variables";

class FileArchiveRepository {
    async find(channelId: string, liveChatId: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/livechats/${liveChatId}/file-archive`);
    }

    async downloadAllFiles(channelId: string, liveChatId: string) {
        return oauthAxios.get(
            `${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/livechats/${liveChatId}/file-archive/download-all`,
            { responseType: "arraybuffer" }
        );
    }
}

export default new FileArchiveRepository();
