import React, {useCallback, useLayoutEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {v4 as uuid} from "uuid";
import {atom, useRecoilValue, useSetRecoilState} from "recoil";
import {CenteredIcon} from "../component/UnstyledBootstrap";

const MAX_NOTIFICATION_COUNT = 5;

const NotificationState = atom<{[id: string]: NotificationInfo}>({
    key: 'notification',
    default: {}
});


const useToast = () => {
    const setNotification = useSetRecoilState(NotificationState)

    const addNotificationInfo = useCallback( (type: NotificationType, title?: string, description?: string) => {
        const id = uuid();
        setNotification((prev) => {
            const notificationInfos = Object.values(prev).filter(notificationInfo => !notificationInfo.hidden);
            if (notificationInfos.length >= MAX_NOTIFICATION_COUNT) {
                const earliestNotificationInfo = notificationInfos[0];
                return {
                    ...prev,
                    [earliestNotificationInfo.id]: {
                        ...prev[earliestNotificationInfo.id],
                        hidden: true
                    },
                    [id]: {
                        id: id,
                        type: type,
                        title: title,
                        description: description,
                        hidden: false
                    }
                }
            }

            return {
                ...prev,
                [id]: {
                    id: id,
                    type: type,
                    title: title,
                    description: description,
                    hidden: false
                }
            }
        })
    }, [setNotification])

    const off = useCallback((id: string) => {
        setNotification((prev) => {
            const {[id]: notificationInfo, ...others} = prev
            return others
        })
    }, [setNotification])

    const success = useCallback((title: string, description?: string) => {
        addNotificationInfo('success', title, description)
    }, [addNotificationInfo])

    const warning = useCallback((title: string, description?: string) => {
        addNotificationInfo('warning', title, description)
    }, [addNotificationInfo])

    const error = useCallback((title: string, description?: string) => {
        addNotificationInfo('danger', title, description)
    }, [addNotificationInfo])

    return useMemo(() => {
        return {
            success,
            warning,
            error,
            off
        }
    }, [success, warning, error, off])
}

type NotificationType = 'success'|'warning'|'danger';

type NotificationInfo = {
    id: string;
    type: NotificationType;
    title?: string;
    description?: string;
    hidden: boolean;
};

export const NotificationContainer: React.FC = () => {
    const notification = useRecoilValue(NotificationState);
    return (
        <>
            {Object.keys(notification).reverse().map((id, index) => <Notification key={id} id={id} index={index} />)}
        </>
    );
};


export const Notification: React.FC<NotificationProps> = ({id, index}) => {
    const notification = useRecoilValue(NotificationState);
    const {off} = useToast()

    const [on, setOn] = useState(false);

    const onTimeoutIdRef = useRef<any>(null);
    const closeTimeoutIdRef = useRef<any>(null);
    const offTimeoutIdRef = useRef<any>(null);

    useLayoutEffect(() => {
        onTimeoutIdRef.current = setTimeout(() => {
            setOn(true);
            closeTimeoutIdRef.current = setTimeout(() => {
                setOn(false);
                setTimeout(() => {
                    off(id);
                }, 400);
            }, 5000);
        }, 50);

        return () => {
            clearTimeout(onTimeoutIdRef.current);
            clearTimeout(closeTimeoutIdRef.current);
        }
    }, [id, off]);

    const notificationInfo = useMemo(() => {
        return notification[id];
    }, [notification, id]);

    const onClickClose = async (e: React.MouseEvent) => {
        e.preventDefault();

        if (closeTimeoutIdRef.current) {
            clearTimeout(closeTimeoutIdRef.current);
        }
        if (offTimeoutIdRef.current) {
            clearTimeout(offTimeoutIdRef.current);
        }
        await off(id);
    }

    if (notificationInfo) {
        return (
            <NotificationStyle isOn={on} index={index} type={notification[id].type} hidden={notification[id].hidden}>
                <div className="status-notification-content">
                    {notificationInfo.type === 'success' && <CenteredIcon size={16} className="dripicons-checkmark text-success font-16" />}
                    {notificationInfo.type === 'warning' && <CenteredIcon size={16}  className="dripicons-warning text-warning font-16" />}
                    {notificationInfo.type === 'danger' && <CenteredIcon size={16}  className="dripicons-wrong text-danger font-16" />}
                    <div className="d-flex flex-column ml-1 flex-grow-1">
                        <p className="p-0 mb-0">
                            {notificationInfo.title || ''}
                        </p>
                        <small>{notificationInfo.description}</small>
                    </div>
                    <CenteredIcon size={18} className="ml-1 mdi mdi-close font-18 status-color font-20" onClick={onClickClose} role="button" />
                </div>
            </NotificationStyle>
        );
    }
    else {
        return null;
    }
};

type NotificationProps = {
    id: string;
    index?: number;
};

type NotificationStyleProps = {
    isOn: boolean;
    type: string;
    index?: number;
    hidden: boolean;
};

const NotificationStyle = styled.div<NotificationStyleProps>`
  position: fixed;
  -webkit-box-shadow: 0 14px 28px rgba(154,161,171,0.25), 0 10px 10px rgba(154,161,171,0.22);
  box-shadow: 0 14px 28px rgba(154,161,171,0.25), 0 10px 10px rgba(154,161,171,0.22);
  border-radius: 7px;
  transition: all .3s ease-out;
  min-width: 240px;
  z-index: 2000;

  ${props => {
    if (props.type === 'success') {
      return `color: #226d52; background-color: #d9f6eb; border: 1px solid #caf2e4;`;
    }
    else if (props.type === 'warning') {
      return `color: #816207; background-color: #fef2cf; border: 1px solid #fdecbb;`;
    }
    else if (props.type === 'danger') {
      return `color: #823636; background-color: #fee1e1; border: 1px solid #fed4d4;`;
    }
  }}

  ${props => props.index ? `top: ${40 + (65 * props.index)}px;`: `top: 40px;`}

  div.status-notification-content {
    display: flex;
    align-items: center !important;
    height: 52px;
    padding: 0.75rem 0.75rem 0.75rem 1rem;

    p {
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    small {
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    i.status-color {
      ${props => {
        if (props.type === 'success') {
          return 'color: #226d52;';
        }
        else if (props.type === 'warning') {
          return 'color: #816207;';
        }
        else if (props.type === 'danger') {
          return 'color: #823636;';
        }
      }}
    }
  }

  ${props => props.isOn ? `right: 20px;` : `right: -350px`}
  ${props => props.hidden && 'display: none;'}
`;

export default useToast;