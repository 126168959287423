import React, {useLayoutEffect, useRef, useState} from "react";
import {ChatbotMessage} from "../../model/chatbot";
import {Button} from "react-bootstrap";
import {useInView} from "react-intersection-observer";


const QuickReplyMessage: React.FC<QuickReplyMessageProps> = ({message}) => {

    const carouselRef = useRef<any>();

    const [isScroll, setIsScroll] = useState(false);

    const [firstItemRef, firstItemInView] = useInView({threshold: 1});
    const [lastItemRef, lastItemInView] = useInView({threshold: 1});

    useLayoutEffect(() => {
        setIsScroll(carouselRef.current.offsetWidth < carouselRef.current.scrollWidth);
    }, []);

    const onClickLeftArrow = (e: React.MouseEvent) => {
        e.preventDefault();
        let offset = carouselRef.current.scrollLeft - carouselRef.current.offsetWidth;
        if (offset < 0) {
            offset = 0;
        }
        carouselRef.current.scrollLeft = offset;
    };

    const onClickRightArrow = (e: React.MouseEvent) => {
        e.preventDefault();
        carouselRef.current.scrollLeft = carouselRef.current.scrollLeft + carouselRef.current.offsetWidth;
    };

    if (message.data instanceof Array) {
        const messageData = message.data;
        const length = messageData.length;

        return (
            <div className="message-content">
                {isScroll && !firstItemInView && (
                    <div className="carousel-arrow-left">
                        <button onClick={onClickLeftArrow}><i className="mdi mdi-chevron-left" /></button>
                    </div>
                )}

                <div className="carousel-message" ref={carouselRef}>
                    {messageData.filter(data => data.label).map((data, index) => {
                        let ref = undefined;
                        if (isScroll) {
                            if (index === 0) {
                                ref = firstItemRef;
                            }
                            else if (index !== 0 && index + 1 === length) {
                                ref = lastItemRef;
                            }
                        }

                        return (
                            <div key={index} className="carousel-quick-reply" ref={ref}>
                                <Button type={'button'}
                                        variant={'outline-primary'}
                                        className="carousel-reply-btn"
                                        disabled>
                                    {data.label}
                                </Button>
                            </div>
                        );
                    })}
                </div>
                {isScroll && !lastItemInView && (
                    <div className="carousel-arrow-right">
                        <button onClick={onClickRightArrow}><i className="mdi mdi-chevron-right" /></button>
                    </div>
                )}
            </div>
        );
    }
    else {
        return null;
    }
};

type QuickReplyMessageProps = {
    message: ChatbotMessage;
};


export default QuickReplyMessage;