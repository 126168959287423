import {AwayReason} from "./managerHistory";

export const AWAY_REASON_ITEMS: {[key in AwayReason] :string} = {
    'meeting': 'i100101',
    'moving': 'i100102',
    'meal': 'i100103',
    'busy': 'i100104',
    'vacation': 'i100105'
}

export interface ManagerBase {
    name: string;
    avatar?: string
}

export interface Manager extends ManagerBase{
    _id: string;
    userId: string;
    channelId: string;
    away?: {reason?: AwayReason, until?: string}
}

export interface ManagerStatistics {
    _id: string;
    name: string;
    userId: string;

    state: 'online' | 'offline' | 'away'
    stateReason?: string
    stateDurationMinutes: number
    totalOnlineMinutes: number

    waitingLivechatIds: string[]
    respondingLivechatIds: string[]
    holdingLivechatIds: string[]
    solvedLivechatIds: string[]
    closedLivechatIds: string[]
    expiredLivechatIds: string[]
    canceledLivechatIds: string[]
    totalLivechatIds: string[]

    exceedStateDurationMinutes: boolean
}


export interface ManagerUpdate extends ManagerBase {}
