import {LIVE_CHAT_API_HOST} from "../core/variables";
import oauthAxios from "../core/oauthAxios";


class UserCategoryRepository {
    async find(channelId: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/user-categories`);
    }

    async add(channelId: string, categoryId: string) {
        return oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/user-categories/${categoryId}`);
    }

    async remove(channelId: string, categoryId: string) {
        return oauthAxios.delete(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/user-categories/${categoryId}`);
    }
}

export default new UserCategoryRepository();