import {LiveChat, LiveChatStatus} from "../../model/livechat";
import {useInfiniteQuery} from "@tanstack/react-query";
import liveChatRepository from "../../repository/LiveChatRepository";
import {LiveChatError, toLiveChatError} from "../../error";
import {Page} from "../index";


/**
 * 상태(Status)별 상담목록
 **/

export const getData = async (
    channelId: string,
    status: LiveChatStatus,
    pageable: {since?: string, limit: number},
    params?: {priorities?: number[], sortBy?: string}
) => {
    try {
        const {data} = await liveChatRepository.find(channelId, {status: status, priorities: params?.priorities, sortBy: params?.sortBy, since: pageable.since, limit: pageable.limit});
        const liveChats: LiveChat[] = data.result;

        return {
            result: liveChats,
            pageable: {
                ...pageable,
                isLast: liveChats.length < pageable.limit
            }
        };
    } catch (e) {
        throw toLiveChatError(e);
    }

}

const useAllLiveChats = (channelId: string, status: LiveChatStatus, priorities: number[], sortBy?: string) => {
    return useInfiniteQuery<Page<LiveChat, {since?: string}>, LiveChatError>({
        queryKey: ['liveChats', status, channelId, 'all', priorities],
        queryFn: ({pageParam = {limit: 50, isLast: false}}) => getData(channelId, status, pageParam, {priorities, sortBy}),
        refetchOnMount: true,
        getNextPageParam: ({pageable}, pages) => {
            if (pageable.isLast) {
                return undefined;
            }

            const lastPageResult = pages.length > 0 ? (pages[pages.length - 1].result ?? []) : [];
            return {
                since: lastPageResult.length > 0 ? lastPageResult[lastPageResult.length - 1].since : undefined,
                limit: pageable.limit,
                isLast: false
            };
        }
    });
};


export default useAllLiveChats;
