import axios, {AxiosError, AxiosResponse, InternalAxiosRequestConfig} from "axios";
import {getAccessToken, getRefreshToken, setAuthToken} from "./token";
import {LIVE_CHAT_API_HOST} from "./variables";
import electronRuntime from "./electronRuntime";
import * as Sentry from "@sentry/react";
import {isAxiosNetworkError, LiveChatError} from "../error";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import React, {useEffect} from "react";
import {useSetRecoilState} from "recoil";
import axiosNetworkOnlineState from "../recoil/axiosResult";

const oauthAxios = axios.create({withCredentials: true});

export const renewAuthToken = async () => {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
        try {
            const {data} = await axios.post(`${LIVE_CHAT_API_HOST}/v1/auth/refresh-token`, {
                refreshToken: refreshToken
            });

            const responseAuthToken = data.result;
            const authToken = {refreshToken: responseAuthToken.refresh_token, accessToken: responseAuthToken.access_token, idToken: responseAuthToken.id_token}
            setAuthToken(authToken, true);

            return authToken;
        } catch (e) {
            throw new LiveChatError('401');
        }
    }
    else {
        throw new LiveChatError('401');
    }
}


export const refreshAuth = (failedRequest: AxiosError) => {
    const _refreshToken = getRefreshToken();
    if (!_refreshToken) {
        return Promise.reject(new LiveChatError('401', 'No refresh token'));
    }

    return axios.post(`${LIVE_CHAT_API_HOST}/v1/auth/refresh-token`, {
        refreshToken: _refreshToken
    }).then(response => {
        const responseAuthToken = response.data.result;
        setAuthToken({refreshToken: responseAuthToken.refresh_token, accessToken: responseAuthToken.access_token, idToken: responseAuthToken.id_token}, true);
        // failedRequest.request.headers['Authorization'] = `Bearer ${responseAuthToken.access_token}`;
        if (failedRequest.response?.config?.headers) {
            failedRequest.response.config.headers['Authorization'] = `Bearer ${responseAuthToken.access_token}`;
        }
        else {
            console.log('Not found failed request response info')
        }

        return Promise.resolve()
    }).catch(_ => {
        setAuthToken({}, true);
        return Promise.reject(new LiveChatError('401', 'Failed refresh token'));
    });
}


if (electronRuntime) {
    createAuthRefreshInterceptor(oauthAxios, refreshAuth, {
        pauseInstanceWhileRefreshing: false,
        // shouldRefresh: (_: AxiosError): boolean => {
        //     return !!electronRuntime
        // }
    });
}

const OAuthAxiosProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
    const setAxiosNetworkOnline = useSetRecoilState(axiosNetworkOnlineState);

    useEffect(() => {
        const onRequestFulfilled = (config: InternalAxiosRequestConfig) => {
            setAxiosNetworkOnline(true)

            if (electronRuntime) {
                config.headers['Authorization'] = `Bearer ${getAccessToken()}`;
            }

            return config
        }

        const onRequestRejected = (error: any) => {
            if (isAxiosNetworkError(error)) {
                setAxiosNetworkOnline(false)
                return new Promise(() => {})
            }

            return Promise.reject(error)
        }

        const onResponseFulfilled = (response: AxiosResponse) => {
            setAxiosNetworkOnline(true);
            return response;
        }
        const onResponseRejected = (error: any) => {
            if (isAxiosNetworkError(error)) {
                setAxiosNetworkOnline(false)
                return new Promise(() => {})
            }

            if (electronRuntime) {
                Sentry.setContext('API Request Detail', {
                    method: error.config.method,
                    url: error.config.url,
                    params: error.config.params,
                    data: error.config.data,
                    headers: error.config.headers
                });
                if (error.response && error.response.status !== 401) {
                    Sentry.setContext('API Response Detail', {
                        status: error.response.status,
                        data: error.response.data
                    });
                    Sentry.captureException(error);
                }
            }

            return Promise.reject(error);
        }

        const requestInterceptor = oauthAxios.interceptors.request.use(
            onRequestFulfilled,
            onRequestRejected
        )

        const responseInterceptor = oauthAxios.interceptors.response.use(
            onResponseFulfilled,
            onResponseRejected
        )

        return () => {
            oauthAxios.interceptors.request.eject(requestInterceptor);
            oauthAxios.interceptors.response.eject(responseInterceptor);
        }

    }, [setAxiosNetworkOnline]);

    return (
        <>
            {children}
        </>
    );
}

export default oauthAxios;
export { OAuthAxiosProvider };
