import React, {useEffect, useRef, useState} from "react";
import useModal, {MaruModal} from "../../hook/useModal";
import {useIntl} from "react-intl";
import {Button, Form, Modal} from "react-bootstrap";
import categoryRepository from "../../repository/CategoryRepository";
import _ from "lodash";
import {Category} from "../../model/category";
import useUpdateCategory from "../../query/category/useUpdateCategory";
import toast from "react-hot-toast";


const INITIAL_INVALID = {name: false};

const CategoryUpdateModal: React.FC<CategoryUpdateModalProps> = ({channelId, onUpdated}) => {
    const intl = useIntl();
    const modal = useModal();

    const nameRef = useRef<HTMLInputElement>(null);

    const [invalid, setInvalid] = useState(INITIAL_INVALID);
    const [duplicatedName, setDuplicatedName] = useState(false);

    const {mutate: updateCategory} = useUpdateCategory(channelId);

    useEffect(() => {
        const data = modal.data['category-update-modal'];
        if (data) {
            if (nameRef.current) {
                nameRef.current.value = data.name;
            }
        }
    }, [modal.data]);

    const checkName = _.debounce(() => {
        const name = nameRef.current?.value.trim();
        const data = modal.data['category-update-modal'];
        const originalName = data?.name;

        if (!name || name === originalName) {
            setDuplicatedName(false);
            return;
        }

        categoryRepository.find(channelId, name).then(({data}) => {
            if (data.result.some((category: Category) => category.name === name)) {
                setDuplicatedName(true);
            }
            else {
                setDuplicatedName(false);
            }
        });
    }, 300);

    const onChangeName = () => {
        checkName();
    }

    const onClickCancelBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        onClosed();
        modal.close('category-update-modal');
    };

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const {categoryId} = modal.data['category-update-modal'];
        const name = nameRef.current?.value;

        const invalid = {name: !name};
        if (Object.values(invalid).some(value => value) || duplicatedName) {
            setInvalid(invalid);
            return;
        }
        else {
            setInvalid(INITIAL_INVALID);
        }

        updateCategory({categoryId, name: name!}, {

            onSuccess: (_) => {
                onUpdated && onUpdated();
                onClosed();
                modal.close('category-update-modal');
            },
            onError: (error) => {
                if (error.response?.status !== 400) {
                    toast.error(intl.formatMessage({id: 'i000120'}));
                }
            }
        });
    };

    const onClosed = () => {
        setInvalid(INITIAL_INVALID);
        setDuplicatedName(false);
    }

    return (
        <MaruModal id={'category-update-modal'} title={intl.formatMessage({id: 'i000124'})} onClose={onClosed}>
            <Modal.Body>
                <Form id={'category-update-form'} onSubmit={onSubmit}>
                    <Form.Group className="mb-1">
                        <Form.Label>{intl.formatMessage({id: 'i000119'})}</Form.Label>
                        <Form.Control onChange={onChangeName} ref={nameRef} isInvalid={invalid.name || duplicatedName} />
                        <Form.Control.Feedback type={'invalid'}>
                            {duplicatedName ? intl.formatMessage({id: 'i000125'}) : intl.formatMessage({id: 'i000024'})}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" className="btn-rounded" onClick={onClickCancelBtn}>{intl.formatMessage({id: 'i000012'})}</Button>
                <Button type="submit" className="btn-rounded" form={'category-update-form'}>{intl.formatMessage({id: 'i000040'})}</Button>
            </Modal.Footer>
        </MaruModal>
    )
};

type CategoryUpdateModalProps = {
    channelId: string;
    onUpdated?: () => void;
}

export default CategoryUpdateModal;
