import {useQuery} from "@tanstack/react-query";
import {LiveChatError, toLiveChatError} from "../../error";
import oauthAxios from "../../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../../core/variables";
import {LiveChatTotal} from "../../model/livechat";


export const getData = async (channelId: string, managerUserId: string) => {
    try {
        const {data} = await oauthAxios.get(`${LIVE_CHAT_API_HOST}/v2/channels/${channelId}/livechats/total`, {
            params: {managerUserId}
        });

        return data.result;
    } catch (e) {
        throw toLiveChatError(e);
    }
}

const useLiveChatTotal = (channelId: string, managerUserId: string) => {
    return useQuery<LiveChatTotal, LiveChatError>({
        queryKey: ['liveChatTotal', channelId, managerUserId],
        queryFn: async () => getData(channelId, managerUserId),
        refetchOnMount: true
    });
};


export default useLiveChatTotal;