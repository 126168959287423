import React, {useEffect, useMemo} from "react";
import {AxiosError} from "axios";
import View40X from "./View40X";
import {useIntl} from "react-intl";
import View500 from "./View500";
import {Navigate} from "react-router-dom";
import electronRuntime from "../../core/electronRuntime";
import TitleBar from "../TitleBar";
import {LiveChatError} from "../../error";

const SentryErrorFallback = (props: {error: Error, componentStack: string | null, eventId: string | null, resetError: () => void}) => {
    const intl = useIntl();

    const { error, resetError } = props;

    const axiosError = useMemo(() => {
        if ('isAxiosError' in error) {
            const axiosError = error as AxiosError
            if (axiosError.isAxiosError) {
                return axiosError;
            }
        }

        return null;
    }, [error]);

    const liveChatError = useMemo(() => {
        if ('isLiveChatError' in error) {
            const liveChatError = error as LiveChatError
            if (liveChatError.isLiveChatError) {
                return liveChatError;
            }
        }

        return null;
    }, [error]);

    const code = useMemo(() => {
        if (axiosError) {
            return String(axiosError.response?.status);
        }
        else if (liveChatError) {
            return liveChatError.code;
        }

        return null;
    }, [axiosError, liveChatError]);

    // useEffect(() => {
    //     if (liveChatError?.noErrorPage) {
    //         resetError();
    //     }
    // }, [liveChatError]);

    useEffect(() => {
        if (code === '401') {
            resetError();
        }
    }, [code, resetError]);

    if (code === '401') {
        return <Navigate replace to="/login" />
    }
    else if (code === '403') {
        return (
            <>
                {electronRuntime && <TitleBar />}
                <View40X code={code} title={intl.formatMessage({id: 'e000006'})} description={intl.formatMessage({id: 'e000007'})} reset={resetError} />
            </>
        );
    }
    else if (code === '404') {
        return (
            <>
                {electronRuntime && <TitleBar />}
                <View40X code={code} title={intl.formatMessage({id: 'e000008'})} description={intl.formatMessage({id: 'e000009'})} reset={resetError} />
            </>
        );
    }
    else {
        return (
            <>
                {electronRuntime && <TitleBar />}
                <View500 reset={resetError} />
            </>
        );
    }

}

export default SentryErrorFallback;