import React, {useCallback, useMemo, useState} from "react";
import {LiveChat} from "../../model/livechat";
import TypingIndicator from "../TypingIndicator";
import MessageTextarea from "../MessageTextarea";
import styled from "styled-components";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import liveChatRepository from "../../repository/LiveChatRepository";
import {Button, Spinner} from "react-bootstrap";
import useManager from "../../query/manager/useManager";
import useLiveChatsHandler from "../../hook/useLiveChatsHandler";
import useUpdateLiveChatState from "../../query/liveChat/useUpdateLiveChatState";
import {SocketEvent, useSocketSubscribe} from "../../socket";
import toast from "react-hot-toast";


const LiveChatBottom: React.FC<{liveChat: LiveChat}> = ({liveChat}) => {
    const {data: manager} = useManager(liveChat.channelId);

    const [typing, setTyping] = useState<{[id: string]: boolean}>({});

    useSocketSubscribe<{userId: string, action: string, liveChatId: string, senderType: string}>(SocketEvent.TYPING, (data) => {
        if (manager?.userId !== data.userId) {
            setTyping(prev => ({...prev, [data.liveChatId]: data.action === 'on'}));
        }
    });

    const isParticipant = useMemo(() => {
        return liveChat.managers.some(m => m.userId === manager?.userId);
    }, [liveChat.managers, manager]);

    return (
        <ChatBottomStyle>
            {typing[liveChat._id] && <TypingIndicator />}
            {(!isParticipant || (liveChat.state !== 'responding' && liveChat.state !== 'holding')) && (
                <LiveChatStateFragment liveChat={liveChat} />
            )}

            {(isParticipant && (liveChat.state === 'responding' || liveChat.state === 'holding')) && (
                <MessageTextarea liveChat={liveChat} />
            )}
        </ChatBottomStyle>
    );
};

const LiveChatStateFragment: React.FC<LiveChatStateFragmentProps> = ({liveChat}) => {
    const intl = useIntl();
    const navigate = useNavigate();

    const {data: manager} = useManager(liveChat.channelId);
    const liveChatsHandler = useLiveChatsHandler(liveChat.channelId);

    const { mutateAsync: updateLiveChatState, isLoading: isUpdatingState } = useUpdateLiveChatState(liveChat.channelId, liveChat._id);
    const updateStateToResponding = useCallback(async () => {
        await updateLiveChatState({
            state: 'responding',
            sendMessageTrigger: liveChat.state === "unassigned" || liveChat.state === "assigned"
        });
    }, [liveChat, updateLiveChatState]);

    const isParticipant = useMemo(() => {
        return liveChat.managers.some(m => m.userId === manager?.userId);
    }, [liveChat.managers, manager]);

    const chatType = useMemo(() => {
        return liveChat.type || 'web'
    }, [liveChat]);

    const onClickAssignedBtn = async (e: React.MouseEvent) => {
        e.preventDefault();
        await updateStateToResponding();
    };

    const onClickCanceledConfirmBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        liveChatsHandler.removeLiveChat(liveChat._id);
        navigate(`/channels/${liveChat.channelId}/livechats`, {replace: true});
    };

    const onClickJoinBtn = async (e: React.MouseEvent) => {
        e.preventDefault();

        if (!manager) {
            return;
        }

        try {
            await liveChatRepository.addManager(liveChat.channelId, liveChat._id, manager.userId);
        } catch (e) {
            toast.error(intl.formatMessage({id: 'e000001'}));
        }
    }

    if (liveChat.canceled) {
        return (
            <div className="chat-state border-top">
                <h5>{intl.formatMessage({id: 'i000015'})}</h5>
                <Button variant="outline-secondary" className="ml-2 btn-rounded" size="sm" onClick={onClickCanceledConfirmBtn}>
                    {intl.formatMessage({id: 'i000016'})}
                </Button>
            </div>
        );
    }
    else {
        switch (liveChat.state) {
            case "unassigned":
                return (
                    <div className="chat-state">
                        <Button variant="primary" className="btn-rounded flex-grow-1" onClick={onClickAssignedBtn} disabled={isUpdatingState}>
                            {isUpdatingState && <Spinner animation="border" size="sm" className="me-1" />}
                            {intl.formatMessage({id: 'i000008'})}
                            { chatType === 'sangdamtalk' && <small>{intl.formatMessage({id: 'i000327'})}</small> }
                        </Button>
                    </div>
                );
            case "assigned":
                return (
                    <div className="chat-state">
                        <Button variant="primary" className="btn-rounded flex-grow-1" onClick={onClickAssignedBtn} disabled={isUpdatingState}>
                            {isUpdatingState && <Spinner animation="border" size="sm" className="me-1" />}
                            {isParticipant ? intl.formatMessage({id: 'i000008'}) : intl.formatMessage({id: 'i000320'})}
                            { chatType === 'sangdamtalk' && <small>{intl.formatMessage({id: 'i000327'})}</small> }
                        </Button>
                    </div>
                );
            case "responding":
                if (isParticipant) {
                    return (
                        <></>
                    );
                }
                else {
                    return (
                        <div className="chat-state">
                            <Button variant="primary" className="btn-rounded flex-grow-1" onClick={onClickJoinBtn}>
                                {intl.formatMessage({id: 'i000320'})}
                            </Button>
                        </div>
                    );
                }

            case "holding":
                return (
                    <></>
                );
            case "solved":
                return (
                    <div className="chat-state border-top">
                        <h5>{intl.formatMessage({id: 'i000013'})}</h5>
                    </div>
                );
            case "closed":
                return (
                    <div className="chat-state border-top">
                        <h5>{intl.formatMessage({id: 'i000014'})}</h5>
                    </div>
                );
            case "expired":
                return (
                    <div className="chat-state border-top">
                        <h5>{intl.formatMessage({id: 'i000068'})}</h5>
                    </div>
                );
            default:
                return null;
        }
    }
};

interface LiveChatStateFragmentProps {
    liveChat: LiveChat;
}

const ChatBottomStyle = styled.div`
  //background-color: white;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center !important;

  padding: 1rem 0.75rem 0.75rem;
  
  .chat-state {
    display: flex;
    width: 100%;
    padding: 10px 40px;

    align-items: center !important;
    justify-content: center !important;
  }
  
  .tooltip-body {
    z-index: 20 !important;
  }

  .content-editor-toolbar-buttons {
    align-items: center;
  }
`

export default LiveChatBottom;
