import {useMutation, useQueryClient} from "@tanstack/react-query";
import categoryRepository from "../../repository/CategoryRepository";
import {AxiosError, AxiosResponse} from "axios";


const useUpdateCategory = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, {categoryId: string, name: string}>(['categories', 'update', channelId], async (params: {categoryId: string, name: string}) => {
        try {
            return await categoryRepository.update(channelId, params.categoryId, params.name);
        } catch (e) {
            throw e;
        }
    }, {
        onSuccess: () => {
            return Promise.all([
                queryClient.invalidateQueries(['categories', channelId], {exact: true}),
                queryClient.invalidateQueries(['categorySummaries', channelId], {exact: true})
            ]);
        }
    });
}

export default useUpdateCategory;
