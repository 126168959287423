import {useQuery} from "@tanstack/react-query";
import channelRepository from "../../repository/ChannelRepository";
import {Channel} from "../../model/channel";
import {LiveChatError, toLiveChatError} from "../../error";


const getData = async () => {
    try {
        const {data} = await channelRepository.find();
        return data.result;
    } catch (e) {
        throw toLiveChatError(e);
    }

}


const useChannels = () => {
    return useQuery<Channel[], LiveChatError>(['channels'], async () => getData(), {suspense: false});
};


export default useChannels;