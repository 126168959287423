import {useRecoilState} from "recoil";
import chatMenuSegmentState from "../recoil/chatMenuSegment";
import {useCallback, useMemo} from "react";
import {LiveChatContext, LiveChatState, LiveChatStatus} from "../model/livechat";
import {useLocalStorageValue} from "@react-hookz/web";
import liveChatContextState from "../recoil/liveChatContext";


const useLiveChatContext = (channelId: string) => {
    const [liveChatContext, setLiveChatContext] = useRecoilState(liveChatContextState);
    const [chatMenuSegment, setChatMenuSegment] = useRecoilState(chatMenuSegmentState);

    const [, setChatMenu] = useLocalStorageValue('LCM', {[channelId]: LiveChatContext.INBOX}, {storeDefaultValue: true});

    const currentSegment = useMemo(() => {
        return liveChatContext?.type ? chatMenuSegment?.[liveChatContext.type] : undefined;
    }, [liveChatContext, chatMenuSegment]);

    const update = useCallback((_context: LiveChatContext, _segment?: LiveChatStatus) => {
        if (_context !== LiveChatContext.DASHBOARD) {
            setChatMenu(oldData => {
                return {
                    ...oldData,
                    [channelId]: _context
                };
            });
        }

        setLiveChatContext({type: _context});

        setChatMenuSegment(oldData => ({
            ...(oldData ?? {}),
            [_context]: _segment ?? currentSegment
        }));
    }, [channelId, currentSegment, setLiveChatContext, setChatMenuSegment, setChatMenu]);

    const updateByLiveChatState = useCallback((state: LiveChatState) => {
        let context: LiveChatContext;
        let segment;
        switch (state) {
            case "unassigned":
                context = LiveChatContext.UNASSIGNED;
                segment = undefined;
                break;
            case "assigned":
                context = LiveChatContext.INBOX;
                segment = LiveChatStatus.IN_PROGRESS;
                break;
            case "responding":
                context = LiveChatContext.INBOX;
                segment = LiveChatStatus.IN_PROGRESS;
                break;
            case "holding":
                context = LiveChatContext.INBOX;
                segment = LiveChatStatus.HOLDING;
                break;
            case "solved":
                context = LiveChatContext.INBOX;
                segment = LiveChatStatus.COMPLETED;
                break;
            case "closed":
                context = LiveChatContext.INBOX;
                segment = LiveChatStatus.COMPLETED;
                break;
            case "expired":
                context = LiveChatContext.INBOX;
                segment = LiveChatStatus.IN_PROGRESS;
                break;
        }

        update(context, segment);

    }, [update]);

    return {
        liveChatContext: liveChatContext.type,
        chatMenuSegment: currentSegment,
        update,
        updateByLiveChatState
    }
};


export default useLiveChatContext;