import {useQuery} from "@tanstack/react-query";
import {Profile} from "../model/Profile";
import electronRuntime from "../core/electronRuntime";
import authenticationRepository from "../repository/AuthenticationRepository";
import {LiveChatError, toLiveChatError} from "../error";
import {getAuthToken, getIdToken, getRefreshToken, syncAuthTokenFromElectron} from "../core/token";
import jwtDecode from "jwt-decode";
import {renewAuthToken} from "../core/oauthAxios";


const getData = async () => {
    if (electronRuntime) {
        let profile: Profile|undefined = undefined;
        let idToken = getIdToken();
        if (!idToken) {
            await syncAuthTokenFromElectron();
        }

        idToken = getIdToken();

        if (idToken) {
            profile = decodeIdToken(idToken);
        }
        else {
            if (getRefreshToken()) {
                await renewAuthToken();
                const refreshAuthToken = getAuthToken();
                if (refreshAuthToken.idToken) {
                    profile = decodeIdToken(refreshAuthToken.idToken);
                }
            }
        }

        if (!profile) {
            throw new LiveChatError('401', 'profile not found');
        }

        return profile
    }
    else {
        try {
            const {data} = await authenticationRepository.getMe();
            return data.result;
        } catch (e) {
            throw toLiveChatError(e);
        }
    }
}

const useProfile = () => {
    const retry = (failureCount: number, error: LiveChatError) => {
        return failureCount < 3 && error.code !== '401';
    }
    return useQuery<Profile, LiveChatError>(['profile'], async () => getData(), {retry: retry, useErrorBoundary: false});
};


const decodeIdToken = (idToken: string): Profile => {
    const decodedIdToken = jwtDecode<{sub: string, name: string, email: string, nickname: string, given_name: string, family_name: string}>(idToken);
    return {
        user_id: decodedIdToken.sub,
        username: decodedIdToken.name,
        email: decodedIdToken.email,
        nickname: decodedIdToken.nickname,
        name: decodedIdToken.nickname,
        given_name: decodedIdToken.given_name,
        family_name: decodedIdToken.family_name
    };
}


export default useProfile;