import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {Form, InputGroup, Pagination} from "react-bootstrap";
import moment, {Moment} from "moment";
import ChatSearchDateRangeFilter from "../../component/chat/ChatSearchDateRangeFilter";
import ChatSearchResultItem from "../../component/chat/ChatSearchResultItem";
import {useParams} from "react-router-dom";
import {useRecoilState, useSetRecoilState} from "recoil";
import chatSearchTrackingState from "../../recoil/chatSearchTracking";
import {Message} from "../../model/message";
import channelChatSearchHistoriesState from "../../recoil/chatSearchHistories";
import {ChatSearchHistory, FilterSnapshot, FilterSnapshotKey, SortType} from "../../model/chatSearch";
import _ from "lodash";
import ChatSearchUserFilter from "../../component/chat/ChatSearchUserFilter";
import ChatSearchManagerFilter from "../../component/chat/ChatSearchManagerFilter";
import ChatSearchFilterSnapshotItem from "../../component/chat/ChatSearchFilterSnapshotItem";
import {ChatSearchFilterResetToggleStyle} from "../../component/chat/ChatSearchFilterStyle";
import ChatSearchResultSortingDropdown from "../../component/chat/ChatSearchResultSortingDropdown";
import {useIntl} from "react-intl";
import {useMountEffect, useUnmountEffect} from "@react-hookz/web";
import useSearchMessages from "../../query/message/useSearchMessages";
import {ChatUser} from "../../model/chatUser";
import {Manager} from "../../model/manager";
import {genDateRangeLabel} from "../../util/datetime";

const MESSAGES_PER_PAGE = 10;
const PAGES_PER_DISPLAY = 10;

type SearchDateInfo = {
    startDate?: Moment
    endDate?: Moment
    label?: string
}

const ChatSearchView: React.FC = () => {
    const { channelId } = useParams<{channelId: string}>();
    const intl = useIntl();

    const setChatSearchTracking = useSetRecoilState(chatSearchTrackingState);
    const [
        channelChatSearchHistories,
        setChannelChatSearchHistories
    ] = useRecoilState(channelChatSearchHistoriesState(channelId!));

    // string state for triggering search query
    const [search, setSearch] = useState("");
    // string state for triggering filtering search histories
    const [filterText, setFilterText] = useState("");
    const [showSearchHistory, setShowSearchHistory] = useState(false);

    // state for search filter
    const [selectedManagers, setSelectedManagers] = useState<Manager[]>([]);
    const [selectedChatUsers, setSelectedChatUsers] = useState<ChatUser[]>([]);
    const [searchDateInfo, setSearchDateInfo] = useState<SearchDateInfo>({});
    const [sortType, setSortType] = useState<SortType>(SortType.MOST_RELEVANT);

    // state for pagination
    const [currentPage, setCurrentPage] = useState(0);

    const searchBarRef = useRef<HTMLInputElement>(null);

    const { data: searchMessages } = useSearchMessages(
        channelId!,
        {
            searchText: search,
            page: currentPage,
            limit: MESSAGES_PER_PAGE,
            managerIds: selectedManagers.map(manager => manager.userId),
            chatUserIds: selectedChatUsers.map(chatUser => chatUser._id),
            startDate: searchDateInfo.startDate && moment.utc(searchDateInfo.startDate).toISOString(),
            endDate: searchDateInfo.endDate && moment.utc(searchDateInfo.endDate).toISOString(),
            sortType: sortType
        }
    )

    const displaySearchHistories = useMemo(() => {
        return channelChatSearchHistories.filter(history => history.searchText.startsWith(filterText))
    }, [channelChatSearchHistories, filterText])

    const totalPage = useMemo(() => {
        return Math.ceil((searchMessages?.total ?? 0) / MESSAGES_PER_PAGE)
    }, [searchMessages?.total])

    const pageIndexes = useMemo(() => {
        const start = Math.floor(currentPage / PAGES_PER_DISPLAY) * PAGES_PER_DISPLAY
        return _.range(start, Math.min(start + PAGES_PER_DISPLAY, totalPage))
    }, [currentPage, totalPage])

    const wrapperSetChatSearchTracking = useCallback((trackingMessage: Message) => {
        setChatSearchTracking({ trackingMessage, searchText: search, isTracking: true})
    }, [setChatSearchTracking, search]);

    const wrapperSetSortType = useCallback((sortType: SortType) => {
        setSortType(sortType);
    }, [setSortType]);

    const onDateRangePickerCallBack = useCallback((start?: Moment, end?: Moment, label?: string) => {
        if (label === intl.formatMessage({id: "i210035"})) {
            setSearchDateInfo({});
            return
        }

        setSearchDateInfo({
            startDate: start?.startOf('day'),
            endDate: end?.endOf('day'),
            label: label === intl.formatMessage({id: "i210005"})
                ? genDateRangeLabel(start, end, intl.formatMessage({id: "i210006"}))
                : label
        });
    }, [intl]);

    const getSearchFilter = useCallback(() => {
        const dateRange = (searchDateInfo.startDate && searchDateInfo.endDate && [searchDateInfo.startDate, searchDateInfo.endDate]) ?? [];
        return {
            managers: selectedManagers,
            chatUsers: selectedChatUsers,
            dateRange
        } as FilterSnapshot;
    }, [selectedManagers, selectedChatUsers, searchDateInfo])

    const onKeyDownSearchBar = (e: React.KeyboardEvent) => {
        if (channelId) {
            switch (e.code) {
                case "Enter":
                    if (!e.nativeEvent.isComposing) {
                        const searchText = searchBarRef.current?.value ?? "";

                        if (searchText) {
                            const filterSnapshot = getSearchFilter();

                            setSearch(searchText);
                            setChannelChatSearchHistories(prev => ([
                                {
                                    searchedAt: moment.utc(),
                                    searchText,
                                    filterSnapshot
                                },
                                ...prev
                            ]));
                            searchBarRef.current?.blur();
                        }
                    }
                    return;
                case "Escape":
                    if (!e.nativeEvent.isComposing) {
                        searchBarRef.current?.blur();
                        setShowSearchHistory(false);
                    }
                    return;
            }
        }
    };

    const resetFilter = useCallback(() => {
        setSelectedManagers([]);
        setSelectedChatUsers([]);
        setSearchDateInfo({});
        setSortType(SortType.MOST_RELEVANT)
    }, []);

    const onChangeSearchBar = _.debounce(() => {
        setFilterText(searchBarRef.current?.value ?? "")
    }, 200);

    const clearSearchBar = useCallback(() => {
        if (searchBarRef.current) {
            searchBarRef.current.value = '';
            searchBarRef.current.blur();
        }
        setFilterText("")
        setShowSearchHistory(false)
    }, []);

    const onClickSearchHistory = (e: React.MouseEvent, history: ChatSearchHistory) => {
        e.preventDefault();

        setSearch(history.searchText);
        setFilterText(history.searchText);
        if (searchBarRef.current) {
            searchBarRef.current.value = history.searchText;
            searchBarRef.current.blur();
        }

        // restore filter snapshots
        setSelectedManagers(history.filterSnapshot?.managers ?? []);
        setSelectedChatUsers(history.filterSnapshot?.chatUsers ?? []);

        const [startDate, endDate] = history.filterSnapshot?.dateRange ?? [];
        setSearchDateInfo({
            startDate,
            endDate,
            label: startDate && endDate
                ? genDateRangeLabel(startDate, endDate, intl.formatMessage({id: "i210006"}))
                : undefined
        });
    }

    const onClickDeleteSearchHistory = (e: React.MouseEvent, index: number) => {
        e.preventDefault();
        e.stopPropagation();
        setChannelChatSearchHistories(prev => prev.filter((_, idx) => idx !== index))
    }

    useMountEffect(() => {
        setChatSearchTracking(prev => {
            // restore search context when back to search view
            if (prev.isTracking) {
                setSearch(prev.searchText);
                setFilterText(prev.searchText);
                if (searchBarRef.current) {
                    searchBarRef.current.value = prev.searchText;
                }
                setSortType(prev.sortType ?? SortType.MOST_RELEVANT)
                setSelectedManagers(prev.filter?.managers ?? []);
                setSelectedChatUsers(prev.filter?.chatUsers ?? []);

                const [startDate, endDate] = prev.filter?.dateRange ?? [];
                setSearchDateInfo({
                    startDate: startDate,
                    endDate: endDate,
                    label: startDate && endDate
                        ? genDateRangeLabel(startDate, endDate, intl.formatMessage({id: "i210006"}))
                        : undefined
                })
            } else {
                searchBarRef.current?.focus();
            }

            return {
                trackingMessage: {} as Message,
                searchText: "",
                isTracking: false
            };
        })
    })

    useUnmountEffect(() => {
        setChatSearchTracking(prev => {
            if (prev.isTracking) {
                return {
                    ...prev,
                    searchText: search,
                    sortType,
                    filter: getSearchFilter()
                }
            } else {
                return {
                    trackingMessage: {} as Message,
                    searchText: "",
                    isTracking: false
                }
            }
        })
    })

    useEffect(() => {
        setCurrentPage(0);
    }, [search, selectedManagers, selectedChatUsers, sortType, searchDateInfo])

    return (
        <ChatSearchViewStyle>

            <ChatSearchContextStyle>

                <ChatSearchBarStyle>
                    <InputGroup className="chat-search-bar-input-group" size="sm">
                        <InputGroup.Text>
                            <i className="mdi mdi-magnify"/>
                        </InputGroup.Text>
                        <Form.Control ref={searchBarRef}
                                      onFocus={() => {
                                          setShowSearchHistory(true)
                                      }}
                                      onBlur={() => {
                                          setShowSearchHistory(false)
                                          searchBarRef.current?.blur()
                                      }}
                                      onChange={onChangeSearchBar}
                                      placeholder={intl.formatMessage({id: "i210007"})}
                                      onKeyDown={onKeyDownSearchBar}/>
                        <div className="clear-search-bar-btn" onClick={() => clearSearchBar()}>
                            <i className="mdi mdi-close"/>
                        </div>

                        {showSearchHistory &&
                            <SearchHistoryStyle onMouseDown={(e) => e.preventDefault()}>
                                <h5 className="mt-1 mb-0">{intl.formatMessage({id: "i210008"})}</h5>
                                <hr/>
                                <div className="recent-histories">
                                    {displaySearchHistories.map((history, index) => {
                                        return (
                                            <SearchHistoryItemStyle key={index} onClick={(e) => onClickSearchHistory(e, history)}>
                                                <div className="search-text">
                                                    <i className="mdi mdi-clock-outline font-20 mx-1"/>
                                                    <span className="font-16 text-truncate" style={{maxWidth: "500px"}}>
                                                    {history.searchText}
                                                </span>
                                                    {history.filterSnapshot &&
                                                        Object.entries(history.filterSnapshot)
                                                            .filter(([_, content]) => content.length)
                                                            .map(([key, content], index) => (
                                                                <ChatSearchFilterSnapshotItem key={index}
                                                                                              filterKey={key as FilterSnapshotKey}
                                                                                              content={content} />
                                                            ))
                                                    }
                                                </div>

                                                <div className="d-flex justify-content-start align-items-center">
                                                <span className="text-muted font-14 mr-2">
                                                    {moment(history.searchedAt).local().format('YYYY년 MM월 DD일')}
                                                </span>
                                                    <i className="mdi mdi-close mr-1 font-16 cursor-pointer"
                                                       onClick={(e) => onClickDeleteSearchHistory(e, index)}
                                                    />
                                                </div>
                                            </SearchHistoryItemStyle>
                                        )
                                    })}
                                </div>
                            </SearchHistoryStyle>
                        }
                    </InputGroup>
                </ChatSearchBarStyle>

                <div className="chat-search-filter">
                    <div className="from-filter">
                        <i className="mdi mdi-account-multiple"/>
                        <span className="mx-1">{intl.formatMessage({id: "i210032"})}</span>

                        <ChatSearchManagerFilter channelId={channelId!}
                                                 selectedManagers={selectedManagers}
                                                 setSelectedManagers={setSelectedManagers}/>
                        <span className="mx-1 font-14 text-muted">{intl.formatMessage({id: "i210033"})}</span>
                        <ChatSearchUserFilter channelId={channelId!}
                                              selectedChatUsers={selectedChatUsers}
                                              setSelectedChatUsers={setSelectedChatUsers}/>
                    </div>
                    <ChatSearchDateRangeFilter onCallback={onDateRangePickerCallBack}
                                               label={searchDateInfo.label}/>
                    <ChatSearchFilterResetToggleStyle role="button" onClick={() => resetFilter()}>
                        <i className="mdi mdi-refresh"/>
                        <span className="mx-1">{intl.formatMessage({id: "i210009"})}</span>
                    </ChatSearchFilterResetToggleStyle>
                </div>

                <div className="chat-search-result">
                    {totalPage > 0 &&
                        <>
                            <div className="d-flex justify-content-start align-items-center">
                                <h4 className="m-0">{intl.formatMessage({id: "i210010"}, {search})}</h4>
                                <ChatSearchResultSortingDropdown sortType={sortType} setSortType={wrapperSetSortType} />
                            </div>
                            <hr/>
                        </>
                    }

                    <div className="chat-search-result-body">
                        {searchMessages && searchMessages.messages.map((message, index) => (
                            <ChatSearchResultItem key={index}
                                                  message={message}
                                                  setChatSearchTracking={wrapperSetChatSearchTracking}
                                                  searchText={search}/>
                        ))}
                        {!totalPage &&
                            <div className="w-100 h-100 d-flex flex-column justify-content-center"
                                 style={{minWidth: "900px"}}>
                                <h5 className="text-center">{intl.formatMessage({id: "i210012"})}</h5>
                                <div className="text-center">{intl.formatMessage({id: "i210013"})}</div>
                            </div>
                        }
                    </div>

                </div>

                {totalPage > 1 &&
                    <ChatSearchResultPaginationStyle>
                        <Pagination size="sm">
                            <Pagination.Prev disabled={!currentPage}
                                             onClick={() => setCurrentPage(prev => (prev - 1))}/>
                            {pageIndexes.map(index => (
                                <Pagination.Item key={index}
                                                 active={index === currentPage}
                                                 onClick={() => setCurrentPage(index)}>
                                    {index + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next disabled={currentPage === totalPage - 1}
                                             onClick={() => setCurrentPage(prev => (prev + 1))}/>
                        </Pagination>
                    </ChatSearchResultPaginationStyle>
                }

            </ChatSearchContextStyle>
        </ChatSearchViewStyle>
    )
};

const ChatSearchViewStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const ChatSearchBarStyle = styled.div`
  padding: 0.75rem;

  .chat-search-bar-input-group {
    position: relative;
    background-color: white;
    border: 1px solid #DDDDDD;
    border-radius: 10px;

    & > .input-group-text {
      border: unset;
      background-color: white;
      border-radius: 10px;
      font-size: 18px;
    }

    & > .form-control {
      border: unset;
      padding: 0.25rem 0;
    }

    & > .clear-search-bar-btn {
      align-self: center;
      margin: 0 1rem;
      cursor: pointer;
    }
  }
`

const ChatSearchContextStyle = styled.div`
  height: 100%;
  background-color: white;
  flex-grow: 1;
  padding: 0.5rem;

  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: auto;

  .chat-search-filter {
    display: flex;
    margin: 0.5rem;
    min-width: 900px;

    .from-filter {
      display: flex;
      justify-content: start;
      align-items: center;
      padding: 0 0 0 1rem;
      height: 35px;
      margin-right: 1rem;

      border: none;
      border-radius: 10px;
      background-color: #F6F6F7;
      color: black;

      & > div.dropdown {
        margin: 0 !important;

        & > div > div {
          padding: 0 0.5rem;
        }
      }
    }
  }

  .chat-search-result {
    min-height: 1px;
    flex-grow: 1;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    
    .chat-search-result-body {
      overflow-y: auto;
    }
  }
`;

const SearchHistoryStyle = styled.div`
  display: flex;
  flex-direction: column;
  
  position: absolute;
  top: 40px;

  z-index: 1000;

  width: 100%;
  padding: 1rem;
  
  background-color: white;
  box-shadow: 0 4px 4px rgba(140, 140, 140, 0.25);
  border: 1px solid #ececec;
  border-radius: 10px !important;
  
  .recent-histories {
    max-height: 600px;
    overflow: auto;
  }
`;

const SearchHistoryItemStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 10px;
  margin-bottom: 0.25rem;

  &:hover {
    background-color: #ececec;
  }

  .search-text {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .mdi-close {
    color: #c9c9c9;

    &:hover {
      color: #000000;
    }
  }
`;

const ChatSearchResultPaginationStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  ul {
    margin: 1rem;
  }

  .page-link {
    padding: 0.5rem 0.75rem;
  }
`;

export default ChatSearchView;
