import {useCallback, useMemo} from "react";
import {LiveChat, LiveChatState, LiveChatStatus} from "../model/livechat";
import {Message} from "../model/message";
import {ChatUser} from "../model/chatUser";
import {useQueryClient} from "@tanstack/react-query";
import {Page} from "../query";
import useManager from "../query/manager/useManager";
import {useLocalStorageValue} from "@react-hookz/web";
import {LATEST_ALL_PRIORITIES_KEY, LATEST_MANAGER_PRIORITIES_KEY} from "../core/variables";
import liveChatRepository from "../repository/LiveChatRepository";


// const LIVE_CHAT_STATES_BY_STATUS: {[key: LiveChatStatus|string]: LiveChatState[]} = {
//     unassigned: ['unassigned'],
//     in_progress: ['assigned', 'responding', 'expired'],
//     holding: ['holding'],
//     completed: ['closed', 'solved']
// }


const useLiveChatsHandler = (channelId: string) => {
    const queryClient = useQueryClient();

    const {data: manager} = useManager(channelId);

    const [_managerLiveChatPriorities] = useLocalStorageValue<{[key: string]: number[]}>(LATEST_MANAGER_PRIORITIES_KEY, {});
    const [_allLiveChatPriorities] = useLocalStorageValue<{[key: string]: number[]}>(LATEST_ALL_PRIORITIES_KEY, {});

    const managerLiveChatPriorities = useMemo(() => {
        return _managerLiveChatPriorities[channelId] ?? [] as number[];
    }, [_managerLiveChatPriorities, channelId]);

    const allLiveChatPriorities = useMemo(() => {
        return _allLiveChatPriorities[channelId] ?? [] as number[];
    }, [_allLiveChatPriorities, channelId]);

    const getLiveChatsCache = useCallback((): LiveChat[] => {
        const unassignedLiveChatPager = queryClient.getQueryData<any>(['liveChats', LiveChatStatus.UNASSIGNED, channelId]);
        const inProgressLiveChatPager = queryClient.getQueryData<any>(['liveChats', LiveChatStatus.IN_PROGRESS, channelId, manager?.userId, managerLiveChatPriorities]);
        const holdingLiveChatPager = queryClient.getQueryData<any>(['liveChats', LiveChatStatus.HOLDING, channelId, manager?.userId, managerLiveChatPriorities]);
        const completedLiveChatPager = queryClient.getQueryData<any>(['liveChats', LiveChatStatus.COMPLETED, channelId, manager?.userId, managerLiveChatPriorities]);
        const requestedOutsideWorkingTimesLiveChatPager = queryClient.getQueryData<any>(['liveChats', 'requestedOutsideWorkingTimes', channelId]);

        const unassignedLiveChats = unassignedLiveChatPager?.pages?.map((page: Page<LiveChat>) => page.result).flat() ?? [];
        const inProgressLiveChats = inProgressLiveChatPager?.pages?.map((page: Page<LiveChat>) => page.result).flat() ?? [];
        const holdingLiveChats = holdingLiveChatPager?.pages?.map((page: Page<LiveChat>) => page.result).flat() ?? [];
        const completedLiveChats = completedLiveChatPager?.pages?.map((page: Page<LiveChat>) => page.result).flat() ?? [];
        const requestedOutsideWorkingTimesLiveChats = requestedOutsideWorkingTimesLiveChatPager?.pages?.map((page: Page<LiveChat>) => page.result).flat() ?? [];

        return [...unassignedLiveChats, ...inProgressLiveChats, ...holdingLiveChats, ...completedLiveChats, ...requestedOutsideWorkingTimesLiveChats];
    }, [queryClient, channelId, manager, managerLiveChatPriorities]);

    const getAllLiveChatsCache = useCallback((): LiveChat[] => {
        const inProgressAllLiveChatPager = queryClient.getQueryData<any>(['liveChats', LiveChatStatus.IN_PROGRESS, channelId, 'all', allLiveChatPriorities]);
        const holdingAllLiveChatPager = queryClient.getQueryData<any>(['liveChats', LiveChatStatus.HOLDING, channelId, 'all', allLiveChatPriorities]);
        const completedAllLiveChatPager = queryClient.getQueryData<any>(['liveChats', LiveChatStatus.COMPLETED, channelId, 'all', allLiveChatPriorities]);

        const inProgressAllLiveChats = inProgressAllLiveChatPager?.pages?.map((page: Page<LiveChat>) => page.result).flat() ?? [];
        const holdingAllLiveChats = holdingAllLiveChatPager?.pages?.map((page: Page<LiveChat>) => page.result).flat() ?? [];
        const completedAllLiveChats = completedAllLiveChatPager?.pages?.map((page: Page<LiveChat>) => page.result).flat() ?? [];

        return [...inProgressAllLiveChats, ...holdingAllLiveChats, ...completedAllLiveChats];
    }, [queryClient, channelId, allLiveChatPriorities]);

    // const getUnreadLiveChatsCache = useCallback((): LiveChat[] => {
    //     const unreadLiveChatPager = queryClient.getQueryData<any>(['liveChats', 'unread', channelId]);
    //     return unreadLiveChatPager?.pages?.map((page: Page<LiveChat>) => page.result).flat() ?? [];
    // }, [queryClient, channelId]);

    const getLiveChat = useCallback((liveChatId: string, states?: LiveChatState[]) => {
        const liveChats = getLiveChatsCache();
        const filteredLiveChats = liveChats?.filter(liveChat => liveChat._id === liveChatId && (!states || states.includes(liveChat.state))) ?? [];

        if (filteredLiveChats.length > 0) {
            return filteredLiveChats[0];
        }

        const allLiveChats = getAllLiveChatsCache();
        const filteredAllLiveChats = allLiveChats?.filter(liveChat => liveChat._id === liveChatId && (!states || states.includes(liveChat.state))) ?? [];

        if (filteredAllLiveChats.length > 0) {
            return filteredAllLiveChats[0];
        }

        const unreadLiveChatPager = queryClient.getQueryData<any>(['liveChats', 'unread', channelId]);
        const unreadLiveChats: LiveChat[] = unreadLiveChatPager?.pages?.map((page: Page<LiveChat>) => page.result).flat() ?? [];
        const filteredUnreadLiveChats = unreadLiveChats?.filter(liveChat => liveChat._id === liveChatId && (!states || states.includes(liveChat.state))) ?? [];
        return filteredUnreadLiveChats.length > 0 ? filteredUnreadLiveChats[0] : undefined;
    }, [channelId, queryClient, getLiveChatsCache, getAllLiveChatsCache]);

    const genLiveChatQueryKey = useCallback((state: LiveChatState, requestedOutsideWorkingTimes?: boolean) => {
        const queryKey: (string|number[])[] = ['liveChats'];

        switch (state) {
            case "unassigned":
                if (requestedOutsideWorkingTimes) {
                    queryKey.push('requestedOutsideWorkingTimes');
                    queryKey.push(channelId);
                }
                else {
                    queryKey.push(LiveChatStatus.UNASSIGNED);
                    queryKey.push(channelId);
                }
                return queryKey;
            case "assigned":
            case "responding":
            case "expired":
                queryKey.push(LiveChatStatus.IN_PROGRESS);
                break;
            case "holding":
                queryKey.push(LiveChatStatus.HOLDING);
                break;
            case "solved":
            case "closed":
                queryKey.push(LiveChatStatus.COMPLETED);
                break;
        }

        if (!manager?.userId) {
            return null;
        }

        queryKey.push(channelId);
        queryKey.push(manager.userId);
        queryKey.push(managerLiveChatPriorities);
        return queryKey;
    }, [channelId, manager, managerLiveChatPriorities]);

    const genAllLiveChatQueryKey = useCallback((state: LiveChatState) => {
        const queryKey: (string|number[])[] = ['liveChats'];

        switch (state) {
            case "unassigned":
                return null;
            case "assigned":
            case "responding":
            case "expired":
                queryKey.push(LiveChatStatus.IN_PROGRESS);
                break;
            case "holding":
                queryKey.push(LiveChatStatus.HOLDING);
                break;
            case "solved":
            case "closed":
                queryKey.push(LiveChatStatus.COMPLETED);
                break;
        }

        queryKey.push(channelId);
        queryKey.push('all');
        queryKey.push(allLiveChatPriorities)
        return queryKey;
    }, [channelId, allLiveChatPriorities]);

    const genLiveChatQueryKeyByStatus = useCallback((status: LiveChatStatus) => {
        switch (status) {
            case LiveChatStatus.UNASSIGNED:
                return ['liveChats', LiveChatStatus.UNASSIGNED, channelId];
            case LiveChatStatus.IN_PROGRESS:
                if (!manager?.userId) {
                    return null;
                }
                return ['liveChats', LiveChatStatus.IN_PROGRESS, channelId, manager.userId, managerLiveChatPriorities];
            case LiveChatStatus.HOLDING:
                if (!manager?.userId) {
                    return null;
                }
                return ['liveChats', LiveChatStatus.HOLDING, channelId, manager.userId, managerLiveChatPriorities];
            case LiveChatStatus.COMPLETED:
                if (!manager?.userId) {
                    return null;
                }
                return ['liveChats', LiveChatStatus.COMPLETED, channelId, manager.userId, managerLiveChatPriorities];

        }
    }, [channelId, manager, managerLiveChatPriorities]);

    const genAllLiveChatQueryKeyByStatus = useCallback((status: LiveChatStatus) => {
        switch (status) {
            case LiveChatStatus.UNASSIGNED:
                return null;
            case LiveChatStatus.IN_PROGRESS:
                return ['liveChats', LiveChatStatus.IN_PROGRESS, channelId, 'all', allLiveChatPriorities];
            case LiveChatStatus.HOLDING:
                return ['liveChats', LiveChatStatus.HOLDING, channelId, 'all', allLiveChatPriorities];
            case LiveChatStatus.COMPLETED:
                return ['liveChats', LiveChatStatus.COMPLETED, channelId, 'all', allLiveChatPriorities];

        }
    }, [channelId, allLiveChatPriorities]);

    const genLiveChatQueryKeyByLiveChatId = useCallback((liveChatId: string) => {
        const liveChat = getLiveChat(liveChatId);
        if (!liveChat) {
            return null;
        }

        return genLiveChatQueryKey(liveChat.state, liveChat.requestedOutsideWorkingTimes);
    }, [getLiveChat, genLiveChatQueryKey]);

    const genAllLiveChatQueryKeyByLiveChatId = useCallback((liveChatId: string) => {
        const liveChat = getLiveChat(liveChatId);
        if (!liveChat) {
            return null;
        }

        return genAllLiveChatQueryKey(liveChat.state);
    }, [getLiveChat, genAllLiveChatQueryKey]);

    const getLiveChatAllQueryKeys = useCallback(() => {
        const allQueryKeys: (string|number[])[][] = [];
        Object.values(LiveChatStatus).forEach(status => {
            const queryKey = genLiveChatQueryKeyByStatus(status);
            if (queryKey) {
                allQueryKeys.push(queryKey);
            }

            const allQueryKey = genAllLiveChatQueryKeyByStatus(status);
            if (allQueryKey) {
                allQueryKeys.push(allQueryKey);
            }
        });
        allQueryKeys.push(['liveChats', 'requestedOutsideWorkingTimes', channelId]);
        return allQueryKeys;
    }, [genLiveChatQueryKeyByStatus, genAllLiveChatQueryKeyByStatus, channelId]);

    const _updateQueryData = useCallback((queryKey: (string|number[])[], callable: (liveChats: LiveChat[]) => LiveChat[]) => {
        queryClient.cancelQueries({ queryKey, exact: true, fetchStatus: "fetching" }).then(() => {
            queryClient.setQueryData<{pages?: Page<LiveChat, {since?: string}>[]}>(queryKey, ((oldData) => {
                const allLiveChats = oldData?.pages?.map((page: Page<LiveChat>) => page.result).flat() ?? [];

                const readjustLiveChats = callable(allLiveChats);
                const checkingLiveChatIds: string[] = [];
                const pages = oldData?.pages?.reverse().map((page: Page<LiveChat, {since?: string}>) => {
                    const liveChats = readjustLiveChats.filter(liveChat => {
                        if (checkingLiveChatIds.includes(liveChat._id)) {
                            return false;
                        }

                        if (!page.pageable.since) {
                            checkingLiveChatIds.push(liveChat._id);
                            return true;
                        }

                        if (liveChat.since < page.pageable.since) {
                            checkingLiveChatIds.push(liveChat._id);
                            return true;
                        }

                        return false;
                    });
                    return {
                        ...page,
                        result: liveChats
                    };
                }).reverse();

                return {
                    ...oldData,
                    pages
                };
            }));
        });
    }, [queryClient]);

    /* Try to get liveChat even if it's not cached yet, which can be used in case of unread counting. */
    const getShouldExistLiveChat = useCallback(async (liveChatId: string, states?: LiveChatState[]) => {
        const liveChat = getLiveChat(liveChatId, states);
        if (liveChat) {
            return liveChat
        } else {
            try {
                const { data } = await liveChatRepository.read(channelId, liveChatId);
                const liveChat = data.result as LiveChat;

                // 현재 보고 있는 liveChats이 업데이트되어야 하는 경우 query data 업데이트
                // e.g. 다음 페이지에 있는 liveChat이 최신으로 올라올때
                if (managerLiveChatPriorities.length === 0 || managerLiveChatPriorities.includes(liveChat.priority)) {
                    const queryKey = genLiveChatQueryKey(liveChat.state, liveChat.requestedOutsideWorkingTimes);
                    if (queryKey) {
                        _updateQueryData(queryKey, liveChats => {
                            if (!liveChats?.some(lc => lc._id === liveChat._id)) {
                                return [liveChat, ...liveChats ?? []];
                            }
                            return liveChats ?? [];
                        });
                    }
                }

                return data.result as LiveChat
            } catch (e) {
                return undefined
            }
        }
    }, [getLiveChat, channelId, _updateQueryData, genLiveChatQueryKey, managerLiveChatPriorities]);

    const removeLiveChat = useCallback((liveChatId: string) => {
        const queryKey = genLiveChatQueryKeyByLiveChatId(liveChatId);
        if (queryKey) {
            _updateQueryData(queryKey, liveChats => {
                return liveChats?.filter(lc => lc._id !== liveChatId) ?? [];
            });
        }

        const allQueryKey = genAllLiveChatQueryKeyByLiveChatId(liveChatId);
        if (allQueryKey) {
            _updateQueryData(allQueryKey, liveChats => {
                return liveChats?.filter(lc => lc._id !== liveChatId) ?? [];
            });
        }
    }, [_updateQueryData, genLiveChatQueryKeyByLiveChatId, genAllLiveChatQueryKeyByLiveChatId]);

    const addLiveChat = useCallback((liveChat: LiveChat) => {
        const queryKey = genLiveChatQueryKey(liveChat.state, liveChat.requestedOutsideWorkingTimes);
        if (queryKey) {
            _updateQueryData(queryKey, liveChats => {
                if (!liveChats?.some(lc => lc._id === liveChat._id)) {
                    return [liveChat, ...liveChats ?? []];
                }

                return liveChats ?? [];
            });
        }

        const allQueryKey = genAllLiveChatQueryKey(liveChat.state);
        if (allQueryKey) {
            _updateQueryData(allQueryKey, liveChats => {
                if (!liveChats?.some(lc => lc._id === liveChat._id)) {
                    return [...liveChats ?? [], liveChat];
                }

                return liveChats ?? [];
            });
        }
    }, [genLiveChatQueryKey, genAllLiveChatQueryKey, _updateQueryData]);

    const addUnreadLiveChat = useCallback((liveChat: LiveChat) => {
        const queryKey = ['liveChats', 'unread', channelId];
        _updateQueryData(queryKey, liveChats => {
            return liveChats?.filter(lc => lc._id !== liveChat._id) ?? [];
        });

        _updateQueryData(queryKey, liveChats => {
            if (!liveChats?.some(lc => lc._id === liveChat._id)) {
                return [...liveChats ?? [], liveChat];
            }

            return liveChats ?? [];
        });
    }, [channelId, _updateQueryData]);

    const removeUnreadLiveChat = useCallback((liveChatId: string) => {
        const queryKey = ['liveChats', 'unread', channelId];
        _updateQueryData(queryKey, liveChats => {
            return liveChats?.filter(lc => lc._id !== liveChatId) ?? [];
        });
    }, [channelId, _updateQueryData]);

    const updateLiveChat = useCallback((liveChat: LiveChat) => {
        const oldLiveChat = getLiveChat(liveChat._id);
        const latestMessage = liveChat.latestMessage || oldLiveChat?.latestMessage;

        removeLiveChat(liveChat._id);

        const hasManager = liveChat.managers.some(_manager => _manager.userId === manager?.userId);
        const queryKey = genLiveChatQueryKey(liveChat.state, liveChat.requestedOutsideWorkingTimes);
        if (queryKey && (hasManager || liveChat.state === 'unassigned')) {
            _updateQueryData(queryKey, liveChats => {
                if (liveChats?.some(lc => lc._id === liveChat._id)) {
                    return liveChats.filter(lc =>
                        managerLiveChatPriorities.length < 1 || managerLiveChatPriorities.includes(lc.priority)
                    ).map(lc => {
                        if (lc._id === liveChat._id) {
                            return {
                                ...liveChat,
                                latestMessage: latestMessage,
                            }
                        }

                        return lc;
                    }) ?? [];
                }
                else {
                    return [{...liveChat, latestMessage: latestMessage}, ...(liveChats ?? [])].filter(lc => {
                        return managerLiveChatPriorities.length < 1 || managerLiveChatPriorities.includes(lc.priority)
                    });
                }
            });
        }

        const allQueryKey = genAllLiveChatQueryKey(liveChat.state);
        if (allQueryKey) {
            _updateQueryData(allQueryKey, liveChats => {
                if (liveChats?.some(lc => lc._id === liveChat._id)) {
                    return liveChats.filter(lc =>
                        allLiveChatPriorities.length < 1 || allLiveChatPriorities.includes(lc.priority)
                    ).map(lc => {
                        if (lc._id === liveChat._id) {
                            return {
                                ...liveChat,
                                latestMessage: latestMessage,
                            }
                        }

                        return lc;
                    }) ?? [];
                }
                else {
                    return [{...liveChat, latestMessage: latestMessage}, ...(liveChats ?? [])].filter(lc =>
                        allLiveChatPriorities.length < 1 || allLiveChatPriorities.includes(lc.priority)
                    );
                }
            });
        }
    }, [getLiveChat, genLiveChatQueryKey, genAllLiveChatQueryKey, removeLiveChat, _updateQueryData, manager, managerLiveChatPriorities, allLiveChatPriorities]);

    const updateLatestMessage = useCallback((message: Message) => {
        const queryKey = genLiveChatQueryKeyByLiveChatId(message.liveChatId);
        if (queryKey) {
            _updateQueryData(queryKey, liveChats => {
                return liveChats?.map(lc => {
                    if (lc._id === message.liveChatId) {
                        if ((lc.latestMessage?.createdAt ?? '') < message.createdAt) {
                            return {
                                ...lc,
                                since: message.createdAt,
                                latestMessage: message
                            };
                        }
                        else {
                            return lc;
                        }
                    }
                    return lc;
                }) ?? [];
            });
        }

        const allQueryKey = genAllLiveChatQueryKeyByLiveChatId(message.liveChatId);
        if (allQueryKey) {
            _updateQueryData(allQueryKey, liveChats => {
                return liveChats?.map(lc => {
                    if (lc._id === message.liveChatId) {
                        return {
                            ...lc,
                            since: message.createdAt,
                            latestMessage: message
                        };
                    }
                    return lc;
                }) ?? [];
            });
        }

        // 안 읽은 상담에 추가
        _updateQueryData(['liveChats', 'unread', channelId], liveChats => {
            return liveChats?.map(lc => {
                if (lc._id === message.liveChatId) {
                    return {
                        ...lc,
                        since: message.createdAt,
                        latestMessage: message
                    };
                }
                return lc;
            }) ?? [];
        });

        // 즐겨찾기 상담에 추가
        _updateQueryData(['liveChats', 'favorite', channelId], liveChats => {
            return liveChats?.map(lc => {
                if (lc._id === message.liveChatId) {
                    return {
                        ...lc,
                        since: message.createdAt,
                        latestMessage: message
                    };
                }
                return lc;
            }) ?? [];
        });
    }, [channelId, _updateQueryData, genLiveChatQueryKeyByLiveChatId, genAllLiveChatQueryKeyByLiveChatId]);

    const expireLiveChat = useCallback((liveChat: LiveChat) => {
        const queryKey = genLiveChatQueryKey(liveChat.state, liveChat.requestedOutsideWorkingTimes);
        if (queryKey) {
            _updateQueryData(queryKey, liveChats => {
                return liveChats?.map(lc => {
                    if (lc._id === liveChat._id) {
                        lc.state = 'expired';
                        lc.expiredAt = liveChat.expiredAt;
                    }

                    return lc;
                }) ?? [];
            });
        }

        const allQueryKey = genAllLiveChatQueryKey(liveChat.state);
        if (allQueryKey) {
            _updateQueryData(allQueryKey, liveChats => {
                return liveChats?.map(lc => {
                    if (lc._id === liveChat._id) {
                        lc.state = 'expired';
                        lc.expiredAt = liveChat.expiredAt;
                    }

                    return lc;
                }) ?? [];
            });
        }
    }, [_updateQueryData, genLiveChatQueryKey, genAllLiveChatQueryKey]);

    const updateUser = useCallback((user: ChatUser) => {
        getLiveChatAllQueryKeys().forEach(queryKey => {
            _updateQueryData(queryKey, liveChats => {
                return liveChats?.map(lc => {
                    if (lc.user._id === user._id) {
                        return {
                            ...lc,
                            user: {
                                ...lc.user,
                                ...user
                            }
                        };
                    }

                    return lc;
                }) ?? [];
            });
        });

        // 즐겨찾기에 사용자 업데이트
        _updateQueryData(['liveChats', 'favorite', channelId], liveChats => {
            return liveChats?.map(lc => {
                if (lc.user._id === user._id) {
                    return {
                        ...lc,
                        user: {
                            ...lc.user,
                            ...user
                        }
                    };
                }

                return lc;
            }) ?? [];
        });

    }, [_updateQueryData, getLiveChatAllQueryKeys, channelId]);

    const removeManager = useCallback((liveChatId: string, userId: string) => {
        const queryKey = genLiveChatQueryKeyByLiveChatId(liveChatId);
        if (queryKey) {
            _updateQueryData(queryKey, liveChats => {
                return liveChats?.map(lc => {
                    if (lc._id === liveChatId) {
                        return {
                            ...lc,
                            managers: lc.managers.filter(manager => manager.userId !== userId)
                        }
                    }
                    return lc;
                }) ?? [];
            });
        }

        const allQueryKey = genAllLiveChatQueryKeyByLiveChatId(liveChatId);
        if (allQueryKey) {
            _updateQueryData(allQueryKey, liveChats => {
                return liveChats?.map(lc => {
                    if (lc._id === liveChatId) {
                        return {
                            ...lc,
                            managers: lc.managers.filter(manager => manager.userId !== userId)
                        }
                    }
                    return lc;
                }) ?? [];
            });
        }


    }, [_updateQueryData, genLiveChatQueryKeyByLiveChatId, genAllLiveChatQueryKeyByLiveChatId]);

    const invalidate = useCallback(async () => {
        const promises = getLiveChatAllQueryKeys().map(queryKey => queryClient.invalidateQueries(queryKey, {exact: true}));
        await Promise.all(promises);
    }, [queryClient, getLiveChatAllQueryKeys]);

    const refetchLiveChats = useCallback(async () => {
        const promises = getLiveChatAllQueryKeys().map(queryKey => queryClient.refetchQueries({queryKey: queryKey, exact: true}));
        await Promise.all(promises);

    }, [queryClient, getLiveChatAllQueryKeys]);

    const refetchUnreadLiveChats = useCallback(async () => {
        await queryClient.refetchQueries({queryKey: ['liveChats', 'unread', channelId], exact: true});
    }, [channelId, queryClient]);

    const invalidateLiveChatTotal = useCallback(async () => {
        if (!manager) {
            return;
        }
        await queryClient.refetchQueries({queryKey: ['liveChatTotal', channelId, manager!.userId], exact: true});
    }, [channelId, manager, queryClient]);

    const updateLiveChatFollowers = useCallback((liveChat: LiveChat) => {
        if (!manager) return
        const queryKey = ['liveChats', 'favorite', channelId];
        const action = liveChat.followers.includes(manager.userId) ? 'push' : 'pop'

        _updateQueryData(queryKey, liveChats => {

            if (action === 'push') {
                if (!liveChats?.some(lc => lc._id === liveChat._id)) {
                    return [liveChat, ...(liveChats ?? [])];
                }

                return liveChats ?? [];
            } else {
                return liveChats?.filter(lc => lc._id !== liveChat._id) ?? [];
            }

        });

    }, [channelId, manager, _updateQueryData]);

    return {
        addLiveChat,
        removeLiveChat,
        addUnreadLiveChat,
        removeUnreadLiveChat,
        updateLiveChat,
        updateLatestMessage,
        expireLiveChat,
        updateUser,
        getLiveChat,
        getShouldExistLiveChat,
        removeManager,
        invalidate,
        refetchLiveChats,
        refetchUnreadLiveChats,
        invalidateLiveChatTotal,
        updateLiveChatFollowers
    };
};


export default useLiveChatsHandler;