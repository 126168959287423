import React, {useEffect} from "react";
import {useInView} from "react-intersection-observer";


type MoreTriggerProps = {
    id?: string
    position: 'top' | 'bottom'
    onEnter: () => void
    scrollHeight?: number
}

const MoreTrigger: React.FC<MoreTriggerProps> = (props) => {
    const { id, position, onEnter, scrollHeight } = props;
    const [ref, inView] = useInView({threshold: 0});

    useEffect(() => {
        if (inView) {
            onEnter();
        }
    }, [inView, onEnter]);

    if (scrollHeight !== undefined) {
        return (
            <div id={id}
                 className="position-absolute" ref={ref}
                 style={{left: 0, width: '100%', height: `${scrollHeight * 0.3}px`, [position]: 0}}/>
        )
    } else {
        return null;
    }
};

export default MoreTrigger;
