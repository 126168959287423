import oauthAxios from "../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../core/variables";

class RunCodeSnippetRepository {
    async runCommerceOrder(channelId: string, memberId: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/code-snippet/commerce-orders?memberId=${memberId}`)
    }

    async runTestCodeSnippet(channelId: string, key: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/code-snippet/test`, {params: {key}})
    }

}

export default new RunCodeSnippetRepository();