import React, {createRef, CSSProperties, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import classNames from "classnames";
import {Badge} from "react-bootstrap";
import {LiveChat} from "../../model/livechat";
import styled from "styled-components";
import {TextMessage} from "../../model/message";
import Avatar from "../../component/Avatar";
import kakaotalkIcon from "../../assets/images/kakaotalk_logo.png";
import instagramIcon from "../../assets/images/instagram_logo.png";
import facebookMessengerIcon from "../../assets/images/facebook_messenger_logo.png";
import navertalkIcon from "../../assets/images/navertalk_logo.png";
import lineIcon from "../../assets/images/line_logo.png";
import moment from "moment";
import useUnreadMessages from "../../query/message/useUnreadMessages";
import {useIsMounted, useMountEffect} from "@react-hookz/web";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import useLiveChatBulkActionHandler from "../../hook/useLiveChatBulkActionHandler";


const ChatList: React.FC<ChatListProps> = ({groupKey, liveChats, className, style}) => {
    const isMounted = useIsMounted();
    const [availableAnimation, setAvailableAnimation] = useState(false);

    const sortedLiveChats = useMemo(() => {
        return liveChats.sort((a, b) => {
            const isBefore = moment(a.since).isBefore(moment(b.since));
            const isSame = a.since === b.since;

            return isSame ? (b.priority - a.priority) : (isBefore ? 1 : -1);
        }).map(liveChat => ({
            ...liveChat,
            nodeRef: createRef<HTMLLIElement>()
        }));
    }, [liveChats]);

    useMountEffect(() => {
        if (isMounted()) {
            setTimeout(() => {
                setAvailableAnimation(true);
            }, 2000);
        }
    });

    return (
        <ChatListStyle className={classNames({[className || '']: className})} style={style}>
            {liveChats.length >= 1 &&
                <TransitionGroup component="ul" className="live-chat-list" enter={availableAnimation} appear={availableAnimation} exit={availableAnimation}>
                    {sortedLiveChats.map((liveChat, index) => (
                        <CSSTransition key={`${groupKey}${liveChat._id}`} nodeRef={liveChat.nodeRef} timeout={300} classNames="chat-item">
                            <Chat index={index} liveChat={liveChat} ref={liveChat.nodeRef} />
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            }
        </ChatListStyle>
    );
};


const Chat = React.forwardRef<HTMLLIElement, ChatProps>(({liveChat}, ref) => {
    const {channelId, liveChatId} = useParams<{channelId: string, liveChatId: string}>();

    if (!channelId) {
        throw new Error('Required channelId path params')
    }

    const navigate = useNavigate();
    const intl = useIntl();

    const {isBulkMode, isBulkActionLoading, bulkLiveChatIds, updateBulkLiveChatIds} = useLiveChatBulkActionHandler(channelId);

    const bulkSelected = useMemo(() => {
        return bulkLiveChatIds.includes(liveChat._id);
    }, [bulkLiveChatIds, liveChat._id]);

    // const liveChatsHandler = useLiveChatsHandler(liveChat.channelId);

    // const [mountState] = useState(liveChat.state);

    const {data: unreadMessages} = useUnreadMessages(channelId!);

    const latestPreviewMessage = useMemo(() => {
        const latestMessage = liveChat.latestMessage;
        if (latestMessage && latestMessage.blocks.length > 0) {
            if (latestMessage.blocks[0].type === 'text') {
                return (latestMessage.blocks[0].value as TextMessage).text;
            }
            else if ((latestMessage.blocks[0].type === 'file')) {
                return intl.formatMessage({id: 'i000069'});
            }
            else {
                return intl.formatMessage({id: 'i000065'});
            }
        }
        else {
            return intl.formatMessage({id: 'i000064'});
        }
    }, [liveChat, intl]);

    const unreadCount = useMemo(() => {
        if (unreadMessages) {
            const liveChatUnreadMessages = unreadMessages[liveChat._id] ?? [];
            return liveChatUnreadMessages.length < 100 ? liveChatUnreadMessages.length : '99+';
        }
        else {
            return 0;
        }
    }, [liveChat, unreadMessages]);

    const chatType = useMemo(() => {
        return liveChat.type || 'web'
    }, [liveChat]);

    const onClickChat = (e: React.MouseEvent) => {
        if (liveChatId === liveChat._id) {
            e.preventDefault();
            return;
        }

        // liveChatsHandler.cleanOtherState();
        navigate(`/channels/${channelId}/livechats/${liveChat._id}`);
    };

    const onClickAvatar = (e: React.MouseEvent) => {
        if (!isBulkMode || isBulkActionLoading) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        updateBulkLiveChatIds(liveChat._id);
    }

    const displayDate = useMemo(() => {

        const now = moment.utc().local()
        const nowYear = now.year()
        const nowMonth = now.month()
        const nowDay = now.daysInMonth()

        const createdAt = moment.utc(liveChat.latestMessage?.createdAt ?? liveChat.createdAt).local()
        const createdAtYear = createdAt.year()
        const createdAtMonth = createdAt.month()
        const createdAtDay = createdAt.daysInMonth()

        if (createdAtYear === nowYear && createdAtMonth === nowMonth && createdAtDay === nowDay) {
            return createdAt.startOf('minute').fromNow() // Today
        } else if (createdAtYear === nowYear && createdAtMonth === nowMonth && createdAtDay === nowDay - 1) {
            return intl.formatMessage({id: 'i200011'}); // Yesterday
        } else if (createdAtYear === nowYear) {
            return createdAt.format("MMM Do"); // This year
        } else {
            return createdAt.format("YYYY.MM.DD"); // Others
        }
    }, [liveChat.latestMessage, intl, liveChat.createdAt])

    return (
        <ChatItemStyle className={classNames({'active': liveChatId === liveChat._id})} onClick={onClickChat} ref={ref}>
            <div className="chat-link overflow-hidden">
                <div onClick={onClickAvatar}>
                    {isBulkMode && bulkSelected ?
                        <div className="position-relative">
                            <Avatar size={40} name="" className="chat-avatar" style={{backgroundColor: "#4F77F2"}}/>
                            <i className="position-absolute mdi mdi-check" style={{fontSize: "30px", left: "5px", top: "-2px", color: "white"}}/>
                        </div>
                        :
                        <Avatar size={40} name={liveChat.user.name || liveChat.user.alias} className="chat-avatar" />
                    }
                </div>
                <div className="d-flex flex-column w-100 overflow-hidden" style={{minWidth: 0}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center" style={{minWidth: 0}}>
                            <p className="mb-0 text-truncate fw-bold" >
                                {liveChat.user.name || liveChat.user.alias}
                            </p>
                            {(chatType === 'kakaotalk' || chatType === 'sangdamtalk') && <img className="chat-type" src={kakaotalkIcon} alt="kakaotalk" />}
                            {chatType === 'instagram' && <img className="chat-type" src={instagramIcon} alt="instagrem" />}
                            {chatType === 'facebook' && <img className="chat-type" src={facebookMessengerIcon} alt="facebook" />}
                            {chatType === 'navertalk' && <img className="chat-type" src={navertalkIcon} alt="navertalk" />}
                            {chatType === 'line' && <img className="chat-type" src={lineIcon} alt="line" />}
                        </div>
                        {displayDate && (
                            <small className={classNames('d-block text-truncate text-end', {'text-primary fw-bold': unreadCount > 0})}
                                   style={{flexShrink: 0, flexGrow: 0}}
                            >
                                {displayDate}
                            </small>
                        )}
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="mb-0 text-truncate" >
                            {latestPreviewMessage}
                        </p>
                        {unreadCount > 0 && (<Badge bg="primary" className="ml-1">{unreadCount}</Badge>)}
                    </div>
                </div>
            </div>
        </ChatItemStyle>
    );
});

type ChatListProps = {
    groupKey: string
    liveChats: LiveChat[]
    className?: string
    style?: CSSProperties
};

type ChatProps = {
    index: number;
    liveChat: LiveChat;
};

const ChatListStyle = styled.div`
  transition: opacity 0.15s ease-in-out;
  margin-top: 8px;
  
  .live-chat-list {
    //width: 100%;
    //padding-inline-start: 0.325rem;
    //padding-left: 0.325rem;
    padding: 0 11px;
    list-style: none;
    margin-bottom: 0;
    transition: height 0.3s ease-in-out;
  }

  .chat-item-enter {
    opacity: 0;
    //max-height: 0;
  }
  .chat-item-enter-active {
    opacity: 1;
    //max-height: 100px;
    transition: opacity 300ms ease-in;
  }
  .chat-item-exit {
    opacity: 1;
    max-height: 100px;
  }
  .chat-item-exit-active {
    opacity: 0;
    max-height: 0;
    transition: opacity 300ms ease-out, max-height 300ms ease-out;
  }
`;

const ChatItemStyle = styled.li`
  color: var(--left-container-text-color);
  border: 1px solid transparent;
  margin-bottom: 1px;
  user-select: none;
  position: relative;
  overflow-x: visible;
  overflow-y: hidden;
  border-radius: 4px;

  //&.remove {
  //  //transition: all 0.5s ease-in-out;
  //  //margin-left: 100%;
  //  animation: remove-chats .5s ease-in-out;
  //}
  
  &.active, &:hover {
    //border-radius: 4px;
    background-color: var(--chat-list-active-color);
    color: var(--menu-active-color) !important;
  }

  .chat-mask {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    //background-color: rgba(0, 0, 0, 0.3);
    //background-color: rgba(49, 50, 52, 0.6);
    
    border-radius: 4px;
    cursor: pointer;
    
    &.active {
      background-color: rgba(120, 124, 122, 0.6);

      .check-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--ct-primary);
        width: 28px;
        height: 28px;
        border-radius: 50%;
      }
    }
    
    transition: background-color ease-in-out 0.3s;
  }
  
  .check-icon {
    display: none;
  }

  .chat-link {
    display: flex;
    position: relative;
    cursor: pointer;
    font-size: 0.8rem;
    color: inherit;
    padding: 4px;
    //margin: 0.1rem 0.1rem 0.1rem 0.1rem;

    align-items: center !important;

    .chat-avatar {
      border-radius: 20px;
      margin-right: 10px;
    }
    
    .chat-type {
      margin-left: 3px;
      width: 17px;
      height: 17px;
    }

    .complete-masking {
      position: absolute;
      background-color: rgba(206, 212, 218, 0.50);
      display: flex;
      align-items: center;
      justify-content: center;
      top: -5px;
      right: -5px;
      bottom: -5px;
      left: -5px;
      border-radius: 6px;

      span {
        padding: 0.325rem 0.6rem;
        border-radius: 6px;
        background-color: rgb(206, 212, 218);
      }
    }
  }
`

export default ChatList;