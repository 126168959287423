import React, {useMemo} from "react";
import parse from "html-react-parser";
import ImageMessage from "./ImageMessage";
import {ErrorBoundary} from "@sentry/react";


const TextMessage = React.memo<{text?: string, trackedText?: string, preview?: boolean}>(({text, trackedText, preview}) => {
    const urls = useMemo(() => {
        if (preview) {
            // const urlRegex = /(https?:\/\/\S+)/g;
            const urlRegex = /\bhttps?:\/\/\S+\.(?:jpg|jpeg|png|webp|avif|gif|svg)\b/;
            return text?.match(urlRegex) ?? [];
        }

        return [];
    }, [text, preview]);

    const trackedWrapperText = useMemo(() => {
        return (trackedText ? text?.replaceAll(trackedText, `<span class="tracked-substring">${trackedText}</span>`) : text) ?? '';
    }, [text, trackedText]);

    const contents = useMemo(() => {
        return parse(trackedWrapperText);
    }, [trackedWrapperText]);

    if (text) {


        return (
            <>
                <div className="message-content">
                    <ErrorBoundary fallback={<div className="text-message">{trackedWrapperText}</div>}>
                        <div className="text-message">{contents}</div>
                    </ErrorBoundary>
                </div>
                {urls.map((url, index) => (
                    <ImageMessage key={`${url}_${index}`} src={url} />
                ))}
            </>
        );
    }
    else {
        return null;
    }
});

// const TextMessage: React.FC<{text?: string, trackedText?: string, preview?: boolean}> = ({text, trackedText, preview}) => {
//     const urls = useMemo(() => {
//         if (preview) {
//             const urlRegex = /(https?:\/\/\S+)/g;
//             return text?.match(urlRegex) ?? [];
//         }
//
//         return [];
//     }, [text, preview]);
//
//     if (text) {
//         const wrappedText = trackedText ? text.replaceAll(trackedText, `<span class="tracked-substring">${trackedText}</span>`) : text
//
//         return (
//             <>
//                 <div className="message-content">
//                     <div className="text-message">{parse(wrappedText)}</div>
//                 </div>
//                 {urls.map((url, index) => (
//                     <ImageMessage key={`${url}_${index}`} src={url} />
//                 ))}
//             </>
//         );
//     }
//     else {
//         return null;
//     }
// };


export default TextMessage;
