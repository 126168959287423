import oauthAxios from "../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../core/variables";
import {ConditionalAnnouncementCreate} from "../model/conditionalAnnouncement";

class ConditionalAnnouncementRepository {
    async create(channelId: string, body: ConditionalAnnouncementCreate) {
        return oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/conditional-announcements`, body)
    }

    async find(channelId: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/conditional-announcements`)
    }

    async delete(channelId: string, resourceId: string) {
        return oauthAxios.delete(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/conditional-announcements/${resourceId}`)
    }
}

export default new ConditionalAnnouncementRepository();
