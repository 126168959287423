import React, {useRef} from "react";
import useLiveChatBulkActionHandler from "../../hook/useLiveChatBulkActionHandler";
import useModal, {MaruModal} from "../../hook/useModal";
import {Button, Form, Modal} from "react-bootstrap";
import {Content, Editor, EditorRef, FileInfo} from "@42maru/content-editor";
import {useIntl} from "react-intl";
import {isSupportedFileExtension, isSupportedFileSize} from "../../util/fileUtil";
import toast from "react-hot-toast";
import checkAxiosError from "../../util/checkAxiosError";
import {SocketEvent, useSocket} from "../../socket";
import {MessageForm} from "../../model/message";
import useManager from "../../query/manager/useManager";
import moment from "moment/moment";
import {useDialog} from "../../hook/useDialog";
import liveChatBulkRepository from "../../repository/LiveChatBulkRepository";

type BulkFileInfo = {
    url: string,
    filename: string,
    originalFilename: string,
    size: number,
    contentType: string
}

type BulkFileGroup = {
    defaultFile: BulkFileInfo;
    filesWithLiveChatId: {liveChatId: string, file: BulkFileInfo}[];
}

type BulkLiveChatMessageModalProps = {
    channelId: string;
};

export const BULK_LIVECHAT_MESSAGE_MODAL_ID = "bulk-livechat-message-modal-id";

const BulkLiveChatMessageModal: React.FC<BulkLiveChatMessageModalProps> = (props) => {
    const { channelId } = props;

    const intl = useIntl();
    const modal = useModal();
    const dialog = useDialog();

    const socket = useSocket();

    const { bulkLiveChatIds, disableBulkMode } = useLiveChatBulkActionHandler(channelId);
    const { data: manager } = useManager(channelId);

    const editorRef = useRef<EditorRef>(null);
    const defaultFileRef = useRef<BulkFileInfo[]>([]);
    const filesWithLiveChatIdRef = useRef<{liveChatId: string, file: BulkFileInfo}[][]>([]);

    const onClickFile = async (file: File) => {
        if (!isSupportedFileSize(file)) {
            toast.error(intl.formatMessage({id: "i200076"}));
            return;
        }

        if (!isSupportedFileExtension(file)) {
            toast.error(intl.formatMessage({id: "i200077"}));
            return;
        }

        try {
            const {data} = await liveChatBulkRepository.uploadFile(channelId, file, bulkLiveChatIds);
            const bulkFileGroup = data.result as BulkFileGroup;
            const fileInfo = bulkFileGroup.defaultFile;
            defaultFileRef.current.push(fileInfo);
            filesWithLiveChatIdRef.current.push(bulkFileGroup.filesWithLiveChatId);

            return {
                filename: fileInfo.originalFilename,
                url: fileInfo.url,
                size: fileInfo.size,
                contentType: fileInfo.contentType
            }
        } catch (e){
            if (checkAxiosError(e, 'FIL_001')) {
                toast.error(intl.formatMessage({id: 'e000002'}));
            } else if (checkAxiosError(e, 'FIL_003')) {
                toast.error(intl.formatMessage({id: 'i200077'}))
            } else {
                toast.error(intl.formatMessage({id: 'e000003'}));
            }
        }
    };

    const sendMessages = (contents: Content[], files: FileInfo[]) => {
        if (!manager) {
            return;
        }
        const blocks: any[] = [];
        const text = contents.map(content => content.value.text).join('\n');
        if (!text && files.length < 1) {
            toast.error(intl.formatMessage({id: "i210060"}));
            return;
        }

        const html = contents.map(content => content.value.html).join('\n');
        if (text) {
            blocks.push({
                type: "text",
                value: {
                    text: text.trim(),
                    html: html.trim()
                },
                meta: contents.map(content => content.meta)
            });
        }

        const messages = bulkLiveChatIds.map(liveChatId => {
            const blocksWithFile = [...blocks];
            filesWithLiveChatIdRef.current.forEach((item, index) => {
                const fileWithLiveChatId = item.find(v => v.liveChatId === liveChatId);
                const file = fileWithLiveChatId?.file ?? defaultFileRef.current?.[index];
                if (!file) {
                    return;
                } else {
                    blocksWithFile.push({
                        type: 'file',
                        value: {
                            filename: file.filename,
                            url: file.url,
                            size: file.size,
                            contentType: file.contentType
                        }
                    })
                }
            });

            const message: MessageForm = {
                senderType: "manager",
                senderUserId: manager.userId,
                blocks: blocksWithFile,
                createdAt: moment.utc().format("YYYY-MM-DD HH:mm:ss.SSSSS"),
                sendId: `${Date.now()}`,
            }

            return {liveChatId, message}
        });

        socket.send(SocketEvent.MESSAGES, messages);
        toast.success(intl.formatMessage({id: "i210061"}));

        defaultFileRef.current = [];
        filesWithLiveChatIdRef.current = [];
        modal.close(BULK_LIVECHAT_MESSAGE_MODAL_ID);
        disableBulkMode();
    }

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!manager) {
            return;
        }

        if (!bulkLiveChatIds) {
            toast.error(intl.formatMessage({id: "i210062"}));
            return;
        }

        dialog.open({
            content: intl.formatMessage({id: "i210063"}),
            confirmText: intl.formatMessage({id: "i210064"}),
            cancelText: intl.formatMessage({id: "i210065"}),
            onConfirm: () => {
                if (editorRef.current) {
                    sendMessages(editorRef.current.getContents(), editorRef.current.files);
                }
            }
        });
    };

    const onClickCancelBtn = (e: React.MouseEvent) => {
        e.preventDefault();
        modal.close(BULK_LIVECHAT_MESSAGE_MODAL_ID);
    }

    return (
        <MaruModal id={BULK_LIVECHAT_MESSAGE_MODAL_ID} title={intl.formatMessage({id: "i210069"})} centered size="lg">
            <Modal.Body>
                <div className="d-flex justify-content-center">
                    <Form id="bulk-message-form" onSubmit={onSubmit}>
                        <Form.Label>{intl.formatMessage({id: "i210066"})}</Form.Label>
                        <Editor id="bulk-message-editor"
                                placeholder={intl.formatMessage({id: "i210067"})}
                                height="300px"
                                width="600px"
                                ref={editorRef}
                                onClickFile={onClickFile}
                                enterFunctionKey={"shift"}
                        />
                    </Form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" className="btn-rounded" variant="outline-secondary" onClick={onClickCancelBtn}>
                    {intl.formatMessage({id: 'i000022'})}
                </Button>
                <Button type="submit" className="btn-rounded" form={'bulk-message-form'}>
                    {intl.formatMessage({id: "i210068"})}
                </Button>
            </Modal.Footer>
        </MaruModal>
    );
};

export default BulkLiveChatMessageModal;
