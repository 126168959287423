import {atom, selectorFamily} from "recoil";
import {ChatSearchHistory} from "../../model/chatSearch";
import {LIVE_CHAT_SEARCH_HISTORIES_STORAGE_KEY} from "../../core/variables";
import moment from "moment";

const chatSearchHistoriesState = atom<{[key: string]: ChatSearchHistory[]}>({
    key: 'chatSearchHistoriesState',
    default: (() => {
        const historiesRaw = window.localStorage.getItem(LIVE_CHAT_SEARCH_HISTORIES_STORAGE_KEY) ?? "{}";
        return JSON.parse(historiesRaw) as {[key: string]: ChatSearchHistory[]}
    })()
});

const channelChatSearchHistoriesState = selectorFamily({
    key: 'channelChatSearchHistoriesState',
    get: (channelId: string) => ({ get }) => {
        const chatSearchHistories = get(chatSearchHistoriesState);
        return (chatSearchHistories[channelId] ?? []) as ChatSearchHistory[];
    },
    set: (channelId: string) => ({ get, set }, newHistories) => {
        set(
            chatSearchHistoriesState,
            prevValue => {
                const filteredHistories = (newHistories as ChatSearchHistory[]).filter(
                    history => moment.utc().diff(moment(history.searchedAt), 'days') < 7
                ).slice(0, 50);

                const result = {
                    ...prevValue,
                    [channelId]: filteredHistories
                }

                window.localStorage.setItem(
                    LIVE_CHAT_SEARCH_HISTORIES_STORAGE_KEY,
                    JSON.stringify(result)
                );

                return result
            }
        );
    }
});

export default channelChatSearchHistoriesState;
