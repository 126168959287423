import React from "react";
import '../assets/css/typing_indicator.scss';

const TypingIndicator: React.FC = () => {

    return (
        <div className="typing-indicator">
            <span/>
            <span/>
            <span/>
        </div>
    )
};

export default TypingIndicator;