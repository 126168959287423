import {atom, selectorFamily} from "recoil";
import {PanelSetting} from "../../model/livechat";
import {PANEL_SETTINGS_STORAGE_KEY, PANEL_SETTINGS_STORAGE_KEY_LEGACY} from "../../core/variables";

const PANEL_DEFAULT_SETTING: PanelSetting[] = [
    {id: 'livechat', i18n: "i100019", show: true, collapse: false},
    {id: 'customer', i18n: "i100020", show: true, collapse: false},
    {id: 'archive', i18n: "i100021", show: true, collapse: false},
    {id: 'fileArchive', i18n: "i100045", show: true, collapse: false},
    {id: 'accessLog', i18n: "i100022", show: true, collapse: false},
    {id: 'members', i18n: 'i100033', show: true, collapse: false},
    {id: 'orders', i18n: 'i100057', show: true, collapse: false},
];

const getLegacyPanelSettings = () => {
    const raw = window.localStorage.getItem(PANEL_SETTINGS_STORAGE_KEY_LEGACY) ?? undefined;
    return raw ? JSON.parse(raw) : undefined
}

const panelSettingsState = atom<{[key: string]: PanelSetting[]}>({
    key: 'panelSettingsState',
    default: (() => {
        const panelSettingsRaw = window.localStorage.getItem(PANEL_SETTINGS_STORAGE_KEY) ?? "{}";
        return JSON.parse(panelSettingsRaw) as {[key: string]: PanelSetting[]}
    })()
});

const channelPanelSettingsState = selectorFamily({
    key: 'channelPanelSettingsState',
    get: (channelId: string) => ({ get }) => {
        const panelSettings = get(panelSettingsState);

        if (!panelSettings[channelId]) {
            // migrate legacy panel settings to each channel-wise panel settings
            let panels: PanelSetting[] = getLegacyPanelSettings();
            if (panels) {
                const panelKeys = panels.map((v) => v.id);
                const extraPanels = PANEL_DEFAULT_SETTING.filter((v) => !panelKeys.includes(v.id));
                if (extraPanels) {
                    panels = panels.concat(extraPanels);
                }
                return panels
            } else {
                return PANEL_DEFAULT_SETTING;
            }
        } else {
            return panelSettings[channelId]
        }
    },
    set: (channelId: string) => ({ get, set }, newPanelSettings) => {
        set(
            panelSettingsState,
            prevValue => {
                const result = {
                    ...prevValue,
                    [channelId]: newPanelSettings as PanelSetting[]
                }

                window.localStorage.setItem(PANEL_SETTINGS_STORAGE_KEY, JSON.stringify(result))

                return result
            }
        );
    }
})

export default channelPanelSettingsState;
