import styled from "styled-components";


export const UnStyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  cursor: pointer;
  user-select: none;
  background-color: white;
  border: none;
  padding: 0.3rem 0.9rem;
  font-size: .9rem;
  border-radius: 0.15rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out;
  
  &:hover {
    background-color: var(--ct-light);
  }
`;

export const CenteredIcon = styled.i<{size: number}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  padding: 0;
  
  :before {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
  }
  
  &:hover {
    color: var(--ct-info);
  }
`