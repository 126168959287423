import {useMutation, useQueryClient} from "@tanstack/react-query";
import {RoleForm} from "../../model/role";
import {LIVE_CHAT_API_HOST} from "../../core/variables";
import {toLiveChatError} from "../../error";
import oauthAxios from "../../core/oauthAxios";


const useCreateManagementUnitRole = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation(['managementUnitRoles', 'create', channelId], async (form: RoleForm) => {
        try {
            const {data} = await oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/roles`, form);
            return data.result;
        } catch (e) {
            throw toLiveChatError(e);
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(['roles', channelId], {exact: true});
        }
    });
}

export default useCreateManagementUnitRole;
