import {LIVE_CHAT_API_HOST} from "../core/variables";
import {NotificationSetting} from "../model/NotificationSetting";
import oauthAxios from "../core/oauthAxios";

class NotificationSettingRepository {

    async create(channelId: string, notificationSetting: NotificationSetting) {
        return oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/notification-settings`, notificationSetting);
    }

    async read(channelId: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/notification-settings`);
    }

    async update(channelId: string, notificationSetting: NotificationSetting) {
        return oauthAxios.put(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/notification-settings`, notificationSetting);
    }
}

export default new NotificationSettingRepository();