import React, {useRef, useState} from "react";
import Viewer from "react-viewer";
import IconButton from "../StyledButton";
import electronRuntime from "../../core/electronRuntime";
import {v4} from "uuid";
import {useSetRecoilState} from "recoil";
import downloadFilesState from "../../recoil/downloadFiles";
import FullAreaSpinner from "../FullAreaSpinner";
import {useIntl} from "react-intl";

const ImageMessage: React.FC<{src: string, filename?: string, downloadable?: boolean}> = ({src, filename, downloadable}) => {
    const intl = useIntl();

    const [visible, setVisible] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState(false);

    const setDownloadFiles = useSetRecoilState(downloadFilesState);

    const downloadLinkRef = useRef<HTMLAnchorElement>(null);

    const toggleImage = () => {
        setVisible(!visible);
    };

    const onClickDownload = async (e: React.MouseEvent) => {
        e.preventDefault();

        if (src) {
            if (electronRuntime) {
                const id = v4();
                electronRuntime.send('download', {id, url: src, filename});
                setDownloadFiles(prev => {
                    return [{id, url: src, filename: filename ?? src.split('/').pop()!}, ...prev];
                });
            }
            else {
                if (downloadLinkRef.current) {
                    downloadLinkRef.current.click();
                }
            }
        }
    };

    const onErrorLoadImage = (e: React.SyntheticEvent<HTMLImageElement>) => {
        e.preventDefault();
        setError(true);
        setLoading(false);
    };

    const onLoadImage = (e: React.SyntheticEvent<HTMLImageElement>) => {
        e.preventDefault();
        setLoading(false);
    };

    return (
        <div className="message-content d-flex align-items-end my-1">
            <div className="image-message" onClick={toggleImage}>
                {isLoading && <FullAreaSpinner size="sm" variant="light" animation="border" />}
                {!isError && <img src={src} alt="preview" onError={onErrorLoadImage} onLoad={onLoadImage} />}
                {/*{(!isLoading && !isError) && <img src={src} alt="preview" />}*/}
                {isError && (
                    <div className="h-100 w-100 d-flex align-items-center justify-content-center p-1">
                        <span className="text-muted font-12">{intl.formatMessage({id: 'i000384'})}</span>
                    </div>
                )}
            </div>
            {(!isLoading && !isError) && (
                <IconButton onClick={onClickDownload}>
                    <i className="mdi mdi-download" />
                </IconButton>
            )}
            {(!isLoading && !isError && visible) && <Viewer visible={visible}
                                                onMaskClick={toggleImage}
                                                onClose={toggleImage}
                                                images={[{src: src, downloadUrl: src, alt: filename}]}
                                                rotatable={false}
                                                scalable={false}
                                                changeable={false}
                                                noNavbar={true} />
            }
            {downloadable && <a href={src} ref={downloadLinkRef} target="_blank" rel="noreferrer" download={filename} className="d-none">download</a>}
        </div>
    );
};

export default ImageMessage;
