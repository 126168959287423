import {Page} from "../index";
import {useInfiniteQuery} from "@tanstack/react-query";
import chatbotMessageRepository from "../../repository/ChatbotMessageRepository";
import {ChatbotMessageInfo} from "../../model/chatbot";
// import {UseInfiniteQueryOptions} from "react-query/types/react/types";
import {LiveChatError, toLiveChatError} from "../../error";


const getData = async (channelId: string, liveChatId: string, endDate: string, pageable: {page: number, limit: number}) => {
    try {
        const {data} = await chatbotMessageRepository.find(channelId, liveChatId, endDate, pageable.page, pageable.limit);
        const messages = _genMessages(data.result.content.reverse());
        return {
            result: messages,
            pageable: {
                ...pageable,
                isFirst: !data.result.has_next
            }
        };
    } catch (e) {
        throw toLiveChatError(e);
    }
}

const _genMessages = (messages: ChatbotMessageInfo[]) => {
    return messages.filter(messageInfo => {
        if (messageInfo.messages?.length === 1) {
            return messageInfo.messages[0].data_type !== 'custom';
        }
        return true;
    });
    // return messages.map((messageInfo, index) => {
    //     if (index > 0) {
    //         const prevMessage = messages[index - 1];
    //         const diff = Math.abs(moment(prevMessage.created_at)
    //             .diff(moment(messageInfo.created_at), 'minutes'));
    //         return {
    //             ...messageInfo,
    //             showSenderInfo: diff > 0 || prevMessage.sender?.type !== messageInfo.sender?.type
    //         };
    //     }
    //     else {
    //         return {
    //             ...messageInfo,
    //             showSenderInfo: true
    //         };
    //     }
    // }).filter(messageInfo => {
    //     if (messageInfo.messages?.length === 1) {
    //         return messageInfo.messages[0].data_type !== 'custom';
    //     }
    //     return true;
    // });
}

const useChatbotMessages = (channelId: string, liveChatId: string, endDate: string) => {
    return useInfiniteQuery<Page<ChatbotMessageInfo, {page: number}>, LiveChatError>({
        queryKey: ['chatbotMessages', channelId, liveChatId],
        queryFn: async ({pageParam = {page: 0, limit: 50, isFirst: false}}) => getData(channelId, liveChatId, endDate, pageParam),
        getPreviousPageParam: ({pageable}) => {
            if (pageable.isFirst) {
                return undefined;
            }

            return {
                page: pageable.page + 1,
                limit: pageable.limit,
                isFirst: false
            };
        },
        onError: (error) => {
            console.log(error)
        }
    });
};


export default useChatbotMessages;