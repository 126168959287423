import {LIVE_CHAT_API_HOST} from "../core/variables";
import {Channel} from "../model/channel";
import oauthAxios from "../core/oauthAxios";

class ChannelRepository {

    async create(name: string) {
        return oauthAxios.post(`${LIVE_CHAT_API_HOST}/v2/channels`, {name});
    }

    async find() {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v2/channels`);
    }

    async update(channelId: string, channel: Channel) {
        return oauthAxios.put(`${LIVE_CHAT_API_HOST}/v2/channels/${channelId}`, channel)
    }

    async read(channelId: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v2/channels/${channelId}`);
    }

    async applyBannedWordsTemplate(channelId: string) {
        return oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/apply-banned-words-template`);
    }

    async createApiKey(channelId: string, name: string) {
        return oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/api-keys`, {name})
    }

    async findApiKeys(channelId: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/api-keys`)
    }

    async readApiKey(channelId: string, apiKeyId: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/api-keys/${apiKeyId}`)
    }

    async updateApiKey(channelId: string, name: string) {
        return oauthAxios.put(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/api-keys`, {name})
    }

    async deleteApiKey(channelId: string, apiKeyId: string) {
        return oauthAxios.delete(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/api-keys/${apiKeyId}`)
    }
}

export default new ChannelRepository();
