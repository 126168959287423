

export const RECONNECT = 'reconnect';
export const HEALTH = 'health';
export const HEALTH_ACK = 'health_ack';
export const MESSAGE = 'message';
export const MESSAGES = 'messages';
export const LIVE_CHAT_OPENED = 'live_chat_opened';
export const LIVE_CHAT_ASSIGNED = 'live_chat_assigned';
export const LIVE_CHAT_RESPONDING = 'live_chat_responding';
export const LIVE_CHAT_HOLDING = 'live_chat_holding';
export const LIVE_CHAT_CANCELED = 'live_chat_canceled';
export const LIVE_CHAT_EXPIRED = 'live_chat_expired';
export const LIVE_CHAT_SOLVED = 'live_chat_solved';
export const LIVE_CHAT_CLOSED = 'live_chat_closed';
export const LIVE_CHAT_UPDATED = 'live_chat_updated';
export const LIVE_CHAT_JOINED = 'live_chat_joined';
export const LIVE_CHAT_LEFT = 'live_chat_left';
export const LIVE_CHAT_CHANGE_ASSIGNEE = 'live_chat_change_assignee';
export const LIVE_CHAT_FOLLOWERS_UPDATE = 'live_chat_followers_update';
export const CONFIRM_MESSAGE = 'confirm_message';
export const CONFIRM_MESSAGES = 'confirm_messages';
export const CONFIRM_MESSAGE_ACK = 'confirm_message_ack';
export const CONFIRM_MESSAGES_ACK = 'confirm_messages_ack';
export const CHAT_USER_UPDATED = 'chat_user_updated';
export const CHAT_USER_ONLINE = 'chat_user_online';
export const CHAT_USER_OFFLINE = 'chat_user_offline';
export const BOARD_UNREAD_ARTICLE = 'board_unread_article';
export const BOARD_ARTICLE_CREATED = 'board_article_created';
export const TYPING = 'typing';
export const CHANNEL_UPDATED = 'channel_updated';
export const CATEGORY_CREATED = 'category_created';
export const CATEGORY_UPDATED = 'category_updated';
export const CATEGORY_DELETED = 'category_deleted';
export const APIKEY_CREATED = 'api_key_created';
export const APIKEY_UPDATED = 'api_key_updated';
export const APIKEY_DELETED = 'api_key_deleted';
export const WEBHOOK_CREATED = 'webhook_created';
export const WEBHOOK_UPDATED = 'webhook_updated';
export const WEBHOOK_DELETED = 'webhook_deleted';
export const SNIPPET = 'snippet';
export const REJECTED_LIVECHAT_MESSAGE = 'reject_livechat_message';
export const ANNOUNCEMENT = 'announcement';
export const MANAGER = 'manager';
export const FEED = 'feed';
