import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {Button, Dropdown, Form} from "react-bootstrap";
import {LiveChat} from "../../model/livechat";
import styled from "styled-components";
import moment from "moment";
// import {usePopper} from 'react-popper';
import {UnStyledButton} from "../UnstyledBootstrap";
import ChatPanelLabel from "./ChatPanelLabel";
import useCategories from "../../query/category/useCategories";
import useMemos from "../../query/memo/useMemos";
import useCreateMemo from "../../query/memo/useCreateMemo";
import useUpdateMemo from "../../query/memo/useUpdateMemo";
import toast from "react-hot-toast";
import LiveChatPriorityDropdown from "./LiveChatPriorityDropdown";
import useUpdateLiveChat from "../../query/liveChat/useUpdateLiveChat";
import useSocketIOSubscribe from "../../socket/useSocketIOSubscribe";
import {SocketEvent} from "../../socket";

const LiveChatInfoCard: React.FC<{liveChat: LiveChat}> = ({liveChat}) => {
    const intl = useIntl();

    return (
        <div>
            <AttributeInfoStyle>
                <ChatPanelLabel>{intl.formatMessage({id: 'i000170'})}</ChatPanelLabel>
                <div className="value category-selector">
                    <CategoryDropdown liveChat={liveChat} />
                </div>
            </AttributeInfoStyle>
            <AttributeInfoStyle>
                <ChatPanelLabel>{intl.formatMessage({id: 'i000382'})}</ChatPanelLabel>
                <div className="value category-selector">
                    <LiveChatPriorityDropdown liveChat={liveChat} />
                </div>
            </AttributeInfoStyle>
            <AttributeInfoStyle>
                <ChatPanelLabel>
                    {intl.formatMessage({id: 'i000171'})}
                </ChatPanelLabel>
                <span className="value">
                    {moment.utc(liveChat.createdAt).local().format('LLL')}
                </span>
            </AttributeInfoStyle>
            <AttributeInfoStyle>
                <ChatPanelLabel>
                    {intl.formatMessage({id: 'i000172'})}
                </ChatPanelLabel>
                <span className="value">
                    {liveChat.respondedAt ? moment.utc(liveChat.respondedAt).local().format('LLL') : '-'}
                </span>
            </AttributeInfoStyle>
            <AttributeInfoStyle>
                <ChatPanelLabel>
                    {intl.formatMessage({id: 'i000167'})}
                </ChatPanelLabel>
                <span className="value">
                    {`${liveChat.satisfaction ?? '-'} / 5`}
                </span>
            </AttributeInfoStyle>

            <MemoForm liveChat={liveChat} className="mt-1" />
        </div>
    );
};

const MemoForm: React.FC<{liveChat: LiveChat} & React.HTMLProps<HTMLDivElement>> = ({liveChat, className}) => {

    const intl = useIntl();

    const memoTmp = useRef<undefined | string>(undefined);
    const contentsRef = useRef<HTMLTextAreaElement>(null);

    const {data: memos, refetch} = useMemos(liveChat.channelId, liveChat._id);
    const {mutate: createMemo, isLoading: isCreating} = useCreateMemo(liveChat.channelId, liveChat._id);
    const {mutate: updateMemo, isLoading: isUpdating} = useUpdateMemo(liveChat.channelId, liveChat._id);

    useEffect(() => {
        const contents = memos && memos.length > 0 ? memos[0].contents : '';
        if (contentsRef.current) {
            contentsRef.current.value = contents || '';
            memoTmp.current = contents
        }
    }, [memos]);

    const submit = useCallback(async () => {
        if (isCreating || isUpdating) return

        const liveChatMemo = memos && memos.length > 0 ? memos[0] : {_id: undefined, contents: ''};
        const contents = contentsRef.current?.value || '';

        if (liveChatMemo._id) {
            updateMemo({memoId: liveChatMemo._id, contents: contents}, {
                onSuccess: () => {
                    memoTmp.current = contents
                    toast.success(intl.formatMessage({id: 'i000105'}));
                },
                onError: () => {
                    toast.error(intl.formatMessage({id: 'i000262'}));
                }
            });
        }
        else {
            createMemo(contents, {
                onSuccess: () => {
                    memoTmp.current = contents
                    toast.success(intl.formatMessage({id: 'i000105'}));
                },
                onError: () => {
                    toast.error(intl.formatMessage({id: 'i000262'}));
                }
            });
        }

    }, [isCreating, isUpdating, intl, createMemo, updateMemo, memos]);


    useSocketIOSubscribe(SocketEvent.RECONNECT, async () => refetch().finally());

    return (
        <MemoFormStyle className={className}>
            <Form.Control as="textarea"
                          ref={contentsRef}
                          rows={3}
                          placeholder={intl.formatMessage({id: 'i100023'})} />

            <Button id="livechat-memo-submit-btn"
                    size="sm"
                    variant="outline-primary"
                    className="btn-rounded"
                    onClick={submit}>
                {intl.formatMessage({id: 'i000040'})}
            </Button>
        </MemoFormStyle>
    )
}

const CategoryDropdown: React.FC<{liveChat: LiveChat}> = ({liveChat}) => {
    const intl = useIntl();

    const [showCategoryItems, setShowCategoryItems] = useState(false)
    const [categoryId, setCategoryId] = useState(liveChat.categoryId);

    const {data: categories} = useCategories(liveChat.channelId, {retry: true, suspense: false});

    // const liveChatHandler = useLiveChatHandler(liveChat.channelId);

    const {mutate: updateLiveChat} = useUpdateLiveChat(liveChat.channelId, liveChat._id);

    const options = useMemo(() => {
        const result = categories?.map((category) => ({id: category._id, label: category.name})) ?? [];

        result.splice(0, 0,  {id: 'null', label: intl.formatMessage({id: 'i100024'})})

        const leftOptions: {id: string, label: string}[] = [];
        const rightOptions: {id: string, label: string}[] = [];
        result.forEach((category, index) => {
            if (index % 2 === 0 || result.length < 6) {
                leftOptions.push(category);
            } else {
                rightOptions.push(category);
            }
        })
        return [leftOptions, rightOptions];
    }, [categories, intl]);

    const currentCategory = useMemo(() => {
        if (liveChat.categoryId) {
            const liveChatCategory = categories?.find(category => category._id === liveChat.categoryId)
            if (liveChatCategory) {
                return {id: liveChatCategory._id, label: liveChatCategory.name}
            }
        }

        return {id: 'null', label: intl.formatMessage({id: 'i100024'})}
    }, [liveChat, categories, intl]);

    const onToggleCategoryDropdown = async () => {
        const value = !showCategoryItems
        setShowCategoryItems(!showCategoryItems)

        if (!value && liveChat.categoryId !== categoryId) {
            updateLiveChat({categoryId: categoryId}, {
                onError: () => {
                    toast.error(intl.formatMessage({id: 'i100025'}));
                }
            });
            // try {
            //
            //     const {data} = await liveChatRepository.update(liveChat.channelId, liveChat._id, {categoryId: categoryId});
            //     liveChatHandler.updateLiveChat(data.result);
            // } catch (e) {
            //     toast.error(intl.formatMessage({id: 'i100025'}))
            // }
        }
    }

    const onCheckedCategory = (categoryId?: string) => {
        setCategoryId(categoryId);
    };

    return (
        <CategoryDropdownStyle onToggle={onToggleCategoryDropdown} show={showCategoryItems}>
            <Dropdown.Toggle id="chat-menu-dropdown-toggle" as={UnStyledButton} className="pl-0" bsPrefix="unused">
                <CategoryItem categoryId={currentCategory.id} label={currentCategory.label} showForm={false} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {showCategoryItems &&
                    <div className="category-list p-1">
                        <div className="option-section">
                            {options[0].map(v => (
                                <CategoryItem key={v.id}
                                              categoryId={v.id}
                                              label={v.label}
                                              showForm={true}
                                              defaultChecked={v.id === currentCategory.id}
                                              onChecked={onCheckedCategory}
                                              className="mb-1 highlight" />
                            ))}
                        </div>

                        {options[1].length > 0 && (
                            <>
                                <div className="option-split-bar" />
                                <div className="option-section">
                                    {options[1].map(v => (
                                        <CategoryItem key={v.id}
                                                      categoryId={v.id}
                                                      label={v.label}
                                                      showForm={true}
                                                      defaultChecked={v.id === currentCategory.id}
                                                      onChecked={onCheckedCategory}
                                                      className="mb-1 highlight" />
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                }
            </Dropdown.Menu>
        </CategoryDropdownStyle>
    )
};

const CategoryItem:React.FC<{categoryId: string, label: string, showForm?: boolean, defaultChecked?: boolean, onChecked?: (categoryId: string) => void} & React.HTMLProps<HTMLDivElement>> = (props) => {
    const {categoryId, label, showForm=true, defaultChecked=false, onChecked, className} = props
    const labelRef = useRef<HTMLSpanElement>(null)
    const radioRef = useRef<HTMLInputElement>(null);

    const _onClick = (e: React.MouseEvent) => {
        e.preventDefault();

        if (radioRef.current) {
            onChecked?.(categoryId);
            radioRef.current.checked = true;
        }
    };

    const onClickRadio = (e: React.MouseEvent) => {
        e.stopPropagation();

        onChecked?.(categoryId);
    }

    return (
        <CategoryItemStyle className={className} onClick={_onClick}>
            <span className="category-bar bg-primary"/>
            <span ref={labelRef} className="category-label mr-1 flex-grow-1 text-start">{label}</span>
            {showForm &&
                <Form.Check id={categoryId}
                            name="category-item"
                            type="radio"
                            defaultChecked={defaultChecked}
                            aria-label={label}
                            className="form-check"
                            onClick={onClickRadio}
                            ref={radioRef}
                />
            }
        </CategoryItemStyle>
    );
};

const CategoryItemStyle = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0.15rem;
  cursor: pointer;
  
  &.highlight {
    &:hover {
      background-color: var(--ct-light);
    }
  }
`

const CategoryDropdownStyle = styled(Dropdown)`
  position: unset;
  
  .category-bar {
    width: 20px;
    border-radius: 2.5px;
    height: 5px;
    margin: 0 0.375rem 0 0.2rem;
  }

  .category-label {
    position: relative;
    min-width: 100px;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .category-list {
    display: flex;
    max-height: 320px;
    overflow-y: auto;

    .option-split-bar {
      background-color: #e1e1e1;
      width: 1px;
      margin: 0.375rem 0.75rem;
    }
  }
`

const AttributeInfoStyle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.1rem 0;
  line-height: 28px;
  
  .label {
    flex: 1;
    margin-right: 0.4rem;
    flex-shrink: 0;
    color: #98a6ad;
    font-size: 12px;
  }
  .value {
    flex: 3;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const MemoFormStyle = styled.div `
  position: relative;
  width: 100%;

  textarea {
    padding-bottom: 2.4rem;
    &:focus + #livechat-memo-submit-btn {
      display: inline-block;
    }
  }
  
  #livechat-memo-submit-btn {
    display: none;
    position: absolute;
    right: 8px;
    bottom: 8px;
    
    &:focus {
      display: inline-block;
    }
    &:hover {
      display: inline-block;
    }
  }
`

export default LiveChatInfoCard;