import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {toLiveChatError} from "../../error";
import oauthAxios from "../../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../../core/variables";
import {ManagerStatistics} from "../../model/manager";
import {DEFAULT_ARGS, ReactQueryOptions} from "../index";


const useChannelManagerStatistics = (channelId: string, sinceHours: number, args?: ReactQueryOptions) => {
    let _args = {...DEFAULT_ARGS}
    if (args) {
        _args = Object.assign(_args, args)
    }
    return useQuery<ManagerStatistics[], AxiosError>({
            queryKey: [channelId, 'dashboard', 'manager', sinceHours],
            queryFn: async () => {
                try {
                    const { data } = await oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/statistic/managers?sinceHours=${sinceHours}`)
                    return data.result;
                } catch (e) {
                    throw toLiveChatError(e);
                }
            },
            ..._args
        }
    )
};

export default useChannelManagerStatistics;
