import {useQueryClient} from "@tanstack/react-query";
import {useCallback, useMemo} from "react";
import useStores from "./useStores";
import toast from "react-hot-toast";
import {useIntl} from "react-intl";

const useChannelSettingHandler = (channelId: string) => {
    const queryClient = useQueryClient();

    const {webhookListStore} = useStores();
    const intl = useIntl();

    const invalidateChannels = useCallback(async (_channelId: string) => {
        if (channelId === _channelId) {
            await queryClient.invalidateQueries(['channels', _channelId], {exact: true})
        }
    }, [channelId, queryClient])

    const invalidateCategorySummaries = useCallback(async (_channelId: string) => {
        if (channelId === _channelId) {
            await queryClient.refetchQueries(['categorySummaries', _channelId], {exact: true});
            await queryClient.invalidateQueries(['categories', _channelId], {exact: true});
        }
    }, [channelId, queryClient])

    const invalidateApiKeys = useCallback(async (_channelId: string) => {
        if (channelId === _channelId) {
            await queryClient.refetchQueries(['apiKeys', _channelId], {exact: true});
        }
    }, [channelId, queryClient])

    const invalidateWebhooks = useCallback(async (_channelId: string) => {
        if (channelId === _channelId) {
            webhookListStore.load(_channelId).catch(_ => {
                toast.error(intl.formatMessage({id: 'i0000201'}))
            });
        }
    }, [channelId, webhookListStore, intl])

    const invalidateSnippets = useCallback(async (_channelId: string) => {
        if (channelId === _channelId) {
            await queryClient.invalidateQueries(['snipptes', _channelId], {exact: true})
        }
    }, [channelId, queryClient])

    return useMemo(() => {
        return {
            invalidateChannels,
            invalidateCategorySummaries,
            invalidateApiKeys,
            invalidateWebhooks,
            invalidateSnippets,
    }}, [
        invalidateChannels,
        invalidateCategorySummaries,
        invalidateApiKeys,
        invalidateWebhooks,
        invalidateSnippets
    ])
};

export default useChannelSettingHandler;
