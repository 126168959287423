import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import {LiveChatError, toLiveChatError} from "../../error";
import conditionalAnnouncementRepository from "../../repository/ConditionalAnnouncementRepository";

const useDeleteConditionalAnnouncement = (channelId: string, resourceId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, LiveChatError>({
        mutationKey: ["conditional-announcement", "edit", resourceId],
        mutationFn: async () => {
            try {
                return await conditionalAnnouncementRepository.delete(channelId, resourceId)
            } catch (e) {
                throw toLiveChatError(e);
            }
        },
        onSuccess: () => {
            return Promise.all([
                queryClient.invalidateQueries(['conditional-announcements', channelId], {exact: true}),
            ]);
        }
    });
};

export default useDeleteConditionalAnnouncement;
