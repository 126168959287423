import {useQuery} from "@tanstack/react-query";
import {LiveChatFileArchive} from "../model/livechat";
import oauthAxios from "../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../core/variables";
import {LiveChatError, toLiveChatError} from "../error";


const getData = async (channelId: string, liveChatId: string) => {
    try {
        const {data} = await oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/livechats/${liveChatId}/file-archive`);
        const now = Date.now();
        return data.result.map((archive: LiveChatFileArchive, index: number) => ({...archive, _id: `fa${now}${index}`}));
    } catch (e) {
        throw toLiveChatError(e);
    }

}

const useFileArchives = (channelId: string, liveChatId: string) => {
    return useQuery<LiveChatFileArchive[], LiveChatError>(['fileArchives', channelId, liveChatId], async () => getData(channelId, liveChatId), {suspense: false});
};


export default useFileArchives;