import React, {useMemo} from "react";
import {LiveChatHistory} from "../../model/livechat";
import useManagers from "../../query/manager/useManagers";
import {useIntl} from "react-intl";


const ManagerHistoryFragment = React.memo<{history: LiveChatHistory}>(({history}) => {
    const {data: managers} = useManagers(history.channelId);
    const intl = useIntl();

    const managerName = useMemo(() => {
        if (history.action === 'join' || history.action === 'leave' || history.action === 'change_assignee') {
            const filtered = managers?.filter(manager => manager.userId === history.data?.managerUserId) ?? [];
            return filtered.length > 0 ? filtered[0].name : intl.formatMessage({id: "i200078"});
        }
        else {
            const filtered = managers?.filter(manager => manager.userId === history.userId) ?? [];
            return filtered.length > 0 ? filtered[0].name : intl.formatMessage({id: "i200078"});
        }
    }, [history, managers, intl]);

    return (
        <p className="hr-sect">
            <strong>{managerName}</strong>
            {history.action === 'join' && '님이 대화에 참여했습니다.'}
            {history.action === 'leave' && '님이 대화를 떠났습니다.'}
            {history.action === 'holding' && '님이 상담을 보류했습니다.'}
            {history.action === 'resume' && '님이 상담을 재개했습니다.'}
            {history.action === 'change_assignee' && '님으로 담당자가 변경되었습니다.'}
        </p>
    );
});

export default ManagerHistoryFragment;