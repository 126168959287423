import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import {LiveChatError, toLiveChatError} from "../../error";
import customSnippetRepository from "../../repository/CustomSnippetRepository";

const useDeleteCustomSnippet = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, LiveChatError, string>({
        mutationKey: ['snippets', 'delete', channelId],
        mutationFn: async (snippetId: string) => {
            try {
                return await customSnippetRepository.delete(channelId, snippetId);
            } catch (e) {
                throw toLiveChatError(e);
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(['snippets', channelId], {exact: true});
        }
    })
};

export default useDeleteCustomSnippet;
