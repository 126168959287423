import React, {useMemo} from "react";
import {Navigate, useParams} from "react-router-dom";
import useManager from "../../query/manager/useManager";
import useLiveChatContext from "../../hook/useLiveChatContext";
import {LiveChatContext} from "../../model/livechat";

const ChatListView: React.FC = () => {
    // const intl = useIntl();
    const {channelId} = useParams<{channelId: string}>();

    if (!channelId) {
        throw new Error('Required channelId path params')
    }

    const {data: manager} = useManager(channelId);

    if (!manager) {
        throw new Error('Required manager')
    }

    const {liveChatContext} = useLiveChatContext(channelId!)

    // const {data: unassignedLiveChats} = useUnassignedLiveChats(channelId, sortBy);
    // const {data: inProgressLiveChats} = useInProgressLiveChats(channelId, manager.userId, sortBy);
    // const {data: holdingLiveChats} = useHoldingLiveChats(channelId, sortBy);

    const defaultLiveChatId = useMemo(() => {
        const latestLiveChatRaw = window.localStorage.getItem('LLC');
        if (latestLiveChatRaw) {
            try {
                const latestLiveChat = JSON.parse(latestLiveChatRaw);
                return latestLiveChat[channelId];
            } catch (e) {
                return null;
            }
        }

        // if (inProgressLiveChats && inProgressLiveChats.length > 0) {
        //     return inProgressLiveChats[0]._id;
        // }
        //
        // if (unassignedLiveChats && unassignedLiveChats.length > 0) {
        //     return unassignedLiveChats[0]._id;
        // }
        //
        // if (holdingLiveChats && holdingLiveChats.length > 0) {
        //     return holdingLiveChats[0]._id;
        // }

        return null;
    }, [channelId]);

    if (liveChatContext === LiveChatContext.SEARCH) {
        return <Navigate replace to="search" />
    } else if (liveChatContext === LiveChatContext.DASHBOARD) {
        return <Navigate replace to="dashboard" />
    }  else {
        return (
            defaultLiveChatId ? <Navigate replace to={defaultLiveChatId} /> : <BlankChatListFragment />
        );
    }
};


const BlankChatListFragment: React.FC = () => {
    // const intl = useIntl();

    return (
        // <div className="h-100 w-100 d-flex align-items-center justify-content-center bg-light-lighten">
        <div className="h-100 w-100 d-flex align-items-center justify-content-center bg-white">
            {/*<CenteredIcon size={120} className="mdi mdi-file-cabinet" style={{fontSize: '120px', color: '#dddddd'}} />*/}
            {/*<h4>{intl.formatMessage({id: 'i000018'})}</h4>*/}
            {/*<img className="ms-3" src={blankImage} width="400" alt="Generic placeholder image" />*/}
        </div>
    );
};

export default ChatListView;
