import React, {Dispatch, SetStateAction, useCallback, useMemo, useRef, useState} from "react";
import {Dropdown, Form} from "react-bootstrap";
import {FilterSelectionItemStyle} from "./ChatSearchFilterStyle";
import {useClickOutside} from "@react-hookz/web";
import {Manager} from "../../model/manager";
import Avatar from "../Avatar";
import FullAreaSpinner from "../FullAreaSpinner";
import useManagers from "../../query/manager/useManagers";
import ChatSearchFilterToggle from "./ChatSearchFilterToggle";
import ChatSearchFilterDropdownMenu from "./ChatSearchFilterDropdownMenu";
import {FilterResultOperation} from "../../model/chatSearch";
import {useIntl} from "react-intl";

type ChatSearchManagerFilterProps = {
    channelId: string
    selectedManagers: Manager[]
    setSelectedManagers: Dispatch<SetStateAction<Manager[]>>
}

const ChatSearchManagerFilter : React.FC<ChatSearchManagerFilterProps> = (props) => {
    const { channelId, selectedManagers, setSelectedManagers } = props;
    const intl = useIntl();

    const [showPopup, setShowPopup] = useState(false);
    const [filterSearch, setFilterSearch] = useState("");

    const filterToggleRef = useRef<HTMLDivElement>(null);
    const dropDownMenuRef = useRef<HTMLDivElement>(null);

    const { data: managers, isLoading: isLoadingManagers } = useManagers(channelId!, filterSearch);

    const deselectedManagers = useMemo<Manager[]>(() => {
        return managers?.filter(manager => selectedManagers.every(item => item._id !== manager._id)) ?? [];
    }, [managers, selectedManagers]);

    const wrapperSetFilterSearch = useCallback((value: string) => {
        setFilterSearch(value)
    }, [setFilterSearch]);

    const onClickOutside = useCallback((e: Event) => {
        if (filterToggleRef.current && !e.composedPath().includes(filterToggleRef.current)) {
            setShowPopup(false);
        }
    }, []);

    useClickOutside(dropDownMenuRef, (e) => {
        onClickOutside(e);
    });

    const onClickFilterSelect = (manager: Manager, operation: FilterResultOperation) => {
        if (operation === FilterResultOperation.ADD) {
            setSelectedManagers(prev => [...prev, manager]);
        } else if (operation === FilterResultOperation.REMOVE) {
            setSelectedManagers(prev => prev.filter(item => item._id !== manager._id));
        }
    }

    return (
        <Dropdown show={showPopup} style={{marginRight: "1rem"}}>
            <ChatSearchFilterToggle showPopup={showPopup}
                                    updateShowPopup={() => setShowPopup(prev => !prev)}
                                    label={intl.formatMessage({id: "i210016"})}
                                    toggleRef={filterToggleRef}
                                    selectedLength={selectedManagers.length}/>

            <ChatSearchFilterDropdownMenu showPopup={showPopup}
                                          setFilterSearch={wrapperSetFilterSearch}
                                          dropDownMenuRef={dropDownMenuRef}>
                {selectedManagers.map((manager, index) => (
                    <FilterSelectionItemStyle key={`added-${index}`}
                                              onClick={() => onClickFilterSelect(manager, FilterResultOperation.REMOVE)}>
                        <Form.Check id={`filter-select-added-${index}`}
                                    checked={true}
                                    readOnly={true}/>
                        <div className="item-info">
                            <Avatar size={20} name={manager.name} avatarUrl={manager.avatar}/>
                            <div className="name">
                                {manager.name}
                            </div>
                        </div>
                    </FilterSelectionItemStyle>
                ))}

                {selectedManagers.length > 1 &&
                    <div className="cursor-pointer text-primary font-12"
                         style={{padding: "0.25rem 1.5rem 0 1.5rem", width: "fit-content"}}
                         onClick={() => setSelectedManagers([])}>
                        {intl.formatMessage({id: "i210021"})}
                    </div>
                }

                {selectedManagers.length > 0 && <hr className="my-2"/>}

                {isLoadingManagers ?
                    <FullAreaSpinner/> :
                    <>
                        {deselectedManagers.map((manager, index) => (
                            <FilterSelectionItemStyle key={index}
                                                      onClick={() => onClickFilterSelect(manager, FilterResultOperation.ADD)}>
                                <Form.Check id={`filter-select-${index}`}
                                            checked={false}
                                            readOnly={true}/>
                                <div className="item-info">
                                    <Avatar size={20} name={manager.name} avatarUrl={manager.avatar}/>
                                    <div className="name">
                                        {manager.name}
                                    </div>
                                </div>
                            </FilterSelectionItemStyle>
                        ))}
                    </>
                }

                {managers && !managers.length &&
                    <div className="text-muted mx-3 font-14">{intl.formatMessage({id: "i210022"})}</div>
                }
            </ChatSearchFilterDropdownMenu>
        </Dropdown>
    )
};

export default ChatSearchManagerFilter;
