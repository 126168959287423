import React, {useRef, useState} from "react";
import {useIntl} from "react-intl";
import styled from "styled-components";
import {Button, Dropdown, Form} from "react-bootstrap";
import channelRepository from "../../repository/ChannelRepository";
import {Link, useNavigate} from "react-router-dom";
import {APP_TITLE, KEEPER_API_HOST, KEEPER_CLIENT_ID, WEB_HOST} from "../../core/variables";
import Avatar from "../../component/Avatar";
import electronRuntime from "../../core/electronRuntime";
import useProfile from "../../query/useProfile";
import {clearToken} from "../../core/token";
import toast from "react-hot-toast";


const ChannelRegistrationView: React.FC = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const nameRef = useRef<HTMLInputElement>(null);

    const [invalid, setInvalid] = useState({name: false});

    const {data: profile, remove: removeProfile} = useProfile();

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const name = nameRef.current?.value;

        const invalidForm = {name: !name};

        if (Object.values(invalidForm).some(value => value)) {
            setInvalid(invalidForm);
            return;
        }
        else {
            setInvalid({name: false});
        }

        try {
            await channelRepository.create(name!);
            navigate('/', {replace: true});
            // try {
            //     await refreshToken();
            //     navigate('/', {replace: true});
            // } catch (e) {
            //     navigate('/login', {replace: true});
            // }
        } catch (_) {
            toast.error(intl.formatMessage({id: 'i000215'}));
        }
    };

    const onClickLogout = () => {
        localStorage.removeItem('redirect');
        localStorage.removeItem('default_channel');
        localStorage.removeItem('LCM');
        localStorage.removeItem('LLC');

        if (electronRuntime) {
            electronRuntime.invoke('logout').then(async () => {
                removeProfile();
                await clearToken();

                navigate('/', {replace: true});
            });
        }
        else {
            window.location.href = `${KEEPER_API_HOST}/auth/logout?client_id=${KEEPER_CLIENT_ID}&logout_uri=${WEB_HOST}`
        }
    };

    return (
        <div className="app-content-wrapper flex-column">
            <div className="main-view-header">
                <h4 className="m-0">{APP_TITLE}</h4>

                <div className="main-view-header-right-items">
                    <Dropdown>
                        <Dropdown.Toggle id="menu-profile-drop" as={'div'} bsPrefix={'unused'} className="cursor-pointer">
                            <Avatar size={30} name={profile?.nickname || ''} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="end">
                            <Dropdown.Item as={'button'} type={'button'} onClick={onClickLogout} className="notify-item">
                                <i className="mdi mdi-logout mr-1" />
                                <span>Logout</span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <ChannelRegistrationWrapper>
                <div className="channel-header">
                    <h4>{intl.formatMessage({id: 'i000110'})}</h4>
                    <p>{intl.formatMessage({id: 'i000111'})}</p>
                </div>
                <div className="mt-3">
                    <Form onSubmit={onSubmit}>
                        <Form.Group className="mb-1">
                            <Form.Label>{intl.formatMessage({id: 'i000112'})}</Form.Label>
                            <Form.Control ref={nameRef} isInvalid={invalid.name} />
                        </Form.Group>
                        <Button type="submit" className="btn-rounded">{intl.formatMessage({id: 'i000115'})}</Button>
                        <Link to={'/'} replace className="ml-3">{intl.formatMessage({id: 'i000012'})}</Link>
                    </Form>
                </div>
            </ChannelRegistrationWrapper>
        </div>
    );
};


const ChannelRegistrationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
    height: 100%;
    margin: 5rem;
    
    max-width: 420px;
    min-width: 320px;
    
    @media screen and (max-width: 768px) {
        margin: 5rem 0.75rem !important;
    }
`;

export default ChannelRegistrationView;