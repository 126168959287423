import React, {useMemo} from "react";
import {ChatbotMessage} from "../../model/chatbot";
import ButtonMessage from "./ButtonMessage";

const ListMessage: React.FC<ListMessageProps> = ({message}) => {
    const buttons = useMemo(() => {
        if (message.data instanceof Array) {
            return message.data.filter(data => data.label);
        }
        else {
            return [];
        }
    }, [message]);
    return (
        <div className="message-content">
            <div className="list-message">
                {buttons.map((button, index) => {
                    return <ButtonMessage key={index} data={button} disabled={true} />
                })}
            </div>
        </div>
    );
}

type ListMessageProps = {
    message: ChatbotMessage;
};

export default ListMessage;