import React, {useMemo, useRef, useState} from "react";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {useIntl} from "react-intl";
import messageTemplateRepository from "../../repository/MessageTemplateRepository";
import useModal, {MaruModal} from "../../hook/useModal";
import {FileMessage, MessageBlock, MessageTemplate, TextMessage} from "../../model/message";
import {FileGroup} from "../../model/fileGroup";
import {Content, Editor, EditorElement, EditorRef, FileInfo} from "@42maru/content-editor";
import {toJS} from "mobx";
import {useQueryClient} from "@tanstack/react-query";
import {isSupportedFileExtension, isSupportedFileSize} from "../../util/fileUtil";
import toast from "react-hot-toast";
import useUserAttributes from "../../query/useUserAttributes";

const MessageTemplateFormModal: React.FC<{channelId: string}> = ({channelId}) => {
    const modal = useModal();
    const intl = useIntl();

    const [uploading, setUploading] = useState(false);
    const [isValid, setValid] = useState(false);

    const nameRef = useRef<HTMLInputElement>(null);
    const editorRef = useRef<EditorRef>(null);
    const publicCheckboxRef = useRef<HTMLInputElement>(null);

    const queryClient = useQueryClient();

    const {data: userAttributes} = useUserAttributes(channelId)

    const userEditorVariables = useMemo(() => {
        return userAttributes?.map(attribute => ({
            key: attribute.name,
            label: attribute.description
        })) ?? [];
    }, [userAttributes]);

    const messageTemplate: MessageTemplate | null = useMemo(() => {
        return modal.data[messageTemplateFormModalId];
    }, [modal.data]);

    const defaultValue: EditorElement[] = useMemo(() => {
        const textBlocks = messageTemplate?.blocks.filter(block => block.type === 'text') || [];
        if (textBlocks.length > 0) {
            if (textBlocks[0].meta) {
                return toJS(textBlocks[0].meta) as EditorElement[];
            }
            else {
                const textMessage = textBlocks[0].value as TextMessage;
                return [{type: 'paragraph', children: [{text: textMessage.text}]}]
            }
        }

        return [];
    }, [messageTemplate]);

    const defaultFiles = useMemo(() => {
        const fileBlocks = messageTemplate?.blocks.filter(block => block.type === 'file') || [];
        return fileBlocks.map(block => {
            const fileMessage = block.value as FileMessage;
            return {
                filename: fileMessage.filename,
                url: fileMessage.url,
                size: fileMessage.size || 0,
                contentType: fileMessage.contentType || ''
            };
        })
    }, [messageTemplate]);

    const onClickFile = async (file: File) => {
        if (!isSupportedFileSize(file)) {
            toast.error(intl.formatMessage({id: "i200076"}));
            return;
        }

        if (!isSupportedFileExtension(file)) {
            toast.error(intl.formatMessage({id: "i200077"}));
            return;
        }

        setUploading(true);
        const {data} = await messageTemplateRepository.uploadFile(channelId, file);
        setUploading(false);
        const fileGroup: FileGroup = data.result;
        const fileInfo = fileGroup.files[0];
        return {
            filename: fileInfo.originalFilename,
            url: fileInfo.url,
            size: fileInfo.size,
            contentType: fileInfo.contentType
        }
    };

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const name = nameRef.current?.value;
        const contents = editorRef.current?.getContents() || [];
        const files = editorRef.current?.files || [];
        const checkedPublic = publicCheckboxRef.current?.checked || false;

        const text = contents.map(content => content.value.text).join('\n');
        const html = contents.map(content => content.value.html).join('\n');

        const meta = contents.map(content => content.meta);
        const blocks: MessageBlock[] = [];
        if (text) {
            blocks.push({
                type: 'text',
                value: {
                    text: text,
                    html: html
                },
                meta: meta
            });
        }

        files.forEach(file => {
            blocks.push({
                type: 'file',
                value: {
                    filename: file.filename,
                    url: file.url,
                    size: file.size,
                    contentType: file.contentType
                }
            });
        });

        if (!name || blocks.length < 1) {
            return;
        }


        if (messageTemplate && messageTemplate._id) {
            messageTemplateRepository.update(channelId, messageTemplate._id, name, blocks, checkedPublic).then(async () => {
                await queryClient.invalidateQueries(['messageTemplates', channelId], {exact: true});
            }).catch(error => {
                console.error(error);
            }).finally(() => {
                close();
            });
        }
        else {
            messageTemplateRepository.create(channelId, name, blocks, checkedPublic).then(async () => {
                await queryClient.invalidateQueries(['messageTemplates', channelId], {exact: true});
            }).catch(error => {
                console.error(error);
            }).finally(() => {
                close();
            });
        }
    };

    const onChangeName = (_: React.ChangeEvent<HTMLInputElement>) => {
        const name = nameRef.current?.value;
        const contents = editorRef.current?.getContents() ?? [];
        const files = editorRef.current?.files ?? [];
        const validContents = (contents.length > 0 && contents.some(content => !!content.value.text)) || files.length > 0;
        setValid(!!name && validContents);
    }

    const onChangeEditor = (contents: Content[], files?: FileInfo[]) => {
        const name = nameRef.current?.value;
        const validContents = (contents.length > 0 && contents.some(content => !!content.value.text)) || (files ?? []).length > 0;
        setValid(!!name && validContents);
    }

    const close = () => {
        if (nameRef.current) {
            nameRef.current.value = '';
        }

        editorRef.current?.clear();

        setUploading(false);

        modal.close(messageTemplateFormModalId);
    };

    return (
        <MaruModal id={messageTemplateFormModalId} title={intl.formatMessage({id: 'i000028'})} centered enforceFocus={false}>
            {/*<Modal.Header closeButton>*/}
            {/*    <Modal.Title as={'h4'}>*/}
            {/*        {intl.formatMessage({id: 'i000028'})}*/}
            {/*    </Modal.Title>*/}
            {/*</Modal.Header>*/}
            <Modal.Body>
                <Form id={'message-template-form'} onSubmit={onSubmit}>
                    <Form.Group className="mb-1">
                        <Form.Label>{intl.formatMessage({id: 'i000020'})}</Form.Label>
                        <Form.Control type={'text'} size="sm" defaultValue={messageTemplate?.name ?? ''} onChange={onChangeName} ref={nameRef} disabled={uploading} />
                        <Form.Control.Feedback type={'invalid'}>{intl.formatMessage({id: 'i000024'})}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-1">
                        <Form.Label>{intl.formatMessage({id: 'i000021'})}</Form.Label>
                        {/*<Form.Control as={'textarea'} rows={6} size="sm" isInvalid={invalidForm.contents} defaultValue={textValue} ref={contentsRef} disabled={uploading} />*/}
                        {/*<Form.Control.Feedback type={'invalid'}>{intl.formatMessage({id: 'i000024'})}</Form.Control.Feedback>*/}
                        <Editor id="message-template-editor"
                                defaultValue={defaultValue}
                                defaultFiles={defaultFiles}
                                placeholder={intl.formatMessage({id: 'i000291'})}
                                onChange={onChangeEditor}
                                height="170px"
                                onClickFile={onClickFile}
                                ref={editorRef}
                                enterFunctionKey={"shift"}
                                variables={userEditorVariables}
                        />
                    </Form.Group>
                    <Form.Group className="mb-1">
                        <Form.Check id="template-message-public-check"
                                    type="checkbox"
                                    label={intl.formatMessage({id: 'i000182'})}
                                    defaultChecked={messageTemplate?.public || false}
                                    ref={publicCheckboxRef} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" type="button" className="btn-rounded" onClick={close} disabled={uploading}>
                    {intl.formatMessage({id: 'i000022'})}
                </Button>
                <Button type="submit" className="btn-rounded" form={'message-template-form'} disabled={uploading || !isValid}>
                    {uploading && (<Spinner size="sm" animation="border" className="mr-1" />)}
                    {messageTemplate?._id ? intl.formatMessage({id: 'i000029'}) : intl.formatMessage({id: 'i000023'})}
                </Button>
            </Modal.Footer>
        </MaruModal>
    );
};

export const messageTemplateFormModalId = 'message-template-form-modal';

export default MessageTemplateFormModal;
