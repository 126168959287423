import React, {useMemo} from "react";
import styled from "styled-components";
import {Message, MessageSearchResult, TextMessage as MessageBlockTextMessage} from "../../model/message";
import Avatar from "../Avatar";
import moment from "moment";
import TextMessage from "../message/TextMessage";
import {useNavigate} from "react-router-dom";
import useLiveChatContext from "../../hook/useLiveChatContext";
import {LiveChatContext} from "../../model/livechat";
import {useIntl} from "react-intl";

type ChatSearchResultItemProps = {
    message: MessageSearchResult
    setChatSearchTracking: (trackingMessage: Message) => void,
    searchText: string
}

const ChatSearchResultItem: React.FC<ChatSearchResultItemProps> = (props) => {
    const { message, setChatSearchTracking, searchText } = props;
    const intl = useIntl();
    const navigate = useNavigate();

    const {update: updateLivechatContext, chatMenuSegment} = useLiveChatContext(message.channelId!)

    const senderName = useMemo(() => {
        return message.sender.name ?? message.sender.alias;
    }, [message])

    const userName = useMemo(() => {
        const chatUser = message.livechat.user;
        return chatUser.name ?? chatUser.alias;
    }, [message.livechat]);

    const assigneeName = useMemo(() => {
        const assignee = message.livechat.managers.find(manager => manager.userId === message.livechat.assigneeId);
        return assignee?.name ?? intl.formatMessage({id: "i210029"})
    }, [message.livechat, intl]);

    const onClickResultItem = async () => {
        if (message) {
            setChatSearchTracking(message)
            navigate(`/channels/${message.channelId}/livechats/${message.liveChatId}`);
            updateLivechatContext(LiveChatContext.ALL_INBOX, chatMenuSegment)
        }
    }

    if (senderName) {
        return (
            <ChatSearchResultItemStyle onClick={onClickResultItem}>
                <div className="live-chat-info">
                    <small className="mr-2">{userName} ~ {assigneeName}</small>
                    <small>{moment.utc(message.createdAt).local().format(intl.formatMessage({id: "i210023"}))}</small>
                </div>
                <div className="message-preview">
                    <div className="message-avatar">
                        <Avatar size={35} name={senderName}/>
                    </div>

                    <div className="message-wrapper">
                        <p className="message-header">
                            {senderName}
                            <small className="text-muted ml-1">
                                {moment.utc(message.createdAt).local().format('LT')}
                            </small>
                        </p>

                        <div className="message-container">
                            {message.blocks.map((messageBlock, index) => (
                                <TextMessage key={index}
                                             text={(messageBlock.value as MessageBlockTextMessage).html}
                                             trackedText={searchText}/>
                            ))}
                        </div>
                    </div>
                </div>
            </ChatSearchResultItemStyle>
        )
    } else {
        return null;
    }
};

const ChatSearchResultItemStyle = styled.div`
  width: 100%;
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  cursor: pointer;

  border: 0.5px solid #9DA3AD;
  border-radius: 8px;
  background-color: white;
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: #efefef;
    transition: background-color 0.1s ease-in-out;
  }

  .live-chat-info {
    display: flex;
    margin-bottom: 0.25rem;
  }

  .message-preview {
    display: flex;
    align-items: center;

    .message-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      overflow: hidden;

      .message-header {
        margin: 0 0 0 5px;
        font-size: 0.9rem;
        line-height: 1.1rem;
        font-weight: bold;

        small {
          color: var(--color-muted)
        }
      }

      .message-container {
        width: 100%;
        display: flex;
        margin-left: 5px;
        align-items: center;

        .message-content {
          display: inherit;
          border-radius: 2px;
          flex-grow: 1;
        }
      }
    }
  }

  span.tracked-substring {
    background-color: #F7E9BA;
  }
`;

export default ChatSearchResultItem;
