import liveChatRepository from "../../repository/LiveChatRepository";
import {useQuery} from "@tanstack/react-query";
import {LiveChat} from "../../model/livechat";
import {LiveChatError, toLiveChatError} from "../../error";


const getData = async (channelId: string, liveChatId: string) => {
    try {
        const {data} = await liveChatRepository.read(channelId, liveChatId);
        return data.result;
    } catch (e) {
        throw toLiveChatError(e);
    }

}


const useLiveChat = (channelId: string, liveChatId: string) => {
    return useQuery<LiveChat, LiveChatError>(['liveChat', channelId, liveChatId], async () => getData(channelId, liveChatId), {suspense: false});
};


export default useLiveChat;