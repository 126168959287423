export const ItemDataType = {
    TEXT: "text",
    SMALL_TEXT: "small-text",
    PAYMENT_METHOD_TEXT: "payment-method-text",
    ICON: "icon",
    LINK: "link",
    DIVIDER: "divider",
    IMAGE: "image",
    LIST: "list",
    LIST_ROW: "list-row",
    CLIPBOARD: "clipboard"
}

interface ItemDataBase {
    style?: object
    className?: string
}

export interface TextItemData extends ItemDataBase {
    text: string
    date?: boolean
}

export interface SmallTextItemData extends TextItemData {
}

// 임시로 생성한 Type Item
export interface PaymentMethodTextItemData extends TextItemData {
}

export interface IconItemData extends ItemDataBase {
    clickUrl?: string
}

export interface LinkItemData extends ItemDataBase {
    text: string
    url: string
}

export interface DividerItemData extends ItemDataBase {
}

export interface ImageItemData extends ItemDataBase {
    url: string
    clickUrl?: string
}

export interface ClipboardData extends ItemDataBase {
    text: string
}

export interface JSONTemplateItem {
    type: string,
    data: TextItemData | LinkItemData | DividerItemData | ImageItemData | JSONTemplateItem[]
    style?: object // for list item
}

export interface CodeSnippetObject {
    snippet: string,
    data: JSONTemplateItem[]
    style?: object
    className?: string
}

export const CodeSnippetErrorObject: CodeSnippetObject = {
    snippet: "error",
    data: [{ type: "small-text", data: { text: "Code Snippet 실행 실패" } }],
    className: "text-muted"
}

export const ParsingErrorObject: CodeSnippetObject = {
    snippet: "parsing-error",
    data: [{ type: "small-text", data: { text: "Code Snippet 결과 parsing 실패" } }],
    className: "text-muted"
}

export const EmptyObject: CodeSnippetObject = {
    snippet: "empty",
    data: [{ type: "text", data: { text: "고객이 쇼핑몰에서 주문한 내역을 확인할 수 있습니다." } }],
    className: "text-muted font-13 h-100 d-flex align-items-center justify-content-center"
}

export const NotFoundMemberObject: CodeSnippetObject = {
    snippet: "invalid-member-error",
    data: [{ type: "small-text", data: { text: "주문 정보를 조회할 수 없습니다." } }],
    className: "text-muted"
}

export const Cafe24PaymentMethod: Map<string, string> = new Map<string, string>([
    ["cash", "무통장"],
    ["card", "신용카드"],
    ["tcash", "계좌이체"],
    ["icash", "가상계좌"],
    ["cell", "휴대폰"],
    ["deferpay", "후불"],
    ["cvs", "편의점"],
    ["point", "선불금"],
    ["mileage", "적립금"],
    ["deposit", "예치금"],
    ["etc", "기타"]
])