import React, { useMemo } from "react";
import {useIntl} from "react-intl";
import {useParams} from "react-router-dom";
import SettingViewWrapper from "../../component/setting/SettingViewWrapper";
import useChannel from "../../query/channel/useChannel";
import {WorkingTimeCalendar} from "../../component/WorkingTimeCalendar/WorkingTimeCaldendar"

const WorkingHolidayConfigView: React.FC = () => {

    const intl = useIntl();

    const {channelId} = useParams<{channelId: string}>();

    if (!channelId) {
        throw new Error('Required channelId path params')
    }

    const {data: channel} = useChannel(channelId);
    const config = useMemo(() => {
        return channel?.config;
    }, [channel])

    // const {mutateAsync: updateWorkingTimes} = useUpdateWorkingTimes(channelId);

    // const enabledRef = useRef<HTMLInputElement>(null);

    // const toggleActive = async (e: React.FormEvent) => {
    //     e.preventDefault()
    //     if (!config || !enabledRef.current) {
    //         return;
    //     }
    //
    //     const form: updateWorkingTimesProps = {
    //         workingHolidayConfig: {active: enabledRef.current.checked, holidays: config.workingHoliday.holidays}
    //     };
    //
    //     await toast.promise(
    //         updateWorkingTimes(form),
    //         {
    //             loading: intl.formatMessage(({id: 'i200003'})),
    //             success: () => {
    //                 setActiveHoliday(!config.workingHoliday.active)
    //                 return intl.formatMessage(({id: 'i100080'}));
    //             },
    //             error: () => {
    //                 return intl.formatMessage(({id: 'e000001'}))
    //             }
    //         }
    //     )
    // }

    if (!config) return null

    return (
        <SettingViewWrapper>
            <div className="setting-title">
                <h3>{intl.formatMessage({id: 'i100066'})}</h3>
                <p className='overflow-auto text-break mb-0' style={{whiteSpace: 'break-spaces'}}>
                    {intl.formatMessage({id: 'i100068'})}
                </p>
            </div>

            {/*<Form.Group className="mb-2 d-flex align-items-center">*/}
            {/*    <Form.Label className='mb-0 mr-2 text-dark'>*/}
            {/*        {intl.formatMessage({id: 'i100072'})}*/}
            {/*    </Form.Label>*/}
            {/*    <Form.Switch className="m-0"*/}
            {/*                 disabled={isLoading}*/}
            {/*                 checked={config.workingHoliday.active}*/}
            {/*                 ref={enabledRef}*/}
            {/*                 onChange={toggleActive}*/}
            {/*    />*/}
            {/*</Form.Group>*/}

            <WorkingTimeCalendar channelId={channelId}/>
        </SettingViewWrapper>
    );
};


export default WorkingHolidayConfigView;
