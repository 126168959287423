import React, {useEffect, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {Button, Card, Col, Form, ListGroup, Row} from "react-bootstrap";
import classNames from "classnames";
import {useParams} from "react-router-dom";
import moment from "moment-timezone";
import SettingViewWrapper from "../../component/setting/SettingViewWrapper";
import useChannel from "../../query/channel/useChannel";
import {CenteredIcon} from "../../component/UnstyledBootstrap";
import toast from "react-hot-toast";
import useUpdateWorkingTimes, {updateWorkingTimesProps} from "../../query/channel/useUpdateWorkingTimes";
import electronRuntime from "../../core/electronRuntime";
import {CHATBOT_WEB_HOST} from "../../core/variables";
import useChannelIntegration from "../../query/useChannelIntegration";


const WorkingTimeConfigView: React.FC = () => {
    const intl = useIntl();

    const {channelId} = useParams<{channelId: string}>();

    if (!channelId) {
        throw new Error('Required channelId path params')
    }

    const {data: channel} = useChannel(channelId);
    const {data: channelIntegration, isLoading: channelIntegrationLoading } = useChannelIntegration(channelId);
    const {mutateAsync: updateWorkingTimes} = useUpdateWorkingTimes(channelId);

    const [workingTimeMode, setWorkingTimeMode] = useState(channel?.config.workingTimeMode || 'manual');
    const [workingTimes, setWorkingTimes] = useState(channel?.config.workingTimes || []);
    const [saving, setSaving] = useState(false);

    const workingTimeModeRef = useRef<HTMLSelectElement>(null);
    const workingOnRef = useRef<HTMLInputElement>(null);
    const workingOffRef = useRef<HTMLInputElement>(null);
    const monRef = useRef<HTMLInputElement>(null);
    const tueRef = useRef<HTMLInputElement>(null);
    const wedRef = useRef<HTMLInputElement>(null);
    const thuRef = useRef<HTMLInputElement>(null);
    const friRef = useRef<HTMLInputElement>(null);
    const satRef = useRef<HTMLInputElement>(null);
    const sunRef = useRef<HTMLInputElement>(null);

    const timezoneRef = useRef<HTMLSelectElement>(null);

    const config = useMemo(() => {
        return channel?.config;
    }, [channel])

    // for automatic update
    useEffect(() => {
        if (config) {
            if (workingOnRef.current) {
                workingOnRef.current.checked = config.working;
            }

            if (workingOffRef.current) {
                workingOffRef.current.checked = !config.working;
            }

            setWorkingTimeMode(config.workingTimeMode);
            if (workingTimeModeRef.current) {
                workingTimeModeRef.current.value = config.workingTimeMode;
            }

            setWorkingTimes(config.workingTimes);
        }
    }, [config])

    const daysOfWeek = useMemo(() => {
        return [
            intl.formatMessage({id: 'i000044'}),
            intl.formatMessage({id: 'i000045'}),
            intl.formatMessage({id: 'i000046'}),
            intl.formatMessage({id: 'i000047'}),
            intl.formatMessage({id: 'i000048'}),
            intl.formatMessage({id: 'i000049'}),
            intl.formatMessage({id: 'i000043'}),
        ];
    }, [intl]);

    const onChangeWorkingTimeMode = (e: React.ChangeEvent) => {
        e.preventDefault();

        if (workingTimeModeRef.current?.value) {
            setWorkingTimeMode(workingTimeModeRef.current.value);
        }

    };

    const onAddWorkingTime = (openingTime: string, closingTime: string) => {
        const daysOfWeek = [];
        if (sunRef.current?.checked) daysOfWeek.push(7);
        if (monRef.current?.checked) daysOfWeek.push(1);
        if (tueRef.current?.checked) daysOfWeek.push(2);
        if (wedRef.current?.checked) daysOfWeek.push(3);
        if (thuRef.current?.checked) daysOfWeek.push(4);
        if (friRef.current?.checked) daysOfWeek.push(5);
        if (satRef.current?.checked) daysOfWeek.push(6);

        if (daysOfWeek.length < 1 || !openingTime || !closingTime) {
            return;
        }

        setWorkingTimes([...workingTimes!, {
            daysOfWeek: daysOfWeek,
            openingTime: openingTime,
            closingTime: closingTime
        }]);

        monRef.current!.checked = false;
        tueRef.current!.checked = false;
        wedRef.current!.checked = false;
        thuRef.current!.checked = false;
        friRef.current!.checked = false;
        satRef.current!.checked = false;
        sunRef.current!.checked = false;
    };

    const onClickDeleteWorkingTime = (e: React.MouseEvent, index: number) => {
        e.preventDefault();
        setWorkingTimes(workingTimes.filter((_, i) => i !== index));
    }

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!channel || !config) {
            return;
        }

        // 요일별 운영시간일 경우 등록한 시간이 있는지 체크
        if (workingTimeModeRef.current?.value === 'time' && workingTimes.length < 1) {
            toast.error(intl.formatMessage({id: 'i000290'}));
            return;
        }
        setSaving(true);

        const form: updateWorkingTimesProps = {
            workingTimeMode: workingTimeModeRef.current?.value || config.workingTimeMode,
            working: workingOnRef.current?.checked || false,
            workingTimes: workingTimes,
            timezone: timezoneRef.current?.value || config.timezone
        };

        await toast.promise(
            updateWorkingTimes(form),
            {
                loading: intl.formatMessage(({id: 'i200003'})),
                success: () => {
                    setSaving(false);
                    return intl.formatMessage(({id: 'i000103'}));
                },
                error: () => {
                    setSaving(false);
                    return intl.formatMessage(({id: 'e000001'}))
                }
            }
        )
    };

    const navigateToTimezoneConsole = async () => {
        if (!channelIntegration) return;

        const consoleUrl = `${CHATBOT_WEB_HOST}/channels/${channelIntegration.swingChatChannelId}/settings/basic-info`
        if (electronRuntime) {
            electronRuntime.send('open-browser', consoleUrl);
        } else {
            window.open(consoleUrl, '_blank');
        }
    }

    if (channelIntegrationLoading) {
        return null
    }

    return (
        <SettingViewWrapper>
            <div className="setting-title">
                <h3>{intl.formatMessage({id: 'i000041'})}</h3>
                <p>
                    {intl.formatMessage({id: 'i000042'})}
                </p>
            </div>

            <Form id={'working_time_form'}  style={{width: 650}} onSubmit={onSubmit}>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column xs={'auto'}>{intl.formatMessage({id: 'i000050'})}</Form.Label>
                    <Col>
                        <Form.Control as={'select'}
                                      defaultValue={config?.workingTimeMode}
                                      onChange={onChangeWorkingTimeMode}
                                      ref={workingTimeModeRef}>
                            <option value={'manual'}>{intl.formatMessage({id: 'i000051'})}</option>
                            <option value={'time'}>{intl.formatMessage({id: 'i000052'})}</option>
                        </Form.Control>
                    </Col>
                </Form.Group>

                <Card border={'secondary'} className="border shadow-none">
                    <Card.Body className="py-2">
                        <Form.Group as={Row} className={classNames('mb-0', {'d-none': workingTimeMode !== 'manual'})}>
                            <Form.Label column xs={'2'}>{intl.formatMessage({id: 'i000053'})}</Form.Label>
                            <Col className="d-flex align-items-center">
                                <Form.Check id={'working_on'}
                                            name={'working'}
                                            type={'radio'}
                                            label={intl.formatMessage({id: 'i000054'})}
                                            defaultChecked={config?.working}
                                            ref={workingOnRef}
                                            inline />
                                <Form.Check id={'working_off'}
                                            name={'working'}
                                            type={'radio'}
                                            label={intl.formatMessage({id: 'i000055'})}
                                            defaultChecked={!config?.working}
                                            ref={workingOffRef}
                                            inline />
                            </Col>
                        </Form.Group>

                        <div className={classNames('mb-2', { 'd-none': workingTimeMode !== 'time'})}>

                            <Form.Group as={Row} className="mb-1">
                                <Form.Label column xs={'2'}>{intl.formatMessage({id: 'i000056'})}</Form.Label>
                                <Col className="d-flex align-items-center">
                                    <Form.Check inline id={'day_of_week_sun'} type={'checkbox'} label={intl.formatMessage({id: 'i000043'})} ref={sunRef} />
                                    <Form.Check inline id={'day_of_week_mon'} type={'checkbox'} label={intl.formatMessage({id: 'i000044'})} ref={monRef} />
                                    <Form.Check inline id={'day_of_week_tue'} type={'checkbox'} label={intl.formatMessage({id: 'i000045'})} ref={tueRef} />
                                    <Form.Check inline id={'day_of_week_wed'} type={'checkbox'} label={intl.formatMessage({id: 'i000046'})} ref={wedRef} />
                                    <Form.Check inline id={'day_of_week_thu'} type={'checkbox'} label={intl.formatMessage({id: 'i000047'})} ref={thuRef} />
                                    <Form.Check inline id={'day_of_week_fri'} type={'checkbox'} label={intl.formatMessage({id: 'i000048'})} ref={friRef} />
                                    <Form.Check inline id={'day_of_week_sat'} type={'checkbox'} label={intl.formatMessage({id: 'i000049'})} ref={satRef} />
                                </Col>
                            </Form.Group>
                            <TimeSelectFragment onAddWorkingTime={onAddWorkingTime} />
                            {/*<Form.Group as={Row}>*/}
                            {/*    <Form.Label column xs={'2'}>{intl.formatMessage({id: 'i000057'})}</Form.Label>*/}
                            {/*    <Col className="d-flex align-items-center">*/}
                            {/*        <Form.Control as={'select'} defaultValue={'09:00'} ref={openingTimeRef}>*/}
                            {/*            {times.map(time => <option key={time} value={time}>{time}</option>)}*/}
                            {/*        </Form.Control>*/}
                            {/*        <span className="mx-1">-</span>*/}
                            {/*        <Form.Control as={'select'} defaultValue={'18:00'} ref={closingTimeRef}>*/}
                            {/*            {times.map(time => <option key={time} value={time}>{time}</option>)}*/}
                            {/*        </Form.Control>*/}
                            {/*    </Col>*/}
                            {/*    <Col xs={'auto'} className="d-flex align-items-center">*/}
                            {/*        <Button variant={'outline-secondary'}*/}
                            {/*                className="ml-1"*/}
                            {/*                onClick={onClickAddWorkingTime}>*/}
                            {/*            {intl.formatMessage({id: 'i000058'})}*/}
                            {/*        </Button>*/}
                            {/*    </Col>*/}
                            {/*</Form.Group>*/}
                            <ListGroup>
                                {workingTimes?.map((workingTime, index) => (
                                    <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
                                        {workingTime.daysOfWeek.map(day => daysOfWeek[day-1]).join(', ')}
                                        <div className="d-flex align-items-center">
                                            <span>{`${workingTime.openingTime} - ${workingTime.closingTime}`}</span>
                                            <CenteredIcon size={28} className="mdi mdi-trash-can-outline font-20 ms-2" onClick={e => onClickDeleteWorkingTime(e, index)} role="button" />
                                        </div>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>

                        </div>

                        <Form.Group as={Row} className={classNames('mb-3', {'d-none': workingTimeMode !== 'time'})}>
                            <Form.Label column xs={'2'}>{intl.formatMessage({id: 'i000059'})}</Form.Label>
                            <Col className='d-flex align-items-center'>
                                {channelIntegration ?
                                    <Card.Link className='text-decoration-underline'
                                               href="#"
                                               onClick={navigateToTimezoneConsole}
                                    >
                                        {config?.timezone}
                                    </Card.Link>
                                    :
                                    <Form.Select defaultValue={config?.timezone} ref={timezoneRef}>
                                        {moment.tz.names().map(timezone => <option key={timezone} value={timezone}>{timezone}</option>)}
                                    </Form.Select>
                                }
                            </Col>
                        </Form.Group>

                    </Card.Body>
                </Card>

                <Button type="submit" className="btn-rounded" disabled={saving}>
                    {intl.formatMessage({id: 'i000040'})}
                </Button>
            </Form>
        </SettingViewWrapper>
    );
};


const TimeSelectFragment: React.FC<{onAddWorkingTime: (openingTime: string, closingTime: string) => void}> = ({onAddWorkingTime}) => {
    const intl = useIntl();

    const [openingTime, setOpeningTime] = useState('09:00');
    const [closingTime, setClosingTime] = useState('18:00');

    const openingTimeRef = useRef<HTMLSelectElement>(null);
    const closingTimeRef = useRef<HTMLSelectElement>(null);

    const times = useMemo(() => {
        const result = new Array(48).fill('');
        return result.map((_, index) => {
            const hour = `00${Math.floor(index / 2)}`.slice(-2);
            const minutes = `00${index % 2 * 30}`.slice(-2);
            return `${hour}:${minutes}`;
        });
    }, []);

    const onClickAddWorkingTime = (e: React.MouseEvent) => {
        e.preventDefault();

        if (openingTimeRef.current && closingTimeRef.current) {
            onAddWorkingTime(openingTimeRef.current.value, closingTimeRef.current.value);

            // openingTimeRef.current.value = '09:00';
            // closingTimeRef.current.value = '18:00';
        }
    };

    const onChangeOpeningTime = (_: React.ChangeEvent) => {
        if (openingTimeRef.current) {
            setOpeningTime(openingTimeRef.current.value);
        }
    }

    const onChangeClosingTime = (_: React.ChangeEvent) => {
        if (closingTimeRef.current) {
            setClosingTime(closingTimeRef.current.value);
        }
    }

    return (
        <Form.Group as={Row} className="mb-1">
            <Form.Label column xs={'2'}>{intl.formatMessage({id: 'i000057'})}</Form.Label>
            <Col className="d-flex align-items-center">
                <Form.Control as={'select'} defaultValue={'09:00'} ref={openingTimeRef} onChange={onChangeOpeningTime}>
                    {times.map(time => <option key={time} value={time} disabled={time >= closingTime}>{time}</option>)}
                </Form.Control>
                <span className="mx-1">-</span>
                <Form.Control as={'select'} defaultValue={'18:00'} ref={closingTimeRef} onChange={onChangeClosingTime}>
                    {times.map(time => <option key={time} value={time} disabled={time <= openingTime}>{time}</option>)}
                </Form.Control>
            </Col>
            <Col xs={'auto'} className="d-flex align-items-center">
                <Button variant="outline-secondary"
                        className="ml-1 btn-rounded"
                        onClick={onClickAddWorkingTime}>
                    {intl.formatMessage({id: 'i000058'})}
                </Button>
            </Col>
        </Form.Group>
    );
};




export default WorkingTimeConfigView;
