import {useQuery} from "@tanstack/react-query";
import categoryRepository from "../../repository/CategoryRepository";
import {Category} from "../../model/category";
import {LiveChatError, toLiveChatError} from "../../error";
import {ReactQueryOptions} from "../index";


const getData = async (channelId: string) => {
    try {
        const {data} = await categoryRepository.find(channelId);
        return data.result;
    } catch (e) {
        throw toLiveChatError(e);
    }

}


const useCategories = (channelId: string, options?: ReactQueryOptions) => {
    return useQuery<Category[], LiveChatError>(['categories', channelId], async () => getData(channelId), {...options});
};


export default useCategories;