import {action, makeObservable, observable} from "mobx";
import {Webhook} from "../model/webhook";
import {StoreState} from "../model";
import webhookRepository from "../repository/WebhookRepository";


class WebhookStore {
    @observable
    data?: Webhook;

    @observable
    state: StoreState = 'none';

    constructor() {
        makeObservable(this);
    }

    @action
    async load(channelId: string, webhookId: string) {
        this.state = 'pending';
        try {
            const {data} = await webhookRepository.read(channelId, webhookId);
            this.data = data.result;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    clear() {
        this.data = undefined;
        this.state = 'none';
    }
}

export default WebhookStore;