import {atom} from "recoil";
import {LiveChatContext, LiveChatStatus} from "../../model/livechat";

type ChatMenuSegmentType = {[key: LiveChatContext|string]: LiveChatStatus|undefined};

const chatMenuSegmentState = atom<ChatMenuSegmentType>({
    key: 'chatMenuSegment',
    default: {}
});

export default chatMenuSegmentState;