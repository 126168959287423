import {atom} from "recoil";


type UpdateEvent = 'update-available-manual'|'update-not-available-manual'|'update-downloaded'|string|undefined;

const appUpdateEventState = atom<UpdateEvent>({
    key: 'appUpdateEvent',
    default: undefined
});

export default appUpdateEventState;