import React from "react";
import classNames from "classnames";
import useTooltip from "../hook/useTooltip";
import {Config, PopperOptions} from "react-popper-tooltip";


const TooltipIcon: React.FC<{tooltip: string, className?: string, config?: Config, options?: PopperOptions}> = ({tooltip: text, className, config, options}) => {
    const tooltip = useTooltip(text, config, options);

    return (
        <>
            <i className={classNames('mdi mdi-information-outline font-16', {[className || '']: className})} ref={tooltip.setTriggerRef} />
            {tooltip.visible && tooltip.element}
        </>
    );
};


export default TooltipIcon;