import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import {LiveChatError, toLiveChatError} from "../../error";
import oauthAxios from "../../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../../core/variables";


const useUpdateLiveChatPriority = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, LiveChatError, {priority: number, color: string, alias?: string}>(['channels', 'liveChatPriority', 'update', channelId], async (params) => {
        try {
            return await oauthAxios.put(`${LIVE_CHAT_API_HOST}/v2/channels/${channelId}/config/priorities/${params.priority}`, {
                color: params.color,
                alias: params.alias
            });
        } catch (e) {
            throw toLiveChatError(e);
        }
    }, {
        onSuccess: () => {
            return queryClient.invalidateQueries(['channels', channelId], {exact: true})
        }
    });
}

export default useUpdateLiveChatPriority;
