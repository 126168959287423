import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import {LIVE_CHAT_API_HOST} from "../../core/variables";
import {LiveChatError, toLiveChatError} from "../../error";
import oauthAxios from "../../core/oauthAxios";


const useCreateInvitation = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, LiveChatError, {username: string, roleId?: string, managementUnitRoleId?: string}>(['invitations', 'create', channelId], async (params) => {
        if (!(params.roleId || params.managementUnitRoleId)) {
            throw new LiveChatError('400');
        }

        try {
            return await oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/invitations`, params);
        } catch (e) {
            throw toLiveChatError(e);
        }
    }, {
        onSuccess: () => {
            return queryClient.invalidateQueries(['invitations', channelId], {exact: true})
        }
    });
}

export default useCreateInvitation;
