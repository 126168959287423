import React, {useEffect} from "react";
import {useNotification} from "../hook/useNotification";
import {useIntl} from "react-intl";
import electronRuntime from "../core/electronRuntime";
import {useSetRecoilState} from "recoil";
import appUpdateEventState from "../recoil/appUpdateEvent";
import toast from "react-hot-toast";
// import {Button, ProgressBar, Spinner} from "react-bootstrap";


const AutoUpdateCheckView: React.FC = () => {
    const notification = useNotification();
    const intl = useIntl();

    const setAppUpdateEvent = useSetRecoilState(appUpdateEventState);

    useEffect(() => {
        if (!electronRuntime) {
            return
        }

        electronRuntime.invoke('checking-for-update').then();

        const unsubscribe = electronRuntime.subscribe('auto-update', (event: any, args: any) => {
            setAppUpdateEvent(args.event);
            // if (args.event === 'update-downloaded') {
            //     notification.on({
            //         text: intl.formatMessage({id: 'i000072'}),
            //         variant: 'info',
            //         buttonText: intl.formatMessage({id: 'i000073'}),
            //         onClick: () => electronRuntime.send('update', 'ping')
            //     });
            // }
            // else if (args.event === 'update-available-manual') {
            //     notification.on({
            //         text: intl.formatMessage({id: 'i000355'}),
            //         variant: 'info',
            //         hiddenCloseButton: true
            //     });
            // }
            if (args.event === 'update-not-available-manual') {
                toast(intl.formatMessage({id: 'i000354'}));
            }
        });

        return () => {
            unsubscribe();
        };
    }, [notification, intl, setAppUpdateEvent]);

    return null;

    // const onClickUpdateBtn = (e: React.MouseEvent) => {
    //     e.preventDefault();
    //
    //     if (window.ipcRenderer) {
    //         window.ipcRenderer.send('update-download', 'ping');
    //     }
    // };

    // if (window.ipcRenderer) {
    //     if (updateInfo?.event === 'checking-for-update') {
    //         return (
    //             <div className="d-flex justify-content-center align-items-center flex-grow-1">
    //                 <Spinner animation="border" />
    //             </div>
    //         );
    //     }
    //     else if (updateInfo?.event === 'update-available') {
    //         return (
    //             <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1">
    //                 <h4>새로운 업데이트가 있습니다. 잠시만 기다려 주세요.</h4>
    //                 <p>자동으로 업데이트가 되지 않을 경우 아래의 버튼을 눌러 주세요.</p>
    //                 <Button onClick={onClickUpdateBtn}>업데이트</Button>
    //             </div>
    //         );
    //     }
    //     else if (updateInfo?.event === 'update-not-available') {
    //         return (
    //             <>{children}</>
    //         );
    //     }
    //     else if (updateInfo?.event === 'download-progress') {
    //         return (
    //             <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1">
    //                 <h4>업데이트 중.. {`${Math.floor(updateInfo.progress.percent)}%`}</h4>
    //                 <ProgressBar now={Math.floor(updateInfo.progress.percent)} style={{height: 20, width: '60%'}}  />
    //             </div>
    //         );
    //     }
    //     else if (updateInfo?.event === 'update-downloaded') {
    //         return (
    //             <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1">
    //                 <h4>업데이트가 완료됐습니다. 프로그램을 재시작 하겠습니다.</h4>
    //             </div>
    //         );
    //     }
    //     else {
    //         return (
    //             <div className="d-flex justify-content-center align-items-center flex-grow-1">
    //                 <Spinner animation="border" />
    //             </div>
    //         );
    //     }
    // }
    // else {
    //     return (
    //         <>{children}</>
    //     );
    // }

};

export default AutoUpdateCheckView;
