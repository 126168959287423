import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError, AxiosResponse} from "axios";
import channelRepository from "../../repository/ChannelRepository";


const useDeleteApiKey = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, string>(['apiKeys', 'delete', channelId], async (apiKeyId) => {
        try {
            return await channelRepository.deleteApiKey(channelId, apiKeyId);
        } catch (e) {
            throw e;
        }
    }, {
        onSuccess: () => {
            return queryClient.invalidateQueries(['apiKeys', channelId], {exact: true});
        }
    });
}

export default useDeleteApiKey;
