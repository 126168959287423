import React from "react";
import LeftContainer from "../LeftContainer";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {useIntl} from "react-intl";
import useUserPermissions from "../../query/useUserPermissions";
import SimpleBar from "simplebar-react";


const SettingMenu: React.FC<{channelId: string}> = ({channelId}) => {
    const intl = useIntl();

    const {data: permissions} = useUserPermissions(channelId);

    return (
        <LeftContainer title={intl.formatMessage({id: 'm03'})}>
            <div className="scroll-wrapper">
                <SimpleBar className="h-100">
                    <SettingMenuHeaderStyle>{intl.formatMessage({id: 'i000188'})}</SettingMenuHeaderStyle>
                    <SettingMenuFragment to={`/channels/${channelId}/settings/manager`} name={intl.formatMessage({id: 'i000173'})} />
                    {permissions?.includes('default:livechat') && (
                        <>
                            <SettingMenuFragment to={`/channels/${channelId}/settings/templates`} name={intl.formatMessage({id: 'i000027'})} />
                            <SettingMenuFragment to={`/channels/${channelId}/settings/notification`} name={intl.formatMessage({id: 'i000074'})} />
                            <SettingMenuFragment to={`/channels/${channelId}/settings/user-categories`} name={intl.formatMessage({id: 'i000093'})} />
                        </>
                    )}
                    {permissions?.includes('setting:livechat') && (
                        <>
                            <SettingMenuDivider />
                            <SettingMenuHeaderStyle>{intl.formatMessage({id: 'i000189'})}</SettingMenuHeaderStyle>
                            <SettingMenuFragment to={`/channels/${channelId}/settings/announcements`} name={intl.formatMessage({id: 'i210038'})}/>
                            <SettingMenuFragment to={`/channels/${channelId}/settings/messages`} name={intl.formatMessage({id: 'i000035'})} />
                            <SettingMenuFragment to={`/channels/${channelId}/settings/working-times`} name={intl.formatMessage({id: 'i000041'})} />
                            <SettingMenuFragment to={`/channels/${channelId}/settings/working-holidays`} name={intl.formatMessage({id: 'i100066'})} />
                            <SettingMenuFragment to={`/channels/${channelId}/settings/categories`} name={intl.formatMessage({id: 'i000095'})} />
                            <SettingMenuFragment to={`/channels/${channelId}/settings/assign-rule`} name={intl.formatMessage({id: 'i000134'})} />
                            <SettingMenuFragment to={`/channels/${channelId}/settings/banned-words`} name={intl.formatMessage({id: 'i000142'})} />
                            <SettingMenuFragment to={`/channels/${channelId}/settings/auto-close`} name={intl.formatMessage({id: 'i000362'})} />
                            <SettingMenuFragment to={`/channels/${channelId}/settings/priorities`} name={intl.formatMessage({id: 'i000380'})} />
                            {/*<SettingMenuFragment to={`/channels/${channelId}/settings/roles`} name={intl.formatMessage({id: 'i000335'})} />*/}
                            <SettingMenuDivider />
                            <SettingMenuHeaderStyle>{intl.formatMessage({id: 'i000190'})}</SettingMenuHeaderStyle>
                            <SettingMenuFragment to={`/channels/${channelId}/settings/api-keys`} name={intl.formatMessage({id: 'i000191'})} />
                            <SettingMenuFragment to={`/channels/${channelId}/settings/webhooks`} name={intl.formatMessage({id: 'i000192'})} />
                            <SettingMenuFragment to={`/channels/${channelId}/settings/custom-panels`} name={intl.formatMessage({id: 'i200081'})} />
                        </>
                    )}
                </SimpleBar>
            </div>
        </LeftContainer>
    )
};


const SettingMenuFragment: React.FC<{to: string, name: string}> = ({to, name}) => {

    return (
        <SettingMenuStyle>
            <NavLink to={to}>
                <span>{name}</span>
            </NavLink>
        </SettingMenuStyle>
    );
};

const SettingMenuHeaderStyle = styled.h5`
  display: block;
  margin: 0.325rem 0 0;
  padding: 0.55rem 0;
  //color: #555;
  color: var(--left-container-text-color);
`

const SettingMenuStyle = styled.div`
  color: var(--left-container-text-color);
  background-color: transparent;
  position: relative;
  margin-bottom: 1px;
  //margin-left: 0.325rem;
  
  a {
    display: block;
    position: relative;
    padding: 0.5rem 0.5rem;
    color: var(--left-container-text-color);
    width: 100%;
    line-height: 1.1rem;
    text-decoration: none;
    border: 1px solid transparent;
    
    &.active, &:hover {
      border-radius: 8px;
      background-color: var(--menu-active-background-color);
      border: 1px solid rgba(212, 213, 215, 0.6);
    }
    
    //&.active {
    //  border-radius: 0.25rem 0 0 0.25rem;
    //  background-color: var(--menu-active-background-color);
    //  color: var(--menu-active-color) !important;
    //  border-top: 1px solid rgba(212, 213, 215, 0.8);
    //  border-bottom: 1px solid rgba(212, 213, 215, 0.8);
    //  border-left: 1px solid rgba(212, 213, 215, 0.8);
    //  box-shadow: inset 1px 0 2px 0 rgba(212, 213, 215, 0.4);
    //}
    
    //span {
    //  display: block;
    //  margin: -0.4rem -0.2rem -0.4rem -0.4rem;
    //  padding: 0.4rem;
    //}
    //
    //&:not(.active) {
    //  &:hover {
    //    border-radius: 0.25rem 0 0 0.25rem;
    //    background-color: var(--menu-active-background-color);
    //  }
    //}

  }
`

const SettingMenuDivider = styled.div`
    height: 0;
    margin: .5rem 0.55rem;
    overflow: hidden;
    border-top: 1px solid var(--menu-border-color);
`;

export default SettingMenu;