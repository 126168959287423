import {useQuery} from "@tanstack/react-query";
import {CustomSnippet} from "../../model/customSnippet";
import {LiveChatError, toLiveChatError} from "../../error";
import customSnippetRepository from "../../repository/CustomSnippetRepository";

const getData = async (channelId: string, snippetId: string) => {
    try {
        const { data } = await customSnippetRepository.read(channelId, snippetId);
        return data.result;
    } catch (e) {
        throw toLiveChatError(e);
    }
}

const useCustomSnippet = (channelId: string, snippetId: string) => {
    return useQuery<CustomSnippet, LiveChatError>({
        queryKey: ['snippets', channelId, snippetId],
        queryFn: async () => getData(channelId, snippetId),
        suspense: false,
        retry: false,
    })
};

export default useCustomSnippet;
