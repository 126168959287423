import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import {LiveChatError, toLiveChatError} from "../../error";
import conditionalAnnouncementRepository from "../../repository/ConditionalAnnouncementRepository";
import {ConditionalAnnouncementCreate} from "../../model/conditionalAnnouncement";

const useCreateConditionalAnnouncement = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, LiveChatError, ConditionalAnnouncementCreate>({
        mutationKey: ["conditional-announcement", "create"],
        mutationFn: async (body) => {
            try {
                const { data } = await conditionalAnnouncementRepository.create(channelId, body)
                return data.result;
            } catch (e) {
                throw toLiveChatError(e);
            }
        },
        onSuccess: () => {
            return Promise.all([
                queryClient.invalidateQueries(['conditional-announcements', channelId], {exact: true}),
            ]);
        }
    });
};

export default useCreateConditionalAnnouncement;
