import oauthAxios from "../core/oauthAxios";
import {LiveChatState} from "../model/livechat";
import {LIVE_CHAT_API_HOST} from "../core/variables";

class LiveChatBulkRepository {
    async changeFavorite(channelId: string, liveChatIds: string[], isFavorite: boolean) {
        return await oauthAxios.post(
            `${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/bulk-livechats/followers`,
            {isFavorite, liveChatIds}
        );
    };

    async changeState(channelId: string, liveChatIds: string[], state: LiveChatState, sendMessage?: boolean) {
        return await oauthAxios.put(
            `${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/bulk-livechats/state/${state}`,
            {liveChatIds: liveChatIds},
            {params: {sendMessage}}
        );
    };

    async uploadFile(channelId: string, file: File, liveChatIds: string[]) {
        let form = new FormData();
        form.append("file", file);
        form.append("liveChatIds", liveChatIds.toString());
        return oauthAxios.post(
            `${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/bulk-livechats/file`,
            form,
            {
                headers: { "Content-Type" : "multipart/form-data"}
            }
        );
    };
}

export default new LiveChatBulkRepository();
