import React, {useRef} from "react";
import electronRuntime from "../core/electronRuntime";
import {useSetRecoilState} from "recoil";
import downloadFilesState from "../recoil/downloadFiles";
import {v4} from "uuid";

const FileDownloader: React.FC<{url: string, filename: string} & React.HTMLProps<HTMLDivElement>> = ({url, filename, children, ...props}) => {
    const ref = useRef<HTMLAnchorElement>(null);

    const setDownloadFiles = useSetRecoilState(downloadFilesState);

    // const [downloadProgress, setDownloadProgress] = useState(0);

    const onClick = async (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (url) {
            // setDownloading(true);
            if (electronRuntime) {
                const id = v4();
                electronRuntime.send('download', {id, url, filename});
                setDownloadFiles(prev => {
                    return [{id, url, filename}, ...prev];
                });
            }
            else {
                if (ref.current) {
                    ref.current.click();
                }
            }

            // try {
            //     ref.current.download = filename;
            //     setTimeout(() => {
            //         if (ref.current) {
            //             ref.current.click();
            //         }
            //     }, 250);
            // } catch (e: any) {
            //     if (e.response && e.response.status === 404) {
            //         alert.error(intl.formatMessage({id: 'i000222'}));
            //     }
            //     else {
            //         alert.error(intl.formatMessage({id: 'i000221'}));
            //     }
            // } finally {
            //     setDownloading(false);
            //     // setTimeout(() => {
            //     //     setDownloadProgress(0);
            //     // }, 500);
            // }
        }
    };

    return (
        <div {...props}>
            <div onClick={onClick} role="button" className="d-flex align-items-center justify-content-center h-100 w-100">
                {children}
            </div>
            <a ref={ref} href={url} className="d-none" target="_blank" rel="noreferrer" download={filename}>download</a>
        </div>

    );
}

export default FileDownloader;