import React, {useMemo} from "react";
import {FileMessage} from "../../model/message";
import FileInfoCard from "../FileInfoCard";
import ImageMessage from "./ImageMessage";
import VideoMessage from "./VideoMessage";
import AudioMessage from "./AudioMessage";
import moment from "moment";


const FileMessageBlock: React.FC<{fileMessage: FileMessage, createdAt?: string}> = ({fileMessage, createdAt}) => {
    const downloadable = useMemo(() => {
        if (!createdAt) {
            return true;
        }
        return moment.utc().diff(moment.utc(createdAt), 'days') < 7;
    }, [createdAt]);

    return useMemo(() => {
        if (!downloadable) {
            return (
                <div className="message-content my-1">
                    <FileInfoCard id={fileMessage.url} variant={'info'} filename={fileMessage.filename} downloadable={downloadable}/>
                </div>
            )
        } else {
            if (fileMessage.contentType?.indexOf('image') === 0) {
                return (
                    <ImageMessage src={fileMessage.url} filename={fileMessage.filename} downloadable={downloadable} />
                );
            }
            else if (fileMessage.contentType?.indexOf('video') === 0) {
                return (
                    <VideoMessage url={fileMessage.url} />
                );
            }
            else if (fileMessage.contentType?.indexOf('audio') === 0) {
                return (
                    <AudioMessage url={fileMessage.url} />
                );
            }
            else {
                return (
                    <div className="message-content my-1">
                        <FileInfoCard id={fileMessage.url} variant={'info'} filename={fileMessage.filename} fileSize={fileMessage.size} downloadable={downloadable} downloadSrc={fileMessage.url} />
                    </div>
                );
            }
        }
    }, [fileMessage, downloadable])
};


export default FileMessageBlock;
