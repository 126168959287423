import {LIVE_CHAT_API_HOST} from "../core/variables";
import oauthAxios from "../core/oauthAxios";
import {ManagerUpdate} from "../model/manager";

class ManagerRepository {

    async find(channelId: string, name?: string) {
        const params: {name?: string} = {}
        if (name) {
            params.name = name;
        }
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/managers`, {
            params: params
        })
    }

    async create(channelId: string, name: string) {
        return oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/managers`, {
            name
        });
    }

    async update(channelId: string, managerId: string, body: ManagerUpdate) {
        return oauthAxios.put(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/managers/${managerId}`, body);
    }

    async setAway(channelId: string, managerId: string) {
        return oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/managers/${managerId}/away`);
    }

    async updateAway(channelId: string, managerId: string, body: {awayReason?: string, awayUntil?: string} ) {
        return oauthAxios.put(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/managers/${managerId}/away`, body);
    }

    async releaseAway(channelId: string, managerId: string) {
        return oauthAxios.put(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/managers/${managerId}/end-away`);
    }
}

export default new ManagerRepository();
