import React from "react";
import {PropsGetterArgs} from "react-popper-tooltip/dist/types";


const Tooltip = React.memo<TooltipCompProps>(({visible, setTooltipRef, getTooltipProps, getArrowProps, message}) => {
    if (visible && message) {
        return (
            <div ref={setTooltipRef} {...getTooltipProps({className: 'tooltip-container text-pre'})}>
                <div {...getArrowProps({className: 'tooltip-arrow'})} />
                {message}
            </div>
        )
    }
    else {
        return null;
    }
});

type TooltipCompProps = {
    visible?: boolean
    setTooltipRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>
    getTooltipProps: (args?: PropsGetterArgs) => {
        'data-popper-interactive': boolean | undefined;
        style: React.CSSProperties;
    }
    getArrowProps: (args?: PropsGetterArgs) => {
        style: React.CSSProperties;
        'data-popper-arrow': boolean;
    }
    message?: string
}

export default Tooltip;