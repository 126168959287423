import {useMutation, useQueryClient} from "@tanstack/react-query";
import categoryRepository from "../../repository/CategoryRepository";
import {AxiosError, AxiosResponse} from "axios";


const useCreateCategory = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, string>(['categories', 'create', channelId], async (name: string) => {
        try {
            return await categoryRepository.create(channelId, name);
        } catch (e) {
            throw e;
        }
    }, {
        onSuccess: () => {
            return Promise.all([
                queryClient.invalidateQueries(['categories', channelId], {exact: true}),
                queryClient.invalidateQueries(['categorySummaries', channelId], {exact: true})
            ]);
        }
    });
}

export default useCreateCategory;
