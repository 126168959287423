import React, {useMemo} from "react";
import {FilterSnapshotKey} from "../../model/chatSearch";
import styled from "styled-components";
import {Manager} from "../../model/manager";
import {ChatUser} from "../../model/chatUser";
import moment, {Moment} from "moment";
import {useIntl} from "react-intl";

type ChatSearchFilterSnapshotItemProps = {
    filterKey: FilterSnapshotKey
    content: Manager[] | ChatUser[] | Moment[]
}

const ChatSearchFilterSnapshotItem = React.memo<ChatSearchFilterSnapshotItemProps>((props) => {
    const { filterKey, content } = props;
    const intl = useIntl();

    const filter = useMemo(() => {
        switch (filterKey) {
            case "managers":
                const managers = content as Manager[];
                return {
                    key: intl.formatMessage({id: "i210016"}),
                    summary: managers[0].name
                        + (managers.length > 1
                            ? intl.formatMessage({id: "i210017"}, {count: managers.length - 1})
                            : "")
                }
            case "chatUsers":
                const chatUsers = content as ChatUser[];

                return {
                    key: intl.formatMessage({id: "i210018"}),
                    summary: (chatUsers[0].name ?? chatUsers[0].alias)
                        + (chatUsers.length > 1
                            ? intl.formatMessage({id: "i210017"}, {count: chatUsers.length - 1})
                            : "")
                }
            case "dateRange":
                const [startDate, endDate] = content as Moment[];
                const format = intl.formatMessage({id: "i210020"})

                return {
                    key: intl.formatMessage({id: "i210019"}),
                    summary: `${moment(startDate).local().format(format)} ~ ${moment(endDate).local().format(format)}`
                }
        }
    }, [filterKey, content, intl]);

    return (
        <SnapshotItemStyle>
            <div className="filter-key">{filter.key}</div>
            <div className="filter-summary">{filter.summary}</div>
        </SnapshotItemStyle>
    );
});

const SnapshotItemStyle = styled.div`
  display: flex;
  align-items: center;
  margin: 0.25rem;
  background-color: #dcdcdc;

  border-radius: 5px;

  font-size: 12px;

  .filter-key {
    padding: 0.1rem 0.25rem;
    border-right: 1px solid #b7b7b7;
    border-radius: 5px 0 0 5px;
    background-color: #b7b7b7;
    color: white;
  }

  .filter-summary {
    padding: 0.1rem 0.25rem;
  }
`;

export default ChatSearchFilterSnapshotItem;
