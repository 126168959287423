import {useQuery} from "@tanstack/react-query";
import {LiveChatMemo} from "../../model/livechat";
import liveChatMemoRepository from "../../repository/LiveChatMemoRepository";
import {LiveChatError, toLiveChatError} from "../../error";


const getData = async (channelId: string, liveChatId: string) => {
    if (!liveChatId) {
        return undefined;
    }

    try {
        const {data} = await liveChatMemoRepository.find(channelId, liveChatId)
        return data.result;
    } catch (e) {
        throw toLiveChatError(e);
    }

}


const useMemos = (channelId: string, liveChatId: string) => {
    return useQuery<LiveChatMemo[], LiveChatError>(['memos', channelId, liveChatId], async () => getData(channelId, liveChatId), {suspense: false});
};


export default useMemos;