import React from "react";
import logo from "../../assets/images/swing_chat_logo.png";
import errorBgUrl from "../../assets/images/error-bg.svg";
import styled from "styled-components";
import {Button} from "react-bootstrap";
import ReactDOM from "react-dom";
import electronRuntime from "../../core/electronRuntime";

const View40X: React.FC<{code: string, title: string, description: string, reset: () => void}> = (props) => {

    const onClickReturnHome = async () => {
        localStorage.removeItem('default_channel');
        localStorage.removeItem('LCM');
        localStorage.removeItem('LLC');
        localStorage.removeItem('redirect');

        props.reset();

        if (electronRuntime) {
            electronRuntime.send('loadMain', 'ping');
        }
        else {
            window.location.href = '/';
        }
    };

    return ReactDOM.createPortal((
        <ErrorViewWrapper>
            <div className="container">
                <div className="row justify-content-center mt-2">
                    <div className="col-xxl-4 col-lg-5">
                        <div className="card">
                            <div className="card-header py-4 d-flex align-items-center justify-content-center bg-primary">
                                <img src={logo} alt="logo" height="30" className="mr-1"/>
                                <h3 className="m-0 text-white mt-1">
                                    스윙챗
                                </h3>
                            </div>

                            <div className="card-body py-4 px-4">
                                <div className="text-center">
                                    <h1 className="text-error">4<i className="mdi mdi-emoticon-sad"/>{props.code.at(-1)}</h1>
                                    <h4 className="text-uppercase text-danger mt-3">{props.title}</h4>

                                    <p className="text-start text-muted mt-3 text-pre font-13">{props.description}</p>

                                    <Button variant="info" onClick={onClickReturnHome}>
                                        <i className="mdi mdi-reply me-1"/>
                                        Return Home
                                    </Button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ErrorViewWrapper>
    ), document.getElementById('error-portal')!);
};


const ErrorViewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  height: 100%;
  
  background-image: url(${errorBgUrl});
  background-size: cover;
  background-position: center;
`;

export default View40X;