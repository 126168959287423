import React, {CSSProperties} from "react";
import classNames from "classnames";
import styled from "styled-components";

const LeftContainer: React.FC<LeftContainerProps> = ({title, className, style, children}) => {

    return (
        <LeftContainerStyle style={style}>
            <div className="divider" />
            {title && <h3 className="title user-select-none">{title}</h3>}
            <div className={classNames('left-container', {[className!]: className})} >
                {children}
            </div>
        </LeftContainerStyle>
    );
};

type LeftContainerProps = {
    title?: string
    className?: string
    style?: CSSProperties
    children?: React.ReactNode
}

const LeftContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  //box-shadow: inset -1px 0 1px #bbb;
  background-color: var(--left-container-background-color);
  padding: 0 0.75rem;
  width: var(--live-chat-container-width);
  //overflow-x: visible;
  flex-shrink: 0;
  //margin: 0 1px 0 0;
  position: relative;
  overflow: hidden;
  
  .divider {
    //box-shadow: 0 0 2px rgba(212, 213, 215, 0.8);
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    width: 1px;
    height: 100%;
    right: 1px;
    //right: -1px;
    //background-color: #ffffff;
    //z-index: 10;
  }

  .title {
    //padding: 0 0.325rem 0 1.075rem;
    margin-top: 20px;
    margin-bottom: 10px;
    //width: calc(240px - 1.5rem);
    flex-grow: 0;
    flex-shrink: 0;
  }

  .left-container {
    position: relative;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    flex-shrink: 1;
    //height: 100%;
    overflow: hidden;
    //overflow: hidden auto;
    
    .scroll-wrapper {
      height: 100%;
      min-height: 0;
      //margin-left: 1.075rem;
    }
  }
`


export default LeftContainer;