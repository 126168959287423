import React, {useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {Button, Card, Form, Modal, Spinner, Table} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import useStores from "../../hook/useStores";
import {Link, Route, useNavigate, useParams} from "react-router-dom";
import {utcToLocal} from "../../util/datetime";
import useModal, {MaruModal} from "../../hook/useModal";
import webhookRepository from "../../repository/WebhookRepository";
import {useDialog} from "../../hook/useDialog";
import {Webhook} from "../../model/webhook";
import styled from "styled-components";
import * as yup from "yup";
import {WEBHOOK_URL_CHECK} from "../../core/variables";
import SettingViewWrapper from "../../component/setting/SettingViewWrapper";
import {SentryRoutes} from "../../core/sentry";
import toast from "react-hot-toast";

const WebhookManagementView: React.FC = observer(() => {

    const intl = useIntl();
    const modal = useModal();

    const {channelId} = useParams<{channelId: string}>();

    if (!channelId) {
        throw new Error('Required channelId path params')
    }

    if (!channelId) {
        throw new Error('Required channelId path params')
    }

    const onClickAddWebhook = (e: React.MouseEvent) => {
        e.preventDefault();
        modal.open('webhook-registration-modal');
    };

    return (
        <SettingViewWrapper>
            <div className="setting-title">
                <h3>{intl.formatMessage({id: 'i000192'})}</h3>
                <p>
                    {intl.formatMessage({id: 'i000202'})}
                </p>
                <SentryRoutes>
                    <Route index element={(
                        <Button variant="info" type="button" className="btn-rounded" onClick={onClickAddWebhook}>
                            {intl.formatMessage({id: 'i000203'})}
                        </Button>
                    )} />
                </SentryRoutes>
            </div>

            <SentryRoutes>
                <Route index element={<WebhookListFragment />} />
                <Route path=":webhookId" element={<WebhookDetailFragment />} />
            </SentryRoutes>

            <WebhookRegistrationModal channelId={channelId} />
        </SettingViewWrapper>
    )
});

const WebhookListFragment: React.FC = observer(() => {
    const {webhookListStore} = useStores();

    const intl = useIntl();

    const {channelId} = useParams<{channelId: string}>();

    if (!channelId) {
        throw new Error('Required channelId path params')
    }

    useEffect(() => {
        webhookListStore.load(channelId).catch(_ => {
            toast.error(intl.formatMessage({id: 'i000201'}))
        });

        return () => {
            webhookListStore.clear();
        }
    }, [channelId, webhookListStore, intl]);

    return (
        <Table size="sm" className="table-centered" responsive={false}>
            <colgroup>
                <col width={'180px'} />
                <col />
                <col width={'110px'} />
                <col width={'50px'} />
            </colgroup>
            <thead>
            <tr>
                <th>{intl.formatMessage({id: 'i000087'})}</th>
                <th>URL</th>
                {/*<th>Token</th>*/}
                <th>{intl.formatMessage({id: 'i000133'})}</th>
                <th>{''}</th>
            </tr>
            </thead>
            <tbody>
            {webhookListStore.data.filter(webhook => !webhook.default).map(webhook => (
                <WebhookRowFragment key={webhook._id} webhook={webhook} />
            ))}
            {webhookListStore.state === 'pending' && (
                <tr><th colSpan={4}><Spinner size="sm" animation="border" /></th></tr>
            )}
            {webhookListStore.data.length < 1 && webhookListStore.state === 'done' && (
                <tr><th colSpan={4} className="text-muted">{intl.formatMessage({id: 'i000204'})}</th></tr>
            )}
            </tbody>
        </Table>
    );
});

const WebhookRowFragment: React.FC<{webhook: Webhook}> = ({webhook}) => {
    return (
        <WebhookRowStyle>
            <td>
                <span className="name text-truncate" title={webhook.name}>{webhook.name}</span>
            </td>
            <td>{webhook.url}</td>
            {/*<td className="text-key">{webhook.token}</td>*/}
            <td>{utcToLocal(webhook.createdAt)}</td>
            <td className="text-end">
                <Link to={`/channels/${webhook.channelId}/settings/webhooks/${webhook._id}`}>
                    <i className="mdi mdi-chevron-right" />
                </Link>
            </td>
        </WebhookRowStyle>
    );
};

const WebhookDetailFragment: React.FC = observer(() => {
    const {webhookStore} = useStores();
    const intl = useIntl();
    const dialog = useDialog();
    const navigate = useNavigate();

    const {channelId, webhookId} = useParams<{channelId: string, webhookId: string}>();

    if (!channelId || !webhookId) {
        throw new Error('Required channelId path params')
    }

    const [invalid, setInvalid] = useState({name: false, url: false, urlType: false, token: false});
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const nameRef = useRef<HTMLInputElement>(null);
    const urlRef = useRef<HTMLInputElement>(null);
    const tokenRef = useRef<HTMLInputElement>(null);
    const liveChatWebhookRef = useRef<HTMLInputElement>(null);
    const messageWebhookRef = useRef<HTMLInputElement>(null);
    const memberWebhookRef = useRef<HTMLInputElement>(null);
    const typingWebhookRef = useRef<HTMLInputElement>(null);


    useEffect(() => {
        webhookStore.load(channelId, webhookId).then(() => {
            if (nameRef.current) {
                nameRef.current.value = webhookStore.data?.name || '';
            }
            if (urlRef.current) {
                urlRef.current.value = webhookStore.data?.url || '';
            }
            if (tokenRef.current) {
                tokenRef.current.value = webhookStore.data?.token || '';
            }
        }).catch(_ => {
            toast.error(intl.formatMessage({id: 'i000210'}))
        });

        return () => {
            webhookStore.clear();
        };
    }, [channelId, webhookId, webhookStore, intl]);


    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const name = nameRef.current?.value || '';
        const url = urlRef.current?.value || '';
        const token = tokenRef.current?.value || '';

        const invalidUrl = WEBHOOK_URL_CHECK ? !(await yup.string().url().isValid(url)) : false;
        const invalid = {name: !name, url: !url, urlType: invalidUrl, token: !token};
        if (Object.values(invalid).some(value => value)) {
            setInvalid(invalid);
            return;
        }

        const events = [];
        if (liveChatWebhookRef.current?.checked) {
            events.push('live_chat');
        }
        if (messageWebhookRef.current?.checked) {
            events.push('message');
        }
        if (memberWebhookRef.current?.checked) {
            events.push('member');
        }
        if (typingWebhookRef.current?.checked) {
            events.push('typing');
        }
        setInvalid({name: false, url: false, urlType: false, token: false});

        setSaving(true);

        try {
            await webhookRepository.update(channelId, webhookId, name!, url!, token, events);
            await webhookStore.load(channelId, webhookId);

            toast.success(intl.formatMessage({id: 'i000214'}))
        } catch (e) {
            toast.error(intl.formatMessage({id: 'i000207'}));
        }

        setSaving(false);
    }

    const onClickDeleteBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        dialog.open({
            title: intl.formatMessage({id: 'i000034'}),
            content: intl.formatMessage({id: 'i000205'}),
            variant: 'danger',
            onConfirm: async () => {
                setDeleting(true);
                try {
                    await webhookRepository.delete(channelId, webhookId);
                    navigate(`/channels/${channelId}/settings/webhooks`);
                } catch (e) {
                    toast.error(intl.formatMessage({id: 'i000206'}));
                }
            }
        });
    };


    return (
        <div>
            <Form onSubmit={onSubmit}>
                <Form.Group className="mb-2">
                    <Form.Label>{intl.formatMessage({id: 'i000087'})}</Form.Label>
                    <Form.Control type="text" isInvalid={invalid.name} ref={nameRef} readOnly={saving} />
                    <Form.Control.Feedback type="invalid">{intl.formatMessage({id: 'i000024'})}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>URL</Form.Label>
                    <Form.Control type="text" isInvalid={invalid.url || invalid.urlType} ref={urlRef} readOnly={saving} />
                    <Form.Control.Feedback type="invalid">
                        {invalid.urlType ? intl.formatMessage({id: 'i000209'}) : intl.formatMessage({id: 'i000024'})}
                    </Form.Control.Feedback>
                    <Form.Text>{intl.formatMessage({id: 'i000208'})}</Form.Text>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>{intl.formatMessage({id: 'i000251'})}</Form.Label>
                    <div>
                        <Form.Check id="live_chat_event_checkbox"
                                    inline
                                    label={intl.formatMessage({id: 'i000252'})}
                                    defaultChecked={webhookStore.data?.events.includes('live_chat')}
                                    ref={liveChatWebhookRef} />
                        <Form.Check id="message_event_checkbox"
                                    inline
                                    label={intl.formatMessage({id: 'i000253'})}
                                    defaultChecked={webhookStore.data?.events.includes('message')}
                                    ref={messageWebhookRef} />
                        <Form.Check id="member_event_checkbox"
                                    inline
                                    label={intl.formatMessage({id: 'i000254'})}
                                    defaultChecked={webhookStore.data?.events.includes('member')}
                                    ref={memberWebhookRef} />
                        <Form.Check id="typing_event_checkbox"
                                    inline
                                    label={intl.formatMessage({id: 'i000281'})}
                                    defaultChecked={webhookStore.data?.events.includes('typing')}
                                    ref={typingWebhookRef} />
                    </div>
                    <Form.Text>{intl.formatMessage({id: 'i000255'})}</Form.Text>
                </Form.Group>
                <Form.Group className="mb-1">
                    <Form.Label>Token</Form.Label>
                    <Form.Control type={'text'} isInvalid={invalid.token} ref={tokenRef} readOnly={saving} />
                    <Form.Control.Feedback type={'invalid'}>{intl.formatMessage({id: 'i000024'})}</Form.Control.Feedback>
                    <Form.Text>{intl.formatMessage({id: 'i000213'})}</Form.Text>
                </Form.Group>

                <div className="float-end">
                    <Link to="../" className="mr-1 btn btn-link text-secondary">
                        {intl.formatMessage({id: 'i000022'})}
                    </Link>
                    <Button type="submit" className="btn-rounded" disabled={saving}>
                        {saving && <Spinner size="sm" animation="border" />}
                        {!saving && intl.formatMessage({id: 'i000040'})}
                    </Button>
                </div>
                <div className="clearfix" />
            </Form>

            <Card border="danger" className="shadow-none border mt-4">
                <Card.Header className="text-danger">Danger</Card.Header>
                <Card.Body>
                    <Card.Text className="float-left">{intl.formatMessage({id: 'i000212'})}</Card.Text>
                    <Button variant="danger" className="float-right btn-rounded" onClick={onClickDeleteBtn} disabled={deleting}>
                        {deleting && <Spinner size="sm" animation="border" />}
                        {!deleting && intl.formatMessage({id: 'i000034'})}
                    </Button>
                </Card.Body>
            </Card>
        </div>
    );
});

const WebhookRegistrationModal: React.FC<{channelId: string}> = ({channelId}) => {
    const {webhookListStore} = useStores();
    const intl = useIntl();
    const modal = useModal();

    const [invalid, setInvalid] = useState({name: false, url: false});
    const [invalidUrl, setInvalidUrl] = useState(false);

    const nameRef = useRef<HTMLInputElement>(null);
    const urlRef = useRef<HTMLInputElement>(null);

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const name = nameRef.current?.value || '';
        const url = urlRef.current?.value || '';

        const invalid = {name: !name, url: !url};
        const validUrl = WEBHOOK_URL_CHECK ? await yup.string().url().isValid(url) : true;
        if (Object.values(invalid).some(value => value) || !validUrl) {
            setInvalid(invalid);
            setInvalidUrl(!validUrl);
            return;
        }
        setInvalid({name: false, url: false});
        setInvalidUrl(false);

        try {
            await webhookRepository.create(channelId, name!, url!);
            await webhookListStore.load(channelId);
            onClose();

            modal.close('webhook-registration-modal');
        } catch (e) {
            toast.error(intl.formatMessage({id: 'i000207'}));
        }
    }

    const onClose = () => {
        setInvalid({name: false, url: false});
        setInvalidUrl(false);
    };

    const onClickCancelBtn = (e: React.MouseEvent) => {
        e.preventDefault();
        onClose();
        modal.close('webhook-registration-modal');
    }

    return (
        <MaruModal id={'webhook-registration-modal'} title={intl.formatMessage({id: 'i000203'})} onClose={onClose}>
            <Modal.Body>
                <Form id={'webhook-registration-form'} onSubmit={onSubmit}>
                    <Form.Group className="mb-1">
                        <Form.Label>{intl.formatMessage({id: 'i000087'})}</Form.Label>
                        <Form.Control type={'text'} isInvalid={invalid.name} ref={nameRef} />
                        <Form.Control.Feedback type={'invalid'}>{intl.formatMessage({id: 'i000024'})}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-1">
                        <Form.Label>{'URL'}</Form.Label>
                        <Form.Control type={'text'} isInvalid={invalid.url || invalidUrl} ref={urlRef} />
                        <Form.Control.Feedback type={'invalid'}>
                            {invalidUrl ? intl.formatMessage({id: 'i000209'}) : intl.formatMessage({id: 'i000024'})}
                        </Form.Control.Feedback>
                        <Form.Text>{intl.formatMessage({id: 'i000208'})}</Form.Text>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" className="btn-rounded" variant="outline-secondary" onClick={onClickCancelBtn}>{intl.formatMessage({id: 'i000022'})}</Button>
                <Button type="submit" className="btn-rounded" form={'webhook-registration-form'}>{intl.formatMessage({id: 'i000023'})}</Button>
            </Modal.Footer>
        </MaruModal>
    )
};

const WebhookRowStyle = styled.tr`
  td {
    span.name {
      width: 150px !important;
      display: block;
    }
  }
  td.text-key {
    font-family: "Roboto Mono", monospace !important;
  }
`

export default WebhookManagementView;
