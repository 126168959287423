import React, {useEffect, useMemo, useRef, useState} from "react";
import Menu from "../component/Menu";
import {Navigate, Route, useLocation, useNavigate, useParams} from "react-router-dom";
import {useIntl} from "react-intl";
import {LiveChat, LiveChatContext, LiveChatStatus} from "../model/livechat";
import notificationIcon from "../assets/images/notification_icon.png";
import useModal, {MaruModal} from "../hook/useModal";
import {Button, Form, Modal} from "react-bootstrap";
import {Channel} from "../model/channel";
import {ChatUser} from "../model/chatUser";
import electronRuntime from "../core/electronRuntime";
import {Message, TextMessage} from "../model/message";
import useManager from "../query/manager/useManager";
import useLiveChatsHandler from "../hook/useLiveChatsHandler";
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import useChannel from "../query/channel/useChannel";
import ReactDOM from "react-dom";
import platformState from "../recoil/platform";
import useNotificationSetting from "../query/notification/useNotificationSetting";
import useCreateManager from "../query/manager/useCreateManager";
import PrivateRoute from "../component/PrivateRoute";
import ChatListMenu from "../component/menu/ChatListMenu";
import SettingMenu from "../component/menu/SettingMenu";
import useLiveChatHandler from "../hook/useLiveChatHandler";
import SocketProvider from "../socket/SocketProvider";
import useSocketSubscribe from "../socket/useSocketSubscribe";
import {SocketEvent, useThrottledSocketSubscribe} from "../socket";
import * as Sentry from "@sentry/react";
import {SentryRoutes} from "../core/sentry";
import useUserPermissions from "../query/useUserPermissions";
import {authorizePermissions} from "../util/permissionUtil";
import {useLocalStorageValue, useMountEffect, useUnmountEffect} from "@react-hookz/web";
import {useQueryClient} from "@tanstack/react-query";
import {getData as getManagerLiveChats} from "../query/liveChat/useManagerLiveChats";
import {getData as getAllLiveChats} from "../query/liveChat/useAllLiveChats";
import {getData as getUnassignedLiveChats} from "../query/liveChat/useUnassignedLiveChats";
import useFavoriteLiveChats, {getData as getFavoriteLiveChats} from "../query/liveChat/useFavoriteLiveChats";
import {getData as getUnreadLiveChats} from "../query/liveChat/useUnreadLiveChats";
import useManagers, {getData as getManagers} from "../query/manager/useManagers";
import useUserAttributes, {getData as getUserAttributes} from "../query/useUserAttributes";
import liveChatContext from "../recoil/liveChatContext";
import useUpdateUnreadMessages from "../query/message/useUpdateUnreadMessages";
import useDeleteUnreadMessage from "../query/message/useDeleteUnreadMessage";
import AppLoading from "../component/AppLoading";
import ChatListView from "./chat/ChatListView";
import ChatView from "./chat/ChatView";
import SettingView from "./setting/SettingView";
import MemberView from "./member/MemberView";
import ChatFullSizeView from "./chat/ChatFullSizeView";
import toast from "react-hot-toast";
import useChannelSettingHandler from "../hook/useChannelSettingHandler";
import ChatMenu from "../component/menu/ChatMenu";
import useLiveChatContext from "../hook/useLiveChatContext";
import {Manager} from "../model/manager";
import useProfile from "../query/useProfile";
import DashboardView from "./dashboard/DashboardView";
import ChatSearchView from "./chat/ChatSearchView";
import chatSearchTrackingState from "../recoil/chatSearchTracking";
import {LATEST_ALL_PRIORITIES_KEY, LATEST_MANAGER_PRIORITIES_KEY} from "../core/variables";
import useSocketIOSubscribe from "../socket/useSocketIOSubscribe";
import useCategories from "../query/category/useCategories";
import useUnreadMessages from "../query/message/useUnreadMessages";
import ChannelNotificationView from "./notification/ChannelNotificationView";
import {Announcement} from "../model/announcement";
import useUnreadToastHandlers from "../hook/useUnreadToastHandlers";
import ChannelUnreadToast from "../component/announcement/ChannelUnreadToast";
import {ManagerEvent} from "../model/socketEvents/managerEvent";
import ManagerDashboardView from "./chat/managersDashboard/ManagerDashboardView";
import ManagerNotificationView from "./chat/ManagerNotificationView";
import {ManagerFeed} from "../model/managerFeeds";


const MANAGER_CREATE_MODAL_ID = 'manager-create-modal';

const UNREAD_TOAST_ID = "unread-toast-id";

const ChannelContextView: React.FC = () => {
    const {channelId} = useParams<{channelId: string}>();

    const modal = useModal();
    const queryClient = useQueryClient();

    const {data: channel, status: channelStatus} = useChannel(channelId!);
    const {data: userPermissions, remove: clearUserPermissions, status: userPermissionsStatus, isSuccess: isSuccessUserPermissions} = useUserPermissions(channelId!);
    const {
        data: manager,
        error: managerError,
        isError: isManagerError,
        status: managerStatus} = useManager(channelId!);
    const {data: profile} = useProfile();

    const setLiveChatContext = useSetRecoilState(liveChatContext);
    const resetChatSearchTracking = useResetRecoilState(chatSearchTrackingState);

    const [managerLiveChatPriorities] = useLocalStorageValue<{[key: string]: number[]}>(LATEST_MANAGER_PRIORITIES_KEY, {[channelId!]: []});
    const [allLiveChatPriorities] = useLocalStorageValue<{[key: string]: number[]}>(LATEST_ALL_PRIORITIES_KEY, {[channelId!]: []});

    const [prefetching, setPrefetching] = useState(true);

    const [chatMenu, ] = useLocalStorageValue<{[key: string]: LiveChatContext}>('LCM', {[channelId!]: LiveChatContext.INBOX}, {storeDefaultValue: true});

    useEffect(() => {
        if (channel) {
            Sentry.configureScope((scope) => {
                scope.setExtra('channel', {id: channel._id, name: channel.name});
            });
        }
    }, [channel]);

    useEffect(() => {
        if (managerError?.code === '404' && !modal.status[MANAGER_CREATE_MODAL_ID]) {
            modal.open(MANAGER_CREATE_MODAL_ID);
        }
    }, [managerError, modal]);

    useMountEffect(() => {
        (async () => {
            // if (!manager) {
            //     return;
            // }
            const sortBy = '-since';
            await Promise.all([
                queryClient.prefetchInfiniteQuery({
                    queryKey: ['liveChats', 'favorite', channelId],
                    queryFn: () => getFavoriteLiveChats(channelId!, profile!.user_id, {limit: 50}, sortBy),
                    staleTime: 60000,
                }),
                queryClient.prefetchInfiniteQuery({
                    queryKey: ['liveChats', LiveChatStatus.UNASSIGNED, channelId],
                    queryFn: () => getUnassignedLiveChats(channelId!, {limit: 50}, sortBy),
                    staleTime: 60000,
                }),
                queryClient.prefetchInfiniteQuery({
                    queryKey: ['liveChats', LiveChatStatus.IN_PROGRESS, channelId, profile!.user_id, managerLiveChatPriorities[channelId!] ?? []],
                    queryFn: () => getManagerLiveChats(
                        channelId!,
                        {limit: 50},
                        {
                            status: LiveChatStatus.IN_PROGRESS,
                            managerUserId: profile!.user_id,
                            priorities: managerLiveChatPriorities[channelId!] ?? [],
                            sortBy
                        }),
                    staleTime: 60000
                }),
                queryClient.prefetchInfiniteQuery({
                    queryKey: ['liveChats', LiveChatStatus.HOLDING, channelId, profile!.user_id, managerLiveChatPriorities[channelId!] ?? []],
                    queryFn: () => getManagerLiveChats(
                        channelId!,
                        {limit: 50},
                        {
                            status: LiveChatStatus.HOLDING,
                            managerUserId: profile!.user_id,
                            priorities: managerLiveChatPriorities[channelId!] ?? [],
                            sortBy
                        }),
                    staleTime: 60000
                }),
                queryClient.prefetchInfiniteQuery({
                    queryKey: ['liveChats', LiveChatStatus.COMPLETED, channelId, profile!.user_id, managerLiveChatPriorities[channelId!] ?? []],
                    queryFn: () => getManagerLiveChats(
                        channelId!,
                        {limit: 50},
                        {
                            status: LiveChatStatus.COMPLETED,
                            managerUserId: profile!.user_id,
                            priorities: managerLiveChatPriorities[channelId!] ?? [],
                            sortBy
                        }),
                    staleTime: 60000
                }),

                queryClient.prefetchInfiniteQuery({
                    queryKey: ['liveChats', LiveChatStatus.IN_PROGRESS, channelId, 'all', allLiveChatPriorities[channelId!] ?? []],
                    queryFn: () => getAllLiveChats(channelId!, LiveChatStatus.IN_PROGRESS, {
                        limit: 50
                    }, {priorities: allLiveChatPriorities[channelId!] ?? [] ,sortBy}),
                    staleTime: 60000
                }),
                queryClient.prefetchInfiniteQuery({
                    queryKey: ['liveChats', LiveChatStatus.HOLDING, channelId, 'all', allLiveChatPriorities[channelId!] ?? []],
                    queryFn: () => getAllLiveChats(channelId!, LiveChatStatus.HOLDING, {
                        limit: 50
                    }, {priorities: allLiveChatPriorities[channelId!] ?? [], sortBy}),
                    staleTime: 60000
                }),
                queryClient.prefetchInfiniteQuery({
                    queryKey: ['liveChats', LiveChatStatus.COMPLETED, channelId, 'all', allLiveChatPriorities[channelId!] ?? []],
                    queryFn: () => getAllLiveChats(channelId!, LiveChatStatus.COMPLETED, {
                        limit: 50
                    }, {priorities: allLiveChatPriorities[channelId!] ?? [], sortBy}),
                    staleTime: 60000
                }),

                queryClient.prefetchInfiniteQuery({
                    queryKey: ['liveChats', 'unread', channelId],
                    queryFn: () => getUnreadLiveChats(channelId!, {limit: 50}, {sortBy}),
                    staleTime: 60000
                }),

                queryClient.prefetchQuery({
                    queryKey: ['managers', channelId],
                    queryFn: () => getManagers(channelId!),
                    staleTime: 60000
                }),
                queryClient.prefetchQuery({
                    queryKey: ['useAttributes', channelId],
                    queryFn: () => getUserAttributes(channelId!),
                    staleTime: 60000
                })
            ]);

            setPrefetching(false);
        })();
    });

    useEffect(() => {
        setLiveChatContext(prev => {
            return {
                ...prev,
                type: chatMenu[channelId!] ?? LiveChatContext.INBOX
            }
        })
    }, [channelId, chatMenu, setLiveChatContext]);

    useUnmountEffect(() => {
        clearUserPermissions();
        resetChatSearchTracking();
        toast.remove(UNREAD_TOAST_ID);
        // await queryClient.invalidateQueries(['channels', channelId ?? '', 'permissions'], {exact: true});
    });

    if (channelStatus === 'loading' || userPermissionsStatus === 'loading' || managerStatus === 'loading' || prefetching) {
        return (
            <AppLoading />
        );
    }
    else {
        if (isSuccessUserPermissions) {
            if (isManagerError && managerError.code === '404') {
                return (
                    <>
                        {channel && <ManagerModal channel={channel} />}
                        <AppTitlePortal title={channel?.name} />
                    </>
                );
            }
            else if (manager) {
                if (authorizePermissions(['default:livechat'], userPermissions)) {
                    return (
                        <SocketProvider channelId={channelId!}>
                            <ChannelView channelId={channelId!} manager={manager} />
                            <AppTitlePortal title={channel?.name} />
                        </SocketProvider>
                    );
                }
                else {
                    return (
                        <>
                            <ChannelView channelId={channelId!} manager={manager} />
                            <AppTitlePortal title={channel?.name} />
                        </>
                    );
                }
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }

    }
};


const ChannelView: React.FC<{channelId: string, manager: Manager}> = ({channelId, manager}) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();

    const queryClient = useQueryClient();
    const platform = useRecoilValue(platformState);

    const {data: notificationSetting} = useNotificationSetting(channelId);
    const {data: userPermissions} = useUserPermissions(channelId);

    const liveChatsHandler = useLiveChatsHandler(channelId);
    const liveChatHandler = useLiveChatHandler(channelId);
    const channelSettingHandler = useChannelSettingHandler(channelId);
    const updateUnreadMessages = useUpdateUnreadMessages();
    const deleteUnreadMessage = useDeleteUnreadMessage();
    const {updateByLiveChatState, liveChatContext} = useLiveChatContext(channelId);
    const {data: managers, refetch: refetchManagers} = useManagers(channelId)
    const {refetch: refetchUserAttributes} = useUserAttributes(channelId)
    const {refetch: refetchCategories} = useCategories(channelId);
    const {refetch: refetchUnreadMessages} = useUnreadMessages(channelId)
    const {refetch: refetchFavoriteLiveChats} = useFavoriteLiveChats(channelId, manager.userId)

    const {
        initialized,
        unreadToasts,
        addNewAnnouncement,
        addNewFeed,
        removeUnreadToast,
    } = useUnreadToastHandlers(channelId);

    useThrottledSocketSubscribe<Message>(SocketEvent.MESSAGE, async (messages) => {
        const messagesByLiveChat: {[key: string]: Message[]} = {};
        messages.forEach(message => {
            const key = `${message.channelId}_${message.liveChatId}`;
            const _messages = messagesByLiveChat[key] || [];
            _messages.push(message);
            messagesByLiveChat[key] = _messages;
        });

        const activeWindow = document.hasFocus();

        const actions = Object.keys(messagesByLiveChat).map(async (key) => {
            const [channelId, liveChatId] = key.split('_');

            const messages = messagesByLiveChat[key];
            const lastMessage = messages.at(-1);

            const receiveMessages = messages.filter(message => message.sender.userId !== manager.userId);
            const firstReceiveMessage = receiveMessages.at(0);

            let liveChat = await liveChatsHandler.getShouldExistLiveChat(liveChatId, ['unassigned', 'responding', 'assigned', 'holding']);
            const joinedLiveChat = liveChat?.managers.some(m => m.userId === manager.userId) ?? false;

            // 안 읽은 메시지 메뉴 갱신
            if (liveChat) {
                if (receiveMessages.length > 0 && (joinedLiveChat || liveChat.state === 'unassigned')) {
                    liveChatsHandler.addUnreadLiveChat(liveChat);
                }
            }
            // else {
            //     // await liveChatsHandler.refetchUnreadLiveChats();
            //     await liveChatsHandler.refetchLiveChats();
            //     liveChat = liveChatsHandler.getLiveChat(liveChatId, ['unassigned', 'responding', 'assigned', 'holding']);
            // }

            // 현재 대화중이고 상담사 클라이언트 창이 활성화가 되어 있는 경우에는 뱃지 카운트를 추가하지 않는다.
            const noBadge = liveChat && liveChatHandler.liveChatId === liveChat._id && activeWindow;
            if (lastMessage) {
                liveChatsHandler.updateLatestMessage({...lastMessage, noBadge: noBadge});
            }

            if ((liveChat?.state === 'unassigned' || joinedLiveChat) && receiveMessages.length > 0) {
                updateUnreadMessages(channelId, receiveMessages.map(message => ({...message, noBadge})));
            }

            // OS 알림 처리
            if (!activeWindow && firstReceiveMessage && (liveChat?.state === 'unassigned' || joinedLiveChat)) {
                if (electronRuntime) {
                    await electronRuntime.invoke('flashFrame');
                }

                if (!notificationSetting?.allowsNotification || manager.away) {
                    return;
                }

                if (notificationSetting?.allowsMessage) {
                    if (!('Notification' in window)) {
                        return;
                    }

                    if (firstReceiveMessage.sender.userId !== manager?.userId && firstReceiveMessage.blocks.length > 0) {
                        let text = '';
                        const messageType = firstReceiveMessage.blocks[0].type;
                        if (messageType === 'text') {
                            text = (firstReceiveMessage.blocks[0].value as TextMessage).text;
                        }
                        else if (messageType === 'file') {
                            text = intl.formatMessage({id: 'i000069'});
                        }

                        if (receiveMessages.length > 1) {
                            text = `${text} 외 ${receiveMessages.length - 1}건`;
                        }

                        const senderName = firstReceiveMessage.sender.name || firstReceiveMessage.sender.alias;
                        const notification = new Notification(senderName, {
                            body: text,
                            data: {channelId: channelId, liveChatId: firstReceiveMessage.liveChatId},
                            icon: platform !== 'darwin' ? notificationIcon : undefined
                        });

                        notification.onclick = (e) => {
                            if (e.target) {
                                if (electronRuntime) {
                                    electronRuntime.send('window-active', 'ping');
                                }

                                const liveChat = liveChatsHandler.getLiveChat(notification.data.liveChatId);
                                if (liveChat) {
                                    updateByLiveChatState(liveChat.state);
                                }

                                navigate(`/channels/${notification.data.channelId}/livechats/${notification.data.liveChatId}`);
                            }
                        }
                    }
                }
            }
        });

        await Promise.all(actions);
    }, 1500);

    useSocketSubscribe<LiveChat>(SocketEvent.LIVE_CHAT_OPENED, async (liveChat) => {
        await liveChatsHandler.invalidateLiveChatTotal();

        liveChatsHandler.addLiveChat(liveChat);

        const activeWindow = document.hasFocus();
        if (!activeWindow && (liveChat.state === 'unassigned' || liveChat.managers.some(m => m.userId === manager?.userId))) {
            if (electronRuntime) {
                await electronRuntime.invoke('flashFrame');
            }

            if (!notificationSetting?.allowsNotification || manager.away) {
                return;
            }

            if (notificationSetting?.allowsLiveChat) {
                if (!('Notification' in window)) {
                    return;
                }

                const notification = new Notification(liveChat.user.name || liveChat.user.alias, {
                    body: intl.formatMessage({id: 'i000070'}),
                    data: {channelId: channelId, liveChatId: liveChat._id},
                    icon: platform !== 'darwin' ? notificationIcon : undefined
                });

                notification.onclick = (_) => {
                    if (electronRuntime) {
                        electronRuntime.send('window-active', 'ping');
                    }

                    updateByLiveChatState(liveChat.state);

                    navigate(`/channels/${notification.data.channelId}/livechats/${notification.data.liveChatId}`);
                };
            }

        }
    });

    useSocketSubscribe<ManagerEvent>(SocketEvent.MANAGER, (event: ManagerEvent) => {
        switch (event.type) {
            case "updated":
                queryClient.setQueryData<Manager>(['manager', channelId], ((_) => {
                    return event.manager
                }))
                break;
            case 'sample':
                break;
        }
    });

    useSocketSubscribe<LiveChat>(SocketEvent.LIVE_CHAT_CANCELED, (data) => {
        liveChatsHandler.updateLiveChat(data);
        liveChatHandler.updateLiveChat(data);
    });

    useSocketSubscribe<LiveChat>(SocketEvent.LIVE_CHAT_ASSIGNED, async (data) => {
        await liveChatsHandler.invalidateLiveChatTotal();

        liveChatsHandler.updateLiveChat(data);

        if (liveChatHandler.liveChatId === data._id) {
            liveChatHandler.updateLiveChat(data);
            await liveChatHandler.invalidateHistories(data._id);
        }
    });

    useThrottledSocketSubscribe<LiveChat>(SocketEvent.LIVE_CHAT_RESPONDING, async (liveChats) => {
        await liveChatsHandler.invalidateLiveChatTotal();

        for (const liveChat of liveChats) {
            liveChatsHandler.updateLiveChat(liveChat);

            if (liveChatHandler.liveChatId === liveChat._id) {
                liveChatHandler.updateLiveChat(liveChat);
                await liveChatHandler.invalidateHistories(liveChat._id);
            }
        }
    }, 200);

    useThrottledSocketSubscribe<LiveChat>(SocketEvent.LIVE_CHAT_HOLDING, async (liveChats) => {
        await liveChatsHandler.invalidateLiveChatTotal();

        for (const liveChat of liveChats) {
            liveChatsHandler.updateLiveChat(liveChat);

            if (liveChatHandler.liveChatId === liveChat._id) {
                liveChatHandler.updateLiveChat(liveChat);
                await liveChatHandler.invalidateHistories(liveChat._id);
            }
        }
    }, 200);

    useSocketSubscribe<LiveChat>(SocketEvent.LIVE_CHAT_EXPIRED, async (data) => {
        await liveChatsHandler.invalidateLiveChatTotal();

        liveChatsHandler.expireLiveChat(data);
        liveChatHandler.updateLiveChat(data);
    });

    useThrottledSocketSubscribe<LiveChat>(SocketEvent.LIVE_CHAT_SOLVED, async (liveChats) => {
        await liveChatsHandler.invalidateLiveChatTotal();

        liveChats.forEach(liveChat => {
            liveChatsHandler.updateLiveChat(liveChat);
            liveChatHandler.updateLiveChat(liveChat);
        });
    }, 200);

    useThrottledSocketSubscribe<LiveChat>(SocketEvent.LIVE_CHAT_CLOSED, async (liveChats) => {
        await liveChatsHandler.invalidateLiveChatTotal();

        liveChats.forEach(liveChat => {
            liveChatsHandler.updateLiveChat(liveChat);
            liveChatHandler.updateLiveChat(liveChat);
        });
    }, 200);

    useSocketSubscribe<LiveChat>(SocketEvent.LIVE_CHAT_JOINED, async (liveChat) => {
        await liveChatsHandler.invalidateLiveChatTotal();

        liveChatsHandler.updateLiveChat(liveChat);
        liveChatHandler.updateLiveChat(liveChat);

        if (liveChatHandler.liveChatId === liveChat._id) {
            await liveChatHandler.invalidateHistories(liveChat._id);
        }
    });

    useSocketSubscribe<LiveChat>(SocketEvent.LIVE_CHAT_LEFT, async (liveChat) => {
        await liveChatsHandler.invalidateLiveChatTotal();

        liveChatsHandler.updateLiveChat(liveChat);
        liveChatHandler.updateLiveChat(liveChat);

        if (liveChatHandler.liveChatId === liveChat._id) {
            await liveChatHandler.invalidateHistories(liveChat._id);
        }
    });

    useSocketSubscribe<{liveChatId: string, messageIds: string[]}>(SocketEvent.CONFIRM_MESSAGES_ACK, async  ({liveChatId, messageIds}) => {
        await deleteUnreadMessage(channelId, liveChatId, messageIds, async (remainingUnreadMessages) => {
            if (remainingUnreadMessages.length < 1) {
                // await liveChatsHandler.invalidateLiveChatTotal();
                await liveChatsHandler.removeUnreadLiveChat(liveChatId);
            }
        });
    });

    useSocketSubscribe<ChatUser>(SocketEvent.CHAT_USER_UPDATED, (chatUser) => {
        liveChatsHandler.updateUser(chatUser);
        if (liveChatHandler.liveChatId) {
            liveChatHandler.updateUser(liveChatHandler.liveChatId, chatUser);
        }
    });

    useSocketSubscribe<LiveChat>(SocketEvent.LIVE_CHAT_CHANGE_ASSIGNEE, async (liveChat) => {
        liveChatsHandler.updateLiveChat(liveChat);

        if (liveChatHandler.liveChatId === liveChat._id) {
            await liveChatHandler.invalidateHistories(liveChat._id);
        }
    });

    useSocketSubscribe<string>(SocketEvent.CHANNEL_UPDATED, async (receivedChannelId: string) => {
        await channelSettingHandler.invalidateChannels(receivedChannelId);
    })

    useSocketSubscribe<string>(SocketEvent.CATEGORY_CREATED, async (receivedChannelId: string) => {
        await channelSettingHandler.invalidateCategorySummaries(receivedChannelId);
    })

    useSocketSubscribe<string>(SocketEvent.CATEGORY_UPDATED, async (receivedChannelId: string) => {
        await channelSettingHandler.invalidateCategorySummaries(receivedChannelId);
    })

    useSocketSubscribe<string>(SocketEvent.CATEGORY_DELETED, async (receivedChannelId: string) => {
        await channelSettingHandler.invalidateCategorySummaries(receivedChannelId);
    })

    useSocketSubscribe<string>(SocketEvent.APIKEY_CREATED, async (receivedChannelId: string) => {
        await channelSettingHandler.invalidateApiKeys(receivedChannelId);
    })

    useSocketSubscribe<string>(SocketEvent.APIKEY_UPDATED, async (receivedChannelId: string) => {
        await channelSettingHandler.invalidateApiKeys(receivedChannelId);
    })

    useSocketSubscribe<string>(SocketEvent.APIKEY_DELETED, async (receivedChannelId: string) => {
        await channelSettingHandler.invalidateApiKeys(receivedChannelId);
    })

    useSocketSubscribe<string>(SocketEvent.WEBHOOK_CREATED, async (receivedChannelId: string) => {
        await channelSettingHandler.invalidateWebhooks(receivedChannelId);
    })

    useSocketSubscribe<string>(SocketEvent.WEBHOOK_UPDATED, async (receivedChannelId: string) => {
        await channelSettingHandler.invalidateWebhooks(receivedChannelId);
    })

    useSocketSubscribe<string>(SocketEvent.WEBHOOK_DELETED, async (receivedChannelId: string) => {
        await channelSettingHandler.invalidateWebhooks(receivedChannelId);
    })

    useThrottledSocketSubscribe<LiveChat>(SocketEvent.LIVE_CHAT_FOLLOWERS_UPDATE, async (liveChats) => {
        await liveChatsHandler.invalidateLiveChatTotal();

        liveChats.forEach(liveChat => {
            liveChatsHandler.updateLiveChatFollowers(liveChat);
            liveChatHandler.updateLiveChat(liveChat);
        });
    }, 200);

    useSocketSubscribe<string>(SocketEvent.SNIPPET, async (receivedChannelId: string) => {
        await channelSettingHandler.invalidateSnippets(receivedChannelId);
    })

    useSocketSubscribe<Announcement>(SocketEvent.ANNOUNCEMENT, (announcement) => {
        addNewAnnouncement(announcement);
    });

    useSocketSubscribe<ManagerFeed>(SocketEvent.FEED, (feed) => {
        addNewFeed(feed);
    });

    useSocketIOSubscribe(SocketEvent.RECONNECT, async () => {
        Promise.all([
            liveChatsHandler.refetchLiveChats(),
            liveChatsHandler.refetchUnreadLiveChats(),
            refetchUserAttributes(),
            refetchManagers(),
            refetchCategories(),
            refetchFavoriteLiveChats(),
            refetchUnreadMessages()
        ]).finally()
    });

    const isFullSize = useMemo(() => {
        return location.pathname.endsWith('/full');
    }, [location]);

    useEffect(() => {
        if (managers && initialized && unreadToasts && unreadToasts.length > 0) {
            const unreadToast = unreadToasts[0];
            toast.custom((t) => (
                <ChannelUnreadToast t={t}
                                    unreadToast={unreadToast}
                                    managers={managers}
                                    onClose={() => removeUnreadToast(unreadToast)}
                                    unreadCount={unreadToasts.length}
                />
            ), {
                id: UNREAD_TOAST_ID,
                position: "bottom-left",
                duration: Infinity,
            })
        }
    }, [managers, initialized, unreadToasts, removeUnreadToast]);

    return (
        <div className="app-content-wrapper">
            {!isFullSize && <Menu />}
            <SentryRoutes>
                {authorizePermissions(['default:livechat'], userPermissions) && (
                    <Route index element={<Navigate replace to="livechats" />} />
                )}
                {authorizePermissions(['setting:livechat'], userPermissions) && (
                    <Route index element={<Navigate replace to="settings" />} />
                )}

                <Route path="livechats">
                    <Route index element={(
                        <>
                            <ChatMenu channelId={channelId!} managerUserId={manager.userId} />
                            {liveChatContext && liveChatContext !== LiveChatContext.SEARCH &&
                                <ChatListMenu channelId={channelId!} managerUserId={manager.userId} />
                            }
                            <PrivateRoute permissions={['default:livechat']}>
                                <ChatListView />
                            </PrivateRoute>
                        </>
                    )} />
                    <Route path="search/*" element={(
                        <>
                            <ChatMenu channelId={channelId!} managerUserId={manager.userId} />
                            <PrivateRoute permissions={['default:livechat']}>
                                <ChatSearchView />
                            </PrivateRoute>
                        </>
                    )} />
                    <Route path="manager-notifications/*" element={(
                        <>
                            <ChatMenu channelId={channelId!} managerUserId={manager.userId} />
                            <PrivateRoute permissions={['default:livechat', 'setting:livechat']}>
                                <ManagerNotificationView channelId={channelId!}/>
                            </PrivateRoute>
                        </>
                    )} />
                    <Route path="dashboard/*" element={(
                        <>
                            <ChatMenu channelId={channelId!} managerUserId={manager.userId} />
                            <PrivateRoute permissions={['default:livechat', 'setting:livechat']}>
                                <ManagerDashboardView channelId={channelId!}/>
                            </PrivateRoute>
                        </>
                    )} />
                    <Route path=":liveChatId" element={(
                        <>
                            <ChatMenu channelId={channelId!} managerUserId={manager.userId} />
                            <ChatListMenu channelId={channelId!} managerUserId={manager.userId} />
                            <PrivateRoute permissions={['default:livechat']}>
                                <ChatView />
                            </PrivateRoute>
                        </>
                    )} />
                    <Route path=":liveChatId/full" element={(
                        <PrivateRoute permissions={['default:livechat']}>
                            <ChatFullSizeView />
                        </PrivateRoute>
                    )} />
                </Route>
                <Route path="notifications/*" element={(
                    <PrivateRoute permissions={['default:livechat']}>
                        {channelId && managers &&
                            <ChannelNotificationView channelId={channelId}
                                                     managerUserId={manager.userId}
                                                     managers={managers}/>
                        }
                    </PrivateRoute>
                )} />
                <Route path="settings/*" element={(
                    <>
                        <SettingMenu channelId={channelId!} />
                        <PrivateRoute permissions={['default:livechat', 'setting:livechat']}>
                            <SettingView />
                        </PrivateRoute>
                    </>
                )} />
                <Route path="members/*" element={(
                    <PrivateRoute permissions={['setting:member']}>
                        <MemberView />
                    </PrivateRoute>
                )} />
                {/*<Route path="boards/*" element={(*/}
                {/*    <Suspense fallback={<FullAreaSpinner />}>*/}
                {/*        <PrivateRoute permission="write:board">*/}
                {/*            <views.boardListView />*/}
                {/*        </PrivateRoute>*/}
                {/*    </Suspense>*/}
                {/*)} />*/}
                <Route path="dashboard/*" element={(
                    <>
                        {/*<DashboardMenu channelId={channelId!}/>*/}
                        <PrivateRoute permissions={['default:analytics']}>
                            <DashboardView />
                        </PrivateRoute>
                    </>
                )} />
            </SentryRoutes>
        </div>
    )
};

const AppTitlePortal: React.FC<{title?: string}> = ({title}) => {
    if (electronRuntime) {
        return ReactDOM.createPortal((<>{title ?? '스윙챗'}</>), document.getElementById('app-title')!);
    }
    else {
        return null;
    }
}

const ManagerModal: React.FC<{channel: Channel}> = ({channel}) => {
    const intl = useIntl();
    const modal = useModal();
    const navigate = useNavigate();

    const [invalid, setInvalid] = useState({name: false});

    const nameRef = useRef<HTMLInputElement>(null);

    const {mutate: createManager, isLoading} = useCreateManager(channel._id);

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const name = nameRef.current?.value;
        if (!name) {
            setInvalid({name: true});
            return;
        }
        else {
            setInvalid({name: false});
        }

        createManager(name, {
            onSuccess: (_) => {
                modal.close(MANAGER_CREATE_MODAL_ID);
            },
            onError: (_) => {
                toast.error(intl.formatMessage({id: 'i000090'}));
            }
        });
    }

    const onClose = () => {
        modal.close(MANAGER_CREATE_MODAL_ID);
        navigate('/channels', {replace: true});
    }

    return (
        <MaruModal id={MANAGER_CREATE_MODAL_ID} title={`${channel.name} ${intl.formatMessage({id: 'i000088'})}`} backdrop="static" onClose={onClose}>
            <Modal.Body>
                <Form id="manager-form" onSubmit={onSubmit}>
                    <Form.Group className="mb-1">
                        <Form.Label>{intl.formatMessage({id: 'i000087'})}</Form.Label>
                        <Form.Control type="text" ref={nameRef} isInvalid={invalid.name} placeholder={intl.formatMessage({id: 'i000089'})} />
                        <Form.Control.Feedback type="invalid">{intl.formatMessage({id: 'i000024'})}</Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit"
                        className="btn-rounded"
                        form="manager-form"
                        disabled={isLoading}>
                    {intl.formatMessage({id: 'i000040'})}
                </Button>
            </Modal.Footer>
        </MaruModal>
    );
};

export default ChannelContextView;
