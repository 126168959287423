import oauthAxios from "../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../core/variables";

class ManagerFeedRepository {

    async find(channelId: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/feeds`)
    }
}

export default new ManagerFeedRepository();
