import {useQuery} from "@tanstack/react-query";
import {Message} from "../../model/message";
import {LiveChatError, toLiveChatError} from "../../error";
import messageRepository from "../../repository/MessageRepository";


const getData = async (channelId: string) => {
    try {
        const {data} = await messageRepository.findUnreadMessages(channelId);
        return data.result;
    } catch (e) {
        throw toLiveChatError(e);
    }
}


const useUnreadMessages = (channelId: string) => {
    return useQuery<{[key: string]: Message[]}, LiveChatError>(['unreadMessages', channelId], async () => getData(channelId), {suspense: false});
};


export default useUnreadMessages;