import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import {LiveChatError, toLiveChatError} from "../../error";
import {CustomSnippetUpdate} from "../../model/customSnippet";
import customSnippetRepository from "../../repository/CustomSnippetRepository";

const useUpdateCustomSnippet = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, LiveChatError, CustomSnippetUpdate>({
        mutationKey: ['snippets', 'update', channelId],
        mutationFn: async (body: CustomSnippetUpdate) => {
            try {
                return await customSnippetRepository.update(channelId, body._id, body);
            } catch (e) {
                throw toLiveChatError(e);
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(['snippets', channelId], {exact: true});
        }
    })
};

export default useUpdateCustomSnippet;
