import oauthAxios from "../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../core/variables";


class UserEventsRepository {

    async findChatAccessEvents(channelId: string, userId: string, offset: number, limit: number) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/chat-users/${userId}/events/chat-access`, {
            params: {offset, limit}
        });
    }
}

export default new UserEventsRepository();
