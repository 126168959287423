import React, {useEffect, useMemo} from "react";
import {useParams} from "react-router-dom";
import styled from "styled-components";
import LiveChatUI from "../../component/chat/LiveChatUI";
import ChatPanels from "./ChatPanels";
import useLiveChat from "../../query/liveChat/useLiveChat";
import useLiveChatsHandler from "../../hook/useLiveChatsHandler";
import {SocketEvent} from "../../socket";
import useLiveChatHandler from "../../hook/useLiveChatHandler";
import useSocketIOSubscribe from "../../socket/useSocketIOSubscribe";
import useLiveChatBulkActionHandler from "../../hook/useLiveChatBulkActionHandler";

const ChatView: React.FC = () => {
    const {channelId, liveChatId} = useParams<{channelId: string, liveChatId: string}>();

    const liveChatsHandler = useLiveChatsHandler(channelId!);
    const {data: liveChat} = useLiveChat(channelId!, liveChatId!);
    const liveChatHandler = useLiveChatHandler(channelId!);

    const {isBulkMode, disableBulkMode} = useLiveChatBulkActionHandler(channelId!);

    const _liveChat = useMemo(() => {
        return liveChat ?? liveChatsHandler.getLiveChat(liveChatId!);
    }, [liveChatId, liveChatsHandler, liveChat]);

    useEffect(() => {
        const latestLiveChatRaw = window.localStorage.getItem('LLC');
        try {
            const latestLiveChat = latestLiveChatRaw ? JSON.parse(latestLiveChatRaw) : {};
            latestLiveChat[channelId!] = liveChatId;
            window.localStorage.setItem('LLC', JSON.stringify(latestLiveChat));
        } catch (e) {
            window.localStorage.setItem('LLC', JSON.stringify({[channelId!]: liveChatId}));
        }

    }, [channelId, liveChatId]);

    useSocketIOSubscribe(SocketEvent.RECONNECT, async () => liveChatHandler.refetchLiveChat(liveChatId!).finally());

    return (
        <LiveChatContentStyle dimmed={isBulkMode} onClick={() => {disableBulkMode()}}>
            {_liveChat && (
                <>
                    <LiveChatUI channelId={channelId!} liveChat={_liveChat} />
                    <ChatPanels liveChat={_liveChat}/>
                </>
            )}
        </LiveChatContentStyle>
    );
};


const LiveChatContentStyle = styled.div<{dimmed: boolean}>`
  background-color: #f4f5f7;
  display: flex;
  flex-grow: 1;
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    content: "";
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;
    visibility: ${props => props.dimmed ? "visible" : "hidden"};
    opacity: ${props => props.dimmed ? "1" : "0"};
    transition: opacity 0.2s ease-in-out;
  }
`

export default ChatView;
