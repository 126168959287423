import {LiveChat} from "../../model/livechat";
import {useInfiniteQuery} from "@tanstack/react-query";
import {LiveChatError, toLiveChatError} from "../../error";
import {Page} from "../index";
import oauthAxios from "../../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../../core/variables";


/**
 * 읽지 않은 메시지가 있는 상담목록
 **/

export const getData = async (channelId: string, pageable: {since?: string, limit: number}, params?: {sortBy?: string}) => {
    try {
        const {data} = await oauthAxios.get(`${LIVE_CHAT_API_HOST}/v2/channels/${channelId}/livechats/unread`, {
            params: {sortBy: params?.sortBy, since: pageable.since, limit: pageable.limit}
        });

        const liveChats: LiveChat[] = data.result;

        return {
            result: liveChats,
            pageable: {
                ...pageable,
                isLast: liveChats.length < pageable.limit
            }
        };
    } catch (e) {
        throw toLiveChatError(e);
    }

}

const useUnreadLiveChats = (channelId: string, sortBy?: string) => {
    return useInfiniteQuery<Page<LiveChat, {since?: string}>, LiveChatError>({
        queryKey: ['liveChats', 'unread', channelId],
        queryFn: ({pageParam = {limit: 50, isLast: false}}) => getData(channelId, pageParam, {sortBy}),
        refetchOnMount: true,
        getNextPageParam: ({pageable}, pages) => {
            if (pageable.isLast) {
                return undefined;
            }

            const lastPageResult = pages.length > 0 ? (pages[pages.length - 1].result ?? []) : [];

            return {
                since: lastPageResult.length > 0 ? lastPageResult[lastPageResult.length - 1].since : undefined,
                limit: pageable.limit,
                isLast: false
            };
        }
    });
};


export default useUnreadLiveChats;