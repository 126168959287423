import React from "react";
import {ButtonData} from "../../model/chatbot";
import {Button} from "react-bootstrap";
import classNames from "classnames";

const ButtonMessage: React.FC<ButtonMessageProps> = ({data, disabled, className}) => {

    return (
        <Button variant="outline-primary" type={'button'} className={classNames('shadow-none', {className})} disabled={disabled}>{data.label}</Button>
    );
};

type ButtonMessageProps = {
    data: ButtonData;
    disabled?: boolean;
    className?: string;
}

export default ButtonMessage;