import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError, AxiosResponse} from "axios";
import channelRepository from "../../repository/ChannelRepository";


const useCreateApiKey = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, string>(['apiKeys', 'create', channelId], async (name) => {
        try {
            return await channelRepository.createApiKey(channelId, name);
        } catch (e) {
            throw e;
        }
    }, {
        onSuccess: () => {
            return queryClient.invalidateQueries(['apiKeys', channelId], {exact: true});
        }
    });
}

export default useCreateApiKey;
