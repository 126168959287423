import {useQueryClient} from "@tanstack/react-query";
import {useCallback} from "react";
import {Message} from "../../model/message";


const useDeleteUnreadMessage = () => {
    const queryClient = useQueryClient();

    return useCallback(async (channelId: string, liveChatId: string, messageIds: string[], onDelete?: (remainingUnreadMessages: Message[]) => Promise<void>) => {
        let remainingUnreadMessages: Message[] = [];
        queryClient.setQueryData<{[key: string]: Message[]}>(['unreadMessages', channelId], (oldData) => {
            const nonNullOldData = oldData ?? {[liveChatId]: []};
            remainingUnreadMessages = nonNullOldData[liveChatId].filter(message => !messageIds.includes(message._id));
            return {
                ...nonNullOldData,
                [liveChatId]: remainingUnreadMessages
            }
        });

        await onDelete?.(remainingUnreadMessages);
    }, [queryClient]);
};


export default useDeleteUnreadMessage;