import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError, AxiosResponse} from "axios";
import managerRepository from "../../repository/ManagerRepository";


const useSetManagerAway = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, string>(['manager', 'away', 'update', channelId], async (managerId: string) => {
        try {
            return await managerRepository.setAway(channelId, managerId);
        } catch (e) {
            throw e;
        }
    }, {
        onSuccess: () => {
            return Promise.all([
                queryClient.invalidateQueries(['manager', 'away', 'update', channelId], {exact: true}),
                queryClient.invalidateQueries(['manager', channelId], {exact: true})
            ]);
        }
    });
}

export default useSetManagerAway;
