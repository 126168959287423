import React, {useEffect, useMemo, useRef} from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import logoImage from "../../assets/images/swingchat_logo.png";
import {
    APP_LOGIN_URI,
    APP_NAME,
    APP_TITLE,
    ELECTRON_AUTHORIZATION_REDIRECT_URL,
    KEEPER_API_HOST,
    KEEPER_CLIENT_ID,
    WEB_HOST
} from "../../core/variables";
import * as uuid from "uuid";
import qs from "qs";
import {useLocation} from "react-router-dom";
import electronRuntime from "../../core/electronRuntime";
import classNames from "classnames";
import FullAreaSpinner from "../../component/FullAreaSpinner";
import {useRecoilValue} from "recoil";
import deepLinkQueriesState from "../../recoil/deepLinkQueries";

const LoginView: React.FC = () => {
    const location = useLocation();

    const loginFormRef = useRef<HTMLFormElement>(null);
    const tokenRef = useRef<HTMLInputElement>(null);
    const queriesRef = useRef<HTMLInputElement>(null);

    const deepLinkQueries = useRecoilValue(deepLinkQueriesState);

    const loginUrl = useMemo(() => {
        const loginParams = {
            app: process.env.REACT_APP_NAME,
            client: electronRuntime ? 'desktop' : 'browser'
        };
        return `${APP_LOGIN_URI}?${qs.stringify(loginParams)}`;
    }, []);

    const authorizationRedirectUri = useMemo(() => {
        // Electron run with file
        // return electronRuntime ? 'http://localhost:4000/authorize' : `${process.env.REACT_APP_LIVECHAT_API_HOST}/v1/auth/authorize`;
        // Electron run with url
        // return electronRuntime ? `${WEB_HOST}/authorize` : `${process.env.REACT_APP_LIVECHAT_API_HOST}/v1/auth/authorize`;

        return electronRuntime ? ELECTRON_AUTHORIZATION_REDIRECT_URL : `${process.env.REACT_APP_LIVECHAT_API_HOST}/v1/auth/authorize`;
    }, []);

    const queries: any = useMemo(() => {
        return qs.parse(location.search.replace('?', ''));
    }, [location]);

    useEffect(() => {
        if (electronRuntime) {
            electronRuntime.send('checking-for-keeper-logged', 'ping');
        }
        else {
            // 브라우저 로그인
            if (queries.magic_token && tokenRef.current && loginFormRef.current) {
                tokenRef.current.value = queries.magic_token;
                loginFormRef.current.submit();
            }
        }

        const unsubscribe = electronRuntime ? electronRuntime.subscribe('keeper-login', (event: any, magicToken: string) => {
            // electron 로그인
            if (tokenRef.current && loginFormRef.current) {
                tokenRef.current.value = magicToken;
                loginFormRef.current.submit();
            }
        }) : undefined;


        return () => {
            unsubscribe?.();
        }
    }, [queries]);

    // 딥링크 쿼리를 로그인 요청에 포함
    useEffect(() => {
        if (deepLinkQueries && queriesRef.current) {
            queriesRef.current.value = JSON.stringify(deepLinkQueries)
        }
    }, [deepLinkQueries, queriesRef])

    const onClickLoginBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        if (electronRuntime) {
            electronRuntime.send('open-login', `${loginUrl}`);
        }
        else {
            window.location.href = loginUrl;
        }
    }

    return (
        <Row className="h-100">
            <Col xs={11} lg={6} xl={5} className="mx-auto h-100">
                <div className="d-flex align-items-center h-100 w-100 justify-content-center">
                    {(!electronRuntime && queries.magic_token) && <FullAreaSpinner />}
                    <Card border="secondary" className={classNames('border flex-grow-1', {'d-none': !electronRuntime && queries.magic_token})}>
                        <Card.Body className="d-flex flex-column justify-content-between p-5">
                            <h3><img src={logoImage} height={32} className="mr-1" alt="logo" />{APP_TITLE}</h3>
                            <Card.Title className="mb-4">로그인이 필요합니다. 버튼을 눌러서 로그인을 해주세요.</Card.Title>
                            <div className="d-grid">
                                <Button variant="primary" onClick={onClickLoginBtn}>로그인</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>

                <form action={`${KEEPER_API_HOST}/auth/apps/${APP_NAME}/login`} method="post" ref={loginFormRef}>
                    <input type="hidden" name="magic_token" ref={tokenRef}/>
                    <input type="hidden" name="client_id" value={KEEPER_CLIENT_ID} />
                    <input type="hidden" name="response_type" value="code"/>
                    <input type="hidden" name="redirect_uri" value={authorizationRedirectUri}/>
                    <input type="hidden" name="scope" value="openid profile email"/>
                    <input type="hidden" name="queries" ref={queriesRef}/>
                    <input type="hidden" name="state" value={uuid.v4()}/>
                    <input type="hidden" name="nonce" value={uuid.v4()}/>
                    <input type="hidden" name="error_redirect_url" value={`${WEB_HOST}/unauthorized`} />
                    {/*{*/}
                    {/*    Object.entries(loginFormData).map(entry => <input key={entry[0]} type={'hidden'} name={entry[0]} value={String(entry[1]) || ''} />)*/}
                    {/*}*/}
                </form>
            </Col>
        </Row>
    );

    // if (!electronRuntime && queries.magic_token) {
    //     return (
    //         <div className="h-100 w-100 d-flex align-items-center justify-content-center">
    //             <Spinner animation="border" />
    //             <form action={`${KEEPER_API_HOST}/auth/apps/${APP_NAME}/login`} method={'post'} ref={loginFormRef}>
    //                 <input type={'hidden'} name={'magic_token'} ref={tokenRef}/>
    //                 <input type={'hidden'} name={'client_id'} value={KEEPER_CLIENT_ID} />
    //                 <input type={'hidden'} name={'response_type'} value={'code'}/>
    //                 <input type={'hidden'} name={'redirect_uri'} value={redirectUri}/>
    //                 <input type={'hidden'} name={'scope'} value={'openid profile email'}/>
    //                 <input type={'hidden'} name={'state'} value={uuid.v4()}/>
    //                 <input type={'hidden'} name={'nonce'} value={uuid.v4()}/>
    //                 <input type={'hidden'} name={'error_redirect_url'} value={`${WEB_HOST}/unauthorized`} />
    //                 {/*{*/}
    //                 {/*    Object.entries(loginFormData).map(entry => <input key={entry[0]} type={'hidden'} name={entry[0]} value={String(entry[1]) || ''} />)*/}
    //                 {/*}*/}
    //             </form>
    //         </div>
    //     );
    // }
    // else {
    //
    // }

    // if (electronRuntime) {
    //     return (
    //         <Row>
    //             <Col xs={11} lg={6} xl={5} className="mx-auto pt-5">
    //                 <Card border={'secondary'} className="mt-5 border">
    //                     <Card.Body className="d-flex flex-column justify-content-between p-5">
    //                         <h3><img src={logoImage} height={32} className="mr-1" alt="logo" />{APP_TITLE}</h3>
    //                         <Card.Title className="mb-4">로그인이 필요합니다. 버튼을 눌러서 로그인을 해주세요.</Card.Title>
    //                         <Button variant="primary" block onClick={onClickLoginBtn}>로그인</Button>
    //                     </Card.Body>
    //                 </Card>
    //                 <form action={`${KEEPER_API_HOST}/auth/apps/${APP_NAME}/login`} method={'post'} ref={loginFormRef}>
    //                     <input type={'hidden'} name={'magic_token'} ref={tokenRef}/>
    //                     <input type={'hidden'} name={'client_id'} value={KEEPER_CLIENT_ID} />
    //                     <input type={'hidden'} name={'response_type'} value={'code'}/>
    //                     <input type={'hidden'} name={'redirect_uri'} value={"http://localhost:4000/authorize"}/>
    //                     <input type={'hidden'} name={'scope'} value={'openid profile email'}/>
    //                     <input type={'hidden'} name={'state'} value={uuid.v4()}/>
    //                     <input type={'hidden'} name={'nonce'} value={uuid.v4()}/>
    //                     <input type={'hidden'} name={'error_redirect_url'} value={`${WEB_HOST}/unauthorized`} />
    //                     {/*{*/}
    //                     {/*    Object.entries(loginFormData).map(entry => <input key={entry[0]} type={'hidden'} name={entry[0]} value={String(entry[1]) || ''} />)*/}
    //                     {/*}*/}
    //                 </form>
    //             </Col>
    //         </Row>
    //     );
    // }
    // else {
    //     return null;
    // }
};


export default LoginView;
