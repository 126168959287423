import oauthAxios from "../core/oauthAxios";
import {CustomSnippetCreate, CustomSnippetUpdate} from "../model/customSnippet";
import {LIVE_CHAT_API_HOST} from "../core/variables";

class CustomSnippetRepository {
    async find(channelId: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/snippets`);
    }

    async read(channelId: string, snippetId: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/snippets/${snippetId}`);
    }

    async existed(channelId: string, name: string) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/snippets/existed`, {
            params: { name }
        })
    }

    async create(channelId: string, body: CustomSnippetCreate) {
        return oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/snippets`, body);
    }

    async update(channelId: string, snippetId: string, body: CustomSnippetUpdate) {
        return oauthAxios.put(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/snippets/${snippetId}`, body);
    }

    async delete(channelId: string, snippetId: string) {
        return oauthAxios.delete(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/snippets/${snippetId}`);
    }

    async bypass(channelId: string, snippetId: string, params: string) {
        return oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/snippets/${snippetId}/bypass`, {
            params
        })
    }
}

export default new CustomSnippetRepository();
