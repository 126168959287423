import {useQuery} from "@tanstack/react-query";
import notificationSettingRepository from "../../repository/NotificationSettingRepository";
import {NotificationSetting} from "../../model/NotificationSetting";
import {LiveChatError, toLiveChatError} from "../../error";


const getData = async (channelId: string) => {
    try {
        const {data} = await notificationSettingRepository.read(channelId);
        return data.result;
    } catch (e) {
        throw toLiveChatError(e);
    }

}


const useNotificationSetting = (channelId: string) => {
    return useQuery<NotificationSetting, LiveChatError>(['notificationSetting', channelId], async () => getData(channelId), {suspense: false});
};


export default useNotificationSetting;