import React, {useCallback, useMemo, useState} from "react";
import {Moment} from "moment";
import {
    LiveChatBounceRateStatistic,
    LiveChatStatisticSummaryResponse,
    LiveChatTimeHistory,
    QualityChartDataType,
    QualityChartType,
    TotalStatisticSummary
} from "../../model/statistic";
import {useIntl} from "react-intl";
import {Dropdown, DropdownButton, Spinner} from "react-bootstrap";
import TooltipIcon from "../../component/TooltipIcon";
import Chart from "react-apexcharts";
import {ApexOptions} from "apexcharts";
import styled from "styled-components";
import useLiveChatStatisticSummary from "../../query/statistic/useLiveChatStatisticSummary";
import {useParams} from "react-router-dom";
import {Manager} from "../../model/manager";
import {DashboardHelpCardStyle, DiffStatisticSummaryCardStyle, QualityStatusChartStyle} from "./DashboardStyles";

const DISTRIBUTION_RANGE = [180, 600, 1800, 3600];

type QualityStatusDashboardViewProps = {
    liveChatStatisticSummary?: LiveChatStatisticSummaryResponse
    isLoading: boolean
    period: {
        startDate: Moment
        endDate: Moment
    },
    previousPeriod: {
        startDate: Moment
        endDate: Moment
    }
    manager?: Manager
}

const QualityStatusDashboardView: React.FC<QualityStatusDashboardViewProps> = (props) => {
    const { liveChatStatisticSummary, isLoading, period, previousPeriod, manager } = props;

    const intl = useIntl();
    const {channelId} = useParams<{channelId: string}>();

    const { data: previousPeriodSummary } = useLiveChatStatisticSummary(
        channelId!,
        {
            startDate: previousPeriod.startDate.toISOString(),
            endDate: previousPeriod.endDate.toISOString(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            managerUserId: manager?.userId
        }
    )

    const previousPeriodToString = useMemo(() => {
        const start = previousPeriod.startDate.local().format("YYYY.MM.DD")
        const end = previousPeriod.endDate.local().format("YYYY.MM.DD")
        return `${start} ~ ${end}`;
    }, [previousPeriod])

    const statisticCategoryList = useMemo(() => {
        return [
            { key: "mean", name: intl.formatMessage({id: "i200026"}) },
            { key: "median", name: intl.formatMessage({id: "i200027"}) },
        ]
    }, [intl])

    const searchCoverageList = useMemo(() => {
        return [
            { key: "entire", name: intl.formatMessage({id: "i200028"}) },
            { key: "working", name: intl.formatMessage({id: "i200029"}) },
        ]
    }, [intl])

    const [statisticCategory, setStatisticCategory] = useState(statisticCategoryList[0]);
    const [searchCoverage, setSearchCoverage] = useState(searchCoverageList[0]);

    const xAxisCategories = useMemo(() => {
        const startDate = period.startDate.clone()
        const endDate = period.endDate.clone()
        const daysLength = Number(endDate.diff(startDate, 'days', true).toFixed(0))

        return Array.from({length: daysLength}).map((_, dayIndex) => {
            const date = startDate.clone().add(dayIndex, 'days')
            return date.local().format("MM-DD")
        })
    }, [period]);

    const distributionChartCategory = useMemo(() => {
        return [
            intl.formatMessage({id: "i200040"}),
            intl.formatMessage({id: "i200041"}),
            intl.formatMessage({id: "i200042"}),
            intl.formatMessage({id: "i200043"}),
            intl.formatMessage({id: "i200044"})
        ]
    }, [intl])

    const totalStatistics = useMemo(() => {
        const parser = (total: TotalStatisticSummary|undefined) => {
            if (!total) {
                return { value: 0, count: 0 }
            }

            if (searchCoverage.key === 'entire') {
                return {
                    value: statisticCategory.key === 'mean' ? total.entire.average : total.entire.median,
                    count: total.entire.count
                }
            } else {
                return {
                    value: statisticCategory.key === 'mean' ? total.working.average : total.working.median,
                    count: total.working.count
                }
            }
        }
        const acceptStatistic = parser(liveChatStatisticSummary?.accept.totalStatistics);
        const previousAcceptStatistic = parser(previousPeriodSummary?.accept.totalStatistics);

        const responseStatistic = parser(liveChatStatisticSummary?.response.totalStatistics);
        const previousResponseStatistic = parser(previousPeriodSummary?.response.totalStatistics);

        const handleStatistic = parser(liveChatStatisticSummary?.handle.totalStatistics);
        const previousHandleStatistic = parser(previousPeriodSummary?.handle.totalStatistics);

        const firstRespondStatistic = parser(liveChatStatisticSummary?.firstRespond.totalStatistics);
        const previousFirstRespondStatistic = parser(previousPeriodSummary?.firstRespond.totalStatistics);

        const durationStatistic = parser(liveChatStatisticSummary?.duration.totalStatistics);
        const previousDurationStatistic = parser(previousPeriodSummary?.duration.totalStatistics);

        const acwStatistic = parser(liveChatStatisticSummary?.acw.totalStatistics);
        const previousACWStatistic = parser(previousPeriodSummary?.acw.totalStatistics);

        const userTalkTimeStatistic = parser(liveChatStatisticSummary?.userTalk.totalStatistics);
        const previousUserTalkTimeStatistic = parser(previousPeriodSummary?.userTalk.totalStatistics);

        const bounceRateStatistic = parser(liveChatStatisticSummary?.bounceRate.totalStatistics);
        const previousBounceRateStatistic = parser(previousPeriodSummary?.bounceRate.totalStatistics);

        return {
            accept: {
                value: acceptStatistic.value,
                previousValue: previousAcceptStatistic.value,
                count: acceptStatistic.count
            },
            response: {
                value: responseStatistic.value,
                previousValue: previousResponseStatistic.value,
                count: responseStatistic.count
            },
            handle: {
                value: handleStatistic.value,
                previousValue: previousHandleStatistic.value,
                count: handleStatistic.count
            },
            firstRespond: {
                value: firstRespondStatistic.value,
                previousValue: previousFirstRespondStatistic.value,
                count: firstRespondStatistic.count
            },
            duration: {
                value: durationStatistic.value,
                previousValue: previousDurationStatistic.value,
                count: durationStatistic.count
            },
            acw: {
                value: acwStatistic.value,
                previousValue:previousACWStatistic.value,
                count: acwStatistic.count
            },
            userTalk: {
                value: userTalkTimeStatistic.value,
                previousValue: previousUserTalkTimeStatistic.value,
                count: userTalkTimeStatistic.count
            },
            bounceRate: {
                value: bounceRateStatistic.value * 100,
                previousValue: previousBounceRateStatistic.value * 100,
                count: bounceRateStatistic.count
            }
        }
    }, [liveChatStatisticSummary, previousPeriodSummary, searchCoverage, statisticCategory]);

    const chartDataList = useMemo<QualityChartDataType[]>(() => {
        return [
            {
                title: intl.formatMessage({id: "i200018"}),
                description: intl.formatMessage({id: "i200049"}),
                distributionTitle: intl.formatMessage({id: "i200021"}, {count: totalStatistics.accept.count}),
                statisticType: "time",
                chartType: "accept-chart",
                statisticToShow: totalStatistics.accept
            },
            {
                title: intl.formatMessage({id: "i200061"}),
                description: intl.formatMessage({id: "i200063"}),
                distributionTitle: intl.formatMessage({id: "i200062"}, {count: totalStatistics.userTalk.count}),
                statisticType: "time",
                chartType: "user-talk-chart",
                statisticToShow: totalStatistics.userTalk
            },
            {
                title: intl.formatMessage({id: "i200019"}),
                description: intl.formatMessage({id: "i200050"}),
                distributionTitle: intl.formatMessage({id: "i200022"}, {count: totalStatistics.response.count}),
                statisticType: "time",
                chartType: "response-chart",
                statisticToShow: totalStatistics.response
            },
            // {
            //     title: intl.formatMessage({id: "i200031"}),
            //     description: intl.formatMessage({id: "i200052"}),
            //     distributionTitle: intl.formatMessage({id: "i200032"}, {count: totalStatistics.firstRespond.count}),
            //     statisticType: "time",
            //     chartType: "first-respond-chart",
            //     statisticToShow: totalStatistics.firstRespond
            // },
            {
                title: intl.formatMessage({id: "i200033"}),
                description: intl.formatMessage({id: "i200053"}),
                distributionTitle: intl.formatMessage({id: "i200034"}, {count: totalStatistics.duration.count}),
                statisticType: "time",
                chartType: "duration-chart",
                statisticToShow: totalStatistics.duration
            },
            // {
            //     title: intl.formatMessage({id: "i200020"}),
            //     description: intl.formatMessage({id: "i200051"}),
            //     distributionTitle: intl.formatMessage({id: "i200023"}, {count: totalStatistics.handle.count}),
            //     statisticType: "time",
            //     chartType: "handle-chart",
            //     statisticToShow: totalStatistics.handle
            // },
            {
                title: intl.formatMessage({id: "i200067"}),
                description: intl.formatMessage({id: "i200054"}),
                distributionTitle: intl.formatMessage({id: "i200035"}),
                statisticType: "percent",
                chartType: "bounce-rate-chart",
                statisticToShow: totalStatistics.bounceRate
            },
            // {
            //     title: intl.formatMessage({id: "i200069"}),
            //     description: intl.formatMessage({id: "i200070"}),
            //     distributionTitle: intl.formatMessage({id: "i200071"}, {count: totalStatistics.acw.count}),
            //     statisticType: "time",
            //     chartType: "acw-chart",
            //     statisticToShow: totalStatistics.acw
            // }
        ]
    }, [intl, totalStatistics]);

    const getRateStatistic = useCallback((statistic: LiveChatBounceRateStatistic, coverage: string) => {
        return {
            value: coverage === 'entire' ? statistic.bounceRate : statistic.workingBounceRate
        }
    }, [])

    const bounceRateChartSeries = useMemo(() => {
        if (liveChatStatisticSummary) {
            return [{
                name: intl.formatMessage({id: "i200067"}),
                data: liveChatStatisticSummary.bounceRate.statistics.map(statistic => {
                    const { value: statisticValue } = getRateStatistic(statistic, searchCoverage.key);
                    return Number(statisticValue.toFixed(1));
                })
            }] as ApexAxisChartSeries
        } else {
            return [{
                name: intl.formatMessage({id: "i200008"}),
                data: []
            }] as ApexAxisChartSeries
        }
    }, [liveChatStatisticSummary, getRateStatistic, intl, searchCoverage.key]);

    const bounceRateChartOptions = useMemo(() => {
        let maxValue = 50
        if (liveChatStatisticSummary) {
            maxValue = Math.max(...liveChatStatisticSummary.bounceRate.statistics.map(statistic => {
                        return statistic.bounceRate;
            })) < 50 ? 50 : 100;
        }

        return {
            chart: {
                id: 'bounce-rate-chart-line',
                type: 'line',
                height: 350,
                width: 600,
                zoom: { enabled: false },
                toolbar: { show: false }
            },
            markers: { size: 5 },
            dataLabels: { enabled: false },
            colors: ["#656565"],
            grid: {
                show: true,
                position: "back",
            },
            xaxis: {
                categories: xAxisCategories,
                tickAmount: 5,
                labels: {
                    rotate: 0,
                    hideOverlappingLabels: true
                },
                axisBorder: {
                    show: true,
                    color: "#868686",
                },
            },
            yaxis: {
                title: { text: intl.formatMessage({id: "i200036"}) },
                min: 0,
                max: maxValue,
                tickAmount: 5,
                labels: {
                    formatter: val => `${val.toFixed(1)}`
                }
            },
            tooltip: {
                shared: true,
                followCursor: true,
                y: {
                    formatter: val => `${val.toFixed(1)}%`
                }
            },
            stroke: {
                curve: 'straight',
                width: 3
            },
        } as ApexOptions
    }, [liveChatStatisticSummary, xAxisCategories, intl])

    const generateBarChartSeries = useCallback((chartType: QualityChartType) => {
        if (liveChatStatisticSummary) {
            let histories: LiveChatTimeHistory[]

            switch (chartType) {
                case "accept-chart":
                    histories = liveChatStatisticSummary.accept.histories;
                    break
                case "user-talk-chart":
                    histories = liveChatStatisticSummary.userTalk.histories;
                    break
                case "response-chart":
                    histories = liveChatStatisticSummary.response.histories;
                    break
                case "duration-chart":
                    histories = liveChatStatisticSummary.duration.histories;
                    break
                case "bounce-rate-chart":
                    return
                // case "first-respond-chart":
                //     histories = liveChatStatisticSummary.firstRespond.histories;
                //     break
                // case "handle-chart":
                //     histories = liveChatStatisticSummary.handle.histories;
                //     break
                // case "acw-chart":
                //     histories = liveChatStatisticSummary.acw.histories;
                //     break*/
            }

            const timesDistribution = [0, 0, 0, 0, 0];
            let totalResponses = 0;

            const times = histories.map(history => {
                return searchCoverage.key === 'entire' ? history.time : history.workingTime;
            });

            times.forEach(time => {
                if (time) {
                    totalResponses += 1;
                    if (time < DISTRIBUTION_RANGE[0]) {
                        timesDistribution[0] += 1
                    } else if (time < DISTRIBUTION_RANGE[1]) {
                        timesDistribution[1] += 1
                    } else if (time < DISTRIBUTION_RANGE[2]) {
                        timesDistribution[2] += 1
                    } else if (time < DISTRIBUTION_RANGE[3]) {
                        timesDistribution[3] += 1
                    } else {
                        timesDistribution[4] += 1
                    }
                }
            })
            return [{
                name: intl.formatMessage({id: "i200037"}),
                data: timesDistribution.map(value => totalResponses ? value / totalResponses * 100 : 0)
            }]
        } else {
            return [{
                name: intl.formatMessage({id: "i200008"}),
                data: []
            }]
        }
    }, [liveChatStatisticSummary, intl, searchCoverage.key])

    const generateBarChartOption = useCallback((chartType: QualityChartType) => {
        const commonOptions: ApexOptions = {
            chart: {
                id: `default-distribution-${chartType}`,
                type: 'bar',
                height: 350,
                width: 600,
                toolbar: { show: false },
            },
            colors: ["#FFBC00D9"],
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                    barHeight: '50%',
                    dataLabels: { total: { enabled: true } }
                }
            },
            dataLabels: { enabled: true, formatter: (val) => `${Number(val).toFixed(1)}%`},
            xaxis: {
                min: 0,
                max: 100,
                labels: {
                    show: true,
                    formatter: (val) => `${val}%`
                },
                categories: distributionChartCategory
            },
            tooltip: {
                y: {
                    formatter: (val) => `${val.toFixed(2)}%`
                }
            },
        }

        if (commonOptions.chart?.id) {
            commonOptions.chart.id = `${chartType}-bar`
        }

        switch (chartType) {
            case "accept-chart":
                commonOptions.colors = ["#FF9696D9"]
                break
            case "user-talk-chart":
                commonOptions.colors = ["#FF9696D9"]
                break
            case "response-chart":
                commonOptions.colors = ["#FFBC00D9"]
                break
            case "duration-chart":
                commonOptions.colors = ["#818DF9D9"]
                break
            case "bounce-rate-chart":
                commonOptions.colors = ["#656565"]
                break
            // case "first-respond-chart":
            //     commonOptions.colors = ["#FFBC00D9"]
            //     break
            // case "handle-chart":
            //     commonOptions.colors = ["#818DF9D9"]
            //     break
            // case "acw-chart":
            //     commonOptions.colors = ["#8acca2"]
            //     break
        }

        return commonOptions
    }, [distributionChartCategory])

    return (
        <div className="w-100">
            <div className="d-flex mx-3 mt-3 justify-content-start align-items-center">
                <DropdownButton title={statisticCategory.name}
                                variant="light">
                    {statisticCategoryList.map(category => (
                        <Dropdown.Item key={category.key}
                                       onClick={() => setStatisticCategory(category)}>
                            {category.name}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
                <DropdownButton title={searchCoverage.name}
                                className="ms-2"
                                variant="light">
                    {searchCoverageList.map(coverage => (
                        <Dropdown.Item key={coverage.key}
                                       onClick={() => setSearchCoverage(coverage)}>
                            {coverage.name}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
            </div>

            <div className="d-flex justify-content-start align-items-start">
                <DiffStatisticSummaryCardStyle>
                    {chartDataList.map((chartData, index) => {
                        return (
                            <CompareStatisticItem key={index}
                                                  isLoading={isLoading}
                                                  statisticToShow={chartData.statisticToShow}
                                                  title={chartData.title}
                                                  description={chartData.description}
                                                  previousPeriod={previousPeriodToString}
                                                  type={chartData.statisticType}/>
                        )
                    })}
                </DiffStatisticSummaryCardStyle>

                <DashboardHelpCardStyle>
                    <h5 className="mb-2 fw-bold">
                        {intl.formatMessage({id: "i200139"})}
                    </h5>
                    <div className="help-paragraph">
                        <i className="mdi mdi-text-box-outline mr-2"/>
                        <p className="text-dark">
                            {intl.formatMessage({id: "i200140"})}
                        </p>
                    </div>
                    <div className="help-paragraph">
                        <i className="mdi mdi-swap-vertical mr-2"/>
                        <p className="text-dark">
                            {intl.formatMessage({id: "i200141"})}
                        </p>
                    </div>
                    <div className="help-paragraph">
                        <i className="mdi mdi-chart-bar mr-2"/>
                        <p className="text-dark">
                            {intl.formatMessage({id: "i200142"})}
                        </p>
                    </div>
                </DashboardHelpCardStyle>
            </div>
            <QualityChartGridStyle>
                {chartDataList.map((chartData, index) => {
                    return (
                        <QualityStatusChartStyle key={index}>
                            <div className="chart-card">
                                <h5>{chartData.distributionTitle}</h5>
                                {chartData.chartType === "bounce-rate-chart" ?
                                    <Chart type='line' height={280} width={700}
                                           series={bounceRateChartSeries}
                                           options={bounceRateChartOptions}/>
                                    :
                                    <Chart type='bar' height={280} width={700}
                                           series={generateBarChartSeries(chartData.chartType)}
                                           options={generateBarChartOption(chartData.chartType)}/>
                                }
                            </div>
                        </QualityStatusChartStyle>
                    )
                })}
            </QualityChartGridStyle>
        </div>
    )
}

type CompareStatisticItemProps = {
    statisticToShow: {
        previousValue: number
        value: number
        count: number
    }
    isLoading: boolean
    title: string
    description: string
    previousPeriod: string
    type: "percent" | "time"
}

const CompareStatisticItem: React.FC<CompareStatisticItemProps> = (props) => {
    const {
        statisticToShow,
        isLoading,
        title,
        description,
        previousPeriod,
        type
    } = props;
    const intl = useIntl();

    const diff = useMemo(() => {
        const cur = Number(statisticToShow.value.toFixed(type === "time" ? 0 : 1));
        const prev = Number(statisticToShow.previousValue.toFixed(type === "time" ? 0 : 1));

        let diffValue: number|undefined

        if (type === "time") {
            diffValue = (prev === 0 || prev === cur) ? undefined : ((cur - prev) / prev) * 100;
        } else {
            diffValue = cur - prev;
        }

        return {
            value: diffValue,
            color: (diffValue === undefined || diffValue === 0) ? "gray" : (diffValue < 0 ? "green" : "red")
        };
    }, [statisticToShow, type]);

    const diffItem = useMemo(() => {
        let diffAmount = diff.value ? diff.value.toFixed(type === "time" ? 0 : 1) : "-";
        diffAmount = diffAmount.concat(type === "time" ? "%" : "%p");

        return (
            <div className="diff-percent" style={{color: diff.color}}>
                {diff.value !== undefined && diff.value < 0 && (<i className="mdi mdi-arrow-down"/>)}
                {diff.value !== undefined && diff.value > 0 && (<i className="mdi mdi-arrow-up"/>)}
                {(type === "time" && diff.value && diff.value > 1000) ? intl.formatMessage({id: "i200068"}) : diffAmount}
            </div>
        );
    }, [diff, type, intl]);

    const secondsToDHMS = useCallback((seconds: number) => {
        const day = Math.floor(seconds / (3600 * 24));
        const hour = Math.floor(seconds % (3600 * 24) / 3600);
        const minute = Math.floor(seconds % 3600 / 60);
        const second = Math.floor(seconds % 60);

        const dayFormat = day > 0 ? intl.formatMessage({id: "i200045"}, {day: day}) : "";
        const hourFormat = (day <= 10 && hour > 0) ? intl.formatMessage({id: "i200046"}, {hour: hour}) : "";
        const minuteFormat = (day === 0 && minute > 0)? intl.formatMessage({id: "i200047"}, {minute: minute}) : "";
        const secondFormat = (day === 0 && hour === 0 && second > 0) ? intl.formatMessage({id: "i200048"}, {second: second}) : "";
        const result = dayFormat + hourFormat + minuteFormat + secondFormat;

        return result ? result.trim() : intl.formatMessage({id: "i200048"}, {second: 0});
    }, [intl]);

    return (
        <CompareStatisticItemStyle>
            <div className="title">
                <h5>{title}</h5>
                <TooltipIcon className="ml-1"
                             tooltip={description}
                             options={{placement: "right"}}/>
            </div>
            <div className="compare-previous-current-card">
                <div className="current-statistic">
                    <div className="current-value">
                        {isLoading ?
                            <Spinner animation="border" size="sm"/>
                            :
                            <>
                                {type === "time" && secondsToDHMS(Number(statisticToShow.value.toFixed(0)))}
                                {type === "percent" && `${(statisticToShow.value).toFixed(1)}%`}
                            </>
                        }
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    {diffItem}
                    <div className="font-13 text-muted">
                        ({type === "time"
                            ? secondsToDHMS(Number(statisticToShow.previousValue.toFixed(0)))
                            : `${(statisticToShow.previousValue).toFixed(1)}%`})
                    </div>
                </div>
                <div className="text-muted">
                    {intl.formatMessage({id: "i200064"}, {period: previousPeriod})}
                </div>
            </div>
        </CompareStatisticItemStyle>
    )
};

const CompareStatisticItemStyle = styled.div`
  width: 100%;
  padding: 1.25rem;
  border: 2px solid #e7e7e7;
  border-radius: 15px;
  box-shadow: #f5f5f5 0 0 20px 10px;

  .title {
    display: flex;
    justify-content: start;
    align-items: center;
    h5 {margin: 0;}
  }

  .compare-previous-current-card {
    display: flex;
    flex-direction: column;
    align-items: end;

    .current-statistic {
      display: flex;
      align-items: center;
      
      .current-value {
        font-size: 24px;

        .spinner-border {
          font-size: 11px !important;
        }
      }
    }

    .diff-percent {
      margin-right: 0.25rem;
      font-size: 15px;
    }
  }
`;

const QualityChartGridStyle = styled.div`
  display: grid;
  grid-gap: 2.5rem;
  grid-template-columns: repeat(2, 2fr);
  margin: 1.5rem;
`;

export default QualityStatusDashboardView;
