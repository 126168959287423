import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {Link, NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import Avatar from "./Avatar";
import styled from "styled-components";
import {Dropdown, Form, ListGroup} from "react-bootstrap";
import electronRuntime from "../core/electronRuntime";
import {LIVE_CHAT_API_HOST, QNA_CHATBOT_URL} from "../core/variables";
import logoImage from "../assets/images/swingchat_logo.png";
import useUserPermissions from "../query/useUserPermissions";
import {useRecoilValue, useSetRecoilState} from "recoil";
import platformState from "../recoil/platform";
import useProfile from "../query/useProfile";
import {clearToken} from "../core/token";
import {useDebouncedCallback, useResizeObserver} from "@react-hookz/web";
import {authorizePermissions} from "../util/permissionUtil";
import qnaChatbotState from "../recoil/qnaChatbot";
import useUnreadMessages from "../query/message/useUnreadMessages";
import useManager from "../query/manager/useManager";
import useAnnouncements from "../query/announcement/useAnnouncements";
import {isWithinDaysFromNow} from "../util/datetime";
import useReleaseManagerAway from "../query/managerAway/useReleaseManagerAway";
import useSetManagerAway from "../query/managerAway/useSetManagerAway";
import useUpdateManagerAway from "../query/managerAway/useUpdateManagerAway";
import {AwayReason} from "../model/managerHistory";
import moment from "moment-timezone";

const Menu: React.FC = () => {
    const location = useLocation();
    const intl = useIntl();
    const {channelId} = useParams<{channelId: string}>();

    if (!channelId) {
        throw new Error('Required channelId path params')
    }

    const platform = useRecoilValue(platformState);
    const setChatQnaChatbot = useSetRecoilState(qnaChatbotState);

    const {data: unreadMessagesByLiveChat} = useUnreadMessages(channelId);
    const {data: announcements} = useAnnouncements(channelId);

    const notificationMenuRef = useRef<HTMLDivElement>(null);
    const settingMenuRef = useRef<HTMLDivElement>(null);
    const menuListRef = useRef<HTMLElement[]>([]);

    const {data: permissions} = useUserPermissions(channelId);

    const [indicatorPosition, setIndicatorPosition] = useState<string|undefined>(undefined);

    const liveChatMenuBadgeCount = useMemo(() => {
        return unreadMessagesByLiveChat ? Object.values(unreadMessagesByLiveChat).flat().length : 0
    }, [unreadMessagesByLiveChat]);

    const unreadNotificationCount = useMemo(() => {
        if (announcements) {
            return announcements.filter(announcement => {
                return isWithinDaysFromNow(announcement.createdAt, 7);
            }).length;
        } else {
            return 0;
        }
    }, [announcements])

    const menuList = useMemo(() => {
        return [
            {
                icon: 'uil-comment-dots',
                label: intl.formatMessage({id: 'm02'}),
                to: `/channels/${channelId}/livechats`,
                permission: ['default:livechat'],
                badge: liveChatMenuBadgeCount,
                showBadge: liveChatMenuBadgeCount > 0,
                indicatorColor: '#f4f5f7'
            },
        //     {
        //     icon: ' uil-clipboard-notes',
        //     label: intl.formatMessage({id: 'm05'}),
        //     to: `/channels/${channelId}/boards`,
        //     exact: false,
        //     permission: `write:board`,
        //     indicatorColor: '#ffffff'
        // },
            {
                icon: 'uil-users-alt',
                label: intl.formatMessage({id: 'm04'}),
                to: `/channels/${channelId}/members`,
                permission: ['setting:member'],
                indicatorColor: '#ffffff'
            },
            {
                icon: 'mdi mdi-chart-line',
                label: intl.formatMessage({id: "i200038"}),
                to: `/channels/${channelId}/dashboard`,
                permission: ['default:analytics'],
                indicatorColor: '#ffffff'
            }
        ].filter(menu => authorizePermissions(menu.permission, permissions));
    }, [channelId, intl, permissions, liveChatMenuBadgeCount]);

    // {
    //     icon: 'uil-home-alt',
    //         label: intl.formatMessage({id: 'm01'}),
    //     to: `/`,
    //     exact: true,
    //     permission: `read:channel`
    // },

    const notificationMenu = useMemo(() => {
        return {
            icon: 'mdi-bullhorn-variant-outline',
            label: intl.formatMessage({id: 'm06'}),
            to: `/channels/${channelId}/notifications`,
            permission: ['default:livechat'],
            showBadge: unreadNotificationCount > 0,
        }
    }, [intl, channelId, unreadNotificationCount])

    const settingMenu = useMemo(() => {
        return {
            icon: 'uil-cog',
            label: intl.formatMessage({id: 'm03'}),
            to: `/channels/${channelId}/settings`,
            permission: ['setting:livechat', 'default:livechat']
        };
    }, [intl, channelId]);

    const indicatorColor = useMemo(() => {
        let color = undefined;
        menuList.forEach(menu => {
            if (location.pathname.indexOf(menu.to) === 0) {
                color = menu.indicatorColor;
            }
        });

        return color;
    }, [location, menuList]);

    const updateIndicatorPosition = useCallback((pathname: string) => {
        const topHeight = electronRuntime ? (platform === 'darwin' ? 40 : 32) : 0;

        if (pathname.indexOf(notificationMenu.to) === 0 && notificationMenuRef.current) {
            setIndicatorPosition(`${notificationMenuRef.current.getBoundingClientRect().top - 3 - topHeight}px`); // 'calc(100vh - 140px)';
        } else if (pathname.indexOf(settingMenu.to) === 0 && settingMenuRef.current) {
            setIndicatorPosition(`${settingMenuRef.current.getBoundingClientRect().top - 3 - topHeight}px`); // 'calc(100vh - 140px)';
        }
        else {
            menuList.forEach((menu, index) => {
                if (pathname.indexOf(menu.to) === 0) {
                    setIndicatorPosition(`${menuListRef.current[index].getBoundingClientRect().top - 3 - topHeight}px`);
                }
            });
        }
    }, [menuList, notificationMenu, settingMenu, platform]);

    const cb = useDebouncedCallback(_ => updateIndicatorPosition(location.pathname), [updateIndicatorPosition, location], 100);
    useResizeObserver(document.body, cb);

    useEffect(() => {
        updateIndicatorPosition(location.pathname);
    }, [location, updateIndicatorPosition]);

    const onClickQnaChatbot = (e: React.MouseEvent) => {
        e.preventDefault();

        setChatQnaChatbot(prev => ({...prev, show: !prev.show}));
    }

    return (
        <MenuContainerStyle>
            <LogoStyle>
                <Link to="/channels" className="d-block">
                    <img src={logoImage} alt="logo" width="30" />
                </Link>
            </LogoStyle>
            {menuList.map((menu, index) => (
                location.pathname.indexOf(menu.to) === 0 ? (
                    <div key={menu.to} className="menu-item position-relative" ref={el => {
                        if (el) {
                            menuListRef.current[index] = el;
                        }
                    }}>
                        <i className={`uil ${menu.icon} font-24`} />
                        {menu.showBadge && (
                            <BadgeStyle>{menu.badge < 100 ? menu.badge : '99+'}</BadgeStyle>
                        )}
                    </div>
                ) : (
                    <NavLink key={menu.to} to={menu.to} className="menu-item position-relative" ref={el => el && (menuListRef.current[index] = el)}>
                        <i className={`uil ${menu.icon} font-24`} />
                        {menu.showBadge && (
                            <BadgeStyle>{menu.badge < 100 ? menu.badge : '99+'}</BadgeStyle>
                        )}
                    </NavLink>
                )
            ))}
            <div className="flex-grow-1" />
            {QNA_CHATBOT_URL && (
                <div className="menu-item position-relative">
                    <i className="dripicons-question font-24" role="button" onClick={onClickQnaChatbot}/>
                </div>
            )}
            {location.pathname.indexOf(notificationMenu.to) === 0 ? (
                <div className="menu-item position-relative" ref={notificationMenuRef}>
                    <i className={`mdi ${notificationMenu.icon} font-24`} />
                    {notificationMenu.showBadge && (
                        <BadgeStyle style={{width: "10px", height: "10px", top: "13px"}}/>
                    )}
                </div>
            ) : (
                <NavLink to={notificationMenu.to} className="menu-item position-relative">
                    <i className={`mdi ${notificationMenu.icon} font-24`} />
                    {notificationMenu.showBadge && (
                        <BadgeStyle style={{width: "10px", height: "10px", top: "13px"}}/>
                    )}
                </NavLink>
            )}

            {location.pathname.indexOf(settingMenu.to) === 0 ? (
                <div className="menu-item position-relative mb-3" ref={settingMenuRef}>
                    <i className={`uil ${settingMenu.icon} font-24`} />
                </div>
            ) : (
                <NavLink to={settingMenu.to} className="menu-item position-relative mb-3">
                    <i className={`uil ${settingMenu.icon} font-24`} />
                </NavLink>
            )}

            {indicatorPosition && (
                <MenuIndicator indicatorPosition={indicatorPosition} indicatorColor={indicatorColor}>
                    <div />
                </MenuIndicator>
            )}

            <ProfileFragment channelId={channelId}/>
        </MenuContainerStyle>
    );
};

const ProfileFragment: React.FC<{channelId: string}> = ({channelId}) => {
    const navigate = useNavigate();
    const intl = useIntl()

    const toggleRef = useRef<HTMLDivElement>(null);

    const {data: profile, remove: removeProfile} = useProfile();

    const {data: manager} = useManager(channelId);

    const {mutate: releaseAway, isLoading: awayLoading} = useReleaseManagerAway(channelId)
    const {mutate: setAway} = useSetManagerAway(channelId)

    const onClickLogout = () => {
        localStorage.removeItem('redirect');
        localStorage.removeItem('default_channel');
        localStorage.removeItem('LCM');
        localStorage.removeItem('LLC');

        if (electronRuntime) {
            electronRuntime.invoke('logout').then(async () => {
                removeProfile();
                await clearToken();

                navigate('/', {replace: true});
            });
        }
        else {
            window.location.href = `${LIVE_CHAT_API_HOST}/v1/auth/logout`;
        }
    };

    const onChangeAllowsOfflineEmail = () => {
        if (!manager) return

        if (manager?.away) {
            releaseAway(manager._id)
        } else {
            setAway(manager._id)
        }
    }

    return (
        <ProfileMenuStyle>
            <Dropdown placement="top-start">
                <Dropdown.Toggle id="menu-profile-drop" as="div" bsPrefix="unused" className="cursor-pointer" ref={toggleRef}>
                    <Avatar size={30} name={profile?.nickname ?? ''} />
                </Dropdown.Toggle>
                {/*<PopperDropDownMenu referenceElement={toggleRef.current} placement="top-start">*/}
                <Dropdown.Menu>
                    <div className='dropdown-item d-flex align-items-center'>
                        <i className="mdi mdi-alarm-snooze mr-1 mdi-18px" />
                        <span className='flex-grow-1'>{intl.formatMessage({id: 'i100099'})}</span>
                        <Form.Check type="switch"
                                    className="ml-4"
                                    id="manager-away"
                                    onChange={onChangeAllowsOfflineEmail}
                                    disabled={awayLoading}
                                    checked={!!manager?.away}/>
                    </div>

                    {manager?.away &&
                        <AwayControlItems channelId={channelId}
                                          managerId={manager._id}
                                          reason={manager.away.reason}
                                          until={manager.away.until}/>
                    }

                    <Dropdown.Divider className='mr-2 ml-2'/>
                    <Dropdown.Item as="button" type="button" onClick={onClickLogout} className="notify-item d-flex align-items-center">
                        <i className="mdi mdi-logout mr-1 mdi-18px" />
                        <span>Logout</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
                {/*</PopperDropDownMenu>*/}
            </Dropdown>
        </ProfileMenuStyle>
    );
};

type AwayControlItemsProps = {
    channelId: string,
    managerId: string,
    reason: AwayReason | undefined,
    until: string | undefined
}

const AwayControlItems: React.FC<AwayControlItemsProps> = (props) => {
    const {channelId, managerId, reason, until} = props

    const {mutate: updateAway, isLoading: awayLoading} = useUpdateManagerAway(channelId, managerId)

    const updateReason = useCallback((reason: string) => updateAway({awayReason: reason}), [updateAway])

    const updateUntil = useCallback((until: string) => updateAway({awayUntil: until}), [updateAway])

    return (
        <>
            <ReasonItem update={updateReason} disable={awayLoading} reason={reason}/>
            <UntilItem update={updateUntil} disable={awayLoading} until={until}/>
        </>
    )
}
const REASON_ITEMS: {[key in AwayReason] :string} = {
    'meeting': 'i100101',
    'moving': 'i100102',
    'meal': 'i100103',
    'busy': 'i100104',
    'vacation': 'i100105'
}

const ReasonItem: React.FC<{update: (reason: string) => void, disable: boolean, reason?: AwayReason}> = ({update, disable, reason}) => {
    const intl = useIntl()
    const thisRef = useRef<HTMLButtonElement>(null)

    const [showSubItems, setShowSubItems] = useState(false)

    const expand = () => setShowSubItems(true)
    const shrink = () => setShowSubItems(false)


    const updateReason = useCallback((reason: string) => {
        if (disable) return;
        update(reason)
    }, [disable, update])


    return (
        <Dropdown.Item as="button"
                       type="button"
                       className="notify-item position-relative d-flex align-items-center"
                       ref={thisRef}
                       onMouseEnter={expand}
                       onMouseLeave={shrink}
                       onClick={(e) => e.preventDefault()}>
            <i className="mdi mdi-logout mr-1 invisible" />
            <span className='flex-grow-1'>{intl.formatMessage({id: 'i100100'})}</span>
            <div>
                {reason ? <span>{intl.formatMessage({id: REASON_ITEMS[reason]})}</span> : <i className='uil uil-angle-right'/>}
            </div>
            {thisRef.current &&
                <div className={`position-absolute mb-0 ${!showSubItems && 'd-none'}`}
                     style={{
                         left: thisRef.current.offsetLeft + thisRef.current.offsetWidth,
                         bottom: 0,
                         paddingLeft: '1.5px'
                }}
                >
                    <ListGroup>
                        {Object.entries(REASON_ITEMS).map(([key, i18n]) => (
                            <ListGroup.Item key={`reason-item-${key}`}
                                            className="dropdown-item d-flex align-items-center dropdown-item"
                                            onClick={() => updateReason(key)}
                            >
                                <span className='flex-grow-1'>{intl.formatMessage({id: i18n})}</span>
                                {key === reason && <i className='uil uil-check'/>}
                            </ListGroup.Item>
                        ))}

                    </ListGroup>
                </div>
            }
        </Dropdown.Item>
    )
}

const UntilItem: React.FC<{update: (until: string) => void, disable: boolean, until?: string}> = ({update, disable, until}) => {
    const intl = useIntl()
    const thisRef = useRef<HTMLButtonElement>(null)

    const [showSubItems, setShowSubItems] = useState(false)
    const [now, setNow] = useState(moment())

    const expand = () => setShowSubItems(true)
    const shrink = () => setShowSubItems(false)

    useEffect(() => {
        const timerId = setInterval(() => {setNow(moment())}, 1000 * 60);  // check every one hour
        return () => {window.clearTimeout(timerId)}
    }, [])

    const displayUntil = useMemo(() => {
        if (!until) return undefined

        const untilMoment = moment.utc(until)
        const minuteDiff = untilMoment.diff(now, 'minutes') // 1

        if (minuteDiff < 1) {
            return intl.formatMessage({id: 'i100109'}, {minute: 1})
        } else if (minuteDiff < 60) {
            return intl.formatMessage({id: 'i100109'}, {minute: minuteDiff})
        } else if (minuteDiff % 60 === 0) {
            const h = Math.floor(minuteDiff / 60)
            return intl.formatMessage({id: 'i100111'}, {hour: h})
        } else {
            const h = Math.floor(minuteDiff / 60)
            const m = minuteDiff - 60 * h
            return intl.formatMessage({id: 'i100110'}, {hour: h, minute: m})
        }
    }, [now, until, intl])

    const _update = useCallback((durationKey: string) => {
        if (disable) return

        let _now = moment()
        setNow(moment(_now))

        // console.log(now.toISOString())
        if (durationKey === 'half') {
            _now.add(30, 'minutes')
        } else if (durationKey === 'one') {
            _now.add(1, 'hours')
        } else if (durationKey === 'two') {
            _now.add(2, 'hours')
        } else if (durationKey === 'four') {
            _now.add(4, 'hours')
        } else if (durationKey === 'today') {
            _now = _now.endOf('day')
        } else if (durationKey === 'week') {
            _now = _now.endOf('week')
        }

        if (_now) {
            // console.log(_now.toISOString())
            update(_now.toISOString())
        }
    }, [update, disable])

    return (
        <Dropdown.Item as="button"
                       type="button"
                       className="notify-item position-relative d-flex align-items-center"
                       ref={thisRef}
                       onMouseEnter={expand}
                       onMouseLeave={shrink}
                       onClick={(e) => e.preventDefault()}>
            <i className="mdi mdi-logout mr-1 invisible" />
            <span className='flex-grow-1'>{intl.formatMessage({id: 'i100108'})}</span>
            <div className='ml-2'>
                {displayUntil ? <span>{displayUntil}</span> : <i className='uil uil-angle-right'/>}
            </div>
            {thisRef.current &&
                <div className={`position-absolute mb-0 ${!showSubItems && 'd-none'}`}
                     style={{
                         left: thisRef.current.offsetLeft + thisRef.current.offsetWidth,
                         bottom: 0,
                         paddingLeft: '1.5px'
                     }}
                >
                    <ListGroup>

                        <ListGroup.Item key={`reason-item-half`} className="dropdown-item" onClick={() => _update('half')}>
                            <span>{intl.formatMessage({id: 'i200047'}, {minute: 30})}</span>
                        </ListGroup.Item>

                        <ListGroup.Item key={`reason-item-one`} className="dropdown-item" onClick={() => _update('one')}>
                            <span>{intl.formatMessage({id: 'i200046'}, {hour: 1})}</span>
                        </ListGroup.Item>

                        <ListGroup.Item key={`reason-item-two`} className="dropdown-item" onClick={() => _update('two')}>
                            <span>{intl.formatMessage({id: 'i200046'}, {hour: 2})}</span>
                        </ListGroup.Item>

                        <ListGroup.Item key={`reason-item-four`} className="dropdown-item" onClick={() => _update('four')}>
                            <span>{intl.formatMessage({id: 'i200046'}, {hour: 4})}</span>
                        </ListGroup.Item>

                        <ListGroup.Item key={`reason-item-today`} className="dropdown-item" onClick={() => _update('today')}>
                            <span>{intl.formatMessage({id: 'i100106'})}</span>
                        </ListGroup.Item>

                        <ListGroup.Item key={`reason-item-week`} className="dropdown-item" onClick={() => _update('week')}>
                            <span>{intl.formatMessage({id: 'i100107'})}</span>
                        </ListGroup.Item>

                    </ListGroup>
                </div>
            }
        </Dropdown.Item>
    )
}

const MenuContainerStyle = styled.div`
  position: relative;
  z-index: 100;
  background-color: var(--menu-background-color);
  padding: 20px 0.375rem 0.375rem 0.375rem;

  //box-shadow: 1px 0 2px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
  //box-shadow: 0 0 1px 0 rgba(212, 213, 215, 0.8);

  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;

  .menu-item {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.25rem;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    display: block;
    text-align: center;
    color: var(--menu-text-color);

    i {
      line-height: 52px;
    }
  }
`

const LogoStyle = styled.div`
  margin-left: auto;
  margin-right: auto;
  //margin-bottom: 1.2rem;
  width: 52px;
  height: 34px;
  display: block;
  text-align: center;
`;

const ProfileMenuStyle = styled.div`
  text-align: center;
  margin-bottom: 10px;
  
  
  .dropdown-item {

    #away-reason-dropdown {
      background-color: transparent;
      color: rgba(var(--ct-secondary-rgb));
      border: unset;
      box-shadow: unset;
      padding: unset;
    }

    #away-reason-dropdown:after {
      display: none;
    }

    .list-group-item {
      border-bottom: unset;
      color: rgba(var(--ct-secondary-rgb));
      min-width: 200px;
    }

    .list-group-item:hover {
      color: black;
    }
  }
  
`

const BadgeStyle = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 6px;
  background-color: rgba(236, 49, 44, 0.95);
  border-radius: 9px;
  width: 18px;
  height: 18px;
  font-size: 0.5rem;
  font-weight: 500;
  line-height: 1.7;
  color: #ffffff;
`;

const MenuIndicator = styled.div<{indicatorColor?: string, indicatorPosition?: string}>`
  position: absolute;
  transform: translateY(${props => props.indicatorPosition}) rotate(135deg);
  //border-right: solid 1px rgba(0, 0, 0, 0.2);
  //border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  right: -9px;
  width: 17px;
  height: 17px;
  transition: all .3s ease-in-out;
  will-change: all;
  // background-color: ${props => props.indicatorColor || 'var(--left-container-background-color)'};
  background-color: transparent;
  box-shadow: inset -1px -1px 1px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;

  div {
    position: absolute;
    //bottom: px;
    left: -1px;
    //width: 16px;
    //height: 16px;
    //border-radius: 2px;
    border-right: solid 8px ${props => props.indicatorColor || 'var(--left-container-background-color)'};
    border-bottom: solid 8px ${props => props.indicatorColor || 'var(--left-container-background-color)'};
    border-top: solid 8px transparent;
    border-left: solid 8px transparent;

    //border-top: solid 8px transparent;
    //border-left: solid 8px transparent;
    
    //transition: all 0.3s ease-in-out;
  }
`

export default Menu;
