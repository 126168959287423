import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";

import {LiveChat} from "../../model/livechat";
import {useIntl} from "react-intl";
import {useDialog} from "../../hook/useDialog";
import liveChatRepository from "../../repository/LiveChatRepository";
import moment from "moment";
import {Button, Modal} from "react-bootstrap";
import useManager from "../../query/manager/useManager";
import Avatar from "../Avatar";
import useModal, {MaruModal} from "../../hook/useModal";
import kakaotalkIcon from "../../assets/images/kakaotalk_logo.png";
import instagramIcon from "../../assets/images/instagram_logo.png";
import facebookMessengerIcon from "../../assets/images/facebook_messenger_logo.png";
import navertalkIcon from "../../assets/images/navertalk_logo.png";
import lineIcon from "../../assets/images/line_logo.png";
import ChatUserInfoCard from "./ChatUserInfoCard";
import LiveChatManagerInfoCard from "./LiveChatManagerInfoCard";
import SimpleBar from "simplebar-react";
import toast from "react-hot-toast";
import useUpdateLiveChatState from "../../query/liveChat/useUpdateLiveChatState";

export const LIVECHAT_SETTING_MODAL_KEY = 'livechat_setting_modal'

type LiveChatSettingModalType = {
    liveChat: LiveChat,
    isFavorite: boolean,
    activeHoldBtn: boolean

    showHold: boolean,
    showClose: boolean
    onClickHoldBtn: (e: React.MouseEvent) => void
    toggleFavorite: (e: React.MouseEvent) => void
}

const LiveChatSettingModal: React.FC<LiveChatSettingModalType> = (props) => {
    const {
        liveChat,
        isFavorite,
        toggleFavorite,
        onClickHoldBtn,
        showHold,
        showClose,
        activeHoldBtn
    } = props

    const intl = useIntl();
    const modal = useModal();
    const dialog = useDialog()

    const {data: manager} = useManager(liveChat.channelId);

    const {mutate: updateLiveChatState} = useUpdateLiveChatState(liveChat.channelId, liveChat._id);

    const data = modal.data[LIVECHAT_SETTING_MODAL_KEY];

    const [content, setContent] = useState<undefined | string>(undefined);

    const isHolding = useMemo(() => {
        return liveChat.state === 'holding';
    }, [liveChat]);


    useEffect(() => {
        if (data) {
            setContent(data.tapContent)
        }
    }, [data])

    const swapContent = (content: string) => setContent(content)

    // const close = useCallback(() => {
    //     modal.close(LIVECHAT_SETTING_MODAL_KEY)
    // }, [modal])

    const onClickSolvedBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        dialog.open({
            content: intl.formatMessage({id: 'i000011'}),
            variant: 'primary',
            confirmText: intl.formatMessage({id: 'i100029'}),
            cancelText: intl.formatMessage({id: 'i100030'}),
            onConfirm: async () => {
                const state = (liveChat.state === 'expired' || liveChat.state === 'unassigned') ? 'closed' : 'solved';
                const sendMessageTrigger = (liveChat.state === 'responding' || liveChat.state === 'holding') && !!manager;

                updateLiveChatState({state, sendMessageTrigger}, {
                    onSuccess: async () => {
                        try {
                            if (sendMessageTrigger) {
                                await liveChatRepository.requestSatisfactionInfo(liveChat.channelId, liveChat._id);
                            }
                        } catch (e) {
                            // toast.error(intl.formatMessage({id: 'i000217'}));
                        }
                    },
                    onError: () => {
                        toast.error(intl.formatMessage({id: 'i000217'}));
                    }
                });
            }
        });
    };

    const chatType = useMemo(() => {
        return liveChat.type || 'web'
    }, [liveChat])

    return (
        <MaruModal id={LIVECHAT_SETTING_MODAL_KEY} title={intl.formatMessage({id: 'i100026'})} >
            <ThisStyle>
                <div className="header mb-3">
                    {/*<div className="d-flex align-items-center">*/}
                    {/*    <h4 className="flex-grow-1">*/}
                    {/*        {intl.formatMessage({id: 'i100026'})}*/}
                    {/*    </h4>*/}
                    {/*    <CenteredIcon size={22} role="button" className="mdi mdi-close font-20" onClick={close}/>*/}
                    {/*</div>*/}

                    <div className="live-chat-controller">
                        <div className="row-1 d-flex align-items-center mb-2">
                            <Avatar size={24} name={liveChat.user.name || liveChat.user.alias} className="chat-avatar mr-1" />
                            <span>
                                {liveChat.user.name || liveChat.user.alias}
                            </span>

                            {(chatType === 'kakaotalk' || chatType === 'sangdamtalk') && <img className="chat-type" src={kakaotalkIcon} alt="kakaotalk" />}
                            {chatType === 'instagram' && <img className="chat-type" src={instagramIcon}  alt="instagrem"/>}
                            {chatType === 'facebook' && <img className="chat-type" src={facebookMessengerIcon} alt="facebook" />}
                            {chatType === 'navertalk' && <img className="chat-type" src={navertalkIcon} alt="navertalk" />}
                            {chatType === 'line' && <img className="chat-type" src={lineIcon} alt="line" />}

                            <div className="d-flex flex-grow-1 justify-content-end">
                                <i role="button" className={`mdi mdi-star${isFavorite ? '' : '-outline'} mr-1`} onClick={toggleFavorite}/>
                            </div>
                        </div>

                        <div className="d-flex row-2 align-items-center">
                            <span className="flex-grow-1">{intl.formatMessage({id: 'i100031'})}{':'} {moment.utc(liveChat.createdAt).local().format('YYYY-MM-DD HH:mm')}</span>
                            {showHold &&
                                <Button variant={isHolding ? 'danger' : 'outline-secondary'}
                                        className="mr-1"
                                        size="sm"
                                        active={activeHoldBtn}
                                        onClick={onClickHoldBtn}>
                                    {intl.formatMessage({id: isHolding ? 'i000227' : 'i000226'})}
                                </Button>
                            }
                            {showClose && (
                                <Button size="sm"
                                        variant="outline-secondary"
                                        onClick={onClickSolvedBtn}>
                                    {intl.formatMessage({id: 'i000010'})}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>

                {content &&
                    <>
                        <ul className="nav nav-tabs nav-justified nav-bordered mb-1">
                            <li key={`live-chat-modal-user-tap`} className="nav-item">
                                <button
                                    onClick={() => swapContent('user')}
                                    className={`nav-link ${content === 'user' ? 'active' : ''}`}
                                >
                                    <i className="mdi mdi-home-variant d-md-none d-block"></i>
                                    <span className="d-none d-md-block">{intl.formatMessage({id: 'i000165'})}</span>
                                </button>
                            </li>

                            <li key={`live-chat-modal-member-tap`} className="nav-item">
                                <button
                                   onClick={() => swapContent('member')}
                                   className={`nav-link ${content === 'member' ? 'active' : ''}`}
                                >
                                    <i className="mdi mdi-home-variant d-md-none d-block"></i>
                                    <span className="d-none d-md-block">{intl.formatMessage({id: 'i000313'})}</span>
                                </button>
                            </li>
                        </ul>

                        {content === 'user' &&
                            <SimpleBar style={{maxHeight: 'calc(100vh - 340px)'}}>
                                <ChatUserInfoCard channelId={liveChat.channelId}
                                                  className="p-2"
                                                  liveChatId={liveChat._id}
                                                  user={liveChat.user}
                                                  showUserInfoBtn={liveChat.state === 'responding' || liveChat.state === 'holding'}
                                />
                            </SimpleBar>
                        }

                        {content === 'member' &&
                            <LiveChatManagerInfoCard liveChat={liveChat} className="p-2"/>
                        }
                    </>

                }
            </ThisStyle>
            {/*<Modal.Footer>*/}
            {/*    <Button type="submit" className="btn-rounded" form={'manager_create_form'}>{intl.formatMessage({id: 'i000040'})}</Button>*/}
            {/*</Modal.Footer>*/}
        </MaruModal>
    );


};


const ThisStyle = styled(Modal.Body) `
  
  i.mdi-star {
    color: var(--ct-yellow);
    font-size: 24px;
  }

  i.mdi-star-outline {
    font-size: 24px;
  }

  img.chat-type {
    width: 20px;
    height: 20px;
    margin-left: 0.375rem;
  }
  
  .live-chat-controller {
    background-color: #F4F5F7;
    border-radius: 0.25rem;
    padding: 1rem;
  }
  
  .nav-tabs li button {
    border: 0;
    background-color: transparent;
    width: 100%;
    padding: 0.625rem 1.25rem;
  }
  
  .nav-tabs li .active {
    border-bottom: 2px solid #4575fb;
  }
`


export default LiveChatSettingModal;
