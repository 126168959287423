import React from "react";


const ChatPanelEmptyMessage = React.memo<{message: string}>(({message}) => {

    return (
        <div className="d-flex align-items-center justify-content-center h-100" style={{height: '40px'}}>
            <small className="text-muted p-0 m-0">{message}</small>
        </div>
    )
});

export default ChatPanelEmptyMessage;