import {Message, MessageSearchResult} from "./message";
import {Moment} from "moment";
import {Manager} from "./manager";
import {ChatUser} from "./chatUser";

export interface ChatSearchTracking {
    trackingMessage: Message
    searchText: string
    isTracking: boolean
    filter?: FilterSnapshot
    sortType?: SortType
}

export type FilterSnapshotKey = "managers" | "chatUsers" | "dateRange";

export type FilterSnapshot = {
    managers: Manager[],
    chatUsers: ChatUser[],
    dateRange: Moment[]
}

export interface ChatSearchHistory {
    searchedAt: Moment,
    searchText: string
    filterSnapshot?: FilterSnapshot
}

export enum FilterResultOperation {
    ADD = "add",
    REMOVE = "remove"
}

/**
 *  Rule for value of sort type enum
 *
 *  Format of value of sort type enum: {field}.{order}
 *
 *  field: a field that we want sort
 *
 *  order: sort by ascending ('asc') or descending order ('desc')
 *
 */
export enum SortType {
    MOST_RELEVANT = "",
    LATEST = "createdAt.desc",
    EARLIEST = "createdAt.asc",
    PRIORITY_DESC = "livechat.priority.desc",
    PRIORITY_ASC = "livechat.priority.asc",
}

export interface ChatSearchRequestParams {
    searchText: string
    page: number
    limit: number
    managerIds: string[]
    chatUserIds: string[]
    startDate?: string
    endDate?: string
    sortType?: SortType
}

export interface ChatSearchResultResponse {
    total: number // total hit documents
    messages: MessageSearchResult[] // searched messages in requested page
}

export interface ChatSearchResultSnapshot {
    searchText: string
    filter?: FilterSnapshot
    sortType: SortType
}
