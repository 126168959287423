import React from "react";
import SimpleBar from "simplebar-react";
import styled from "styled-components";


const DashboardViewWrapper:React.FC<{children?: React.ReactNode, minWidth?: string}> = ({children, minWidth}) => {
    return (
        <DashboardViewWrapperStyle minWidth={minWidth}>
            <SimpleBar className="dashboard-content">
                {children}
            </SimpleBar>
        </DashboardViewWrapperStyle>
    )
}

const DashboardViewWrapperStyle = styled.div<{minWidth?: string}>`
  background-color: white;
  flex: 1;

  .dashboard-content {
    min-width: ${props => props.minWidth || '1200px'};
    height: 100%;
    padding: 1rem;

    .dashboard-title {
      //padding: 0.5rem;
      border-bottom: 1px solid #b9b9b9;
      margin-bottom: 1rem;
    }

    .nav-tabs > li > .nav-link {
      color: #2c343f;
      border-top-color: #ffffff;
      border-right-color: #ffffff;
      border-left-color: #ffffff;

    }

    .nav-tabs > li > .nav-link.active {
      border-top-color: #ffffff;
      border-right-color: #ffffff;
      border-left-color: #ffffff;
      border-bottom: 2px solid #727CF5;
    }
  }
`
export default DashboardViewWrapper;
