import {useQuery} from "@tanstack/react-query";
import oauthAxios from "../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../core/variables";
import {LiveChatError, toLiveChatError} from "../error";


const getData = async (channelId?: string) => {
    if (!channelId) {
        return [];
    }

    try {
        const {data} = await oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/permissions`);
        return data.result;
    } catch (e) {
        throw toLiveChatError(e);
    }
}


const useUserPermissions = (channelId?: string) => {
    return useQuery<string[], LiveChatError>({
        queryKey: ['channels', channelId ?? '', 'permissions'],
        queryFn: () => getData(channelId)
    });
};


export default useUserPermissions;