const DEFAULT_TICK_AMOUNT = 5;

/* Get Y-axis interval maximum which is suitable with series data.
        *
        *  The interval maximum would be 5 times of well-known time units, e.g. minute, hour, day.*/
export const getIntervalMaximum = (values: number[]) => {
    const maximum = Math.max(...values);

    if (maximum < 60) {
        return Math.ceil(maximum / (DEFAULT_TICK_AMOUNT * 12)) * (DEFAULT_TICK_AMOUNT * 12);
    } else if (maximum < 3600) {
        return Math.ceil(maximum / (DEFAULT_TICK_AMOUNT * 60)) * (DEFAULT_TICK_AMOUNT * 60);
    } else if (maximum < 86400) {
        return Math.ceil(maximum / (DEFAULT_TICK_AMOUNT * 3600)) * (DEFAULT_TICK_AMOUNT * 3600);
    } else if (maximum < 864000) {
        return Math.ceil(maximum / (DEFAULT_TICK_AMOUNT * 7200)) * (DEFAULT_TICK_AMOUNT * 7200);
    } else {
        return Math.ceil(maximum / (DEFAULT_TICK_AMOUNT * 86400)) * (DEFAULT_TICK_AMOUNT * 86400);
    }
}
