import {LiveChat, LiveChatStatus} from "../../model/livechat";
import {useInfiniteQuery} from "@tanstack/react-query";
import liveChatRepository from "../../repository/LiveChatRepository";
import {LiveChatError, toLiveChatError} from "../../error";
import {Page} from "../index";


export const getData = async (
    channelId: string,
    pageable: {since?: string, limit: number},
    params?: {status: LiveChatStatus, managerUserId?: string, priorities: number[], sortBy?: string}
) => {
    const _params = {
        status: params?.status,
        managerUserId: params?.managerUserId,
        priorities: params?.priorities,
        sortBy: params?.sortBy,
        since: pageable.since,
        limit: pageable.limit
    };

    try {
        const {data} = await liveChatRepository.find(channelId, _params);
        const liveChats: LiveChat[] = data.result;

        return {
            result: liveChats,
            pageable: {
                ...pageable,
                isLast: liveChats.length < pageable.limit
            }
        };
    } catch (e) {
        throw toLiveChatError(e);
    }

}

const useManagerLiveChats = (channelId: string, status: LiveChatStatus, managerUserId: string, priorities: number[], sortBy?: string) => {
    return useInfiniteQuery<Page<LiveChat, {since?: string}>, LiveChatError>({
        queryKey: ['liveChats', status, channelId, managerUserId, priorities],
        queryFn: ({pageParam = {limit: 50, isLast: false}}) => getData(channelId, pageParam, {status, managerUserId, priorities, sortBy}),
        refetchOnMount: true,
        getNextPageParam: ({pageable}, pages) => {
            if (pageable.isLast) {
                return undefined;
            }

            const lastPageResult = pages.length > 0 ? (pages[pages.length - 1].result ?? []) : [];
            return {
                // offset: pageable.offset + pageable.limit,
                since: lastPageResult.length > 0 ? lastPageResult[lastPageResult.length - 1].since : undefined,
                limit: pageable.limit,
                isLast: false
            };
        }
    });
};


export default useManagerLiveChats;