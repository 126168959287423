import styled from "styled-components";
import useChannelManagerStatistics from "../../../query/statistic/useChannelManagerStatistics";
import {useIntl} from "react-intl";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {AWAY_REASON_ITEMS, ManagerStatistics} from "../../../model/manager";
import {Button, Dropdown, Table} from "react-bootstrap";
import Avatar from "../../../component/Avatar";
import {useNavigate, useSearchParams} from "react-router-dom";
import useLiveChatTotal from "../../../query/liveChat/useLiveChatTotal";
import useManager from "../../../query/manager/useManager";
import {AwayReason} from "../../../model/managerHistory";
import classNames from "classnames";
import {useSocket} from "../../../socket";

const ManagerDashboardView: React.FC<{channelId: string}> = ({channelId}) => {
    const intl = useIntl()
    const navigate = useNavigate();
    const {connected: socketConnected} = useSocket()
    const {data: manager} = useManager(channelId);
    const [searchParams] = useSearchParams();

    const sinceHours= useMemo(() => {
        const sinceHoursParam = searchParams.get('sinceHours')
        if (!sinceHoursParam) return 4

        try {
            return parseInt(sinceHoursParam)
        } catch (e) {
            console.log(e)
            return 4
        }
    }, [searchParams])

    const {data, isSuccess, refetch} = useChannelManagerStatistics(channelId!, sinceHours,
        {refetchInterval: 60000, enabled: socketConnected === true})

    const [propagatedData, setPropagatedData] = useState<ManagerStatistics[] | undefined>(undefined)

    useEffect(() => {
        if (data) {
            setPropagatedData(data)
        }
    }, [data])

    // Refresh when component is remounted
    useEffect(() => {
        if (isSuccess) {
            refetch().catch(() => {})
        }
    }, [isSuccess, refetch])

    const toggleRef = useRef<HTMLButtonElement>(null);

    const onClickSinceHourItem = useCallback((hour: number) => {
        navigate(`/channels/${channelId}/livechats/dashboard?sinceHours=${hour}`)
    }, [channelId, navigate])

    const navigateToNotificationView = () => {
        navigate(`/channels/${channelId}/livechats/manager-notifications`)
    }

    return (
        <ManagerDashboardViewStyle>
            <div className='m-2 h-100 d-flex flex-column'>
                <div className="p-2 d-flex align-items-center">
                    <div className='d-flex align-items-center flex-grow-1'>
                        <h3 className="mr-2">{intl.formatMessage({id: 'i100119'})}</h3>
                        <span>{intl.formatMessage({id: 'i100120'})}</span>
                    </div>
                    <Button className='rounded'
                            size="sm"
                            variant="outline-secondary"
                            onClick={navigateToNotificationView}
                    >
                        {intl.formatMessage({id: 'i100121'}, {hour: sinceHours})}
                        <i className="mdi mdi-arrow-right ml-1" role={'button'} />
                    </Button>
                </div>

                <div className='p-2 flex-grow-1'>
                    <div className='h-100 d-flex flex-column'>

                        <div className='d-flex justify-content-end mb-2'>
                            <Dropdown placement="top-start">
                                <Dropdown.Toggle id="menu-profile-drop"
                                                 as="button"
                                                 className="cursor-pointer rounded btn btn-outline-secondary"
                                                 ref={toggleRef}
                                >
                                    {intl.formatMessage({id: 'i100111'}, {hour: sinceHours})}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>

                                    {[1,4,8,12,24].map((hour) => (
                                        <Dropdown.Item key={`since-${hour}-hour`}
                                                       as="button"
                                                       type="button"
                                                       onClick={() => {onClickSinceHourItem(hour)}}
                                        >
                                            <span>{intl.formatMessage({id: 'i100111'}, {hour})}</span>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        {propagatedData &&
                            <>
                                <ConditionOverview channelId={channelId} userId={manager!.userId} managerStatistics={propagatedData}/>

                                <div className='flex-grow-1 mt-3'>
                                    <ManagerConditions managerStatistics={propagatedData}/>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </ManagerDashboardViewStyle>
    )
}


const ManagerDashboardViewStyle = styled.div `
  flex: 1 0 0;
  background-color: white;
`

type ConditionOverviewProp = {
    channelId: string,
    userId: string,
    managerStatistics: ManagerStatistics[]
}

const ConditionOverview: React.FC<ConditionOverviewProp> = ({channelId, userId, managerStatistics}) => {

    const {data: total} = useLiveChatTotal(channelId, userId);

    const liveChatAggregation: null | LivechatConditionProps = useMemo(() => {
        if (!total) return null

        const result = {
            'assigned': new Set(),
            'responding': new Set(),
            'holding': new Set(),
            'solved': new Set(),
            'total': new Set()
        }

        managerStatistics.forEach((managerStatistics) => {
            managerStatistics.waitingLivechatIds.forEach((liveChatId) => {result.assigned.add(liveChatId)})
            managerStatistics.respondingLivechatIds.forEach((liveChatId) => {result.responding.add(liveChatId)})
            managerStatistics.holdingLivechatIds.forEach((liveChatId) => {result.holding.add(liveChatId)})
            managerStatistics.solvedLivechatIds.forEach((liveChatId) => {result.solved.add(liveChatId)})
            managerStatistics.totalLivechatIds.forEach((liveChatId) => {result.total.add(liveChatId)})
        })

        const tt = Object.entries(result).reduce((acc, current) => {
            acc[current[0]] = current[1].size
            return acc
        }, {'unassigned': total.unassignedCount} as {[key: string]: number})

        return tt as LivechatConditionProps

    }, [managerStatistics, total])

    const managerAggregation = useMemo(() => {
        if (!total) return null

        const result = {
            online: 0,
            offline: 0,
            away: 0,
            total: managerStatistics.length
        }

        managerStatistics.forEach((manager) => {
            if (manager.state === 'away') {
                result.away += 1
            } else if (manager.state === 'online') {
                result.online += 1
            } else if (manager.state === 'offline'){
                result.offline += 1
            }
        })
        return result
    }, [total, managerStatistics])

    return (
        <ChannelConditionStyle className="">
            {managerAggregation && <ManagerCondition {...managerAggregation}/>}
            <div style={{width: "30px"}}/>
            {liveChatAggregation && <LivechatCondition {...liveChatAggregation}/>}
        </ChannelConditionStyle>
    )
}

const ChannelConditionStyle = styled.div `
  display: flex;
`

const ManagerCondition: React.FC<{online: number, away: number, offline: number, total: number}> = ({online, away, offline, total}) => {
    const intl = useIntl()
    return (
        <ManagerConditionStyle className="border border-light rounded border-2 px-4 py-1">
                <h4 className="fw-bold mb-1">
                    {intl.formatMessage({id: 'i100034'})}
                </h4>

                <div className='flex-grow-1 d-flex align-items-center ml-2'>
                    <div className='state-item'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <h2>
                                {online}
                            </h2>
                            <div className='bg-success rounded-circle ml-1' style={{width: "10px", height: "10px"}}/>

                        </div>
                        <h5 className='text-secondary'>{intl.formatMessage({id: 'i000333'})}</h5>
                    </div>

                    <div className='state-item'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <h2>
                                {offline}
                            </h2>
                            <div className='bg-secondary rounded-circle ml-1' style={{width: "10px", height: "10px"}}/>
                        </div>
                        <h5 className='text-secondary'>{intl.formatMessage({id: 'i000334'})}</h5>

                    </div>

                    <div className='state-item'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <h2>
                                {away}
                            </h2>
                            <div className='bg-warning rounded-circle ml-1' style={{width: "10px", height: "10px"}}/>
                        </div>
                        <h5 className='text-secondary'>{intl.formatMessage({id: 'i100099'})}</h5>
                    </div>

                    <div className='state-item'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <h2>
                                {total}
                            </h2>
                            {/*<div className='bg-success rounded-circle ml-1' style={{width: "10px", height: "10px"}}/>*/}
                        </div>
                        <h5 className='text-secondary'>{intl.formatMessage({id: 'i200015'})}</h5>
                    </div>
                </div>
        </ManagerConditionStyle>
    )
}

const ManagerConditionStyle = styled.div `
  display: flex;
  flex-direction: column;

  h5 {
    text-align: center;
    margin: 0
  }
  
  .state-item {
    flex-grow: 1;
    margin-left: 1.5rem;
  }
  
`

type LivechatConditionProps = {
    unassigned: number,
    assigned: number,
    responding: number,
    holding: number,
    solved: number,
    total: number
}

const LivechatCondition: React.FC<LivechatConditionProps> = (props) => {
    const intl = useIntl()


    return (
        <LivechatConditionStyle className="border border-2 border-light rounded px-4 py-1">
            <h4 className="fw-bold mb-1">
                {intl.formatMessage({id: 'm02'})}
            </h4>

            <div className='d-flex ml-4'>
                <div className='d-flex align-items-center mr-4'>
                    <div className='flex-grow-1 mr-5'>
                        <div className="d-flex align-items-center justify-content-center">
                            <h2>
                                {props.assigned}
                            </h2>
                        </div>
                        <h5  className='text-secondary'>{intl.formatMessage({id: 'i100114'})}</h5>
                    </div>

                    <div className='flex-grow-1'>
                        <div className="d-flex align-items-center justify-content-center">
                            <h2>
                                {props.unassigned}
                            </h2>
                        </div>
                        <h5 className='text-secondary'>{intl.formatMessage({id: 'i100116'})}</h5>
                    </div>
                </div>

                <div className='ml-3'>
                    <div className='horizon-state-item'>
                        <div className='d-flex align-items-center'>
                            <div className='mr-2'>
                                <i className="mdi mdi-chat-processing-outline text-secondary"/>
                            </div>
                            <span>{intl.formatMessage({id: 'i000002'})}</span>
                        </div>
                         <h2 className='text-mono ml-2 text-end'>
                             {props.responding}
                        </h2>
                    </div>

                    <div className='horizon-state-item'>
                        <div className='d-flex align-items-center'>
                            <i className="mdi mdi-chat-sleep-outline text-danger mr-2" />
                            <span>{intl.formatMessage({id: 'i000004'})}</span>
                        </div>

                        <h2 className='text-mono ml-2 text-end'>
                            {props.holding}
                        </h2>
                    </div>

                    <div className='horizon-state-item'>
                        <div className='d-flex align-items-center'>
                            <i className="mdi mdi-check-circle-outline text-success mr-2"/>
                            <span>{intl.formatMessage({id: 'i000383'})}</span>
                        </div>
                        <h2 className='text-mono ml-2 text-end'>
                            {props.solved}
                        </h2>
                    </div>

                    {/*<div className='horizon-state-item'>*/}
                    {/*    <div></div>*/}
                    {/*    <span>{intl.formatMessage({id: 'i200015'})}</span>*/}
                    {/*    <h2>*/}
                    {/*        {props.total}*/}
                    {/*    </h2>*/}
                    {/*</div>*/}
                </div>
            </div>
        </LivechatConditionStyle>
    )
}

const LivechatConditionStyle = styled.div `
  
  h2 {
    margin: 0;
  }
  
  h5 {
    text-align: center;
  }
  
  .horizon-state-item {
    display: flex;
    align-items: center;
    //font-size: 1rem;
    
    .mdi {
      font-size: 1.2rem;
    }
    
    span {
      font-size: 1rem;
    }

    & > *:last-child {
      flex: 1 0 0;
    }
  }
`

type ManagerConditionsProp = {
    // channelId: string
    managerStatistics: ManagerStatistics[]
}

const ManagerConditions: React.FC<ManagerConditionsProp> = ({managerStatistics}) => {
    const intl = useIntl()

    return (
        <ManagerConditionsStyle size="sm" className="table-centered">
            <thead className='bg-light rounded mb-1'>
            <tr>
                <th/>
                <th>{intl.formatMessage({id: 'i000020'})}</th>
                <th>
                    <span className='pl-2' style={{marginLeft: "10px"}}>
                        {intl.formatMessage({id: 'i100112'})}
                    </span>
                </th>
                <th className='text-center'>{intl.formatMessage({id: 'i100118'})}</th>
                <th className='text-center'>{intl.formatMessage({id: 'i100113'})}</th>
                <th>{intl.formatMessage({id: 'i100114'})}</th>
                <th>{intl.formatMessage({id: 'i000002'})}</th>
                <th>{intl.formatMessage({id: 'i000004'})}</th>
                <th>{intl.formatMessage({id: 'i000383'})}</th>
                <th>{intl.formatMessage({id: 'i200015'})}</th>
            </tr>
            </thead>
            <tbody className='mb-3'>
            {managerStatistics?.map((manager) => (
                <ManagerRow key={`manager-row-${manager._id}`} manager={manager}/>
            ))}
            </tbody>
        </ManagerConditionsStyle>

    )
}


const ManagerConditionsStyle = styled(Table) `
  
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  
  * {
    border: unset !important;
  }
  

  tbody {
    display: block;
    flex: 1 0 0;
    width: 100%;
    overflow: auto;
  }
  
  tr {
    display: flex;
    
    th:nth-child(1), td:nth-child(1) {
      flex: 0 0 50px;
    }

    th:nth-child(2), td:nth-child(2) {
      flex: 2 0 0;
    }

    th:nth-child(3), td:nth-child(3) {
      flex: 2 0 0;
    }

    th:nth-child(4), td:nth-child(4) {
      flex: 2 0 0;
    }

    th:nth-child(5), td:nth-child(5) {
      flex: 3 0 0;
    }
    
    th:nth-child(n+6), td:nth-child(n+6) {
      flex: 1 0 0;
      text-align: center;
    }
    
    th:nth-child(2) {
      color: black;
    }
    
    th:nth-child(n+3) {
      color: darkgrey;
    }
  }

`

type ManagerRowProp = {
    manager: ManagerStatistics
}

const ManagerRow: React.FC<ManagerRowProp> = ({manager}) => {
    const intl = useIntl()

    const displayMinute = useCallback((minute: number, justNowResult?: string) => {
        if (minute < 1) {
            return justNowResult ?? '-'
        }

        let result = ''
        if (minute >= 60) {
            result += intl.formatMessage({id: 'i100111'}, {hour: Math.floor(minute/ 60).toString()})
        }

        const remain = minute % 60
        if (remain) {
            result += `${result.length > 0 ? " " : ""}${intl.formatMessage({id: 'i200047'}, {minute: remain})}`
        }
        return result
    }, [intl])

    const displayState = useMemo(() => {
        switch (manager.state) {
            case 'online':
                return intl.formatMessage({id: 'i000333'})
            case 'offline':
                return intl.formatMessage({id: 'i000334'})
            case 'away':
                return intl.formatMessage({id: 'i100099'})
        }
    }, [manager, intl])

    const stateDescription = useMemo(() => {
        if (manager.stateReason && manager.stateReason in AWAY_REASON_ITEMS) {
            return intl.formatMessage({id: AWAY_REASON_ITEMS[manager.stateReason as AwayReason]})
        }
        return null
    }, [manager, intl])

    return (
        <ManagerRowStyle>
            <td><Avatar size={30} name={manager.name}/></td>
            <td>
                {manager.name}
            </td>
            <td>

                {{
                    online: <div className='bg-success rounded-circle mr-2' style={{width: "10px", height: "10px"}}/>,
                    offline: <div className='bg-secondary rounded-circle mr-2' style={{width: "10px", height: "10px"}}/>,
                    away: <div className='bg-warning rounded-circle mr-2' style={{width: "10px", height: "10px"}}/>,
                }[manager.state]}

                <div className='mr-1'>
                    <p className='mb-0'>{displayState}</p>
                    {stateDescription && <span className='text-muted'>{stateDescription}</span>}
                </div>

            </td>
            <td>
                <span className={classNames({'text-danger': manager.exceedStateDurationMinutes})}>
                    {displayMinute(manager.stateDurationMinutes, intl.formatMessage({id: 'i100134'}))}
                </span>
            </td>
            <td className='text-center'>
                {displayMinute(manager.totalOnlineMinutes)}
            </td>
            <td>{manager.waitingLivechatIds.length > 0 ? manager.waitingLivechatIds.length : '-'}</td>
            <td>{manager.respondingLivechatIds.length > 0 ? manager.respondingLivechatIds.length : '-'}</td>
            <td>{manager.holdingLivechatIds.length > 0 ? manager.holdingLivechatIds.length : '-'}</td>
            <td>{manager.solvedLivechatIds.length > 0 ? manager.solvedLivechatIds.length : '-'}</td>
            <td>{manager.totalLivechatIds.length > 0 ? manager.totalLivechatIds.length : '-'}</td>

        </ManagerRowStyle>
    )
}

const ManagerRowStyle = styled.tr `
  & > td {
    display: flex;
    align-items: center;
  }
  
  & > td:nth-child(1), td:nth-child(n+4) {
    justify-content: center;
  }
`

export default ManagerDashboardView