import React, {useEffect} from "react";
import {LiveChat} from "../../model/livechat";
import classNames from "classnames";
import "../../assets/css/autocomplete.css";
import LiveChatBottom from "./LiveChatBottom";
import LiveChatTop from "./LiveChatTop";
import styled from "styled-components";
import useLiveChatHandler from "../../hook/useLiveChatHandler";
import {SocketEvent, useSocketSubscribe} from "../../socket";
import MessageList from "./MessageList";


const LiveChatUI: React.FC<LiveChatUIProps> = ({channelId, liveChat, fullSize}) => {
    const liveChatHandler = useLiveChatHandler(channelId);

    useSocketSubscribe<string>(SocketEvent.CHAT_USER_ONLINE, (data) => {
        if (liveChat.user._id === data) {
            liveChatHandler.onlineUser(liveChat._id);
        }
    });

    useSocketSubscribe<string>(SocketEvent.CHAT_USER_OFFLINE, (data) => {
        if (liveChat.user._id === data) {
            liveChatHandler.offlineUser(liveChat._id);
        }
    });

    useEffect(() => {
        liveChatHandler.setLiveChatId(liveChat?._id);
    }, [liveChat, liveChatHandler]);

    return (
        <LiveChatMessageContainerStyle className={classNames('live-chat-ui',{'full': fullSize})}>
            <LiveChatTop key={liveChat._id} liveChat={liveChat} fullSize={fullSize} />
            <LiveChatWrapperStyle>
                <MessageList liveChat={liveChat} />
                {/*<VirtualMessageList liveChat={liveChat} />*/}
                <LiveChatBottom liveChat={liveChat} />
            </LiveChatWrapperStyle>
        </LiveChatMessageContainerStyle>
    );
};


type LiveChatUIProps = {
    channelId: string;
    liveChat: LiveChat;
    fullSize?: boolean;
};

const LiveChatMessageContainerStyle = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 550px;
  max-width: var(--message-container-width);
  margin-right: 2px;
  padding: 0;
  //height: 100%;

  &.full {
    min-width: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
  }

  &.loading {
    align-items: center !important;
    justify-content: center !important;
  }

  &:not(.loading) {
    .block {
      display: none;
    }
  }
`

const LiveChatWrapperStyle = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
  //height: calc(100% - 55px);
  overflow-y: auto;

  //box-shadow: var(--panel-box-shadow);
  border-top: 1px solid rgba(212, 213, 215, 0.8);
  border-right: 1px solid rgba(212, 213, 215, 0.8);
`

export default LiveChatUI;
