import conditionalAnnouncementRepository from "../../repository/ConditionalAnnouncementRepository";
import {LiveChatError, toLiveChatError} from "../../error";
import {useQuery} from "@tanstack/react-query";
import {ConditionalAnnouncement} from "../../model/conditionalAnnouncement";
import {DEFAULT_ARGS} from "../index";

const getData = async (channelId: string) => {
    try {
        const { data } = await conditionalAnnouncementRepository.find(channelId);
        return data.result;
    } catch (e) {
        throw toLiveChatError(e);
    }
};

const useConditionalAnnouncements = (channelId: string, args?: {refetchOnMount?: boolean}) => {
    let _args = {...DEFAULT_ARGS}
    if (args) {
        _args = Object.assign(_args, args)
    }
    return useQuery<ConditionalAnnouncement[], LiveChatError>({
        queryKey: ['conditional-announcements', channelId],
        queryFn: async () => getData(channelId),
        ..._args
    });
};

export default useConditionalAnnouncements;
