import {LIVE_CHAT_API_HOST} from "../core/variables";
import oauthAxios from "../core/oauthAxios";
import {MessageBlock} from "../model/message";

class MessageTemplateRepository {
    async find(channelId: string, includePublic?: boolean) {
        return oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/message-templates`, {
            params: {
                includePublic: includePublic
            }
        });
    }

    async create(channelId: string, name: string, blocks: MessageBlock[], checkedPublic: boolean) {
        return oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/message-templates`, {
            name,
            blocks,
            public: checkedPublic
        });
    }

    async update(channelId: string, templateId: string, name: string, blocks: MessageBlock[], checkedPublic: boolean) {
        return oauthAxios.put(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/message-templates/${templateId}`, {
            name,
            blocks,
            public: checkedPublic
        });
    }

    async delete(channelId: string, templateId: string) {
        return oauthAxios.delete(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/message-templates/${templateId}`);
    }

    async uploadFile(channelId: string, file: File) {
        let form = new FormData();
        form.append('files', file);
        return oauthAxios.post(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/message-templates/files`, form);
    }
}

export default new MessageTemplateRepository();