import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import oauthAxios from "../../core/oauthAxios";
import {LIVE_CHAT_API_HOST} from "../../core/variables";
import {LiveChatError, toLiveChatError} from "../../error";


const useDeleteMember = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, LiveChatError, string>(['members', 'delete', channelId], async (memberId) => {
        try {
            return await oauthAxios.delete(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/members/${memberId}`)
        } catch (e) {
            throw toLiveChatError(e);
        }
    }, {
        onSuccess: () => {
            return Promise.all([
                queryClient.invalidateQueries(['members', channelId], {exact: true})
            ]);
        }
    });
}

export default useDeleteMember;
