import React, {useMemo, useState} from 'react'
import {
    DateSelectArg,
    EventClickArg,
    EventInput,
    EventSourceInput,
} from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import {useIntl} from "react-intl";
import WorkingTimeCalendarModal, {calendarSchedulerModalId} from "./WorkingTimeCalendarModal";
import useModal from "../../hook/useModal";
import {LiveChatWorkingHoliday} from "../../model/channel";
import {Button} from "react-bootstrap";
import styled from "styled-components";
import useChannel from "../../query/channel/useChannel";
import {WorkingTimeHolidayList} from "./WorkingTimeHolidayList";
import moment from "moment-timezone";


const getFormat = (d: string) => {
    if(d.includes("T")){
        return "YYYY-MM-DDTHH:mm:ss";
    } else {
        return "YYYY-MM-DD";
    }
}

export const workingHolidayToEvent = (timezone: string, holiday: LiveChatWorkingHoliday): EventInput => {

    const start = moment(`${holiday.start}Z`).tz(timezone).format(getFormat(holiday.start));
    const end = moment(`${holiday.end}Z`).tz(timezone).format(getFormat(holiday.end));
    return {
        id: holiday.holidayId,
        title: holiday.title,
        start: start,
        end: end,
        extendedProps: {holiday: holiday}
    }
}

export interface CalendarSchedulerProps {
    channelId: string
}

export const WorkingTimeCalendar: React.FC<CalendarSchedulerProps> = (props) => {
    const {channelId} = props
    const intl = useIntl();
    const modal = useModal()

    const {data: channel} = useChannel(channelId);

    const [calendarRef, setCalendarRef] = useState<FullCalendar | null>(null)
    const [calendarYear, setCalendarYear] = useState(new Date().getFullYear())

    const initialEvents: EventSourceInput = useMemo(() => {
        if (!channel) return []

        return channel.config.workingHoliday.holidays
            .map((holiday) => workingHolidayToEvent(channel.config.timezone, holiday))

        // return holidayEvents
        // const workingTimeEvents = channel.config.workingTimes.map((workingTime, i) => workingTimeToEvent(i, workingTime))
        // return holidayEvents.concat(workingTimeEvents)
    }, [channel])

    const openRegisterHolidayModal = (
        start: Date,
        end: Date
    ) => {
        modal.open(calendarSchedulerModalId, {start, end})
    };

    const onClickRegisterHoliday = () => {
        if (!channel) return

        const start = moment().tz(channel.config.timezone).startOf('day').toDate()
        const end = moment.utc(start).add(1, 'days').toDate()

        openRegisterHolidayModal(start, end)
    }

    const handleEditEventSelectAndOpenModal = (clickInfo: EventClickArg) => {
        // Ignore working-time
        if (clickInfo.event.id.startsWith('workingTime-')) return

        const { holiday } = clickInfo.event.extendedProps

        modal.open(
            calendarSchedulerModalId,
            {
                start: moment(`${holiday.start}Z`).toDate(),
                end: moment(`${holiday.end}Z`).toDate(),
                holiday,
                eventId: clickInfo.event.id
            }
        )
    };

    const selectCalendar = (args: DateSelectArg) => {

        if (!channel) return

        const convertDate = (input: Date) => {
            const timeDifference = moment.tz(channel.config.timezone).utcOffset()
            return moment(input).subtract(timeDifference, 'minutes').toDate()
        }

        openRegisterHolidayModal(convertDate(args.start), convertDate(args.end))
    }

    if (!channel) return null

    return (
        <WorkingTimeCalendarWrapper>
            <section id='wk-calendar' className='mb-3'>
                <div className='d-flex justify-content-between mb-2 align-items-center'>
                    <Button className="btn-rounded" onClick={onClickRegisterHoliday}>
                        {intl.formatMessage({id: 'i100073'})}
                    </Button>
                    <div>
                        <p className='mb-0'>
                            {intl.formatMessage({id: 'i000059'})}
                            {` : `}
                            {channel.config.timezone}
                        </p>
                    </div>
                </div>
                <FullCalendar
                    ref={(_ref) => setCalendarRef(_ref)}
                    eventStartEditable={false}
                    displayEventTime={false}
                    plugins={[
                        timeGridPlugin,
                        dayGridPlugin,
                        interactionPlugin
                    ]}
                    initialView="dayGridMonth"
                    headerToolbar={{
                        left: "prev",
                        center: "title",
                        right: 'next'
                        // right: "dayGridMonth,timeGridWeek,timeGridDay",
                    }}
                    aspectRatio={2.5}
                    timeZone={channel.config.timezone}
                    locale="ko"
                    weekends={true}
                    select={selectCalendar}
                    eventClick={handleEditEventSelectAndOpenModal}
                    events={initialEvents}
                    longPressDelay={1000}
                    eventLongPressDelay={1000}
                    selectLongPressDelay={1000}
                    selectable={true}
                    allDaySlot={true}
                    editable={true}
                    datesSet={(_) => {
                        if (calendarRef) {
                            setCalendarYear(calendarRef.getApi().getDate().getFullYear())
                        }
                    }}
                    // eventContent={renderEventContent}
                    dayHeaderClassNames='dayHeaderClassNames'
                    dayCellClassNames={function(_) {
                        return 'dayCellClassNames'
                    }}
                    weekNumberClassNames='weekNumberClassNames'
                    buttonText={{
                        today: intl.formatMessage({id: 'i100062'}),
                        month: intl.formatMessage({id: 'i000044'}),
                        week: intl.formatMessage({id: 'i100063'}),
                        day: intl.formatMessage({id: 'i000043'}),
                        list: intl.formatMessage({id: 'm02'}),
                    }}
                />
            </section>

            {calendarRef &&
                <section id='wk-holiday-list'>
                    <WorkingTimeHolidayList channelId={channelId} calendarRef={calendarRef} displayYear={calendarYear}/>
                </section>
            }

            {calendarRef &&
                <WorkingTimeCalendarModal channelId={channelId} calendarRef={calendarRef}/>
            }
        </WorkingTimeCalendarWrapper>
    );
};

// function renderEventContent(eventInfo: EventContentArg) {
//     return <WorkingTimeEvent eventInfo={eventInfo}/>
// }
//
// const WorkingTimeEvent: React.FC<{eventInfo: EventContentArg}> = ({eventInfo}) => {
//     return (
//         <WorkingTimeEventWrapper className='w-100 text-center text-red'>
//             <b className='fc-working-time-title'>{eventInfo.event.title}</b>
//         </WorkingTimeEventWrapper>
//         )
// }

const WorkingTimeCalendarWrapper = styled.div `
  padding-right: 5%;
  padding-left: 5%;
  
  .fc-day-today {
    background-color: unset !important;
  }
  
  .fc-event {
    cursor: pointer;
  }
  
  .fc-button {
    box-shadow: none !important;
  }
  
  
  .fc-toolbar {
    justify-content: center;
  }

  .fc-toolbar-title {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .fc-button {
    background-color: white;
    color: black;
    border: none
  }
  
  
`