import React, {useCallback, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {LiveChat, LiveChatFileArchive} from "../../model/livechat";
import styled from "styled-components";
import moment from "moment";
import Viewer from "react-viewer";
import {FileMessage, Message, TextMessage} from "../../model/message";
import {DefaultExtensionType, defaultStyles, FileIcon} from "react-file-icon";
import ReactPlayer from "react-player";
import {CenteredIcon, UnStyledButton} from "../UnstyledBootstrap";
import usePlayer from "../../hook/usePlayer";
import FloatingToolbar from "../FloatingToolbar";
import FileDownloader from "../FileDownloader";
import useFileArchives from "../../query/useFileArchives";
import {useQueryClient} from "@tanstack/react-query";
import {SocketEvent, useSocketSubscribe} from "../../socket";
import FullAreaSpinner from "../FullAreaSpinner";
import ChatPanelEmptyMessage from "./ChatPanelEmptyMessage";
import fileArchiveRepository from "../../repository/FileArchiveRepository";
import electronRuntime from "../../core/electronRuntime";
import {useSetRecoilState} from "recoil";
import downloadFilesState from "../../recoil/downloadFiles";
import {v4} from "uuid";
import toast from "react-hot-toast";
import useSocketIOSubscribe from "../../socket/useSocketIOSubscribe";


const LiveChatFileArchiveCard: React.FC<{liveChat: LiveChat}> = ({liveChat}) => {
    const intl = useIntl();

    const queryClient = useQueryClient();

    const setDownloadFiles = useSetRecoilState(downloadFilesState);

    const {data: fileArchives, isError, isSuccess, status, refetch} = useFileArchives(liveChat.channelId, liveChat._id);

    const [expanded, setExpanded] = useState(false);
    const [downloadAllDisabled, setDownloadAllDisabled] = useState(false);

    useSocketSubscribe<Message>(SocketEvent.MESSAGE, (message) => {
        const now = Date.now();
        message.blocks.forEach((block, index) => {
            if (checkFileBlock(block.value)) {
                const newFileArchive = {
                    _id: `fa${now}${index}`,
                    liveChatId: message.liveChatId,
                    chatId: message.channelId,
                    filename: block.value.filename,
                    contentType: block.value.contentType,
                    size: block.value.size,
                    url: block.value.url,
                    sender: message.sender,
                    createdAt: message.createdAt
                }

                queryClient.setQueryData<LiveChatFileArchive[]>(['fileArchives', message.channelId, message.liveChatId], (oldValue => {
                    return [newFileArchive, ...oldValue ?? []]
                }))
            }
        })
    });

    const size = useMemo(() => {
        return fileArchives?.length ?? 0;
    }, [fileArchives]);

    const height = useMemo(() => {
        const itemHeight = 55;
        if (size === 0) {
            return null;
        }
        else if (size < 6) {
            return itemHeight * 5 + 40;
        }
        else {
            return itemHeight * (expanded ? size : 5) + 40;
        }
    }, [size, expanded]);

    const onClickDownloadAll = async (e: React.MouseEvent) => {
        e.preventDefault();
        setDownloadAllDisabled(true);

        await toast.promise(
            fileArchiveRepository.downloadAllFiles(liveChat.channelId, liveChat._id),
            {
                loading: intl.formatMessage({id: "i200074"}),
                success: ({ data }) => {
                    const blob = new Blob([data], {type: "application/zip"});
                    const url = window.URL.createObjectURL(blob);
                    const filename = "file_collection.zip"

                    if (electronRuntime) {
                        const id = v4();
                        electronRuntime.send('download', {id, url, filename});
                        setDownloadFiles(prev => [
                            { id, url, filename },
                            ...prev
                        ])
                    } else {
                        const link = document.createElement("a");
                        link.href = url;
                        link.download = filename;
                        link.click();
                    }
                    setDownloadAllDisabled(false);
                    return intl.formatMessage({id: "i200075"})
                },
                error: () => {
                    setDownloadAllDisabled(false);
                    return intl.formatMessage({id: "i200073"})
                }
            }
        );
    }

    const checkFileBlock = useCallback((v: TextMessage | FileMessage): v is FileMessage => {
        return 'filename' in v
    }, []);

    useSocketIOSubscribe(SocketEvent.RECONNECT, async () => refetch().finally());

    return (
        <FileArchiveWrapperStyle style={{height: height ? `${height}px` : undefined}}>
            {status === 'loading' && (
                <FullAreaSpinner size="sm" />
            )}
            {isSuccess && (
                <>
                    {fileArchives?.slice(0, expanded ? size : 5).map(archive => (
                        <FileArchiveItem key={archive._id} fileArchive={archive} />
                    ))}
                    {size > 5 && (
                        <div className="d-grid mt-1"
                             style={{gridTemplateColumns: 'repeat(2, 2fr)', gridGap: "1rem"}}>
                            <UnStyledButton className="text-center py-0" onClick={() => setExpanded(prev => !prev)}>
                                <i className={`font-20 mdi ${expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'}`}/>
                            </UnStyledButton>
                            <UnStyledButton className="text-center py-0" onClick={onClickDownloadAll} disabled={downloadAllDisabled}>
                                {intl.formatMessage({id: "i200072"})}
                                <i className='mdi mdi-download font-18 ms-1'/>
                            </UnStyledButton>
                        </div>
                    )}
                    {size === 0 && <ChatPanelEmptyMessage message={intl.formatMessage({id: 'i100044'})} />}
                </>
            )}
            {isError && (
                <small className="text-muted variant-message">{intl.formatMessage({id: 'i100047'})}</small>
            )}
        </FileArchiveWrapperStyle>
    )

    // if (status === 'loading') {
    //     return (
    //         <Spinner size="sm" animation="border" />
    //     );
    // }
    // else {
    //
    // }

    // return (
    //     <FileArchiveWrapperStyle>
    //         {fileArchives?.slice(0, expanded ? size : 5).map(archive => (
    //             <FileArchiveItem key={archive._id} fileArchive={archive} />
    //         ))}
    //         {size > 5 && (
    //             <div className="d-grid mt-1">
    //                 <UnStyledButton className="text-center py-0" onClick={toggleCollapse}>
    //                     <i className={classNames('font-20 mdi', {'mdi-chevron-up': expanded, 'mdi-chevron-down': !expanded})} />
    //                 </UnStyledButton>
    //             </div>
    //         )}
    //
    //         {(isSuccess && size === 0) && <small className="text-muted">{intl.formatMessage({id: 'i100044'})}</small>}
    //         {isError && <small className="text-muted">{intl.formatMessage({id: 'i100047'})}</small>}
    //     </FileArchiveWrapperStyle>
    // );
};

const FileArchiveItem: React.FC<{fileArchive: LiveChatFileArchive}> = ({fileArchive}) => {
    const type = useMemo(() => {
        const [_type] = fileArchive.contentType?.split('/') ?? ['unknown'];
        return _type
    }, [fileArchive.contentType]);

    switch (type) {
        case 'image':
            return <ImageFileArchive fileArchive={fileArchive} />
        case 'video':
            return <VideoFileArchive fileArchive={fileArchive} />
        case 'audio':
            return <AudioFileArchive fileArchive={fileArchive} />
        default:
            return <DefaultFileArchive fileArchive={fileArchive} />
    }

}

const ImageFileArchive: React.FC<{fileArchive: LiveChatFileArchive}> = ({fileArchive}) => {
    const [showImage, setShowImage] = useState(false);

    const onClickShowImage = (e: React.MouseEvent) => {
        e.preventDefault();
        setShowImage(true);
    };

    const hideImage = () => {
        setShowImage(false);
    }

    return (
        <FileArchiveItemStyle data-floating-toolbar="true">
            <div className="file-archive" role="button" onClick={onClickShowImage}>
                <FileThumbnailStyle>
                    <img width="100%" height="100%" src={fileArchive.url} alt="Upload thumbnail" />
                </FileThumbnailStyle>

                <FileDescription fileArchive={fileArchive} />

                {/*<div className="actions ml-1">*/}
                {/*    /!*<i role='button' className='dripicons-backspace d-block text-dark mb-1'/>*!/*/}
                {/*    <i role={downloading ? "" : 'button'} className={`mdi mdi-download d-block text-${downloading ? 'secondary' : 'dark'}`} onClick={onClickDownload}/>*/}
                {/*</div>*/}
                {/*<a href={'/'} ref={downloadLinkRef} className="d-none">download</a>*/}
            </div>
            <Viewer visible={showImage}
                    onMaskClick={hideImage}
                    onClose={hideImage}
                    images={[{src: fileArchive.url!, downloadUrl: fileArchive.url, alt: fileArchive.filename}]}
                    rotatable={false}
                    scalable={false}
                    changeable={false}
                    noNavbar={true}/>

            <FloatingToolbar placement={'center'}>
                <FileDownloader url={fileArchive.url} filename={fileArchive.filename ?? ''}>
                    <CenteredIcon size={28} className="mdi mdi-download font-20" />
                </FileDownloader>
            </FloatingToolbar>
        </FileArchiveItemStyle>
    );
}


const VideoFileArchive: React.FC<{fileArchive: LiveChatFileArchive}> = ({fileArchive}) => {
    const {playing, play, stop} = usePlayer(fileArchive._id);

    const _onClick = (e: React.MouseEvent) => {
        e.preventDefault();

        play();
    }

    // stop propagation event to FileArchiveItemStyle
    const onClickPlayerRoot = (e: React.MouseEvent) => {
        e.stopPropagation();
    }

    const onClickClose = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        stop();
    }

    return (
        <FileArchiveItemStyle data-floating-toolbar="true">
            <div className="file-archive" role="button" onClick={_onClick} data-floating-toolbar="true">
                {playing && (
                    <div className="video-wrapper" onClick={onClickPlayerRoot}>
                        <ReactPlayer style={{backgroundColor: '#000'}}
                                     url={fileArchive.url}
                                     width="100%"
                                     height="280px"
                                     controls={true}
                                     playing={playing}
                                     muted={false}
                                     config={{
                                         file: {
                                             attributes: {
                                                 controlsList: "nodownload",
                                                 disablePictureInPicture: true
                                             }
                                         }
                                     }} />
                        <CenteredIcon size={24} className="mdi mdi-close font-22 close-btn" onClick={onClickClose} />
                    </div>
                )}
                {!playing && (
                    <>
                        <FileThumbnailStyle>
                            <ReactPlayer style={{backgroundColor: '#000'}}
                                         url={fileArchive.url}
                                         width={36}
                                         height={36}
                                         controls={false}
                                         playing={false}
                                         config={{
                                             file: {
                                                 attributes: {
                                                     controlsList: "nodownload",
                                                     disablePictureInPicture: true
                                                 }
                                             }
                                         }} />
                        </FileThumbnailStyle>

                        <FileDescription fileArchive={fileArchive} />
                    </>
                )}
            </div>
            {!playing && (
                <FloatingToolbar placement={'center'}>
                    <FileDownloader url={fileArchive.url} filename={fileArchive.filename ?? ''} style={{height: '28px', width: '28px'}}>
                        <CenteredIcon size={28} className="mdi mdi-download font-20" />
                    </FileDownloader>
                </FloatingToolbar>
            )}
        </FileArchiveItemStyle>
    );
}

const AudioFileArchive: React.FC<{fileArchive: LiveChatFileArchive}> = ({fileArchive}) => {
    const {playing, play, stop} = usePlayer(fileArchive._id);

    const _onClick = (e: React.MouseEvent) => {
        e.preventDefault();
        play();
    }

    const onClickClose = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        stop();
    }

    const onEnded = () => {
        stop();
    }

    return (
        <FileArchiveItemStyle data-floating-toolbar="true">
            <div className="file-archive" role="button" onClick={_onClick} data-floating-toolbar="true">
                {playing && (
                    <div className="audio-wrapper">
                        <ReactPlayer url={fileArchive.url}
                                     width={300}
                                     height={39}
                                     controls={true}
                                     playing={playing}
                                     config={{
                                         file: {
                                             attributes: {
                                                 controlsList: "nodownload",
                                                 disablePictureInPicture: true
                                             }
                                         }
                                     }}
                                     onEnded={onEnded} />
                        <CenteredIcon size={24} className="mdi mdi-close font-22 close-btn" onClick={onClickClose} />
                    </div>

                )}
                {!playing && (
                    <>
                        <FileThumbnailStyle>
                            <span className="audio-file-thumbnail mdi mdi-play" />
                        </FileThumbnailStyle>

                        <FileDescription fileArchive={fileArchive} />
                    </>
                )}

            </div>
            {!playing && (
                <FloatingToolbar placement={'center'}>
                    <FileDownloader url={fileArchive.url} filename={fileArchive.filename ?? ''}>
                        <CenteredIcon size={28} className="mdi mdi-download font-20" />
                    </FileDownloader>
                </FloatingToolbar>
            )}
        </FileArchiveItemStyle>
    );
}

const DefaultFileArchive: React.FC<{fileArchive: LiveChatFileArchive}> = ({fileArchive}) => {
    const extension: DefaultExtensionType = useMemo(() => {
        const split = fileArchive.filename?.split('.') ?? [];
        if (split.length > 1) {
            return (split.pop() || 'txt') as DefaultExtensionType;
        }

        return 'txt';
    }, [fileArchive]);

    return (
        <FileArchiveItemStyle data-floating-toolbar="true">
            <div className="file-archive" role="button">
                <FileThumbnailStyle className="position-relative">
                    <div className="no-media-file">
                        <FileIcon extension={extension} {...defaultStyles[extension]} fold={false} />
                    </div>
                </FileThumbnailStyle>

                <FileDescription fileArchive={fileArchive} />
            </div>
            <FloatingToolbar placement={'center'}>
                <FileDownloader url={fileArchive.url} filename={fileArchive.filename ?? ''}>
                    <CenteredIcon size={28} className="mdi mdi-download font-20" />
                </FileDownloader>
            </FloatingToolbar>
        </FileArchiveItemStyle>
    );
}

const FileDescription: React.FC<{fileArchive: LiveChatFileArchive}> = ({fileArchive}) => {

    return (
        <div className="file-description">
            <p className="filename">{fileArchive.filename}</p>
            <div className="d-flex justify-content-between">
                <span className="sender-name">{fileArchive.sender?.name}</span>
                <span className="date text-mono">{moment.utc(fileArchive.createdAt).local().format("YYYY-MM-DD A hh:mm")}</span>
            </div>
        </div>
    );
}

const FileArchiveWrapperStyle = styled.div`
  transition: height 0.1s ease-in-out;
  //overflow-y: hidden;
  //overflow-x: visible;
  overflow-y: clip;
`;

const FileArchiveItemStyle = styled.div `
  
  margin: 0 -0.5rem;
  
  &:hover {
    background-color: var(--ct-light);
    border-radius: 0.25rem;
  }
  
  .file-archive {
    display: flex;
    align-items: center;
    position: relative;
    padding: .5rem;

    .file-description {
      display: flex;
      flex-direction: column;
      min-width: 0;
      flex-grow: 1;
      .filename, .sender-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
      }

      .filename {
        font-weight: 700;
        margin-bottom: 0;
      }

      .sender-name {
        width: 100px;
      }

      .date {
        font-size: 13px;
      }
    }

    & > .actions {
      display: none;
    }

    &:hover > .actions {
      display: unset;
    }

    .audio-wrapper {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      align-items: center;

      .close-btn {
        display: none;
      }

      &:hover {
        .close-btn{
          display: inline-flex;
        }
      }
    }

    .video-wrapper {
      position: relative;

      .close-btn {
        display: none;
        position: absolute;
        top: 8px;
        right: 8px;
      }

      &:hover {
        .close-btn {
          display: inline-flex;
        }
      }
    }
  }
`

const FileThumbnailStyle = styled.div`
  flex-shrink: 0;
  margin-right: 0.75rem;
  height: 36px;
  width: 36px;
  border-radius: 0.25rem;
  overflow: hidden;
  //border: 1px solid var(--ct-light);
  background-color: var(--ct-light);

  .audio-file-thumbnail {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--ct-blue);
    height: 100%;
    width: 100%;
    font-size: 24px;
    color: #fff;
  }

  .no-media-file {
    position: absolute;
    top: -6px;
  }

  img {
    object-fit: cover;
  }
`;

export default LiveChatFileArchiveCard;