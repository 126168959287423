import {Manager} from "./manager";
import {Message, MessageSender} from "./message";
import {ChatUser} from "./chatUser";
import {DictType} from "./index";

export type LiveChat = {
    _id: string;
    chatId?: string;
    channelId: string;
    categoryId?: string;
    type?: string;
    state: LiveChatState;
    user: ChatUser,
    assigneeId: string;
    assignedAt: string;
    respondedAt: string;
    solvedAt: string;
    closedAt: string;
    expiredAt: string;
    canceled: boolean;
    canceledAt: string;
    satisfaction?: string;
    followers: string[];
    managers: Manager[];
    chatLink?: string;
    latestMessage?: Message;
    priority: number;
    requestedOutsideWorkingTimes: boolean;
    // unreadMessages?: Message[];
    since: string;
    createdAt: string;
}

export type LiveChatState = 'unassigned'|'assigned'|'responding'|'holding'|'solved'|'closed'|'expired';

export enum LiveChatStatus {
    UNASSIGNED = 'unassigned',
    IN_PROGRESS = 'in_progress',
    HOLDING = 'holding',
    COMPLETED = 'completed'
}

export type LiveChatMemo = {
    _id: string;
    liveChatId: string;
    contents: string;
};

// export type LiveChatContext = 'inbox'|'managers-inbox'|'responding'|'unassigned'|'holding'|'complete';
// export type LiveChatContext = 'inbox'|'managers-inbox'|'complete'|string;
// export type LiveChatContext = 'search'|'unassigned'|'inbox.responding'|'inbox.holding'|'inbox.completed'|'unread'|'managers.inbox.responding'|'managers.inbox.holding'|'managers.inbox.completed'|string;
export enum LiveChatContext {
    SEARCH = 'search',
    UNASSIGNED = 'unassigned',
    INBOX = 'inbox',
    ALL_INBOX = 'all.inbox',
    UNREAD = 'unread',
    FAVORITE = 'favorite',
    DASHBOARD = 'dashboard',
    REQUESTED_OUTSIDE_WORKING_TIMES = 'requestedOutsideWorkingTimes'
}

export type LiveChatHistory = {
    _id: string
    channelId: string
    action: LiveChatState|'leave'|'join'|'change_assignee'|'resume'
    userId: string
    data?: DictType<any>
    createdAt: string
}

export type LiveChatFileArchive = {
    _id: string
    liveChatId: string
    chatId: string
    filename?: string
    contentType?: string
    size?: number
    url: string
    sender?: MessageSender
    createdAt: string;
}


export interface PanelSetting {
    id: string;
    i18n: string;
    show: boolean;
    collapse: boolean;
    isCustom?: boolean;
}


export interface LiveChatTotal {
    unassignedCount: number;
    assignedCount: number;
    favoriteCount: number;
    respondingCount: number;
    expiredCount: number;
    holdingCount: number;
    managerAssignedCount: number;
    managerRespondingCount: number;
    managerExpiredCount: number;
    managerHoldingCount: number;
    managerUnreadCount: number;
    requestedOutsideWorkingTimesCount: number;
}

export type LiveChatWithSearch = Omit<LiveChat, "latestMessage">
