import React from "react";
import moment from "moment/moment";


const DatelineMessage = React.memo<{date: string}>(({date}) => {
    return (
        <p className="hr-sect">
            {moment.utc(date).local().format('LL')}
        </p>
    );
});

export default DatelineMessage;