import {DictType} from "./index";
import {LiveChatHistory, LiveChatWithSearch} from "./livechat";
import {ChatbotMessageInfo} from "./chatbot";


export type Message = {
    _id: string;
    channelId: string;
    liveChatId: string;
    blocks: MessageBlock[];
    sender: MessageSender;
    createdAt: string;
    noBadge?: boolean;
    // showLastConfirmedHelpMessage?: boolean;
    // showDateLine?: boolean;
    // managerHistories?: LiveChatHistory[];
    sendId?: string;
    shouldAck?: boolean;
    rejectedBannedWords?: string[];
};

export type TextMessage = {
    text: string
    html: string
}

export type FileMessage = {
    filename: string,
    url: string,
    size?: number,
    contentType?: string
};

export type MessageBlock = {
    type: 'text' | 'file' | string;
    value: TextMessage | FileMessage;
    meta?: DictType<any>[];
};

export type MessageSender = {
    userId: string;
    name?: string;
    type: string;
    alias: string;
};

export type MessageTemplate = {
    _id: string;
    channelId: number;
    userId: string;
    name: string;
    blocks: MessageBlock[];
    public: boolean;
    createAt: string;
};

export type PredictionSource = {
    id: string,
    context: string,
    sentence: string,
    value: string,
    score: string
};

export type MessageForm = {
    blocks: {
        type: string;
        value: TextMessage | FileMessage;
        meta?: DictType<any>[]
    }[];
    senderType: 'manager'|'user';
    senderUserId: string;
    createdAt: string;
    sendId?: string;
    ignoreBannedWords?: boolean;
}

export interface MessageUI {
    type: 'message' | 'chatbotMessage' | 'lastConfirmedMessage' | 'managerHistory' | 'dateline' | 'endChatbotMessage'
    data?: Message | ChatbotMessageInfo | LiveChatHistory | string
    date: string
}

export enum MessageQueryDirection {
    PREV = 'prev',
    NEXT = 'next',
    EXACT = 'exact'
}

export interface MessageSearchResult extends Message {
    livechat: LiveChatWithSearch
}
