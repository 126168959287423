import {atom} from "recoil";
import {ChatSearchTracking} from "../../model/chatSearch";
import {Message} from "../../model/message";

/* To highlight search result in message list, this recoil state will be used.*/
const chatSearchTrackingState = atom<ChatSearchTracking>({
    key: 'chatSearchTrackingState',
    default: {
        trackingMessage: {} as Message,
        searchText: "",
        isTracking: false,
    }
});

export default chatSearchTrackingState;
