import React, {useMemo} from "react";
import moment from "moment";

const MessageSentAt: React.FC<{at: string, expanded: boolean}> = ({at, expanded}) => {
    const text = useMemo(() => {
        return expanded ? moment.utc(at).local().format("YYYY-MM-DD hh:mm A") : moment.utc(at).local().format('LT')
    }, [expanded, at])

    return (
        <small className="text-muted ml-1">
            {text}
        </small>
    )
}

export default MessageSentAt