import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosError, AxiosResponse} from "axios";
import userCategoryRepository from "../../repository/UserCategoryRepository";


const useDeleteUserCategory = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, string>(['userCategories', 'delete', channelId], async (categoryId: string) => {
        try {
            return await userCategoryRepository.remove(channelId, categoryId);
        } catch (e) {
            throw e;
        }
    }, {
        onSuccess: () => {
            return queryClient.invalidateQueries(['userCategories', channelId], {exact: true});
        }
    });
}

export default useDeleteUserCategory;
