import {useMutation, useQueryClient} from "@tanstack/react-query";
import {RoleForm} from "../../model/role";
import {LIVE_CHAT_API_HOST} from "../../core/variables";
import {toLiveChatError} from "../../error";
import oauthAxios from "../../core/oauthAxios";


const useUpdateManagementUnitRole = (channelId: string) => {
    const queryClient = useQueryClient();

    return useMutation(['managementUnitRoles', 'update', channelId], async (params: {id: string, data: RoleForm}) => {
        try {
            const {data} = await oauthAxios.put(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/roles/${params.id}`, params.data);
            return data.result;
        } catch (e) {
            throw toLiveChatError(e);
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(['roles', channelId], {exact: true});
        }
    });
}

export default useUpdateManagementUnitRole;
