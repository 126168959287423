import React, {useEffect, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import SettingViewWrapper from "../../component/setting/SettingViewWrapper";
import useChannel from "../../query/channel/useChannel";
import {useParams} from "react-router-dom";
import {LiveChatPriority} from "../../model/channel";
import styled from "styled-components";
import {CenteredIcon} from "../../component/UnstyledBootstrap";
import {Form} from "react-bootstrap";
import {useClickOutside} from "@react-hookz/web";
import classNames from "classnames";
import useUpdateLiveChatPriority from "../../query/channel/useUpdateLiveChatPriority";
import toast from "react-hot-toast";
import {HexColorInput, HexColorPicker} from "react-colorful";


const LiveChatPrioritySettingView: React.FC = () => {
    const intl = useIntl();

    const {channelId} = useParams<{channelId: string}>();

    const {data: channel} = useChannel(channelId!);

    const priorities = useMemo(() => {
        return channel?.config.priorities.sort((a, b) => a.priority > b.priority ? -1 : 1) ?? [];
    }, [channel]);

    return (
        <SettingViewWrapper>
            <div className="setting-title">
                <h3>{intl.formatMessage({id: 'i000380'})}</h3>
                <p>
                    {intl.formatMessage({id: 'i000381'})}
                </p>
            </div>

            <div className="d-flex flex-column align-items-start">
                {priorities.map((priority, index) => (
                    <PriorityItem key={priority.priority} index={index} channelId={channelId!} priority={priority} />
                ))}
            </div>

        </SettingViewWrapper>
    );
}

const PriorityItem: React.FC<{index: number, channelId: string, priority: LiveChatPriority}> = ({index, channelId, priority}) => {
    const intl = useIntl();

    const colorPickerRef = useRef<HTMLDivElement>(null);
    const aliasRef = useRef<HTMLInputElement>(null);

    const [color, setColor] = useState(priority.color);
    const [editingAlias, setEditingAlias] = useState(false);
    const [editingColor, setEditingColor] = useState(false);

    useClickOutside(colorPickerRef, () => {
        setEditingColor(false);
        save();
    });

    const {mutate: updatePriority} = useUpdateLiveChatPriority(channelId);

    const save = () => {
        const alias = aliasRef.current?.value || priority.alias;

        if (color === priority.color && alias === priority.alias) {
            setEditingAlias(false);
            return;
        }

        updatePriority({priority: priority.priority, color: color, alias: alias}, {
            onSuccess: () => {
                setEditingAlias(false);
            },
            onError: () => {
                toast.error(intl.formatMessage({id: 'e000001'}));
            }
        });
    };

    const onChangeColor = (color: string) => {
        if (color !== priority.color) {
            setColor(color);
        }
    }

    const onClickColorPickerTrigger = (e: React.MouseEvent) => {
        e.preventDefault();
        setEditingColor(true);
    }

    const onClickAliasEditBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        setEditingAlias(oldData => !oldData);
    }

    const onClickAliasSaveBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        save();
    }

    const onKeyDownAlias = (e: React.KeyboardEvent) => {
        if (e.key.toLowerCase() === 'enter') {
            save();
        }
    }

    // for automatic update
    useEffect(() => {
        if (aliasRef.current && priority.alias) {
            aliasRef.current.value = priority.alias;
        }

        setColor(priority.color);
    }, [priority])

    return (
        <PriorityItemStyle>
            <span className="number">
                {index + 1}.
            </span>
            <span className="name">
                {priority.name}
            </span>
            <div className="divider" />
            <div className="color-selector-wrapper">
                <div className="color-picker">
                    <div className="swatch" style={{backgroundColor: color}} onClick={onClickColorPickerTrigger} />
                    {editingColor && (
                        <div className="popover" ref={colorPickerRef}>
                            <HexColorPicker color={color} onChange={onChangeColor} />
                            <HexColorInput color={color} onChange={onChangeColor} className="form-control form-control-sm hex-input-form" />
                        </div>
                    )}
                </div>
            </div>
            <div className="divider" />
            {!editingAlias && (
                <span className="alias" title={priority.alias || '별칭을 입력하세요.'}>
                    {priority.alias ?? priority.name}
                </span>
            )}
            {editingAlias && (
                <Form.Control className="alias-form"
                              defaultValue={priority.alias || ''}
                              onKeyDown={onKeyDownAlias}
                              ref={aliasRef} />
            )}

            {editingAlias && (
                <CenteredIcon size={20}
                              className="mdi mdi-check font-18 text-primary"
                              role="button"
                              onClick={onClickAliasSaveBtn} />
            )}

            <CenteredIcon size={20}
                          className={classNames('mdi font-18', {'mdi-square-edit-outline': !editingAlias, 'mdi-close': editingAlias})}
                          role="button"
                          onClick={onClickAliasEditBtn} />
        </PriorityItemStyle>
    )
}


const PriorityItemStyle = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 0 0 0.75rem;
  padding: 0 0.75rem;
  height: 48px;
  border: 1px solid var(--ct-input-border-color);
  background-color: var(--ct-light);
  border-radius: 4px;
  
  .number {
    width: 20px;
  }
  
  .name {
    width: 100px;
  }
  
  .alias {
    padding-left: 6px;
    margin-left: 6px;
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .alias-form {
    margin: 0 6px;
    width: 154px;
    padding: 4px 6px !important;
    line-height: 1 !important;
    font-size: 0.9rem;
  }
  
  .divider {
    background-color: var(--ct-input-border-color);
    width: 1px;
    height: 20px;
    margin: 0 0.375rem;
  }
  
  .color-selector-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    
    .color-picker {
      position: relative;
      
      .swatch {
        width: 23px;
        height: 23px;
        border-radius: 50%;
        cursor: pointer;
        border: 3px solid #fff;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
      }
      
      .popover {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: calc(100% + 2px);
        left: 0;
        border-radius: 9px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        padding: 0.75rem;
        
        .hex-input-form {
          text-align: center;
          //width: 140px;
          margin-top: 0.6rem;
        }
      }
    }
  }
  
  
  .form-control-color {
    width: 20px !important;
    height: 20px !important;
    min-height: unset !important;
    padding: 0;

    ::-webkit-color-swatch {
      height: 20px !important;
    }
  }
`

export default LiveChatPrioritySettingView;