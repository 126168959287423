import {useQuery} from "@tanstack/react-query";
import {LIVE_CHAT_API_HOST} from "../../core/variables";
import {LiveChatError, toLiveChatError} from "../../error";
import {Invitation} from "../../model/member";
import oauthAxios from "../../core/oauthAxios";


const getData = async (channelId: string) => {
    try {
        const {data} = await oauthAxios.get(`${LIVE_CHAT_API_HOST}/v1/channels/${channelId}/invitations`);
        return data.result;
    } catch (e) {
        throw toLiveChatError(e);
    }

}


const useInvitations = (channelId: string) => {
    return useQuery<Invitation[], LiveChatError>(['invitations', channelId], async () => getData(channelId), {suspense: false});
};


export default useInvitations;