import React, {useRef, useState} from "react";
import {useIntl} from "react-intl";
import useModal, {MaruModal} from "../../hook/useModal";
import useCreateCustomSnippet from "../../query/customSnippet/useCreateCustomSnippet";
import * as yup from "yup";
import * as uuid from "uuid";
import toast from "react-hot-toast";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import customSnippetRepository from "../../repository/CustomSnippetRepository";
import _ from "lodash";
import useUserAttributes from "../../query/useUserAttributes";
import {QueryParamRef, SnippetQueryParam} from "../../model/customSnippet";
import {useList} from "@react-hookz/web";

export const REGISTRATION_MODAL_ID = "custom-panel-customSnippet-registration-modal";

type CustomPanelSnippetRegistrationModalProps = {
    channelId: string,
}

const CustomPanelSnippetRegistrationModal: React.FC<CustomPanelSnippetRegistrationModalProps> = (props) => {
    const { channelId } = props;

    const intl = useIntl();
    const modal = useModal();

    const [invalid, setInvalid] = useState({ name: false, url: false });
    const [invalidUrl, setInvalidUrl] = useState(false);
    const [existed, setExisted] = useState(false);

    const [queryParams, {
        push: pushQueryParams,
        updateAt: updateAtQueryParams,
        removeAt: removeAtQueryParams,
        reset: resetQueryParams,
    }] = useList<{id: string, invalid: boolean}>([]);

    const nameRef = useRef<HTMLInputElement>(null);
    const urlRef = useRef<HTMLInputElement>(null);

    const queryParamRefs = useRef<QueryParamRef[]>([]);

    const { mutateAsync: createSnippet, isLoading: isCreateSnippetLoading } = useCreateCustomSnippet(channelId);
    const { data: userAttributes } = useUserAttributes(channelId);

    const onClickAddParam = () => {
        pushQueryParams({ id: uuid.v4(), invalid: false })
    };

    const onClickDeleteParam = (index: number) => {
        queryParamRefs.current = [];
        removeAtQueryParams(index);
    };

    const checkExisted = _.debounce(() => {
        const name = nameRef.current?.value.trim();
        if (!name) {
            setExisted(false);
            return;
        }

        if (!modal.status[REGISTRATION_MODAL_ID]) {
            return;
        }

        customSnippetRepository.existed(channelId, name).then(({ data }) => {
            setExisted(!!data.result);
        }).catch(() => {});
    }, 300)

    const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        checkExisted();
    }

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const name = nameRef.current?.value.trim() || '';
        const url = urlRef.current?.value.trim() || '';

        const invalid = { name: !name, url: !url };
        const validUrl = await yup.string().url().isValid(url);
        let invalidQueryParamExists = false;

        // collect query parameters
        let validQueryParams: SnippetQueryParam[] = [];
        const queryParamRegExp = new RegExp("^[a-zA-Z0-9._$-]+$");

        queryParamRefs.current.forEach((ref, index) => {
            if (ref.name?.value && queryParamRegExp.test(ref.name.value) && ref.attribute?.value) {
                validQueryParams.push({ name: ref.name.value, attribute: ref.attribute.value });
                updateAtQueryParams(index, { ...queryParams[index], invalid: false });
            } else {
                invalidQueryParamExists = true;
                updateAtQueryParams(index, { ...queryParams[index], invalid: true });
            }
        });

        if (Object.values(invalid).some(value => value) || !validUrl || existed || invalidQueryParamExists) {
            setInvalid(invalid);
            setInvalidUrl(!validUrl);
            return;
        }
        setInvalid({name: false, url: false});
        setInvalidUrl(false);


        await toast.promise(
            createSnippet({ channelId, name, url, queryParams: validQueryParams }),
            {
                loading: intl.formatMessage({id: "i200124"}),
                success: () => {
                    modal.close(REGISTRATION_MODAL_ID);
                    return intl.formatMessage({id: "i200097"});
                },
                error: () => {
                    return intl.formatMessage({id: "i200098"})
                }
            }
        )
    }

    const onExited = () => {
        setInvalid({ name: false, url: false });
        setInvalidUrl(false);
        setExisted(false);

        queryParamRefs.current = [];
        resetQueryParams();
    }

    const onClickCancelBtn = () => {
        modal.close(REGISTRATION_MODAL_ID);
    }

    return (
        <MaruModal id={REGISTRATION_MODAL_ID} size="lg" centered title={intl.formatMessage({id: "i200099"})} onExited={onExited}>
            <Modal.Body>
                <Form id="custom-panel-snippet-form" onSubmit={onSubmit}>
                    <Form.Group className="mb-2">
                        <Form.Label>{intl.formatMessage({id: "i200100"})}</Form.Label>
                        <Form.Control ref={nameRef}
                                      onChange={onChangeName}
                                      placeholder={intl.formatMessage({id: "i200101"})}
                                      isInvalid={invalid.name || existed}/>
                        <Form.Control.Feedback type="invalid">
                            {intl.formatMessage({id: existed ? "i200102" : "i000024"})}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>{intl.formatMessage({id: "i200103"})}</Form.Label>
                        <Form.Control ref={urlRef}
                                      placeholder={intl.formatMessage({id: "i200104"})}
                                      isInvalid={invalidUrl || invalid.url}/>
                        <Form.Control.Feedback type="invalid">
                            {intl.formatMessage({id: invalidUrl ? "i000209": "i000024"})}
                        </Form.Control.Feedback>
                        <Form.Text>{intl.formatMessage({id: "i200105"})}</Form.Text>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>{intl.formatMessage({id: "i200125"})}</Form.Label>

                        {queryParams.map((queryParam, index) => {
                            return (
                                <div key={queryParam.id} className="d-flex align-items-start mb-2">
                                    <div className="flex-grow-1">
                                        <Form.Control
                                            ref={(el: HTMLInputElement) => {
                                                if (el) {
                                                    if (!queryParamRefs.current[index]) {
                                                        queryParamRefs.current[index] = { name: null, attribute: null }
                                                    }
                                                    queryParamRefs.current[index].name = el;
                                                }
                                            }}
                                            isInvalid={queryParam.invalid}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {intl.formatMessage({id: "i200126"})}
                                        </Form.Control.Feedback>
                                    </div>
                                    <div className="d-flex align-items-center w-50">
                                        <Form.Select
                                            className="ms-2"
                                            ref={(el: HTMLSelectElement) => {
                                                if (el) {
                                                    if (!queryParamRefs.current[index]) {
                                                        queryParamRefs.current[index] = { name: null, attribute: null }
                                                    }
                                                    queryParamRefs.current[index].attribute = el;
                                                }
                                            }}
                                        >
                                            {userAttributes && userAttributes.map(attribute => (
                                                <option key={attribute.name} value={attribute.name}>
                                                    {intl.formatMessage({id: "i200121"}, {name: attribute.displayName, type: attribute.type})}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <i className="mdi mdi-trash-can-outline font-16 ms-2 cursor-pointer"
                                           onClick={() => onClickDeleteParam(index)}/>
                                    </div>
                                </div>
                            )
                        })}

                        <Button variant="outline-secondary" className="w-100" onClick={onClickAddParam}>
                            {intl.formatMessage({id: "i200127"})}
                        </Button>
                        <Form.Text>
                            {intl.formatMessage({id: "i200128"})}
                        </Form.Text>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" className="btn-rounded" variant="outline-secondary" onClick={onClickCancelBtn}>{intl.formatMessage({id: 'i000022'})}</Button>
                <Button type="submit" className="btn-rounded" form="custom-panel-snippet-form" disabled={isCreateSnippetLoading}>
                    {intl.formatMessage({id: 'i000023'})}
                    {isCreateSnippetLoading && <Spinner className="ms-1" animation="border" size="sm"/>}
                </Button>
            </Modal.Footer>
        </MaruModal>
    )
}

export default CustomPanelSnippetRegistrationModal;
