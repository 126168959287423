import customSnippetRepository from "../../repository/CustomSnippetRepository";
import {LiveChatError, toLiveChatError} from "../../error";
import {useQuery} from "@tanstack/react-query";
import {CustomSnippet} from "../../model/customSnippet";

const getData = async (channelId: string) => {
    try {
        const { data } = await customSnippetRepository.find(channelId);
        return data.result
    } catch (e) {
        throw toLiveChatError(e);
    }
}

const useCustomSnippets = (channelId: string) => {
    return useQuery<CustomSnippet[], LiveChatError>({
        queryKey: ['snippets', channelId],
        queryFn: async () => getData(channelId),
        suspense: false,
    })
};

export default useCustomSnippets;
