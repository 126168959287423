import styled from "styled-components";

export interface SnippetQueryParam {
    name: string;
    attribute: string;
}

export interface QueryParamRef {
    name: HTMLInputElement | null,
    attribute: HTMLSelectElement | null,
}

interface CustomSnippetBase {
    channelId: string;
    name: string;
    url: string;
    queryParams: SnippetQueryParam[];
}

export interface CustomSnippet extends CustomSnippetBase {
    _id: string;
    token: string;
    createdAt: string;
    updatedAt: string;
}

export interface CustomSnippetCreate extends CustomSnippetBase {}

export interface CustomSnippetUpdate extends CustomSnippetBase {
    _id: string
}

export const ComponentType = {
    TEXT: "text",
    LINK: "link",
    DIVIDER: "divider",
    IMAGE: "image",
    LIST: "list",
    KEY_VALUE: "key-value",
}

export interface CustomSnippetObject {
    snippet: string;
    data?: ComponentItem[];
    style?: object;
}

export const isCustomSnippetObject = (_object: unknown): _object is CustomSnippetObject => {
    return (_object instanceof Object && "snippet" in _object && "data" in _object)
}

export interface ComponentItem {
    type: string;
    data?: TextComponentData
        | LinkComponentData
        | DividerComponentData
        | ImageComponentData
        | KeyValueComponentData
        | InputComponentData
        | ComponentItem[]
    style?: object
}

interface ComponentDataBase {
    style?: object;
}

export interface TextComponentData extends ComponentDataBase{
    text?: string;
}

export const DefaultTextStyle = styled.div`
  margin: 0.5rem 0;
`;

export interface LinkComponentData extends ComponentDataBase{
    text?: string;
    url?: string;
}

export const DefaultLinkStyle = styled.div`
  cursor: pointer;
  text-decoration: underline;
`;

export interface DividerComponentData extends ComponentDataBase {}

export const DefaultDividerStyle = styled.hr`
`;

export interface ImageComponentData extends ComponentDataBase {
    url?: string;
    clickUrl?: string;
}

export const DefaultImageStyle = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 5px;
`

interface KeyValueItem<T> {
    key?: T;
    value?: T;
}

export interface KeyValueComponentData extends ComponentDataBase {
    items?: KeyValueItem<string>[];
    itemStyle?: KeyValueItem<object>;
}

export const DefaultKeyValueStyle = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  
  .key {
    font-size: 14px;
    width: 50px;
  }
  
  .value {
    margin-left: 1rem;
    font-size: 16px;
    max-width: 250px;
  }
`;

interface InputItem {
    label?: string;
    input?: "string" | "number" | "select"
    selectOptions?: string[]
}

interface InputItemStyle {
    label?: object;
    input?: object;
}

export interface InputComponentData extends ComponentDataBase {
    items?: InputItem[];
    itemStyle?: InputItemStyle;
    buttonLabel?: string
    buttonStyle?: object
}
