import React, {ChangeEvent, useEffect, useMemo, useRef, useState} from "react";
import SettingViewWrapper from "../../component/setting/SettingViewWrapper";
import {useIntl} from "react-intl";
import {Button, Form, InputGroup} from "react-bootstrap";
import useChannel from "../../query/channel/useChannel";
import {useParams} from "react-router-dom";
import {Content, Editor, EditorElement, EditorRef, FileInfo} from "@42maru/content-editor";
import useUploadFiles from "../../query/fileGroup/useUploadFiles";
import {FileGroup} from "../../model/fileGroup";
import useUpdateChannel from "../../query/channel/useUpdateChannel";
import {FileMessage, MessageBlock} from "../../model/message";
import {LiveChatAutoCloseSetting} from "../../model/channel";
import toast from "react-hot-toast";
import TooltipIcon from "../../component/TooltipIcon";
import {isSupportedFileExtension, isSupportedFileSize} from "../../util/fileUtil";


type AutoCloseReasonType = 'no-user-message' | 'no-manager-message' | 'force-close-live-chat';
type INVALIDATION_TYPE = {waitingTime: boolean, message: boolean}
const INIT_INVALIDATION = {waitingTime: false, message: false}

const LiveChatAutoCloseConfigView: React.FC = () => {
    const intl = useIntl();

    const {channelId} = useParams<{channelId: string}>();

    const {data: channel} = useChannel(channelId!);

    const {mutateAsync: updateChannel} = useUpdateChannel(channelId!);

    // noUserMessage - 고객의 추가 문의가 없는 경우
    // noManagerMessage - 상담사가 회신을 안하는 경우
    const [enabled, setEnabled] = useState({
        noUserMessage: channel?.config.liveChatAutoCloseSetting?.enabled ?? false,
        noManagerMessage: channel?.config.liveChatAutoCloseSetting?.enabledNoManagerMessage ?? false,
        forceCloseLiveChat: channel?.config.liveChatAutoCloseSetting?.enabledForceCloseLiveChat ?? false
    });

    // const [waitingTime, setWaitingTime] = useState({
    //     noUserMessage: channel?.config.liveChatAutoCloseSetting?.waitingTime ?? 120,
    //     noManagerMessage: channel?.config.liveChatAutoCloseSetting?.waitingTimeNoManagerMessage ?? 120
    // });

    const [invalidation, setInvalidation] = useState({
        noUserMessage: INIT_INVALIDATION,
        noManagerMessage: INIT_INVALIDATION,
        forceCloseLiveChat: INIT_INVALIDATION
    });

    const [saving, setSaving] = useState(false);
    const [forceEnabled, setForceEnabled] = useState(enabled.forceCloseLiveChat);

    const waitingTimeNoUserMessageRef = useRef<HTMLInputElement>(null);
    const waitingTimeNoManagerMessageRef = useRef<HTMLInputElement>(null);
    const waitingTimeForceCloseLiveChatRef = useRef<HTMLInputElement>(null);

    // const notificationTimeNoUserMessageRef = useRef<HTMLInputElement>(null);
    // const notificationTimeNoManagerMessageRef = useRef<HTMLInputElement>(null);


    const editorNoUserMessageRef = useRef<EditorRef>(null);
    const editorNoManagerMessageRef = useRef<EditorRef>(null);
    const editorForceCloseLiveChatRef = useRef<EditorRef>(null);

    const autoCloseSetting = useMemo(() => {
        return channel?.config.liveChatAutoCloseSetting;
    }, [channel]);

    const defaultMessageBlocks = useMemo(() => {
        let elementsNoUserMessage: EditorElement[] = [];
        autoCloseSetting?.messageBlocks?.forEach(block => {
            if (block.type === 'text') {
                elementsNoUserMessage = elementsNoUserMessage.concat(block.meta as EditorElement[]);
            }
        });

        let elementsNoManagerMessage: EditorElement[] = [];
        autoCloseSetting?.messageBlocksNoManagerMessage?.forEach(block => {
            if (block.type === 'text') {
                elementsNoManagerMessage = elementsNoManagerMessage.concat(block.meta as EditorElement[]);
            }
        });

        let elementsForceCloseLiveChat: EditorElement[] = [];
        autoCloseSetting?.messageBlocksForceCloseLiveChat?.forEach(block => {
            if (block.type === 'text') {
                elementsForceCloseLiveChat = elementsForceCloseLiveChat.concat(block.meta as EditorElement[]);
            }
        });

        return {
            noUserMessage: elementsNoUserMessage,
            noManagerMessage: elementsNoManagerMessage,
            forceCloseLiveChat: elementsForceCloseLiveChat
        };
    }, [autoCloseSetting]);

    const defaultAttachedFiles = useMemo(() => {
        return {
            noUserMessage: autoCloseSetting?.messageBlocks?.filter((block: MessageBlock) => block.type === 'file').map((block: MessageBlock) => {
                const fileMessage = block.value as FileMessage;
                return {
                    filename: fileMessage.filename,
                    size: fileMessage.size,
                    url: fileMessage.url,
                    contentType: fileMessage.contentType
                } as FileInfo
            }) ?? [],
            noManagerMessage: autoCloseSetting?.messageBlocksNoManagerMessage?.filter((block: MessageBlock) => block.type === 'file').map((block: MessageBlock) => {
                const fileMessage = block.value as FileMessage;
                return {
                    filename: fileMessage.filename,
                    size: fileMessage.size,
                    url: fileMessage.url,
                    contentType: fileMessage.contentType
                } as FileInfo
            }) ?? [],
            forceCloseLiveChat: autoCloseSetting?.messageBlocksForceCloseLiveChat?.filter((block: MessageBlock) => block.type === 'file').map((block: MessageBlock) => {
                const fileMessage = block.value as FileMessage;
                return {
                    filename: fileMessage.filename,
                    size: fileMessage.size,
                    url: fileMessage.url,
                    contentType: fileMessage.contentType
                } as FileInfo
            }) ?? []
        };
    }, [autoCloseSetting]);

    const onChangeEnabled = (e: React.ChangeEvent<HTMLInputElement>, reason: AutoCloseReasonType) => {
        switch (reason) {
            case 'no-user-message':
                setEnabled(current => ({...current, noUserMessage: e.target.checked}))
                break
            case "no-manager-message":
                setEnabled(current => ({...current, noManagerMessage: e.target.checked}))
                break
            case "force-close-live-chat":
                setForceEnabled(e.target.checked)
                setEnabled({
                    noUserMessage: false,
                    noManagerMessage: false,
                    forceCloseLiveChat: e.target.checked
                })
                break
        }
    }

    const onChangeWaitingTime = (e: React.ChangeEvent<HTMLInputElement>, reason: AutoCloseReasonType) => {
        switch (reason) {
            case "no-user-message":
                if (!waitingTimeNoUserMessageRef.current) return;

                if (e.target.value) {
                    let value = e.target.value;
                    if (value.startsWith('0')) {
                        value = value.substring(1)
                        waitingTimeNoUserMessageRef.current.value = value;
                    }
                    // setWaitingTime(current => ({...current, noUserMessage: parseInt(value)}));

                    if (parseInt(value) <= 0) {
                        waitingTimeNoUserMessageRef.current.value = '0'
                    }
                }
                else {
                    // setWaitingTime(current => ({...current, noUserMessage: 0}));
                    waitingTimeNoUserMessageRef.current.value = '0';
                }
                break
            case "no-manager-message":
                if (!waitingTimeNoManagerMessageRef.current) return;

                if (e.target.value) {
                    let value = e.target.value;
                    if (value.startsWith('0')) {
                        value = value.substring(1)
                        waitingTimeNoManagerMessageRef.current.value = value;
                    }
                    // setWaitingTime(current => ({...current, noManagerMessage: parseInt(value)}));

                    if (parseInt(value) <= 0) {
                        waitingTimeNoManagerMessageRef.current.value = '0'
                    }
                }
                else {
                    // setWaitingTime(current => ({...current, noUserMessage: 0}));
                    waitingTimeNoManagerMessageRef.current.value = '0';
                }
                break
            case "force-close-live-chat":
                if (!waitingTimeForceCloseLiveChatRef.current) return;

                if (e.target.value) {
                    let value = e.target.value;
                    if (value.startsWith('0')) {
                        value = value.substring(1)
                        waitingTimeForceCloseLiveChatRef.current.value = value;
                    }

                    if (parseInt(value) <= 0) {
                        waitingTimeForceCloseLiveChatRef.current.value = '0'
                    }
                }
                else {
                    waitingTimeForceCloseLiveChatRef.current.value = '0';
                }
                break
        }
    }

    // const onChangeNotificationTime = (e: React.ChangeEvent<HTMLInputElement>, reason: AutoCloseReason) => {
    //     switch (reason) {
    //         case "no-user-message":
    //             if (!notificationTimeNoUserMessageRef.current) return;
    //
    //             if (e.target.value) {
    //                 const value = e.target.value;
    //                 if (value.startsWith('0')) {
    //                     notificationTimeNoUserMessageRef.current.value = value.substring(1);
    //                 }
    //
    //                 if (parseInt(value) >= waitingTime.noUserMessage) {
    //                     notificationTimeNoUserMessageRef.current.value = waitingTime.noUserMessage.toString();
    //                 }
    //
    //                 if (parseInt(value) <= 0) {
    //                     notificationTimeNoUserMessageRef.current.value = '0'
    //                 }
    //             }
    //             else notificationTimeNoUserMessageRef.current.value = '0';
    //
    //             break
    //         case "no-manager-message":
    //             if (!notificationTimeNoManagerMessageRef.current) return;
    //
    //             if (e.target.value) {
    //                 const value = e.target.value;
    //                 if (value.startsWith('0')) {
    //                     notificationTimeNoManagerMessageRef.current.value = value.substring(1);
    //                 }
    //
    //                 if (parseInt(value) >= waitingTime.noManagerMessage) {
    //                     notificationTimeNoManagerMessageRef.current.value = waitingTime.noManagerMessage.toString();
    //                 }
    //
    //                 if (parseInt(value) <= 0) {
    //                     notificationTimeNoManagerMessageRef.current.value = '0'
    //                 }
    //             }
    //             else notificationTimeNoManagerMessageRef.current.value = '0';
    //
    //             break
    //     }
    // }

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!channel) {
            return;
        }

        // if (!waitingTimeNoUserMessageRef.current || !notificationTimeNoUserMessageRef.current) {
        //     return;
        // }
        //
        // if (!waitingTimeNoManagerMessageRef.current || !notificationTimeNoManagerMessageRef.current) {
        //     return;
        // }

        if (!waitingTimeNoUserMessageRef.current || !waitingTimeNoManagerMessageRef.current || !waitingTimeForceCloseLiveChatRef.current) {
            return;
        }

        setSaving(true);

        let noUserMessageSetting = {
            waitingTime: parseInt(waitingTimeNoUserMessageRef.current?.value),
            // notificationTime: parseInt(waitingTimeNoUserMessageRef.current?.value),
            contents: editorNoUserMessageRef.current?.getContents() || [],
            text: '', html: '', files: [] as FileInfo[]
        }

        let noManagerMessageSetting = {
            waitingTime: parseInt(waitingTimeNoManagerMessageRef.current?.value),
            // notificationTime: parseInt(waitingTimeNoManagerMessageRef.current?.value),
            contents: editorNoManagerMessageRef.current?.getContents() || [],
            text: '', html: '', files: [] as FileInfo[]
        }

        let forceCloseLiveChatSetting = {
            waitingTime: parseInt(waitingTimeForceCloseLiveChatRef.current?.value),
            // notificationTime: parseInt(waitingTimeNoManagerMessageRef.current?.value),
            contents: editorForceCloseLiveChatRef.current?.getContents() || [],
            text: '', html: '', files: [] as FileInfo[]
        }

        noUserMessageSetting = {
            ...noUserMessageSetting,
            text: noUserMessageSetting.contents.map(content => content.value.text).join('\n'),
            html: noUserMessageSetting.contents.map(content => content.value.html).join('\n'),
            files: editorNoUserMessageRef.current?.files || []
        }

        noManagerMessageSetting = {
            ...noManagerMessageSetting,
            text: noManagerMessageSetting.contents.map(content => content.value.text).join('\n'),
            html: noManagerMessageSetting.contents.map(content => content.value.html).join('\n'),
            files: editorNoManagerMessageRef.current?.files || []
        }

        forceCloseLiveChatSetting = {
            ...forceCloseLiveChatSetting,
            text: forceCloseLiveChatSetting.contents.map(content => content.value.text).join('\n'),
            html: forceCloseLiveChatSetting.contents.map(content => content.value.html).join('\n'),
            files: editorForceCloseLiveChatRef.current?.files || []
        }

        const noUserMessageInvalidation = enabled.noUserMessage ? {
            waitingTime: noUserMessageSetting.waitingTime <= 0,
            // notificationTime: noUserMessageSetting.notificationTime <= 0,
            // overNotificationTime: noUserMessageSetting.waitingTime - noUserMessageSetting.notificationTime < 0,
            message: !noUserMessageSetting.text && noUserMessageSetting.files.length < 1
        } : INIT_INVALIDATION;

        const noManagerMessageInvalidation = enabled.noManagerMessage ? {
            waitingTime: noManagerMessageSetting.waitingTime <= 0,
            // notificationTime: noManagerMessageSetting.notificationTime <= 0,
            // overNotificationTime: noManagerMessageSetting.waitingTime - noManagerMessageSetting.notificationTime < 0,
            message: !noManagerMessageSetting.text && noManagerMessageSetting.files.length < 1
        } : INIT_INVALIDATION;

        const forceCloseLiveChatInvalidation = enabled.forceCloseLiveChat ? {
            waitingTime: forceCloseLiveChatSetting.waitingTime <= 0,
            // notificationTime: forceCloseLiveChatSetting.notificationTime <= 0,
            // overNotificationTime: forceCloseLiveChatSetting.waitingTime - forceCloseLiveChatSetting.notificationTime < 0,
            message: !forceCloseLiveChatSetting.text && forceCloseLiveChatSetting.files.length < 1
        } : INIT_INVALIDATION;

        setInvalidation({
            noUserMessage: noUserMessageInvalidation,
            noManagerMessage: noManagerMessageInvalidation,
            forceCloseLiveChat: forceCloseLiveChatInvalidation
        });

        if ([noManagerMessageInvalidation, noManagerMessageInvalidation, forceCloseLiveChatInvalidation].some(invalidationItem => {
            return Object.values(invalidationItem).some(v => v);
        })) {
            toast.error(intl.formatMessage({id: 'i200004'}));
            setSaving(false);
            return;
        }

        const generateMessageBlocks = (setting: {waitingTime: number, contents: Content[], text: string, html: string, files: FileInfo[]}) => {
            const messageBlocks = [];

            if (setting.text) {
                messageBlocks.push({
                    type: 'text',
                    value: {
                        text: setting.text.trim(),
                        html: setting.html.trim()
                    },
                    meta: setting.contents.map(content => content.meta)
                });
            }

            setting.files.forEach(file => {
                messageBlocks.push({
                    type: 'file',
                    value: {
                        filename: file.filename,
                        url: file.url,
                        size: file.size,
                        contentType: file.contentType
                    }
                });
            });

            return messageBlocks;
        };

        const noUserMessageBlocks = generateMessageBlocks(noUserMessageSetting);
        const noManagerMessageBlocks = generateMessageBlocks(noManagerMessageSetting);
        const forceCloseLiveChatMessageBlocks = generateMessageBlocks(forceCloseLiveChatSetting);


        const body = {
            ...channel,
            config: {
                ...channel.config,
                liveChatAutoCloseSetting: {
                    enabled: enabled.noUserMessage,
                    waitingTime: noUserMessageSetting.waitingTime,
                    notificationTime: noUserMessageSetting.waitingTime,
                    messageBlocks: noUserMessageBlocks,
                    enabledNoManagerMessage: enabled.noManagerMessage,
                    waitingTimeNoManagerMessage: noManagerMessageSetting.waitingTime,
                    notificationTimeNoManagerMessage: noManagerMessageSetting.waitingTime,
                    messageBlocksNoManagerMessage: noManagerMessageBlocks,
                    enabledForceCloseLiveChat: enabled.forceCloseLiveChat,
                    waitingTimeForceCloseLiveChat: forceCloseLiveChatSetting.waitingTime,
                    notificationTimeForceCloseLiveChat: forceCloseLiveChatSetting.waitingTime,
                    messageBlocksForceCloseLiveChat: forceCloseLiveChatMessageBlocks,
                }
            }
        };

        await toast.promise(
            updateChannel(body),
            {
                loading: intl.formatMessage(({id: 'i200003'})),
                success: () => {
                    setSaving(false);
                    return intl.formatMessage({id: 'i000377'});
                },
                error: () => {
                    setSaving(false);
                    return intl.formatMessage({id: 'e000001'});
                }
            }
        )
    }

    // for automatic update
    useEffect(() => {
        if (channel?.config) {
            setEnabled({
                noUserMessage: channel.config.liveChatAutoCloseSetting?.enabled ?? false,
                noManagerMessage: channel.config.liveChatAutoCloseSetting?.enabledNoManagerMessage ?? false,
                forceCloseLiveChat: channel.config.liveChatAutoCloseSetting?.enabledForceCloseLiveChat ?? false,
            })
        }
    }, [channel?.config])

    return (
        <SettingViewWrapper>
            <div className="setting-title">
                <h3>{intl.formatMessage({id: 'i000362'})}</h3>
                <p>{intl.formatMessage({id: 'i000363'})}</p>
            </div>
            <Form onSubmit={onSubmit}>
                <AutoCloseConfig reason='no-user-message' enabled={enabled.noUserMessage}
                                 forceEnabled={forceEnabled}
                                 autoCloseSetting={autoCloseSetting}
                                 onChangeEnabled={onChangeEnabled}
                                 onChangeWaitingTime={onChangeWaitingTime}
                                 // onChangeNotificationTime={onChangeNotificationTime}
                                 invalidation={invalidation.noUserMessage}
                                 waitingTimeRef={waitingTimeNoUserMessageRef}
                                 // notificationTimeRef={notificationTimeNoUserMessageRef}
                                 editorRef={editorNoUserMessageRef}
                                 defaultMessageBlocks={defaultMessageBlocks.noUserMessage}
                                 defaultAttachedFiles={defaultAttachedFiles.noUserMessage}/>
                <AutoCloseConfig reason='no-manager-message' enabled={enabled.noManagerMessage}
                                 forceEnabled={forceEnabled}
                                 autoCloseSetting={autoCloseSetting}
                                 onChangeEnabled={onChangeEnabled}
                                 onChangeWaitingTime={onChangeWaitingTime}
                                 // onChangeNotificationTime={onChangeNotificationTime}
                                 invalidation={invalidation.noManagerMessage}
                                 waitingTimeRef={waitingTimeNoManagerMessageRef}
                                 // notificationTimeRef={notificationTimeNoManagerMessageRef}
                                 editorRef={editorNoManagerMessageRef}
                                 defaultMessageBlocks={defaultMessageBlocks.noManagerMessage}
                                 defaultAttachedFiles={defaultAttachedFiles.noManagerMessage}/>
                <AutoCloseConfig reason='force-close-live-chat' enabled={enabled.forceCloseLiveChat}
                                 autoCloseSetting={autoCloseSetting}
                                 onChangeEnabled={onChangeEnabled}
                                 onChangeWaitingTime={onChangeWaitingTime}
                                 invalidation={invalidation.forceCloseLiveChat}
                                 waitingTimeRef={waitingTimeForceCloseLiveChatRef}
                                 editorRef={editorForceCloseLiveChatRef}
                                 defaultMessageBlocks={defaultMessageBlocks.forceCloseLiveChat}
                                 defaultAttachedFiles={defaultAttachedFiles.forceCloseLiveChat}/>
                <hr/>
                <Button type="submit" className="btn-rounded" disabled={saving}>
                    {intl.formatMessage({id: 'i000040'})}
                </Button>
            </Form>
        </SettingViewWrapper>
    );
};

type AutoCloseConfigProps = {
    reason: AutoCloseReasonType;
    forceEnabled?: boolean;
    enabled: boolean;
    autoCloseSetting?: LiveChatAutoCloseSetting;
    onChangeEnabled: (e: ChangeEvent<HTMLInputElement>, reason: AutoCloseReasonType) => void;
    onChangeWaitingTime: (e: ChangeEvent<HTMLInputElement>, reason: AutoCloseReasonType) => void;
    // onChangeNotificationTime: (e: ChangeEvent<HTMLInputElement>, reason: AutoCloseReason) => void;
    invalidation: INVALIDATION_TYPE;
    waitingTimeRef: React.RefObject<HTMLInputElement>,
    // notificationTimeRef: React.RefObject<HTMLInputElement>,
    editorRef:  React.RefObject<EditorRef>,
    defaultMessageBlocks: EditorElement[],
    defaultAttachedFiles: FileInfo[]
}

const AutoCloseConfig: React.FC<AutoCloseConfigProps> = (props) => {
    const intl = useIntl()

    const {
        reason,
        forceEnabled,
        enabled,
        autoCloseSetting,
        onChangeEnabled,
        onChangeWaitingTime,
        // onChangeNotificationTime,
        invalidation,
        waitingTimeRef,
        // notificationTimeRef,
        editorRef,
        defaultMessageBlocks,
        defaultAttachedFiles,
    } = props;

    const {mutateAsync: uploadFile} = useUploadFiles();

    const enabledRef = useRef<HTMLInputElement>(null);

    const onClickFile = async (file: File) => {
        if (!isSupportedFileSize(file)) {
            toast.error(intl.formatMessage({id: "i200076"}));
            return;
        }

        if (!isSupportedFileExtension(file)) {
            toast.error(intl.formatMessage({id: "i200077"}));
            return;
        }

        try {
            const response = await uploadFile(file);
            const fileGroup: FileGroup = response.data.result;
            const fileInfo = fileGroup.files[0];
            return {
                filename: fileInfo.originalFilename,
                url: fileInfo.url,
                size: fileInfo.size,
                contentType: fileInfo.contentType
            }
        } catch (e) {
            toast.error(intl.formatMessage({id: 'e000001'}));
        }
    }

    const defaultConfig = useMemo(() => {
        switch (reason) {
            case "no-user-message":
                return {
                    waitingTime: autoCloseSetting?.waitingTime,
                    // notificationTime: autoCloseSetting?.notificationTime
                };
            case "no-manager-message":
                return {
                    waitingTime: autoCloseSetting?.waitingTimeNoManagerMessage,
                    // notificationTime: autoCloseSetting?.notificationTimeNoManagerMessage
                }
            case "force-close-live-chat":
                return {
                    waitingTime: autoCloseSetting?.waitingTimeForceCloseLiveChat,
                    // notificationTime: autoCloseSetting?.notificationTimeForceCloseLiveChat
                }
        }
    }, [reason, autoCloseSetting])

    const configIntl = useMemo(() => {
        switch (reason) {
            case "no-user-message":
                return {
                    title: intl.formatMessage({id: "i000366"}),
                    description: intl.formatMessage({id: "i000365"})
                }
            case "no-manager-message":
                return {
                    title: intl.formatMessage({id: "i000376"}),
                    description: intl.formatMessage({id: "i000375"})
                }
            case "force-close-live-chat":
                return {
                    title: intl.formatMessage({id: "i210002"}),
                    description: intl.formatMessage({id: "i210001"})
                }
        }
    }, [reason, intl])

    // for automatic update
    useEffect(() => {
        if (autoCloseSetting) {
            switch (reason) {
                case "no-user-message":
                    if (enabledRef.current) {
                        enabledRef.current.checked = autoCloseSetting.enabled;
                    }

                    if (waitingTimeRef.current) {
                        waitingTimeRef.current.value = String(autoCloseSetting.waitingTime ?? "120");
                    }
                    break
                case "no-manager-message":
                    if (enabledRef.current) {
                        enabledRef.current.checked = autoCloseSetting.enabledNoManagerMessage ?? false;
                    }

                    if (waitingTimeRef.current) {
                        waitingTimeRef.current.value = String(autoCloseSetting.waitingTimeNoManagerMessage ?? '120');
                    }
                    break
                case "force-close-live-chat":
                    if (enabledRef.current) {
                        enabledRef.current.checked = autoCloseSetting.enabledForceCloseLiveChat ?? false;
                    }

                    if (waitingTimeRef.current) {
                        waitingTimeRef.current.value = String(autoCloseSetting.waitingTimeForceCloseLiveChat ?? '120');
                    }
                    break
            }

            if (editorRef.current) {
                editorRef.current.setValues(defaultMessageBlocks)
                editorRef.current.setFiles(defaultAttachedFiles)
            }
        }
    }, [reason, autoCloseSetting, defaultAttachedFiles, defaultMessageBlocks, editorRef, waitingTimeRef])

    return (
        <>
            <Form.Group className="mb-2 d-flex align-items-center justify-content-start">
                <div className="d-flex align-items-center justify-content-between"
                     style={{width: "330px"}}>
                    <Form.Label htmlFor={`enable-auto-close-with-${reason}`} className="me-1 mb-0">
                        {configIntl.title}
                    </Form.Label>
                    <Form.Switch id={`enable-auto-close-with-${reason}-${forceEnabled}`}
                                 key={`enable-auto-close-with-${reason}-${forceEnabled}`}
                                 className="m-0"
                                 defaultChecked={enabled}
                                 ref={enabledRef}
                                 disabled={forceEnabled}
                                 onChange={(e) => {onChangeEnabled(e, reason)}} />
                </div>
                {reason === 'force-close-live-chat' &&
                    <div className="text-danger">
                        {intl.formatMessage({id: "i210004"})}
                        <TooltipIcon tooltip={intl.formatMessage({id: "i210003"})}/>
                    </div>
                }
            </Form.Group>
            <hr className={enabled ? '' : 'd-none'}/>
            <div className={enabled ? 'd-flex justify-content-between' : 'd-none'} style={{width: '1050px'}}>
                <div>
                    <Form.Group className="mb-2">
                        <Form.Label>{intl.formatMessage({id: 'i000364'})}</Form.Label>
                        <div className="d-flex justify-content-start align-items-end">
                            <InputGroup style={{width: '120px'}} size="sm">
                                <Form.Control type="number"
                                              defaultValue={defaultConfig?.waitingTime ?? 120}
                                              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeWaitingTime(e, reason)}
                                              isInvalid={invalidation.waitingTime}
                                              ref={waitingTimeRef} />
                                <InputGroup.Text>{intl.formatMessage({id: 'i000367'})}</InputGroup.Text>
                                <Form.Control.Feedback type="invalid">{intl.formatMessage({id: 'i000024'})}</Form.Control.Feedback>
                            </InputGroup>
                            <small className="text-muted ml-1">{configIntl.description}</small>
                        </div>
                    </Form.Group>
                    {/*<Form.Group className="mb-2">*/}
                    {/*    <Form.Label>{intl.formatMessage({id: 'i000368'})}</Form.Label>*/}
                    {/*    <div className="d-flex justify-content-start align-items-center">*/}
                    {/*        <InputGroup style={{width: '120px'}} size="sm">*/}
                    {/*            <Form.Control type="number"*/}
                    {/*                          defaultValue={defaultConfig?.notificationTime ?? 110}*/}
                    {/*                          onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeNotificationTime(e, reason)}*/}
                    {/*                          isInvalid={invalidation.notificationTime || invalidation.overNotificationTime}*/}
                    {/*                          ref={notificationTimeRef} />*/}
                    {/*            <InputGroup.Text>{intl.formatMessage({id: 'i000367'})}</InputGroup.Text>*/}
                    {/*            <Form.Control.Feedback type="invalid">*/}
                    {/*                {invalidation.notificationTime && intl.formatMessage({id: 'i000024'})}*/}
                    {/*                {invalidation.overNotificationTime && intl.formatMessage({id: 'i000372'})}*/}
                    {/*            </Form.Control.Feedback>*/}
                    {/*        </InputGroup>*/}
                    {/*        <small className="text-muted ml-1">*/}
                    {/*            {intl.formatMessage({id: reason === 'no-user-message' ? 'i000374' : 'i000373'})}*/}
                    {/*        </small>*/}
                    {/*    </div>*/}
                    {/*</Form.Group>*/}
                </div>
                <Form.Group className="ml-3">
                    <Form.Label>
                        {intl.formatMessage({id: 'i000370'})}
                        {invalidation.message && <Form.Text className="ml-1 text-danger">{intl.formatMessage({id: 'i000024'})}</Form.Text>}
                    </Form.Label>
                    <Editor id={`auto-close-${reason}-editor`}
                            placeholder={intl.formatMessage({id: "i000371"})}
                            height="150px"
                            width="500px"
                            onClickFile={onClickFile}
                            defaultValue={defaultMessageBlocks}
                            defaultFiles={defaultAttachedFiles}
                            ref={editorRef}
                            enterFunctionKey={"shift"}
                    />
                </Form.Group>
            </div>
        </>
    )
}

export default LiveChatAutoCloseConfigView;
