import {atom} from "recoil";


type PlayerState = {
    id: string,
    playing: boolean
};


const playersState = atom<{[key: string]: PlayerState}>({
    key: 'players',
    default: {}
});

export default playersState;