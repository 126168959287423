import React, {useMemo, useRef} from "react";
import {LiveChat} from "../../model/livechat";
import {Dropdown} from "react-bootstrap";
import {UnStyledButton} from "../UnstyledBootstrap";
import styled from "styled-components";
import useChannel from "../../query/channel/useChannel";
import {LiveChatPriority} from "../../model/channel";
import useUpdateLiveChat from "../../query/liveChat/useUpdateLiveChat";
import toast from "react-hot-toast";
import {useIntl} from "react-intl";
import classNames from "classnames";


const LiveChatPriorityDropdown: React.FC<{liveChat: LiveChat}> = ({liveChat}) => {
    const intl = useIntl();
    const {data: channel} = useChannel(liveChat.channelId);

    const {mutate: updateLiveChat} = useUpdateLiveChat(liveChat.channelId, liveChat._id);

    const priorities = useMemo(() => {
        return channel?.config.priorities.sort((a, b) => a.priority > b.priority ? -1 : 1) ?? [];
    }, [channel]);

    const currentPriority = useMemo(() => {
        const liveChatPriority = priorities.find(priority => priority.priority === liveChat.priority);
        return liveChatPriority || {priority: 3, name: '보통', color: '#'};
    }, [liveChat, priorities]);

    const onSelect = (eventKey: number) => {
        updateLiveChat({priority: eventKey}, {
            onError: () => {
                toast.error(intl.formatMessage({id: 'e000001'}));
            }
        })
    }

    return (
        <LiveChatDropdownStyle onSelect={onSelect}>
            <Dropdown.Toggle id="chat-menu-dropdown-toggle" as={UnStyledButton} className="pl-0" bsPrefix="unused">
                <PriorityItem priority={currentPriority} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-1">
                {priorities.map(priority => (
                    <Dropdown.Item key={priority.priority} className="m-0" style={{padding: '0.1875rem 0'}} eventKey={priority.priority}>
                        <PriorityItem priority={priority} defaultChecked={priority.priority === currentPriority.priority} showRadio />
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </LiveChatDropdownStyle>
    );
};

const PriorityItem:React.FC<{priority: LiveChatPriority, defaultChecked?: boolean, showRadio?: boolean} & React.HTMLProps<HTMLDivElement>> = (props) => {
    const {priority, defaultChecked=false, showRadio, className} = props
    const labelRef = useRef<HTMLSpanElement>(null)

    const alias = useMemo(() => {
        return priority.alias || priority.name;
    }, [priority])


    return (
        <PriorityItemStyle className={className}>
            <span className="priority-color" style={{backgroundColor: priority.color}} />
            <span ref={labelRef} className="priority-name mr-1 flex-grow-1 text-start">{alias}</span>
            {showRadio && <div className={classNames('checkbox', {selected: defaultChecked})} />}
        </PriorityItemStyle>
    );
};

const PriorityItemStyle = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0.15rem;
  cursor: pointer;

  .priority-color {
    width: 20px;
    border-radius: 2.5px;
    height: 5px;
    margin: 0 0.375rem 0 0.2rem;
  }

  .priority-name {
    position: relative;
    min-width: 100px;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .checkbox {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: var(--ct-form-check-input-border);
    margin: 0.375rem;
    
    &.selected {
      //background-color: var(--ct-form-check-input-checked-bg-color);
      border: 5px solid var(--ct-form-check-input-checked-border-color);
    }
  }

  //&:hover {
  //  background-color: var(--ct-light);
  //}
`

const LiveChatDropdownStyle = styled(Dropdown)`
  position: unset;
  
`

export default LiveChatPriorityDropdown;