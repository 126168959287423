import React from "react";
import {useRecoilValue} from "recoil";
import platformState from "../recoil/platform";
import styled from "styled-components";
import {useHotkeys} from "react-hotkeys-hook";

type HotkeysProps = {
    keys: string,
    callbackFn: () => void
}

const Hotkeys: React.FC<HotkeysProps> = (props) => {
    const { keys, callbackFn } = props;

    const platform = useRecoilValue(platformState);

    useHotkeys(keys, () => {
        callbackFn()
    }, [callbackFn]);

    return (
        <div className="d-flex">
            {keys.split("+").map((key) => {
                switch (key.toLowerCase()) {
                    case "meta":
                        return (
                            <KBDStyle key={key}>
                                {platform === "darwin" ?
                                    <i className="mdi mdi-apple-keyboard-command"/>
                                    :
                                    <i className="mdi mdi-microsoft-windows"/>
                                }
                            </KBDStyle>
                        )
                    case "alt":
                        return (
                            <KBDStyle key={key}>
                                {platform === "darwin" ?
                                    <i className="mdi mdi-apple-keyboard-option"/>
                                    :
                                    key
                                }
                            </KBDStyle>
                        )
                    default:
                        return <KBDStyle key={key}>{key}</KBDStyle>
                }
            })}
        </div>
    )
};

const KBDStyle = styled.kbd`
  width: fit-content;
  height: 20px;

  text-align: center;

  font-size: 12px;
  font-family: inherit;
  padding: 0.1rem 0.25rem;
  margin-left: 0.15rem;

  box-shadow: 0 0 1px 1px #e8e8e8;

  color: #464f60;
  background-color: #D9D9D9;
`;

export default Hotkeys;
