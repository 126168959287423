const FILE_UPLOAD_MAX_SIZE = 100 * 1024 * 1024;

const SUPPORTED_FILE_EXTENSION = [
    // Image
    "jpg", "jpeg", "png",
    // Video
    "mp4", "m4v", "avi", "asf", "wmv", "mkv", "ts", "mpg", "mpeg", "mov", "flv", "ogv",
    // Audio
    "mp3", "wav", "flac", "tta", "tak", "aac", "wma", "ogg", "m4a",
    // Etc
    "pdf", "odp", "ppt", "pptx", "key", "show", "doc", "docx", "hwp", "txt", "rtf", "xml",
    "wks", "wps", "xps", "md", "odf", "odt", "pages", "ods", "csv", "tsv", "xls", "xlsx",
    "numbers", "cell", "psd", "ai", "sketch", "tif", "tiff", "tga", "webp", "zip", "gz",
    "bz2", "rar", "7z", "lzh", "alz"
];

export function isSupportedFileExtension(file: File) {
    const extension = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);
    return SUPPORTED_FILE_EXTENSION.includes(extension);
}

export function isSupportedFileSize(file: File) {
    return file.size < FILE_UPLOAD_MAX_SIZE;
}
