import styled from "styled-components";

export const FilterSelectionItemStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  
  cursor: pointer;
  
  background-color: white;
  padding: 0.5rem 1.5rem;
  
  .form-check-input {
    border-radius: 2px;
    margin-top: 0.25rem;
  }
  
  .item-info {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 0.5rem;
    
    .name {
      margin-left: 0.5rem;
    }
  }
  
  &:hover {
    background-color: #CADCFF80;
  }
`;

export const ChatSearchFilterToggleStyle = styled.div<{active?: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  padding: 0 1rem;
  height: 35px;
  
  border: none;
  border-radius: 10px;
  background-color: ${props => props.active ? "#E4EDFF" : "#ececec"};
  color: black;
  transition: background-color 0.1s ease-in-out;
  
  &:hover {
    background-color: #DBDBDB;
    transition: background-color 0.1s ease-in-out;
  }
  
  & > i {
    font-size: 18px;
  }
`;

export const ChatSearchFilterResetToggleStyle = styled(ChatSearchFilterToggleStyle)`
  background-color: #9d9d9d;
  color: white;
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: #727272;
    transition: background-color 0.1s ease-in-out;
  }
`;

export const ChatSearchSortingDropdownToggleStyle = styled(ChatSearchFilterToggleStyle)`
  font-size: 13px;
  height: 30px;
  padding: 0 0.5rem;

  background-color: white;
  color: black;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;

  &:hover {
    background-color: #E4EDFF;
    color: #2E36EF;
    transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
  }
`;

export const ChatSearchFilterInputGroupStyle = styled.div<{show?: boolean}>`
  visibility: ${props => props.show ? "visible": "hidden"};
  
  z-index: 9;
  width: 300px;
  padding: 0.75rem;

  .filter-search-bar-input-group {
    background-color: white;
    border: 2px solid rgba(15, 98, 254, 0.8);
    border-radius: 5px;

    & > .input-group-text {
      
      border: unset;
      background-color: white;
      border-radius: 5px;
    }

    & > .form-control {
      border: unset;
      border-radius: 5px;
      padding: 0.25rem 0;
    }
  }
`;

export const ChatSearchFilterListStyle = styled.div`
  margin: 0.25rem 0 0.5rem 0;
  max-height: 250px;
  overflow-y: auto;
  scrollbar-gutter: auto;
`;
