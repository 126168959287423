import {Article} from "../model/board";
import {action, makeObservable, observable} from "mobx";
import {StoreState} from "../model";
import boardRepository from "../repository/BoardRepository";

class BoardArticleListStore {
    @observable
    data: Article[] = [];

    @observable
    state: StoreState = 'none';

    @observable
    hasMore: boolean = false;

    offset = 0;
    limit = 20;

    constructor() {
        makeObservable(this);
    }

    @action
    async load(props: {channelId: string, boardId: string, startDate?: string, endDate?: string, limit?: number, silent?: boolean}) {
        if (!props.silent) {
            this.state = 'pending';
        }

        this.offset = 0;
        this.limit = props.limit || 20;

        try {
            const {data} = await boardRepository.findArticles({
                channelId: props.channelId,
                offset: 0,
                boardId: props.boardId,
                startDate: props.startDate,
                endDate: props.endDate,
                limit: this.limit
            });

            this.data = data.result;
            this.hasMore = data.result.length === props.limit

            if (!props.silent) {
                this.state = 'done';
            }
        } catch (e) {
            if (!props.silent) {
                this.state = 'error';
            }
            throw e;
        }
    }

    // @action
    // async loadUnreadArticles(props: {channelId: string, boardId: string}) {
    //     this.offset = this.offset + this.limit;
    //
    //     try {
    //         const {data} = await boardRepository.paginateArticle(
    //             {
    //                 channelId: props.channelId,
    //                 boardId: props.boardId,
    //                 offset: this.offset,
    //                 limit: this.limit}
    //         );
    //
    //         this.data = [...this.data, ...data.result];
    //         this.hasMore = data.result.length === this.limit;
    //     } catch (e) {
    //         throw e;
    //     }
    // }

    @action
    async more(props: {channelId: string, boardId: string}) {
        this.state = 'pending';
        this.offset = this.offset + this.limit;

        try {
            const {data} = await boardRepository.findArticles(
                {
                    channelId: props.channelId,
                    boardId: props.boardId,
                    offset: this.offset,
                    limit: this.limit}
            );

            this.data = [...this.data, ...data.result];
            this.hasMore = data.result.length === this.limit;

            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    clear() {
        this.data = [];
        this.state = 'none';
        this.hasMore = false;
        this.offset = 0;
        this.limit = 20;
    }
}


export default BoardArticleListStore;
