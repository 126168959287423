import React, {useMemo} from "react";
import toast, {Toast} from "react-hot-toast";
import styled from "styled-components";
import {Manager} from "../../model/manager";
import {utcToLocal} from "../../util/datetime";
import {TextMessage} from "../../model/message";
import {UnreadToast} from "../../model/unreadToast";
import parse from "html-react-parser";
import classNames from "classnames";
import {useIntl} from "react-intl";
import announcementIcon from "../../assets/images/announcement_icon.png";

type ChannelUnreadToastProps = {
    t: Toast;
    onClose?: () => void;
    unreadToast: UnreadToast;
    managers: Manager[];
    unreadCount: number;
}

const ChannelUnreadToast: React.FC<ChannelUnreadToastProps> = (props) => {
    const { t, onClose, unreadToast, managers, unreadCount } = props;

    const intl = useIntl();

    const authorName = useMemo(() => {
        if (!unreadToast.authorId) return null

        const managerWriter = managers.find(manager => manager.userId === unreadToast.authorId);
        if (managerWriter) return managerWriter.name

        return intl.formatMessage({id: "i210040"})
    }, [unreadToast, managers, intl]);

    const unreadToastContent = useMemo(() => {
        const value = unreadToast.blocks[0].value as TextMessage;
        return value.html;
    }, [unreadToast])

    const onClickDismiss = async (e: React.MouseEvent) => {
        e.preventDefault();
        onClose?.();

        if (unreadCount === 1) {
            toast.dismiss(t.id);
        }
    }

    return (
        <UnreadToastStyle t={t}>
            <div className="unread-toast-header">
                <div className="unread-toast-title">
                    {/*<i className="mdi mdi-bullhorn-variant-outline font-20 mr-2"/>*/}
                    <img src={announcementIcon} alt="annoucement"/>
                    <div>
                        <span>
                           {unreadToast.title}
                        </span>
                        {authorName &&
                            <span className='ml-1'>
                                {authorName}
                            </span>
                        }
                    </div>
                </div>
                <div className="unread-toast-tail">
                    <div className="font-13">{utcToLocal(unreadToast.createdAt, "LLL")}</div>
                </div>
            </div>
            <div className="unread-toast-content">
                {parse(unreadToastContent)}
            </div>
            <div className="unread-toast-footer">
                <div className="next-or-close-toast" onClick={onClickDismiss}>
                    <div className="text-muted">
                        {unreadCount > 0 && intl.formatMessage({id: "i210041"}, {count: unreadCount})}
                    </div>
                    <i className={classNames("mdi font-16 p-0 pl-1", unreadCount > 1 ? "mdi-chevron-right" : "mdi-close")}/>
                </div>
            </div>
        </UnreadToastStyle>
    )
};

const UnreadToastStyle = styled.div<{t: Toast}>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  position: absolute;
  left: 55px;
  bottom: 80px;

  border-radius: 5px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
  background-color: white;

  width: 600px;
  max-height: 180px;

  @keyframes unread-toast-enter {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes unread-toast-exit {
    from {
      transform: scale(1);
      opacity: 1;
    }

    to {
      transform: scale(0.95);
      opacity: 0;
    }
  }
  animation: ${props => props.t.visible ? "unread-toast-enter 0.2s ease" : "unread-toast-exit 0.2s ease forwards"};

  .unread-toast-header {
    padding: 0.5rem 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .unread-toast-title {
      display: flex;
      align-items: center;

      font-weight: bold;
      color: gray;

      img {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        margin-right: 0.5rem;
      }
    }

    .unread-toast-tail {
      display: flex;
      align-items: center;
    }
  }

  .unread-toast-content {
    margin: 0.75rem;
    white-space: pre-wrap;

    max-height: 130px;
    overflow-y: auto;
  }
  
  .unread-toast-footer {
    padding: 0 0.5rem 0.5rem 0.5rem;
    display: flex;
    justify-content: end;
    align-items: center;

    .next-or-close-toast {
      display: flex;
      align-items: center;
      padding: 0 0.25rem;
      cursor: pointer;
      font-size: 12px;

      background-color: white;
      transition: background-color 0.1s ease-in;
    }

    .next-or-close-toast:hover {
      border-radius: 5px;
      background-color: #ececec;
      transition: background-color 0.1s ease-out;
    }
  }
`;

export default ChannelUnreadToast;
